import styled from 'styled-components';
import { Card } from '../../../components/Styled-Components/Card';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

export const Container = styled.div`
  flex: 1;
  width: 100%;
  height:100%;
  font-family: "Roboto";
  display:flex;
  overflow:auto;

`;
export const DropzoneContainer = styled.div`
border: 2px dashed #555;
height:150px ;
`
export const DropzoneContent = styled.div`

`
export const TableRow = styled.div`
  margin-bottom:${({ marginBottom }) => marginBottom ? marginBottom : '0px'};
  display:flex;
  flex-direction:row;
 
  > div {
    border: 1px solid #555555;
    min-width:250px;
    margin-right:10px;
    align-items:center;
    justify-content:center;
    border-radius: ${({ topRound }) => topRound ? '8px 8px' : '0px 0px'} ${({ bottomRound }) => bottomRound ? '8px 8px' : '0px 0px'};
    border-width: ${({ topRound, bottomRound }) => {

    if (!topRound && bottomRound)
      return '0px 1px 1px 1px'

    if (!bottomRound && !topRound)
      return '0px 1px 1px 1px'
    else
      return '1px'

  }};
 
    padding : 10px 20px;
  }
    span {
      width:100% ;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align:center;

  }
`

export const Tabs = styled(Card)`
margin-right:10px;
margin-bottom:10px;
margin-top:10px;
border-radius:10px;
width:100% ;
height:100%;
`;

export const ExternalButtonDiv = styled.div` 
display:flex;
flex-direction:row;
justify-content:space-between;
padding:10px;
`
export const StyledCheckIcon = styled(CheckCircleOutlineIcon)`
  color:${props => !props.panelConcluded ? 'grey' : 'green'};
  margin-right:10px;
`

export const HeaderSpan = styled.span`
  font-size:15px;
  font-weight:bold;
`

export const Button = styled.button`
border-radius:20px;
display:flex;
flex-direction: row;
background-color:${({disabled}) => disabled?"#555":"#ee5928"};
:hover{
  cursor:${({disabled}) => disabled?"not-allowed":"pointer"};
  background-color:${({disabled}) => disabled?"#555f":"#ee592855"};
  opacity:1 ;
}
margin-right:20px;
padding:5px;
color:white;
height: 36px;
align-items: center;
`

export const InsideContainer = styled.div`
  padding:50px;
  max-width:90vw;
  overflow: auto;
`

export const StyledDropzoneContainer = styled.div`
flex-direction: column;
align-items: center;
padding: 20px;
border-width: 2px;
border-radius: 2px;
border-color: #eeeeee;
border-style: dashed;
background-color: #fafafa;
color: #bdbdbd;
outline: none;
`

export const SecondTabDiv = styled.div`
  display:flex;
  flex-direction:row; 
  justify-content:space-around;
  margin-top:20px;
  margin-bottom:20px;
  & >span {
    font-size:12px;
  }
`

export const SecondTabButtonDiv = styled(SecondTabDiv)`
  justify-content:flex-end;
`

export const MySelect = styled.select`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  color:#888888;
  padding-left: 12px;
  margin-left:7px;
  margin-top:10px;
  background-color: white;
  border: 1.5px solid #888888;
  border-radius: 4px;
  box-sizing: border-box;
  width: 100%;
  &:link {
    text-decoration: none;
  }
  &:active {
    text-decoration: none;
  }
  &:focus {
    text-decoration: none;
    box-shadow: 0 0 0 0;
    outline: 0;
  }
`

export const ThirdTabTable = styled.div`
overflow-x:scroll;
  tr {
    height: 50px ;
  }

  .properties {
    border:1px solid;
    border-radius:1em;
    height:150px;
    width:150px;
    padding:0px 10px ;
  }
  .header {
    border:1px solid;
    border-radius:1em;
    height:50px;
    width:150px;
  }
`
