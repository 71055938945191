
import React from 'react';
import { useDrag } from 'react-dnd';

import * as S from './styled';
export default function DraggableField({ flags, data, outsideDropped, dispatchAdd, add, type, fields, setFields}) {
  const [{ opacity }, dragRef] = useDrag(
    () => ({
      type: 'string',
      item: data,
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0 : 1
      }),
      end: (item, monitor) => {
        if (!monitor.getDropResult()) {
          if (outsideDropped) {
            outsideDropped(item)
          }
        }
      }
    }),
    []
  )

  return (
    <S.Container 
      ref={dragRef} 
      style={{ opacity }} 
      onClick={() => {
        if (dispatchAdd) {
          add(data)
        } else {
          outsideDropped(data)
        }
      }}
      type={type}
    >
      <label>
        {data?.name}
      </label>
    </S.Container>
  )
}

