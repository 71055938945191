import React from 'react';

export const Checkbox = React.forwardRef(({ideterminate,...rest},ref) =>{
    const defaultRef = React.useRef()
    
    const resolvedRef = ref || defaultRef
    
    React.useEffect(() => {
        resolvedRef.current.ideterminate = ideterminate
    },[resolvedRef])

    return(
        <>
            <input type='checkbox' ref={resolvedRef} {...rest}/>
        </>

    )
})