import styled from "styled-components";

export const ButtonContainer = styled.div`
 flex-direction:row;
align-items:center;
justify-content:center;
height: 20px;
`
export const CreateApiConnectionHeader = styled.div`

.row{
    flex-direction:row;
}
button{

    background-color: var(--color-text-colorful);
    color: #fff;
    border-radius:20px;
    padding: 3px 3px;
    @media(max-width: 1000px) {
         position:absolute;
         right: 0;
        }
}
input{
    margin-right:10px;
    margin-bottom:10px;
}

width: 100%;
margin-top: 10px;
border-radius: 10px;
background-color:#fff;
-webkit-box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.1); 
box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.1);
overflow:hidden;
flex-direction:row;
display:flex;
.container{
    width: 100%;
    display:flex;
    .inputsContainer{
        .topDivision{

            width: 50%;

            @media(max-width: 1000px) {
             width: 100%;
            }
        }
        min-width: 90%;
        @media(max-width: 1000px) {
            min-width: 100%;
    
        }
    }
    .saveContainer{

        width: 10%;
        @media(max-width: 1000px) {
         position:relative;
        
        }
    }
    flex-direction:row;
}
`
export const Container = styled.div`

width: 100%;


`
export const TitleText = styled.label`
color:var(--color-text-colorful);
font-weight:bold;
`
export const SmallText = styled.label`
color: #aaa;
font-size:10px;
`
export const TitleTextBlack = styled.label`
color:#000;
font-weight:bold;
`
export const CircleButton = styled.button`
background-color:#C4C4C4;
color:#FFF;
border-radius:10px;
font-size:20px;
align-items:center;
justify-content:center;
vertical-align:center;
line-height:20px;
width: 20px;
height: 20px;
border:none;
`
export const TriggerListContainer = styled.div`
width: 100%;
margin-top: 10px;
border-radius: 10px;
padding: 10px;
-webkit-box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.1); 
box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.1);
`
export const EditableInput = styled.input.attrs(props => ({
    disabled: !props.editable,
}))`

font-size:12px;
background-color:transparent ;
padding: 0;
border: none;
display:flex;


width: 100%;
min-width:100px;
border:${({ editable }) => editable ? "2px solid #DFDFDF" : "none"};
padding:${({ editable }) => editable ? "5px" : "0"} ;
border-radius:${({ editable }) => editable ? "25px" : "0"}}
margin-top:${({ editable }) => editable ? "2px" : "0"}}
height:${({ editable }) => editable ? "30px" : "auto"}}

::placeholder{

    color: #707070;
   
}
`
export const Trigger = styled.div`
width: 100%;
display:flex;
margin-top: 10px;
border-radius: 10px;
padding: 10px;
-webkit-box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.1); 
box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.1);
div{
    flex-wrap:wrap;
}
.inputContainer{

margin-right: 5px;
}
.titleContainer{

    flex-direction:row;
    align-items:center;
    
}
.apiConfigs{

    flex-direction:row;
    .inputs{
        flex-direction:row;
        flex:1;
        div{
            flex:1
        }
    }
    .buttons{
        align-items:center;
        margin-top:20px ;
        margin-left:5px ;
        margin-right:2px ;
        flex-direction:row;
    }
    

}
.newFieldButton{
    flex-direction:row;
    margin-top:10px;
    display: flex;
    justify-content:space-between;

    .transparent{
        width:70%;
     
    }
}
.newField{
    
    flex-direction:row;
 
    div{
        flex:1
    }
   
}
.fields{
    
    flex-direction:row;
 
    .fieldsContainer{
       flex-direction:row;
       width: 100%;
       align-items:center;
       button{
        margin-top:8px;
       }
    }
   
}
`