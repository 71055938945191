import React from 'react'

import CloseIcon from '@material-ui/icons/Close'

import * as S from './styles'
import CheckIcon from '@material-ui/icons/Check';
import HelpIcon from '@material-ui/icons/Help';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';


const GroupRemoveModal = (props) => {
  const { 
    active, 
    handleClickClose = () => {}, 
    handleClickSave = () => {},
    partOfGroup = false
  } = props

  return(
    <>
      { active  && (
        <>
          <S.Root >
            <S.Modal>
              <S.Container>
                <S.Header>
                  <button onClick={handleClickClose}>
                    <CloseIcon style={{width:'20px', height:'20px'}} />
                  </button>
                </S.Header>
                <S.IconDiv>
                  <HelpIcon style={{color:'#EE5928', width:'80px', height:'80px'}} />
                </S.IconDiv>
                <S.TitleDiv>
                    <S.Title>{!partOfGroup ? 'Deseja realmente excluir este usuário do time?':
                      'Este usuário já faz parte de outro time, deseja realmente incluir ele neste time?'}
                    </S.Title>
                </S.TitleDiv>
                <S.SubTitleDiv>
                  <S.SubTitle>
                    {!partOfGroup ?
                    'Ao excluir este usuário dos grupos e times, ele não aparecerá mais nos relatórios':
                    'Ao incluir neste time, se o usuário for um promotor será automaticamente excluído do outro time.'
                    }
                  </S.SubTitle>
                </S.SubTitleDiv>
                <S.Footer>
                  <S.ButtonModal return onClick={handleClickClose}>
                    <KeyboardReturnIcon /> Voltar
                  </S.ButtonModal> 
                  <S.ButtonModal onClick={handleClickSave}>
                    <CheckIcon /> Salvar
                  </S.ButtonModal> 
                </S.Footer>
              </S.Container>
            </S.Modal>
          </S.Root>
        </>
        )
      }
    </>
  )
} 

export default GroupRemoveModal