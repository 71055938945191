import styled from 'styled-components'

export const Tag = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => !props.presentTag ? '#444444' : '#EE5928'};
  color: white;
  border-radius: 7px;
  font-size: 9px;
  padding: 5px 20px;
  margin: 0 5px 5px 0;
  height: 19px;
  font-weight: bold;
  user-select: none;
`