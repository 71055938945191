import React from 'react';

import './styles.css';

export default function ModalNewProduct(props){
  return(
    <>
      { props.showAlert? (
        <div id="modalFieldContainer">
          <div id="modalNewProductCard">
          {
            props.addedProducts.length > 0 &&
            <label className="modalFieldTitle" style={{fontSize:20}}>Produtos adicionados</label>
          }
            <div className="cointainerAddedProducts">
              {
                props.addedProducts.map(elm =><div className="addedProduct" key={elm}>{elm}</div> )
              }
            </div>
              {
                props.sendedProducts.length > 0 &&
                <label className="modalFieldTitle" style={{fontSize:20}}>Produtos já existentes</label>
              }
            <div className="cointainerErrorProducts">
              {
                props.sendedProducts.map(elm =><div className="errorProduct" key={elm}>{elm}</div> )
              }
            </div>
            <div className="modalFieldButtonRow">
                <button                  
                  className="modalFieldButton" 
                  onClick={
                    _=> { 
                      if (props.addedProducts.length > 0 && props.sendedProducts.length === 0 ) {
                        props.exit()
                      } else {                     
                        props.showModal()
                      }                        
                    }
                  }
                >
                  {"Fechar"}
                </button>              
            </div>
          </div>
        </div>
      ):(
        <div/>
      )}
    </>
  )
}