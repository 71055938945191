import styled from 'styled-components'

export const Card = styled.div`
  display: flex;
  box-shadow: 1px 2px 13px #00000032;
  width: 100%;
  min-height: 60vh;
  max-height: auto;
  border-radius: 15px;
  flex-direction: column;
  :nth-child(1) {
    margin-right: 10px;
  }
`
export const Input = styled.input`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: #000000;
  width: ${props => props.primary ? "100%" : "50%"};
  padding-left: 12px;
  height: 30px;
  border: 1.5px solid #888888;
  border-radius: 20px;
  box-sizing: border-box;
  &:link {
    text-decoration: none;
  }
  &:active {
    text-decoration: none;
  }
  &:focus {
    text-decoration: none;
    box-shadow: 0 0 0 0;
    outline: 0;
  }
`
export const TextArea = styled.textarea`  
  width: ${props => props.primary ? "100%" : "50%"};
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  color: #000000;
  height: auto;
  border: 1.5px solid #888888;
  border-radius: 20px;
  box-sizing: border-box;
  &:link {
    text-decoration: none;
  }
  &:active {
    text-decoration: none;
  }
  &:focus {
    text-decoration: none;
    box-shadow: 0 0 0 0;
    outline: 0;
  }
`

export const Label = styled.label`
  display: inline;
  margin: 15px 0px 7px 0;
  color: #000000;
  opacity: 0.51;
  font-size: 14px;
`

export const Select = styled.select`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold !important;
  font-size: 12px;
  color: #000000;
  display: flex;
  padding-left: 12px;
  justify-content: space-between;
  margin: ${props => props.primary ? "" : "2px"};
  width: ${props => props.primary ? "100%" : "48%"};
  height: 30px;
  border: 1.5px solid #888888;
  opacity: 1;
  border-radius: 20px;
  box-sizing: border-box;
  &:link {
    text-decoration: none;
  }
  &:active {
    //text-decoration: none;
  }
  &:focus {
    text-decoration: none;
    box-shadow: 0 0 0 0;
    outline: 0;
  }

  option {
    color: #000000;
    background: white;
    display: flex;
    white-space: pre;
    min-height: 20px;
    padding: 0px 2px 1px;
  }
`

export const ShowSelect = styled.div `
  display: ${props => props.view ? "" : "none"};
`
export const InfoText = styled.div`
  h2 {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    color: #000000;
    opacity: 0.51;
    margin: 15px 0px 7px 0;
    font-family: Roboto;
  }
  p {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    color: #000000;
    margin: 15px 13px 7px 0;
    font-family: Roboto;
  }

`

export const ButtonSend = styled.button`
  display: flex;
  height: 45px;
  width: 204px;
  margin-bottom: 10px;
  border-radius: 25px;
  color: #FFFFFF;
  background-color: #EE5928;
  align-self: center;
  align-items: center;
  justify-content: center;
`
export const ClientInfo = styled.button`
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  border-bottom: 0px;
  cursor: pointer;
  color: #EE5928;
  margin-bottom: 10px;

  &:hover{
    color:#EE5928;
  };

  &:focus{
    color: #EE5928;
    outline: none;  
  };

`

export const ContentBtns = styled.div`
  display: flex;
  flex-direction: row;
`

export const ContainerCustomer = styled.div`
  margin-top: 15px;
`

export const ContentCustomer = styled.div`
  width: 100%;
  margin-top: 15px;
  box-shadow: 1px 2px 13px #00000032;
  border-radius: 15px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const CustomerPhoto = styled.div`
  width: 30%;
  > img {
    width: 150px;
    height: 150px;
  }
`

export const CustomerInfo = styled.div`
  width: 70%;
  display: flex;
  flex-direction: row;
  justify-content: center;

  > div {
    width: 50%;
    display: flex;
    flex-direction: column;
  }

  .prisma-color {
    color:#EE5928 !important;
  }
`

export const CustomerInfoData = styled.div`
  margin-bottom: 8px;

  label {
    color: #999;
    font-size: 14px;
  }

  span {
    color: #000000;
    font-weight: 700;
    font-size: 13px;
  }
`
export const CustomerManyInfoData = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;

  > div {
    width: 50%;
  }

  label {
    color: #999;
    font-size: 14px;
  }

  span {
    color: #000000;
    font-weight: 700;
    font-size: 13px;
  }
`