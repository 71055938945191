import React, { useState, useEffect, useContext, useCallback} from 'react'

import { useLocation, useHistory } from 'react-router-dom'
import StoreContext, { ScrollContext } from '../../context/Context'
import pt from 'date-fns/locale/pt-BR'
import * as datefns from 'date-fns'
import api from '../../services/api'
import swal from 'sweetalert'
import ModalForm from '../../components/ModalForm'
import SearchClients from '../../components/SearchClients'
import LoaderSpin from '../../components/LoaderSpin'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'

import * as Styled from './styles'

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}


const CustomersWalletsPage = () => {
  const history = useHistory()
  const query = useQuery();

  const { userProjectStore, userInfoStore } = useContext(StoreContext);
  const { onScroll } = useContext(ScrollContext);

  const [users, setUsers] = useState([])
  const [copyUsers, setCopytUsers] = useState([])
  const [lastUser, setLastUser] = useState('')
  const [customersWallets, setCustomersWallets] = useState([])
  const [customerWallet, setCustomerWallet] = useState({
    id: null,
    name: null,
    id_user: null
  })
  const [search, setSearch] = useState('')
  const [searchCustomersWallets, setSearchCustomersWallets] = useState('')
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingQuery, setLoadingQuery] = useState(false);  
  const [showModalEdit, setShowModalEdit] = useState(false);  
  const [showModalCreate, setShowModalCreate] = useState(false);  
  
  const setNullCustomerWalletObj = () => {
    setCustomerWallet({
      id: null,
      name: null,
      id_user: null
    })
  }

  let id_user = query.get('id_user') ?? null
  
  const getCustomerWallets = useCallback(async () => {
    setLoading(true)

    setLoadingQuery(true)

    try {
      const { data } = await api.get('/customers-wallets', { 
        params: { id_project: userProjectStore.id, search: searchCustomersWallets, page: 1 }
      })
        
      setPage(1)
  
      setLastPage(data?.lastPage)
  
      setCustomersWallets(data?.data ?? [])
  
      setLoading(false)

      setLoadingQuery(false)        
      
      document.querySelector('#mainScroll').scrollTo(0, 0)
    } catch {

    }

  },[searchCustomersWallets,  userProjectStore.id])

  const getUsers = useCallback(async () => {
    try {
        const { data } = await api.get('promotors-supervisors', { params: { id_project:  userProjectStore.id } });

        if (id_user) {
          setCopytUsers(
            data.sort((a, b) => 
              a.fullname.toLowerCase().localeCompare(b.fullname.toLowerCase()) - 
              b.fullname.toLowerCase().localeCompare(a.fullname.toLowerCase())
            )  
          )   

          setUsers(data.filter(user => user.id === id_user))   

          return 
        } 
    
        setUsers(
          data.sort((a, b) => 
            a.fullname.toLowerCase().localeCompare(b.fullname.toLowerCase()) - 
            b.fullname.toLowerCase().localeCompare(a.fullname.toLowerCase())
          )  
        )      

    } catch (error) {

    }

  }, [])

  const addSearch = (_search) => {
    setSearch(_search)
    
    if (_search.length === 0) {
      setSearchCustomersWallets('')
    } 
  }

  const autoSearch = async (_search) => {
    if (_search) {
      setSearchCustomersWallets(_search.trim())
    }
  }

  const autoSrollQuery = async () => {
    
    if (page <= lastPage) {
      const sumPage = page + 1
      
      setLoadingQuery(true)
      
      const { data } = await api.get('/customers-wallets', { 
        params: { id_project: userProjectStore.id, search: searchCustomersWallets, page: sumPage}
      })
      
      setPage(sumPage)

      const newArray = [...customersWallets, ...data?.data]

      setCustomersWallets(newArray)

      setLoadingQuery(false)
    }
  }

  const handleEditWallet = async () => {
    try {      
      const editWallet = await swal({
        title: `Deseja realmente editar a carteira?`,
        icon: "info",
        buttons: ["Não", "Sim"],
        dangerMode: true,
      })

      if (!editWallet) {
        return 
      }


      if (!customerWallet.id_user) {
        swal(`Obrigatório informar o usuário da carteira!`, {
          icon: "warning",
          button: "Fechar"
        });

        return
      }

      if (!customerWallet.name) {
        swal(`O nome da carteira é obrigatório!`, {
          icon: "warning",
          button: "Fechar"
        });

        return
      }    
    
      await api.put(`/customers-wallets/${customerWallet.id}`, { 
        name: customerWallet.name, 
        id_user: customerWallet.id_user
      })

      swal(`Carteira atualizada com sucesso!`, {
        icon: "success",
        button: "Fechar"
      });

      setSearch('')

      
      setShowModalEdit(false)  
      
      setNullCustomerWalletObj()
      
      if (id_user) {
        history.replace('Settings?s=wallets')
        setUsers(copyUsers)
      }
      
      if (searchCustomersWallets) {
        setSearchCustomersWallets('')
      } else {
        await getCustomerWallets()
      }
      
    } catch (error) {
      swal(`Não foi possível atualizar a carteira!`, {
        icon: "error",
        button: "Fechar"
      });
    }
  }

  const handleRemoveUserFromWallet = async (wallet) => {
    try {      
      const removeUserFromWallet = await swal({
        title: `Deseja realmente remover (${wallet.fullname}) da carteira ${wallet.name}?`,
        icon: "info",
        buttons: ["Não", "Sim"],
        dangerMode: true,
      })

      if (!removeUserFromWallet) {
        return 
      }

      await api.delete(`/customers-wallets/${wallet.id}`, { 
        name: customerWallet.name, 
        id_user: customerWallet.id_user
      })

      swal(`Usuário removido com sucesso!`, {
        icon: "success",
        button: "Fechar"
      });

      await getCustomerWallets()
    } catch (error) {
      swal(`Não foi possível remover usuário da carteira!`, {
        icon: "error",
        button: "Fechar"
      });

    }
  }

  const handleWallet = async () => {
    try {      
      if (!customerWallet.name) {
        swal(`O nome da carteira é obrigatório!`, {
          icon: "warning",
          button: "Fechar"
        });

        return
      }

      await api.post(`/customers-wallets`, { 
        id_project: userProjectStore.id,
        name: customerWallet.name, 
        id_user: customerWallet.id_user
      })

      swal(`Carteira criada com sucesso!`, {
        icon: "success",
        button: "Fechar"
      });
    
      setShowModalCreate(false)  
      
      setNullCustomerWalletObj()          

      await getCustomerWallets()
    } catch (error) {
      swal(`Não foi possível criar a carteira!`, {
        icon: "error",
        button: "Fechar"
      });
    }
  }
 

  useEffect(() => {
    getCustomerWallets()   
    getUsers() 
  },[getCustomerWallets, getUsers])

  useEffect(() => {
    if ((onScroll?.target.scrollTop) / (onScroll?.target.scrollHeight - onScroll?.target.clientHeight) > 0.8) {
      if (!loadingQuery) {
        autoSrollQuery();
      }
    }
  }, [onScroll]) 

  const userType = (type) => {
    const types = {
      1: 'Promotor',
      2: 'Supervisor',
      default: '---'
    }

    return types[type] || types.default
  }

  return (
      <Styled.Container>
        <Styled.HeaderCard>
          <Styled.HeaderTitle>
            Carteiras de Clientes
          </Styled.HeaderTitle>
          <Styled.ButtonDiv>
            <SearchClients autoSearch={autoSearch} setSearch={addSearch} search={search}/>
          </Styled.ButtonDiv>
          <Styled.ButtonDiv>
            <Styled.Button 
              onClick={() => setShowModalCreate(true)}
              disabled={userInfoStore.id_users_type !== 6}
            >              
              Nova Carteira
            </Styled.Button>
          </Styled.ButtonDiv>      
        </Styled.HeaderCard>
        <Styled.MainCard>          
          <Styled.GridTitleContainer>
            <Styled.GridTitle>
              Nome
            </Styled.GridTitle>
            <Styled.GridTitle>
              Responsável
            </Styled.GridTitle>
            <Styled.GridTitle>
              Cargo
            </Styled.GridTitle>
            <Styled.GridTitle>
              Clientes
            </Styled.GridTitle>
            <Styled.GridTitle>
              Data da criação
            </Styled.GridTitle>
            <Styled.GridTitle>
              Última atualização
            </Styled.GridTitle>
            <Styled.GridTitle>
              Ações
            </Styled.GridTitle>
          </Styled.GridTitleContainer>
          <LoaderSpin type={'Grid'} enable={loading} />
          {
            customersWallets.length > 0 && customersWallets.map(wallet => {
              return (
                <Styled.GridTitleContainer>
                  <Styled.GridCard>
                    {wallet.name}
                  </Styled.GridCard>
                  <Styled.GridCard
                    onClick={() => {
                      if (wallet?.id_user) {
                        window.open(`/UsersRegister/${wallet.id_user}`, "_blank");
                      }
                    }}  
                    style={{
                      cursor: wallet?.id_user ? 'pointer': 'no-drop'
                    }}
                  >   
                    { wallet?.fullname ? wallet.fullname : '---'}        
                  </Styled.GridCard>
                  <Styled.GridCard>
                    {
                      userType(Number(wallet.id_users_type))
                    }
                  </Styled.GridCard>
                  <Styled.GridCard>
                    {
                      wallet.total_customers
                    }
                  </Styled.GridCard>
                  <Styled.GridCard>
                    { 
                      datefns.format(new Date(wallet.created_at), 'dd/MM/yyyy HH:mm', { locale: pt }) 
                    }
                  </Styled.GridCard>
                  <Styled.GridCard>
                    {
                      datefns.format(new Date(wallet.updated_at), 'dd/MM/yyyy HH:mm', { locale: pt }) 
                    }
                  </Styled.GridCard>
                  <Styled.GridCard>
                    <Styled.ButtonActions>
                      <EditIcon onClick={() => {
                        setLastUser('')

                        if (id_user && wallet?.fullname) {
                          setLastUser(wallet?.fullname)
                          setCustomerWallet({ name: wallet.name, id_user, id: wallet.id })
                        } else {
                          setCustomerWallet({ name: wallet.name, id_user: wallet.id_user, id: wallet.id })
                        }

                        setShowModalEdit(true)
                      }}/>
                      <DeleteIcon 
                        onClick={() => {
                          if (wallet?.id_user && userInfoStore?.id_users_type === 6)
                            handleRemoveUserFromWallet(wallet)
                        }} 
                        style={{
                          cursor: wallet?.id_user && userInfoStore?.id_users_type === 6 ? 'pointer': 'no-drop'
                        }}
                      />                      
                    </Styled.ButtonActions>
                  </Styled.GridCard>
                </Styled.GridTitleContainer>
              )
            })
              
          }
        </Styled.MainCard>
        <ModalForm 
          title="Editar Carteira" 
          customButton={
            () => <Styled.Button 
                    disabled={!customerWallet?.name || !customerWallet.id_user} 
                    onClick={() => handleEditWallet()}
                  >
                    Salvar Alteração
                  </Styled.Button>
          } 
          close={() => {
            setShowModalEdit(false)
            setNullCustomerWalletObj()
          }} 
          showAlert={showModalEdit}          
        >
          <Styled.ModalFormContainer>
            <Styled.MyLabel 
              htmlFor='name' 
            >
              Nome da Carteira
            </Styled.MyLabel>
            <Styled.MyInput 
              placeholder="Digite o nome" 
              id='name' 
              value={customerWallet.name}
              onChange={(e) => setCustomerWallet({ ...customerWallet, name: e.target.value })} 
            />
            {
              id_user && lastUser && <>
                <Styled.MyLabel 
                  htmlFor='last_user' 
                >
                  Responsável Atual
                </Styled.MyLabel>
                <Styled.MyInput 
                  id='last_user' 
                  value={lastUser}
                  disabled={true}
                  style={{
                    cursor: 'no-drop'
                  }}
                />

              </>
            }
            <Styled.MyLabel 
              htmlFor='user' 
            >
              {
                lastUser ? 'Novo Responsável': 'Responsável'
              }
            </Styled.MyLabel>
            <Styled.MySelect      
              id='user'      
              onChange={(e) => setCustomerWallet({ ...customerWallet, id_user: e.target.value })}
              value={customerWallet?.id_user ?? 'Selecione o responsável'}
            >
              <option
                value={'Selecione o responsável'}
                disabled={true}
              >
                Selecione o responsável
              </option>
              {
                users.map(user => {
                  return (
                    <option value={user.id} key={user.username}>{user.fullname}</option>
                  )
                })
              }

            </Styled.MySelect>
          </Styled.ModalFormContainer>
        </ModalForm>
        <ModalForm 
          title="Nova Carteira" 
          customButton={
            () => <Styled.Button 
                    onClick={() => handleWallet()}
                    disabled={!customerWallet?.name || !customerWallet.id_user} 
                  >
                    Criar Carteira
                  </Styled.Button>
          } 
          close={() => {
            setShowModalCreate(false)
            setNullCustomerWalletObj()
          }} 
          showAlert={showModalCreate}          
        >
          <Styled.ModalFormContainer>
            <Styled.MyLabel 
              htmlFor='name' 
            >
              Nome da Carteira
            </Styled.MyLabel>
            <Styled.MyInput 
              placeholder="Digite o nome" 
              id='name' 
              value={customerWallet.name}
              onChange={(e) => setCustomerWallet({ ...customerWallet, name: e.target.value })} 
            />
            <Styled.MyLabel 
              htmlFor='user' 
            >
              Responsável
            </Styled.MyLabel>
            <Styled.MySelect      
              id='user'      
              onChange={(e) => setCustomerWallet({ ...customerWallet, id_user: e.target.value })}
              value={customerWallet?.id_user ?? 'Selecione o responsável'}
            >
              <option
                value={'Selecione o responsável'}
                disabled={true}
              >
                Selecione o responsável
              </option>
              {
                users.map(user => {
                  return (
                    <option value={user.id} key={user.id}>{user.fullname}</option>
                  )
                })
              }
            </Styled.MySelect>
          </Styled.ModalFormContainer>
        </ModalForm>
      </Styled.Container>
  )

}

export default CustomersWalletsPage