export const permission_obj = {
    'clientes_vendas': ['customersales_read', 'customersales_seeAll', 'customersales_seeBelow'],
    'acoes_clientes_vendas': ['customersales_delete'],    
    'agenda': ['schedule_read', 'schedule_seeBelow', 'schedule_seeAll'],
    'produtos': ['products_read', 'products_seeBelow', 'products_seeAll'],
    'acoes_produtos': ['products_edit', 'products_delete', 'products_create'], 
    'categorias': ['productCategory_read', 'productCategory_seeBelow', 'productCategory_seeAll'],
    'acoes_categorias': ['productCategory_create', 'productCategory_edit', 'productCategory_delete'],
    'usuarios': ['users_read', 'users_seeAll', 'users_seeBelow'],
    'acoes_usuario': ['users_create', 'users_edit', 'users_delete'],
    'projetos': ['projects_read'],
    'acoes_projetos': ['projects_delete', 'projects_create', 'projects_edit'],
    'grupos': ['groups_read', 'groups_seeAll'],
    'acoes_grupos': ['groups_edit','groups_delete', 'groups_create'], 
    'permissoes': ['permissions_read'],
    'acoes_permissoes': ['permissions_create', 'permissions_edit', 'permissions_delete'],
    'metricas': ['metrics_read'], 
    'campos': ['customFields_read'],
    'acoes_campos': ['customFields_edit', 'customFields_delete', 'customFields_create'],
    'reports': ['report_read'],
    'reports_beta': ['betareports_create'],
    'acoes_reports': ['report_create'],
    'formularios': ['forms_read'],
    'bases': ['importbases_read'],
    'maps': ['maps_read'],
}

export const permission_list = (() => Object.values(permission_obj))()

export const permissionCheck = (permission_name, userAccess) => permission_obj[permission_name]?.some(elm => userAccess.includes(elm))
