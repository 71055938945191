import React, { useState, useEffect } from 'react';
import Header from '../../components/Header'
import CreateIcon from '@material-ui/icons/Create';
import { useHistory } from "react-router-dom";
import DeleteIcon from '@material-ui/icons/Delete';
import api from '../../services/api';
import ModalForm from '../../components/ModalForm';
import ModalField from '../../components/ModalField';
import DoneIcon from '@material-ui/icons/Done';

import './styles.css'


const ListContent = ({ profile, created, att, onEdit, onDelete, id, history, editOrDelete }) => {
  return (
    <div className='backgroundShadow'> 
      <div className='rowListContent'>
        <span className='flex listContent Profile'>{profile}</span>
        <span className='flex listContent Creation'>{created}</span>
        <span className='flex listContent Att'>{att}</span>
        <div className='row listContent-btn'>
          <div onClick={onEdit} className='optionCircle'>
            <CreateIcon className='editPencil' style={{ fontSize: 22 }} />
          </div>
          <div onClick={onDelete} className='optionCircle'>
            <DeleteIcon className='trash ' style={{ fontSize: 22 }} />
          </div>
        </div>

      </div>
    </div>


  )


}

function Permissions() {
  const history = useHistory();
  const [allRoles, setAllRoles] = useState([])
  const [modal, setModal] = useState(false)
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [nameError, setNameError] = useState(false)
  const [descriptionError, setDescriptionError] = useState(false)
  const [selectedProfile, setSelectedProfile] = useState({})
  const [modalDelete, setModalDelete] = useState(false)
  const [edit, setEdit] = useState(false);

  const getAllRoles = async () => {

    const { data } = await api.get('/roles/all')

    setAllRoles(data)
  }

  useEffect(() => {

    getAllRoles()

  }, [])

  const editProfile = async () => {
    setNameError(false)
    setDescriptionError(false)

    if (name === '' && name.length < 3) {
      setNameError(true)
      return;
    }
    if (description === '' && description.length < 3) {
      setDescriptionError(true)
      return;
    }

    await api.put(`/roles/${selectedProfile.id}`, { name, description, slug: name.toLowerCase().replace(/[\s]/g, '_') })
    setModal(false)

  }
  const deleteProfile = async () => {

    await api.delete(`/roles/${selectedProfile.id}`)
    setModalDelete(false)

  }
  const newProfile = async () => {

    setNameError(false)
    setDescriptionError(false)

    if (name === '' && name.length < 3) {
      setNameError(true)
      return;
    }
    if (description === '' && description.length < 3) {
      setDescriptionError(true)
      return;
    }

    await api.post('/roles', { name, description, slug: name.toLowerCase().replace(/[\s]/g, '_') })
    setModal(false)
    getAllRoles()

  }

  return (
    <>
      <Header styles={{marginTop:10}} title='' buttons={() => <button onClick={() => { setEdit(false); setModal(true); setName(''); setDescription('') }} className='btnProducts' style={{ alignItems: 'center', justifyContent: 'center' }}>
        <span><b style={{ fontSize: 15 }}>+</b> Novo</span>

      </button>} />
      <ModalForm
        close={() => setModal(false)}
        customButton={() =>
          <button

            className="modalFieldButton"
            onClick={edit ? editProfile : newProfile}
          >
            <div style={{ height: '100%', alignItems: 'center', flexDirection: 'row', justifyContent: 'center' }}>
              <DoneIcon style={{ marginRight: 10 }} />
              <label>Salvar</label>
            </div>
          </button>
        } showAlert={modal} title={(edit ? 'Editar' : 'Novo') + ' perfil de acesso'}>

        <div style={{ width: '70%' }}>
          <label style={{ fontSize: 13.333 }}>Nome do perfil de acesso</label>
          <input value={name} onChange={(v) => setName(v.target.value)} className={`inputRounded ${nameError ? 'invalidInput' : ''}`} />
          <label style={{ fontSize: 13.333 }}>Descrição</label>
          <textarea value={description} onChange={(v) => setDescription(v.target.value)} className={`inputRounded ${descriptionError ? 'invalidInput' : ''}`}>
          </textarea>
        </div>
      </ModalForm>
      <ModalField delete={deleteProfile} cancel={() => setModalDelete(false)} title={`Realmente deja excluir o perfil ${selectedProfile.name}?`} showAlert={modalDelete} />
      <div className='mainContainerPermissions'>


        <div className='listPermissionsContainerP'>
          <div className='titlesPermissions'>
            <span className='titlePermission PermissionsProfile'>Perfil</span>
            <span className='titlePermission PermissionsCreation'>Data da Criação</span>
            <span className='titlePermission PermissionsAtt'>Última Atualização</span>
            <span className='titlePermission PermissionsOptions'>Opções</span>
          </div>
          {allRoles?.map((elm, index) => {

            return <ListContent
              key={index+1}
              history={history}              
              onDelete={() => { setSelectedProfile(elm); setModalDelete(true) }}
              onEdit={() => { 
                setSelectedProfile(elm); 
                setEdit(true); 
                setModal(true); 
                setName(elm.name); 
                setDescription(elm.description)  
                history.push('/Permission/' + elm.id) 
              }}
              profile={elm.name}
              created={new Date(elm.created_at).toLocaleString([], { dateStyle: 'short', timeStyle: 'short' })}
              att={new Date(elm.updated_at).toLocaleString([], { dateStyle: 'short', timeStyle: 'short' })}
              id={elm.id}
            />

          })}


        </div>


      </div>
    </>
  )
}

export default Permissions;