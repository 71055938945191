import React from 'react';
import styled from 'styled-components';
//import iconUser from '../../assets/icons/iconUser.png'
import AddIcon from '@material-ui/icons/Add';
const UserSlotComponent = ({ cargo, onClick }) => {

    return (
        <UserButton
            onClick={onClick ?? (() => { })}
        >
            <div>
                <div className="picture">
                    <AddIcon />
                </div>
            </div>
            <div className="textContainers">
                <div className="textContainer">
                    <span className="name">{cargo ?? 'Sem Cargo'}</span>
                </div>
            </div>
        </UserButton>
    )


}
export default UserSlotComponent;

const UserButton = styled.button`
    display: flex;
    width:auto;
    flex-direction: row;
    background-color: transparent;
    margin-bottom:10px;
    border-radius: 10px;
    box-shadow: ${({ shadow }) => shadow ? '2px 2px 7px #00000044' : 'none'};
    max-width: 200px;
    min-width: 200px;
    height: 50px;
    background-color:#eee;
    justify-content: center;

    div.textContainers{
        width: 80%;
        flex:1;
        height: 100%;
        align-items: center;
        justify-content: center;

    }
    div{
        height: 100%;
    justify-content: center;
    align-items: center;
    div.picture{
      background-color: #ddd;
      height: 40px;
      width: 40px;
      border-radius: 25px;
      align-items: center;
      justify-content: center;
      svg{
        fill:#aaa;
      }
    }
    
    div.textContainer{
      margin-right: 5px;
      justify-content:center;
      width: 80%;
      span{
        width: 100%;
        display: flex;
        flex:1;
        margin-left:15px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
        vertical-align: center;
      }
      span.name{
        align-items: center;
        font-weight: bold;
        color: #444;
      }
      span.cargo{
          font-size: 10px;
          color:#777;
      }
    }
  }
  :not(first-child){
    margin-right: 20px;
  }



`