import React, { useState, useEffect, useContext, useCallback } from "react";
import * as Styled from "./styled";
import api from "../../services/api";
import StoreContext, { ScrollContext } from "../../context/Context";
import { useHistory } from "react-router-dom";
import iconUser from "../../assets/icons/iconUser.png";
import Toggle from "react-toggle";
import swal from "sweetalert";

const UserListV2 = () => {
  const history = useHistory();
  const [userList, setUserList] = useState([]);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);
  const [loadingQuery, setLoadingQuery] = useState(false);
  const [queryParameters, setQueryParameters] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const { userProjectStore } = useContext(StoreContext);
  const [searchTimeout, setSearchTimeout] = useState("");

  const { onScroll } = useContext(ScrollContext);

  const getUsers = useCallback(async () => {
    const response = await api.get("/users", {
      params: { page: 1, id_project: userProjectStore.id },
    });

    setUserList(response?.data?.data);

    setLastPage(response?.data?.lastPage);
  }, [userProjectStore.id]);

  const fetchByScroll = useCallback(async () => {
    if (page <= lastPage) {
      setLoadingQuery(true);
      const { data } = await api.get("users", {
        params: {
          ...queryParameters,
          page: page + 1,
          id_project: userProjectStore.id,
        },
      });
      setPage(page + 1);
      const newArray = userList.concat(data.data);
      setUserList(newArray);
      setLoadingQuery(false);
    }
  }, [lastPage, page, queryParameters, userList, userProjectStore.id]);

  useEffect(() => {
    if (
      onScroll?.target.scrollTop /
        (onScroll?.target.scrollHeight - onScroll?.target.clientHeight) >
      0.8
    ) {
      if (!loadingQuery) {
        fetchByScroll();
      }
    }
  }, [fetchByScroll, loadingQuery, onScroll]);

  const searchUser = async (search) => {
    clearTimeout(searchTimeout);
    if (search) {
      setSearchTerm(search);
      const regex_validation_email =
        /^([a-z]){1,}([a-z0-9._-]){1,}([@]){1}([a-z]){2,}([.]){1}([a-z]){2,}([.]?){1}([a-z]?){2,}$/i;
      setSearchTimeout(
        setTimeout(async () => {
          const newParameters = queryParameters;
          if (regex_validation_email.test(search)) newParameters.email = search;
          else if (search) newParameters.fullname = search;
          setQueryParameters(newParameters);
          setLoadingQuery(true);
          finishSearch(search, newParameters);
          setLoadingQuery(false);
        }, 1000)
      );
      return;
    } else {
      setSearchTerm("");
      setQueryParameters({});
      getUsers();
    }
    return;
  };

  const finishSearch = async (search, params) => {
    const { data } = await api.get("/users", {
      params: { ...params, page: 1, id_project: userProjectStore.id },
    });

    setLastPage(data.lastPage);

    setPage(1);

    if (data.lastPage > 0) {
      if (params.email)
        setUserList(
          data.data.sort(
            (a, b) =>
              search.toLowerCase().localeCompare(a.email.toLowerCase()) -
              search.toLowerCase().localeCompare(b.email.toLowerCase())
          )
        );
      else
        setUserList(
          data.data.sort(
            (a, b) =>
              search.toLowerCase().localeCompare(a.fullname.toLowerCase()) -
              search.toLowerCase().localeCompare(b.fullname.toLowerCase())
          )
        );
    } else {
      setUserList([]);
    }
  };

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  const goToNewUSerPage = () => {
    history.push("/UsersRegister");
  };

  const goToEditUserPage = (userId) => {
    history.push(`/UsersRegister/${userId}`);
  };

  const changeUserActiveStatus = async (checked, userData) => {
    userData.active = checked;

    try {
      if (!checked) {
        const requiredFiredDate = await swal({
          title: `O usuário inativado foi demitido?`,
          text: "Se SIM, você será redirecionado para a tela de edição de usuários.",
          icon: "info",
          buttons: ["Não", "Sim"],
          dangerMode: true,
        });

        if (requiredFiredDate) {
          goToEditUserPage(userData.id_user);
          return;
        }

        const confirm = await swal({
          title: `Deseja realmente inativar ${userData.fullname}?`,
          text: `Ao inativar o usuário ele perderá os dados não sincronizados no aplicativo!`,
          icon: "info",
          buttons: ["Não", "Sim"],
          dangerMode: true,
        });

        if (confirm) {
          await api.put(`/users/${userData.id_user}`, {
            active: checked,
            id_project: userProjectStore.id,
          });

          if (queryParameters && searchTerm) {
            finishSearch(searchTerm, queryParameters);
          } else {
            getUsers();
          }
        }
      } else {
        await api.put(`/users/${userData.id_user}`, {
          active: checked,
          id_project: userProjectStore.id,
        });

        if (queryParameters && searchTerm) {
          finishSearch(searchTerm, queryParameters);
        } else {
          getUsers();
        }
      }
    } catch {}
  };

  const changeUserTemporaryBlock = async (checked, userData) => {
    userData.temporary_block = checked;

    try {
      if (checked) {
        const confirm = await swal({
          title: `Deseja realmente bloquear ${userData.fullname}?`,
          text: `Ao bloquear você poderá desbloquea-lo a qualquer momento ou ele será automaticamente desbloqueado pelo sistema no dia seguinte!`,
          icon: "info",
          buttons: ["Não", "Sim"],
          dangerMode: true,
        });

        if (confirm) {
          await api.put(`/users/${userData.id_user}`, {
            temporary_block: checked,
            id_project: userProjectStore.id,
          });

          if (queryParameters && searchTerm) {
            finishSearch(searchTerm, queryParameters);
          } else {
            getUsers();
          }
        }
      } else {
        await api.put(`/users/${userData.id_user}`, {
          temporary_block: checked,
          id_project: userProjectStore.id,
        });

        if (queryParameters && searchTerm) {
          finishSearch(searchTerm, queryParameters);
        } else {
          getUsers();
        }
      }
    } catch {}
  };

  return (
    <Styled.Container>
      <Styled.HeaderCard>
        <Styled.HeaderTitle>Usuários</Styled.HeaderTitle>
        <Styled.ButtonDiv>
          <Styled.UserSearch
            onChange={(value) => {
              searchUser(value);
            }}
            searchField={["email", "fullname"]}
            placeholder="Busca rápida"
            showButtom={false}
          />
          <Styled.NewUserButton onClick={() => goToNewUSerPage()}>
            Novo Usuário
          </Styled.NewUserButton>
        </Styled.ButtonDiv>
      </Styled.HeaderCard>
      <Styled.MainCard>
        <Styled.GridTitleContainer>
          <Styled.GridTitle>Nome</Styled.GridTitle>
          <Styled.GridTitle>Email</Styled.GridTitle>
          <Styled.GridTitle>Projetos</Styled.GridTitle>
          <Styled.GridTitle>Cargo</Styled.GridTitle>
          <Styled.GridTitle>Bloqueado</Styled.GridTitle>
          <Styled.GridTitle>Ativo</Styled.GridTitle>
          {/* <Styled.GridTitle>
          
          </Styled.GridTitle> */}
        </Styled.GridTitleContainer>
        {userList.map((data, index) => {
          return (
            <Styled.GridCard key={index + 1}>
              <Styled.UserListContainer key={data.id_user}>
                <Styled.PromotorDiv>
                  <Styled.UserImg
                    onClick={() => goToEditUserPage(data.id_user)}
                    src={data?.file?.uri || iconUser}
                    alt="user"
                  />
                  <Styled.UserListText>
                    {data.fullname.toLowerCase() || ""}
                  </Styled.UserListText>
                </Styled.PromotorDiv>
                <Styled.UserListText style={{ textTransform: "lowercase" }}>
                  {data.email || ""}
                </Styled.UserListText>
                <Styled.UserListText>{data.projeto || ""}</Styled.UserListText>
                <Styled.UserListText>
                  {data.userType?.name || ""}
                </Styled.UserListText>
                <Styled.UserListText>
                  <Toggle
                    onChange={(e) =>
                      changeUserTemporaryBlock(e.target.checked, data)
                    }
                    checked={data.temporary_block}
                    icons={false}
                  />
                </Styled.UserListText>
                <Styled.UserListText>
                  <Toggle
                    onChange={(e) =>
                      changeUserActiveStatus(e.target.checked, data)
                    }
                    checked={data.active}
                    icons={false}
                  />
                </Styled.UserListText>
              </Styled.UserListContainer>
            </Styled.GridCard>
          );
        })}
      </Styled.MainCard>
    </Styled.Container>
  );
};

export default UserListV2;
