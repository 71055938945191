import React, { useState, useEffect, useContext } from "react";
import Toggle from "react-toggle";
import styled from "styled-components";
import Header from '../../components/Header';
import { Link, useHistory } from "react-router-dom";
import { useTable, useFilters, useGlobalFilter } from "react-table";
import { Settings } from '@material-ui/icons';
import api from "../../services/api";
import StoreContext from '../../context/Context'
import Search from '../../components/Search';
import LoaderSpin from '../../components/LoaderSpin';

import swal from 'sweetalert';

import "./styles.css";

function SelectColumnFilter({ column: { filterValue, setFilter } }, options) {
  options.labels.unshift("Todos");
  options.values.unshift("");

  return (
    <select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      {options.values?.map((elm, index) => (
        <option value={elm} key={index+10}>{options.labels[index]}</option>
      ))}
    </select>
  );
}

function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  // const count = preFilteredRows.length;

  return (
    <input
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      placeholder={``}
    />
  );
}

const Styles = styled.div`
  display: block;
  max-width: 100%;
  width: 100%;

  .tableWrap {
    display: block;
    max-width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    border-bottom: 1px solid black;
  }

  table {
    border-collapse: separate !important;
    width: 100%;
    border-spacing: 0;
  
    thead {
      tr{
        background-color:#eee;
        border-radius:10px;
        -moz-border-radius: 3px;
        -webkit-border-radius: 3px;

         th{
            border-width:0px;
            :first-child { border-top-left-radius: 15px;border-bottom-left-radius: 15px; }
            :last-child { border-top-right-radius: 15px;border-bottom-right-radius: 15px; }
         }
      }
    }
    th,
    td {
      width: 0%;  
      &.collapse {
        width: 0.0000000001%;
      } 
      :last-child {
        border-right: 0;
      }
    }
  }

  .pagination {
    padding: 0.5rem;
  }
`;

function ProductsList({ userProject }) {
  const { userInfoStore } = useContext(StoreContext);
  const { userProjectStore } = useContext(StoreContext);

  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);

  const [searchTimeout, setSearchTimeout] = useState('');
  const [queryParameters, setQueryParameters] = useState({})

  const [loading, setLoading] = useState(false);


  const History = useHistory();

  const autoSearch = async (search) => {
    clearTimeout(searchTimeout);

    setSearchTimeout(setTimeout(async () => {
      const newParameters = queryParameters;
      
      newParameters.code = search;
      
      setQueryParameters(newParameters);

      getData();

    }, 2500))
  }

  async function toogleStock(elm, index, e) {
    try {
      await api.put("/categories/" + elm.id, { stock: !elm.stock });

      setData(data.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())));

      elm.stock = !elm.stock;

      if (elm.stock) {
        swal(`Estoque ativado com sucesso!`, {
          icon: "success",
          button: "Fechar"
        });
      } else {
        swal(`Estoque desativado com sucesso!`, {
          icon: "success",
          button: "Fechar"
        });
      }

    } catch (err) {
      console.log(err);
    }

    getData();
  }

  async function toogle(elm, index, e) {
    try {
      await api.put("/categories/" + elm.id, { active: !elm.active });
      
      setData(data.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())));
      
      elm.active = !elm.active;

      if (elm.active) {
        swal(`Categoria ativada com sucesso!`, {
          icon: "success",
          button: "Fechar"
        });
      } else {
        swal(`Categoria desativada com sucesso!`, {
          icon: "success",
          button: "Fechar"
        });
      }

    } catch (err) {
      console.log(err);
    }
    getData();
  }

  async function getData() {
    setLoading(true)

    function filter(rows, id, filterValue) {
      return rows.filter((row, index) => {
        const rowValue = row.values[id];
        return rowValue !== undefined
          ? data[index][id] == filterValue
          : true;
      });

    }
    
    try {
      const { data } = await api.get("/categories", { params: { id_project: userProjectStore.id, ...queryParameters } });
      
      if (data.length > 0) {
        setData(data.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())));
      } else {
        setData([])
      }
      
      setLoading(false)

      setColumns([
        {
          Header: "Nome",
          accessor: (elm, index) => {
            return (
            <h4 onClick={() => {
  
            }} >{elm.name}</h4>)
          },
        },
        {
          Header: "Status",
          accessor: (elm, index) => {  
            return (
              <>
                <Toggle
                  disabled={!userInfoStore.permissions.includes('products_edit')}               
                  onChange={(e) => {
                    toogle(elm, index, e);
                  }}
                  icons={false}
                  checked={elm.active}
                />
              </>
            )
          },
          id: "status",
          Filter: (colums) =>
            SelectColumnFilter(colums, {
              values: ["true", "false"],
              labels: ["Ativo", "Desabilitado"],
            }),
          filter: filter,
          collapse: true,
        },
        {
          Header: "Estoque",
          accessor: (elm, index) => {
            return (
            <>
              <Toggle
                disabled={!userInfoStore.permissions.includes('products_edit')}
                checked={elm.stock}
                onChange={(e) => {
                    toogleStock(elm, index,e);
                }}
                icons={false}
              />
            </>
            )
          },
          id: "stock",
          Filter: (colums) =>
            SelectColumnFilter(colums, {
              values: ["true", "false"],
              labels: ["Ativo", "Desabilitado"],
            }),
          filter: filter,
          collapse: true,
        },
        {
          Header: "Tipo de Cadastro",
          accessor: elm => (
            <b> {elm.document_type?.join('|')}</b>
          ),
  
        },
        {
          Header: "Data da criação",
          accessor: (elm, index) => (
            <h4 onClick={() => {
  
            }} >{new Date(elm.created_at).toLocaleString([], { dateStyle: 'short', timeStyle: 'short' })}</h4>
          ),
        },
        {
          Header: "Última atualização",
          accessor: (elm, index) => (
            <h4 onClick={() => {
  
            }} >{new Date(elm.updated_at).toLocaleString([], { dateStyle: 'short', timeStyle: 'short' })}</h4>
          ),
        },
        {
          Header: " ",
          accessor: (elm, index) => Options(index, elm),
          disableFilters: true,
        },
      ]);

    } catch (error) {
      setLoading(false)
    }      
  }

  useEffect(() => {
    getData();
  }, [userProject]);


  const Options = (index, elm) => {
    return (
      userInfoStore.permissions.includes('productCategory_edit') ? 
      (
        <Link to={{
          pathname: '/CategoryDetails',
          state: { id: elm.id }
        }}>
          <Settings style={{ fill: '#ee5928', fontSize: 35 }} />
        </Link>
      ) : 
      (
        <Settings style={{ fill: '#ccc', fontSize: 35 }} />
      )
    )
  };


  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );
  const filterTypes = () => ({
    // Add a new fuzzyTextFilterFn filter type.
    StatusSelect: (rows, id, filterValue) => {
      return rows.filter((row, index) => {
        const rowValue = row.values[id];
        return rowValue !== undefined ? data[index][id] == filterValue : true;
      });
    },
    // Or, override the default text filter to use
    // "startWith"
    text: (rows, id, filterValue) => {
      return rows.filter((row) => {
        const rowValue = row.values[id];
        return rowValue !== undefined
          ? String(rowValue)
            .toLowerCase()
            .startsWith(String(filterValue).toLowerCase())
          : true;
      });
    },
  });




  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    { columns, defaultColumn, data, filterTypes },
    useFilters,
    useGlobalFilter
  );

  return (
    <>

      {/* <div className="fieldsMainContainer"> */}
        <Header title='Categorias de Produtos' buttons={
          () => <div className='cotaniner_search'>                
                <Search
                    className='searchInputUserList' 
                    placeholder='Pesquisar por categoria'
                    showButton={true}
                    list={data}
                    onChange={(value) => {                    
                      autoSearch(value);
                    }}
                    searchField={['code']}                     
                />
                {
                  userInfoStore.permissions.includes('productCategory_create') && 
                  <button onClick={() => History.push('/CreateCategory')} className="btnProducts">Nova categoria</button>
                }
            </div>
        } />
        {/* <div className="FieldFilters">
        {headerGroups.map((headerGroup) => (
          <>
            {headerGroup.headers.map((column) =>
              column.canFilter ? (
                <div
                  className={
                    "FilterContainer " +
                    column.render("Header").replaceAll(" ", "")
                  }
                >
                  <p>{column.render("Header")}</p>
                  <div>{column.render("Filter")}</div>
                </div>
              ) : null
            )}
          </>
        ))}
      </div> */} 
        {
          loading ? <LoaderSpin type={'Grid'} enable={loading} /> :
          <Styles>
            <table {...getTableProps()} className="fieldsTable">
              <thead>
                {headerGroups.map((headerGroup, index) => (
                  <tr {...headerGroup.getHeaderGroupProps()} key={index+5}>
                    {headerGroup.headers.map((column, indexTwo) => (
                      <th {...column.getHeaderProps()}  key={indexTwo+2+6}>
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>              
              <tbody {...getTableBodyProps()}>
                  {rows.map((row, rowIndex) => {
                    prepareRow(row);
                    return (
                      <tr style={{ cursor: 'pointer' }} className="fieldsTableRow" {...row.getRowProps()} key={rowIndex+3+1}>
                        {row.cells.map((cell, index) => {
                          return (                            
                            <td onClick={() => {
                                if (row.cells.length - 1 != index && index != 1 && index != 2 && cell.row.original.stock)
                                  History.push('/ProductsDetails', data[rowIndex])

                              }} 
                              {...cell.getCellProps()}
                              key={index+4+2}
                            >
                              {cell.render("Cell")}
                            </td>                            
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>            
            </table>
          </Styles>
        }
      {/* </div> */}
    </>
  );
}

export default ProductsList;
