import React, { useEffect } from 'react'

import CloseIcon from '@material-ui/icons/Close'

import * as S from './styled'
import CheckIcon from '@material-ui/icons/Check';
import HelpIcon from '@material-ui/icons/Help';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';


const SaleModal = (props) => {
  const { 
    saleObservation, 
    setSaleObservation,
    active, 
    contentMain = "", 
    titleContent = "", 
    contentForm = "", 
    handleClickClose = () => {}, 
    handleClickSave = () => {},
    pendSale = false,
    subtitle='',
  } = props

  useEffect(() => {
    if(pendSale && active) {
      setSaleObservation('')
    }
  },[pendSale, setSaleObservation, active])

  return(
    <>
      { active  && (
        <>
          <S.Root >
            <S.Modal approval={titleContent.includes('aprovada')}>
              <S.Container>
                <S.Header>
                  <button onClick={handleClickClose}>
                    <CloseIcon style={{width:'20px', height:'20px'}} />
                  </button>
                </S.Header>
                <S.IconDiv>
                  <HelpIcon style={{color:'#EE5928', width:'80px', height:'80px'}} />
                </S.IconDiv>
                <S.TitleDiv>
                  {
                    !titleContent.includes('reverter') ? (
                      titleContent.includes('novo Histórico') ?
                        <S.Title>Deseja realmente criar uma nova observação para a venda?</S.Title>
                      :
                        <S.Title>Deseja realmente alterar esta venda para {titleContent}?</S.Title>
                    ):
                    (
                      <S.Title>Deseja realmente retroceder para fase de aprovação?</S.Title>
                    )
                  }
                </S.TitleDiv>
                <S.SubTitleDiv>
                  {
                    !(titleContent.includes('aprovada')) && !titleContent.includes('reverter') && 
                      titleContent.includes('novo Histórico') ?
                      <S.SubTitle>
                        Será criado uma nova observação para o status atual da venda!          

                        <span
                          style={{
                            wordBreak: 'break-word',
                            width: '480px',
                            display: 'inline-block',
                            lineHeight: '23px',
                            marginTop: '10px'
                          }}
                        >
                          Lembre-se que no app aparecerá somente a última observação registrada, 
                          deste modo se você tiver esquecido algum detalhe na observação anterior, reescreva a informação por completo, 
                          selecionando também todas as tags necessárias.
                        </span>    
                      </S.SubTitle>
                    : 
                   !titleContent.includes('reverter') && 
                    <S.SubTitle>
                      Selecione as tags ou descreva no campo de<br />observações o motivo de {subtitle}.
                    </S.SubTitle>
                  }
                </S.SubTitleDiv>
                {!(titleContent.includes('novo Histórico')) &&
                  <S.Main>
                    {contentForm}
                    <S.DivMain>
                      {!titleContent.includes('reverter') && contentMain}
                      <div style={{ width: '100%' }} >
                        {!contentForm && !titleContent.includes('reverter') && 
                        <S.MyLabel for="saleObservation">Observações</S.MyLabel> 
                        }
                        {!contentForm && !titleContent.includes('reverter') &&
                          <S.MyInputModal 
                            value={saleObservation} 
                            isEditing 
                            id="saleObservation" 
                            onChange={(e) => setSaleObservation(e.target.value)} />
                        }                     
                        {(titleContent.includes('reverter') &&  
                          <span style={{ textAlign: 'center', color: '#8F8F8F', fontSize: 18, marginBottom: 25, lineHeight: '30px' }}>
                            Ao confirmar, o registro retornará para a 
                            fase de aprovação aguardando e poderá ser pendenciado com as tags de pendência 
                            de aprovação
                          </span>)}
                      </div>
                    </S.DivMain>
                  </S.Main>
                }
                {titleContent.includes('novo Histórico') && 
                  <S.Main>
                    {contentForm}
                    <S.DivMain>                     
                      {contentMain}
                      <div style={{ width: '100%' }} >
                        <S.MyLabel for="saleObservation">Observações</S.MyLabel>                       
                        <S.MyInputModal 
                          value={saleObservation} 
                          isEditing 
                          id="saleObservation" 
                          onChange={(e) => setSaleObservation(e.target.value)}
                        />        
                      </div>
                    </S.DivMain>
                  </S.Main>
                }
                <S.Footer>
                  <S.ButtonModal return onClick={handleClickClose}>
                    <KeyboardReturnIcon /> Voltar
                  </S.ButtonModal> 
                  <S.ButtonModal onClick={handleClickSave}>
                    <CheckIcon /> Salvar
                  </S.ButtonModal> 
                </S.Footer>               
              </S.Container>
            </S.Modal>
          </S.Root>
        </>
        )
      }
    </>
  )
} 

export default SaleModal