import React, { useState, useEffect,useCallback } from 'react';
import Header from '../../components/Header'
import api from '../../services/api';

import { useParams, Link } from "react-router-dom";
import './styles.css'


const ListContent = ({ name, obj, idP, permissions }) => {


  const changePermission = async (id, check) => {

    if (check) {
      permissions[id] = true;
    } else {
      delete permissions[id]
    }
    await api.put('/roles/' + idP, { permissions: Object.keys(permissions) })


  }
  return (
    <div className='backgroundShadow' >

      <div className='rowListContent'>

        <span className='flex listContent Permission'>{name.split('_')[0]}</span>
        <span className='flex listContent Section'>{name.split('_')[1] ? name.split('_')[1] : '---'}</span>
        <div className='flex'>
          <input type='checkbox' disabled={!obj.read} defaultChecked={obj.read ? !!permissions[obj.read.id] : false} onChange={(elm) => changePermission(obj.read.id, elm.target.checked)} className='listContent Access' />
        </div>
        <div className='flex'>
          <input type='checkbox' disabled={!obj.seeAll} defaultChecked={obj.seeAll ? !!permissions[obj.seeAll.id] : false} onChange={(elm) => changePermission(obj.seeAll.id, elm.target.checked)} className='listContent All' />
        </div>
        <div className='flex'>
          <input type='checkbox' disabled={!obj.seeBelow} defaultChecked={obj.seeBelow ? !!permissions[obj.seeBelow.id] : false} onChange={(elm) => changePermission(obj.seeBelow.id, elm.target.checked)} className='listContent Below' />
        </div>
        <div className='flex'>
          <input type='checkbox' disabled={!obj.create} defaultChecked={obj.create ? !!permissions[obj.create.id] : false} onChange={(elm) => changePermission(obj.create.id, elm.target.checked)} className='listContent Creation' />
        </div>
        <div className='flex'>
          <input type='checkbox' disabled={!obj.edit} defaultChecked={obj.edit ? !!permissions[obj.edit.id] : false} onChange={(elm) => changePermission(obj.edit.id, elm.target.checked)} className='listContent Edit' />
        </div>
        <div className='flex'>
          <input type='checkbox' disabled={!obj.delete} defaultChecked={obj.delete ? !!permissions[obj.delete.id] : false} onChange={(elm) => changePermission(obj.delete.id, elm.target.checked)} className='listContent Delete' />
        </div>



      </div>
    </div>


  )


}

function PermissionsEdit() {

  const { id } = useParams()

  const [allPermissions, setAllPermissions] = useState([])
  const [roleNumberObject, setRoleNumberObject] = useState(null)
  const [roleInfo, setRoleInfo] = useState([])
  // const [edit, setEdit] = useState(false);

  const getAllPermissions = async () => {

    const { data } = await api.get('/permissions')

    setAllPermissions(data)

  }
  const getRole = useCallback(async () => {

    const { data } = await api.get('/roles/' + id)

    setRoleInfo(data)
    const permissions = {}
    data.permissions.forEach(elm => {
      permissions[elm.id] = true;
    });

    setRoleNumberObject(permissions)
  }, [id])

  useEffect(() => {
    getRole()
    getAllPermissions()

  }, [getRole])



  return (
    <>
      <Header 
        title={roleInfo.name} 
        buttons={
            () => (
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row', marginLeft: "2vw" }}>
                  <div style={{ cursor: 'pointer' }}>
                    <Link to='/Settings?s=permissions'>
                      <buttons style={{ height: 35, display: 'flex', flexDirection: 'row' }} className="btnBack"> 
                          Voltar                        
                      </buttons>
                    </Link>
                  </div>
              </div>              
            )
          }  
      />
     

      <div className='mainContainerPermissions'>
        <div className='listPermissionsContainer'>
          <div className='titlesPermissions'>
            <span className='titlePermission PermissionsPermission'>Permissão</span>
            <span className='titlePermission PermissionsSession'>Seção</span>
            <span className='titlePermission PermissionsAccess'>Acesso</span>
            <span className='titlePermission PermissionsAll'>Ver Tudo</span>
            <span className='titlePermission PermissionsAll'>Ver Abaixo</span>
            <span className='titlePermission PermissionsCreate'>Criação</span>
            <span className='titlePermission PermissionsEdit'>Edição</span>
            <span className='titlePermission PermissionsDelete'>Deleção</span>
          </div>
          {roleNumberObject && Object.keys(allPermissions)?.map((elm) => {

            return <ListContent
              permissions={roleNumberObject}
              idP={id}
              name={elm}
              obj={allPermissions[elm]}
              created={new Date(elm.created_at).toLocaleString([], { dateStyle: 'short', timeStyle: 'short' })}
              att={new Date(elm.updated_at).toLocaleString([], { dateStyle: 'short', timeStyle: 'short' })} />
          })}
        </div>
      </div>
    </>
  )
}

export default PermissionsEdit;