import React, {useState, useEffect, useCallback} from 'react'
import auth from '../../services/auth'
import api from '../../services/api';
import {useHistory} from "react-router-dom";

import { useForm } from "react-hook-form";

import * as S from './login.styles'
import { Spinner } from '../../components'
import Logo from '../../utils/logoPro.svg'
import { toast } from 'react-toastify';


export const Login = () => {
  const [loading, setLoading] = useState(false)
  const [checked, setChecked] = useState(false)
  const [token, setToken] = useState('')
  const history = useHistory()

  const getUser = useCallback(async() => {
    setLoading(true)

    try {
      const response = await api.get('/user')

      setLoading(false)

      const user = response.data[0];

      localStorage.setItem('user',  JSON.stringify({...user,logs:undefined}));
      localStorage.setItem('project', JSON.stringify(user?.projects));
      localStorage.setItem('permission', user.permissions)
      
      history.push("/Customer")
    } catch (err) {
      const savedFiltersInStorage = sessionStorage.getItem('savedFilters') || localStorage.getItem('savedFilters')
      const customer_sale_filter = sessionStorage.getItem('customerSaleFilter') || localStorage.getItem('customerSaleFilter')

      const data = err?.response?.data
      
      const message = data?.message ?? 'Não possível realizar login automaticamente!'
      
      setLoading(false)
     
      toast.error(message)

      if (savedFiltersInStorage) {
        localStorage.setItem('savedFilters', savedFiltersInStorage)
        sessionStorage.setItem('savedFilters', savedFiltersInStorage)
      }

      if (customer_sale_filter) {
        localStorage.setItem('customerSaleFilter', customer_sale_filter)
        sessionStorage.setItem('customerSaleFilter', customer_sale_filter)
      }

    }
  }, [history])

  useEffect(() => {
    const token = localStorage.getItem('token')

    if (token) {
      setToken(token)
    }
  },[])

  useEffect(() => {
    if (token) {
      getUser()
    }
  },[token, getUser])

  const { register, handleSubmit, formState: { errors }} = useForm();

  const handleCheckboxChange = () => {
    setChecked(!checked)
  }
  
  function validEmail(email) {
    const re = /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
    return re.exec(String(email).toLowerCase())[0];
  }

  const onSubmit = async (data) => {    
    try {
      const savedFiltersInStorage = sessionStorage.getItem('savedFilters') || localStorage.getItem('savedFilters')

      const normalizedEmail = validEmail(data.email)

      const response = await auth.login({...data, email: normalizedEmail })

      const { data: responseData} = response

      const { token: responseToken, refreshToken } = responseData
     
      localStorage.setItem('refresh', refreshToken)

      setToken(responseToken)

      if (checked) {
        localStorage.setItem('token', responseToken)
      } else {
        sessionStorage.setItem('token', responseToken)
      }

      if (savedFiltersInStorage) {
        localStorage.setItem('savedFilters', savedFiltersInStorage)
        sessionStorage.setItem('savedFilters', savedFiltersInStorage)
      }

    }catch(e) {
      if (e?.response?.status === 401) {
        const message = e.response?.data?.message
        toast.error(message ? message : "E-mail ou Senha inválidos")
      } else {
        toast.error('Erro no login')
      }
    }
  }

  return(
    <>
      <S.Background>
        <S.SideWhite/>
        <S.SideOrange/>
        <S.Container>
          <S.BoxLogo>
            <img src={Logo}  alt="Logo Prisma"/>
          </S.BoxLogo>
          <S.BoxLogin>
            <S.Login>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="divInputandButton">
                  <S.Label for="email" opacity>E-mail</S.Label>
                  <S.Input
                    id="email"
                    placeholder="Digite seu e-mail"
                    {...register('email', 
                      { required: true, 
                        pattern: {
                        value: /[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}/i,
                    }, })} 
                  />
                  {errors.email && errors.email.type === "required" && <S.PValidator>Insira um e-mail</S.PValidator>}
                  {errors.email && errors.email.type === "pattern" && <S.PValidator>Insira um e-mail válido</S.PValidator> }
                  <S.Label for="password" opacity>Senha</S.Label>
                  <S.Input 
                  {...register('password',
                    {required: true}
                  )} 
                  type="password" 
                  id="password" 
                  placeholder="Digite sua Senha" 
                  />
                  {errors.password && errors.password.type === "required" && <S.PValidator>Insira uma senha</S.PValidator>}
                </div>
                

                <S.BoxCheck>
                  <S.Label>
                    <S.MyCheckbox
                      checked={checked}
                      onChange={handleCheckboxChange}
                    />
                    <p>Manter Conectado</p>
                  </S.Label>
                  <div className="rememberPassword">
                    <a href="/forgotpassword">Esqueci minha senha</a>
                  </div>
                </S.BoxCheck>
                <div className="divInputandButton"> 
                  <S.MyButton centered={true}>
                    {loading && <Spinner small/>}
                    {!loading && <>Enviar</>} 
                  </S.MyButton>
                </div>
              </form>
            </S.Login>
          </S.BoxLogin>
        </S.Container>
      </S.Background>

    </>
  )

}