import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import * as squareClient from '../../assets/squareClient.png';
import * as plus from '../../assets/icons/schedule/Novo.png';
import * as redFire from '../../assets/redFire.png';
import * as blueFire from '../../assets/blueFire.png';
import * as yellowFire from '../../assets/yellowFire.png';
import purpleFire from '../../assets/purpleFire.svg';
import { useHistory, useLocation } from 'react-router-dom';
import * as datefns from 'date-fns';
import pt from 'date-fns/locale/pt-BR';
import ModalForm from '../../components/ModalForm';
import api from '../../services/api';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import Header from '../../components/Header';
import StoreContext from '../../context/Context';
import './styles.css';
import styled from 'styled-components';
import swal from 'sweetalert';
import * as S from './styled';
import moment from 'moment';

require('moment/locale/pt-br');

const _localizer = momentLocalizer(moment);

const BigCalendarStyled = styled.div`
  div.rbc-time-content {
    flex-direction: row;
  }
`;

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const SchedulesList = (props) => {
  let history = useHistory();

  let query = useQuery();

  const { userProjectStore, userInfoStore } = useContext(StoreContext);
  const [searchTiemout, setSearchTiemout] = useState();
  const [change, setChange] = useState('Nenhum');
  const [agendamentoState, setAgendamentoState] = useState('Nenhum');
  const [statusState, setStatusState] = useState('Nenhum');
  const [usuarioState, setUsuarioState] = useState('Nenhum');
  const [userID, setUserID] = useState('');
  const [users, setUsers] = useState([]);
  const [eventList, setEventList] = useState([]);
  const [usuario, setUsuario] = useState('Usuários');
  const [status, setStatus] = useState('status');
  const [agendamentos, setAgendamentos] = useState('Tipo de Agendamento');
  const [modal, setModal] = useState(false);
  const [modalContent, setModalContent] = useState(false);

  const utcToNormalizedData = (date) => {
    return datefns.parseISO(date.replace('T', ' ').replace('Z', ''));
  };

  const styleCalendar = (event, start, end, isSelected) => {
    var style = {
      backgroundColor: event.color,
      height: '20px',
      marginBottom: '-1px',
      borderRadius: '6px',
      opacity: 1,
      color: 'white !important',
      border: '1px solid ',
      borderColor: 'white',
      display: 'block',
      textAlign: 'left',
    };
    return {
      style: style,
    };
  };

  const handleVerifyFilter = useCallback(() => {
    setChange(false);

    //fazer com  usuarioState
    api
      .get('/scheduleFilter', {
        params: {
          project: userProjectStore.id,
          usuario: userID == 'Nenhum' ? undefined : userID,
          agendamento:
            agendamentoState == 'Nenhum' ? undefined : agendamentoState,
          status: statusState == 'Nenhum' ? undefined : statusState,
        },
      })
      .then((res) => {
        const formatedDataToCalendar = res.data.map((e) => {
          if (e.id_schedule_status === 1) {
            return {
              id_customer: e.id_customer,
              id_user: e.id,
              color: '#5497fc',
              allDay: false,
              // start: new Date('2021/03/15 10:00'),
              // end: new Date('2021/03/15 11:00'),
              start: utcToNormalizedData(e.date),
              end: utcToNormalizedData(e.date),
              scheduleType: e.id_schedule_status,
              title: e.to,
              reson: e.return_reason,
              from: e.from,
              para: e.to,
              status: e.id_operation,
              customerDescription: e.customerDescription,
              address: e.address,
              addressNumber: e.address_number,
              addressZip: e.address_zip,
              addressDistrict: e.address_district,
              addressState: e.address_state,
              createdAt: utcToNormalizedData(e.created_at),
              history: e.history.map((el) => el),
              tags: e?.tags ?? [],
            };
          }

          if (e.id_schedule_status === 2) {
            return {
              id_customer: e.id_customer,
              id_user: e.id,
              color: '#fcb243',
              allDay: false,
              start: utcToNormalizedData(e.date),
              end: utcToNormalizedData(e.date),
              scheduleType: e.id_schedule_status,
              title: e.to,
              reson: e.return_reason,
              from: e.from,
              para: e.to,
              status: e.id_operation,
              customerDescription: e.customerDescription,
              address: e.address,
              addressNumber: e.address_number,
              addressZip: e.address_zip,
              addressDistrict: e.address_district,
              addressState: e.address_state,
              createdAt: utcToNormalizedData(e.created_at),
              history: e.history.map((el) => el),
              tags: e?.tags ?? [],
            };
          }

          if (e.id_schedule_status === 3) {
            return {
              id_customer: e.id_customer,
              id_user: e.id,
              color: '#fc434e',
              allDay: false,
              start: utcToNormalizedData(e.date),
              end: utcToNormalizedData(e.date),
              scheduleType: e.id_schedule_status,
              title: e.to,
              reson: e.return_reason,
              from: e.from,
              para: e.to,
              status: e.id_operation,
              customerDescription: e.customerDescription,
              address: e.address,
              addressNumber: e.address_number,
              addressZip: e.address_zip,
              addressDistrict: e.address_district,
              addressState: e.address_state,
              createdAt: utcToNormalizedData(e.created_at),
              history: e.history.map((el) => el),
              tags: e?.tags ?? [],
            };
          }

          if (e.id_schedule_status === 4) {
            return {
              id_customer: e.id_customer,
              id_user: e.id,
              color: '#BD01FF',
              allDay: false,
              start: utcToNormalizedData(e.date),
              end: utcToNormalizedData(e.date),
              scheduleType: e.id_schedule_status,
              title: e.to,
              reson: e.return_reason,
              from: e.from,
              para: e.to,
              status: e.id_operation,
              customerDescription: e.customerDescription,
              address: e.address,
              addressNumber: e.address_number,
              addressZip: e.address_zip,
              addressDistrict: e.address_district,
              addressState: e.address_state,
              createdAt: utcToNormalizedData(e.created_at),
              history: e.history.map((el) => el),
              tags: e?.tags ?? [],
            };
          }
          return null;
        });

        return setEventList(formatedDataToCalendar);
      });
  }, [userProjectStore.id, userID, agendamentoState, statusState]);

  useEffect(() => {
    api
      .get('/schedulesUsers', { params: { project: userProjectStore.id } })
      .then((res) => {
        setUsers(res.data);
      });

    handleVerifyFilter();
  }, [handleVerifyFilter, userProjectStore.id]);

  const handleChangeToCreate = () => {
    history.push('/scheduleCreate');
  };

  const handleSetState = ({ agendamento, status, usuario }) => {
    if (!!usuario) {
      setUsuarioState(usuario);
    }

    if (!!agendamento) {
      setAgendamentoState(agendamento);
    }

    if (!!status) {
      setStatusState(status);
    }

    clearTimeout(searchTiemout);

    setSearchTiemout(
      setTimeout(async () => {
        setChange(true);
      }, 1000)
    );
  };

  useEffect(() => {
    if (usuarioState == 'Nenhum') {
      return setUserID('Nenhum');
    }

    if (usuarioState != 'Nenhum') {
      api.get('userByName', { params: { name: usuarioState } }).then((res) => {
        return setUserID(res.data[0].id);
      });
    }
  }, [usuarioState]);

  useEffect(() => {
    handleVerifyFilter();
  }, [change, handleVerifyFilter]);

  const handleModal = (event) => {
    setModal(true);

    setModalContent(event);
  };

  const handleSearchCustomer = (e) => {
    return window.open(`CustomerInfo/${e}`, '_blank');
  };

  async function cancelSchedule() {
    try {
      const id_schedule = modalContent.history[0].id_schedule ?? null;

      if (id_schedule) {
        await api.delete(`schedules/${id_schedule}`);

        setModal(false);

        swal(`Agendamento deletado com sucesso!`, {
          icon: 'success',
          button: 'Fechar',
        });

        handleVerifyFilter();
      }
    } catch {
      setModal(false);

      swal(`Ocorreu um erro ao deletar agendamento!`, {
        icon: 'info',
        button: 'Fechar',
      });
    }
  }

  useEffect(() => {
    let id_schedule = query.get('id_schedule') ?? null;
    let id_customer = query.get('id_customer') ?? null;

    if (id_schedule && id_customer) {
      const customer_schedule = eventList
        .filter((elm) => elm.id_customer === id_customer)
        .find((elm) => elm.history[0]?.id_schedule === id_schedule);

      if (customer_schedule) {
        handleModal(customer_schedule);
        history.replace({ search: '' });
      }
    }
  }, [eventList]);

  const schedule_type_colors = {
    1: '#333',
    2: '#12aa4f',
    3: '#ee5928 ',
  };

  const scheduleStatus = {
    1: blueFire,
    2: yellowFire,
    3: redFire,
    4: purpleFire,
  };

  const scheduleStatusColor = {
    1: '#0E80FC',
    2: '#FDB30D',
    3: '#D63309',
    4: '#BD01FF',
  };

  const scheduleStatusText = {
    1: 'Negociação Fria',
    2: 'Negociação Morna',
    3: 'Negociação Quente',
    4: 'Evento',
  };

  return (
    <>
      <Header
        buttons={() => (
          <div>
            <div
              style={{ display: 'flex', flexDirection: 'row', width: '100%' }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <div className="schedules_filter">
                  <select
                    value={usuario}
                    className="inputRounded2 "
                    onChange={(e) => {
                      if (e.target.value != 'Nenhum') {
                        handleSetState({ usuario: e.target.value });
                        return setUsuario(e.target.value);
                      }
                      handleSetState({ usuario: 'Nenhum' });
                      setUsuarioState('Nenhum');
                      return setUsuario('Usuários');
                    }}
                  >
                    {usuario == 'Usuários' ? (
                      <option value="Usuários">Usuários</option>
                    ) : (
                      <option value="Nenhum">Nenhum</option>
                    )}

                    {users.length > 0
                      ? users.map((e, index) => (
                          <option key={index} value={e.name}>
                            {e.name}
                          </option>
                        ))
                      : null}
                  </select>
                </div>
                <div className="schedules_filter">
                  <select
                    value={agendamentos}
                    className="inputRounded2"
                    onChange={(e) => {
                      if (e.target.value != 'Nenhum') {
                        if (e.target.value == 'Negociação Fria') {
                          handleSetState({ agendamento: 1 });
                        } else if (e.target.value == 'Negociação Morna') {
                          handleSetState({ agendamento: 2 });
                        } else if (e.target.value == 'Negociação Quente') {
                          handleSetState({ agendamento: 3 });
                        } else if (e.target.value == 'Evento') {
                          handleSetState({ agendamento: 4 });
                        }
                        return setAgendamentos(e.target.value);
                      }

                      handleSetState({ agendamento: 'Nenhum' });

                      setAgendamentoState('Nenhum');

                      return setAgendamentos('Tipo de Agendamento');
                    }}
                  >
                    {agendamentos == 'Tipo de Agendamento' ? (
                      <option value="Tipo de Agendamento">
                        Tipo de Agendamento
                      </option>
                    ) : (
                      <option value="Nenhum">Nenhum</option>
                    )}
                    <option value="Negociação Quente">Negociação Quente</option>
                    <option value="Negociação Morna">Negociação Morna</option>
                    <option value="Negociação Fria">Negociação Fria</option>
                    <option value="Evento">Evento</option>
                  </select>
                </div>
                <div className="schedules_filter">
                  <select
                    value={status}
                    className="inputRounded2"
                    onChange={(e) => {
                      if (e.target.value != 'Nenhum') {
                        if (e.target.value == 'Cancelado') {
                          handleSetState({ status: 1 });
                        } else if (e.target.value == 'Concluido') {
                          handleSetState({ status: 2 });
                        } else if (e.target.value == 'Aberto') {
                          handleSetState({ status: 3 });
                        }
                        return setStatus(e.target.value);
                      }
                      handleSetState({ status: 'Nenhum' });
                      setStatusState('Nenhum');
                      return setStatus('status');
                    }}
                  >
                    {status == 'status' ? (
                      <option value="Status">Status</option>
                    ) : (
                      <option value="Nenhum">Nenhum</option>
                    )}
                    <option value="Concluido">Concluido</option>
                    <option value="Aberto">Aberto</option>
                    <option value="Cancelado">Cancelado</option>
                  </select>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'row',
                  marginLeft: '2vw',
                }}
              >
                <div style={{ cursor: 'pointer' }}>
                  <buttons
                    style={{
                      height: 40,
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                    className="btnStandart"
                    onClick={() => handleChangeToCreate()}
                  >
                    <img alt="Plus" src={plus} style={{ marginRight: 3 }} />
                    Novo Agendamento
                  </buttons>
                </div>
              </div>
            </div>
          </div>
        )}
      />
      <div style={{ width: '100%', height: '100%' }}>
        <BigCalendarStyled>
          <Calendar
            key="1"
            localizer={_localizer}
            events={eventList}
            startAccessor="start"
            endAccessor="end"
            style={{ height: '90vh' }}
            eventPropGetter={styleCalendar}
            onSelectEvent={(event) => handleModal(event)}
            messages={{
              next: 'Próximo',
              previous: 'Anterior',
              today: 'Hoje',
              month: 'Mês',
              week: 'Semana',
              day: 'Dia',
              date: 'Data',
              time: 'Horário',
              event: 'Cliente',
              noEventsInRange: 'Não há eventos neste intervalo.',
              showMore: (count) => `+ ${count} agendamentos no dia`,
            }}
          />
        </BigCalendarStyled>
      </div>

      <div>
        <ModalForm
          close={() => setModal(false)}
          showAlert={modal}
          customButton={() => <div></div>}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              height: '100%',
              marginTop: 6,
            }}
          >
            <div style={{ height: '100%', width: '50%' }}>
              <div className="ModalBoxShadow">
                {modalContent.start != undefined ? (
                  // console.log('DATEEE.. ', datefns.format(new Date(2017, 10, 6), 'LLLL', { locale: pt })),
                  // console.log('DATEEE.. ', datefns.format(modalContent.start, 'LLLL', { locale: pt })),
                  // modalContent.start.toLocaleTimeString().slice(0, 5)   <---- horas
                  <div className="dateSchedule">
                    <div
                      className="dateScheduleContent"
                      style={{
                        color:
                          schedule_type_colors[modalContent.status] ??
                          '#ee5928',
                      }}
                    >
                      <div className="dateScheduleContentInfo">
                        <h1>
                          {modalContent.start.toLocaleDateString().slice(0, 2)}
                        </h1>
                        <h5>
                          {' '}
                          de{' '}
                          {datefns.format(modalContent.start, 'LLLL yyyy', {
                            locale: pt,
                          })}
                        </h5>
                        <h6 style={{ marginLeft: 3 }}>
                          às{' '}
                          {datefns.format(modalContent.start, 'HH:mm', {
                            locale: pt,
                          })}
                          h
                        </h6>
                      </div>
                      <div className="dateScheduleContentStatus">
                        {modalContent.status == 1 ? (
                          <div
                            style={{
                              marginLeft: 4,
                              color: 'white',
                              borderRadius: '50px',
                              backgroundColor: '#333',
                              fontWeight: 'bold',
                            }}
                          >
                            <p>Cancelado</p>
                          </div>
                        ) : modalContent.status == 2 ? (
                          <div
                            style={{
                              marginLeft: 4,
                              color: 'white',
                              borderRadius: '50px',
                              backgroundColor: '#12aa4f',
                              fontWeight: 'bold',
                            }}
                          >
                            <p>Concluído</p>
                          </div>
                        ) : (
                          <div
                            style={{
                              marginLeft: 4,
                              color: 'white',
                              borderRadius: '50px',
                              backgroundColor: '#ee5928',
                              fontWeight: 'bold',
                            }}
                          >
                            <p>Aberto</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
              <div
                className="contentModalBoxShadow"
                style={{ overflow: 'auto' }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    marginTop: '5px',
                  }}
                >
                  {/* https://staging.prismapro.com.br/CustomerInfo/ */}
                  {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                  <a
                    style={{
                      marginLeft: '10px',
                      color: '#ee5928',
                      cursor: 'pointer',
                      fontSize: '15px',
                      fontWeight: 'bold',
                      marginRight: '5px',
                    }}
                    onClick={() => {
                      handleSearchCustomer(modalContent.id_customer);
                    }}
                  >
                    Abrir detalhes do cliente
                  </a>
                  <img
                    alt="square"
                    style={{ width: '14px', height: '14px' }}
                    src={squareClient}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    fontSize: '14px',
                    lineHeight: 2,
                  }}
                >
                  <p
                    style={{
                      fontWeight: 'bold',
                      fontSize: '15px',
                      lineHeight: '20px',
                      textTransform: 'capitalize',
                    }}
                  >
                    {modalContent?.para?.toLowerCase()}
                  </p>
                  <p
                    style={{
                      marginTop: '-8px',
                      lineHeight: '20px',
                      marginBottom: '20px',
                    }}
                  >
                    {' '}
                    {modalContent.customerDescription}
                  </p>
                  <p
                    style={{
                      marginTop: '-12px',
                      lineHeight: '20px',
                      color: 'gray',
                      fontWeight: 'bold',
                      marginBottom: '20px',
                    }}
                  >
                    {' '}
                    Atribuído para: {modalContent.from}
                  </p>
                  <p style={{ marginTop: '-12px', color: 'gray' }}>
                    {' '}
                    {modalContent.address}, {modalContent.addressNumber}
                  </p>
                </div>
                <div style={{ fontSize: '14px' }}>
                  <p
                    style={{
                      marginTop: '-12px',
                      color: 'gray',
                      lineHeight: '20px',
                    }}
                  >
                    {modalContent.addressDistrict} - {modalContent.addressState}{' '}
                    - {modalContent.addressZip}
                  </p>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    fontSize: '14px',
                    color: 'gray',
                  }}
                  className="observation"
                >
                  <p
                    style={{
                      fontSize: '14px',
                      fontWeight: 'bold',
                      color: 'black',
                    }}
                  >
                    Observações:
                  </p>
                  {modalContent.history
                    ? modalContent.history.map((e, index) => {
                        if (e.id_schedule_history_type == 1) {
                          return (
                            <>
                              <div key={index} className="schedule_open">
                                <div
                                  style={{
                                    color: '#666666',
                                    fontWeight: 'bold',
                                  }}
                                >
                                  <p>
                                    {' '}
                                    {datefns.format(
                                      utcToNormalizedData(e.created_at),
                                      'dd/MM/yyyy'
                                    )}{' '}
                                    às{' '}
                                    {datefns.format(
                                      datefns.subHours(
                                        new Date(e.created_at),
                                        3
                                      ),
                                      'HH:mm',
                                      { locale: pt }
                                    )}{' '}
                                  </p>
                                  <p
                                    style={{
                                      lineHeight: '17px',
                                      marginBottom: '5px',
                                    }}
                                  >
                                    Aberto por - {e.user.username}
                                  </p>
                                </div>
                                {modalContent?.tags &&
                                  modalContent.tags.length > 0 && (
                                    <div className="schedule-container-tags">
                                      <div className="schedule-content-tags">
                                        {modalContent.tags.map((tag) => (
                                          <S.Tag
                                            key={`${tag}`}
                                            presentTag={true}
                                          >
                                            #{tag}
                                          </S.Tag>
                                        ))}
                                      </div>
                                    </div>
                                  )}
                                <p>{e.description}</p>
                              </div>
                            </>
                          );
                        }
                        if (e.id_schedule_history_type == 2) {
                          //Created_at
                          return (
                            <div className="schedule_open">
                              <div
                                style={{ color: '#666666', fontWeight: 'bold' }}
                              >
                                <p>
                                  {' '}
                                  {datefns.format(
                                    utcToNormalizedData(e.created_at),
                                    'dd/MM/yyyy'
                                  )}{' '}
                                  às{' '}
                                  {datefns.format(
                                    datefns.subHours(new Date(e.created_at), 3),
                                    'HH:mm',
                                    { locale: pt }
                                  )}{' '}
                                </p>
                                <p>Concluído por - {e.user.username}</p>
                              </div>
                              {e?.tags && e.tags.length > 0 && (
                                <div className="schedule-container-tags">
                                  <div className="schedule-content-tags">
                                    {e.tags.map((tag) => (
                                      <S.Tag key={`${tag}`} presentTag={true}>
                                        #{tag}
                                      </S.Tag>
                                    ))}
                                  </div>
                                </div>
                              )}

                              <p> {e.description}</p>
                            </div>
                          );
                        }
                        if (e.id_schedule_history_type == 3) {
                          return (
                            <div className="schedule_open">
                              <div
                                style={{ color: '#666666', fontWeight: 'bold' }}
                              >
                                <p>
                                  {' '}
                                  {datefns.format(
                                    utcToNormalizedData(e.created_at),
                                    'dd/MM/yyyy',
                                    { locale: pt }
                                  )}{' '}
                                  às{' '}
                                  {datefns.format(
                                    datefns.subHours(new Date(e.created_at), 3),
                                    'HH:mm',
                                    { locale: pt }
                                  )}{' '}
                                </p>
                                <p>Cancelado por - {e.user.username}</p>
                              </div>
                              {e?.tags && e.tags.length > 0 && (
                                <div className="schedule-container-tags">
                                  <div className="schedule-content-tags">
                                    {e.tags.map((tag) => (
                                      <S.Tag key={`${tag}`} presentTag={true}>
                                        #{tag}
                                      </S.Tag>
                                    ))}
                                  </div>
                                </div>
                              )}
                              <p> {e.description}</p>
                            </div>
                          );
                        }

                        return null;
                      })
                    : null}
                  <p>{modalContent.description}</p>
                </div>
                <div></div>
              </div>
            </div>

            <div style={{ height: '100%', width: '50%' }}>
              <div className="ModalBoxShadow">
                <div
                  style={{
                    fontSize: '12px',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: 'gray',
                  }}
                >
                  {modalContent.start ? (
                    <p style={{ marginTop: '16px' }}>
                      Agendamento em{' '}
                      {datefns.format(modalContent.createdAt, 'dd/MM/yyyy', {
                        locale: pt,
                      })}{' '}
                      ás{' '}
                      {datefns.format(modalContent.createdAt, 'HH:mm', {
                        locale: pt,
                      })}{' '}
                    </p>
                  ) : null}
                  <img alt="" src={scheduleStatus[modalContent.scheduleType]} />
                </div>
                <div
                  style={{
                    marginTop: '-16px',
                    fontSize: '12px',
                    marginLeft: '14px',
                    color: 'gray',
                  }}
                >
                  <p
                    style={{
                      fontWeight: 'bold',
                      fontSize: '13px',
                      color: scheduleStatusColor[modalContent.scheduleType],
                    }}
                  >
                    {scheduleStatusText[modalContent.scheduleType]}
                  </p>
                </div>
              </div>
              <div
                className="contentModalBoxShadow"
                style={{ overflow: 'auto' }}
              >
                <div className="contentModalBox">
                  {modalContent.history
                    ? modalContent.history.map((e, index) => {
                        if (e.id_schedule_history_type == 1) {
                          return (
                            <div key={index}>
                              <div className="contentModalBoxItem">
                                <p
                                  style={{
                                    color: 'gray',
                                    fontSize: '14px',
                                    marginBottom: '1px',
                                  }}
                                >
                                  <span>Aberto por:</span>
                                  <span
                                    style={{
                                      fontSize: '14px',
                                      fontWeight: 'bold',
                                      marginLeft: '3px',
                                    }}
                                  >
                                    {e.user.username}
                                  </span>
                                </p>
                                {e.user.id_users_type == 1 ? (
                                  <p
                                    style={{ color: 'gray', fontSize: '14px' }}
                                  >
                                    Cargo: Promotor
                                  </p>
                                ) : e.user.id_users_type == 2 ? (
                                  <p
                                    style={{ color: 'gray', fontSize: '14px' }}
                                  >
                                    Cargo: Supervisor
                                  </p>
                                ) : e.user.id_users_type == 3 ? (
                                  <p
                                    style={{ color: 'gray', fontSize: '14px' }}
                                  >
                                    Cargo: Coordenador
                                  </p>
                                ) : e.user.id_users_type == 4 ? (
                                  <p
                                    style={{ color: 'gray', fontSize: '14px' }}
                                  >
                                    Cargo: Gerente
                                  </p>
                                ) : e.user.id_users_type == 5 ? (
                                  <p
                                    style={{ color: 'gray', fontSize: '14px' }}
                                  >
                                    Cargo: BackOffice
                                  </p>
                                ) : (
                                  <p
                                    style={{ color: 'gray', fontSize: '14px' }}
                                  >
                                    Cargo: Administração
                                  </p>
                                )}
                              </div>
                            </div>
                          );
                        }
                        if (e.id_schedule_history_type == 2) {
                          return (
                            <div>
                              <div className="contentModalBoxItem">
                                <p
                                  style={{
                                    color: 'gray',
                                    fontSize: '14px',
                                    marginBottom: '1px',
                                  }}
                                >
                                  <span>Concluído por:</span>
                                  <span
                                    style={{
                                      fontSize: '14px',
                                      fontWeight: 'bold',
                                      marginLeft: '3px',
                                    }}
                                  >
                                    {e.user.username}
                                  </span>
                                </p>
                                {e.user.id_users_type == 1 ? (
                                  <p
                                    style={{ color: 'gray', fontSize: '14px' }}
                                  >
                                    Cargo: Promotor
                                  </p>
                                ) : e.user.id_users_type == 2 ? (
                                  <p
                                    style={{ color: 'gray', fontSize: '14px' }}
                                  >
                                    Cargo: Supervisor
                                  </p>
                                ) : e.user.id_users_type == 3 ? (
                                  <p
                                    style={{ color: 'gray', fontSize: '14px' }}
                                  >
                                    Cargo: Coordenador
                                  </p>
                                ) : e.user.id_users_type == 4 ? (
                                  <p
                                    style={{ color: 'gray', fontSize: '14px' }}
                                  >
                                    Cargo: Gerente
                                  </p>
                                ) : e.user.id_users_type == 5 ? (
                                  <p
                                    style={{ color: 'gray', fontSize: '14px' }}
                                  >
                                    Cargo: BackOffice
                                  </p>
                                ) : (
                                  <p
                                    style={{ color: 'gray', fontSize: '14px' }}
                                  >
                                    Cargo: Administração
                                  </p>
                                )}
                              </div>
                            </div>
                          );
                        }
                        if (e.id_schedule_history_type == 3) {
                          return (
                            <div>
                              <div className="contentModalBoxItem">
                                <p
                                  style={{
                                    color: 'gray',
                                    fontSize: '14px',
                                    marginBottom: '1px',
                                  }}
                                >
                                  <span>Cancelado por:</span>
                                  <span
                                    style={{
                                      fontSize: '14px',
                                      fontWeight: 'bold',
                                      marginLeft: '3px',
                                    }}
                                  >
                                    {e.user.username}
                                  </span>
                                </p>
                                {e.user.id_users_type == 1 ? (
                                  <p
                                    style={{ color: 'gray', fontSize: '14px' }}
                                  >
                                    Cargo: Promotor
                                  </p>
                                ) : e.user.id_users_type == 2 ? (
                                  <p
                                    style={{ color: 'gray', fontSize: '14px' }}
                                  >
                                    Cargo: Supervisor
                                  </p>
                                ) : e.user.id_users_type == 3 ? (
                                  <p
                                    style={{ color: 'gray', fontSize: '14px' }}
                                  >
                                    Cargo: Coordenador
                                  </p>
                                ) : e.user.id_users_type == 4 ? (
                                  <p
                                    style={{ color: 'gray', fontSize: '14px' }}
                                  >
                                    Cargo: Gerente
                                  </p>
                                ) : e.user.id_users_type == 5 ? (
                                  <p
                                    style={{ color: 'gray', fontSize: '14px' }}
                                  >
                                    Cargo: BackOffice
                                  </p>
                                ) : (
                                  <p
                                    style={{ color: 'gray', fontSize: '14px' }}
                                  >
                                    Cargo: Administração
                                  </p>
                                )}
                              </div>
                            </div>
                          );
                        }
                        return null;
                      })
                    : null}
                </div>
              </div>
            </div>
          </div>
          <div className="container-btns">
            <button
              className={
                modalContent.history &&
                modalContent?.history[0]?.user?.id !== userInfoStore.id &&
                !userInfoStore.permissions.includes('schedule_delete')
                  ? 'btn-cancel-disabled'
                  : 'btn-cancel'
              }
              onClick={cancelSchedule}
              disabled={
                modalContent.history &&
                modalContent?.history[0]?.user?.id !== userInfoStore.id &&
                !userInfoStore.permissions.includes('schedule_delete')
              }
            >
              Deletar Agendamento
            </button>
          </div>
        </ModalForm>
      </div>
    </>
  );
};

export default SchedulesList;
