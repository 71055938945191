import React, { useState } from 'react';
import { useHistory } from 'react-router-dom'

import Toggle from "react-toggle";
import api from "../../services/api";
import './style.css'
import * as Styled from '../UserListV2/styled'
import swal from 'sweetalert'

function CreateCategories(props) {

    const [cpf, setCpf] = useState(true)
    const [cnpj, setCnpj] = useState(true)
    const [status, setStatus] = useState(true)
    const [inventory, setInventory] = useState(true)
    const [maxSales, setMaxSales] = useState(0)
    const [categoryName, setCategoryName] = useState('')
    const [categoryDescription, setCategoryDescription] = useState('')
    const [invalidInput, setInvalidInputs] = useState({
        category_name: '',
        category_description: ''
    })


    const history = useHistory()

    const handleSubmit = async () => {
        if (!categoryName) {
            setInvalidInputs({...invalidInput, category_name: "invalid-name"})
            return 
        }

        if (!categoryDescription) {
            setInvalidInputs({...invalidInput, category_description: "invalid-description"})
            return 
        }

        let documentType = []

        if (cpf) documentType.push("CPF")
        if (cnpj) documentType.push("CNPJ")       

        const body = {
            "id_project": props.userProject.id,
            "name": categoryName,
            "description": categoryDescription,
            "active": status,
            "document_type": documentType,
            "stock": inventory,
            "max_sales": maxSales
        }

        const response = await api.post('/categories', body)

        if (response.status === 200) {
            swal(`Categoria cadastrada com sucesso!`, {
                icon: "success",
                button: "Fechar"
            });

            return history.push('/Products')
        } else {
            swal(`Erro ao cadastrar categoria!`, {
                icon: "error",
                button: "Fechar"
            });
        }
    }   

    const handleStatusSwitch = (checked) => {
        if (!checked) {
            return setStatus(false)
        }
        
        return setStatus(true)
    }

    const handleInventorySwitch = (checked) => {
        if (!checked) {
            return setInventory(false)
        }

        return setInventory(true)
    }


    const handleCnpjSwitch = () => {
        if (!cnpj) {
            return setCnpj(true)
        }
        
        return setCnpj(false)
    }

    const handleCpfSwitch = () => {
        if (!cpf) {
            return setCpf(true)
        }

        return setCpf(false)
    }

    const handleMaxSales = (value) => {
      const max_sales = Number(value)
      console.log(max_sales, maxSales)
      if (max_sales > 0)
        setMaxSales(max_sales)
    }

    return (
        <div style={{width:'100%'}}>
            <Styled.HeaderCard>
                <Styled.HeaderTitle>
                    Nova Categoria
                </Styled.HeaderTitle>
                <Styled.ButtonDiv>
                    <Styled.NewUserButton onClick={() => handleSubmit()}>Salvar</Styled.NewUserButton>
                </Styled.ButtonDiv>
            </Styled.HeaderCard>
            <div className="containerForm">
                <div className="content">
                    <div className="content-left">
                        <div className="title-form">
                            <span className="checkspan">Nome da Categoria</span>                      
                            <input 
                                value={categoryName}  
                                onChange={(e) => { setCategoryName(e.target.value) }} 
                                placeholder="Digite o nome da categoria"
                                className="input"
                                style={{ borderColor: invalidInput.category_name === 'invalid-name' && !categoryName ? 'red' : '#DFDFDF'}}
                            />     
                            {
                                invalidInput.category_name === 'invalid-name' && !categoryName && 
                                    <p className="invalidValues">Nome da categoria é obrigatório!</p>   
                            }
                                          
                        </div>
                        <div clasName="category-description">
                            <span className="checkspan">Descrição da Categoria</span>
                            <textarea 
                                value={categoryDescription} 
                                onChange={(e) => { setCategoryDescription(e.target.value) }}                                  
                                placeholder="Escreva sobre a descrição da categoria" 
                                className='textarea'
                                style={{ 
                                    borderColor: invalidInput.category_description === 'invalid-description' && !categoryDescription ? 'red' : '#DFDFDF'
                                }}
                            >
                            </textarea>
                            {
                                invalidInput.category_description === 'invalid-description' && !categoryDescription &&
                                    <p className="invalidValues">Descrição da categoria é obrigatório!</p>    
                            }                          
                        </div>                  

                    </div>
                    <div className='content-right'>   
                        <div className="cad">
                            <span className="checkspan">Permitir Cadastro</span>
                            <div className="form-cad">
                                <input onClick={() => { handleCpfSwitch() }} checked={cpf} type="checkbox" name="CPF"></input>
                                <label className="checkLabel" for="CPF">CPF</label>
                                <input onClick={() => { handleCnpjSwitch() }} checked={cnpj} type="checkbox" name="CNPJ"></input>
                                <label className="checkLabel" for="CNPJ">CNPJ</label>
                            </div>
                        </div>                    
                        <div className="form">
                            <div className="toggle">
                                <span className="checkspan">Status</span>
                                <Toggle
                                    onChange={(e) => { handleStatusSwitch(e.target.checked) }}
                                    defaultChecked={true}
                                    icons={false}                                    
                                />
                            </div>
                            <div className="toggle">
                                <span className="checkspan">Estoque</span>
                                <Toggle
                                    onChange={(e) => {handleInventorySwitch(e.target.checked)}}
                                    defaultChecked={true}
                                    icons={false}                            
                                />
                            </div>
                        </div>
                        <div className="form-sales">
                            <div className="toggle">
                                <span className="checkspan">Máximo de Vendas</span>
                                <input
                                    onChange={(e) => { handleMaxSales(e.target.value) }}                                  
                                    type='number'  
                                    min={0}              
                                />
                            </div>                           
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CreateCategories;