import React, { useState } from 'react';

import * as S from './styled';
import { format } from 'date-fns';
import { ArrowForward, KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';


const Ballon = (props) => {

    return (
        <S.Ballon isPromoter={props.isPromoter}>
            <S.BallonRow>
                <S.CreatorName>{props.status?.user?.name}</S.CreatorName>
                <S.CreatedAt>{props?.status?.created_at ? format(new Date(props?.status?.created_at), 'dd/MM/yyyy HH:mm') : '-'}</S.CreatedAt>
            </S.BallonRow>
            <S.BallonRow>
                <S.Observation style={{ width: '100%', lineHeight: '20px' }}>{props.status?.observation || props.status?.action}</S.Observation>
            </S.BallonRow>
            <S.BallonRow style={{ justifyContent: 'flex-start', marginBottom: '5px' }}>
                {props.status?.tags?.map((e) => <S.Tag>#{e}</S.Tag>)}
            </S.BallonRow>

            <S.BallonRow style={{ justifyContent: props.isPromoter ? 'flex-start' : 'flex-end' }}>

                {props.status?.oldPhase !== props.status?.newPhase &&
                    <>
                        <S.StatusContainer>
                            <S.StatusText>
                                {props.status?.oldPhase}
                            </S.StatusText>
                        </S.StatusContainer>

                        <ArrowForward color='#ee5928' style={{ fill: '#ee5928', marginLeft: 5, marginRight: 5 }} />
                    </>
                }
                <S.StatusContainer newStatus>
                    <S.StatusText>{props.status?.newPhase}</S.StatusText>
                </S.StatusContainer>

            </S.BallonRow>

        </S.Ballon>
    )
}

export default function StatusHistory({ sale }) {

    const [hidden, setHidden] = useState(true);
    let revHistory
    if (Array.isArray(sale?.statusHistory)) {
        revHistory = [...sale?.statusHistory];
    }
    else {
        revHistory = [];
    }
    return (
        <S.Container>
            <S.BallonContainer>
                {(hidden ? [...revHistory].slice(0, 2) : revHistory)?.map((e, index) => {

                    return (

                        <Ballon key={index} status={e} isPromoter={e?.user?.positionId === 1} />


                    )
                })

                }

            </S.BallonContainer>
            {revHistory.length > 2 &&
                <S.HiddenContainer>

                    <S.HiddenButton onClick={(e) => { e.preventDefault(); setHidden(!hidden) }}>
                        {hidden ? 'Ver mais' : 'Ver menos'}
                        {hidden ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
                    </S.HiddenButton>

                </S.HiddenContainer>
            }
        </S.Container>
    )
}