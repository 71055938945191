import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  width: 100%;
  height:auto;
  font-family: "Roboto";
  padding: 20px ;
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  table{
    margin-top:35px;
    height: 100%;
    border-collapse: collapse;
    border-radius:0px 10px 0px 0px;
    thead{
        td{
          width:150px;
          text-align:center;
          border: 1px solid #000;
          margin:0;
          padding:0;
        
        }
    }
    tbody{
        td{
        translate:4s border-width ease;
        width:150px;
        text-align:center;
        border: 1px dashed #000;
        margin:0;
        padding:0;
        height: 100%;
        align-items:center;
        justify-content:center;
        padding:2px;
      }
      td#canDrop{
        border-color: #ee5928;
        border-width: 2px;
      }
      td.selected{
        border-width: 1px;
        border-style: solid;
        color:#fff;
        div{
          height: 100%;
          display:flex ;
          flex:1;
          margin:0;
        }
      }
    }

    @media (max-width: 1366px) {
      
      td {
        font-size: 12px;
      }
    }
  }
`
export const ExportButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
`;

export const ReportStatusContainer = styled.div`
  display:'flex';
  flex-direction:row;
  align-items:center;
  margin-top:5px;
`
export const ReportStatusText = styled.p`
  font-size: 14px;
  margin:5px 0 0 0;
  color: #999;
  font-weight: bold;
  margin-right:5px ;
`
export const ExportButton = styled.button`
  background-color: ${({active})=> active?'#ee5928':'#ccc'};
  :hover{
    cursor: ${({active})=> active?'pointer':'wait'} !important;
    background-color:  ${({active})=> active?'#ee592855':'#ccc'};
    opacity:1 ;
  }
  color: #fff;
  margin-top:10px;
  border-radius: 10px;
  padding: 10px 35px;
`
export const FieldsToAddContainer = styled.div`
  margin-top:10px;
  margin-bottom:10px;
  display:flex;
  flex-direction:row;
  flex-wrap:wrap;
`