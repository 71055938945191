import React, {useState, useEffect, useCallback} from 'react';
import {useHistory, useParams} from "react-router-dom";

import api from '../../../services/api';
import '../styles.css';
import './styles.css';
import SubmitButtonContainer from '../../../components/SubmitButtonContainer';
import { 
  FormatCelphone, 
  FormatDocument, 
  FormatPhone, 
  FormatMoney, 
  FormatNumber, 
  FormatDocumentCpf 
} from '../../../utils/FormatData';
import { CheckCircleOutline } from '@material-ui/icons';

function StepVisit(props){
  let {idCustomer} = useParams();
  const history = useHistory();
  const [invalidMessage, setInvalidMessage] = useState('');
  const [invalidInput, setInvalidInput] = useState([]);
  const [customer, setCustomer] = useState({
    document: '',
    commercial_phone: '',
    commercial_mobile: '',
    email: '',
    monthly_revenue: '',
    number_employees: '',
    id_customer_type: '',
    contact_cpf: '',
  })
  const [contact, setContact] = useState({
    contact_mobile_phone: '',
    contact_position: '',
    contact_name: '',
    verified_contact_mobile_phone: false,
  });
  const [codeSmsTimer, setCodeSmsTimer] = useState(0)
  const [codeSmsShow, setCodeSmsShow] = useState(false)
  const [codeSms, setCodeSms] = useState('')
  
  useEffect(() => {
    if(idCustomer && props.userProject?.id){
      api.get('/customers/'+idCustomer, {params:{id_project:props.userProject.id}})
      .then(response => {
        if(response?.data){
          let data = response?.data
          setContact({
            contact_mobile_phone: data.contact_mobile_phone?FormatCelphone(data.contact_mobile_phone):'',
            contact_position: data.contact_position?data.contact_position:'',
            contact_name: data.contact_name?data.contact_name:'',
            verified_contact_mobile_phone: data.verified_contact_mobile_phone?data.verified_contact_mobile_phone:false
          })
          setCustomer({
            document: data.document? FormatDocument(data.document):'',
            commercial_phone: data.commercial_phone?FormatCelphone(data.commercial_phone):'',
            commercial_mobile: data.commercial_mobile?FormatPhone(data.commercial_mobile):'',
            email: data.email?data.email:'',
            monthly_revenue: data.monthly_revenue?FormatMoney(data.monthly_revenue):'',
            number_employees: data.number_employees?data.number_employees:'',
            id_customer: data.id_customer?data.id_customer:'',
            id_customer_type: data.id_customer?data.id_customer_type:'',
            contact_cpf: data.contact_cpf?FormatDocumentCpf(data.contact_cpf):'',
          });
        }
      }).catch(err => {
        console.log(err);
      });
    }else{
      if(!idCustomer){
        history.push('/CustomerRegister')
      }
    }
  }, [history, idCustomer, props.userProject.id])

  const decrementTime = useCallback(
    (timer) => {
      let timerCount = setInterval(() => {
        if(timer > 0){
          timer--
          setCodeSmsTimer(timer)
        }else{
          stopTimerCount()
        }
      }, 1000)
      let stopTimerCount = ()=>{clearInterval(timerCount);}
    },
    [],
  )

  useEffect(() => {
    let isSubscribe = true;
    if(isSubscribe && codeSmsTimer===60){
      decrementTime(60)
    }
    return () => {
      isSubscribe=false;
    }
  }, [codeSmsTimer, decrementTime])
  
  function submitForm(){
    let invalidList = []
    let postCustomer = {};
    postCustomer.id_project = props.userProject?.id;
    
    if(!idCustomer){
      invalidList.push('idCustomer');
    }else{
      postCustomer.id_customer = idCustomer;
    }
    if(!customer.document){
      invalidList.push('document');
    }else{
      postCustomer.document = customer.document.replace(/[^\d]/g, '');
      if(postCustomer.document.length > 11){
        postCustomer.id_customer_type = 2;
      }else{
        postCustomer.id_customer_type = 1;
      }
    }
    // if(!customer.commercial_phone){
    //   invalidList.push('commercial_phone');
    // }else{
    //   postCustomer.commercial_phone = customer.commercial_phone.replace(/[^\d]/g, '');
    // }
    if(customer.commercial_phone){
      postCustomer.commercial_phone = customer.commercial_phone.replace(/[^\d]/g, '');
    }
    // if(!customer.commercial_mobile){
    //   invalidList.push('commercial_mobile');
    // }else{
    //   postCustomer.commercial_mobile = customer.commercial_mobile.replace(/[^\d]/g, '');; 
    // }
    if(customer.commercial_mobile){
      postCustomer.commercial_mobile = customer.commercial_mobile.replace(/[^\d]/g, '');; 
    }
    if(!customer.email){
      invalidList.push('email');
    }else{
      postCustomer.email = customer.email
    }
    if(!customer.monthly_revenue){
      invalidList.push('monthly_revenue');
    }else{
      let revenue = customer.monthly_revenue
      revenue = revenue.toString()
      revenue = revenue.replace(',', '.')
      revenue = parseFloat(revenue);
      postCustomer.monthly_revenue = revenue;
    }
    if(!customer.number_employees){
      invalidList.push('number_employees');
    }else{
      postCustomer.number_employees = customer.number_employees
    }
    if(!customer.contact_cpf){
      invalidList.push('contact_cpf');
    }else{
      postCustomer.contact_cpf = customer.contact_cpf.replace(/[^\d]/g, '');
    }
    if(!contact.verified_contact_mobile_phone){
      invalidList.push('contact_mobile_phone')
    }

    if(invalidList.length > 0){
      setInvalidInput(invalidList);
      setInvalidMessage('Não foi possível salvar as informações cadastrais.')
    }else{
      api.post('/customers', postCustomer)
      .then(response => {
        if(response?.data){
          history.push('/CustomerRegister/stepSales/'+idCustomer);
        }else{
          console.log('customers: ', response);
        }
      }).catch(err => {
        console.log('customers error: ', err);
        console.log(err?.response?.status)
        if(err?.response?.status){
          setInvalidInput(['document']);
          setInvalidMessage('Não foi possível salvar as informações cadastrais, Verifique o CNPJ.');
        }
        if(err?.response?.data.length > 0){
          if(err?.response?.data?.find(item => item.field==='email')){
            setInvalidInput(['email']);
            setInvalidMessage('Não foi possível salvar as informações e-mail invalido.');
          }
        }
      })
    }
  }

  function sendSmsTo(celphone){
    celphone = celphone?.replace(/[^\d]/g, '')
    removeInvalidInput('contact_mobile_phone');
    if(celphone && codeSmsTimer === 0){
      api.post('/confirm_sms/'+idCustomer, {contact_mobile_phone: celphone})
      .then(response => {
        if(response.data?.response?.situacao === 'ERRO'){
          setInvalidInput([...invalidInput, 'contact_mobile_phone']);
          setInvalidMessage('Não foi possivel enviar o código para o celular.')
        } else {
          setCodeSmsTimer(60);
          setCodeSmsShow(true);
        }
      })
      .catch(error => {
        console.log(error);
        setInvalidInput([...invalidInput, 'contact_mobile_phone']);
        setInvalidMessage('Não foi possivel enviar o código para o celular.')
      })
    }
  }

  function confirmSmsCode(){
    api.put('/confirm_sms', {token: codeSms})
    .then(response => {
      if(response.data?.message === "Número confirmado com sucesso."){
        setContact({...contact, verified_contact_mobile_phone: true});
      }
    }).catch(error => {
      console.log(error);
      setInvalidInput([...invalidInput, 'code_sms']);
      setInvalidMessage('Não foi possivel validar o código de SMS.')
    })
  }

  function removeInvalidInput(input){
    if(invalidInput.includes(input)){
      setInvalidInput(invalidInput.filter(item => item !== input));
    }    
  }

  return(
    <>
    <div id='stepFormRow'>
      <div className='stepFormHalf'>
        <div className='leftAlignedTitle'>
          <label>Dados comerciais</label>
        </div>
        <input 
          className={`inputRounded ${invalidInput.includes('document')?'invalidInput':''}`}
          placeholder='CPF ou CNPJ'
          maxLength='18'
          value={customer.document}
          onChange={(event) => {
            removeInvalidInput('document');
            setCustomer({...customer, document:FormatDocument(event.target.value)})
          }}
        />
        <input 
          className={`inputRounded ${invalidInput.includes('commercial_phone')?'invalidInput':''}`}
          placeholder='Telefone comercial'
          maxLength='14'
          value={customer.commercial_phone}
          onChange={(event) => {
            removeInvalidInput('commercial_phone');
            setCustomer({...customer, commercial_phone: FormatPhone(event.target.value)})
          }}
        />
        <input 
          className={`inputRounded ${invalidInput.includes('commercial_mobile')?'invalidInput':''}`}
          placeholder='Celular comercial'
          maxLength='16'
          value={customer.commercial_mobile}
          onChange={(event) => {
            removeInvalidInput('commercial_mobile');
            setCustomer({...customer, commercial_mobile:FormatCelphone(event.target.value)})
          }}
        />
        <input 
          className={`inputRounded ${invalidInput.includes('email')?'invalidInput':''}`}
          placeholder='E-mail'
          value={customer.email}
          onChange={(event) => {
            removeInvalidInput('email');
            setCustomer({...customer, email:event.target.value})
          }}
        />
        <input 
          className={`inputRounded ${invalidInput.includes('monthly_revenue')?'invalidInput':''}`}
          placeholder='Faturamento Mensal'
          maxLength='17'
          value={customer.monthly_revenue}
          onChange={(event) => {
            removeInvalidInput('monthly_revenue');  
            setCustomer({...customer, monthly_revenue:FormatMoney(event.target.value)})
          }}
        />
        <input 
          className={`inputRounded stepRowMedium ${invalidInput.includes('number_employees')?'invalidInput':''}`}
          placeholder='Quantidade de funcionários'
          maxLength='16'
          value={customer.number_employees}
          onChange={(event) => {
            removeInvalidInput('number_employees');
            setCustomer({...customer, number_employees:FormatNumber(event.target.value)})
          }}
        />
      </div>
      <div className='stepFormHalf'>
        <div className='leftAlignedTitle'>
          <label>Representante</label>
        </div>
        <input 
          className={`inputRounded ${invalidInput.includes('contact_cpf')?'invalidInput':''}`}
          placeholder='CPF'
          maxLength='14'
          value={customer.contact_cpf}
          onChange={(event) => {
            removeInvalidInput('contact_cpf');
            setCustomer({...customer, contact_cpf:FormatDocumentCpf(event.target.value)})
          }}
        />
        <input
          className={`inputUnderline ${invalidInput.includes('contact_name')?'invalidInput':''}`}
          placeholder='Nome'
          value={contact.contact_name}
          onChange={(event) => {
            setContact({...contact, contact_name:event.target.value})
          }}
          disabled
        />
        <div className='registerRow'>
          <input
            className={`inputUnderline ${invalidInput.includes('contact_mobile_phone')?'invalidInputUnderline':''}`}
            placeholder='Telefone'
            value={contact.contact_mobile_phone}
            onChange={(event) => {
              setContact({...contact, contact_mobile_phone:event.target.value})
            }}
            disabled
          />
          {codeSmsTimer>0 && !contact.verified_contact_mobile_phone
            ?(
              <button 
                className='registerButtonWait' 
                disabled
              > 
                {codeSmsTimer}
              </button>
            ):(
              <button 
                // className='registerButtonSend' 
                className={`registerButtonSend ${contact.verified_contact_mobile_phone?'registerButtonDone':''}`} 
                onClick={()=>{
                  if(!contact.verified_contact_mobile_phone){
                    sendSmsTo(contact.contact_mobile_phone);
                    removeInvalidInput('contact_mobile_phone');
                  }
                }}
              > 
                {!contact.verified_contact_mobile_phone
                  ? 'Enviar'
                  : <CheckCircleOutline className='iconSmsDone' />
                }
              </button>
            )}
        </div>
        {codeSmsShow && (
          <div className='registerRow'>
            <input
              className={`inputRounded ${invalidInput.includes('code_sms')
                ?'invalidInput'
                :''
              }`}
              placeholder='Código'
              value={codeSms}
              onChange={(event) => {
                setCodeSms(event.target.value)
                removeInvalidInput('code_sms')
              }}
              readOnly={contact.verified_contact_mobile_phone}
            />
            <button 
              className={`registerButtonSend ${contact.verified_contact_mobile_phone
                ?'registerButtonDone'
                :''
              }`} 
              onClick={!contact.verified_contact_mobile_phone?confirmSmsCode:null}
            > 
              {!contact.verified_contact_mobile_phone
                ? 'Confirmar'
                : <CheckCircleOutline className='iconSmsDone' />
              }
            </button>
          </div>
        )}
        <input
          className={`inputUnderline ${invalidInput.includes('contact_position')
            ?'invalidInput'
            :''
          }`}
          placeholder='Cargo/função'
          value={contact.contact_position}
          onChange={(event) => {
            setContact({...contact, contact_position:event.target.value})
          }}
          disabled
        />
        {invalidInput.length>0 &&  (
          <label id="invalidMessage">{invalidMessage}</label>
        )}
      </div>
    </div>
    <SubmitButtonContainer 
        idCustomer={idCustomer}
        idProject={props.userProject?.id}
        steps={2}
        submitForm={()=>{submitForm()}}
      />
    </>
  )
}

export default StepVisit;