import styled from 'styled-components';
import { Card } from '../../components/Styled-Components/Card'

export const Container = styled.div `
  flex: 1;
  width: 100%;
  height:auto;
  padding: 0 15px;
  font-family: Roboto;
  background-color: #888;
`;

export const HeaderCard = styled(Card) `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width:100%;
  padding:30px;
`;

export const HeaderCardTitleContainer = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;


export const HeaderTitle = styled.h1 `
  font-size:20px;
  color:#EE5928;
  // margin-right: ${(props) => props.rightGrid ? '0px': '40px'};
  // align-self: ${(props) => props.rightGrid ? 'baseline': ''};
  // margin-left: ${(props) => props.rightGrid ? '20px': ''};
`;

export const HeaderSubTitle = styled.h1 `
  font-size:18px;
  color:#EE5928;
  align-self: flex-start;
  margin-top: 5px;
`;

export const HeaderTitleContainer = styled.div `
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 20px;
`;

export const SpaceContainer = styled.div `
  width: 100%;
  height: 25px;
`;

export const EditUserButton = styled.button `
  height: 32px;
  padding: 2px 20px;
  border-radius: 20px;
  color: var(--color-text-light);
  background-color: var(--color-background-primary);
  align-self: center;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  // width: 171px;

  span {
    display: flex;
    align-items: center;
  }
`;

export const ButtonTitle = styled.span `
  font-weight: 700;
  font-size: 15px;
  margin-left: 15px;
`

export const MainCard = styled(Card) `
  display: flex;
  flex-direction: column;
  margin-top:10px;
  width:100%;
  padding: 20px 0;
`

export const UpdateUserGrid = styled.div `
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 5px;
`

export const GridColumn = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items:center;
  padding: 2% 10%;
`

export const MainInput = styled.input `
  color: #1C1C1C;
  font-weight: 600;
  background: white;
  margin-top: 5px;
  padding-right: 10px;
  padding-left: 10px;
  border: 2px solid #ddd;
  border-radius: 20px;
  height: 35px;
  // margin-left: ${(props) => props.rightGrid ? '34px':'240px'};
  width: 100%;
  // position: relative;
  // right: ${(props) => props.rightGrid ? '10%': '' };
  ::placeholder {
    color: grey;
    font-weight: 600;
  }
  :read-only {
    color: #7c7272 !important;
    background: #F9F9F9;
  }
`

export const InputContainer = styled.div `
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 15px;
  
  select {
    text-transform: capitalize !important;
  }
`

export const HalfInputContainer = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: space-between; 
  width: 100%; 
  margin-bottom: 15px;
`

export const HalfInput = styled.input `
  color: #1C1C1C;
  font-weight: 600;
  background: white;
  margin-top: 8px;
  padding-left: 10px;
  border: 2px solid #ddd;
  border-radius: 20px;
  height: 35px;
  width: 100%;
  // margin-right:${(props) => props.right ? '55px' : '0px'};
  &:nth-child(1) {
    margin-right: 10px;
  }
  ::placeholder {
    color: grey;
    font-weight: 600;
  }
  :read-only {
    color: #7c7272 !important;
    background: #F9F9F9;
  }
`

export const MainTextArea = styled.textarea.attrs(props => ({
  rows: 6,
  maxLength: 255,
})) `
  height: 17vh;
  color: #1C1C1C;
  font-weight: 600;
  background: white;
  position: relative;
  width: 100%;
  margin-top: 8px;
  padding-right: 19px;
  padding-left: 10px;
  border-color: #ddd;
  border-style: solid;
  border-radius: 20px;
  margin-top: 14px;
  // left: 20%;
  ::placeholder {
    color: #7c7272;
    font-weight: 600;
  }
  :read-only {
    color: #7c7272 !important;
    background: #F9F9F9;
  }
`

export const ProjectSpan = styled(HeaderTitle) `
  // margin-left:72px;
  // margin-top:15px;
  // margin-bottom:15px;
`

export const ProjectGrid = styled.div `
  width: 100%;
  display:grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 10px;
  row-gap:10px;
  margin-left: 5px;
  margin-bottom:10px;
`

export const GridCheckbox = styled.input `
  color: #1C1C1C;
  height:10px;
  width:10px;
  border: 1px solid #EE5928 !important;
  background: #FFF;

  :checked {
    background: #EE5928 !important;
    color: #000;
  }

  :disabled {
    background: none !important;
  }
`

export const CheckboxLabel = styled.label `
  font-size:14px;
  color: ${(props) => props.isChecked ? '#7c7272' : '#1C1C1C'};
  font-weight: 600;
  margin-left: 10px;
`
export const MainSelect = styled.select.attrs(props => ({
  
})) `
  color: #1C1C1C;
  font-weight: 600;
  background: white;
  margin-top: 8px;
  padding-right: 10px;
  padding-left: 10px;
  border: 2px solid #ddd;
  border-radius: 20px;
  height: 40px;
  cursor: pointer;
  // margin-left: ${(props) => props.rightGrid ? '34px':'240px'};
  width: 100%;
  // position: relative;
  // right: ${(props) => props.rightGrid ? '10%': '' };
  ::placeholder {
    color: grey;
    font-weight: 600;
  }
  :disabled {
    background: #F9F9F9 !important;
  }
  
  option {
    text-transform: capitalize;    
  }
`

export const HalfMainSelect = styled.select `
  color: #1C1C1C;
  background: white;
  margin-top: 8px;
  padding-left: 10px;
  border: 2px solid #ddd;
  border-radius: 15px;
  height: 35px;
  width: 25%;
  text-transform: capitalize;
  margin-right:${(props) => props.right ? '55px' : '0px'};
  ::placeholder {
    color: grey;
    font-weight: 600;
  }
  :read-only {
    background: #F9F9F9 !important;
  }  
`

export const LeftLabel = styled.span `
color: #8b8b8b;
font-size: 14px;
font-family: 'Roboto';
align-self: baseline;
margin-bottom:5px;
margin-top:5px;

`;