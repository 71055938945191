import styled from 'styled-components';

export const Container = styled.div `
  width: 100%;
  box-shadow: 1px 2px 13px #00000032;
  border-radius: 10px;
  margin-bottom: 12px;

  > p {
    text-align: center;
    font-weight: bold;
    color:  #EE5928;
    font-size: 18px;
    text-decoration: underline;
  }
`;

export const Card = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: auto;
`
export const Select = styled.select`
  font-family: Roboto;
  padding-left: 10px;
  font-size: 12px;
  margin: 10px;
  width: 48%;
  height: 30px;
  border: 1.5px solid  #BBBBBB;
  background-color: white;
  border-radius: 20px;
  box-sizing: border-box;
  &:link {
    text-decoration: none;
  }
  &:active {
    text-decoration: none;
  }
  &:focus {
    text-decoration: none;
    box-shadow: 0 0 0 0;
    outline: 0;
  }
  option {
    color: black;
    background: white;
    display: flex;
    white-space: pre;
    min-height: 20px;
    padding: 0px 2px 1px;
    text-transform: capitalize;
  }  
  @media (max-width: 992px){
    height: 25px !important;
  }
`

export const TabBar = styled.div`
  width: 100%;
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  border-radius: 20px !important;    
  button {
    font-size: 18px;
    width: 48.5%;
    padding: 10px;
    border-radius: 10px;
    background-color: #FFF;
    color: #EE5928;
    font-weight: bold;
    box-shadow: 2px 0px 5px 0px rgba(0,0,0,0.35);
    &.active { 
      background-color: #EE5928;
      color: #FFF;
    }
    &:disabled{
      background-color: gray;
      color: white;
      cursor: no-drop;
    }
  }
`

export const ModalFormContainer = styled.div`
  font-family: Roboto;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const MyLabel = styled.label`
 font-size: 16px;
 margin-bottom: 3px;
 color: gray;
 font-weight: 500;
`

export const MyInput = styled.input`
  font-family: Roboto;
  border: 1.5px solid #888888;
  border-radius: 20px;
  font-weight: 500;
  font-size: 14px;
  text-overflow: ellipsis; 
  padding: 5px;
  margin-bottom: 15px;

  ::placeholder {
    color: #808080;  
  }   
`

export const MySelect = styled.select`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color:#888888;
  border: 1.5px solid #888888;
  border-radius: 20px;
  padding: 7px;
  margin-bottom: 15px; 
  text-transform: none;
  width: 320px;
  > option {
    color: #808080;  
    text-transform: none;
  }
`

export const Button = styled.button `
  border-radius: 16px;
  color: var(--color-text-light);
  background-color: var(--color-background-primary);
  height: 32px;
  padding: 5px;

  &:disabled{
    background-color: gray;
    color: white;
    border: 3px solid gray;
    cursor: no-drop;
  }
`;