import React, {useState} from 'react';

import './styles.css';
import ModalSalesSchedule from '../ModalSalesSchedule';
import ModalSalesGiveup from '../ModalSalesGiveup';

function SubmitButtonContainer(props) {
  const [showModal, setShowModal] = useState(false);
  const [stepCancel, setStepCancel] = useState(0);

  return(
    <>
      {showModal && (
        <div id='modalAlert'>
          {stepCancel===0 && (
            <div id='cardModalAlert'>
              <button 
                className='buttonModalAlert'
                onClick={()=>{setStepCancel(2)}}
              >
                Desistir da venda
              </button>
              <button 
                className='buttonModalAlert'
                onClick={()=>{setStepCancel(1)}}
              >
                Agendar retorno
              </button>
            </div>
          )}
          {stepCancel===1 && (
            <ModalSalesSchedule 
              steps={props.steps} 
              idCustomer={props.idCustomer}
              idProject={props.idProject}
            />
          )}
          {stepCancel===2 && (
            <ModalSalesGiveup 
              steps={props.steps} 
              idCustomer={props.idCustomer}
              idProject={props.idProject}
            />
          )}
        </div>
      )}
      <div id='customerButtonContainer'>
        <button 
          id='customerButtonQuit'
          onClick={props.cancelForm?props.cancelForm:()=>{setShowModal(true)}}
        > 
          Sair 
        </button>
        <button 
          id='customerButtonSave' 
          onClick={props.submitForm}
        > 
          Salvar 
        </button>
      </div>
    </>
  )
}

export default SubmitButtonContainer;