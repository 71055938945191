import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  width: 90%;
  height:auto;
  font-family: "Roboto";
  display:flex;
  align-self:center;
`;
export const Tag = styled.div`
background-color:#fff ;
color:#EE5928;
font-weight:bold;
font-size:12px;
padding:2px 7px;
border-radius:5px;
margin-right:5px;
margin-top:5px;
`
export const CreatorName = styled.p`
  font-size:11px;
  font-weight:bold;
  color: #888888;
  margin:0 ;
`
export const CreatedAt = styled.p`
  font-size:11px;
  color: #888888;
  margin:0 ;
`
export const BallonContainer = styled.div`
width:'100%';


`
export const Observation = styled.p`
width:'100%';
margin:0 ;
font-size:13px;
text-align:left;
word-wrap: break-word;
`
export const StatusText = styled.p`
width:'100%';
font-size:11px;
color: #fff;
padding: 1px;
margin:0 ;
border-radius:20px;
`
export const StatusContainer = styled.div`
width:'100%';
height: 18px;
font-size:11px;
background-color: ${({ newStatus }) => newStatus ? '#ee5928' : '#AAA'};
padding: 0px 7px;
border-radius:20px;
justify-content:center;
align-items:center;
`
export const BallonRow = styled.div`
width: 100%;
display:flex;
flex-direction:row;
justify-content:space-between;
margin-top:5px;
flex-wrap:wrap;

`
export const HiddenButton = styled.button`

height: 30px;
background-color: transparent;
color: #ee5928;
font-weight: bold;
display:flex ;
flex-direction:row;
align-items:center;
text-align:center;
`
export const HiddenContainer = styled.div`
width:'100%' ;
display:'flex' ;
align-items:flex-end;
`
export const Ballon = styled.div`
position: relative; 
  width:95%;
  border-radius:10px;
  margin-top:10px;
  align-self:${({ isPromoter }) =>!isPromoter ? 'flex-end' : 'flex-start'} ;
  background-color:${({ isPromoter }) => isPromoter ? '#EEEEEE' : '#DDD'} ;
  padding:8px ;


  :after {
    content: '';
    position: absolute;
    ${({ isPromoter }) => !isPromoter ? 'right: -15px;' : 'left: 3px;'}
   
    ${({ isPromoter }) => !isPromoter ? 'bottom:0%;' : ' top: 5px;'}
    width: 0;
    height: 0;
    border: 23px solid transparent;
    ${({ isPromoter }) => isPromoter ? ' border-right-color: #EEEEEE;' : ' border-left-color: #DDD;'}
    ${({ isPromoter }) => !isPromoter ? ' border-right: 0;' : 'border-left: 0;'}
    ${({ isPromoter }) => !isPromoter ? 'border-bottom: 0;' : 'border-top: 0;'}
    margin-top: -5px;
    margin-left: -18px;
}
`

