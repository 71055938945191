import AddIcon from "@material-ui/icons/Add";
import BorderColor from "@material-ui/icons/BorderColor";
import ClearIcon from "@material-ui/icons/Clear";
import { isEmpty } from "lodash";
import Multiselect from "multiselect-react-dropdown";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FaExpeditedssl } from "react-icons/fa";
import { useParams } from "react-router-dom";
import Toggle from "react-toggle";
import { mask, unMask } from "remask";
import swal from "sweetalert";

import ChangePasswordModal from "../../components/ChangePasswordModal";
import StoreContext from "../../context/Context";
import api from "../../services/api";
import apiZipcode from "../../services/apiZipcode";
import { FormatZipcode } from "../../utils/FormatData";
import { ToggleDiv, ToggleSpan } from "../UserRegisterV2/styled";
import * as Styled from "./styled";

const UserUpdateV2 = () => {
  let { idUser } = useParams();
  const [initialValues, setInitialValues] = useState({});
  const [availableProjects, setAvailableProjects] = useState([]);
  const [availableGroups, setAvailableGroups] = useState([]);
  const [availableGroupsAndTeams, setAvailableGroupsAndTeams] = useState([]);
  const [initialGroupsAndTeams, setInitialGroupsAndTeams] = useState([]);
  const [listRole, setListRole] = useState([]);
  const [listPermissions, setListPermissions] = useState([]);
  const [listSuperior, setListSuperior] = useState([]);
  const [userProjects, setUserProjects] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [mobPhone, setMobPhone] = useState("");
  const [addressZip, setAddressZip] = useState("");
  const [supervisor, setSupervisor] = useState("");
  const [active, setActive] = useState(false);
  const [temporary_block, setTemporaryBlock] = useState(false);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [deleteGroups, setDeleteGroups] = useState([]);
  const [userType, setUserType] = useState(0);
  const [add, setAdd] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState("");
  const [deleteProjects, setDeleteProjects] = useState([]);
  const [wasFired, setWasFired] = useState(false);
  const { userInfoStore, userProjectStore } = useContext(StoreContext);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      active: false,
      fullname: "",
      email: "",
      cpf: "",
      birth_date: "",
      address_zip: "",
      address_number: "",
      address_complement: "",
      address: "",
      address_district: "",
      address_city: "",
      address_state: "",
      hire_date: "",
      corporate_mobile_phone: "",
      imei_corporate_mobile_phone: "",
      id_user_superior: "",
      roles: "",
      note: "",
      agency: "",
      agency_digit: "",
      bank: "",
      bank_code: "",
      bank_account: "",
      check_digit: "",
      employee_status: "",
      registration: "",
      temporary_block: false,
      fired_date: "",
    },
  });

  const addIcon = <AddIcon />;
  const clearIcon = <ClearIcon />;

  const GroupAndTeam = (group) => {
    if (group.length > 0) {
      group.forEach((elm) => {
        elm.section = elm.projectName
          ? `Projeto (${elm.projectName}) - Grupo (${elm.section})`
          : `Grupo (${elm.section})`;

        if (Array.isArray(elm.team) && elm.team.length > 0) {
          const teams = elm.team
            .map((v) => {
              return v.name;
            })
            .join("-");

          elm.section = `${elm.section} - Time(s) (${teams})`;
        }
      });
    }

    return group;
  };

  const resetFormValues = useCallback(
    (values) => {
      if (!isEmpty(values)) {
        setUserProjects(values.projects);

        if (values.groups.length && !isEmpty(availableProjects)) {
          const copy = values.groups;
          const copy2 = copy.map((group) => {
            if (!isEmpty(group.teams)) {
              return group.teams.map((team) => {
                return {
                  ...group,
                  id_section: team.id,
                  section: `${group.section}- ${team.name}`,
                  projectName:
                    availableProjects.filter(
                      (p) => p.id === group.id_project
                    )[0]?.name ?? "",
                };
              });
            }
            return {
              ...group,
              projectName:
                availableProjects.filter((p) => p.id === group.id_project)[0]
                  ?.name ?? "",
            };
          });

          const groupAndTeams = GroupAndTeam(copy2);

          setInitialGroupsAndTeams(groupAndTeams);
        }

        setMobPhone(values.corporate_mobile_phone);
        setAddressZip(values.address_zip);

        let corporate_mobile_phone =
          values?.corporate_mobile_phone &&
          mask(unMask(values?.corporate_mobile_phone), ["(99) 99999-9999"]);
        let address_zip =
          values?.address_zip &&
          mask(unMask(values?.address_zip), ["99999-999"]);
        setUserType(values?.userType?.id ?? 0);
        setSupervisor(values?.id_user_superior ?? "");
        setActive(values.active);
        setTemporaryBlock(values.temporary_block);

        reset({
          fullname: values.fullname,
          email: values.email,
          cpf: values.cpf,
          birth_date: values?.birth_date
            ? new Date(values.birth_date).toISOString().split("T")[0]
            : "",
          address_zip: address_zip,
          address_number: values.address_number,
          address_complement: values.address_complement,
          hire_date: values?.hire_date
            ? new Date(values.hire_date).toISOString().split("T")[0]
            : "",
          corporate_mobile_phone: corporate_mobile_phone,
          imei_corporate_mobile_phone: values.imei_corporate_mobile_phone,
          id_user_superior: values.id_user_superior,
          id_users_type: values.id_users_type,
          roles: values.roles.length ? values?.roles[0].id : "",
          note: values.note,
          username: values?.username,
          agency: values.agency,
          agency_digit: values.agency_digit,
          bank: values.bank,
          bank_code: values.bank_code,
          bank_account: values.bank_account,
          check_digit: values.check_digit,
          employee_status: values.employee_status,
          registration: values.registration,
          temporary_block: values.temporary_block,
          fired_date: values?.fired_date
            ? new Date(values.fired_date).toISOString().split("T")[0]
            : "",
        });

        if (
          values.id_user_superior === null ||
          values.id_user_superior === undefined
        ) {
          setValue("id_user_superior", "Superior imediato");
        }
      }
    },
    [availableProjects, reset, setValue]
  );

  const getGroups = useCallback(async () => {
    if (availableProjects.length) {
      availableProjects.forEach((project) => {
        api
          .get(`/groupSections?id_project=${project.id}&show_teams=true`)
          .then((response) => {
            setAvailableGroups((prevState) => [...prevState, ...response.data]);
          });
      });
    }
  }, [availableProjects]);

  useEffect(() => {
    if (!isEmpty(availableProjects) && !isEmpty(availableGroups)) {
      const copy = availableGroups;
      const copy2 = copy.map((group, index) => {
        if (userType < 3) {
          if (!isEmpty(group.teams)) {
            return group.teams.map((team) => {
              return {
                ...group,
                id_section: team.id,
                section: `Grupo (${group.section}) - Time (${team.name})`,
                projectName: availableProjects.filter(
                  (p) => p.id === group.id_project
                )[0]?.name,
              };
            });
          }

          return {};
        }

        const projectName =
          availableProjects.filter((p) => p.id === group.id_project)[0]?.name ??
          "";

        return {
          ...group,
          projectName,
          section: `Grupo (${group.section})`,
        };
      });

      setAvailableGroupsAndTeams(copy2.flat());
    }
  }, [availableGroups, availableProjects]);

  const getSuperiors = useCallback(async (value) => {
    const response = await api.get("/userList", {
      params: {
        id_users_type: [2, 3, 4, 5],
        id_project: value.projects.map((e) => e?.id),
      },
    });
    setListSuperior(response?.data.filter((user) => user.id_users_type !== 1));
    setInitialValues(value);
  }, []);

  const getAvailableProjects = useCallback(async () => {
    const response = await api.get(`/projects`);

    setAvailableProjects(response.data);
  }, []);

  useEffect(() => {
    if (!isEmpty(initialValues) && !isEmpty(availableProjects)) {
      resetFormValues(initialValues);
    }
  }, [availableProjects, initialValues, resetFormValues]);

  useEffect(() => {
    if (availableProjects.length) {
      getGroups();
    }
  }, [availableProjects, getGroups]);

  const getFormData = useCallback(async () => {
    // const response = await api.get(`/users/${idUser}`)
    // const responseRoles = await api.get(`/usertypes`)
    // const responsePermissions = await api.get(`/roles/all`)

    const [response, responseRoles, responsePermissions] = await Promise.all([
      api.get(`/users/${idUser}`),
      api.get(`/usertypes`),
      api.get(`/roles/all`),
    ]);

    setListRole(responseRoles?.data);

    setListPermissions(responsePermissions?.data);

    getSuperiors(response.data[0]);

    getAvailableProjects();

    setDeleteGroups([]);
    setSelectedGroups([]);
  }, [idUser, getSuperiors, getAvailableProjects]);

  useEffect(() => {
    getFormData();
  }, [getFormData]);

  const handleProjects = async (project, projectName) => {
    if (userProjects.some((p) => p.id === project.id)) {
      const remove_project = await swal({
        title: `Tem certeza que você deseja remover o usuário do projeto?`,
        text: `Ao salvar o usuário ele será automaticamente removido de todos os Grupos ou Times do projeto (${projectName})!`,
        icon: "info",
        buttons: ["Não", "Sim"],
        dangerMode: true,
      });

      if (!remove_project) {
        return;
      }

      const newData = userProjects;

      const filteredData = newData.filter((p) => p.id !== project.id);

      setUserProjects(filteredData);

      if (!deleteProjects.includes(project.id)) {
        setDeleteProjects((prev) => [...prev, project.id]);
      }

      return;
    }

    if (userType > 2 && !userProjects.some((p) => p.id === project.id)) {
      setUserProjects((prevState) => [...prevState, project]);
      return;
    }

    setUserProjects([project]);
  };

  const handleEditClick = () => {
    setEditMode(true);
  };

  const editUser = async (data) => {
    data.id_projects = userProjects.map((d) => d.id);
    data.roles = [data.roles];

    if (deleteProjects.length > 0) {
      data.delete_projects = deleteProjects;
    }

    const phoneMob = getValues("corporate_mobile_phone");
    const zipAddress = getValues("address_zip");

    data.active = active;
    data.temporary_block = temporary_block;
    data.corporate_mobile_phone =
      phoneMob &&
      mask(unMask(mobPhone !== phoneMob ? phoneMob : mobPhone), [
        "99999999999",
      ]);
    data.address_zip =
      zipAddress &&
      mask(unMask(addressZip !== zipAddress ? zipAddress : addressZip), [
        "99999999",
      ]);
    data.id_user_superior =
      data.id_user_superior === "Superior imediato"
        ? null
        : data.id_user_superior;
    data.id_project = userProjectStore.id;

    try {
      if (selectedGroups.length > 0) {
        const promises = [];

        selectedGroups.forEach((groups) => {
          const groupPostData = {
            user_id: idUser,
            section_id: groups.id,
            id_group: groups.id_section,
          };

          if (userType > 2) {
            groupPostData.watcher = true;
          }

          promises.push(
            api.post(`/groupSections/user/add`, groupPostData).then((res) => {
              console.log(res);
            })
          );
        });

        try {
          await Promise.all(promises);
        } catch (error) {
          const message =
            error?.response?.data?.error ?? `Não foi possível editar usuário`;

          swal(message, {
            icon: "error",
            button: "Fechar",
          });

          return;
        }
      }

      if (deleteGroups.length > 0) {
        const deleted_group = deleteGroups.filter((g) =>
          initialGroupsAndTeams.some((initial) => {
            if (g.id_group) {
              return (
                g.section_id === initial.section_id &&
                g.id_group === initial.id_group
              );
            }

            return g.section_id === initial.section_id;
          })
        );

        const promises = [];

        deleted_group.forEach((item) => {
          const payload = { user_id: idUser, section_id: item.section_id };

          if (item.id_group) {
            payload.id_group = item.id_group;
          }

          promises.push(
            api.post(`/groupSections/user/remove`, payload).then((res) => {
              console.log(res);
            })
          );
        });

        try {
          await Promise.all(promises);
        } catch (error) {
          const message =
            error?.response?.data?.error ?? `Não foi possível editar usuário`;

          swal(message, {
            icon: "error",
            button: "Fechar",
          });

          return;
        }
      }

      await api.put(`/users/${idUser}`, data);

      swal(`Usuário editado com sucesso!`, {
        icon: "success",
        button: "Fechar",
      });

      setTimeout(() => {
        document.location.reload();
      }, 1500);
    } catch (e) {
      swal(`Não foi possível editar usuário!`, {
        icon: "error",
        button: "Fechar",
      });
    }

    setEditMode(!editMode);
  };

  const filterSupervisor = (e) => {
    if (!e) {
      if (supervisor) setValue("id_user_superior", supervisor);
      else setValue("id_user_superior", "Superior imediato");

      return;
    }

    listSuperior.filter((v) => {
      let nome = v.fullname.toLowerCase();

      if (nome.startsWith(e.toLowerCase())) {
        setValue("id_user_superior", v.id);
        return true;
      } else return false;
    });
  };

  const selectGroup = (selectedList, selectedItem) => {
    if (userType < 3) {
      if (
        !selectedGroups.some(
          (group) =>
            group.section_id === selectedItem.section_id &&
            group.id_group &&
            selectedItem.id_group
        )
      ) {
        setSelectedGroups((prevState) => [...prevState, selectedItem]);
      }
    } else {
      if (!selectedGroups.some((group) => group.id === selectedItem.id)) {
        setSelectedGroups((prevState) => [...prevState, selectedItem]);
      }
    }

    if (deleteGroups.length > 0) {
      const copy_groups = [...deleteGroups];

      setDeleteGroups(
        copy_groups.filter((group) => group.id !== selectedItem.id)
      );
    }
  };

  const removeGroup = (selectedList, selectedItem) => {
    setDeleteGroups((prevState) => [...prevState, selectedItem]);
  };

  const getAddressByCep = useCallback(
    async (value) => {
      if (value.replace(/\D/g, "").length >= 8) {
        const cep = value.replace(/\D/g, "");
        const response = await apiZipcode.get(`${cep}/json`);
        setValue("address", response?.data?.logradouro);
        setValue("address_district", response?.data?.bairro);
        setValue("address_city", response?.data?.localidade);
        setValue("address_state", response?.data?.uf);
      }
    },
    [setValue]
  );

  const watchZipCode = watch("address_zip");

  useEffect(() => {
    if (watchZipCode) {
      setValue("address_zip", FormatZipcode(watchZipCode));
      getAddressByCep(watchZipCode);
    }
  }, [watchZipCode, getAddressByCep, setValue]);

  const changePassword = async (_) => {
    try {
      setAdd(false);

      const response = await api.put(`/users/${idUser}`, {
        password: newPassword,
        password_confirmation: newPasswordConfirmation,
      });

      if (response.status === 200) {
        swal(`Senha alterada com sucesso!`, {
          icon: "success",
          button: "Fechar",
        });
      }
    } catch (error) {
      swal(`Não foi possível alterar senha no momento!`, {
        icon: "error",
        button: "Fechar",
      });
    }
  };

  const userWasFired = async () => {
    console.log(active);
    setActive(!active);

    if (!active === false) {
      const requiredFiredDate = await swal({
        title: `O usuário inativado foi demitido?`,
        text: "Se SIM, será obrigatório preencher a data da demissão.",
        icon: "info",
        buttons: ["Não", "Sim"],
        dangerMode: true,
      });
      setWasFired(requiredFiredDate);
    }
  };

  return (
    <>
      <ChangePasswordModal
        active={add}
        setNewPassword={setNewPassword}
        setNewPasswordConfirmation={setNewPasswordConfirmation}
        handleClickSave={changePassword}
        handleClickClose={() => {
          setAdd(false);
          setNewPassword("");
          setNewPasswordConfirmation("");
        }}
      />

      {/* <Styled.Container> */}
      <Styled.HeaderCard>
        <Styled.HeaderCardTitleContainer>
          <Styled.HeaderTitle>Editar Usuário</Styled.HeaderTitle>
          {!editMode ? (
            <>
              {
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                  }}
                >
                  {userInfoStore?.permissions.includes(
                    "changepassword_edit"
                  ) && (
                    <Styled.EditUserButton onClick={() => setAdd(true)}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <FaExpeditedssl style={{ fontSize: 17, margin: 0 }} />
                        <Styled.ButtonTitle>Alterar Senha</Styled.ButtonTitle>
                      </div>
                    </Styled.EditUserButton>
                  )}
                  <Styled.EditUserButton onClick={() => handleEditClick()}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <BorderColor style={{ fontSize: 17 }} />
                      <Styled.ButtonTitle>Editar</Styled.ButtonTitle>
                    </div>
                  </Styled.EditUserButton>
                </div>
              }
            </>
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                }}
              >
                <Styled.EditUserButton
                  onClick={handleSubmit((d) => {
                    if (!d.roles) {
                      swal(`Defina a permissão do usuário!`, {
                        icon: "info",
                        button: "Fechar",
                      });
                    } else if (!d.id_users_type) {
                      swal(`Cargo é obrigatório!`, {
                        icon: "info",
                        button: "Fechar",
                      });
                    } else {
                      editUser(d);
                    }
                  })}
                >
                  <span style={{ fontSize: "15px", fontWeight: "700" }}>
                    {addIcon}Salvar
                  </span>
                </Styled.EditUserButton>
                <Styled.EditUserButton
                  onClick={(_) => {
                    document.location.reload();
                  }}
                >
                  <span style={{ fontSize: "15px", fontWeight: "700" }}>
                    {clearIcon}Cancelar
                  </span>
                </Styled.EditUserButton>
              </div>
            </>
          )}
        </Styled.HeaderCardTitleContainer>
      </Styled.HeaderCard>
      <Styled.MainCard>
        <form>
          <Styled.UpdateUserGrid>
            <Styled.GridColumn>
              <Styled.HeaderTitleContainer>
                <Styled.HeaderSubTitle>Dados Pessoais</Styled.HeaderSubTitle>
              </Styled.HeaderTitleContainer>
              {!editMode && (
                <Styled.InputContainer>
                  <Styled.LeftLabel>Nome de usuário*</Styled.LeftLabel>
                  <Styled.MainInput
                    readOnly={true}
                    {...register("username")}
                    placeholder="Nome de usuário"
                  />
                </Styled.InputContainer>
              )}
              <Styled.InputContainer>
                <Styled.LeftLabel>Nome completo*</Styled.LeftLabel>
                <Styled.MainInput
                  readOnly={!editMode}
                  {...register("fullname")}
                  placeholder="Nome completo"
                />
              </Styled.InputContainer>
              <Styled.InputContainer>
                <Styled.LeftLabel>Email*</Styled.LeftLabel>
                <Styled.MainInput
                  readOnly={!editMode}
                  {...register("email")}
                  placeholder="Email"
                />
              </Styled.InputContainer>
              <Styled.HalfInputContainer>
                <div style={{ width: "49%" }}>
                  <Styled.LeftLabel>CPF*</Styled.LeftLabel>
                  <Styled.HalfInput
                    readOnly={!editMode}
                    {...register("cpf")}
                    right
                    placeholder="CPF"
                  />
                </div>
                <div style={{ width: "49%" }}>
                  <Styled.LeftLabel>Data de nascimento*</Styled.LeftLabel>
                  <Styled.HalfInput
                    readOnly={!editMode}
                    {...register("birth_date")}
                    placeholder={`Data de nascimento`}
                    type="date"
                  />
                </div>
              </Styled.HalfInputContainer>
              <Styled.HalfInputContainer>
                <div style={{ width: "49%" }}>
                  <Styled.LeftLabel>CEP*</Styled.LeftLabel>
                  <Styled.HalfInput
                    readOnly={!editMode}
                    {...register("address_zip")}
                    right
                    placeholder="CEP"
                  />
                </div>
                <div style={{ width: "49%" }}>
                  <Styled.LeftLabel>Nº*</Styled.LeftLabel>
                  <Styled.HalfInput
                    readOnly={!editMode}
                    {...register("address_number")}
                    placeholder="Nº"
                  />
                </div>
              </Styled.HalfInputContainer>
              <Styled.InputContainer>
                <Styled.LeftLabel>Complemento*</Styled.LeftLabel>
                <Styled.MainInput
                  readOnly={!editMode}
                  {...register("address_complement")}
                  placeholder="Complemento"
                />
              </Styled.InputContainer>
              <Styled.InputContainer>
                <Styled.LeftLabel>Endereço*</Styled.LeftLabel>
                <Styled.MainInput
                  readOnly={!editMode}
                  {...register("address")}
                  placeholder="Endereço"
                />
              </Styled.InputContainer>
              <Styled.InputContainer>
                <Styled.LeftLabel>Bairro*</Styled.LeftLabel>
                <Styled.MainInput
                  readOnly={!editMode}
                  {...register("address_district")}
                  right
                  placeholder="Bairro"
                />
              </Styled.InputContainer>
              <Styled.HalfInputContainer>
                <div style={{ width: "49%" }}>
                  <Styled.LeftLabel>Cidade*</Styled.LeftLabel>
                  <Styled.HalfInput
                    readOnly={!editMode}
                    {...register("address_city")}
                    right
                    placeholder="Cidade"
                  />
                </div>
                <div style={{ width: "49%" }}>
                  <Styled.LeftLabel>Estado*</Styled.LeftLabel>
                  <Styled.HalfInput
                    readOnly={!editMode}
                    {...register("address_state")}
                    placeholder="UF"
                  />
                </div>
              </Styled.HalfInputContainer>

              <Styled.HalfInputContainer>
                <div style={{ width: "49%" }}>
                  <Styled.LeftLabel>Código Banco</Styled.LeftLabel>
                  <Styled.HalfInput
                    readOnly={!editMode}
                    {...register("bank_code")}
                    right
                    placeholder="Cód Banco"
                    maxLength={3}
                  />
                </div>
                <div style={{ width: "49%" }}>
                  <Styled.LeftLabel>Banco</Styled.LeftLabel>
                  <Styled.HalfInput
                    readOnly={!editMode}
                    {...register("bank")}
                    placeholder="Banco"
                  />
                </div>
              </Styled.HalfInputContainer>

              <Styled.HalfInputContainer>
                <div style={{ width: "49%" }}>
                  <Styled.LeftLabel>Agência</Styled.LeftLabel>
                  <Styled.HalfInput
                    readOnly={!editMode}
                    {...register("agency")}
                    right
                    placeholder="Agência"
                    maxLength={4}
                  />
                </div>
                <div style={{ width: "49%" }}>
                  <Styled.LeftLabel>Dígito Agência</Styled.LeftLabel>
                  <Styled.HalfInput
                    readOnly={!editMode}
                    {...register("agency_digit")}
                    placeholder="Dígito Agência"
                    maxLength={1}
                  />
                </div>
              </Styled.HalfInputContainer>

              <Styled.HalfInputContainer>
                <div style={{ width: "49%" }}>
                  <Styled.LeftLabel>Conta Bancária</Styled.LeftLabel>
                  <Styled.HalfInput
                    readOnly={!editMode}
                    {...register("bank_account")}
                    right
                    placeholder="Conta Bancária"
                    maxLength={11}
                  />
                </div>
                <div style={{ width: "49%" }}>
                  <Styled.LeftLabel>Dígito Verificação</Styled.LeftLabel>
                  <Styled.HalfInput
                    readOnly={!editMode}
                    {...register("check_digit")}
                    placeholder="Dígito Verificação"
                    maxLength={1}
                  />
                </div>
              </Styled.HalfInputContainer>

              <ToggleDiv style={{ width: "100%" }}>
                <Toggle
                  checked={active}
                  onChange={userWasFired}
                  icons={false}
                  disabled={!editMode}
                />
                <ToggleSpan style={{ marginLeft: "2px" }}>
                  Usuário Ativo
                </ToggleSpan>
              </ToggleDiv>

              <ToggleDiv style={{ width: "100%" }}>
                <Toggle
                  checked={temporary_block}
                  onChange={() => setTemporaryBlock(!temporary_block)}
                  icons={false}
                  disabled={!editMode}
                />
                <ToggleSpan style={{ marginLeft: "2px" }}>
                  Bloquear Usuário Temporariamente
                </ToggleSpan>
              </ToggleDiv>
            </Styled.GridColumn>

            <Styled.GridColumn>
              <Styled.HeaderTitleContainer>
                <Styled.HeaderSubTitle>
                  Dados Profissionais
                </Styled.HeaderSubTitle>
              </Styled.HeaderTitleContainer>
              <Styled.InputContainer>
                <Styled.LeftLabel>Data de contratação*</Styled.LeftLabel>
                <Styled.MainInput
                  readOnly={!editMode}
                  {...register("hire_date")}
                  placeholder="Data de contratação"
                  type="date"
                  rightGrid
                />
              </Styled.InputContainer>
              <Styled.InputContainer>
                <Styled.LeftLabel>
                  Data da Demissão{wasFired && "*"}
                </Styled.LeftLabel>
                <Styled.MainInput
                  readOnly={!editMode || active}
                  {...register("fired_date", {
                    required: wasFired,
                  })}
                  placeholder="Data da demissão"
                  type="date"
                  rightGrid
                  style={{
                    borderColor: wasFired && !watch("fired_date") ? "red" : "",
                  }}
                />
              </Styled.InputContainer>
              <Styled.HalfInputContainer>
                <div style={{ width: "49%" }}>
                  <Styled.LeftLabel>Cargo*</Styled.LeftLabel>
                  <Styled.MainSelect
                    {...register("id_users_type")}
                    disabled={!editMode}
                    rightGrid
                    id="userType"
                  >
                    <option value="" disabled>
                      Cargo
                    </option>
                    {listRole?.map((value, index) => {
                      return (
                        <option key={index} value={value.id}>
                          {value.name}
                        </option>
                      );
                    })}
                  </Styled.MainSelect>
                </div>
                <div style={{ width: "49%" }}>
                  <Styled.LeftLabel>Permissão*</Styled.LeftLabel>
                  <Styled.MainSelect
                    {...register("roles")}
                    disabled={!editMode}
                    rightGrid
                    id="roles"
                  >
                    <option value="" disabled>
                      Permissão
                    </option>
                    {listPermissions?.map((value, index) => {
                      return (
                        <option key={index} value={value.id}>
                          {value.name}
                        </option>
                      );
                    })}
                  </Styled.MainSelect>
                </div>
              </Styled.HalfInputContainer>
              <Styled.HeaderTitleContainer style={{ marginBottom: "5px" }}>
                <Styled.HeaderSubTitle style={{ marginBottom: "3px" }}>
                  Projetos
                </Styled.HeaderSubTitle>
              </Styled.HeaderTitleContainer>
              <Styled.ProjectGrid>
                {availableProjects.map((project, index) => {
                  return (
                    <span
                      key={index + 1}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Styled.GridCheckbox
                        disabled={!editMode}
                        onChange={() => handleProjects(project, project.name)}
                        type="checkbox"
                        checked={userProjects.some((p) => p.id === project.id)}
                        id={project.name}
                      />
                      <Styled.CheckboxLabel
                        isChecked={!editMode}
                        htmlFor={project.name}
                      >
                        {project.name}
                      </Styled.CheckboxLabel>
                    </span>
                  );
                })}
              </Styled.ProjectGrid>
              {userProjects.length > 0 && (
                <>
                  <Styled.HeaderSubTitle style={{ marginTop: "10px" }}>
                    Grupos e Times
                  </Styled.HeaderSubTitle>
                  <Multiselect
                    style={{
                      searchBox: {
                        // To change search box element look
                        marginTop: "10px",
                        "border-radius": "25px",
                        display: "flex",
                        flexDirection: "column",
                        pointerEvents: !editMode ? "none" : "",
                      },
                      searchWrapper: {
                        // To change search box element look
                        borderRadius: "25px",
                        display: "flex",
                        flexDirection: "row",
                        pointerEvents: !editMode ? "none" : "",
                      },
                      option: {
                        color: "black",
                      },
                      chips: {
                        background: "#EE5928",
                        borderRadius: "25px",
                        color: "white",
                        width: "auto",
                        maxWidth: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                      },
                      optionContainer: {
                        pointerEvents: !editMode ? "none" : "",
                        borderRadius: "25px",
                        fontSize: "12px",
                        color: "black",
                        "& li": {
                          backgroundColor: "pink",
                        },
                      },
                    }}
                    emptyRecordMsg={"Não tem opções disponíveis"}
                    placeholder={!editMode ? "" : "Clique aqui para adicionar"}
                    selectedValues={initialGroupsAndTeams}
                    options={availableGroupsAndTeams.filter((g) =>
                      userProjects.some((p) => p.id === g.id_project)
                    )} // Options to display in the dropdown
                    groupBy="projectName"
                    onSelect={selectGroup} // Function will trigger on select event
                    onRemove={removeGroup} // Function will trigger on remove event
                    displayValue="section" // Property name to display in the dropdown options
                    // singleSelect={userType === 1 || userType === '1'}
                    selectionLimit={Number(userType) < 2 ? 1 : -1}
                    disabled={!editMode}
                  />
                </>
              )}
              <Styled.InputContainer style={{ marginTop: "10px" }}>
                <Styled.LeftLabel>Pesquisar supervisor</Styled.LeftLabel>
                <Styled.MainInput
                  readOnly={!editMode}
                  onChange={(e) => filterSupervisor(e.target.value)}
                  rightGrid
                  placeholder="Pesquisar supervisor pelo nome..."
                />
              </Styled.InputContainer>
              <Styled.InputContainer>
                <Styled.LeftLabel>Superior imediato</Styled.LeftLabel>
                <Styled.MainSelect
                  {...register("id_user_superior")}
                  disabled={!editMode}
                  rightGrid
                >
                  <option
                    value="Superior imediato"
                    disabled
                    style={{ color: "#8b8b8b" }}
                  >
                    Superior imediato
                  </option>
                  {listSuperior
                    ?.sort(
                      (a, b) =>
                        a.fullname
                          .toLowerCase()
                          .localeCompare(b.fullname.toLowerCase()) -
                        b.fullname
                          .toLowerCase()
                          .localeCompare(a.fullname.toLowerCase())
                    )
                    .map((value, index) => {
                      return (
                        <option key={index} value={value.id}>
                          {value?.fullname.toLowerCase()}
                        </option>
                      );
                    })}
                </Styled.MainSelect>
              </Styled.InputContainer>

              <Styled.InputContainer>
                <Styled.LeftLabel>Celular corporativo</Styled.LeftLabel>
                <Styled.MainInput
                  readOnly={!editMode}
                  {...register("corporate_mobile_phone")}
                  rightGrid
                  placeholder="Celular"
                />
              </Styled.InputContainer>

              <Styled.InputContainer>
                <Styled.LeftLabel>Imei do celular corporativo</Styled.LeftLabel>
                <Styled.MainInput
                  readOnly={!editMode}
                  {...register("imei_corporate_mobile_phone")}
                  rightGrid
                  placeholder="Imei"
                />
              </Styled.InputContainer>

              <Styled.InputContainer>
                <Styled.LeftLabel>Matrícula</Styled.LeftLabel>
                <Styled.MainInput
                  readOnly={!editMode}
                  {...register("registration")}
                  rightGrid
                  placeholder="Matrícula"
                />
              </Styled.InputContainer>

              <Styled.InputContainer>
                <Styled.LeftLabel>Situação do Empregado</Styled.LeftLabel>
                <Styled.MainInput
                  readOnly={!editMode}
                  {...register("employee_status")}
                  rightGrid
                  placeholder="Situação do Empregado"
                />
              </Styled.InputContainer>

              <Styled.InputContainer>
                <Styled.LeftLabel>Notas*</Styled.LeftLabel>
                <Styled.MainTextArea
                  {...register("note")}
                  readOnly={!editMode}
                  placeholder="Notas"
                  rightGrid
                  style={{ marginTop: "4px" }}
                />
              </Styled.InputContainer>
            </Styled.GridColumn>
          </Styled.UpdateUserGrid>
        </form>
      </Styled.MainCard>
      {/* </Styled.Container> */}
    </>
  );
};

export default UserUpdateV2;
