import React, { useState, useEffect, useContext, useCallback, useMemo } from 'react';
import './styles.css';
import styled from 'styled-components';
import api from "../../services/api";
import StoreContext from '../../context/Context';
import Modal from '../../components/ModalForm'
import { Edit, Search, ArrowBackIos, SecurityTwoTone } from '@material-ui/icons';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import UserButton from '../../components/UserButton';
import UserSlot from '../../components/UserSlot';
import swal from 'sweetalert';
import GroupRemovalModal from '../../components/GroupRemovalModal';
import { useHistory } from 'react-router-dom'


function Regions(props) {
  const [searchUser, setSearchUser] = useState('');
  const [selectingManager, setSelectingManager] = useState(false);
  const [addUserOnTeam, setAddUserOnTeam] = useState(false);
  const [searchGroup, setSearchGroup] = useState('');
  const [selectedTeam, setSelectedTeam] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(0);
  const [group, setGroup] = useState(null);
  const [team, setTeam] = useState(null);
  const [selectedGroupColor, setSelectedGroupColor] = useState('#EE5928');
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [groups, setGroups] = useState([]);
  const [watcher, setWatcher] = useState(true);
  const { userProjectStore } = useContext(StoreContext);
  const [showAlert, setShowAlert] = useState(false);
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [newGroup, setNewGroup] = useState(false);
  const [users, setUsers] = useState([]);
  const [openTeamModal, setOpenTeamModal] = useState(false)
  const [userId, setUserId] = useState('')
  const [teamId, setTeamId] = useState('')
  const [add, setAdd] = useState(null)
  const [groupId, setGroupId] = useState('')
  const [hasTeam, setUserHasTeam] = useState(null)


  const openModalTeam = useCallback(() => {
    setOpenTeamModal(true)
  },[]) 

  const closeTeamModal = () => {
    setOpenTeamModal(false)
  }

  const [newTeamData, setNewTeamData] = useState({
    id: '',
    name: '',
    city: '',
    district: '',
    state: '',
    error: ''
  });

  const getStates = async () => {
    try {
      const states = await api.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados/')

      if (states?.data?.length > 0) {
        return states.data
      } else {
        return []
      }

    } catch (error) {
      return []
    }
  }

  const getProjectGroups = useCallback(async () => {
    try {
      const groups = await api.get('groupSections', { params: { id_project: userProjectStore.id } })
      const states = await getStates()
      
      if (groups.data && groups.data.length > 0) {  
        const group = await api.get('groupSections/' + groups.data[selectedGroup].id)

        setGroup(group.data)
      }

      if (states?.length > 0) {
        setStates(states.sort((a, b) => 
            a.sigla.toLowerCase().localeCompare(b.sigla.toLowerCase()) - b.sigla.toLowerCase().localeCompare(a.sigla.toLowerCase())
        ))
      } else {
        setStates([])
      }
      
      setGroups(groups.data)

    } catch (error) {
      setStates([])
      setGroups([])
    }
  },[selectedGroup, userProjectStore.id])
  const [selectedModal, setSelectedModal] = useState(0)

  const [editOneTeam, setEditOneTeam] = useState(false)

  const selectedTeamIndex = useMemo(() => {
    const index = group?.teams.findIndex(team => team.id === selectedTeam)

    if (index === -1 || index === undefined) return false

    return index
  }, [group, selectedTeam])

  const setEditTeam = async (data) => {
    setNewTeamData({ error: '', ...data })
    setEditOneTeam(true)
    setShowAlert(true)
    setSelectedModal(3)
  }

  const editTeam = useCallback(async () => {
    if (!newTeamData.name) {
      setNewTeamData({ ...newTeamData, error: 'Campo nome vazio' })
      return;
    }

    if (!newTeamData.city) {
      setNewTeamData({ ...newTeamData, error: 'Campo cidade vazio' })
      return;
    }

    if (!newTeamData.district) {
      setNewTeamData({ ...newTeamData, error: 'Campo  distrito vazio' })
      return;
    }

    if (!newTeamData.state) {
      setNewTeamData({ ...newTeamData, error: 'Campo  estado vazio' })
      return;
    }


    const data = {
      name: newTeamData.name,
      city: newTeamData.city,
      district: newTeamData.district,
      state: newTeamData.state
    }

    try {
      const response = await api.put(`groups/${newTeamData.id}`, data)

      if (response.status === 200) {
        setShowAlert(false)

        swal(`Time atualizado com sucesso!`, {
          icon: "success",
          button: "Fechar"
        });

        setNewTeamData({
          id: '',
          name: '',
          city: '',
          district: '',
          state: '',
          error: ''
        })

        getProjectGroups()
      }
    } catch (error) {
      const { data } = error.response

      swal({
        title: "Atenção",
        text: data.error,
        icon: "warning",
        button: "Fechar",
      });
    }
  }, [getProjectGroups, newTeamData])

  const [newGroupData, setNewGroupData] = useState({
    section: '',
    description: '',
    color: '#EE5928',
    error: ''
  });

  
  const [editGroupSection, setEditGroupSection] = useState({
    id: '',
    section: '',
    description: '',
    color: '#EE5928',
    id_project: '',
    error: '',
  });
 

  const updateGroupSection = async (data) => {
    setEditGroupSection({ error: '', ...data })
  }

  const editGroup = useCallback(async () => {
    if (!editGroupSection.color) {
      setEditGroupSection({ ...editGroupSection, error: 'Campo cor vazio' })
      return;
    }

    if (!editGroupSection.section) {
      setEditGroupSection({ ...editGroupSection, error: 'Campo nome do grupo vazio' })
      return;
    }

    if (!editGroupSection.description) {
      setEditGroupSection({ ...editGroupSection, error: 'Campo descrição do grupo vazio' })
      return;
    }

    const data = {
      section: editGroupSection.section,
      description: editGroupSection.description,
      color: editGroupSection.color,
      id_project: editGroupSection.id_project
    }

    try {
      const response = await api.put(`groupSections/${editGroupSection.id}`, data)

      if (response.status === 200) {
        setShowAlert(false)

        swal(`Grupo atualizado com sucesso!`, {
          icon: "success",
          button: "Fechar"
        });

        await getProjectGroups();

        setEditGroupSection({
          id: '',
          section: '',
          description: '',
          color: '#EE5928',
          id_project: '',
          error: '',
        })
      }
    } catch (error) {
      const { data } = error.response

      swal({
        title: "Atenção",
        text: data?.error,
        icon: "warning",
        button: "Fechar",
      });
    }
  }, [getProjectGroups, editGroupSection])

  const deleteGroupSection = (data) => {
    swal({
      title: `Tem certeza que você deseja deletar o Grupo ${data.section}?`,
      text: "Uma vez excluído, todos os times serão deletados!",
      icon: "info",
      buttons: ["Não", "Sim"],
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) deleteGroup(data)
      });
  }

  const deleteGroup = async (groupSection) => {
    try {
      const response = await api.delete(`groupSections/${groupSection.id}`)

      if (response.status === 200) {
        swal(`Grupo ${groupSection.section} deletado com sucesso!`, {
          icon: "success",
          button: "Fechar"
        });

        const groupSections = groups.filter(group => group.id !== groupSection.id)

        setGroups(groupSections)
      }
    } catch (error) {
      const { data } = error.response

      swal({
        title: "Atenção",
        text: data?.error,
        icon: "warning",
        button: "Fechar",
      });
    }
  }

  const setTeamDelete = (data) => {
    swal({
      title: `Tem certeza que você deseja deletar o Time ${data.name}?`,
      text: "Uma vez excluído, o time não poderá ser recuperado!",
      icon: "info",
      buttons: {
        cancel: {
          text: "Não",
          value: false,
          visible: true,
          closeModal: true,
        },
        confirm: {
          text: "Sim",
          value: true,
          closeModal: true,
        }
      },
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) deleteTeam(data)
      });
  }

  const deleteTeam = async (dataTeam) => {
    try {
      const response = await api.delete(`groups/${dataTeam.id}`)

      if (response.status === 200) {
        swal(`Time ${dataTeam.name} deletado com sucesso!`, {
          icon: "success",
          button: "Fechar"
        });

        getProjectGroups()
      }

    } catch (error) {
      const { data } = error.response

      swal({
        title: "Atenção",
        text: data?.error,
        icon: "warning",
        button: "Fechar",
      });
    }
  }

  const getUserList = useCallback(async (id_user_types, watcher) => {
    setSelectingManager(!watcher)
    
    setWatcher(watcher);
    
    const users = await api.get('userList', { params: { id_project: userProjectStore.id, id_users_type: id_user_types } })

    setUsers(users.data)
  },[userProjectStore.id])

  const setNewGroupDataFunc = useCallback((key, value) => {
    const _newGroupData = { ...newGroupData }
    _newGroupData[key] = value;
    setNewGroupData(_newGroupData)
  },[newGroupData])

  const setNewTeamDataFunc = useCallback((key, value) => {
    setNewTeamData((prevState) => ({...prevState, [key]:value}))
  },[])



  const createNewGroup = useCallback(async () => {

    if (!newGroupData.section) {
      setNewGroupDataFunc('error', 'Campo nome do grupo vazio')
      return;
    }
    if (!newGroupData.description) {
      setNewGroupDataFunc('error', 'Campo descrição do grupo vazio')
      return;
    }
    if (!newGroupData.color) {
      setNewGroupDataFunc('error', 'Campo cor vazio')
      return;
    }

    setNewGroupDataFunc('error', '')

    try {

      const response = await api.post('groupSections', { ...newGroupData, id_project: userProjectStore.id })

      if (response.status === 200) {
        setShowAlert(false)

        swal(`Grupo ${newGroupData.section} criado com sucesso!`, {
          icon: "success",
          button: "Fechar"
        });

        await getProjectGroups();

        setNewGroupData({
          section: '',
          description: '',
          color: '#EE5928',
          error: ''
        })
      }
    } catch (error) {
      const { data } = error.response

      swal({
        title: "Atenção",
        text: data?.error,
        icon: "warning",
        button: "Fechar",
      });
    }
  },[getProjectGroups, newGroupData, setNewGroupDataFunc, userProjectStore.id])

  const createNewTeam = useCallback(async () => {

    if (!newTeamData.name) {
      setNewTeamDataFunc('error', 'Campo nome do time vazio')
      return;
    }

    if (!newTeamData.state) {
      setNewTeamDataFunc('error', 'Campo estado vazio')
      return;
    }

    if (!newTeamData.city) {
      setNewTeamDataFunc('error', 'Campo cidade vazio')
      return;
    }

    if (!newTeamData.district) {
      setNewTeamDataFunc('error', 'Campo distrito vazio')
      return;
    }

    setNewTeamDataFunc('error', '')

    try {

      const response = await api.post('groups', {
        ...newTeamData, id_project: userProjectStore.id, section_id: groups[selectedGroup].id
      })

      if (response.status === 200) {
        setShowAlert(false)

        swal(`Time ${newTeamData.name} criado com sucesso!`, {
          icon: "success",
          button: "Fechar"
        });

        await getProjectGroups();

        setNewTeamData({
          id: '',
          name: '',
          city: '',
          district: '',
          state: '',
          error: ''
        })
      }
    } catch (error) {
      const { data } = error.response

      swal({
        title: "Atenção",
        text: data?.error,
        icon: "warning",
        button: "Fechar",
      });
    }
  },[getProjectGroups, groups, newTeamData, selectedGroup, setNewTeamDataFunc, userProjectStore.id])

  const getTeamsOfGroup = useCallback(async () => {
    if (groups.length > 0) {
      const group = await api.get('groupSections/' + groups[selectedGroup].id)
      setGroup(group.data)
    }
  },[groups, selectedGroup])

  const setDataForRemoval = useCallback((userHasTeam, add,userId, groupId, teamId) => {
    setOpenTeamModal(true)
    setUserId(userId)
    setGroupId(groupId)
    setTeamId(teamId)
    setUserHasTeam(userHasTeam)
    setAdd(add)
  },[])

  useEffect(() => {
    if(hasTeam !== null && add !== null) {
      openModalTeam()
    }else {
      closeTeamModal()
    }
  },[hasTeam, openModalTeam, add])

  const reloadGroup = () => {
    getTeamsOfGroup();
    getProjectGroups();
  }

  const toggleUserToGroup = useCallback(async (user_id, group_id, group, user_type) => {
    const isAlsoAViewer = (group && group?.admins?.some(elm => elm.id === user_id));
    const coordinator = group.id_coordinator === user_id && user_type === 3;
    const manager = group.id_manager === user_id && user_type === 4;    
    const managerOrCoordinator = user_type === 3 || user_type === 4;

    // Insere e Remove como visualizador usuários que não são coodernadores e nem gerentes
    if (!managerOrCoordinator) {
      if (!isAlsoAViewer && !selectingManager) {        
        await api.post('groupSections/user/add', { section_id: group_id, user_id, watcher: watcher });
      }   
      else { 
        await api.post('groupSections/user/remove', { section_id: group_id, user_id });  
      }
    } else {
      // Insere como gerente ou coodernator ao receber o watcher como false
      if (selectingManager && !coordinator && !manager) {
        // Checa se o gerente ou coodenador é um visualizador para poder retira-lo
        if (isAlsoAViewer) {
          await api.post('groupSections/user/remove', { section_id: group_id, user_id });
        }

        // Adiciona como gerente ou coodernador pois o watcher é false 
        await api.post('groupSections/user/add', { section_id: group_id, user_id, watcher: watcher });
      } else {
        if (coordinator || manager) {
          await api.post('groupSections/user/remove', { section_id: group_id, user_id });


          if (coordinator) {   
            await api.put(`groupSections/${group_id}`, { id_coordinator: null });
          } else {   
            await api.put(`groupSections/${group_id}`, { id_manager: null });
          }
        } else {
          // Se for um gerente o coordendador que esteja como visualizador ele remove
          if (isAlsoAViewer) {
            await api.post('groupSections/user/remove', { section_id: group_id, user_id });
          }
        }
      }
    
      if (!isAlsoAViewer && !selectingManager) {      

        // Checa se o usuário que está sendo colocado como visualizador está como gerente ou coodernador de algum grupo para retira-lo
        if (coordinator || manager) {
          await api.post('groupSections/user/remove', { section_id: group_id, user_id });
          
          if (coordinator) {   
            await api.put(`groupSections/${group_id}`, { id_coordinator: null });
          } else {   
            await api.put(`groupSections/${group_id}`, { id_manager: null });
          }
        }  
        
        await api.post('groupSections/user/add', { section_id: group_id, user_id, watcher: watcher });
      }
    }     
    
    reloadGroup();
  },[getProjectGroups, getTeamsOfGroup, watcher])

  const getTeam = useCallback(async () => {

    const {teams} = group
    if(teams) {
      const team = await api.get('/groups/' + teams[selectedTeamIndex].id)
      setTeam(team.data)
    }

  },[group, selectedTeamIndex])

  const toggleUserToTeam = useCallback(async (id_user, team_id, add, group_id, hasTeam) => {
    if (add) {    
      try {
        if(!!hasTeam) {
          if (openModalTeam === false) {
            setOpenTeamModal(true)
          }

          setDataForRemoval(!!hasTeam, add, id_user,group_id, team_id)
        }else {
          await api.post('groupSections/user/add', { id_group: team_id, user_id: id_user, 
            section_id: group_id, id_project: userProjectStore.id}
          );
        }
      } catch (error) {
        const data = error?.response?.data 

        swal({
          title: "Atenção",
          text: data?.error ?? 'Algo deu errado!',
          icon: "info",
          button: "Fechar",
        });
      }
    }
    else
      setDataForRemoval(false, add, id_user,group_id, team_id)
    getTeam();
    getProjectGroups();
  },[getProjectGroups, getTeam, setDataForRemoval])


  const finishAddingUserToTeam = async() => {
    try {
      const team_id = teamId
      const user_id = userId
      const group_id = groupId

      await api.post('groupSections/user/add', { id_group: team_id, user_id, section_id: group_id, id_project: userProjectStore.id });
      getTeam();
      getProjectGroups();
      setDataForRemoval(null, null, null, null, null)

    } catch (error) {
      const data = error?.response?.data 
       
      setDataForRemoval(null, null, null, null, null)

      swal({
          title: "Atenção",
          text: data?.error ?? 'Algo deu errado!',
          icon: "info",
          button: "Fechar",
      });
    }
  }

  const finishRemovingUserFromTeam = async() => {
    const user_id = userId
    const group_id = groupId
    const team_id = teamId

    await api.post('groupSections/user/remove', { section_id: group_id, user_id, id_project: userProjectStore.id, id_group: team_id });
    setDataForRemoval(null, null, null, null, null)
    getTeam();
    getProjectGroups();
  }

  const getCounties = async () => {
    try {
      const cities = await api.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados/' + newTeamData.state + '/municipios')  
      
      if (cities?.data?.length > 0) {
        return cities.data
      } else {
        return []
      }
    } catch (error) {
      return []
    }
  }

  const getCities = useCallback(async () => {
    try {
      const cities = await getCounties()  
      
      if (cities?.length > 0) {
        setCities(cities)
      } else {
        setCities([])
      }

    } catch (error) {
      setCities([])
    }
  },[newTeamData.state])

  useEffect(() => {
    getTeamsOfGroup();
  }, [getTeamsOfGroup, selectedGroup])
  useEffect(() => {
    getProjectGroups();
    getUserList([3, 4, 5, 6], true);
  }, [getProjectGroups, getUserList])

  useEffect(() => {
    getCities()
  }, [getCities, newTeamData.state])

  useEffect(() => {
    if (selectedTeamIndex !== false) {
      getTeam();
    }
  }, [getTeam, selectedTeamIndex])

  
  const goToEditUserPage = (userId) => {
    window.open(`/UsersRegister/${userId}`, '_blank')
  } 

  function capitalize(string) {
    const words = string.split(" ")
    
    const name = words.map(word => {
      if(word.length > 2 || word.length == 2 && word.charAt(0) === "D") {
        const lower = word.toLowerCase();
        return word.charAt(0).toUpperCase() + lower.slice(1);
      } else {
        return word
      }
    })

    return name.join(" ")
    
  }


  const renderModal = useCallback(() => {
    // eslint-disable-next-line default-case
    switch (selectedModal) {

      case 0: return

      case 1: return ( // AddUserOnTeam
        <Modal
          showAlert={showAddUserModal}
          close={() => {
            setShowAddUserModal(false);
          }}
          buttonText="Concluir"
          button={() => { setShowAddUserModal(false) }}
          color={selectedGroupColor}>

          <ModalAddUser color={selectedGroupColor}>
            <SearchGroupContainer color={selectedGroupColor}>
              <SearchGroupInput onChange={(t) => { setSearchUser(t.target.value) }} placeholder="Procurar usuários" />
              <Search />
            </SearchGroupContainer>
            {
              addUserOnTeam ?
                users
                  .filter(user => user.fullname.toUpperCase().includes(searchUser.toUpperCase()))
                  .sort(user => (team && team.users && team.users.some(elm => elm.id === user.id)) === true ? -1 : 1)
                  .map((user, index) => (
                    <UserButton
                      key={index}
                      style={team && team.users && team.users.some(elm => elm.id === user.id) ? { border: '3px solid ' + selectedGroupColor } : null}
                      onClick={() => {
                        toggleUserToTeam(user.id, group.teams[selectedTeamIndex].id, !(team && team.users && team.users.some(elm => elm.id === user.id)),groups[selectedGroup].id, user.id_team)
                      }}
                      shadow={true}
                      fullname={user.fullname}
                      cargo={user.cargo} />
                  ))
                :
                users
                  .filter(user => user.fullname.toUpperCase().includes(searchUser.toUpperCase()))
                  .sort(user => (group && group.admins.some(elm => elm.id === user.id)) === true ? -1 : 1)
                  .map((user, index) => (
                    <UserButton
                      key={index}
                      style={ (!selectingManager?(group && group?.admins?.some(elm => elm.id === user.id)): (group && (user.id === group?.coordinator?.id || user.id === group?.manager?.id))) ? { border: '3px solid ' + selectedGroupColor } : null}
                      onClick={() => {
                        if(selectingManager) {
                          setShowAddUserModal(false)
                        }

                        toggleUserToGroup(user.id, groups[selectedGroup].id, group, user.id_users_type)
                      }}
                      shadow={true}
                      fullname={user.fullname}
                      cargo={user.cargo} />
                  ))
            }
          </ModalAddUser>
        </Modal>
      )

      case 2: return ( // AddNewGroup Or AddNewTeam
        <Modal
          showAlert={showAlert}
          style={newGroup ? {} : { height: 'auto' }}
          close={() => {
            setShowAlert(false);
            newGroup ?
              setNewGroupData({
                section: '',
                description: '',
                color: '#EE5928',
                error: ''
              })
              :
              setNewTeamData({
                id: '',
                name: '',
                city: '',
                district: '',
                state: '',
                error: ''
              })
          }}
          color={selectedGroupColor}
          customButton={() =>
            <>
              <button
                className="modalFieldButton"
                onClick={() => { newGroup ? createNewGroup() : createNewTeam() }}
                style={selectedGroupColor ? { backgroundColor: selectedGroupColor } : { backgroundColor: '#EE5928' }}
              >
                Salvar
              </button>
              <button
                className="modalFieldButton"
                style={selectedGroupColor ? { backgroundColor: selectedGroupColor } : { backgroundColor: '#EE5928' }}
                onClick={() => {
                  setShowAlert(false);
                  newGroup ?
                    setNewGroupData({
                      section: '',
                      description: '',
                      color: '#EE5928',
                      error: ''
                    })
                    :
                    setNewTeamData({
                      id: '',
                      name: '',
                      city: '',
                      district: '',
                      state: '',
                      error: ''
                    })
                }}
              >
                Cancelar
              </button>

            </>
          }
        >
          <InnerModal>
            <div>
              {
                newGroup ?
                  <>
                    <h1 style={{ margin: 0, paddingBottom: 15, color: '#999' }}>Criar Grupo</h1>
                    <input onChange={(elm) => setNewGroupDataFunc('section', elm.target.value)} defaultValue={newGroupData.section} placeholder={"Nome do Grupo"} className="inputRounded" />
                    <textarea onChange={(elm) => setNewGroupDataFunc('description', elm.target.value)} defaultValue={newGroupData.description} placeholder="Descrição" className="inputRounded">

                    </textarea>
                    <div className="colorPickerContainer">
                      <label className='groupColor'>Cor do grupo</label>
                      <input onBlur={(elm) => { setNewGroupDataFunc('color', elm.target.value) }} defaultValue={newGroupData.color} className="colorPicker" type="color" />
                    </div>
                  </>
                  :
                  <>
                    <div className="TeamContainer">
                      <h1 style={{ margin: 0, paddingBottom: 15, color: '#999' }}>Criar Time</h1>
                      <input onChange={(elm) => setNewTeamDataFunc('name', elm.target.value)} defaultValue={newTeamData.name} placeholder={"Nome do Time"} className="inputRounded" />
                      <div className="stateAndCity">
                        <select onChange={(elm) => setNewTeamDataFunc('state', elm.target.value)} defaultValue={newTeamData.state} placeholder={"Estado"} className="inputRounded" >
                          <option style={{ color: 'gray' }} value=''>UF...</option>
                          {states.map((elm, index) =>
                            <option key={index} value={elm.sigla}>{elm.sigla}</option>
                          )}
                        </select>
                        <select onChange={(elm) => setNewTeamDataFunc('city', elm.target.value)} defaultValue={newTeamData.city} placeholder={"Cidade"} className="inputRounded">

                          <option style={{ color: 'gray' }} value=''>Cidade...</option>
                          {cities.map((elm, index) =>
                            <option key={index} value={elm.nome}>{elm.nome}</option>
                          )}
                        </select>
                      </div>
                      <input onChange={(elm) => setNewTeamDataFunc('district', elm.target.value)} defaultValue={newTeamData.district} placeholder={"Distrito"} className="inputRounded" />
                    </div>
                  </>
              }
              <label className="errorLabel">{newGroup ? newGroupData.error : newTeamData.error}</label>
            </div>
          </InnerModal>
        </Modal>
      )

      case 3: return ( // EditGroup
        <Modal
          showAlert={showAlert}
          style={editOneTeam ? { height: 'auto' } : {}}
          close={() => {
            setShowAlert(false);
            editOneTeam ?
              setNewTeamData({
                id: '',
                name: '',
                city: '',
                district: '',
                state: ''
              })
              : setEditGroupSection({
                id: '',
                section: '',
                description: '',
                color: '#EE5928',
                id_project: '',
                error: '',
              })
          }}
          color={selectedGroupColor}
          customButton={() =>
            <>
              <button
                className="modalFieldButton"
                onClick={() => {
                  editOneTeam ? editTeam() : editGroup()
                }}
                style={selectedGroupColor ? { backgroundColor: selectedGroupColor } : { backgroundColor: '#EE5928' }}
              >
                Salvar
              </button>
              <button
                className="modalFieldButton"
                style={selectedGroupColor ? { backgroundColor: selectedGroupColor } : { backgroundColor: '#EE5928' }}
                onClick={() => {
                  setShowAlert(false);
                  editOneTeam ?
                    setNewTeamData({
                      id: '',
                      name: '',
                      city: '',
                      district: '',
                      state: ''
                    })
                    : setEditGroupSection({
                      id: '',
                      section: '',
                      description: '',
                      color: '#EE5928',
                      id_project: '',
                      error: '',
                    })
                }}
              >
                Cancelar
              </button>

            </>
          }
        >
          <InnerModal>
            <div>
              {
                editOneTeam ?
                  <div className="TeamContainer">
                    <h2 style={{ margin: 0, paddingBottom: 15, color: '#999' }}>Editar Time</h2>
                    <input onChange={(elm) => setNewTeamData({ ...newTeamData, name: elm.target.value, error: '' })} defaultValue={newTeamData.name} placeholder={"Nome do Time"} className="inputRounded" />
                    <div className="stateAndCity">
                      <select onChange={(elm) => setNewTeamData({ ...newTeamData, state: elm.target.value, error: '' })} defaultValue={newTeamData.state} placeholder={"Estado"} className="inputRounded" >
                        <option style={{ color: 'gray' }} value=''>UF...</option>
                        {
                          states                        
                            .map((elm, index) =>
                               <option key={index} value={elm.sigla}>{elm.sigla}</option>
                            )}
                      </select>
                      <select onChange={(elm) => setNewTeamData({ ...newTeamData, city: elm.target.value, error: '' })} defaultValue={newTeamData.city} placeholder={"Cidade"} className="inputRounded">

                        {/* <option style={{ color: 'gray' }} value=''>Cidade...</option> */}
                        {cities.map((elm, index) =>
                          <option key={index} value={elm.nome} selected={newTeamData.city === elm.nome}>{elm.nome}</option>
                        )}
                      </select>
                    </div>
                    <input onChange={(elm) => setNewTeamData({ ...newTeamData, district: elm.target.value, error: '' })} defaultValue={newTeamData.district} placeholder={"Distrito"} className="inputRounded" />
                  </div>
                  : <>
                    <h2 style={{ margin: 0, paddingBottom: 15, color: '#999' }}>Editar Grupo</h2>
                    <input style={{ color: 'black' }} onChange={(elm) => setEditGroupSection({ ...editGroupSection, section: elm.target.value, error: '' })} defaultValue={editGroupSection.section} placeholder={"Nome do Grupo"} className="inputRounded" />
                    <textarea onChange={(elm) => setEditGroupSection({ ...editGroupSection, description: elm.target.value, error: '' })} defaultValue={editGroupSection.description} placeholder="Descrição" className="inputRounded">

                    </textarea>
                    <div className="colorPickerContainer">
                      <label className='groupColor' style={{ color: 'black' }}>Cor do grupo</label>
                      <input onBlur={(elm) => { setEditGroupSection({ ...editGroupSection, color: elm.target.value, error: '' }) }} defaultValue={editGroupSection.color} className="colorPicker" type="color" />
                    </div>
                  </>
              }
              <label className="errorLabel" >{editOneTeam ? newTeamData.error : editGroupSection.error}</label>
            </div>
          </InnerModal>
        </Modal>
      )
    }
  }, [selectedModal, showAddUserModal, selectedGroupColor, addUserOnTeam, users, showAlert, newGroup, newGroupData.section, newGroupData.description, newGroupData.color, newGroupData.error, newTeamData, states, cities, editOneTeam, editGroupSection, searchUser, team, toggleUserToTeam, group, selectedTeamIndex, groups, selectedGroup, selectingManager, toggleUserToGroup, createNewGroup, createNewTeam, setNewGroupDataFunc, setNewTeamDataFunc, editTeam, editGroup])



  return (
    <>
      {renderModal()}
      <GroupRemovalModal partOfGroup={hasTeam} active={openTeamModal} handleClickSave={add ? () => finishAddingUserToTeam(): () => finishRemovingUserFromTeam()} handleClickClose={() => closeTeamModal()} />

      <Styles>
        <GroupHeader>
          <div className="headerContainer">
            <p className="title"></p>
            <button onClick={() => {
              setSelectedModal(2);
              setNewGroup(true);
              setShowAlert(true);
              setSelectedGroupColor(false);
            }}
            >
              Novo grupo
            </button>
          </div>
        </GroupHeader>
        <MainContainer>
          <GroupsContainer>
            <SearchGroupContainer>
              <SearchGroupInput onChange={(t) => { setSearchGroup(t.target.value) }} placeholder="Procurar grupo" />
              <Search />
            </SearchGroupContainer>
            {groups.filter(elm => !searchGroup || elm.section.toLowerCase().includes(searchGroup.toLowerCase())).map((group, index) =>
              <GroupButton
                style={{minHeight: '70px', height:'auto'}}
                key={index}
                onClick={() => {
                  setSelectedGroup(index)
                  setSelectedTeam(false)
                }}
                selected={selectedGroup === index} color={group.color}
              >
                <div>
                  <div>
                    <p className="groupName">{capitalize(group.section)}</p>
                    <div className="btnIcons">
                      <div className="backgroundIconColor">
                        <Edit
                          className="btnEditGroup"
                          onClick={() => {
                            setSelectedGroupColor(group.color)
                            setEditOneTeam(false)
                            setShowAlert(true)
                            setSelectedModal(3)
                            updateGroupSection(group)
                          }}
                        />
                        <span className="tooltipEditGroup">Editar Grupo</span>
                      </div>
                      <div className="backgroundIconColor">
                        <DeleteIcon
                          className="btnDeleteGroup"
                          onClick={() => {
                            setSelectedGroupColor(group.color)
                            deleteGroupSection(group)
                          }}
                        />
                        <span className="tooltipDeleteGroup">Deletar Grupo</span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <p className="members">Membros</p>
                    <p className="membersQty">{group.total_users}</p>
                  </div>
                </div>
              </GroupButton>
            )}
          </GroupsContainer>
          <GroupInfoContainer>
            {groups[selectedGroup] &&
              <>
                <GroupTitle color={groups[selectedGroup].color}>{capitalize(groups[selectedGroup].section)}</GroupTitle>
                <GroupDetailContainer>
                  <SubHeaderGroups color={groups[selectedGroup].color}>
                    <p>Gerência</p>

                  </SubHeaderGroups>

                  <UserContainer>
                    {group.manager ?
                      <>
                        <div style={{ marginBottom: 10 }}>
                          <UserButton
                            fullname={group.manager.fullname}
                            cargo={group.manager.cargo}
                            onClick={() => {                              
                              setShowAddUserModal(true);
                              setSelectedGroupColor(groups[selectedGroup].color);
                              setAddUserOnTeam(false);
                              setUsers([]);
                              getUserList([4], false);
                              setSearchUser('')
                              setSelectedModal(1)
                            }} 
                            style={{ 
                               marginBottom: 8                               
                            }}
                          />
                            <span 
                              style={{ 
                                color: '#777',
                                cursor: 'pointer',
                                fontSize: 11,                               
                              }}
                              onClick={() => goToEditUserPage(group.manager.id)}                        
                            >
                              Ver informações do gerente
                            </span>
                          </div>
                      </>
                      :
                      <UserSlot cargo="Gerente" onClick={() => {
                        setShowAddUserModal(true);
                        setSelectedGroupColor(groups[selectedGroup].color);
                        setAddUserOnTeam(false);
                        setUsers([]);
                        getUserList([4], false);
                        setSearchUser('')
                        setSelectedModal(1)
                      }} />
                    }
                    {group.coordinator ?
                      <>
                        <div>
                          <UserButton
                            fullname={group.coordinator.fullname}
                            cargo={group.coordinator.cargo}
                            onClick={() => {                              
                              setShowAddUserModal(true);
                              setSelectedGroupColor(groups[selectedGroup].color);
                              setAddUserOnTeam(false);
                              setUsers([]);
                              getUserList([3], false);
                              setSearchUser('')
                              setSelectedModal(1)
                            }}
                            style={{ 
                               marginBottom: 8                              
                            }}
                          />
                           <span 
                              style={{ 
                                color: '#777',
                                cursor: 'pointer',
                                fontSize: 11,                                
                              }}
                              onClick={() => goToEditUserPage(group.coordinator.id)}                        
                            >
                              Ver informações do coordenador
                            </span>
                        </div>
                      </>
                      :
                      <UserSlot
                        cargo="Coordenador"
                        onClick={() => {
                          setShowAddUserModal(true);
                          setSelectedGroupColor(groups[selectedGroup].color);
                          setAddUserOnTeam(false);
                          setUsers([]);
                          getUserList([3], false);
                          setSearchUser('')
                          setSelectedModal(1)
                        }} />
                    }
                  </UserContainer>
                  <SubHeaderGroups color={groups[selectedGroup].color}>
                    <p>Visualizadores</p>

                    <AddCircleIcon
                      className="addCircleIconAdminTeam"
                      onClick={() => {
                        setShowAddUserModal(true);
                        setSelectedGroupColor(groups[selectedGroup].color);
                        setAddUserOnTeam(false);
                        setUsers([]);
                        getUserList([3, 4, 5, 6, 7], true);
                        setSearchUser('')
                        setSelectedModal(1)
                      }
                      } />

                    <span className="tooltipCreateOrAddAdminTeam">
                      <div>Adicionar/</div>
                      <div>Remover</div>
                    </span>
                  </SubHeaderGroups>
                  <UserContainer>
                    {group.admins.map((elm, index) =>
                      <UserButton
                        key={index+elm.id}
                        onClick={() => goToEditUserPage(elm.user_id)}
                        fullname={elm.fullname}
                        cargo={elm.cargo} />
                    )}
                  </UserContainer>

                  {group &&
                    <SubHeaderGroups color={groups[selectedGroup].color}>
                      {selectedTeamIndex === false ?
                        <p>Times</p>

                        :
                        <div className="teamBackContainer">
                          <ArrowBackIos onClick={() => setSelectedTeam(false)} />
                          <p>{group.teams[selectedTeamIndex].name}</p>
                          <div className="btnIconsTeamContainer">
                            <div className="btnIconsTeam">
                              <div className="backgroundIconColor">
                                <Edit
                                  onClick={() => {
                                    setSelectedGroupColor(group.color)
                                    setEditTeam(group.teams[selectedTeamIndex])
                                  }}
                                  className="btnEditTeam"
                                />
                                <span className="tooltipEditTeam">Editar Time</span>
                              </div>
                              <div className="backgroundIconColor">
                                <DeleteIcon
                                  onClick={() => {
                                    setSelectedGroupColor(group.color)
                                    setTeamDelete(group.teams[selectedTeamIndex])
                                  }}
                                  className="btnDeleteTeam"
                                />
                                <span className="tooltipDeleteTeam">Deletar Time</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      }

                      <AddCircleIcon
                        className="addCircleIconTeam"
                        onClick={() => {
                          setSelectedGroupColor(groups[selectedGroup].color)
                          if (selectedTeamIndex === false) {
                            setNewGroup(false)
                            setShowAlert(true)
                            setSelectedModal(2)

                          } else {
                            setShowAddUserModal(true)
                            setAddUserOnTeam(true)
                            setUsers([])
                            getUserList([1, 2], true)
                            setSearchUser('')
                            setSelectedModal(1)
                          }
                        }}
                        color={groups[selectedGroup].color}

                      />

                      <span className="tooltipCreateOrAddUserTeam">
                        {selectedTeamIndex === false ? 'Criar time' :
                          <>
                            <div>Adicionar/</div>
                            <div>Remover</div>
                          </>

                        }
                      </span>

                    </SubHeaderGroups>
                  }
                  <TeamContainer>
                    {selectedTeamIndex === false ?
                      <>
                        {
                          group.teams.length > 0 ?
                            group.teams && group.teams.map((elm, index) =>
                              <TeamButton
                                key={index}
                                onClick={() => setSelectedTeam(elm.id)}
                                color={groups[selectedGroup].color}
                              >
                                <div className="teamContainer">
                                  <div className="teamSimbol">
                                    <SecurityTwoTone style={{fill: groups[selectedGroup].color}}/>
                                  </div>
                                  {/* <div className="teamName">  */}
                                    <span>{elm.name}</span>
                                  {/* </div> */}
                                  <div id="teamMemberCountContainer">
                                    <span>Membros</span>
                                    <span>{elm.total_users}</span>
                                  </div>
                                </div>
                              </TeamButton>
                            )
                            : <p>Nenhum time foi criando ainda</p>
                        }
                      </>
                      :
                      <>
                        {
                          team && team.users && team.users.length > 0 ? team.users.map((elm, index) =>

                            <UserButton
                              key={elm.fullname + index}
                              fullname={elm.fullname}
                              cargo={elm.cargo}
                              onClick={() => goToEditUserPage(elm.user_id)}
                            />

                          ) : <p>Nenhum usuário no time</p>

                        }
                      </>
                    }
                  </TeamContainer>
                </GroupDetailContainer>
              </>
            }
          </GroupInfoContainer>
        </MainContainer>
      </Styles>
    </>
  )
}

export default Regions;

const UserContainer = styled.div`

flex-direction: row;
width:95%;
justify-content:space-between;
flex-flow: row wrap;
  ::after {
    content:"";
    flex:auto;
    max-width:25%;
    margin-left: 20px;
  }

`

const TeamContainer = styled.div`
  display: flex;
  flex-direction: row;
  width:95%;
  flex-wrap: wrap;
  // justify-content: space-between;

`
const TeamButton = styled.button`
  margin: 0 10px 15px 0;
  width: 23.5%;
  box-shadow: 2px 2px 10px #00000030;
  background-color:#fff;
  border-radius: 8px;

  div{
    height:100%;
    flex-direction: row;
    align-items: center;
    justify-content:space-between;
    width:100%;

    span {
      width: 60% !important;
      font-weight:bold;
      color:${({ color }) => color ?? '#EE5928'};
      text-align: center;
    }

    div {
      span:first-child {
        color:#aaa;
        font-size:10px;
        width: 100% !important;
        text-align: right;
      }
      span:last-child {
        color:#888;
        font-weight: bold;
        font-size:12px;
        width: 100% !important;
        text-align: right;
      }

    }
  }
`

const SearchGroupContainer = styled.div`
margin-top: 10px;
width: 100%;
height: 35px;
margin-bottom:10px;
align-items: flex-end;
justify-content: space-between;
flex-direction: row;
border-bottom: 1px solid  ${({ color }) => color ?? '#EE5928'};
svg{

  fill:#888;
  margin-bottom: 2px;
}
`
const SearchGroupInput = styled.input`
height: 20px;
width: 80%;
border-radius: 0px;
::placeholder{

  color: #888;

}

background-color:#0000;
`
const GroupDetailContainer = styled.div`
width: 100%;
align-items: center;
`

const GroupButton = styled.button`
  display: flex;
  align-items:center;
  justify-content:center;
  width: 100%;
  height:70px;
  border-radius: 10px;
  box-shadow: 2px 2px 7px #00000022;
  background-color:#fff;
  border: ${({ selected, color }) => selected ? '3px solid ' + color : ''};
  padding:  ${({ selected }) => selected ? '3px' : '4px'};
  margin-bottom: 10px;

div {
  align-items:center;
  justify-content:center;
  display: flex;
  justify-content:space-between;
  width:98%;
  height:100%;
  div {
    flex-direction: row;
  }
 
  p{margin:0}

  div.btnIcons {
    width: 30%;
    display: flex;
    justify-content: flex-end;
    position: relative;

    span.tooltipDeleteGroup, span.tooltipEditGroup {
      visibility: hidden;
      width: 85px;
      background-color: ${({ color }) => color ?? '#EE5928'};
      color: #fff;
      text-align: center;
      border-radius: 5px;
      padding: 6px 1px;
      font-size: 12px;

      /* Position the tooltip */
      position: absolute;
      z-index: 1;
      bottom: 87%;
      left: 50%;
      margin-left: -50px;
    }
    
    span.tooltipDeleteGroup {     
      left: 90%;
    }

    .btnEditGroup {  
      :hover + .tooltipEditGroup {
        visibility: visible;
        opacity: initial;

      } 
    }   

    .btnDeleteGroup {     
      :hover + .tooltipDeleteGroup {
        visibility: visible;
        opacity: initial;
      } 
    }
  }


  div.backgroundIconColor:first-child {
    margin-right: 8px;
  }

  div.backgroundIconColor {
    display: flex;
    justify-content:center;
    align-items:center;
    width:19px;
    height:19px;
    border-radius: 10px;
    // background-color:${({ color }) => color ?? '#EE5928'};
    background-color: #ccc;

    svg{
      font-size: 13px;
      fill:#fff;
      position:absolute;
    }

    
  }
  p.groupName{
      font-weight: bold ;
      font-size:16px;
      overflow-wrap: break-word;
      word-break: break-word;
      text-align: left;
      overflow: hidden;
      max-width:70%;
      color:${({ color }) => color ?? '#EE5928'};
      text-shadow: 0px 0px 1px #00000040;
      text-transform: capitalize;
  } 
  p.members{
      font-size:12px;
      color:#bbb;

  } 
  p.membersQty{
    font-size:15px;
    font-weight:bold;
    color:#999;
    margin-right: 2px;

  }

}

`
const ModalAddUser = styled.div`
::-webkit-scrollbar-thumb {
    background: ${({ color }) => color ?? '#EE5928'};
    border-radius: 15px;
}
  overflow: auto;
  width: 90%;
  height: 80%;
  flex-direction :row;
  flex-wrap: wrap;
  justify-content: center;
  

`
const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  flex-direction: row;
  justify-content: space-between;
`
const SubHeaderGroups = styled.div`
width: 95%;
flex-direction: row;
align-items: center;
justify-content:space-between;
position: relative;

  svg{

  font-size:20px;
  color:${({ color }) => color ?? '#EE5928'};
    :hover{
    
    opacity: 0.5;
    cursor: pointer;
    
    }
  }

  .teamBackContainer{

      flex-direction: row;
      align-items:center;

      svg {
        :hover {
          cursor: pointer;
          opacity:0.5;
        }
        font-size:20px;
        fill:${({ color }) => color ?? '#EE5928'};
      }
  }

  p {
    margin: 10px 0px;
    text-align: start;
    font-size: 18px;
    font-weight: bold;
    color:${({ color }) => color ?? '#EE5928'};
  }

  div.btnIconsTeamContainer {
    display: flex;
    flex-direction: row;
  }

  div.btnIconsTeam {
    padding-left: 8px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    div.backgroundIconColor:first-child {
       margin-right: 10px;
    }

    div.backgroundIconColor {
      width:18px;
      height:18px;
      align-items:center;
      justify-content:center;
      position: relative;
      
      svg {
        font-size: 20px;
        position: absolute;

      }      
     
      span.tooltipEditTeam, span.tooltipDeleteTeam {
        visibility: hidden;
        width: 85px;
        background-color: ${({ color }) => color ?? '#EE5928'};
        color: #fff;
        text-align: center;
        border-radius: 5px;
        padding: 6px 1px;
        font-size: 12px;
  
        /* Position the tooltip */
        position: absolute;
        z-index: 1;
        bottom: 100%;
        left: 50%;
        margin-left: -50px;
      }
       
      > .btnEditTeam {
        :hover {
          cursor: pointer;
          opacity: 0.5;
        }

        :hover + .tooltipEditTeam {
          visibility: visible;
        } 
      }   

      > .btnDeleteTeam {
        :hover {
          cursor: pointer;
          opacity: 0.5;
        }

        :hover + .tooltipDeleteTeam {
          visibility: visible;
        } 
      }
    }
  }

  span.tooltipCreateOrAddUserTeam, span.tooltipCreateOrAddAdminTeam {
    visibility: hidden;
    width: 77px;
    background-color: ${({ color }) => color ?? '#EE5928'};
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 6px 1px;
    font-size: 12px;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    bottom: 75%;
    left: 98%;
    margin-left: -50px;
  }

  .addCircleIconTeam {   
    margin-right: 15px;
    :hover + .tooltipCreateOrAddUserTeam {
      visibility: visible;
    }           
  }

  .addCircleIconAdminTeam {   
    margin-right: 15px;
    :hover + .tooltipCreateOrAddAdminTeam {
      visibility: visible;
    }           
  }
`
const GroupInfoContainer = styled.div`
  box-shadow: 2px 4px 15px #00000022;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  align-items: center;
  padding: 20px 0;
`
const GroupsContainer = styled.div`
  align-items: center;
  box-shadow: 2px 4px 15px #00000022;
  width: 30%;
  height: 100%;
  border-radius: 15px;
  margin-right: 15px;
  padding: 20px;
`
const GroupTitle = styled.h2`

color:${({ color }) => color ?? '#EE5928'};
     
`;

const GroupHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-shadow: 2px 4px 15px #00000022;
  margin-bottom:15px;
  border-radius: 15px;
  align-items:center;
  padding: 20px 30px;
  margin-top:10px;
div.headerContainer{

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  // padding: 15px 35px;
  
  p.title{
    font-size: 20px;
  }
  button{
    background-color: var(--color-background-primary);
    color:white;
    padding: 5px 8px;
    border-radius: 20px;
    margin: 0;
  }
}

`
const InnerModalDelete = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  line-height: 45px;
  > h1 {
    text-align: center;
  }
`
const InnerModal = styled.div`

  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top:20px;
  div{
    display: flex;
    flex: 1;
    align-items: center;

    input.inputRounded {
      width :100% ;
    }

    .TeamContainer {
      width: 100%;
      flex:none;

    .stateAndCity {
      width: 100%;
      flex-direction: row;
      height: 50px;

      select:first {
        width:10%;
        display: flex;
        flex: 1;
      }

      select:last-child{
        width:90%;
        display: flex;
        flex: 1;
        margin-left: 10px;
      }
    }
  }

  textarea{
    width:100%;
  }
  .colorPickerContainer{
    margin-top: 10px;
    align-items: flex-start;
    width: 100%;
    input.colorPicker{
        width: 100%;
        border-radius: 10px;
   }
    label.groupColor{
      margin-bottom: 5px;
      color:#555;
    }
    
  }
  label.errorLabel{
    margin-top: 5px;
    color:#b00;
  }
}

`
const Styles = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 98%;
  align-items: center;
  div.topSlider{
    width: 98%;
    display: flex;
    flex-direction: row;
    overflow: auto;
    height:60px;
    align-items:center;
  }
  div.subGroups{
    display: flex;
    flex:1;
    width: 98%;
    flex-direction: row;
    justify-content:space-between;
    height:100%;
    div.teams{
      padding-bottom: 20px;
      display: block;
      width:25%;
      height: 70%;
      overflow: auto;
        div.teamsTitleContainer{
       
          display: flex;
          flex-direction: row;
          width:90%;
          justify-content:space-between;
          align-items:center;
        }
        div.addTeamButton{
          width:20px;
          height:20px;
          border-radius: 50px;
          align-items:center;
          color:#fff;
          background-color: var(--color-background-primary);
        }
        div.addTeamButton:hover{
          opacity: 0.6;
          cursor: pointer;
          user-select: none;
        }
        div.teamContainer{

          width: 90%;
          margin-top: 10px;
          border: 2px solid #aaa;
          height:60px;
          border-radius:10px;
          align-items:center;
          justify-content:center;
            span{
             font-size:18px;
             color:#666;
            }
        }
        div.teamContainer:hover{

          border-color:#EE592855;
          cursor: pointer;
            span{
             font-size:18px;
             color:#EE592855;
            }
        }
        div.teamContainer.Selected{

          border-color:var(--color-background-primary);
            span{
            
              font-size:18px;
              color:var(--color-background-primary);
            
            }
      }

      align-items: flex-start;
      p{
        color: var(--color-background-primary);
        font-weight: bold;
        font-size: 20px;
      }
    }
    div.infos{
      width:75%;
      align-items: center;
       div.titleContainer{
         flex-direction: row;
         width: 100%;
         justify-content:space-around;
         p.title{
           color: var(--color-background-primary);
           font-weight: bold;
           font-size: 20px;
         }
         div{
           flex-direction: row;
           svg{
             fill:var(--color-background-primary);
              margin-left : 10px;
            }
           svg:hover{
             cursor: pointer;
             opacity: 0.2;
           }
         }
       }
    }
    h1{
      color: var(--color-background-primary);
    }
  

  }

`
