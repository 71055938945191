import styled from'styled-components'

export const Root = styled.div`
  &::before{
    display: block;
    content: ""
  }
`

export const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  align-items: center;
  bottom: 0;
  justify-content: center;
  overflow: auto;
  right: 0;
  display: flex;
  opacity: 1;
  z-index: 400;
  padding-top: 25px;
  padding-bottom: 25px;
  margin: 0 auto; 
  display: flex;

  
`

export const Container = styled.div`
  background: white;
  width: 30%;
  min-height: 300px;
  border-radius: 10px;
  color: white;
  margin-top: auto;
  font-size: 1.4rem;
  padding: 3rem;
  margin-bottom: 10%;
  display: flex;
  flex-direction:column;
  align-items: center;
  justify-content: center;
  p{
    color:#EE5928;
  }
`

export const Header = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`

export const Main = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  text-align: center;

`