import styled from 'styled-components'
import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";


export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .termsAndUse {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    color: #AAAAAA;
    @media (max-width: 800px) {
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
    }
    @media (max-width: 600px) {
      min-width:auto;
      max-width: 270px;
    }
  }

`

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 30px 3fr 4fr 2fr;
  grid-auto-rows: 25px;
  margin-top: 5%;
  width: 80%;

  @media (max-width: 800px) {
    margin-top: 10%;
  }

  @media (max-width: 600px) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: center;
    width: 100%;
    margin-top: 0px;
  }

  .square{
    grid-row-start: 1;
    grid-row-end: 10;
    grid-column-start: 1;
    grid-column-end: 2;
    background-color: transparent;
    div{
      height: 203px;
      width: 203px;
      background-color: #EE5928;
    }
    @media (max-width: 600px) {
      display: none;
    }
  }

  .boxText{
    grid-row-start: 2;
    grid-row-end: 21;
    grid-column-start: 2;
    grid-column-end: 6;
    background-color:#222222;
    border-radius: 0px 0px 100px 100px;
    .labelDiv{
      @media (max-width: 600px) {
        width: 300px;
      }
    }
    @media (max-width: 600px) {
      grid-row-start: 1;
      grid-row-end: 30;
      grid-column-start: 1;
    }
    
    @media only screen and (min-device-width: 601px) and (max-device-width: 1024px){
      grid-row-end: 28;
    }


    .mainText {
      display: flex;
      flex-direction: column;
      padding: 50px;

      @media (max-width: 600px) {
        padding: 31px;
      }
      .firstLine {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        img {
          width: 90px;
          height: 100px;
          margin-top: -10px;
          display: inline-block;
          @media only screen and (min-device-width: 601px) and (max-device-width: 1024px){
            width: 60px;
            height: 80px;
            margin-top: -40px;
          }
          @media (max-width: 600px) {
            margin-top: -30px;
            width: 40px;
            height: 60px;
          }
        }
      }
      .thePrisma {
        margin-top: -20px;
        @media only screen and (min-device-width: 0px) and (max-device-width: 1024px) {
          margin-top: -10px;
        }
      }
      label {
        margin-left: 10px;
      }
      .textInput {
        width: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        p {
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          color: #555555;
        }
      }
    }
  }

  .buttonDiv{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    @media only screen and (min-device-width: 0px) and (max-device-width: 1024px){
      flex-direction: column;
    }
  }
`

export const Paragraph = styled.p`
  color:${props => props.primary ? "#FFFFFF" : "#EE5928"};
  font-size:${props => props.large ? "22px" : "16px"};
  display: inline-block;
  opacity: 0.8;
  line-height: 30px;
  a{
    color:#FFFFFF;
    cursor: pointer;
    text-decoration: underline
  }
`
export const Input = styled(CpfCnpj)`
  background-color: white;
  border: none;
  height: 65px;
  padding-left: 10px;
  width: 308px;
  margin: 20px;
  font-size: 16px;
  border-radius: 15px;
  ::placeholder {
    color:  #222222;
  }
  @media (max-width: 800px) {
  height: 50px;
  margin: 10px;
  font-size: 13px;
  border-radius: 10px;
  }
  @media (max-width: 600px) {
    width: 250px;
  }

`