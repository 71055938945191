import React,{useState,useContext, useEffect,useMemo} from 'react';
import styled from "styled-components";
import { DashboardContext } from '../..';

const FilterPicker = ({width, height,title,options,style,defaultValue,setSelected,selected}) => {
    const {useFilter} = useContext(DashboardContext);
    const {setGeneralSort,setGeneralFilter} = useFilter();
  
    useEffect(()=>{
      
        const checked = options.find((e)=> e.key === selected)

     
        
      if(checked?.sort){
        setGeneralSort(()=>checked?.sort)
        setGeneralFilter(null)
      }
       else if(checked?.filter){
        setGeneralFilter(()=> checked?.filter)
        setGeneralSort(null)
       }
    },[options, selected, setGeneralFilter, setGeneralSort])

    return <Container style={style}>
                <Title>{title}</Title>
                {
                    options?.map((e,i) => 
                        <CheckboxLine key={e.key}>
                            <Checkbox checked={selected === e.key} onChange={()=>setSelected(e.key)}/>
                            <CheckboxLabel>
                                {e?.label}
                            </CheckboxLabel>
                        </CheckboxLine>
                    )
                }
            </Container>
    
}
const CheckboxLine = styled.div`
    margin:5px;
    display:flex;
    flex-direction: row;
    align-items:center;
`
const CheckboxLabel = styled.label`
    margin-left:10px;
    font-size:14px;
    color:#777;
`
const Checkbox = styled.input.attrs({type: 'checkbox'})`

`
const Title = styled.label`
    position: absolute;
    background-color:#fff;
    padding:0px 8px;
    top:-8px;
    left:20px;
    font-weight:bold;
    font-size:14px;
    color: #EE5928;
`
 const Container = styled.div`
    padding:10px;
    min-height:50px;
    position: relative;
    border: 1px solid #D9D9D9;
    border-radius: 10px;
 `
export default FilterPicker;