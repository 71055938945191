import React, { useEffect, useState } from 'react';
import * as S from './styled';
import StorageIcon from '@material-ui/icons/Storage';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import ImportBilling from '../ImportBilling';
import api from "../../../services/api";
import { Card } from '../../../components/Styled-Components/Card';
import { toast } from 'react-toastify';
import { format } from 'date-fns';

const BillingTab = (props) => {
  const { billingData } = props;
  const [importBilling, setImportBilling] = useState(false)
  const [newTag, setNewTag] = useState('')
  const [tags, setTags] = useState([])
  const projectInfo = localStorage.getItem('ProjectInfo')
  const { id: id_project } = JSON.parse(projectInfo)

  const renderImportBilling = () => {
    setImportBilling(true)
  }

  useEffect(() => {
    api.get("/baseOutputs", { params: { id_project, type: 'TPV' } }).then(response => {
      setTags(response.data[0]?.names ?? [])
    })
  }, [id_project])

  const addTags = async () => {
    if (newTag === '') {
      toast.error('Não pode adicionar uma etiqueta vazia')
      return
    }
    await api.post(`/baseOutputs`, { id_project, type: 'TPV', names: [...tags, newTag] })
    setTags([...tags, newTag])
    setNewTag('')
  }
  const removeTag = async (tag) => {
    //find tag in array and remove 
    const newTags = tags.filter(item => item !== tag)
    await api.post(`/baseOutputs`, { id_project, type: 'TPV', names: newTags })
    setTags(newTags)
  }

  return (
    <>
      <S.TopDiv>
        <S.TopDivSpan>Historico de Importação</S.TopDivSpan>
        <S.TopDivButton 
          onClick={() => {
            if (tags?.length === 0) {
              toast.error('Antes de importar adicione as etiquetas!')
            } else {
              renderImportBilling()
            }
          }}
        >
          <StorageIcon />
          <span>Importar</span>
        </S.TopDivButton>
      </S.TopDiv>
      <S.CardDivs>
        {!importBilling && billingData?.map((data) => {
          return (
            <Card style={{ width: '90%' }}>
              <S.InsideCardTopDiv>
                <ImportContactsIcon />
                <S.InsideCardTextTopDiv>
                  <span>{format(new Date(data.updated_at),'dd/MM/yyyy hh:mm')}</span>
                </S.InsideCardTextTopDiv>
              </S.InsideCardTopDiv>
              <S.TextDiv>
                <span>Registros importados: {data.totalItems}</span>
                <span>Registros relacionados: {data.totalModified}</span>
              </S.TextDiv>
            </Card>
          );
        })}
      </S.CardDivs>
      {!importBilling && (
        <>
          <S.TopDiv>
            <S.TopDivSpan>Lista de Etiquetas</S.TopDivSpan>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <S.TagInput placeholder="Digite a nova etiqueta" value={newTag} onChange={(event) => setNewTag(event.target.value)} />
              <S.TopDivButton onClick={() => addTags()}>
                <AddIcon />
                <span>Adicionar</span>
              </S.TopDivButton>
            </div>

          </S.TopDiv>

          <S.TagsContainer>
            {tags?.map((tag) =>
              <S.Tags>
                <label>{tag}</label>
                <CloseIcon onClick={() => removeTag(tag)} />
              </S.Tags>
            )
            }
          </S.TagsContainer>
        </>
      )
      }
      {
        importBilling && (
          <ImportBilling />
        )
      }
    </>
  )
}

export default BillingTab