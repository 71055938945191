import styled from'styled-components'
import {Input, Label} from '../../components'

export const Root = styled.div`
  &::before{
    display: block;
    content: ""
  }
`

export const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  align-items: center;
  bottom: 0;
  justify-content: center;
  overflow: auto;
  right: 0;
  display: flex;
  opacity: 1;
  z-index: 400;
  padding-top: 25px;
  padding-bottom: 25px;
  margin: 0 auto; 
  display: flex;

  
`

export const Container = styled.div`
  background: transparent;
  width: 30%;

  border-radius: 10px;
  color: white;
  margin-top: auto;
  font-size: 1.4rem;
  padding: 3rem;
  margin-bottom: 10%;
  display: flex;
  p{
    color:#EE5928;
  }
`

export const MyInputModal = styled.textarea `
  height:150px;
  align-self: center;
  border:${props => (props.isEditing ? '1.5px solid #888888' : '0 none')};
  margin: 10px 0px 0px 15px;
  padding-left: 4px;
  padding: 5px;
  background-color: transparent;
  width: 70%;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  border-radius: 4%;
  color: ${props => (props.orange ? '#EE5928' : '#444444')};
  :disabled{
    background-color: white;
  }
`


export const MyLabel = styled(Label)`
  margin: 10px 0px 0px 15px;
  font-size: 12px;
  align-self: flex-start;
  margin-left: 70px;
`

export const Header = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: white;
  height: 60px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
`

export const Main = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  text-align: center;
  background-color: white;
  min-height: 300px;
  flex-direction: column;
`

export const ContainerGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, auto);
  grid-template-rows: repeat(7, auto);
  grid-gap: 10px;
  color: black;
  margin-top: 30%;
`

export const Footer = styled.div`
  color: black;
  background-color: white;
  height: 50px;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
  display: flex;
  
`

export const DivHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin: 10px;
  width: 100%;
  button{
    background-color: transparent;
    color: #EE5928;
  }
`

export const DivMain = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px;
  width: 100%;

`

export const SecondText = styled.p`
  font-size: 12px;
  line-height: 14px;
  color: #888888 !important;
`

export const DivFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 94%;
  margin: 10px;
`

export const ButtonModal = styled.button`
  background-color: #EE5928;
  text-transform: uppercase;
  color: white;
  height: 30px;
  width: auto;
  font-size: 12px;
  border-radius: 15px;
  opacity: 0.9;
  display: flex;
  justify-content:center;
  align-items: center;
  flex-direction: row;
`