import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import './styles.css';

export default function ModalForm(props){
  return(
    <>
      {props.showAlert? (
        <div id="modalFieldContainer">
          <div id="modalFieldCardForm" style={props.style ? props.style : {}}>
            <div className="modalHeader">
              <div className="modalFieldTitleContainer">
                <label className="modalFieldTitle">{props.title}</label>
              </div>
              <CloseIcon onClick={()=>props.close()} style={props.color?{fill:props.color}:{fill:'var(--color-text-colorful)'}} className='modalX'/>
            </div>
           
            {props.children}

            <div className="modalFieldButtonRow">
              {props.customButton?props.customButton():
                   <button                   
                     style={props.color?{backgroundColor:props.color}:{backgroundColor:'var(--color-text-colorful)'}}
                     className="modalFieldButton" 
                     onClick={_=>{props.button()}}
                   >
                     {props.buttonText}
                   </button>
              }  
            </div>
          </div>
        </div>
      ):(
        <div/>
      )}
    </>
  )
}