import React from 'react';
import styled from 'styled-components';

const SaleCard = ({sales}) => {
    return ( 
        <Container>
          
            <h4>Vendas</h4>
            <MainInfo>
                    <div>
                        <label>Novos Clientes</label>
                        <span>{sales?.day?.total}</span>
                    </div>  
                {sales?.day?.categories?.map(e =>
                    <div>
                        <label>{e.category_name}</label>
                        <span>{e.count}</span>
                    </div>
                 
                )}
            </MainInfo>
            <SecondaryInfo>
                    <div>
                        <label>Semana</label>
                        <span>{sales?.week?.total}</span>
                    </div>  
                    <div>
                        <label>PF</label>
                        <span>{'--'}</span>
                    </div>  
                    <div>
                        <label>Pend. Apro.</label>
                        <span>{'--'}</span>
                    </div>  
                    <div>
                        <label>Mês</label>
                        <span>{sales?.month?.total}</span>
                    </div>  
                    <div>
                        <label>PJ</label>
                        <span>{'--'}</span>
                    </div>  
                    <div>
                        <label>Pend. Acom.</label>
                        <span>{'--'}</span>
                    </div>  
                    <div>
                        <label>Zerados</label>
                        <span>{'--'}</span>
                    </div>  
            </SecondaryInfo>
        </Container>

     );
}
const MainInfo = styled.table`
    background-color:#EEEEEE;
    border-radius:10px;
    padding:5px;
    row-gap:5px;
    margin-top:10px;
    label,span{
       font-size:12px;
       font-weight:bold;
    }
    label{
       margin-right:10px;
    }
    span{
        color:#666666;
    }
    div{
       label{
           color:#AAAAAA;
       }
       :first-child{
          label{
               color:#555555;
          }
       }
       display: flex;
       flex-direction: row;
       align-items: center;
       width:100%;
       justify-content: space-between;
       padding:0px 5px
    }
    display:grid;
    grid-template-columns: 1fr 1fr 1fr;
`
const SecondaryInfo = styled.div`
    margin-top:2px;
    padding:5px;
    display:grid;
    row-gap:2px;
    grid-template-columns: 1fr 1fr 1fr;
    label,span{
       font-size:12px;
       font-weight:bold;
    }
    label{
       margin-right:10px;
    }
    span{
        color:#666666
    }
    div{
        
       label{
            color:#AAAAAA;
       }
       display: flex;
       flex:1;
       width:100%;
       flex-direction: row;
       justify-content: space-between;
       padding:0px 5px
    }

`

const Container = styled.div`

   display:flex;
   flex-direction: column;
   h4{
        color: #222222;
    }
    height:200px ;
    border-radius: 15px;
    box-shadow: 0px 1.5px 10px rgba(0, 0, 0, 0.2);
    margin-right: 17px;
    padding:10px 20px;
    margin: 5px;
    width:  350px;
    @media (min-width: 1745px) {
        width:  600px;
    }
`


export default SaleCard;