import styled from 'styled-components'
import Tags from "@yaireo/tagify/dist/react.tagify"; // React-wrapper file


export const TransferButtonDiv = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  @media (max-width:992px) {
    position: relative;
    top: 26%;
    right: 11%;
  }
`

export const FirstOptions = styled.div `
  display: flex;
  flex-direction:column !important;
  @media (max-width:992px) {
    margin-left: 15px;
}

`

export const Button = styled.button`
  min-width: auto;
  max-width: 175px;
  background: #EE5928;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  padding: 0px 20px;
  border-radius: 16px;
  color: white;
  align-self: flex-end;
  margin: 10px;
  @media (max-width:992px){
    width:125px;
    height:25px;
    svg {
      height:0.75rem;
    }
    span {
      font-size:0.7rem;
    }
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
`

export const TagDiv = styled.div `
  background-color: #dddddd;
  border-radius:5px;
  padding: 3px 1px 3px 1px;
  display:flex;
  align-content: center;
  justify-content: center;
  width: 75%;
  text-align: center;
`

export const Tag = styled.span `
  font-size:12px;
  color: #EE5928;
`

export const Input = styled.input`
  font-family: Roboto;
  font-size: 12px;
  padding-left: 10px;
  line-height: 16px;
  ::placeholder{
    color: #BBBBBB;
  }
  width: ${props => props.primary ? "100%" : "50%"};
  margin: 10px;
  height: 30px;
  border: 1.5px solid #BBBBBB;
  border-radius: 20px;
  box-sizing: border-box;
  &:link {
    text-decoration: none;
  }
  &:active {
    text-decoration: none;
  }
  &:focus {
    text-decoration: none;
    box-shadow: 0 0 0 0;
    outline: 0;
  }
`

export const BoxFilter = styled.div`
  display: flex;
  margin-top: 15px;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 13px;
  background-color: #ffffff;
  box-shadow: 1px 2px 13px #00000032;
  width: 90%;
  min-height: 80px;
  max-width: auto;
  div {
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
  }
`
export const Select = styled.select`
  font-family: Roboto;
  padding-left: 10px;
  font-size: 12px;
  color:  #BBBBBB;
  margin: 10px;
  width: ${props => props.primary ? "80%" : "48%"};
  height: 30px;
  border: 1.5px solid  #BBBBBB;
  background-color: white;
  border-radius: 20px;
  box-sizing: border-box;
  &:link {
    text-decoration: none;
  }
  &:active {
    text-decoration: none;
  }
  &:focus {
    text-decoration: none;
    box-shadow: 0 0 0 0;
    outline: 0;
  }
  option {
    color: black;
    background: white;
    display: flex;
    white-space: pre;
    min-height: 20px;
    padding: 0px 2px 1px;
  }
  @media (max-width:992px){
    height:25px !important;
  }
`

export const SelectTransfer = styled.select`
  background-color: white;
  margin: 10px;
  padding-left: 10px;
  min-width: ${props => props.primary ? '15vw' : '25vw'};
  max-width: 30vw;
  border: 2px solid ${props => props.invalidInput ?  '#ff9393' : '#DFDFDF'}; 
  border-radius: 15px;
  height: 30px;
  ::placeholder {
  color: #000000;
  opacity: 0.6;
  }
  @media (max-width:992px){
    height:25px !important;
  }
`

export const SelectHalf = styled.select`
  background-color: white;
  margin: 10px;
  width: 45%;
  padding-left: 10px;
  border: 2px solid #DFDFDF; 
  border-radius: 15px;
  height: 30px;
  ::placeholder {
  color: #000000;
  opacity: 0.6;
  }
  @media (max-width:992px){
    height:25px !important;
  }
`

export const StyledTag = styled(Tags)`
  width:100%;
  border-radius: 20px;
  max-height: 150px;
  overflow-y: auto;
  >.tagify__input {
    font-size:11px;
    min-width: 25vw;
    max-width: 30vw;
    :focus{
      border-color: #DDD;
    }
  }
`

export const StyledTagDiv = styled.div`
  width: 100%;
  >.tags-input {
    width:100%;
  }
  margin-left: 10px;
`