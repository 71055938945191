// Função para calcular a distância entre duas coordenadas geográficas
function calculateDistance(clat1, clng1, plat2, plng2) {
  const lat1 = clat1;
  const lon1 = clng1;
  const lat2 = plat2;
  const lon2 = plng2;

  // Fórmula de Haversine para calcular a distância entre duas coordenadas geográficas
  // Raio da Terra em metros
  const R = 6371e3;

  // Latitude em radianos
  const rad1 = (lat1 * Math.PI) / 180;
  const rad2 = (lat2 * Math.PI) / 180;

  const p1 = ((lat2 - lat1) * Math.PI) / 180;
  const p2 = ((lon2 - lon1) * Math.PI) / 180;

  const A =
    Math.sin(p1 / 2) * Math.sin(p1 / 2) +
    Math.cos(rad1) * Math.cos(rad2) * Math.sin(p2 / 2) * Math.sin(p2 / 2);

  const C = 2 * Math.atan2(Math.sqrt(A), Math.sqrt(1 - A));

  // Distância em metros
  const distancia = R * C;

  return Math.floor(distancia);
}

export default calculateDistance;
