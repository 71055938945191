import styled from 'styled-components';

const Styles = styled.div`
width:100%;

.headerCardComponent{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 13px;
    background-color: #ffffff;
    box-shadow: 1px 2px 13px #00000032;
    text-decoration: none;
    padding: 10px 40px;
    width:100%;
    height: 80px;
    min-height: 80px;
    margin-bottom:15px;
`;
export default Styles