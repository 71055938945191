import styled from "styled-components";
import React from "react";
const SmallText = styled.label`
color: #aaa;
font-size:10px;
`
const Input = styled.input.attrs(props => ({
    disabled: !props.editable,
}))`

font-size:12px;
background-color:${({ filled }) => filled ? '#DFDFDF' : 'transparent'} ;
padding: 0;
border: none;
display:flex;
color: ${({ editable }) => editable ? "#222" : "#555"};
width: 100%;
min-width:100px;
border:${({ editable }) => editable ? "2px solid #DFDFDF" : "none"};
padding:${({ editable }) => editable ? "5px" : "0"} ;
border-radius:25px;
margin-top:${({ editable }) => editable ? "2px" : "0"}}
height:${({ editable }) => editable ? "30px" : "auto"}}

${({filled}) => filled?`
height:30px;
padding:5px;
`:null}

::placeholder{

    color: #707070;
   
}
`
const InputContainer = styled.div`
 
 flex: 1;
 label{
    margin-left:${({ editable }) => editable ? '2px' : '0'}

 }
 margin-right:15px;
 :last-of-type {
    margin-right: 0;
}
    margin-top:10px;

 
`
const EditableInput = ({ children,onChange, filled, defaultValue, label, type, placeholder, editable }) => {
    return <InputContainer editable={editable}>
        <SmallText>
            {label}
        </SmallText>

        <Input onChange={onChange} type={type} filled={filled} defaultValue={defaultValue} as={type ? type : null} placeholder={placeholder} editable={editable} >
            {children}
        </Input>
    </InputContainer>
}
export default EditableInput

