import styled from 'styled-components'


import {Checkbox, Button} from '../../components'

export const Background = styled.div`
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-gap: 1rem;
  grid-template-areas:
    "white orange orange"
    "white orange orange"
    "white orange orange";
  grid-template-columns: auto auto auto;
  grid-template-rows: auto auto auto;
  overflow: auto;
  position: relative;
`

export const SideWhite = styled.span`
  grid-area: white;
  height: 100%;
  background-color: white;
`
export const SideOrange = styled.div`
  grid-area: orange;
  height: 100%;
  background: linear-gradient(180deg, #B71E33 0%, #EF5B28 102.12%);
  clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 0% 100%);
  @media (max-width:700px){
  clip-path: polygon(100% 0%, 100% 0%, 100% 100%, 0% 80%);
}
`

export const Container = styled.div`
  width: 70vw;
  border-radius: 15px;
  height: 65vh;
  margin-left:-35vw;
  margin-top:-35vh;
  position: absolute;
  background-color: transparent;
  left: 50%;
  top: 50%;
  box-shadow: 3px 3px 10px #00000042;
  display: grid;
  grid-template-areas:
  "white orange"
  "white orange"
  "white orange";
  grid-template-columns: auto auto ;
  grid-template-rows: auto auto ;

  @media (max-width:700px){
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: center;
    background: linear-gradient(180deg, #B71E33 0%, #EF5B28 102.12%);
    height: auto;
    padding: 20px 10px;
  }
`
export const BoxLogin = styled.div`
  grid-area: orange;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width:700px){
    display: flex;
    align-items: start;
    justify-content: flex-start;
  }
  @media (min-device-width: 701px) and (max-device-width: 1170px){
    margin-left: 40px;
    padding: 10px;
  }
`

export const BoxLogo = styled.div`
  grid-area: white;
  display: flex;
  align-items:center;
  justify-content: center;
  img {
    height: 140px;
    width: auto;
  }
  @media (max-width:700px){
    display: flex;
    align-items: start;
    justify-content: flex-start;
    img{
      height: 90px;
      width: auto;
    }
  }
  @media (min-device-width: 701px) and (max-device-width: 1170px){
  img{
    height: 100px;
    width: auto;
  }
}
`
export const Login = styled.div`
  width: 55%;
  height: auto;
  display: flex;
  align-items: stretch;
  justify-content: space-around;
  .divInputandButton {
    flex-direction: column;
    width: 100%;
    height: auto;
  }
  @media (max-width:700px){
    width: 100%;
  } 

`
export const BoxCheck = styled.div`
  width: 90%;
  margin-top: 0px;
  display: flex;
  justify-content:space-between;
  align-items: center;
  flex-direction: row !important;
  p{
    display: inline-block;
    width: auto;
    font-size: 10px;
  } 
  a {
    color: #FFFFFF;
    display: inline-block;
    width: auto;
    font-size: 10px;
  }
  .rememberPassword{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    text-decoration: underline;
    width: auto;
  }
  @media (max-width:1000px){
    flex-direction: column !important;
    padding: 4px;
  }
  @media (min-device-width: 701px) and (max-device-width: 960px){
    flex-direction: column !important;
  } 
`
export const Input = styled.input`
  height: 35px;
  border-radius: 8px;
  margin: 5px 10px 10px;
  width: 90%;
  padding: 5px 10px;
  ::placeholder{
    color:  #8F8F8F;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
  }
  @media (max-width:600px){
    margin: 10px;
  } 
`

export const Label = styled.label`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: #FFFFFF;
  margin: 5px 0 0 10px;
  opacity: ${props => (props.opacity ? '0.9' : '1')};
`

export const MyButton = styled(Button)`
  height:35px;
  margin: 10px;
  width: 90%;
  border-radius: 8px;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0em;
  @media (max-width:700px){
    background-color:transparent;
    border: 2px solid white;
    margin: 10px;
    margin-bottom: 20px;
  }
  @media (min-device-width: 601px) and (max-device-width: 1170px){

  }
`

export const MyCheckbox = styled(Checkbox)`
  .fPMMog {
    background:#EE5928;
    border: 1px solid #FFFFFF;
  }
  .drGhZm {
    background:#EE5928;
    border: 1px solid #FFFFFF;
  }
  
`
export const PValidator = styled.p`
  color: red;
  font-size: 10px;
  margin-top: -5px;
  background:rgba( 255,255,255,.7);
  width: 34%;
  font-weight: bold;
  @media (max-width:700px){
    width: 41%;
  }
  @media (min-device-width: 701px) and (max-device-width: 981px){
    width: 48%;
  }
`