import styled from 'styled-components';
import { Card } from '../../../components/Styled-Components/Card'

export const TopDiv = styled.div`
  display:flex;
  flex-direction:row;
  justify-content:space-between;
  align-items: center;
  margin-top:10px;
  margin-bottom:20px;
  width:100%
  

`
export const Tags = styled.div`
display: flex;
flex-direction: row;
width: auto;
background-color: #EEEEEE;
border-radius: 12px;
padding: 10px;
justify-content: space-between;
align-items: center;
margin-left: 10px;
margin-top: 10px;

  label {
    font-size:15px;
    color:#444444;
    margin-right: 15px;
  }

  svg{
    font-size: 20px;
    color: #444444;
    :hover{
      cursor:pointer;
    }
  }
  
`
export const TagsContainer = styled.div`
display:flex;
flex-direction:row;
flex-wrap:wrap;
`
export const TopDivSpan = styled.span`
  color:#ee5928;
`

export const TopDivButton = styled.button`
  border-radius:20px;
  display:flex;
  flex-direction: row;
  background-color:#ee5928;
  margin-right:20px;
  padding:5px;
  & > span {
    color:white;
    margin-left:10px;
    align-self:center;
  }
  & > svg {
    color:white;
  }
`

export const CardDivs = styled.div`
  margin-top:10px;
  margin-bottom:10px;
  margin-left:20px;
  margin-right:20px;
  display:grid;
  gap:10px;
  width:100%;
  grid-template-columns: repeat(4,1fr);
`

export const InsideCardTopDiv = styled.div`
  margin-top:15px;
  margin-left:20px;
  margin-right:10px;
  margin-bottom:10px;
  display:flex;
  flex-direction:row;
  & > svg {
  color: #ee5928;
  
}
`

export const InsideCardTextTopDiv = styled.div`
  margin-left:20px;
  display:flex;
  flex-direction:column;
`

export const TextDiv = styled.div`
  margin-left:20px;
  margin-bottom:10px;
  display:flex;
  flex-direction:column;
`

export const TagInput = styled.input`
width: 216px;
border-radius: 26px;
margin-right: 10px;
border: 1px solid gray;
text-align: center;
font-size: 16px;
`