import styled from 'styled-components';
import { Card } from '../../components/Styled-Components/Card'

export const Container = styled.div `
  flex: 1;
  width: 100%;
  height:auto;
  font-family: Roboto;
  overflow-x: hidden;
  padding: 5px;
`;

export const HeaderCard = styled(Card) `
  width:100%;
  display:flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding:30px;
`;

export const ButtonDiv = styled.div `
  display:flex;
  flex-direction:row;
  align-items: center;
`

export const HeaderTitle = styled.h1 `
  font-size:20px;
  color:#EE5928;
`;

export const MainCard = styled(Card) `
  margin-top:10px;
  width:100%;
`
export const NewUserButton = styled.button `
  height: 35px;
  padding: 8px 25px;
  border-radius: 16px;
  color: #FFFFFF;
  background-color: #EE5928;
  display:flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  & span {
    font-size:16px;
    margin-left:2px;
  }

  :disabled {
    opacity: 0.5;
    cursor:auto;
  }
`;

export const FormGrid = styled.div `
  display: flex;
  flex-direction: row; 
  padding: 30px;
`

export const LeftGrid = styled.div `
  width: 50%;
  display: flex;
  flex-direction:column;
`

export const RightGrid = styled.div `
  width: 50%;
  display: flex;
  flex-direction:column;  
`

export const GridTitle = styled.span `
  font-size: 20px;
  color: #EE5928;
  margin-bottom: 15px;
`

export const NoDataSpan = styled(HeaderTitle) `
  font-size:14px;
  opacity:0.7;
`;

export const FullInput = styled.input `
  width: 100%;
  max-width: 450px; 
  padding: 10px;
  border-color: #DFDFDF;
  border-style: solid;
  border-radius: 15px;
  height: 36px;
  ::placeholder {
    color: #000000;
    opacity: 0.6;
  }
  
`

export const InputLabel = styled.span `
  color: #8b8b8b;
  font-size: 14px;
  font-family: 'Roboto';
  margin-bottom: 5px;
`;


export const HalfInputDiv = styled.div `
  width: 50%;
  max-width: 220px;
  display: flex;

  &:nth-child(1) {
    margin-right: 5px;
  }

`

export const InputLine = styled.input `
  width: 70%;
  margin-top: 20px;
  padding-right: 10px;
  padding-left: 10px;
  border-bottom: 2px solid #DFDFDF;
  height: 24px;
  padding-bottom: 6px;
  ::placeholder {
    color: #000000 !important;
  }
`

export const HalfInputLine = styled(InputLine) `
  width:50%;
  margin-left:10px;
`

export const MainTextArea = styled.textarea `
  height: 20vh;
  color: #1C1C1C;
  font-weight: 600;
  background: white;
  position: relative;
  width: 100%;
  max-width: 450px;
  padding-right: 19px;
  padding-left: 20px;
  border-color: #ddd;
  border-style: solid;
  border-radius: 20px;

  ::placeholder {
    color: #7c7272;
    font-weight: 600;
  }
  :read-only {
    color: #7c7272 !important;
    background: #F9F9F9;
  }
`

export const ToggleDiv = styled.div `
  display: 'flex';
  flex-direction: row;
  width: 70%;
  margin-top: 20px;
  align-items: center;
`

export const ToggleSpan = styled.span `
  color: #8b8b8b;
  font-size: 14px;
  font-family: 'Roboto';
`

export const Select = styled.select `
  padding: 10px;
  background: white;
  border: 2px solid #ddd;
  border-radius: 15px;
  height: 40px;
  ::placeholder {
    color: grey;
    font-weight: 600;
  }
  
  option {
    text-transform: capitalize;
  }
`

export const MultiSelectDiv = styled.div `
  margin-bottom: 15px;
  margin-top: 5px;
`
export const ContainerInputs = styled.div `
  margin-bottom: 20px;
`

export const ContainerInputsHalf = styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
`
