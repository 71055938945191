
import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';

export const MyToast = styled(ToastContainer).attrs({})`
  .Toastify__toast--error {
  }

  .Toastify__close-button {
    align-self: flex-end;
    position: relative;
    bottom: 30px;
    opacity: 1;

    > svg {
      fill: white;
      width: 25px;
      height: 20px;
    }
  } 


  .Toastify__toast-body{
    font-weight: bold;
  }

  .Toastify__toast--info {
    background: #ee5928;
  }
`;
