import React, {useState, useEffect} from 'react';
import {useParams, useHistory} from "react-router-dom";

import Dropzone from 'react-dropzone';
import api from '../../../services/api';
import {Add, HourglassEmpty, HighlightOff, EditOutlined, CloseOutlined} from '@material-ui/icons';

import './styles.css';
import SubmitButtonContainer from '../../../components/SubmitButtonContainer';
// import { FormatMoney } from '../../../utils/FormatData';
import LoaderSpin from '../../../components/LoaderSpin';

function StepSales(props){
  let {idCustomer} = useParams();
  let history = useHistory();
  const [stepSubmit, setStepSubmit] =  useState(0);
  // const [totalPrice, setTotalPrice] = useState(0);
  const [invalidInput, setInvalidInput] = useState([]);
  const [imgList, setImgList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [category, setCategory] = useState([]);
  const [userProducts, setUserProducts] = useState([]);
  const [productList, setProductList] = useState([]);
  const [products, setProducts] = useState([]);
  const [salesList, setSalesList] = useState([]);
  const [sale, setSale] = useState({
    id: null,
    id_sku: '',
    id_sku_category: ''
  });
  // const [salesComment, setSalesComment] = useState('');
  const [loading, setLoading] = useState(true);
  const [sendedSales, setSendedSales] = useState(0);
 
  function filterInstalledProducts(listProducts){
    return listProducts.products?.filter(
      product => product.id_sku_status !== 3
    );
  }

  useEffect(() => {
    if(idCustomer && props.userProject?.id){
      api.get('/categories')
      .then(response => {
        if(response?.data){
          const categories = response?.data?.filter(item=> item.id_project === props.userProject?.id)
          setCategoryList(categories);
          if(categories?.length > 0){
            setCategory(categories[0]);
            api.get('/user')
            .then(response => {
              if(response?.data && response?.data.length > 0){
                const dataProducts = filterInstalledProducts(response?.data[0]);
                setUserProducts(dataProducts)
                const categoryProducts = dataProducts.filter(
                  product => product.id_sku_category===categories[0].id
                )
                setProductList(categoryProducts);
                setSale({
                  id: null,
                  id_sku: categoryProducts[0]?.id?categoryProducts[0]?.id:'', 
                  id_sku_category: categories[0]?.id?categories[0]?.id:'',
                });
                setLoading(false);
              }else{
                setLoading(false);
              }
            }).catch(err => {
              console.log(err);
              setLoading(false);
            })
          } else {
            console.log('No categories avalible');
            setLoading(false);
          }
        }
      }).catch(err => {
        console.log(err);
        setLoading(false);
      })
    }else{
      if(!idCustomer){
        history.push('/CustomerRegister')
      }
    }
  }, [history, idCustomer, props.userProject.id]);
  
  useEffect(() => {
    
    
    if(salesList.length <= 0){

      if(categoryList?.length > 0){
        setCategory(categoryList[0]);
        api.get('/user')
        .then(response => {
          if(response?.data && response?.data.length > 0){
            const dataProducts = filterInstalledProducts(response?.data[0]);
            setUserProducts(dataProducts)
            const categoryProducts = dataProducts.filter(product => product.id_sku_category===categoryList[0].id);
            setProductList(categoryProducts);
            setSale({
              id: null,
              id_sku: categoryProducts[0]?.id?categoryProducts[0]?.id:'', 
              id_sku_category: categoryList[0]?.id
            })
          }
        }).catch(err => {
          console.log(err)
        })
      }else{
        setUserProducts([])
        setProductList([]);
        setSale({
          id: null,

          id_sku: '', 
          id_sku_category: ''
        })
      }
      setStepSubmit(0);
    }
  }, [categoryList, salesList])

  useEffect(() => {
    if(salesList.length > 0 && sendedSales === salesList.length){
      history.push('/CustomerInfo/'+idCustomer);
    }
  }, [history, idCustomer, salesList, sendedSales])

  function submitForm(){
    addProduct()
    .then(response => {
      if(response?.success){
        setStepSubmit(1);
      }else{
        if(salesList.length > 0){
          setStepSubmit(1);
        }else{
          console.log('submitForm: ', response?.success);
        }
      }
    }).catch(err => {
      console.log('submitForm: ', err);
    });
  }

  function sendSubmit(){
    setLoading(true);
    salesList.forEach(async item => {
      let postSales = {};
      const category = categoryList.find(category => category?.id === item.id_sku_category);
      let images = []
      item.imgList.forEach(imgItem => {
        images.push(imgItem.id);
      })
   

      postSales = {
        comments: item.comments,
        id_sku: item.id_sku,
        id_project: category?.id_project,
        id_sku_category: item.id_sku_category,
        id_customer: idCustomer,
        id_files: images
      }

      await api.post('/sales', postSales)
      .then(response => {
        if(response.status === 200){
          setSendedSales(s => {return s+1});
        }else{
          console.log(response.data);
          setLoading(false);
        }
      }).catch(err => {
        console.log(err?.response?.data);
        setLoading(false);
      })
    })
  }

  async function loadFiles(id, files){
    removeInvalidInput('img');
    if(id !== null){
      const formData = new FormData();
      formData.append('file', files[0])

      api.put('/uploads/'+id, formData)
      .then(response =>{
        if(response.data.upload){
          setImgList(list => (
            list.map(img => (
              img.id !== id ? img : response.data.upload
            ))
          ))
        }
      }).catch(err => {
        console.log('response: ', err);
      })
    }else{
      const formData = new FormData();
      formData.append('file', files[0])
      
      api.post('/uploads', formData)
      .then(response =>{
        if(response.data.upload){
          setImgList([...imgList, response.data.upload]);
        }
      }).catch(err => {
        console.log('response: ', err);
      })
    }
  }

  async function loadProduct(id){
    if(userProducts.length > 0 ){
      const dataProducts = userProducts.filter(product => (product.id_sku_category === id && product.id_sku_status !== 3));
      setProductList(dataProducts)
      setSale(s =>{
        let sale = {...s}; 
        sale.id_sku= dataProducts[0]?.id?dataProducts[0]?.id:''; 
        sale.id_sku_category= id;
        // console.log(sale)
        return sale;
      })
    }else{
      // console.log('else loadProduct')
      setProductList([])
      
      setSale(sale =>{
        let s = {...sale}; 
        s.id_sku= ''; 
        s.id_sku_category= id;
        return s;
      })
    }
  } 

  async function removeImage(id){
    api.delete('/uploads/'+id)
    .then(response =>{
      if(response.status !== 404){
        let imgs = imgList.filter(item => item.id !== id);
        setImgList(imgs);
      }
    }).catch(err => {
      console.log(err)
    })
  }

  async function addProduct(){
    if(sale.id_sku_category){
      let newProduct = sale;
      if(newProduct.id === null){
        const sameProduct = newProduct.id_sku!==''&&newProduct.id===null
          ?salesList.find(item => item.id_sku === newProduct.id_sku)
          :salesList.find(item => item.id_sku_category === newProduct.id_sku_category);
        if(sameProduct){
          newProduct.id = sameProduct.id!==null?sameProduct.id:null;
        }
      }
      if(newProduct.id !== null){ 
        newProduct.imgList = imgList;
        let newSales = [];
        salesList.map(item => {
          if(item.id !== newProduct.id){ 
            newSales.push(item); 
          }else{ 
            newSales.push(newProduct); 
          }
          return item;
        })
        setSalesList(newSales)
        let product = productList.find(item => item.id === sale.id_sku);
        if(!products.includes(product)){ 
          setProducts([...products, product]); 
        }

        cleanSale();
        // setSale({ ...sale, sale_price: '', id:null, comments:''})
        // setImgList([]);

      }else{
        if(salesList.length > 0){
          let lastProduct = salesList[salesList.length-1];
          newProduct.id = lastProduct.id+1;
        }else{
          newProduct.id = 0;
        }
        newProduct.imgList = imgList;
        setSalesList([...salesList, newProduct]);
        let product = productList.find(item => item.id === sale.id_sku);
        if(!products.includes(product)){ 
          setProducts([...products, product]); 
        }

        cleanSale();
        // setSale({ ...sale, sale_price: '', id:null, comments:'',  })
        // setImgList([]);

      }
      return ({success:true});
    }else{
      let invalid = []

      // if(!sale.sale_price){
      //   invalid.push('sale_price');
      // }

      if(!sale.id_sku_category){
        invalid.push('id_sku_category');
      }
      setInvalidInput([...invalidInput, ...invalid]);
      return ({success:false});
    }
  }

  async function removeProduct(id){
    setSalesList(salesList.filter(item => item.id !== id));
    if(sale.id !== null){

      cleanSale();
      // let id_sku_category = categoryList.length>0?categoryList[0].id:'';
      // setSale({ id: null, id_sku: '', id_sku_category: id_sku_category, sale_price: '', imgList:[] });
      // loadProduct(id_sku_category)
      // setImgList([]);

    }
  }

  function cleanSale(){
    let firstCategory = categoryList.length>0?categoryList[0]:'';
    setSale({ 
      id: null, 
      id_sku: '', 
      id_sku_category: firstCategory.id, 
      sale_price: '',
      comments: '', 
      imgList:[]
    });
    setCategory(firstCategory);
    loadProduct(firstCategory.id);
    setImgList([]);
  }
  
  async function editProduct(id){
    let thisSale = salesList.find(item => item.id === id);
    console.log('thisSale: ', thisSale)
    await loadProduct(thisSale.id_sku_category)
    setSale(thisSale);
    setImgList(thisSale.imgList);
  }

  function removeInvalidInput(input){
    if(invalidInput.includes(input) && Array.isArray(invalidInput)){
      setInvalidInput(invalidInput.filter(item => item !== input));
    }    
  }

  return(
    <>
      {stepSubmit > 0 ?(
        <>
          {!loading && (
            <>
              <div id='stepFormRow'>
                <div className='stepFormHalf'>
                  {salesList.map((item) => {
                    let product = products.find(product => product?.id === item.id_sku);
                    let category = categoryList.find(category => category?.id === item.id_sku_category);
                    return(
                      <div 
                        key={item.id}
                        className='stepSalesProductsItem stepSalesProductsItemCol'
                      >
                        <label className='stepSalesProductsItemLabel'>
                          {product?.ean_code?product?.ean_code:category?.name}
                          </label>
                        <div className='stepSalesProductsItemButton'>
                          <button onClick={()=>{
                            editProduct(item.id)
                          }}>
                            <EditOutlined />
                          </button>
                          <button onClick={()=>{
                            removeProduct(item.id)
                          }}>
                            <CloseOutlined />
                          </button>
                        </div>
                      </div>
                    )
                  })}
                 
                </div>
                <div className='stepFormHalf'>
                  {sale.id !== null?(
                    <>
                      <select 
                        className={`inputRounded ${invalidInput.includes('id_sku_category')
                          ?'invalidInput'
                          :''}`
                        }
                        value={sale.id_sku_category}
                        onChange={(event)=>{
                          setCategory( categoryList.find(item => item.id === event.target.value) )
                          removeInvalidInput('id_sku');
                          loadProduct(event.target.value)
                        }}
                        >
                        {categoryList?.map(item => (
                          <option key={item?.id} value={item?.id}>
                            {item?.name}
                          </option>
                        ))}
                      </select>
                      <select 
                        className={`inputRounded ${invalidInput.includes('id_sku')?'invalidInput':''}`}
                        value={sale.id_sku}
                        onChange={(event)=>{
                          removeInvalidInput('id_sku');
                          setSale({
                            ...sale, 
                            id_sku: event.target.value
                          })}}>
                          <option value='' disabled>Selecione um produto</option>
                        {productList?.map(item => (
                          <option key={item?.id} value={item?.id}>{item?.ean_code}</option>
                        ))}
                      </select>
                      <div id='divButtonStepSales'>
                        <button onClick={() => {addProduct()}}>
                          {sale.id !== null?(
                            <span>Atualizar produto</span>
                          ):(
                            <span>+ Adicionar outro produto</span>
                          )}
                        </button>
                      </div>
                      <label>Observações:</label>
                      <textarea 
                        className={`inputRounded ${invalidInput.includes('comment')?'invalidInput':''}`}
                        value={sale.comments}
                        onChange={(event) => {
                          setSale({...sale, comments: event.target.value});
                          removeInvalidInput('comment');
                        }}
                      />
                    </>
                  ):(
                    <></>
                  )}
                </div>
              </div>
              <SubmitButtonContainer 
                idCustomer={idCustomer}
                idProject={props.userProject?.id}
                steps={3}
                submitForm={()=>{sendSubmit()}}
              />
            </>
          )}
          <LoaderSpin enable={loading} />  
        </>
      ):(
        <>
          {!loading && (
            <>
              <div id='stepSalesProductsList'>
                {salesList.map((item) => {
                  let product = products.find(product => product?.id === item.id_sku);
                  let category = categoryList.find(category => category?.id === item.id_sku_category);
                  return(
                    <div key={item.id} className='stepSalesProductsItem'>
                      <label className='stepSalesProductsItemLabel'>{product?.ean_code?product?.ean_code:category?.name}</label>
                      <div className='stepSalesProductsItemButton'>
                        <button onClick={()=>{editProduct(item.id)}}><EditOutlined /></button>
                        <button onClick={()=>{removeProduct(item.id)}}><CloseOutlined /></button>
                      </div>
                    </div>
                  )
                })}
              </div>
              <div id='stepFormRow'>
                <div className='stepFormHalf'>
                  <div>
                    <select 
                      className={`inputRounded ${invalidInput.includes('id_sku_category')?'invalidInput':''}`}
                      value={sale.id_sku_category}
                      onChange={(event)=>{
                        setCategory( categoryList.find(item => item.id === event.target.value) )
                        loadProduct(event.target.value)
                        removeInvalidInput('id_sku');
                      }}>
                      {categoryList?.map(item => (
                        <option key={item?.id} value={item?.id}>{item?.name}</option>
                      ))}
                    </select>
                    <div className='selesDescriptionCard'>
                      <p> {category.description} </p> 
                    </div>
                  </div>
                  <div>
                    <select 
                      className={`inputRounded ${invalidInput.includes('id_sku')?'invalidInput':''}`}
                      value={sale.id_sku}
                      onChange={(event)=>{
                        removeInvalidInput('id_sku');
                        setSale({
                          ...sale, 
                          id_sku: event.target.value
                        })}}>
                        <option value='' disabled>Selecione um produto</option>
                      {productList?.map(item => (
                        <option key={item?.id} value={item?.id}>{item?.ean_code}</option>
                      ))}
                    </select>
                    
                    <label className='stepRowSmall inputLabel'>Observações:</label>
                    <textarea 
                      className={`inputRounded ${invalidInput.includes('comment')?'invalidInput':''}`}
                      value={sale.comments}
                      onChange={(event) => {
                        setSale({...sale, comments: event.target.value});
                        removeInvalidInput('comment');
                      }}
                    />
                  </div>
                </div>
                <div className='stepFormHalf'>
                  <div className='leftAlignedTitle'>
                    <label>
                      Fotos 
                      <label className='subLeftAlignedTitle'> (comprovantes, pagamento, etc)</label>
                    </label>
                  </div>
                  <div id='imageListContainer'>
                    <div className='imageRow'>
                      <Dropzone 
                        accept='image/*' 
                        onDrop={acceptedFiles => loadFiles(null, acceptedFiles)} 
                      >
                        {({getRootProps, getInputProps, isDragActive, isDragReject}) => (
                          <div 
                            className={`imageCard imagemDrop ${invalidInput.includes('img')?'invalidInput':''}`} 
                            {...getRootProps()}
                          >
                            <input {...getInputProps()} />
                            <Add className='iconAdd' style={{ fontSize: 40 }}/>
                          </div>
                        )}
                      </Dropzone>
                      
                      {
                        imgList.map((item, index) => {
                          if(!item.loading){
                            return (
                              <Dropzone 
                                key={index} 
                                accept='image/*' 
                                onDrop={acceptedFiles => loadFiles(item.id, acceptedFiles)} 
                              >
                                {({getRootProps, getInputProps, isDragActive, isDragReject}) => (
                                  <div key={index} className='imageRemove'>
                                    <div className='imageCard imagemDrop' {...getRootProps()} >
                                      <input {...getInputProps()} />
                                      {item?.uri? 
                                        <img  key={index} className='' src={item.uri} alt='photoClient'/> :
                                        <Add className='iconAdd' style={{ fontSize: 40 }}/>
                                      }
                                    </div>
                                    <button className='iconDelete' onClick={() => removeImage(item.id)}>
                                      <HighlightOff style={{ fontSize: 30 }}/>
                                    </button>
                                  </div>
                                )}
                              </Dropzone>
                            )
                          }else{
                            return (
                              <div key={item.id} className='imageCard'>
                                <HourglassEmpty className='iconHourglassEmpty' style={{ fontSize: 40 }}/>
                              </div>
                            )
                          }
                        })
                      }
                    </div>
                  </div>
                  <div id='divButtonStepSales'>
                    <button onClick={() => {addProduct()}}>
                      {sale.id !== null?(
                        <label>Atualizar produto</label>
                      ):(
                        <label>+ Adicionar outro produto</label>
                      )}
                    </button>
                  </div>

                </div>
              </div>
              <SubmitButtonContainer 
                idCustomer={idCustomer}
                idProject={props.userProject?.id}
                steps={3}
                submitForm={()=>{submitForm()}}
              />
            </>
          )}
          <LoaderSpin enable={loading} />  
        </>
      )}
    </>
  )
}

export default StepSales;