import styled from 'styled-components'

export const Header = styled.div`
  display:flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 20px;
`

export const CardProject = styled.div`
    position: relative;
    width: 300px;
    height: 80px;
    background: linear-gradient(0deg, #FFFFFF, #FFFFFF), #FED6C9;
    box-shadow: 0px 1.5px 10px rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    display:flex;
    flex-direction: row;  
    margin-right: 17px;
    
    > div:first-child {
        padding: 15px;

        > p:first-child {
            color: black;
            font-size: 1.3rem;
            margin-bottom: 5px;            
            font-style: normal;
            font-weight: 700;
        }

        > p {
            font-weigth: 700;
            font-size: 1.0rem;
            margin: 0;
            color: #999999;
        }
    }

    > div:last-child {
        position: absolute;
        width: 49px;
        height: 49px;
        right: 20px;
        top: 16px;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;

        > svg {
            color: white;
        }
    }
`

export const CardProjectG = styled.div`
    position: relative;
    height: 80px;
    width: 50%;
    border-radius: 15px;
    background: linear-gradient(0deg, #FFFFFF, #FFFFFF), #FED6C9;
    box-shadow: 0px 1.5px 10px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: row;
    margin-right: 17px;

    > div:first-child {
        width: 35px;
        height: 80px;
        background: rgba(238, 89, 40, 0.9);
        border-radius: 15px 0px 0px 15px;
        justify-content: center;

        > span {
            font-style: normal;
            font-weight: 700;
            font-size: 1.0rem;
            display: flex;
            align-items: center;
            justify-content: center;

            color: #FFFFFF;
            transform: rotate(-90deg);
            letter-spacing: 1px;
        }
    }

`

export const CardVisitInfo = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    height: 80px;
    width: 100%;
    color: #AAAAAA;
    padding:10px ;
    .titles{
        margin-right: 40px;
    }
     > div:first-child > span:first-child {        
        font-style: normal;
        font-weight: 700;
        font-size: 16px;    
    }

    span {
        margin-bottom: 5px;
        font-size: 0.8rem;        
        font-style: normal;
        font-weight: 700;
    }   

`

export const CardVisitInfoValues = styled.div`    
   > span {
        color: black;
        font-weight: 700;
        font-size: 1.0rem;
   }

`
export const CardProjectSale = styled.div`
    position: relative;
    height: 80px;
    width: 50%;
    border-radius: 15px;
    background: linear-gradient(0deg, #FFFFFF, #FFFFFF), #FED6C9;
    box-shadow: 0px 1.5px 10px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: row;
    padding: 10px;

    > div:first-child {
        position: absolute;
        width: 8%;
        height: 80px;
        left: 0;
        top: 0px;
        background: rgba(238, 89, 40, 0.9);
        border-radius: 15px 0px 0px 15px;
        margin-right: 50px;

        > span {
            position: absolute;
            left: 48%;
            right: 69.53%;
            top: 70%;
            bottom: 2.5%;
            font-style: normal;
            font-weight: 700;
            font-size: 1.0rem;
            display: flex;
            align-items: center;
            color: #FFFFFF;
            transform: rotate(-90deg);
            letter-spacing: 1px;
        }
    }

`

export const CardSaleInfo = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    color: #AAAAAA;
    padding:10px ;
    .titles{
        margin-right: 40px;
        margin-left: 40px;
    }
     > div:first-child > span:first-child {        
        font-style: normal;
        font-weight: 700;
        font-size: 16px;     
    }

    span {
        margin-bottom: 5px;
        font-size: 0.8rem;        
        font-style: normal;
        font-weight: 700;
    }   

`

export const CardSaletInfoValues = styled.div`    
   > span {
        color: black;
        font-weight: 700;
        font-size: 1.0rem;
   }

`



