export function validateCpfCnpj(value) {
  let doc = String(value);

  doc = doc.replace(/[^\d]+/g,''); // Remove caracteres não numéricos do CPF
  
   // Verifica se o CPF tem 11 dígitos e não é uma sequência de números repetidos
  if (doc.length === 11 || /^(\d)\1{10}$/.test(doc)) {
    const cpf = doc;

    if (
      cpf === '00000000000' ||
      cpf === '11111111111' ||
      cpf === '22222222222' ||
      cpf === '33333333333' ||
      cpf === '44444444444' ||
      cpf === '55555555555' ||
      cpf === '66666666666' ||
      cpf === '77777777777' ||
      cpf === '88888888888' ||
      cpf === '99999999999'
    )
      return false;

    let sum = 0;

    let rest;

    for (let i = 1; i <= 9; i++)
      sum += Number(cpf.substring(i - 1, i)) * (11 - i);

    rest = (sum * 10) % 11;

    if (rest === 10 || rest === 11) rest = 0;

    if (rest !== Number(cpf.substring(9, 10))) return false;

    sum = 0;

    for (let i = 1; i <= 10; i++)
      sum += Number(cpf.substring(i - 1, i)) * (12 - i);

    rest = (sum * 10) % 11;

    if (rest === 10 || rest === 11) rest = 0;

    if (rest !== Number(cpf.substring(10, 11))) return false;

    return true;
  }
  if (doc.length === 14) {
    const cnpj = doc;

    if (
      cnpj === '00000000000000' ||
      cnpj === '11111111111111' ||
      cnpj === '22222222222222' ||
      cnpj === '33333333333333' ||
      cnpj === '44444444444444' ||
      cnpj === '55555555555555' ||
      cnpj === '66666666666666' ||
      cnpj === '77777777777777' ||
      cnpj === '88888888888888' ||
      cnpj === '99999999999999'
    )
      return false;

    let size = cnpj.length - 2;

    let numbers = cnpj.substring(0, size);

    const digits = cnpj.substring(size);

    let sum = 0;

    let pos = size - 7;

    for (let i = size; i >= 1; i--) {
      // eslint-disable-next-line no-plusplus
      sum += numbers.charAt(size - i) * pos--;

      if (pos < 2) pos = 9;
    }

    let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

    if (result !== Number(digits.charAt(0))) return false;

    size += 1;

    numbers = cnpj.substring(0, size);

    sum = 0;

    pos = size - 7;

    for (let i = size; i >= 1; i--) {
      // eslint-disable-next-line no-plusplus
      sum += numbers.charAt(size - i) * pos--;

      if (pos < 2) pos = 9;
    }

    result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

    if (result !== Number(digits.charAt(1))) return false;

    return true;
  }
  return false;
}
