import React, { useEffect, useMemo, useState, useContext } from 'react';
import Api from '../../services/api';
import TabBar, { TabBarContext, TabBarProvider, TopBarView } from '../../components/TabBar';
import ReportOptions from './ReportOptions';
import SheetPrepare from './SheetPrepare';
import * as S from './styled';
import StoreContext from '../../context/Context';
import CustomerReports from '../../pages/CustomerReports';

const Reports = ({ report, report_id }) => {
  const [init, setInit] = useState(new Date().toISOString().substring(0, 10));
  const [final, setFinal] = useState(null);
  const [oldAndNewCustomFields, setOldAndNewCustomFields] = useState(false);

  return (
    <S.Container>
      <ReportOptions
        init={init}
        final={final}
        setInit={setInit}
        setFinal={setFinal}
        flags={report.options}
        setOldAndNewCustomFields={setOldAndNewCustomFields}
        oldAndNewCustomFields={oldAndNewCustomFields}
      />
      <SheetPrepare 
        init={init} 
        final={final} 
        fields={report.fields} 
        report_id={report_id} 
        key={report_id} 
        oldAndNewCustomFields={oldAndNewCustomFields}
      />
    </S.Container>
  )
}

export default function CustomerReportsV2(props) {
  const { userProjectStore, userInfoStore } = useContext(StoreContext);

  const [availableReports, setAvailableReports] = useState([]);

  useEffect(() => {
    Api.get('/v2/reports', {
      params: {
        id_project: userProjectStore?.id
      }
    }).then(response => {
      setAvailableReports(e => [{
        id: 'reports',
        name: 'Relatórios',
        permissionNeeded: "reports",
        render: () => <React.Fragment key={'old_report'}>{CustomerReports({ userProjectStore })}</React.Fragment>
      }, ...response.data.map((report, index) => ({
        id: report?.name?.toLowerCase(),
        name: report?.name + " BETA",
        permissionNeeded: userInfoStore.id_users_type >= 5 ? "reports" : 'reports_beta',
        render: () => <React.Fragment key={index}>{Reports({ report, report_id: report._id })}</React.Fragment>

      }))]);
    })

  }, [userProjectStore])

  return (<S.Container>
    <TabBarProvider>
      <TabBar renderItems={availableReports} />
      <TopBarView />
    </TabBarProvider>
  </S.Container>)
}
