import React from 'react';

import './styles.css';

export default function ModalAlert(props) {

  return (
    <>
      {props.showAlert ? (
        <div id="modalAlertContainer">
          <div id="modalAlertCard">
            <div className="modalAlertClose">
              <button onClick={props.onClose}>X</button>
            </div>
            <label className="modalAlertTitle">{props.title}</label>
            {
              props.color == "gray" ? (
                <div style={{ justifyContent: "center", alignItems: "center" }}>
                  <textarea
                    readOnly
                    cols={props.descCol ? props.descCol : "40"}
                    rows={props.descRow ? props.descRow : "2"}
                    className="modalAlertDescGray"
                    defaultValue={props.desc}

                  />
                </div>
              ) : <textarea
                readOnly
                cols={props.descCol ? props.descCol : "40"}
                rows={props.descRow ? props.descRow : "2"}
                className="modalAlertDesc"
                defaultValue={props.desc}

              />
            }
            <div className="modalAlertButtonRow">
              {props.buttons?.map((item, index) => (
                <button
                  key={index}
                  className="modalAlertButton"
                  onClick={(event) => { item.onClick(event) }}
                >
                  {item.name}
                </button>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div />
      )}
    </>
  )
}