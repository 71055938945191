import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from "react-router-dom";

import api from '../../services/api';
import apiZipcode from '../../services/apiZipcode';

import {
  FormatDocumentCpf,
  FormatZipcode,
  FormatImei,
  FormatCelphone,
} from '../../utils/FormatData';
import swal from 'sweetalert';


import * as Styled from './styled'
import { useForm } from "react-hook-form";
import Toggle from "react-toggle";
import Multiselect from 'multiselect-react-dropdown';
import CheckIcon from '@material-ui/icons/Check';
import { isEmpty } from 'lodash';
import { validateCpfCnpj } from '../../utils/validateCpfCnpj';

const UserRegisterV2 = () => {
  const [listRole, setListRole] = useState([]);
  const [listProject, setListProject] = useState([]);
  const [listPermissions, setListPermissions] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [availableGroups, setAvailableGroups] = useState([]);
  const [availableGroupsAndTeams, setAvailableGroupsAndTeams] = useState([])
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [resetPassRequired, setResetPassRequired] = useState(false);
  const history = useHistory()

  const noProjectsRoles = ['6','7'];

  const { register, handleSubmit, watch, setValue, formState: { errors }} = useForm({
    mode:'onChange',
    defaultValues: {
      fullname: '',
      email: '',
      cpf: '',
      address: '',
      address_city: '',
      address_complement: '',
      address_district: '',
      address_number: '',
      address_state: '',
      address_zip: '',
      birth_date: '',
      hire_date: '',
      corporate_mobile_phone: '',
      imei_corporate_mobile_phone: '',
      id_users_type:'',
      note: '',
      agency: '',
      agency_digit: '',
      bank: '',
      bank_code: '',
      bank_account: '',
      check_digit: '',
      employee_status: '',
      registration: ''   
    }
  });

  const watchUserType = watch('id_users_type');
  const watchZipCode = watch('address_zip');
  const watchDocument = watch('cpf')
  const watchCellphone = watch('corporate_mobile_phone')
  const watchImei = watch('imei_corporate_mobile_phone')
  const watchEmail = watch('email')

  const getRoles = useCallback(async () => {
    const response = await api.get('/usertypes')
    setListRole(response.data)
  },[])

  const getPermissions = useCallback(async () => {
    const response = await api.get('/roles/all')
    setListPermissions(response.data)
  },[])

  const getProjects = useCallback(async () => {
    const response = await api.get('/projects')
    setListProject(response.data)
  },[])

  const getGroups = useCallback(async() => {
    setAvailableGroups([])
    if(selectedProjects.length) {
      selectedProjects.forEach((project) => {
        api.get(`/groupSections?id_project=${project?.id}&show_teams=true`)
          .then((response) => {
            setAvailableGroups((prevState) => [...prevState, ...response.data])
          })
      })
    }
  },[selectedProjects])

  useEffect(() => {
    if(!isEmpty(selectedProjects) && !isEmpty(availableGroups)) {
      const copy = availableGroups
      const copy2 = copy.map((group, index) => {
        if (watchUserType < 3) {
          if(!isEmpty(group.teams)) {
            return group.teams.map((team) => {
              return {...group,id_section:team.id, section: `Grupo (${group.section}) - Time (${team.name})`, projectName: selectedProjects.filter(p => p.id === group.id_project)[0]?.name ?? ''}
            })
          }
        }
        return {...group, projectName: selectedProjects.filter(p => p.id === group.id_project)[0]?.name ?? ''}
      })
      setAvailableGroupsAndTeams(copy2.flat())
    }

  },[availableGroups, selectedProjects])

  useEffect(() => {
    !isEmpty(selectedProjects) && getGroups()
  },[selectedProjects, getGroups])

  useEffect(() => {
    getRoles()
    getProjects()
    getPermissions()
  },[getProjects, getRoles, getPermissions])

  const getAddressByCep = useCallback( async(value) => {
    if (value.replace(/\D/g, '').length >= 8) {
      const cep = value.replace(/\D/g, '');
      const response = await apiZipcode.get(`${cep}/json`)
      setValue('address', response?.data?.logradouro)
      setValue('address_district', response?.data?.bairro)
      setValue('address_city', response?.data?.localidade)
      setValue('address_state', response?.data?.uf)
    }
  },[setValue])

  useEffect(() => {
    if(watchZipCode) {
      setValue('address_zip', FormatZipcode(watchZipCode))
      getAddressByCep(watchZipCode)
    }
  },[watchZipCode, getAddressByCep, setValue])

  useEffect(() => {
    if(watchDocument) {
      setValue('cpf', FormatDocumentCpf(watchDocument))
    }
  },[watchDocument, setValue])

  useEffect(() => {
    if(watchCellphone) {
      setValue('corporate_mobile_phone', FormatCelphone(watchCellphone))
    }
  },[watchCellphone, setValue])

  useEffect(() => {
    if(watchImei) {
      setValue('imei_corporate_mobile_phone', FormatImei(watchImei))
    }
  },[watchImei, setValue])

  useEffect(() => {
    if(watchEmail) {
      setValue('email', watchEmail.trim())
    }
  },[watchEmail, setValue])
  
  useEffect(() => {
    if(watchUserType) {
      setSelectedProjects([])
      setSelectedGroups([])
    }
  },[watchUserType])


  const focusBirthDate = (e) => {
    e.currentTarget.type = 'date'
  }

  const blurBirthDate = (e) => {
    e.currentTarget.type = 'text'
  }

  const focusHiringDate = (e) => {
    e.currentTarget.type = 'date'
  }

  const blurHiringDate = (e) => {
    e.currentTarget.type = 'text'
  }

  const selectProject = (selectedList, selectedItem) => {
    if (!selectedProjects.some(project => project.id === selectedItem.id)) {
      setSelectedProjects((prevState) => {    
        return [...prevState, selectedItem]
      })
    }
  }

  const removeProject = (selectedList, selectedItem) => {
    const copy = selectedProjects

    if (selectedGroups.some(group => group.id_project === selectedItem.id)) {
      setSelectedGroups(selectedGroups.filter(g => g.id_project !== selectedItem.id))
    }

    setSelectedProjects(copy.filter(data => data.id !== selectedItem.id))
  }

  const selectGroup = (selectedList, selectedItem) => {
    if (!selectedGroups.some(group => group.id === selectedItem.id)) {
      setSelectedGroups((prevState) => [...prevState, selectedItem])
    }
  }

  const removeGroup = (selectedList, selectedItem) => {    
    const copy = selectedGroups
    setSelectedGroups(copy.filter(data => data.id !== selectedItem.id))
  }

  const onSubmit = async(data) => {
    console.log("Data: ", data)
    const postData = {...data}

    if (selectedProjects.length === 0) {
      swal(`Obrigatório adicionar o projeto!`, {
        icon: "warning",
        button: "Fechar"
      });

      return
    }

    if (selectedGroups.length === 0 && Number(postData?.id_users_type) < 3) {
      swal(`Usuário de cargo (Promotor e Supervisor) é obrigatório estar em um Grupo/Time`, {
        icon: "warning",
        button: "Fechar"
      });

      return
    } 

    if (postData.cpf) {
      const valid_cpf = validateCpfCnpj(postData.cpf)

      if (!valid_cpf) {
        swal(`CPF inválido!`, {
          icon: "warning",
          button: "Fechar"
        });

        return
      }  
    }
    
    postData.score = 0
    postData.active = !!postData.active
    postData.reset_pass_required = resetPassRequired
    postData.corporate_mobile_phone = postData.corporate_mobile_phone.replace(/\D/g,'')   

    if(!isEmpty(selectedProjects)) {
      postData.id_projects = selectedProjects.map(project => project.id)
    }   

    let createWalletAutomatically = false

    if (Number(postData?.id_users_type) < 3) {
      createWalletAutomatically = await swal({
        title: `Deseja criar carteira automaticamente?`,
        text: `Ao escolher (Atribuir) você será redirecionado para tela de carteiras e poderá adicionar uma carteira já existente!`,
        icon: "info",
        buttons: {
          cancel: {
            text: "Fechar",
            value: null,
            visible: true,          
            closeModal: true,
          },
          confirm: {
            text: "Atribuir",
            value: false,
            visible: true,
            closeModal: true
          },
          roll: {
            text: "Criar",
            value: true,
            visible: true,
            closeModal: true
          },
        },
        dangerMode: true,
      })

      if (createWalletAutomatically === null) return
    }
   
    postData.createWalletAutomatically = createWalletAutomatically

    try {
      const response = await api.post('/users', postData)

      if (response?.status === 200) {
        if(selectedGroups.length > 0) {
          const promises = []
  
          selectedGroups.forEach(groups => {
            const groupPostData = { user_id: response.data.id, section_id: groups.id, id_group: groups.id_section }
  
            if (Number(postData?.id_users_type) > 2) {
              groupPostData.watcher = true
            }
  
            promises.push(
              api.post(`/groupSections/user/add`, groupPostData)
            )
          })
  
          try {
            await Promise.all(promises)
  
          } catch (error) {
            const message = error?.response?.data?.error ?? `Não foi possível editar usuário`
        
            swal(message, {
              icon: "error",
              button: "Fechar"
            });
            
            setTimeout(() => {
              history.push(`/UsersRegister/${response.data.id}`)
            }, 2500)          

            return
          }
        }

        swal(`Usuário registrado com sucesso!`, {
          icon: "success",
          button: "Fechar"
        });
  
        setTimeout(() => {          
          if (!createWalletAutomatically && Number(postData?.id_users_type) < 3) {
            history.push(`/Settings?s=wallets&id_user=${response.data.id}`)
          } else {
            history.goBack()            
          }
        }, 1600)
      }

    } catch(e) {
      const message = e?.response?.data?.message ?? `Erro ao cadastrar usuário`
      
      swal(message, {
        icon: "error",
        button: "Fechar"
      });
    }
  }

  return (
    <Styled.Container>
      <Styled.HeaderCard>
        <Styled.HeaderTitle>
          Usuários
        </Styled.HeaderTitle>
        <Styled.ButtonDiv>
          <Styled.NewUserButton disabled={false} type='button' onClick={handleSubmit((data) => onSubmit(data))}>
            <CheckIcon /> <span>Salvar</span>
          </Styled.NewUserButton>
        </Styled.ButtonDiv>
      </Styled.HeaderCard>
      <Styled.MainCard>
        <form>
          <Styled.FormGrid>
            <Styled.LeftGrid>
              <Styled.GridTitle>
                Dados Pessoais
              </Styled.GridTitle>
              <Styled.ContainerInputs>
                <Styled.InputLabel>Nome completo*</Styled.InputLabel>
                <Styled.FullInput {...register('fullname', {required:true})} type='text' placeholder='Digite o nome completo do usuário*' />
              </Styled.ContainerInputs>

              <Styled.ContainerInputs> 
                <Styled.InputLabel>Email*</Styled.InputLabel>
                <Styled.FullInput {...register('email', {required:true, pattern: { value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/, message: 'Email inválido' }  })} type='email' placeholder='Email*' />
                {errors.email && <span 
                  style={{ 
                    color: 'red', 
                    fontSize: '11px', 
                    fontWeight: 'bold', 
                    marginLeft: '3px', 
                    marginTop: '2px'
                    }}
                >
                  {errors.email.message}
                </span>}
              </Styled.ContainerInputs>


              <Styled.ContainerInputsHalf> 
                <Styled.HalfInputDiv>
                  <Styled.InputLabel>CPF*</Styled.InputLabel>
                  <Styled.FullInput {...register('cpf',{required:true})} type='text' placeholder='CPF*' />
                </Styled.HalfInputDiv>
                <Styled.HalfInputDiv>
                  <Styled.InputLabel>Data de nascimento*</Styled.InputLabel>
                  <Styled.FullInput 
                    onFocus={(e) => focusBirthDate(e)}
                    onBlur={(e) => blurBirthDate(e)} 
                    {...register('birth_date',{required:true})}
                    type='text'
                    placeholder='Data de nascimento*'
                  />
                </Styled.HalfInputDiv>
              </Styled.ContainerInputsHalf>
              
              <Styled.ContainerInputsHalf> 
                <Styled.HalfInputDiv>
                  <Styled.InputLabel>CEP*</Styled.InputLabel>
                  <Styled.FullInput {...register('address_zip',{required:true})} type='text' placeholder='CEP' />
                </Styled.HalfInputDiv>
                <Styled.HalfInputDiv>
                  <Styled.InputLabel>Nº*</Styled.InputLabel>
                  <Styled.FullInput {...register('address_number',{required:true})} type='text' placeholder='Nº' />
                </Styled.HalfInputDiv>               
              </Styled.ContainerInputsHalf>

              <Styled.ContainerInputs> 
                <Styled.InputLabel>Complemento*</Styled.InputLabel>
                <Styled.FullInput {...register('address_complement')} type='text' placeholder='Complemento' />               
               </Styled.ContainerInputs>
               
               <Styled.ContainerInputs> 
                <Styled.InputLabel>Endereço*</Styled.InputLabel>
                <Styled.FullInput {...register('address', {required: true})} type='text' placeholder='Endereço' />               
               </Styled.ContainerInputs>

               <Styled.ContainerInputs> 
                <Styled.InputLabel>Bairro*</Styled.InputLabel>
                <Styled.FullInput {...register('address_district', {required: true})} type='text' placeholder='Bairro' maxLength={9}/>               
               </Styled.ContainerInputs>
               
              <Styled.ContainerInputsHalf> 
                <Styled.HalfInputDiv>
                  <Styled.InputLabel>Cidade*</Styled.InputLabel>
                  <Styled.FullInput {...register('address_city',{required:true})} type='text' placeholder='Cidade' />
                </Styled.HalfInputDiv>

                <Styled.HalfInputDiv>
                  <Styled.InputLabel>UF*</Styled.InputLabel>
                  <Styled.FullInput {...register('address_state',{required:true})} type='text' placeholder='UF' maxLength={2}/>
                </Styled.HalfInputDiv>               
              </Styled.ContainerInputsHalf>   

              <Styled.ContainerInputsHalf> 
                <Styled.HalfInputDiv>
                  <Styled.InputLabel>Código Banco</Styled.InputLabel>
                  <Styled.FullInput {...register('bank_code')} type='text' placeholder='Cód Banco' maxLength={3}/>
                </Styled.HalfInputDiv>

                <Styled.HalfInputDiv>
                  <Styled.InputLabel>Banco</Styled.InputLabel>
                  <Styled.FullInput {...register('bank')} type='text' placeholder='Banco'/>
                </Styled.HalfInputDiv>               
              </Styled.ContainerInputsHalf>    

              <Styled.ContainerInputsHalf> 
                <Styled.HalfInputDiv>
                  <Styled.InputLabel>Agência</Styled.InputLabel>
                  <Styled.FullInput {...register('agency')} type='text' placeholder='Agência' maxLength={4}/>
                </Styled.HalfInputDiv>

                <Styled.HalfInputDiv>
                  <Styled.InputLabel>Dígito Agência</Styled.InputLabel>
                  <Styled.FullInput {...register('agency_digit')} type='text' placeholder='Dígito Agência' maxLength={1}/>
                </Styled.HalfInputDiv>               
              </Styled.ContainerInputsHalf>     

              <Styled.ContainerInputsHalf> 
                <Styled.HalfInputDiv>
                  <Styled.InputLabel>Conta Bancária</Styled.InputLabel>
                  <Styled.FullInput {...register('bank_account')} type='text' placeholder='Conta Bancária' maxLength={11}/>
                </Styled.HalfInputDiv>

                <Styled.HalfInputDiv>
                  <Styled.InputLabel>Dígito Verificação</Styled.InputLabel>
                  <Styled.FullInput {...register('check_digit')} type='text' placeholder='Dígito Verificação' maxLength={1}/>
                </Styled.HalfInputDiv>               
              </Styled.ContainerInputsHalf>  
              
              <Styled.ToggleDiv>
                <Toggle 
                  icons={false} 
                  checked={resetPassRequired} 
                  onChange={_ => {
                    setResetPassRequired(!resetPassRequired)
                  }}
                />
                <Styled.ToggleSpan style={{ marginRight: '10px', marginLeft: '2px' }}>
                  Exigir alteração de senha no próximo acesso
                </Styled.ToggleSpan>

                <Toggle {...register('active')} icons={false} />
                <Styled.ToggleSpan style={{ marginLeft: '2px' }}>Usuário Ativo</Styled.ToggleSpan>               
              </Styled.ToggleDiv>
            </Styled.LeftGrid>

            <Styled.RightGrid>
                <Styled.GridTitle>
                    Dados Profissionais
                </Styled.GridTitle>
                <Styled.ContainerInputsHalf> 
                    <Styled.HalfInputDiv>
                      <Styled.InputLabel>Cargo*</Styled.InputLabel>
                      <Styled.Select {...register('id_users_type', {required: true})}>
                        <option value='' >Cargo*</option>
                        {listRole?.map((item) => (
                          <option key={item.id} value={item.id}>{item.name}</option>
                        ))}
                      </Styled.Select>
                    </Styled.HalfInputDiv>                  
                    <Styled.HalfInputDiv>
                      <Styled.InputLabel>Permissão*</Styled.InputLabel>
                      <Styled.Select {...register('roles', {required:true})}>
                        <option value='' >Permissão*</option>
                        {listPermissions?.map((item) => (
                          <option key={item.id} value={item.id}>{item.name}</option>
                        ))}
                      </Styled.Select> 
                    </Styled.HalfInputDiv>               
                </Styled.ContainerInputsHalf>
                <Styled.MultiSelectDiv>
                  <Styled.GridTitle style={{ marginBottom: '5px' }}>
                    Projetos
                  </Styled.GridTitle>
                      {!watchUserType && (
                        <>
                          <Styled.NoDataSpan>
                            Selecione o cargo para visualizar os projetos*
                          </Styled.NoDataSpan>
                        </>
                      )}
                      {watchUserType && !noProjectsRoles.includes(watchUserType) &&  (
                        <>
                          <Multiselect
                            style={{
                              searchBox: { // To change search box element look
                                'border-radius': '25px',
                                display: 'flex',
                                flexDirection: 'column',
                                width: '450px'
                              },
                              searchWrapper: { // To change search box element look
                                borderRadius: '25px',
                                display: 'flex',
                                flexDirection: 'row',
                              },

                              option: {
                                color: 'black',                             
                              },
                              chips : {
                                background: '#EE5928',
                                borderRadius: '25px',
                                color:'white',
                                width: 'auto',
                                maxWidth: '100%',
                                display:'flex',
                                justifyContent:'space-between',
                              },
                              optionContainer: {
                                width: '450px',
                                borderRadius: '25px',
                                fontSize:'12px',
                                color: 'black',
                                '& li': {
                                  backgroundColor: 'pink'
                                },
                              }
                            }} 
                            emptyRecordMsg={'Não tem mais opções disponíveis'}    
                            placeholder={'Clique aqui para adicionar'}
                            options={listProject} // Options to display in the dropdown
                            onSelect={selectProject} // Function will trigger on select event
                            onRemove={removeProject} // Function will trigger on remove event
                            displayValue="name" // Property name to display in the dropdown options
                            selectedValues={selectedProjects}
                            //singleSelect={watchUserType < 3}
                            selectionLimit={Number(watchUserType) < 3 ? 1 : -1}
                          />
                        </>
                      )}
                </Styled.MultiSelectDiv>
                <Styled.MultiSelectDiv>
                  <Styled.GridTitle style={{ marginBottom: '5px' }}>
                      Grupos e Times
                  </Styled.GridTitle>
                      {!watchUserType && isEmpty(selectedProjects) && (
                        <>
                          <Styled.NoDataSpan>
                            Selecione o cargo e o projeto para visualizar os grupos*
                          </Styled.NoDataSpan>
                        </>
                      )}
                      {watchUserType && !noProjectsRoles.includes(watchUserType) && !isEmpty(selectedProjects) && 
                        !isEmpty(availableGroups) && (
                        <>
                          <Multiselect
                            style={{
                              searchBox: { // To change search box element look
                                'border-radius': '25px',
                                display: 'flex',
                                flexDirection: 'column',
                                width: '450px'
                              },
                              searchWrapper: { // To change search box element look
                                borderRadius: '25px',
                                display: 'flex',
                                flexDirection: 'column'
                              },
                              option: {
                                color: 'black',
                              },
                              chips : {
                                background: '#EE5928',
                                borderRadius: '25px',
                                color:'white',
                                width: 'auto',
                                maxWidth: '100%',
                                display:'flex',
                                justifyContent:'space-between'
                              },
                              optionContainer: {
                                width: '450px',
                                borderRadius: '25px',
                                fontSize:'12px',
                                color: 'white',
                                '& li': {
                                  backgroundColor: 'pink'
                                },
                              }
                            }}
                            emptyRecordMsg={'Não tem mais opções disponíveis'}    
                            placeholder={'Clique aqui para adicionar'}
                            options={availableGroupsAndTeams} // Options to display in the dropdown
                            groupBy="projectName"
                            onSelect={selectGroup} // Function will trigger on select event
                            onRemove={removeGroup} // Function will trigger on remove event
                            displayValue="section" // Property name to display in the dropdown options
                            selectedValues={selectedGroups}
                            selectionLimit={Number(watchUserType) < 2 ? 1 : -1}
                          />
                        </>
                      )}
                </Styled.MultiSelectDiv>
                
                <Styled.ContainerInputs> 
                  <Styled.InputLabel style={{ marginTop: '5px' }}>Data de Contratação*</Styled.InputLabel>
                  <Styled.FullInput
                    onFocus={(e) => focusHiringDate(e)}
                    onBlur={(e) => blurHiringDate(e)} 
                    {...register('hire_date', {required:true})}
                    type='text' 
                    placeholder='Data de contratação*' 
                  />               
                </Styled.ContainerInputs>
                <Styled.ContainerInputs> 
                  <Styled.InputLabel>Número do Celular Corporativo</Styled.InputLabel>
                  <Styled.FullInput {...register('corporate_mobile_phone')} type='text' placeholder='Celular corporativo' maxLength={16}/>               
                </Styled.ContainerInputs>
                
                <Styled.ContainerInputs> 
                  <Styled.InputLabel>Imei do Celular Corporativo</Styled.InputLabel>
                  <Styled.FullInput {...register('imei_corporate_mobile_phone')} type='text' placeholder='Imei do celular corporativo' maxLength={20}/>               
                </Styled.ContainerInputs>

                <Styled.ContainerInputs> 
                <Styled.InputLabel>Matrícula</Styled.InputLabel>
                <Styled.FullInput {...register('registration')} type='text' placeholder='Matrícula' />               
               </Styled.ContainerInputs>

               <Styled.ContainerInputs> 
                <Styled.InputLabel>Situação do Empregado</Styled.InputLabel>
                <Styled.FullInput {...register('employee_status')} type='text' placeholder='Situação do Empregado' />               
               </Styled.ContainerInputs>

                <Styled.ContainerInputs> 
                  <Styled.InputLabel>Notas</Styled.InputLabel>
                  <Styled.MainTextArea {...register('note')} placeholder='Notas' />               
                </Styled.ContainerInputs>
            </Styled.RightGrid>
          </Styled.FormGrid>
        </form>
      </Styled.MainCard>
    </Styled.Container>
  )

}

export default UserRegisterV2