import React, {useState, useEffect} from 'react';
import {useHistory, useParams} from "react-router-dom";

import Dropzone from 'react-dropzone';
import api from '../../../services/api';
import apiZipcode from '../../../services/apiZipcode';

import {RoomOutlined, Add, HourglassEmpty, HighlightOff} from '@material-ui/icons';
import { FormatZipcode } from '../../../utils/FormatData';
import SubmitButtonContainer from '../../../components/SubmitButtonContainer';

function StepVisit(props){
  let {idCustomer} = useParams();
  const [prospection, setProspection] = useState({
    fantasy_name: "",
    id_customer_segment: 0,
    latitude: 0,
    longitude: 0,
    address: "",
    address_number: "",
    address_complement: "",
    address_district: "",
    address_city: "",
    address_state: "",
    address_zip: "",
    id_files: [],
    id_project: props.userProject?.id
  })
  const geolocation = false;
  // const [geolocation, setGeolocation] = useState(false);
  const [segmentList, setSegmentList] = useState([]);
  const [invalidInput, setInvalidInput] = useState([]);
  const [invalidMessage, setInvalidMessage] = useState('');
  const [imgList, setImgList] = useState([]);
  const history = useHistory();

  useEffect(() => {
    api.get('/segments')
    .then(response => {
      if(idCustomer && props.userProject?.id){
        api.get('/customers/'+idCustomer, {params:{id_project:props.userProject.id}})
        .then(response => {
          if(response?.data){
            const data = response?.data;
            setProspection({
              id_files: [],
              id_project: props.userProject?.id?props.userProject?.id:'',
              fantasy_name: data.fantasy_name?data.fantasy_name:'',
              id_customer_segment: data.id_customer_segment?data.id_customer_segment:'',
              latitude: data.latitude?data.latitude:'',
              longitude: data.longitude?data.longitude:'',
              address: data.address?data.address:'',
              address_number: data.address_number?data.address_number:'',
              address_complement: data.address_complement?data.address_complement:'',
              address_district: data.address_district?data.address_district:'',
              address_city: data.address_city?data.address_city:'',
              address_state: data.address_state?data.address_state:'',
              address_zip: data.address_zip?data.address_zip:''
            });
            setImgList(data.files)
          }
        }).catch(err => {
          console.log('err: ',err);
        });
      }else{
        setProspection({
          fantasy_name: "",
          id_customer_segment: 0,
          latitude: 0,
          longitude: 0,
          address: "",
          address_number: "",
          address_complement: "",
          address_district: "",
          address_city: "",
          address_state: "",
          address_zip: "",
          id_files: [],
          id_project: props.userProject?.id?props.userProject?.id:''
        })
      }

      if(response?.data){
        setSegmentList(response?.data);
        return({success: true})
      }
      return({success: false})
    }).catch(err => {
      console.log(err);
      return({success: false})
    });

    // if ("geolocation" in navigator) {
    //   console.log("Available");
    //   setGeolocation(true);
    // } else {
    //   console.log("Not Available");
    //   setGeolocation(false);
    // }

    return () => {
      setSegmentList([]);
    }
  }, [idCustomer, props])

  async function submitForm(){
    let invalidList = [];
    let postProspection = prospection;

    if(!postProspection.fantasy_name){
      invalidList.push('fantasy_name');
    }
    if(!postProspection.id_customer_segment){
      invalidList.push('segment');
    }
    if(!postProspection.address_number){
      invalidList.push('number');
    }
    if(!postProspection.address_city){
      invalidList.push('city');
    }
    if(!postProspection.address_state){
      invalidList.push('state');
    }
    if(!postProspection.address_zip){
      invalidList.push('zip');
    }
    
    let images = []
    if(imgList?.length > 0){
      imgList.forEach(item => {
        images.push(item.id);
        return item;
      })
    }
    postProspection.id_files = images
    // console.log(images);
    // console.log('prospection: ', postProspection)

    if(invalidList.length > 0){
      console.log('Não foi possivel salvar a nova prospeção.')
      setInvalidInput(invalidList);
      setInvalidMessage('Não foi possivel salvar a nova prospeção.')
    }else{
      console.log('submit: ', prospection)
      if(idCustomer){
        api.put('/customers/'+idCustomer, prospection)
        .then(response => {
          if(response?.data){
            console.log(response?.data);
            if(response?.data.id){
              history.push('/CustomerRegister/stepContact/'+response?.data.id)
            }
          }else{
            console.log('prospections: ', response);
          }
        }).catch(err => {
          console.log('prospections error: ', err);
          // if(err.response?.data?.find(message => message.field === 'social_name')){        
          //   invalidList.push('social_name');
          //   setInvalidMessage('A razão social já cadastrada.')
          // }
          setInvalidInput(invalidList);
        })
      }else{
        let newProspection = prospection;
        console.log(newProspection)
        api.post('/prospections', newProspection)
        .then(response => {
          if(response?.data){
            console.log(response?.data);
            if(response?.data.id){
              history.push('/CustomerRegister/stepContact/'+response?.data.id)
            }
          }else{
            console.log('prospections: ', response);
          }
        }).catch(err => {
          console.log('prospections error: ', err);
        })
      }
    }
  }

  function addressByCep(value){
    if(value.replace(/\D/g, '').length >= 8){
      const cep = value.replace(/\D/g, '');
      apiZipcode.get( cep + '/json/').then(response => {
        if(response.data){
          const fullAddress = response.data;
          
          let invalidList = invalidInput.filter(item => (
            item !== 'zip' && 
            item !== 'address' && 
            item !== 'district'&& 
            item !== 'state' && 
            item !== 'city'
          ));

          console.log(invalidList)
          setInvalidInput(invalidList);

          setProspection({
            ...prospection, 
            address_zip: FormatZipcode(value),
            address: fullAddress.logradouro,
            address_district: fullAddress.bairro,
            address_city: fullAddress.localidade,
            address_state: fullAddress.uf,
          })
        }
      }).catch(err => {
        console.log(err)
      })
    }else{
      setProspection({
        ...prospection, 
        address_zip: FormatZipcode(value)
      });
    }
  }
  
  async function loadFiles(id, files){
    if(id !== null){
      const formData = new FormData();
      formData.append('file', files[0])

      api.put('/uploads/'+id, formData)
      .then(response =>{
        if(response.data.upload){
          setImgList(list => (
            list.map(img => (
              img.id !== id ? img : response.data.upload
            ))
          ))
        }
      }).catch(err => {
        console.log('response: ', err);
      })
    }else{
      const formData = new FormData();
      formData.append('file', files[0])
      
      api.post('/uploads', formData)
      .then(response =>{
        if(response.data.upload){
          console.log('response: ', response.data.upload);
          setImgList([...imgList, response.data.upload]);
          removeInvalidInput('images');
        }
      }).catch(err => {
        console.log('response: ', err);
      })
    }
  }

  async function removeImage(id){
    api.delete('/uploads/'+id)
    .then(response =>{
      if(response.status !== 404){
        let imgs = imgList.filter(item => item.id !== id);
        setImgList(imgs);
      }
    }).catch(err => {
      console.log(err)
    })
  }

  function removeInvalidInput(input){
    if(invalidInput.includes(input)){
      setInvalidInput(invalidInput.filter(item => item !== input));
    }    
  }

  function loadLocation(){
    if (navigator.geolocation) {
      navigator.geolocation.watchPosition(function(position) {
        console.log("Latitude is :", position.coords.latitude);
        console.log("Longitude is :", position.coords.longitude);
      });
    }
  }

  return(
    <>
      <div id='stepFormRow'>
        <div className='stepFormHalf'>
          <input 
            className={`inputRounded ${invalidInput.includes('fantasy_name')?'invalidInput':''}`}
            placeholder='Nome do estabelecimento'
            value={prospection.fantasy_name}
            onChange={(event) => {
              removeInvalidInput('fantasy_name');
              setProspection({...prospection, fantasy_name:event.target.value})
            }}
          />
          <select 
            className={`inputRounded ${invalidInput.includes('segment')?'invalidInput':''}`}
            value={prospection.id_customer_segment}
            onChange={(event)=>{
              removeInvalidInput('segment');
              console.log(parseInt(event.target.value, 10))
              setProspection({
                ...prospection, 
                id_customer_segment: parseInt(event.target.value, 10),
              })}}>
            <option value={0} className='inputPlaceholder' disabled>
              Tipo de estabelecimento
            </option>
            {segmentList?.map(item => (
              <option key={item?.id} value={item?.id}>{item?.name}</option>
            ))}
          </select>
          <div style={{marginTop:'5%'}} />
          <div id='centerAlignedButton'>
            {geolocation && (
              <button 
                className='colorfulButton' 
                onClick={()=>{
                  loadLocation()
                }}>
                <RoomOutlined className='materialIcon'/>
                Localização
              </button>
            )}
          </div>
          <input 
            placeholder='CEP' 
            className={`inputRounded ${invalidInput.includes('zip')?'invalidInput':''}`}
            maxLength='9'
            value={prospection.address_zip}
            onChange={(event) => {
              addressByCep(event.target.value);
            }}
          />
          <div className='stepInputRow'>
            <input 
              placeholder='Complemento' 
              className='inputRounded stepRowMedium'
              value={prospection.address_complement}
              onChange={(event) => {
                setProspection({...prospection, address_complement:event.target.value})
              }}
            />
            <div className='stepRowSmall stepInputRow'>
              <label className='inputLabel'>Nº</label>
              <input 
                className={`inputRounded ${invalidInput.includes('number')?'invalidInput':''}`}
                style={{width: '100%'}}
                value={prospection.address_number}
                onChange={(event) => {
                  removeInvalidInput('number');
                  setProspection({...prospection, address_number:event.target.value});
                }}
              />
            </div>
          </div>
          <div style={{marginTop:'10%'}} />
          <input 
            placeholder='Endereço' 
            className={`inputUnderline ${invalidInput.includes('address')?'invalidInputUnderline':''}`}
            value={prospection.address}
            onChange={(event) => {
              removeInvalidInput('address');
              setProspection({...prospection, address:event.target.value})
            }}
            disabled
          />
          <input 
            placeholder='Bairro' 
            className={`inputUnderline ${invalidInput.includes('district')?'invalidInputUnderline':''}`}
            value={prospection.address_district}
            onChange={(event) => {
              setProspection({...prospection, address_district:event.target.value})
            }}
            disabled
          />
          <div className='stepInputRow'>
            <input 
              placeholder='Cidade' 
              className={`inputUnderline stepRowMedium ${invalidInput.includes('city')?'invalidInputUnderline':''}`}
              value={prospection.address_city}
              onChange={(event) => {
                setProspection({...prospection, address_city:event.target.value})
              }}
              disabled
            />
            <input 
              placeholder='UF' 
              className={`inputUnderline stepRowSmall ${invalidInput.includes('state')?'invalidInputUnderline':''}`}
              value={prospection.address_state}
              onChange={(event) => {
                setProspection({...prospection, address_state:event.target.value})
              }}
              disabled
            />
          </div>
        </div>
        <div className='stepFormHalf'>
          <div className='leftAlignedTitle'>
            <label>Fotos</label>
          </div>
          <div id='imageListContainer'>
            <div className='imageRow'>
              <Dropzone 
                accept='image/*' 
                onDrop={acceptedFiles => loadFiles(null, acceptedFiles)} 
              >
                {({getRootProps, getInputProps, isDragActive, isDragReject}) => (
                  <div 
                    className={`imageCard imagemDrop ${invalidInput.includes('images')?'invalidInput':''}`} 
                    {...getRootProps()} 
                  >
                    <input {...getInputProps()} />
                    <Add className='iconAdd' style={{ fontSize: 40 }}/>
                  </div>
                )}
              </Dropzone>
              
              {
                imgList.map((item, index) => {
                  if(!item.loading){
                    return (
                      <Dropzone 
                        key={index} 
                        accept='image/*' 
                        onDrop={acceptedFiles => loadFiles(item.id, acceptedFiles)} 
                      >
                        {({getRootProps, getInputProps, isDragActive, isDragReject}) => (
                          <div key={index} className='imageRemove'>
                            <div className='imageCard imagemDrop' {...getRootProps()} >
                              <input {...getInputProps()} />
                              {item?.uri? 
                                <img  key={index} className='' src={item.uri} alt='photoClient'/> :
                                <Add className='iconAdd' style={{ fontSize: 40 }}/>
                              }
                            </div>
                            <button className='iconDelete' onClick={() => removeImage(item.id)}>
                              <HighlightOff style={{ fontSize: 30 }}/>
                            </button>
                          </div>
                        )}
                      </Dropzone>
                    )
                  }else{
                    return (
                      <div key={item.id} className='imageCard'>
                        <HourglassEmpty className='iconHourglassEmpty' style={{ fontSize: 40 }}/>
                      </div>
                    )
                  }
                })
              }
            </div>
          </div>
          <label id="invalidMessage">{invalidMessage}</label>
        </div>
      </div>
      <SubmitButtonContainer 
        cancelForm={()=>{
          console.log('Sair')
          history.push('/Customer')
        }}
        submitForm={()=>{submitForm()}}
      />
    </>
  )
}

export default StepVisit;