import styled from 'styled-components'

export const Container = styled.div`
  font-family: 'Roboto';
  display:flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
justify-content: space-between;
  border-radius: 15px;
  box-shadow: 5px 5px 15px 5px rgb(0 0 0 / 15%);
  padding: 10px;
  margin-bottom: 20px;
`

export const Label = styled.div`
    font-size: 0.8rem;
    font-weight: bold;
    color:#AAAAAA;
    white-space: nowrap;
    max-lines: 1;
    `
export const Value = styled.div`
    font-size: 0.8rem;
    font-weight: bold;
    margin-left: 10px;
    color:#666666;
    `
export const DashInfoColumn = styled.div`
    display: flex;
   flex-direction: column;
   align-items: flex-end;
   justify-content: flex-start;

        margin-left:15px;
    
    `
export const DashValueContainer = styled.div`
    display: flex;
    flex-direction: row;
`
export const InfoUser = styled.div`
    margin-right: 17px;
    font-size: 0.7rem;
    padding: 7px;
    text-transform: capitalize;
    
    cursor: pointer; 

    @media (max-width: 1500px) {
        width:200px ;
    }

    > div > span {
        &.coordinator{
            color: #222;   
            font-size: 1rem;

        }
        &.group{

            color: #EE5928;        
            font-size: 0.9rem;
            margin-bottom: 10px;  
        }
        font-style: normal;
        font-weight: 700;
         
    }


`

export const InfoUserContent = styled.div`
   
   > div {
        display: flex;
        flex-direction: row;
        margin-bottom: 2px;
        color: #888888;

        > span:first-child {
            margin-right: 5px;            
        }

        > span:last-child {
            font-style: normal;
            font-weight: 700;        
            color: #666666;
        }
   }
   

`

export const Info = styled.div`
    display:flex ;
    justify-content: flex-end;
    height: 90px;
    border-radius: 15px;
    box-shadow: 0px 1.5px 10px rgba(0, 0, 0, 0.2);
    margin-right: 17px;
    padding: 5px;
    margin: 5px;

`
export const InfoContent = styled.div`
   
    flex-direction: row;
    justify-content: space-evenly;    
    padding: 5px;



    > div:first-child {
        margin-right: 5px;
        > span {
            font-style: normal;
            font-weight: 700;
            font-size: 16px;

        }   

        > span:last-child {
            margin-top: 4px;
            font-size: 35px;
        }
    }


`



