import styled from'styled-components'

export const Root = styled.div`
  z-index: 10000;
  &::before{
    display: block;
    content: ""
  }
`

export const Modal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: fixed;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
  align-items: center;
  bottom: 0;
  overflow: auto;
  right: 0;
  opacity: 1;
  z-index: 10000;
  padding-bottom: 25px;
  margin: 0 auto; 

  .carousel-container {
    margin: initial;
    margin-bottom: 20px;
  }

  .slide-number {
    font-weight: bold;
    color: #fff;
    font-size: 20px;
    text-shadow: 0 0 3px #ff0000, 0 0 5px #0000ff;
  }
  

  .prev,.next {
    color: #fff;
    margin: 0 -10%;
    top: 0;
  }
`
export const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  height: 28px;
  width: 28px;
  border-radius: 20px;
  margin-bottom: 10px;
  position: relative;
  top:11px;
  z-index: 1;
`
export const DivHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin: 0;
  width: 100%;
  button {
    background-color: transparent;
    color: #fff;
    font-size: 2rem;

    > svg {
      font-size: 50px;
    }
  }
`
export const Main = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  text-align: center;
  background-color: white;
`
