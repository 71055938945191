import styled from 'styled-components';

const Styles = styled.div`

*{
 cursor: ${props => props.noClick?'default':'pointer'}
}

.customerListContainer{
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: flex-start;
  margin: 8px 0 0 0;
  width: 100%;
  border-radius: 17px;
  background-color: #FFFFFF;
  box-shadow: 1px 2px 10px #00000032;
  padding: 12px 10px;
  border: 0px;
  min-height:65px;

  .fantasyName{
    color:var(--color-primary);
    font-weight:bold;
    font-size:13.5px;
    text-overflow:ellipsis;
    justify-content:space-between;
  }

  .estabelecimento{
    display:flex;
    flex:1;
    justify-content:space-between;
    min-width: 180px;
    padding: 0 5px 10px 0;
    color: #444;
  }

  .contato{
    display:flex;
    flex:1;
    justify-content:space-between;
    color: #444;
    margin-left: 5px;
    margin-right: 5px
  }

  .phone{
    font-weight:bold;
    color:#555;
  }

  .ramo{
    display:flex;
    flex:1;
    justify-content:space-between;
    color: #444;
    padding: 0 5px 10px 0;
    min-width: 180px;
  }

  .status{
    display:flex;
    flex:1;
    justify-content:space-between;
    color: #444;
    padding: 0 5px 10px 0;
    min-width: 180px;
  }

  .data{
    width: 10%;
    justify-content:flex-end;
    color: #555;
    min-width: 120px;
  }

  .suspect {
    width: 110px;
    background-color: #FE5454;
    border-radius:6px;
    align-items: center;
    justify-content:center;
    margin-bottom: 10px;
    padding: 2px 0;
  }

  .acceptable { 
    width: 110px;
    background-color: #44DF6F;
    border-radius:6px;
    align-items: center;
    justify-content:center;
    margin-bottom: 10px;
    padding: 2px 0;
  }

  .datatext{
   font-weight:bold;
   color:#555;
  }

  .smallLetter{
    font-size:11px;
    color: #555;
  }

  .smallLetterDistance{

    font-size: 11px;
    font-weight:bold;
    color: #888;
  }

  .smallLetterSuspect{
    font-size: 12px;
    font-weight: bold;
    color: #FFF;
  }

  .smallLetterAcceptable{
    font-size: 12px;
    font-weight: bold;
    color: #FFF;
  }

  .smallLetterBold{
    font-size:12px;
    font-weight:bold;
    color:#555;
  }

  .saleSmallLetterBold{
    font-size: 11px;
    font-weight:bold;
    color:#555;
  }

  .customerPhase {
    margin: 0 10px 10px 0;
  }

  .phaseStatus{
    background-color:var(--color-primary);
    align-items:center;
    border-radius:20px;
    font-size:10px;
    justify-content:center;
    color:#FFFFFF;
    width:70px;
    padding:1px;
  }

  .squarePhaseStatus {
    background-color:var(--color-primary);
    align-items:center;
    justify-content:center;
    border-radius:10px;
    font-size:8px;
    font-weight:500;
    color:#FFFFFF;
    width:35px;
    height: 35px;
    padding:1px;
  }

  .Visita {
    background-color:#F58D27;

  }

  .Venda {
    background-color:#0098EF;
  }

  .Cadastro {
    background-color:#42AB58;
  }
    
  .Contato {
    background-color:#D72D2E;
  }


.salePhase1{
    background-color:var(--color-primary);
    align-items:center;
    border-radius:20px;
    font-size:10px;
    justify-content:center;
    color:#FFFFFF;
    width:auto;
    padding: 3px 10px;
    margin-right:10px;
    align-items:center;
    text-align:center;

  }

.salePhase2{
    background-color:var(--color-primary);
    align-items:center;
    border-radius:20px;
    text-align:center;
    font-size:10px;
    justify-content:center;
    color:#FFFFFF;
    width: auto;
    padding:3px 10px;
    margin-right:10px
  }

  .sellStatusContainer{
    flex-direction:row;
  }

  .Aprovação{
    background-color:#cbf2f0;
    color:#222;
  }

  .Aguardando{
    background-color:#EE5928;
  }

  .Cancelado{
    background-color:#222;
  }
 
  .Pendente{
    background-color:#EE5928;
    color:white;
  }

  .Acompanhamento{
    background-color:#cbf2f0;
    color:#222;
  }

  .Ativo{
    background-color:#82e4ff;
    color: #222;
  }
 
 
 
}

`;
export default Styles