
import styled from "styled-components";

export const Container = styled.div`
  padding:5px 15px;
  align-items: center;
  justify-content: center;
  color:#fff;
  border-radius: 10px;
  margin-right:10px ;
  margin-top:5px; 
  background-color: ${props => props.type ? "#6c04ac" :  "#ee5928"};
  cursor:grab ;
  transform: translate(0, 0);
  *{cursor: grab;}
`;
