import React from 'react';
import styled from 'styled-components';
import iconUser from '../../assets/icons/iconUser.png'
const UserButtonComponent = ({ fullname, cargo, icon, style, background, shadow,onClick }) => {

    return (
        <UserButton 
        background={background}
        shadow={shadow}
        style={style}
        onClick={onClick??(()=>{})}
        >

            <div>
                <div className="picture">
                    <img alt='user icon' src={icon ?? iconUser} />
                </div>
            </div>
            <div className="textContainers">
                <div className="textContainer">
                    <span className="name">{fullname ?? 'Sem Nome'}</span>
                    <span className="cargo">{cargo ?? 'Sem cargo'}</span>
                </div>
            </div>
        </UserButton>
    )


}
export default UserButtonComponent;

const UserButton = styled.button`
    display: flex;
    width:auto;
    flex-direction: row;
    background-color: transparent;
    margin-bottom:10px;
    border-radius: 5px;
    box-shadow: ${({ shadow }) => shadow ? '2px 2px 7px #00000044' : 'none'};
    max-width: 200px;
    max-height:50px;
    min-width: 200px;


    div.textContainers{
        width: 80%;

    }
    div{
    
    div.picture{
      box-shadow: 2px 2px 7px #00000044;
      height: 45px;
      width: 45px;
      border-radius: 25px;
      overflow: hidden;
    }
    
    div.textContainer{
      margin-right: 5px;
      justify-content:center;
      width: 80%;
      span{
          width: 100%;
        display: flex;
        flex:1;
        margin-bottom: 5px;
        margin-left:15px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
      }
      span.name{
        font-weight: bold;
      }
      span.cargo{
          font-size: 10px;
          color:#777;
      }
    }
  }
  :not(first-child){
    margin-right: 20px;
  }



`