import styled from 'styled-components';
import { Card, Input, Label } from '../../components'

export const Container = styled.div`
  margin-top: 10px;
  flex: 1;
  width: 100%;
  height: auto;
  font-family: Roboto;
`;

export const HeaderCard = styled(Card) `
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5px 25px 5px 25px;
`;

export const HeaderTitle = styled.h1 `
  font-size: 22px;
  color: #EE5928;
`;

export const ButtonContainer = styled.div`
  
`

export const ButtonDiv = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const Button = styled.button `
  border-radius: 16px;
  color: var(--color-text-light);
  background-color: var(--color-background-primary);
  height: 32px;
  padding: 5px;

  &:disabled{
    background-color: gray;
    color: white;
    border: 3px solid gray;
    cursor: no-drop;
  }
`;

export const MainCard = styled(Card) `
  margin-top: 10px;
  padding: 15px;
  width: 100%;
`

export const GridTitleContainer = styled.div `
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
  box-shadow: 1px 2px 13px #00000032;
  padding: 15px; 
  border-radius: 15px; 

`;

export const GridTitle = styled.span `
  font-size: 18px; 
  flex: 1;
  font-weight: 700;
  color: #333;  
`;

export const GridCard = styled.div`
  flex: 1;
  font-size: 16px;
  margin-right: 5px;
  line-height: 24px;
`;

export const ButtonActions = styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;

  > svg {
    cursor: pointer;
  }

  > svg:hover {
    opacity: 0.6;
  }

  > svg:first-child {
    margin-right: 6px;
    color: #EE5928
  }

  > svg:nth-child(2) {
    color: #aaaa;
  }  
 
`

export const ModalFormContainer = styled.div`
  font-family: Roboto;
  width: 100%;
`

export const MyLabel = styled.label`
 font-size: 16px;
 margin-bottom: 3px;
 color: gray;
 font-weight: 500;
`

export const MyInput = styled.input`
  font-family: Roboto;
  border: 1.5px solid #888888;
  border-radius: 20px;
  font-weight: 500;
  font-size: 14px;
  text-overflow: ellipsis; 
  padding: 5px;
  margin-bottom: 15px;

  ::placeholder {
    color: #808080;  
  }   
`

export const MySelect = styled.select`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color:#888888;
  border: 1.5px solid #888888;
  border-radius: 20px;
  padding: 5px;
  margin-bottom: 15px; 
  text-transform: none;

  > option {
    color: #808080;  
    text-transform: none;
  }
`