import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
// import {useHistory, useParams} from "react-router-dom";
import './styles.css';

import api from '../../services/api';
import iconProjects from '../../assets/icons/icon_projetos.svg';
import { FormatNumber } from '../../utils/FormatData';

function ProjectRegister() {
  // let {idProject} = useParams();

  const history = useHistory();
  const [project, setProject] = useState({
    name: '',
    color: "green",
    description: '',
    daily_goal_visits: 0,
    daily_goal_headcount: 0,
    daily_goal_sales: 0,
    daily_goal_productivity: 0
  })

  const [invalidInput, setInvalidInput] = useState([]);
  // const [invalidMessage, setInvalidMessage] = useState('');

  useEffect(() => {

  }, []);

  function handleSubmit(event) {
    event.preventDefault();
    let listInvalidInputs = [];

    if (!project.name || project.name.length < 3) {
      listInvalidInputs.push('name')
    }
    if (!project.description) {
      listInvalidInputs.push('description')
    }

    if (listInvalidInputs.length < 1) {
      api.post('/projects', project)
        .then(response => {
          if (response?.data) {
            history.push('/Projects')
          }
        }).catch(err => {
          console.log(err);
        })
    }
  }

  function removeInvalidInput(input) {
    if (invalidInput.includes(input)) {
      setInvalidInput(invalidInput.filter(item => item !== input));
    }
  }

  return (
    <div className='projectList'>
      <div className='newProjectTitleContainer'>
        <div className='newProjectTitle'>
          <label>Novo projeto</label>
          <img src={iconProjects} alt="projectIcon" />
        </div>
      </div>
      <form id='projectFormContainer' onSubmit={(event) => handleSubmit(event)}>
        <div id='projectFormCenter'>
          <input
            placeholder='Nome do projeto'
            className={`inputRoundedProject  ${invalidInput.includes('name') ? 'invalidInput' : ''}`}
            onChange={(event) => {
              setProject({ ...project, name: event.target.value })
              removeInvalidInput('name');
            }}
            value={project.name} />
          <input
            placeholder='Descrição do projeto'
            className={`inputRoundedProject  ${invalidInput.includes('description') ? 'invalidInput' : ''}`}
            onChange={(event) => {
              setProject({ ...project, description: event.target.value })
              removeInvalidInput('description');
            }}
            value={project.description} />

          <input
            placeholder='Código template de e-mail'
            className={`inputRoundedProject  ${invalidInput.includes('id_template_email') ? 'invalidInput' : ''}`}
            onChange={(event) => {
              setProject({ ...project, id_template_email: event.target.value })
              removeInvalidInput('id_template_email');
            }}
            value={project.id_template_email} />
          <label className='descriptionProject'>
            Caso o template de email esteja vazio será usado um template padrão.
          </label>

          <div className='projectsRegisterGoal'>
            <label>Meta de contratações</label>
            <input
              className={`inputRoundedProject  ${invalidInput.includes('headcount') ? 'invalidInput' : ''}`}
              onChange={(event) => {
                setProject({ ...project, daily_goal_headcount: FormatNumber(event.target.value) })
                removeInvalidInput('headcount');
              }}
              value={project.daily_goal_headcount}
            />
          </div>
          <div className='projectsRegisterGoal'>
            <label>Meta de visitas</label>
            <input
              placeholder='Nome do projeto'
              className={`inputRoundedProject  ${invalidInput.includes('visits') ? 'invalidInput' : ''}`}
              onChange={(event) => {
                setProject({ ...project, daily_goal_visits: FormatNumber(event.target.value) })
                removeInvalidInput('visits');
              }}
              value={project.daily_goal_visits}
            />
          </div>
          <div className='projectsRegisterGoal'>
            <label>Meta de vendas</label>
            <input
              placeholder='Nome do projeto'
              className={`inputRoundedProject  ${invalidInput.includes('sales') ? 'invalidInput' : ''}`}
              onChange={(event) => {
                console.log(project)
                setProject({ ...project, daily_goal_sales: FormatNumber(event.target.value) })
                removeInvalidInput('sales');
              }}
              value={project.daily_goal_sales}
            />
          </div>
          <div className='fullColumn' type="submit" value="Submit">
            <button id='projectFormSubmit'>
              Salvar
            </button>
          </div>



        </div>
      </form>
    </div>
  )
}

export default ProjectRegister;
