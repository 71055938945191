import React from 'react'
import { MaskImage } from '../../../components'
import AttachFileIcon from '@material-ui/icons/AttachFile';


import * as S from './CustomInfo.styles'

const CustomInfo = (props) => {

  const { customFieldValues, handleChangeCustom, isEditingCustom, openCarouselModal } = props
  
  const testRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/

  const checkValue = (e) => testRegex.test(e) ? `${process.env.REACT_APP_API}/images/` + e : e
 
  const openLink = value => {
    const url = checkValue(value)

    console.log("URL", url)
    
    window.open(url, '_blank')
  }

  const CustomFieldSelect = (field) => {
    const options = props?.customFieldProject?.find(f => f.label === field.label)?.options ?? [] 

    return (
        <>
          <S.MyLabel htmlFor={field.label} key={field.value}>
            {field.required === 'true' ?
              <>
                {field.label}
                <span style={{ color: 'red', fontWeight: 'bold' }}>
                  *
                </span>
              </>
              : field.label
            }
          </S.MyLabel>

          {

            props?.readOnly ?
              <S.MyInput 
                readOnly={true} 
                value={field?.value ? field?.value : '---'}
              />
            :
            <S.MySelect
              style={{ width: '100%' }}
              onChange={(event) => handleChangeCustom(event.target.value, field.label)}            
              id={field.label}
              value={field.value}  
              disabled={props.readOnly}
            >
              <option style={{ textTransform: 'none' }}>Selecione a opção</option>
              {
                options.map((op, index) => {
                  return (
                    <option value={op} key={index}>{op}</option>
                  )
                })
              }
            </S.MySelect>
          }
        </>
    ) 
  }

  return (
    <>
      {customFieldValues?.some(v => v.type !== '7' && v.type !== '8' && v.type !== '11') &&
        <>
          <h6 style={{ marginTop: 20, color: '#EE5928' }}>Informações Adicionais</h6>
          <div style={{ width: '100%', height: 'auto', display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', marginBottom: '10px'}}>
            {customFieldValues?.filter(v => v.type !== '7' && v.type !== '8' && v.type !== '11').map((field, index) => {
              return (
                <div key={index}>
                  {
                    field.type !== '5' && <>
                      <S.MyLabel for={field.label}>{field.label}</S.MyLabel>
                      {
                        field.value ?
                          <S.MyInput 
                            onChange={(event) => handleChangeCustom(event.target.value, field.label)} 
                            id="example" isEditing={isEditingCustom} 
                            readOnly={!isEditingCustom} 
                            value={field.value}
                          />
                        : <S.MyInput 
                            onChange={(event) => handleChangeCustom(event.target.value, field.label)} 
                            id="example" isEditing={isEditingCustom} 
                            readOnly={!isEditingCustom} 
                            value={isEditingCustom ? field.value : '---'}
                          />
                      }
                    </>
                  }
                  {
                    field.type === '5' && CustomFieldSelect(field)                     
                  }
                </div>
              )
            })}
          </div>
        </>
      }
      {customFieldValues?.some(v => v.type === '11' && v.value) &&
        <>
          <h6 style={{ marginTop: 20 }}>Rolo de fotos</h6>
          <div style={{ display: 'flex', justifyContent: 'start', width: '100%', flexDirection: 'row', margin: '5px' }}>
            <>
              {customFieldValues?.filter(v => v.type === '11').map((field, index) => {
                return (
                <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}} key={index}>
                    {field?.value && JSON.parse(field?.value).map((e, index, array) =>
                      <div style={{ marginLeft: '9px', marginRight: '5px', marginBottom: '5px' }}>
                      <MaskImage
                        onClick={() => openCarouselModal((array.map((val, index) => {
                          return {value: checkValue(val), label: index}
                        })),index)}
                        background={checkValue(e)} 
                      />
                    </div>
                    )}
                </div>
              )
              })}
            </>
          </div>
        </>
      }
      {customFieldValues?.some(v => v.type === '7' && v.value) &&
        <>
          <h6 style={{ marginTop: 20, marginBottom: 5 }}>Imagens</h6>
          <div style={{ display: 'flex', justifyContent: 'start', width: '100%', flexDirection: 'row'}}>
            {customFieldValues?.filter(v => v.type === '7').map((val, index, array) => {
              return (
                val.value && <MaskImage  
                  key={index} 
                  onClick={() => openCarouselModal(array, index)} title={val.label} 
                  background={checkValue(val.value)}
                  style={{ marginLeft: '9px', marginRight: '5px', marginBottom: '5px' }}
                />
              )
            })}
          </div>
        </>
      }
      {customFieldValues?.some(v => v.type === '8' && v.value) &&
        <>
          <h6 style={{ marginTop: 20 }}>Arquivos</h6>
          <div style={{ marginTop: '5px', marginLeft: '9px', height: 'auto', display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', marginBottom: '10px' }}>
            {customFieldValues?.filter(v => v.type === '8').map((field, index) => {
              return (
                field.value && <S.ButtonAction  key={index} type='button' title={field.label} onClick={() => openLink(field.value)}> <AttachFileIcon /></S.ButtonAction>
              )
            })}
          </div>
        </>
      }
      <br />
    </>
  )

}

export default CustomInfo