import React, { useState, useEffect, useCallback } from 'react';
import * as S from './styled';
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'
import StorageIcon from '@material-ui/icons/Storage';
import SaveIcon from '@material-ui/icons/Save';
import { Modal } from '../../../components/BasesModal'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckIcon from '@material-ui/icons/Check';
import BlockIcon from '@material-ui/icons/Block';
import PriorityHighOutlinedIcon from '@material-ui/icons/PriorityHighOutlined';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import VpnKeyOutlinedIcon from '@material-ui/icons/VpnKeyOutlined';
import api from "../../../services/api";
import swal from 'sweetalert';
import { Spinner } from '../../../components';
import { getDroppedOrSelectedFiles } from 'html5-file-selector'

export const ImportBilling = (props) => {
  const [panelActive, setPanelActive] = useState('firstPanel');

  const [model, setModel] = useState();
  const [chosenModel, setChosenModel] = useState();
  const [firstPanelConcluded, setFirstPanelConcluded] = useState(false);
  const [secondPanelConcluded, setSecondPanelConcluded] = useState(false);
  const [thirdPanelConcluded, setThirdPanelConcluded] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [importError, setImportError] = useState(false);
  const [manualImportedTable, setManualImportedTable] = useState([]);
  const [fileUploaded, setFileUploaded] = useState(false)
  const [importSpinner, setImportSpinner] = useState(false)
  const [referenceOptions, setReferenceOptions] = useState([])
  const [outputOptions, setOutputOptions] = useState([])
  const [sheetId, setSheetId] = useState('')
  const [referenceSelected, setReferenceSelected] = useState('')
  const [values, setValues] = useState([])
  const projectInfo = localStorage.getItem('ProjectInfo')

  const { id: id_project } = JSON.parse(projectInfo)

  const handleConfirmFirstPanel = () => {
    setPanelActive('secondPanel');
    setFirstPanelConcluded(true);
  }

  const handleConfirmSecondPanel = () => {
    setPanelActive('thirdPanel')
    setSecondPanelConcluded(true);
  }

  const handleConfirmThirdPanel = () => {
    //loop through manualImportedTable and check if all imported has outputName
    let errors = [];
    manualImportedTable.forEach(element => {
      if (element.import) {
        if (!element.outputName) {
          errors.push(`${element.sheetColumn} está sendo importado sem nome de saída.`)
        }
      }
      if (element.isReferenceKey) {
        if (!element.referenceColumn) {
          errors.push(`${element.sheetColumn} é uma referencia, porem não foi selecionada a coluna de referencia.`)
        }
      }
    });
    if (errors.length > 0) {
      swal("Não foi possível continuar:", errors.join('\n'), "error");
      return;
    }

    setPanelActive('fourthPanel')
    setThirdPanelConcluded(true);
  }

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  const setInitialTableState = (data) => {
    const formattedData = data.map((d) => {
      return {
        isReferenceKey: false,
        import: false,
        sheetColumn: d.name,
        values: d.values,
      }
    })

    const valuesList = []

    for (let i = 0; i < formattedData[0]?.values?.length; i++) {
      const row = []

      for (let j = 0; j < formattedData.length; j++) {
        row.push(formattedData[j].values[i])
      }

      valuesList.push(row)
    }


    setManualImportedTable(formattedData)
    setValues(valuesList)
  }

  const getUploadParams = async ({ file }) => {
    const formData = new FormData();

    formData.append('file', file)

    const token = localStorage.getItem('token') || sessionStorage.getItem('token');

    return {
      url: (process.env.REACT_APP_API ?? 'https://apidev.prismapro.com.br') + '/uploadSheets',
      headers: { authorization: `Bearer ${token}` },
      body: formData,
      method: 'POST'
    }

  }

  // todo render list of past sheets
  const getPastSheets = useCallback(async () => {
    const response = await api.get(`/relateSheets?id_project=${id_project}&type=TPV`)
    if (response.data.length > 0) {
      const importedColumnNames = [];

      response.data[0].properties.forEach(element => {
        if (element.import == true) {
          importedColumnNames.push(element.sheetColumn)
        }
      })

      const model = {
        columns: importedColumnNames,
        date: response.data[0].created_at,
      }

      setChosenModel(response.data[0])
      setModel(model)
    }
  }, [id_project])

  // set reference columns
  const getReferences = useCallback(async () => {
    const response = await api.get(`/referenceColumn?id_project=${id_project}&type=TPV`)
    setReferenceOptions(response.data)
    const outResponse = await api.get(`/baseOutputs?id_project=${id_project}&type=TPV`)
    setOutputOptions(outResponse?.data[0]?.names ?? '')

  }, [id_project])

  useEffect(() => {

    getReferences();
    getPastSheets()
  }, [getReferences, getPastSheets])


  const setNotImport = (index) => {
    const copyData = [...manualImportedTable]
    copyData[index].import = false
    setManualImportedTable(copyData)
  }

  const setImport = (index) => {
    const copyData = [...manualImportedTable]
    copyData[index].import = true
    setManualImportedTable(copyData)
  }

  const setReferenceKey = (index) => {
    const copyData = [...manualImportedTable]
    if (!copyData[index].isReferenceKey == true) {
      copyData.forEach(element => {
        element.isReferenceKey = false
        delete copyData[index]['referenceColumn']
      })
    }
    copyData[index].isReferenceKey = !copyData[index].isReferenceKey
    setManualImportedTable(copyData)
  }

  const setOutput = (outputName, index) => {
    const copyData = [...manualImportedTable]
    copyData[index]['outputName'] = outputName
    setManualImportedTable(copyData)
  }

  const setReference = (referenceValue, index) => {
    const copyData = [...manualImportedTable]

    copyData[index]['referenceColumn'] = JSON.parse(referenceValue)
    setReferenceSelected(referenceValue)
    setManualImportedTable(copyData)
  }

  const saveSheet = async (data) => {
    try {
      await api.post(`/relateSheets`, { ...data })
      setOpenModal(true)
    } catch (e) {
      setImportError(true)
      setOpenModal(true)
    }
  }

  const buildParams = (save) => {
    setImportSpinner(true)
    const paramsObject = {
      idBaseSheet: sheetId,
      type: 'tpv',
      id_project,
      properties: manualImportedTable.filter(d => (d.isReferenceKey || d.import))
    }
    if (save) {
      paramsObject.save = true
    } else {
      paramsObject.save = false
    }

    return saveSheet(paramsObject)
  }

  const handleChangeStatus = (data, status) => {
    if (status === 'done') {
      if (data?.xhr?.response === "") {
        swal({
          title: 'Não foi possível importar planilha.',
          text: 'Verifique a quantidade de linhas ou se tem alguma formatação que possa atrapalhar a leitura das linhas!',
          icon: "error",
          button: "Fechar"
        });

        setTimeout(() => {
          window.location.reload()          
        }, 2500);
      }

      const response = JSON.parse(data.xhr.response);

      const { previewData, key } = response

      setSheetId(key)

      setInitialTableState(previewData)

      setFileUploaded(true)
    }
  }

  const getFilesFromEvent = e => {
    return new Promise(resolve => {
      getDroppedOrSelectedFiles(e).then(chosenFiles => {
        resolve(chosenFiles.map(f => f.fileObject))
      })
    })
  }
  
  return (
    <S.Container>
      <Modal
        active={openModal}
        handleClickCloseModal={handleCloseModal}
        titleContent="Importação"
      >
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%', fontSize: '12px', fontWeight: '500' }}>

          <div style={{ marginBottom: '20px', display: 'flex', alignItems: 'center' }}>
            {!importError ? (
              <CheckCircleOutlineIcon style={{ color: 'green', fontSize: '5.5em' }} />

            ) : (
              <ErrorOutlineIcon style={{ color: 'red', fontSize: '5.5em' }} />
            )}
          </div>
          {importError ? (

            <>
              <span style={{ marginBottom: '20px' }}>Ocorreu um erro durante a importação.</span>
              <span style={{ marginBottom: '50px' }}>Se estiver usando um modelo, verifique se a planilha inserida contém as colunas corretas.
              </span>
            </>
          ) : (
            <>
              <span style={{ marginBottom: '10px' }}>Importação realizada com sucesso.</span><span style={{ marginBottom: '10px' }}>Os dados consultados já podem ser consultados na página de detalhes do cliente
                e na seção de relatórios.
              </span>
              <span style={{ marginBottom: '50px' }}>
                O modelo salvo poderá ser utilizado na proxima importação.
              </span>
            </>
          )}
          <div>
            <S.Button style={{ width: '25%', alignSelf: 'center', justifyContent: 'center' }} onClick={() => {window.location.reload();setOpenModal(false)}}>
              <span>OK</span>
            </S.Button>
          </div>
        </div>
      </Modal>
      <S.Tabs>
        <S.ExternalButtonDiv>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <S.StyledCheckIcon panelConcluded={firstPanelConcluded} />
            <S.HeaderSpan>Adicionar Base</S.HeaderSpan>
          </div>
          {panelActive === 'firstPanel' && <S.Button disabled={!fileUploaded} onClick={() => handleConfirmFirstPanel()}> &gt; Continuar </S.Button>}
        </S.ExternalButtonDiv>
        {panelActive === 'firstPanel' && (
          <S.InsideContainer>
            <Dropzone
              getUploadParams={getUploadParams}
              maxFiles={1}
              multiple={false}
              canCancel={false}
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              inputContent="Arraste ou clique aqui para selecionar o arquivo"
              onChangeStatus={handleChangeStatus}
              LayoutComponent={({ dropzoneProps, files, input, extra: { maxFiles }, submitButton, previews }) =>
                <S.DropzoneContainer {...dropzoneProps} >
                  {previews}
                  <S.DropzoneContent>
                    {files.length < maxFiles && input}
                  </S.DropzoneContent>
                  {files.length > 0 && submitButton}
                </S.DropzoneContainer>
              }
              PreviewComponent={({ meta }) => {
                const { name, status } = meta
                return (
                  <span style={{ textAlign: 'center', alignSelf: 'flex-start', margin: '10px 3%', fontFamily: 'Roboto', display: 'flex', flexDirection: 'row' }}>
                    {name}  {status != "done" ? <Spinner style={{ marginLeft: 5 }} small={true} /> : <CheckIcon style={{ fill: "green", fontSize: 19, marginLeft: 5 }} />}
                  </span>
                )
              }}
              InputComponent={({ accept, onFiles, files }) => {
                return (
                  <div style={{ alignItems: 'center', padding: 10, justifyContent: 'center' }}>
                    <label style={{ fontSize: 20, marginBottom: 20, textAlign: 'center' }}>Arraste, cole ou clique aqui para selecionar o arquivo.</label>
                    <label style={{ textAlign: 'center' }}>Importar dados de um arquivo XLSX. A importação está limitada a 40 MB.
                      Para listas maiores, divida seu arquivo em arquivos menores e envie separadamente.</label>
                    <input
                      style={{ top: 0, left: 0, width: '100%', height: '100%', position: 'absolute', opacity: 0, cursor: 'pointer' }}
                      type="file"
                      accept={accept}
                      multiple
                      onChange={e => {
                        getFilesFromEvent(e).then(chosenFiles => {
                          onFiles(chosenFiles)
                        })
                      }}
                    />
                  </div>
                )
              }}
            />


          </S.InsideContainer>
        )}
      </S.Tabs>

      <S.Tabs>
        <S.ExternalButtonDiv>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <S.StyledCheckIcon panelConcluded={secondPanelConcluded} />
            <S.HeaderSpan>Modelo de importação</S.HeaderSpan>
          </div>

        </S.ExternalButtonDiv>
        {panelActive === 'secondPanel' && (
          <S.InsideContainer>
            {model &&
              <>
                <span>Deseja usar o modelo existente para corresponder as colunas durante a importação?</span>
                <S.SecondTabDiv>
                  <span style={{ width: 30 }}>Nome</span>
                  <span style={{ width: 250 }}>Colunas</span>
                  <span style={{ width: 30 }}>Data</span>
                </S.SecondTabDiv>
                <S.SecondTabDiv>
                  <span style={{ width: 30 }}>Model</span>
                  <span style={{ width: 250 }}>{model.columns.join(", ")}</span>
                  <span style={{ width: 30 }}>{(new Date(model.date)).toLocaleString()}</span>
                </S.SecondTabDiv>
              </>
            }
            <S.SecondTabButtonDiv>
              <S.Button 
                disabled={chosenModel ? false : true}
                onClick={() => {       
                const ref = chosenModel?.properties?.filter(e => e.isReferenceKey === true)[0]
                setReferenceSelected(JSON.stringify({ key: ref.referenceColumn.key, alias: ref.referenceColumn.alias, category: "Venda", type: "TPV" }))
                const _imported = [...manualImportedTable]
                chosenModel.properties.forEach(e => {
                  const column = manualImportedTable.find(f => f.sheetColumn === e.sheetColumn)
                  column.import = e.import
                  column.isReferenceKey = e.isReferenceKey
                  if (e.isReferenceKey) {
                    column.referenceColumn = e.referenceColumn
                  }
                  column.outputName = e.outputName

                })
                setManualImportedTable(_imported)
                handleConfirmSecondPanel()
              }}>
                <StorageIcon />
                Usar Modelo
              </S.Button>
              <S.Button onClick={() => {
                handleConfirmSecondPanel()
              }}>
                <SaveIcon />
                Importar Manualmente
              </S.Button>
            </S.SecondTabButtonDiv>
          </S.InsideContainer>
        )}
      </S.Tabs>

      <S.Tabs>
        <S.ExternalButtonDiv>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <S.StyledCheckIcon panelConcluded={thirdPanelConcluded} />
            <S.HeaderSpan>Selecionar Colunas</S.HeaderSpan>
          </div>
          {panelActive === 'thirdPanel' && <S.Button onClick={() => handleConfirmThirdPanel()}> &gt; Salvar </S.Button>}
        </S.ExternalButtonDiv>
        {panelActive === 'thirdPanel' && (
          <S.InsideContainer>
            <S.ThirdTabTable>
              <S.TableRow topRound bottomRound marginBottom={"10px"}>
                <div style={{ textAlign: 'center' }}><span style={{ alignSelf: 'center', width: '100%' }}>Propriedade</span></div>
                {manualImportedTable?.map((data, index) => {
                  return (

                    <div key={index}>

                      <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                        {data.import ? (
                          <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} >
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                              <CheckCircleOutlineOutlinedIcon style={{ color: '#91DF6D', fontSize: '1.1em' }} />
                              <span style={{ color: '#91DF6D' }}>
                                Importar
                              </span>

                            </div>
                            <BlockIcon onClick={() => setNotImport(index)} style={{ color: '#fe3d3d', fontSize: '1.1em' }} />
                          </div>
                        ) : (
                          <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} >
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                              <PriorityHighOutlinedIcon style={{ color: '#EFB256', fontSize: '1.1em' }} />
                              <span style={{ color: '#EFB256' }}>Não importar</span>
                            </div>
                            <AddCircleOutlineOutlinedIcon onClick={() => setImport(index)} style={{ color: 'grey', fontSize: '1.1em' }} />

                          </div>
                        )}
                      </div>
                      {data.import && (
                        <S.MySelect value={manualImportedTable[index]['outputName']} onChange={(e) => setOutput(e.target.value, index)}>
                          <option value={null} key={"0"}>Selecione...</option>
                          { Array.isArray(outputOptions) && outputOptions?.map((r, i) => {
                            return (
                              <option value={r} key={i}>{r}</option>
                            )
                          })}
                        </S.MySelect>
                      )}
                      <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center' }}>
                        <span style={{ textAlign: 'left', fontWeight: 'bold', marginTop: 10, marginLeft: 7 }}>Propriedade</span>
                        <VpnKeyOutlinedIcon onClick={() => setReferenceKey(index)} style={{ color: `${data.isReferenceKey ? '#EE5B2B' : 'grey'}`, fontSize: '0.9em' }} />
                      </div>
                      {data.isReferenceKey && (
                        <S.MySelect value={referenceSelected} onChange={(e) => { setReference(e.target.value, index) }}>
                          <option value={null} key={"0"}>Selecione...</option>
                          {referenceOptions?.map((r, i) => {
                            return (
                              <option value={JSON.stringify(r)} key={i}>{r.alias}</option>
                            )
                          })}
                        </S.MySelect>
                      )}
                    </div>

                  )
                })}
              </S.TableRow>
              <S.TableRow topRound bottomRound marginBottom={"10px"}>
                <div style={{ textAlign: 'center' }}><span style={{ alignSelf: 'center', width: '100%' }}>Cabeçalho</span></div>
                {manualImportedTable.map((data, index) => {
                  return (
                    <div key={index}>
                      <span>{data.sheetColumn}</span>
                    </div>
                  )
                })}
              </S.TableRow>
              {values.map((values, id) =>
                <S.TableRow topRound={id == 0} bottomRound={id == 4} key={id}>
                  <div style={{ textAlign: 'center' }}>
                    <span style={{ alignSelf: 'center', width: '100%' }}>Amostra</span></div>
                  {values.map((value) => {
                    return (
                      <div key={value}>
                        <span>{value}</span>
                      </div>
                    )
                  })}
                </S.TableRow>
              )}
            </S.ThirdTabTable>
          </S.InsideContainer>
        )}
      </S.Tabs>

      <S.Tabs>
        <S.ExternalButtonDiv>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <S.StyledCheckIcon panelConcluded={false} />
            <S.HeaderSpan>Confirmar Importação</S.HeaderSpan>
          </div>
        </S.ExternalButtonDiv>
        {panelActive === 'fourthPanel' && (
          <S.InsideContainer>
            <span>Deseja salvar como modelo de importação</span>
            {importSpinner ? <Spinner /> : (
              <S.SecondTabButtonDiv>
                <S.Button onClick={() => buildParams()}>
                  <StorageIcon />
                  <span>Apenas Importar</span>
                </S.Button>
                <S.Button onClick={() => buildParams(true)}>
                  <SaveIcon />
                  <span>Salvar e Importar</span>
                </S.Button>
              </S.SecondTabButtonDiv>
            )}
          </S.InsideContainer>
        )}
      </S.Tabs>

    </S.Container>
  )

}

export default ImportBilling;