import React from 'react';

import './styles.css';

export default function ModalField(props){
  return(
    <>
      {props.showAlert? (
        <div id="modalFieldContainer">
          <div id="modalFieldCard">
            
            <label className="modalFieldTitle">{props.title}</label>
            <textarea 
              readOnly 
              cols={props.descCol?props.descCol:"90"} 
              rows={props.descRow?props.descRow:"2"}
              className="modalFieldDesc" 
              defaultValue={props.desc} 
            />
            <div className="modalFieldButtonRow">
              
                <button
                  
                  className="modalFieldButton positive" 
                  onClick={ _ =>{props.delete()}}
                >
                  {"Excluir"}
                </button>
                <button
                  
                  className="modalFieldButton" 
                  onClick={_=>{props.cancel()}}
                >
                  {"Não excluir"}
                </button>
              
            </div>
          </div>
        </div>
      ):(
        <div/>
      )}
    </>
  )
}