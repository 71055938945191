import React, { useState, useContext, useEffect, createContext, useCallback } from 'react';

import TabBar, { TabBarContext, TabBarProvider, TopBarView } from "../../components/TabBar";
import { groupIcons, groupsIcon, promotersIcon, simplifiedControlIcon } from '../../assets/icons/topBarIcons'

import * as Styled from './styled';

import StoreContext from '../../context/Context';

import Groups from './Groups'
import Teams from './Teams'
import Promoters from './Promoters'

import { userType } from '../../utils/userType';
import api from '../../services/api';
import Header from './Header';
import Navigator from './Navigator';
import { useMemo } from 'react';
import { Spinner } from '../../components';
import { useLocation } from 'react-router';
import FilterPicker from './components/FilterPicker';
import SimplifiedControl from './SimplifiedControl';



export const DashboardContext = createContext({})

const useNavigator = (paths,setPaths,useFilter) => {


  const {clearAllFilters} = useFilter()
  const getPaths = useCallback(() => {
     return paths
  },[paths])

  const setHomeAction = useCallback((action) => {
    
    setPaths(e =>{
      const _paths = [...e]
      _paths[0].onClick = ()=> {clearAllFilters() ; action();setPaths((e=>([e[0]])))}
      return _paths
    })
  },[clearAllFilters, setPaths])
  const goHome = useCallback((name) => {
    
    clearAllFilters();
    if (name) {
         setPaths([{name:name.toUpperCase(),onClick:()=>{
          goHome(name.toUpperCase())
        }}])
    }else{
      setPaths([paths[0]])
    }

  },[clearAllFilters, paths, setPaths])
  const pushToNav = useCallback((...newPaths) => {
    setPaths([...paths,...newPaths])
  },[paths, setPaths])
  const goBack = useCallback((qnt) => {
    if (qnt) {
      setPaths((paths) => paths.slice(0, paths.length - qnt))
    }
    else{
      setPaths((paths) => paths.slice(0, paths.length - 1))

    }
  }
  ,[setPaths])

  return { goHome,pushToNav,goBack,getPaths,setHomeAction }
}

const useFilters = (setGroupsFilter, setTeamsFilter, setPromotersFilter, groupsFilter,groupsSort,setGroupsSort, teamsFilter,generalSort,setGeneralSort,generalFilter,setGeneralFilter, promotersFilter) => {

  const clearAllFilters = useCallback(() => {
    console.log("Limpou")
    setTeamsFilter({})
    setGroupsFilter({})
    setPromotersFilter({})
  },[setTeamsFilter, setGroupsFilter, setPromotersFilter])
  


  return { clearAllFilters,setTeamsFilter,promotersFilter,groupsFilter,groupsSort,setGroupsSort,teamsFilter,generalSort,setGeneralSort,generalFilter,setGeneralFilter,setPromotersFilter }

}
const DashboardV2 = (routes) => {
  
  const [render, setRender] = useState([])
  const [dashData, setDashData] = useState(null)
  const [groupData, setGroupData] = useState(null)
  const [teamData, setTeamData] = useState(null)
  const [promotersData, setPromotersData] = useState([])
  const [loading, setLoading] = useState(false)
  const [paths, setPaths] = useState([{name:"CONTROLE SIMPLIFICADO"}])
  const [teamsFilter, setTeamsFilter] = useState({})
  const [generalSort,setGeneralSort] = useState()
  const [generalFilter,setGeneralFilter] = useState()
  const [groupsSort,setGroupsSort] = useState()
  const [selectedOrderingKey,setSelectedOrderingKey] = useState('high_visit_day')
  const [groupsFilter, setGroupsFilter] = useState({})
  const [promotersFilter, setPromotersFilter] = useState({})
  const [project,setProject] = useState({})
  const { userInfoStore } = useContext(StoreContext);
  const [selectedTabId, setSelectedTabId] = useState('');

  const useFilter = () => useFilters(setGroupsFilter, setTeamsFilter, setPromotersFilter, groupsFilter,groupsSort,setGroupsSort, teamsFilter,generalSort,setGeneralSort,generalFilter,setGeneralFilter, promotersFilter)

  const visitOptions = useMemo(()=>[
    {
      label:'Mais visitas realizadas no dia',
      key:'high_visit_day',
      sort: (a,b)=> b.visits.day - a.visits.day
    },
    {
      label:'Mais visitas realizadas na semana',
      key:'high_visit_week',
      sort: (a,b)=> b.visits.week - a.visits.week
    },
    {
      label:'Mais visitas realizadas no mês',
      key:'high_visit_month',
      sort: (a,b)=> b.visits.month - a.visits.month
    },
    {
      label:'Menos visitas realizadas no dia',
      key:'low_visit_day',
      sort: (a,b)=> a.visits.day - b.visits.day
    },
    {
      label:'Menos visitas realizadas na semana',
      key:'low_visit_week',
      sort: (a,b)=> a.visits.week - b.visits.week
    },
    {
      label:'Menos visitas realizadas no mês',
      key:'low_visit_month',
      sort: (a,b)=> a.visits.month - b.visits.month
    },
    {
      label:'Zerados',
      key:'zero_visit',
      filter: (cache)=>  cache.visits.day === 0
    },
    ],[])
  const salesOptions = useMemo(()=>[
    {
      label:'Mais vendas realizadas no dia',
      key:'high_sale_day',
      sort: (a,b)=> b.sales.day.total - a.sales.day.total
    },
    {
      label:'Mais vendas realizadas na semana',
      key:'high_sale_week',
      sort: (a,b)=> b.sales.week.total - a.sales.week.total
    },
    {
      label:'Mais vendas realizadas no mês',
      key:'high_sale_month',
      sort: (a,b)=> b.sales.month.total - a.sales.month.total
    },
    {
      label:'Menos vendas realizadas no dia',
      key:'low_sale_day',
      sort: (a,b)=> a.sales.day.total - b.sales.day.total
    },
    {
      label:'Menos vendas realizadas na semana',
      key:'low_sale_week',
      sort: (a,b)=> a.sales.week.total - b.sales.week.total
    },
    {
      label:'Menos vendas realizadas no mês',
      key:'low_sale_month',
      sort: (a,b)=> a.sales.month.total - b.sales.month.total
    },
    {
      label:'Zerados',
      key:'zero_sale',
      filter: (cache)=> cache.sales.day.total === 0
    },
    ],[])

  const promotersFiltered = useMemo(() => {
    const _promotersFilter = {...promotersFilter}
    delete _promotersFilter.id_team
    const filtered = promotersData.filter((item) => {
      const filterKeys  = Object.keys(_promotersFilter)
      if(filterKeys.length === 0){
        return true
      }
      const filter = filterKeys.every((key, index) => {
        return item[key] === _promotersFilter[key]
      })
      return filter
    }
    )
    return filtered
  }, [promotersData, promotersFilter])

  const dashDataFiltered = useMemo(() => {
    const filtered = dashData?.filter((item) => {
      const filterKeys  = Object.keys(teamsFilter)
      if(filterKeys.length === 0){
        return true
      }
      const filter = filterKeys.every((key, index) => {
        return item[key] === teamsFilter[key]
      })
      return filter
    }
    )
    return {...dashData,groupAndTeam:filtered}
  }, [dashData,  teamsFilter])


  const useNav = () => useNavigator(paths,setPaths,useFilter)
  const { goHome } = useNav();

  const UserType = useCallback(() => {
    /* if (userInfoStore.id_users_type === userType.SUPERVISOR) {
      setRender([
        {
          id: "teams",
          name: "Times",
          img: groupIcons,
          iconSize: '20px',
          render: Teams
        },
        {
          id: "promoters",
          name: "Promotores",
          img: promotersIcon,
          iconSize: '20px',
          render: Promoters
        }
      ])
    } else {
      setRender([
        {
          id: "groups",
          name: "Grupos",
          img: groupsIcon,
          iconSize: '20px',
          render: Groups
        },
        {
          id: "teams",
          name: "Times",
          img: groupIcons,
          iconSize: '20px',
          render: Teams
        },
        {
          id: "promoters",
          name: "Promotores",
          img: promotersIcon,
          iconSize: '20px',
          render: Promoters
        }
      ])
    } */

    setRender([
      {
        id: "simplified_control",
        name: "Controle Simplificado",
        img: simplifiedControlIcon,
        iconSize: '20px',
        render: SimplifiedControl
      }
    ])
  }, [])

  useEffect(() => {
    UserType();
  }, [UserType])

  return (
    <DashboardContext.Provider value={{
      dashData:dashDataFiltered,teamData,setTeamData, loading,
      paths,groupData, useFilter, promotersData:promotersFiltered,
      setPromotersData,useNav,project,setProject,setGroupData
    }}>
      <Styled.Container>
        <Styled.Tab>
          <TabBarProvider>
            {loading? 
            <Spinner style={{alignSelf:'center',marginTop:100}} size="small"/>
            :
            <>
            <TabBar onSwitchTab={(elm) => {
              goHome(elm.name)
            }} renderItems={render} />
            {/* <Navigator /> */}
            <Styled.TabContent>

              {/* <Styled.Left> */}
                <TopBarView />
              {/* </Styled.Left> */}

              {/* <Styled.Right>
                <Styled.FilterTitle>
                  Analisar
                </Styled.FilterTitle>
                <FilterPicker
                  setSelected={setSelectedOrderingKey}
                  selected={selectedOrderingKey}
                  title="Visitas"
                  defaultValue='high_visit_day'
                  options={visitOptions}
                />
                <FilterPicker
                  setSelected={setSelectedOrderingKey}
                  selected={selectedOrderingKey}
                  style={{marginTop:20}}
                  title="Vendas"
                  options={salesOptions}
                />
              </Styled.Right> */}
            </Styled.TabContent>
            </>
             }
          </TabBarProvider>
        </Styled.Tab>
      </Styled.Container>
    </DashboardContext.Provider>
  )
}

export default DashboardV2;