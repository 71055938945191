import React, { useCallback, useMemo, useState, useContext, useEffect } from 'react'
import * as S from './styled'
import { toast } from 'react-toastify'
import Api from "../../services/api"
import StoreContext from "../../context/Context"
import EditableInput from '../../components/EditableInput'
import Delete from '@material-ui/icons/DeleteForever';
import Save from '@material-ui/icons/Save';
import Edit from '@material-ui/icons/Edit';
import swal from 'sweetalert';

const ApisContext = React.createContext();
export const ApiContextProvider = ({ children }) => {
    const [triggers, setTriggers] = useState([])

    return (
        <ApisContext.Provider value={{ triggers, setTriggers }}>
            {children}
        </ApisContext.Provider>
    );
};
const NewTriggerHeader = () => {
    const [newTrigger, setNewTrigger] = useState({ method: "POST" })

    const { userProjectStore } = useContext(StoreContext)
    const { setTriggers } = useContext(ApisContext)

    const saveNewTrigger = useCallback(async (edit) => {
        if (!newTrigger.name) {
            toast.error("Nome do trigger é obrigatório")
            return
        }
        if (!newTrigger.type) {

            toast.error("Tipo do trigger é obrigatório")
            return
        }
        if (!newTrigger.url) {
            toast.error("Url é obrigatório")
            return
        }
        if (!newTrigger.url.startsWith('http://') && !newTrigger.url.startsWith('https://')) {
            toast.error("Url inválida, certifique-se se foi inserido o protocolo")
            return
        }
        const expression = /[-a-zA-Z0-9@{}:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi;
        const regex = new RegExp(expression);
        if (!newTrigger.url.match(regex)) {

            toast.error("Url inválida")
            return
        }
        if (!newTrigger.method) {
            toast.error("Método é obrigatório")
            return
        }
        try {
            const trigger = await Api.post('/trigger', { ...newTrigger, idProject: userProjectStore.id })

            setTriggers(old => [...old, trigger.data])
            toast.success("Trigger criado com sucesso")
            setNewTrigger({})
        } catch {

            toast.error("Erro ao criar trigger")
        }
    }, [newTrigger, userProjectStore, setTriggers])
    const setStateValue = useCallback((key, value) => {
        setNewTrigger(old => ({ ...old, [key]: value.target.value }))
    }, [setNewTrigger])
    const getStateValue = useCallback((key) => {
        return newTrigger[key] ? newTrigger[key] : ''
    }, [newTrigger])
    return useMemo(() => {
        return <S.CreateApiConnectionHeader>
            <div className='container'>
                <div className='inputsContainer'>
                    <div className="topDivision">
                        <S.TitleText>Novo Trigger</S.TitleText>
                        <div className='row'>
                            <input value={getStateValue('name')} onChange={(t) => setStateValue('name', t)} placeholder='Nome do Trigger' className="inputRounded" />
                            <select value={getStateValue('type')} required style={{ minWidth: '50%' }} onChange={(t) => setStateValue('type', t)} className="inputRounded" >
                                <option value="" disabled="disabled" selected="selected">
                                    Tipo de Trigger
                                </option>
                                <option value="VISIT">
                                    Visita
                                </option>
                                <option value="REGISTER">
                                    Cadastro
                                </option>
                                <option value="SALE">
                                    Venda
                                </option>
                            </select>
                        </div>

                    </div>
                    <div className="bottomDivision">
                        <S.TitleText>Configurações da API</S.TitleText>
                        <div className='row'>
                            <input value={getStateValue('url')} onChange={(t) => setStateValue('url', t)} placeholder='URL' className="inputRounded" />
                            <select value={getStateValue('method')} required style={{ minWidth: '33.33%', marginRight: 10 }} onChange={(t) => setStateValue('method', t)} className="inputRounded" >
                                <option disabled="disabled" selected="selected">
                                    Método
                                </option>
                                <option>
                                    POST
                                </option>
                                <option >
                                    GET
                                </option>
                                <option>
                                    PUT
                                </option>
                                <option >
                                    DELETE
                                </option>

                            </select>
                            <input value={getStateValue('apiAuthHeaderToken')} onChange={(t) => setStateValue('apiAuthHeaderToken', t)} placeholder='Token' className="inputRounded" />
                        </div>
                    </div>
                </div>
                <div className='saveContainer'>
                    <button onClick={saveNewTrigger}>Salvar</button>
                </div>
            </div>
        </S.CreateApiConnectionHeader>

    }, [setStateValue, saveNewTrigger, getStateValue])
}
const TriggerList = () => {
    const { userProjectStore } = useContext(StoreContext)
    const { triggers, setTriggers } = useContext(ApisContext)
    const getTriggers = useCallback(async () => {

        const Triggers = await Api.get('/trigger/' + userProjectStore.id)

        setTriggers(Triggers.data)

    }, [setTriggers, userProjectStore])
    useEffect(() => {
        getTriggers()

    }, [getTriggers])

    return useMemo(() => {

        return <S.TriggerListContainer>
            <S.TitleText>Triggers</S.TitleText>
            {triggers.map((e, i) => <Trigger index={i} values={e} />)}
        </S.TriggerListContainer>

    }, [triggers])

}
const convertType = (type) => {
    const types = {
        "VISIT": "Visita",
        "REGISTER": "Cadastro",
        "SALE": "VENDA",
    }
    return types[type]
}

const Trigger = ({ values, index }) => {
    const [newField, setNewField] = useState({ sendMethod: 'BODY' })
    const [editingFiled, setEditingField] = useState('')
    const [editingFiledId, setEditingFieldId] = useState('')
    const [editTrigger, setEditTrigger] = useState(false)
    const [editTriggerValues, _setEditTriggerValues] = useState(false)
    const { userProjectStore } = useContext(StoreContext)

    const editingField = (field) => {

        setEditingFieldId(field.id);
        setEditingField(field)
    }
    const setNewFieldValue = useCallback((key, value) => {

        setNewField(old => ({ ...old, [key]: value.target.value }))

    }, [setNewField])
    const setEditTriggerValues = useCallback((key, value) => {

        _setEditTriggerValues(old => ({ ...old, [key]: value.target.value }))

    }, [_setEditTriggerValues])
    const setEditingFieldValue = useCallback((key, value) => {

        setEditingField(old => ({ ...old, [key]: value.target.value }))

    }, [setEditingField])

    const startEditTrigger = () => {
        const trigger = { ...values };
        delete trigger.fields
        _setEditTriggerValues(trigger)
        setEditTrigger(true)
    }
    const saveEditTrigger = async () => {

        if (!editTriggerValues.name) {
            toast.error("Nome do trigger é obrigatório")
            return
        }
        if (!editTriggerValues.type) {

            toast.error("Tipo do trigger é obrigatório")
            return
        }
        if (!editTriggerValues.url) {
            toast.error("Url é obrigatório")
            return
        }
        if (!editTriggerValues.url.startsWith('http://') && !editTriggerValues.url.startsWith('https://')) {
            toast.error("Url inválida, certifique-se se foi inserido o protocolo")
            return
        }
        const expression = /[-a-zA-Z0-9@{}:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi;
        const regex = new RegExp(expression);
        if (!editTriggerValues.url.match(regex)) {

            toast.error("Url inválida")
            return
        }
        if (!editTriggerValues.method) {
            toast.error("Método é obrigatório")
            return
        }
        try {
            const trigger = await Api.put('/trigger/' + editTriggerValues._id, { ...editTriggerValues, idProject: userProjectStore.id })

            const triggersClone = [...triggers]
            const index = triggersClone.findIndex(e => e._id === values._id)
            triggersClone[index] = { ...triggersClone[index], ...trigger.data }
            setTriggers(triggersClone)
            toast.success("Trigger editado com sucesso")
            setEditTrigger(false)
        } catch {
            setEditTrigger(false)

            toast.error("Erro ao editar trigger")
        }

    }
    const deleteTrigger = async () => {

        const willDelete = await swal({
            title: `Tem certeza que você deseja deletar o Trigger ${values.name}?`,
            icon: "info",
            buttons: ["Não", "Sim"],
            dangerMode: true,
        })

        if (willDelete) {

            await Api.delete(`/trigger/${values._id}`)
            const triggersClone = [...triggers]

            const index = triggersClone.findIndex(e => e._id === values._id)
            triggersClone.splice(index, 1)
            setTriggers(triggersClone)
        }
    }
    const deleteField = async (field) => {

        const willDelete = await swal({
            title: `Tem certeza que você deseja deletar o campo ${field.field}?`,
            icon: "info",
            buttons: ["Não", "Sim"],
            dangerMode: true,
        })

        if (willDelete) {

            const res = await Api.delete(`/trigger/${values._id}/field/${field.id}`, newField)
            const triggersClone = [...triggers]

            triggersClone[index].fields = res.data;
            setTriggers(triggersClone)

        }
    }
    const { triggers, setTriggers } = useContext(ApisContext)

    const saveEditField = async (edit, field) => {
        if (!editingFiled.field) {
            toast.error("Campo é obrigatório")
            return
        }
        const res = await Api.put(`/trigger/${values._id}/field/${editingFiled.id}`, editingFiled)
        const triggersClone = [...triggers]
        triggersClone[index].fields = res.data;
        toast.success("Campo editado com sucesso")
        setEditingFieldId(null)
        setTriggers(triggersClone)
    }
    const addTrigger = async (edit, field) => {

        if (!newField.field) {
            toast.error("Campo é obrigatório")
            return
        }

        const res = await Api.post(`/trigger/${values._id}/field`, newField)
        toast.success("Campo adicionado com sucesso")

        const triggersClone = [...triggers]
        triggersClone[index].fields = res.data;
        setTriggers(triggersClone)

    }
    return <S.Trigger>
        <div className='titleContainer'>
            <S.TitleTextBlack>
                {values.name}
            </S.TitleTextBlack>
            <S.SmallText style={{ marginLeft: 5, marginTop: 3 }}>
                {convertType(values.type)}
            </S.SmallText>
        </div>
        <div className='apiConfigs'>
            <div className="inputs">
                <EditableInput onChange={(value) => { setEditTriggerValues('url', value) }} editable={editTrigger} defaultValue={values.url} label="API URL" />
                <EditableInput onChange={(value) => { setEditTriggerValues('method', value) }} type={'select'} editable={editTrigger} defaultValue={values.method} label="API Método" >
                    <option>
                        POST
                    </option>
                    <option >
                        GET
                    </option>
                    <option>
                        PUT
                    </option>
                    <option >
                        DELETE
                    </option>
                </EditableInput>
                <EditableInput onChange={(value) => { setEditTriggerValues('apiAuthHeaderToken', value) }} editable={editTrigger} defaultValue={values.apiAuthHeaderToken ? values.apiAuthHeaderToken : '---'} label="API Token" />
            </div>
            <div className="buttons">
                {!editTrigger &&
                    <S.ButtonContainer style={{ marginRight: 5 }}>
                        <S.CircleButton onClick={() => deleteTrigger()}>

                            <Delete style={{ fontSize: 15 }} />

                        </S.CircleButton>
                    </S.ButtonContainer>
                }
                <S.ButtonContainer>
                    {editTrigger ?
                        <S.CircleButton onClick={() => saveEditTrigger()}>
                            <Save style={{ fontSize: 15 }} />
                        </S.CircleButton>
                        :
                        <S.CircleButton onClick={() => startEditTrigger()}>
                            <Edit style={{ fontSize: 15 }} />
                        </S.CircleButton>
                    }
                </S.ButtonContainer>
            </div>
        </div>
        <div className='newField'>

            <EditableInput onChange={(value) => { setNewFieldValue('field', value) }} placeholder='Selecione ou digite o nome do campo' editable={true} label="CAMPO" />
            <EditableInput onChange={(value) => { setNewFieldValue('rename', value) }} placeholder='Novo Nome' editable={true} label="RENOMEAR" />
            <EditableInput onChange={(value) => { setNewFieldValue('value', value) }} placeholder='Valor' editable={true} label="VALOR" />
            <EditableInput onChange={(value) => { setNewFieldValue('sendMethod', value) }} type='select' editable={true} label="MÉTODO DE ENVIO">
                <option>
                    BODY
                </option>
                <option>
                    QUERY
                </option>
                <option>
                    URL
                </option>
            </EditableInput>
            <EditableInput onChange={(value) => { setNewFieldValue('modifier', value) }} type='select' editable={true} label="MODIFICADOR">

            </EditableInput>

        </div>
        <div className='newFieldButton'>
            <div className='transparent' />

            <S.ButtonContainer>
                <S.SmallText style={{ marginRight: 5 }}>
                    Novo campo
                </S.SmallText>
                <S.CircleButton onClick={addTrigger}>
                    +
                </S.CircleButton>
            </S.ButtonContainer>

        </div>
        <div className='fields'>

            {values.fields.map(field => (

                <div key={field.id} className='fieldsContainer'>

                    <EditableInput onChange={(t) => setEditingFieldValue('field', t)} filled={field.id !== editingFiledId} placeholder='Selecione ou digite o nome do campo' defaultValue={field.field} editable={field.id === editingFiledId} label="CAMPO" />
                    <EditableInput onChange={(t) => setEditingFieldValue('rename', t)} filled={field.id !== editingFiledId} placeholder='Novo Nome' defaultValue={field.rename} editable={field.id === editingFiledId} label="RENOMEAR" />
                    <EditableInput onChange={(t) => setEditingFieldValue('value', t)} filled={field.id !== editingFiledId} placeholder='Padrão' defaultValue={field.value} editable={field.id === editingFiledId} label="VALOR" />
                    <EditableInput type="select" onChange={(t) => setEditingFieldValue('sendMethod', t)} filled={field.id !== editingFiledId} editable={field.id === editingFiledId} defaultValue={field.sendMethod} label="MÉTODO DE ENVIO" >
                        <option>
                            BODY
                        </option>
                        <option>
                            QUERY
                        </option>
                        <option>
                            URL
                        </option>
                    </EditableInput>
                    <EditableInput type="select" onChange={(t) => setEditingFieldValue('modifier', t)} filled={field.id !== editingFiledId} editable={field.id === editingFiledId} defaultValue={field.modifier} placeholder='Nenhum' label="MODIFICADOR">

                    </EditableInput>
                    <S.ButtonContainer style={{ marginRight: 5 }}>
                        <S.CircleButton onClick={() => deleteField(field)}>
                            <Delete style={{ fontSize: 15 }} />
                        </S.CircleButton>
                    </S.ButtonContainer>
                    {field.id === editingFiledId ?
                        <S.ButtonContainer>
                            <S.CircleButton onClick={() => {
                                saveEditField()
                            }}>
                                <Save style={{ fontSize: 15 }} />
                            </S.CircleButton>
                        </S.ButtonContainer>
                        :
                        <S.ButtonContainer>
                            <S.CircleButton onClick={() => {
                                editingField(field)
                            }}>
                                <Edit style={{ fontSize: 15 }} />
                            </S.CircleButton>
                        </S.ButtonContainer>
                    }
                </div>

            ))}

        </div>
    </S.Trigger >
}


export const Apis = () => {

    return <ApiContextProvider>
        <S.Container>
            <NewTriggerHeader />
            <TriggerList />
        </S.Container>
    </ApiContextProvider>
}
export default Apis