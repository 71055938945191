import React, {useState, useEffect} from 'react';
import {Link, useHistory} from "react-router-dom";
import './styles.css';

import api from '../../services/api';

import iconProducts from  '../../assets/icons/iconProdutos.png';
import iconTransfer from  '../../assets/icons/iconTransferencia.png';
import iconSearch from  '../../assets/icons/icon_search.svg';
import ModalAlert from '../../components/ModalAlert';
import { getField } from '../../components/Search';
import LoaderSpin from '../../components/LoaderSpin';

function ProductsListAll(props) {
  const [productName, setProductName] = useState('');
  const [productsList, setProductsList] = useState([]);
  const [products, setProducts] = useState([]);
  const [productStatusList, setProductStatusList] = useState([]);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useState({
    showAlert: false,
    title: 'Remover produto!',
    desc: `Você deseja mesmo remover esse produto da sua lista? 
    \n Isso irá tirar qualquer acesso a seus dados.`,
    onClose: (event)=>{
      event.preventDefault();
      setAlert({...alert, showAlert: false})
    },
    descRow: "5",
    buttons: [
      {name: 'OK', onClick:(event)=>{
        event.preventDefault();
        setAlert({...alert, showAlert: false})
      }},
      {name: 'Cancelar', onClick:(event)=>{
        event.preventDefault();
        setAlert({...alert, showAlert: false})
      }}
    ]
  });
  const history = useHistory();
  
  useEffect(() => {
    let isSubscribed = true;
    if(isSubscribed){
      setLoading(true);
      setMessage('');
    }
    if(props.userInfo?.id_users_type && props.userProject?.id){
      if(props.userInfo.id_users_type!==1){
        api.get('/products').then((response) => {
          if(response?.data && isSubscribed){
            // console.log(response?.data)
            const productData = response?.data.filter(product  => getField('category.id_project', product) === props.userProject.id);
            setProducts(productData);
            setProductsList(productData);
            setLoading(false);
            if(productData.length > 0){
              setMessage('');
            }else{
              setMessage('Nenhum produto encontrado');
            }

            api.get('/productstatus').then(response => {
              if(response?.data  && isSubscribed){
                // console.log(response?.data)
                setProductStatusList(response?.data);
              }
            }).catch(err => {
              console.log('productstatus ',err);
            })
          }else{
            if(isSubscribed){
              setLoading(false);
              setMessage('Nenhum produto encontrado');
            }
          }
        }).catch(err => {
          console.log(err)
          if(isSubscribed){
            setLoading(false);
            setMessage('Nenhum produto encontrado');
          }
        })
      }else{
        const params = {
          filter_type: "id_user",
	        conditional: props.userInfo.id
        };
        api.get('/products/filter', { params }).then((response) => {
          if(response?.data && isSubscribed){
            // console.log(response?.data)
            const productData = response?.data.filter(product  => getField('category.id_project', product) === props.userProject.id);
            setProducts(productData);
            setProductsList(productData);
            
            setLoading(false);
            if(productData.length > 0){
              setMessage('');
            }else{
              setMessage('Nenhum produto encontrado');
            }

            api.get('/productstatus').then(response => {
              if(response?.data  && isSubscribed){
                // console.log(response?.data)
                setProductStatusList(response?.data);
              }
            }).catch(err => {
              console.log('productstatus ',err);
            })
          }else{
            if(isSubscribed){
              setLoading(false);
              setMessage('Nenhum produto encontrado');
            }
          }
        }).catch(err => {
          console.log(err)
          if(isSubscribed){
            setLoading(false);
            setMessage('Nenhum produto encontrado');
          }
        })
      }
    }else{
      if(props.userInfo?.id && !props.userProject?.id){
        setLoading(false);
        setMessage('Usuário sem projeto selecionado! \nPor favor verifique a aba perfil.');
      }
    }

    return () => {isSubscribed = false}
  }, [history, props.userInfo, props.userProject])

  function filterProducts(item){
    console.log(item);
    if(item !== ''){
      setProductsList(
        products.filter(product => product.id_sku_status === parseInt(item))
      )
    }else{
      setProductsList(products)
    }
    // if(item){
    //   const params = JSON.parse(item);
    //   api.get('/products/filter', { params })
    //   .then(response => {
    //     if(response?.data){
    //       setProductName('');
    //       setProductsList(response?.data);
    //       setAllProductsList(response?.data);
    //     }
    //   }).catch(err => {
    //     console.log(err);
    //   })
    // }else{
    //   api.get('/products')
    //   .then(response => {
    //     if(response?.data){
    //       setProductsList(response?.data);
    //       setAllProductsList(response?.data);
    //     }
    //   }).catch(err => {
    //     console.log(err);
    //   })
    // }
  }
  
  function filterProductsByName(name){
    setProductsList(products.filter(item => item.name?.toLowerCase().includes(name.toLowerCase())))
  }

  function reload(){
    const params = {
      filter_type: "id_user",
      conditional: props.userInfo.id
    };
    api.get('/products/filter', { params }).then((response) => {
      if(response?.data){
        setProducts(response?.data);
        setProductsList(response?.data);
        api.get('/productstatus').then(response => {
          if(response?.data){
            setProductStatusList(response?.data);
          }
        }).catch(err => {
          console.log('productstatus ',err);
        })
      }
    }).catch(err => {
      console.log(err)
    })
  }

  function productAvailable(idUser, idProduct){
    console.log(idUser,' - ', idProduct)
    if(idUser && idProduct){
      api.put('/products/'+idProduct, { id_sku_status: 1, id_user:''})
      .then(response => {
        if(response.data){
          reload();
        }
      })
    }
    setAlert({...alert, showAlert: false})
  }

  function changeProduct(event, id){
    event.preventDefault();
    if( id && props.userInfo.id_users_type && props.userInfo.id_users_type!==1 ){
      history.push(`/ProductsRegister/${id}`)
    }else if( id && props.userInfo.id_users_type){
      // setProductSelected({idProduct:id, idUser:props.userInfo.id})
      setAlert(alert => {
        let a = {...alert};
        a.showAlert=true;
        a.buttons[0]={
          name: 'OK', onClick:(event)=>{productAvailable(props.userInfo.id, id)}
        }
        console.log(a)
        return a
      })
    }
  }

  return(
    <div className='productList'>
      {props.userInfo?.id_users_type!==1 && (
        <div className='newProductContainer'>
          <Link className='newProductButton' to='/ProductsRegister'>
            <p>Registrar produto e categoria</p>
            <img src={iconProducts} alt="productIcon" />
          </Link>
          <Link className='newProductButton' to='/ProductsTransfer'>
            <p>Transferência de posse</p>
            <img src={iconTransfer} alt="productIcon" />
          </Link>
        </div>
      )}
      <div className={`productDivList ${props.userInfo?.id_users_type===1?'productDivListFullscreen':''}`}>
        <div id='searchTitle'>
          <label>Produtos cadastrados</label>
        </div>
        <div className='searchContainer'>
          <div className='searchOptions'>
            <select className='inputRounded' onChange={(event) => {filterProducts(event.target.value)}}>
              <option value=''>Ver todos</option>
              {productStatusList.map(item => (
                <option key={item.id} value={item.id}>{item.name}</option>
              ))}
            </select>
          </div>
          <form className='searchInputProductsList'>
            <button onClick={(event)=>{
              event.preventDefault();
              filterProductsByName(productName);
            }}>
              <img src={iconSearch} alt="searchIcon"/>
            </button>
            <input 
              placeholder='Pesquisar produtos' 
              onChange={(event)=>{
                setProductName(event.target.value);
              }}
              value={productName}
            />
          </form>
        </div>
        <div className='productListContainer'>
          {productsList.map((item, index) => {
            return (
              <button key={index} className='productContainer' onClick={(event)=>{changeProduct(event, item.id)}}>
                  <div className={`productImgContainer ${item.id_sku_status === 1?'productAtive':''}`}>
                    <div className='productImg'>
                      <img src={item.file?.uri?item.file.uri:iconProducts} alt="productPhoto" />
                    </div>
                    <label>{item.status?.name}</label>
                  </div>
                  <div className='productInfoContainer'>
                    <div>
                      <label className='textColorful'>{item.name}</label>
                      <label>{item.category?.name}</label>
                    </div>
                    <div className='productInfoDesc'>
                      <p>{item.description}</p>                    
                    </div>
                    <div className='productInfoRow'>
                      <div className='productStatus'>
                        {item.user && <span>{item.user?.fullname
                          ? item.user?.fullname
                          : item.user?.username
                        }</span>}
                      </div>
                      <div  className='productDate'>
                        <label>10.06</label>
                        <label>19:23h</label>
                      </div>
                      
                    </div>
                  </div>
              </button>
            )}
          )}
          {message && (
            <div className='alertTextMessage'>
              <textarea readOnly defaultValue={message} />
            </div>
          )}
          <LoaderSpin enable={loading} />
        </div>
      </div>
      <ModalAlert 
        showAlert={alert.showAlert}
        title={alert.title}
        desc={alert.desc}
        descRow={alert.descRow}
        buttons={alert.buttons}
        onClose={alert.onClose}
      />
    </div>
  )
}

export default ProductsListAll;