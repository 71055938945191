import styled from 'styled-components';
import { Card } from '../../components/Styled-Components/Card'


export const Container = styled.div `
  flex: 1;
  width: 100%;
  height:auto;
  font-family: "Roboto";
  display:flex;
`;

export const HeaderDiv = styled.div `
  display:flex;
  flex-direction:row;
  margin-top:20px;
  margin-bottom:20px;
  margin-left:20px;
  width: 100%;
`

export const Tabs = styled(Card) `
  cursor:pointer;
  display:flex;
  flex-direction:row;
  margin-right:10px;
  border-radius:10px;
  width:100% ;
  max-width:300px ;
  height:100px;
  justify-content: center;
  align-content: center;
  align-items: center;
  background-color: ${props => props.active ? "#ee5928": 'white'};
  & > span {
    color: ${props => !props.active ? "#ee5928": 'white'};
  }
  & > svg {
    margin-right:10px;
    fill: ${props => !props.active ? "#ee5928": 'white'};
  }
`

export const MainCard = styled(Card) `
margin-top:10px;
padding:15px;
width:100%;
`;
