import React from 'react';

import { Search } from '@material-ui/icons';

import * as S from './search-clients.styles'
import Styles from './styles';

export default function SearchClients({ autoSearch, search, setSearch }) { 
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {

      if (search.length > 0) {
        autoSearch(search)
      }
    }
  }

  return (
    <Styles>
      <div className='spaceb' />
      <div className='searchInputContainerClients'>
        <S.DivSearch>
          <S.Input 
            onChange={elm => setSearch(elm.target.value)}            
            placeholder="Busca Rápida" 
            value={search}
            onKeyPress={handleKeyDown}
          />   

          <button
            style={{ background: 'none' }}
            className="searchButtonCustomerSaleList"
            disabled={search.length === 0 }
            onClick={() => autoSearch(search)}
          >
            <Search 
              className={search.length === 0 ? 'searchInputCustomerSaleListDisabled' : 'searchInputCustomerSaleList'}
            />
          </button>     
        </S.DivSearch> 
      </div>
    </Styles>
  )
}
