import React from 'react';
import * as S from '../forgot-password.styles'
import { Spinner } from '../../../components'

const RequestPasswordSection = (props) => {

  const { email, validEmail, setInputEmail, submit, loading} = props;

  return (
    <>
    <S.newPasswordDiv>
      <S.newPasswordDivTitle>Recuperação de senha</S.newPasswordDivTitle>
      <S.newPasswordSpan>Informe seu email para receber o link de <br /> acesso para a página de 
      recuperação da <br /> senha
      </S.newPasswordSpan>
    </S.newPasswordDiv>
    <form>
      <div className="divInputandButton">
        <S.Label for="password" opacity>Email</S.Label>
        <S.Input
          id="password"
          placeholder="Digite seu e-email"
          onChange={(event) => setInputEmail(event.target.value)}
        />
        {email && !validEmail && <S.PValidator>Insira um email válido.</S.PValidator>}
      </div>
      <div className="divInputandButton"> 
        <S.MyButton onClick={() => submit()} type='button' disabled={!validEmail || !email} centered={true}>
          {loading && <Spinner small/>}
          {!loading && <>Enviar</>} 
        </S.MyButton>
      </div>
    </form>
  </>
  )
}

export default RequestPasswordSection;