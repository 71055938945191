import CloseIcon from '@material-ui/icons/Close'
import { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { Carousel } from 'react-carousel-minimal';

import * as S from './ImageModal.styles'

export const ImageModal = (props) => {

  const { handleCloseModal, active, imageArray, imageIndex } = props

  const [adjustedImageArray, setAdjustedImageArray] = useState([])

  const testRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/

  const checkValue = (e) => testRegex.test(e) ? `${process.env.REACT_APP_API}/images/` + e : e  

  useEffect(() => {
    if(!isEmpty(imageArray) && imageIndex && imageIndex !== 0) {
      const imageToBeOpened = imageArray.splice(imageIndex, 1).pop()

      imageArray.unshift(imageToBeOpened)

      const copy = imageArray.map((val) => {
        return {
          image: checkValue(val.value)
        }
      })

      setAdjustedImageArray(copy)
    }
    else if(imageIndex === 0 && !isEmpty(imageArray)) {
      const copy = imageArray.map((val) => {
        return {
          image: checkValue(val.value)
        }
      })

      setAdjustedImageArray(copy)
    }

  }, [imageArray, setAdjustedImageArray, imageIndex])


  return (
    <>
      {active && !isEmpty(adjustedImageArray) && (
        <S.Root>
          <S.Modal>
            <S.Header>
                <S.DivHeader>
                  <button onClick={() => handleCloseModal()}>
                    <CloseIcon />
                  </button>
                </S.DivHeader>
            </S.Header>
            <Carousel
              data={adjustedImageArray}
              width="100%"
              height="auto"
              radius="10px"
              slideNumber={true}
              captionPosition="bottom"
              automatic={false}
              pauseIconColor="white"
              pauseIconSize="40px"
              slideBackgroundColor="darkgrey"
              slideImageFit="cover"
              thumbnails={false}
              thumbnailWidth="100px"
              style={{
                textAlign: "center",
                maxWidth: "850px",
                maxHeight: "510px",
                padding: "5px"
              }}
            />
          </S.Modal>
        </S.Root>
      )}
    </>
  )
}

export default ImageModal
