import styled from 'styled-components'

export const TableStyles = styled.div`
  display: block;
  padding: 1rem;
  max-width: 2000px;

  @media (max-width: 1900px) {
    max-width: 1660px;
  }

  @media (max-width: 1600px) {
    max-width: 1360px;
  }

  @media (max-width: 1366px) {
    max-width: 1126px;
  }

  @media (max-width: 1200px) {
    max-width: 960px;
  }

  @media (max-width: 992px) {
    max-width: 800px;
  }

  @media (max-width: 768px) {
    max-width: 700px;
  }

  .tableWrap {
    display: block;
    max-width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    border-bottom: 1px solid #CCCCCC;
  }

  table {
    width: 100%;
    border-spacing: 0px 5px;

    tr {
      td {
        border-top: 1px solid #CCCCCC; 
        border-bottom: 1px solid #CCCCCC;
      }

      td:nth-child(1) {
        border-top: 0px; 
        border-bottom: 0px;
      }

      td:nth-child(2) {
        border-left: 1px solid #CCCCCC;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }

      td:last-child {
        border-right: 1px solid #CCCCCC;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }

      th:nth-child(1), td:nth-child(1),
      th:nth-child(2), td:nth-child(2),
      th:nth-child(3), td:nth-child(3),
      th:nth-child(4), td:nth-child(4) {
        position: sticky;
        background-color: #fff;
      }

      th:nth-child(1), td:nth-child(1) {
        left: 0;
      }

      th:nth-child(2), td:nth-child(2) {
        left: 112px;
        min-width: 246px;
      }

      th:nth-child(3), td:nth-child(3) {
        left: 358px;
      }

      th:nth-child(4), td:nth-child(4) {
        left: 465px;
      }
    } 

    thead {
      tr {
        th {
          border-top: 1px solid #CCCCCC; 
          border-bottom: 1px solid #CCCCCC;

          :nth-child(1), :nth-child(2) {
            border-top: 0px; 
            border-bottom: 0px;
          }

          :nth-child(3) {
            border-left: 1px solid #CCCCCC;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
          }

          :last-child {
            border-right: 1px solid #CCCCCC;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
          }
        }

        th:nth-child(2) {
          div {
            display: flex;
            align-items: flex-start;
          }
        }
      }

      tr:nth-child(1) {
        display: none;
      }

      tr:nth-child(2) {
        th:nth-child(1), th:nth-child(2) {
          div {
            display: none;
          }
        }
      }      
    }

    tbody {
      tr {
        td:first-child {
          div {
            background-color: transparent;
          }
        }

        td:nth-child(2) {
          div {
            display: flex;
            align-items: flex-start;
          }
        }
      }

      tr:first-child {
        td:nth-child(2) {
          div {
            background-color: transparent;
          }
        }

        td:nth-child(3), td:nth-child(4) {
          display: none;
        } 
      }      
    }

    th,
    td {
      margin: 0;
      padding: 0.25rem;
      text-align: center;
      font-family: 'Roboto';
      font-size: 12px;
      font-weight: bold;
      color: #000;
      white-space: nowrap;

      div {
        display: flex;
        align-items: center;
        justify-content: center;

        border-radius: 5px;
        margin: 0;
        padding: 0.5rem;
        min-width: 55px;
        min-height: 40px;

        sup {
          width: 100%;
          text-align: left;
          font-size: 10px;
          font-weight: normal;
        }

        sub {
          width: 100%;
          text-align: right;
          font-size: 10px;
          font-weight: normal;
        }

        div {}

        h2 {
          font-size: 10px;
          font-weight: normal;
          color: #444444;
        }
      }
    }
  }
`

function defineStyles(depth, isProdColumn, isDayColumn, valueColumn, isTotalColumn) {
  let styles;

   /* if (isProdColumn) {
    if (valueColumn > 1) {
      styles = `background-color: #BBE4CE;`;
    } else {
      styles = `background-color: #F2E6A9;`;
    }
  } else {} */

  
  switch (depth) {
    case 0:
      styles = `background-color: #EEEEEE;`;
      break;
    case 1:
      styles = `background-color: #999;`;
      break;
    case 2:
      styles = `background-color: #AAA;`;
      break;
    case 3:
      styles = `background-color: #CCC;`;
      break;
    case 4:
      styles = `background-color: #EEE;`;
      break;
    default:
      styles = `background-color: #D9D9D9;`;
      break;
    }
    
  if (isTotalColumn) {
    styles = `background-color: #99b1c2;`;
  }

  if (isProdColumn) {
    styles = `background-color: #99b1c2;`;
  }
    
  if (isDayColumn) {
    const visits = valueColumn.props.children[0].props.children;
    const sales = valueColumn.props.children[1];

    if (visits === 0 && sales === 0) {
      styles = `background-color: #FF9696;`;
    }

    if (visits !== 0 && sales === 0) {
      styles = `background-color: #ffb27a;`;
    }
  }

  return styles;
}

export const ColumnContainer = styled.div`
  ${({ depth, isProdColumn, isDayColumn, valueColumn, isTotalColumn }) => defineStyles(depth, isProdColumn, isDayColumn, valueColumn, isTotalColumn)}
`;

export const Accessor = styled.span`
  all: unset;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const levels = {
  M: '#6004A8',
  G: '#3088DA',
  T: '#00A99F',
}

export const Avatar = styled.span`
  all: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  margin-right: 8px;

  background-color: ${({ level }) => levels[level]};
`;

export const Info = styled.span`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const Abbreviation = styled.h1`
  font-size: 14px;
  color: #fff;
`;
