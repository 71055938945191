import React, { useState, } from 'react';
import { useHistory } from "react-router-dom";
import * as datefns from 'date-fns';
import api from '../../services/api';

function ModalSalesSchedule(props) {
  const history = useHistory();
  const [invalidInput, setInvalidInput] = useState([]);
  const [schedulesDesc, setSchedulesDesc] = useState({
    id_customer: props.idCustomer,
    id_project: props.idProject,
    id_customer_phase_status: props.status ? props.status : 2,
    date: '',
    return_reason: '',
    id_customer_phase: props.steps,
    id_schedule_status: 3
  })
  const [dateSchedulesDay, setDateSchedulesDay] = useState('');
  const [dateSchedulesHour, setDateSchedulesHour] = useState('');

  function schedulesSales(event) {
    let invalidList = []
    let schedulesPost = schedulesDesc;
    if (schedulesPost.id_customer_phase < 3) {
      schedulesPost.id_customer_phase += 1;
    }
    if (dateSchedulesDay !== '' && dateSchedulesHour !== '') {
      try {
        // schedulesPost.date = new Date(dateSchedulesDay+' '+dateSchedulesHour);
        schedulesPost.date = datefns.parseISO(dateSchedulesDay + ' ' + dateSchedulesHour);

      } catch (err) {
        console.log(err);
        invalidList.push('date');
        invalidList.push('hour');
      }
    } else {
      console.log("error")
      invalidList.push('date');
      invalidList.push('hour');
    }

    if (schedulesPost.return_reason.length <= 0) {
      invalidList.push('reason');
    }

    if (invalidList.length > 0) {
      setInvalidInput(invalidList);
    } else {
      api.post('/schedules', schedulesPost)
        .then(response => {
          if (response.data) {
            history.push('/Customer')
          } else {
            console.log(response)
          }
        }).catch(err => {
          console.log(err)
        })
    }
  }

  function removeInvalidInput(input) {
    if (invalidInput.includes(input)) {
      setInvalidInput(invalidInput.filter(item => item !== input));
    }
  }

  return (
    <div id='cardModalAlert'>
      <label className='titleModalAlert'>Agendar retorno</label>
      <div className='fullRow'>
        <input
          className={`inputRounded smallInputModalAlert ${invalidInput.includes('date') ? 'invalidInput' : ''}`}
          type='date' value={dateSchedulesDay}
          onChange={(event) => {
            setDateSchedulesDay(event.target.value);
            removeInvalidInput('date');
          }}
        />
        <label>horário</label>
        <input
          className={`inputRounded smallInputModalAlert ${invalidInput.includes('hour') ? 'invalidInput' : ''}`}
          type='time' value={dateSchedulesHour}
          onChange={(event) => {
            setDateSchedulesHour(event.target.value);
            removeInvalidInput('hour');
          }}
        />
      </div>
      <label className='titleModalAlert'>Qual o motivo do retorno?</label>
      <textarea
        className={`inputRounded fullRow ${invalidInput.includes('reason') ? 'invalidInput' : ''}`}
        onChange={(event) => {
          setSchedulesDesc({ ...schedulesDesc, return_reason: event.target.value });
          removeInvalidInput('reason');
        }}
        value={schedulesDesc.return_reason}
      />
      <div className='buttonRow'>
        {props.onCancel && (
          <button className='buttonModalAlert' onClick={(event) => { props.onCancel(event) }}>
            Cancelar
          </button>
        )}
        <button className='buttonModalAlert' onClick={(event) => { schedulesSales(event) }}>
          Agendar
        </button>
      </div>
    </div>
  )
}

export default ModalSalesSchedule;
