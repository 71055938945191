import React, {useState} from 'react';
import {useHistory} from "react-router-dom";

import './styles.css';
import api from '../../services/api';

function ModalSalesGiveup(props) {
  const history = useHistory();
  const [invalidInput, setInvalidInput] = useState([]);
  const [exitDesc, setExitDesc] = useState({
    id_customer: props.idCustomer,
    id_phase: props.steps,
    number_question: props.steps,
    id_project: props.idProject,
    question: ""
  })

  function exitSales(event) {
    if(exitDesc.question){
      api.post('/exit', exitDesc)
      .then(response => {
        if(response.data){
          history.push('/Customer')
        }
      }).catch(err => {
        console.log(err)
      });
    }else{
      setInvalidInput(['question'])
    }
  }

  function removeInvalidInput(input){
    if(invalidInput.includes(input)){
      setInvalidInput(invalidInput.filter(item => item !== input));
    }    
  }

  return (
    <div id='cardModalAlert'>
      <label className='titleModalAlert'>Você está desistindo da venda.</label>
      <label className='titleModalAlert'>Qual o motivo?</label>
      <textarea 
        className={`inputRounded fullRow ${invalidInput.includes('question')?'invalidInput':''}`}
        value={exitDesc.question}
        onChange={(event) => {
          setExitDesc({...exitDesc, question: event.target.value});
          removeInvalidInput('question');
        }}
      />
      <button 
        className='buttonModalAlert'
        onClick={(event) => {exitSales(event)}}
      >
        Sair
      </button>
      {
        invalidInput.length > 0 &&
        <label 
          id="invalidMessage" 
          className="centerInvalidMessage"
        >
          Não é possivel registar a desistência sem justificativa
        </label>
      }
    </div>
  )
}

export default ModalSalesGiveup;