import React, { useState, useContext } from 'react';
import { Spinner } from '../../../components';
import Dropzone from 'react-dropzone-uploader'
import CheckIcon from '@material-ui/icons/Check';
import { getDroppedOrSelectedFiles } from 'html5-file-selector'

import StoreContext from '../../../context/Context';
import api from "../../../services/api";


import * as S from './styled';

import swal from 'sweetalert';

export const ChangeStatusSale = (props) => {    
    const [fileUploaded, setFileUploaded] = useState(false)
    const [firstPanelConcluded, setFirstPanelConcluded] = useState(false);

    const { userProjectStore } = useContext(StoreContext);


    const getUploadParams = async ({ file }) => {       
        const formData = new FormData();

        formData.append('xlsx', file);
    
        const token = localStorage.getItem('token') || sessionStorage.getItem('token');
        
        setFirstPanelConcluded(true);      

        return {
            url: (process.env.REACT_APP_API ?? 'https://apidev.prismapro.com.br') + `/importSpreadScheet/${userProjectStore.id}`,          
            headers: { authorization: `Bearer ${token}` },
            body: formData,
            method: 'POST'
        }        
    }

    const getFilesFromEvent = e => {
        return new Promise(resolve => {
          getDroppedOrSelectedFiles(e).then(chosenFiles => {
            resolve(chosenFiles.map(f => f.fileObject))
          })
        })
    }

    const handleChangeStatus = ({ meta, file, remove }, status) => {
        if (status === 'done') {
            setFileUploaded(true);
        }
        
        if (status === 'error_upload' || status === 'exception_upload') {
            remove()

            swal('Desculpe, tivemos um erro inesperado!', {
                icon: "error",
                button: "Fechar"
            });            
        }
        
    }
    
    const handleSubmit = (files, allFiles) => {
        allFiles.forEach(f => f.remove())
    }   

    const downloadModelSheet = async () => {
        const response = await api.get('https://api.prismapro.com.br/images/b5bddf78-e7cd-4a00-8c2a-16ff8f64238f', {
            responseType: "blob",
            headers: {
              'Accept': 'application/octet-stream'
            }
        })
    
        if (response.status === 200) {
        
            const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
    
            const link = document.createElement('a');
    
            link.href = downloadUrl;    
    
            link.setAttribute('download', `Modelo_De_Alteracao_De_Status_De_Vendas.xlsx`);
    
            document.body.appendChild(link);
    
            link.click();
    
            link.remove()
    
            return
        }    
    }
    
    return (
        <>  
            <S.Tabs>
                <S.ExternalButtonDiv>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <S.StyledCheckIcon panelconcluded={firstPanelConcluded} />
                        <S.HeaderSpan>{'Adicionar Base de Alteração de Status da Venda'}</S.HeaderSpan>
                    </div>
                </S.ExternalButtonDiv>
                <div style={{ textAlign: 'center' }}>
                    <p 
                        style={{ 
                            textDecoration: 'underline', 
                            color: '#EE5928',
                            cursor: 'pointer'
                        }}
                       onClick={downloadModelSheet}
                    >
                        Baixe o modelo aqui
                    </p>                   
                </div>
                <S.InsideContainer>                   
                    <Dropzone
                        getUploadParams={getUploadParams}
                        maxFiles={1}
                        multiple={false}
                        canCancel={false}
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        inputContent="Arraste ou clique aqui para selecionar o arquivo"
                        onChangeStatus={handleChangeStatus}      
                        PreviewComponent={(data) => {
                            const response = data?.fileWithMeta?.xhr?.response ?? {}
                                                       
                            const { name, status } = data.meta
                            
                            let _sales = []
                            let _count = 0

                            if (status === "done") {                                
                                _sales = response

                                if (_sales) {
                                   const { sales, count } = JSON.parse(_sales)
                                   _count = count
                                   _sales = sales.join(', ') 
                                }

                            }
                            return (
                            <>
                                <span 
                                    style={{ 
                                        textAlign: 'center', 
                                        alignSelf: 'flex-start', 
                                        margin: '10px 3%', 
                                        fontFamily: 'Roboto', 
                                        display: 'flex', 
                                        flexDirection: 'row',
                                        fontSize: 20 
                                    }}
                                >                                    
                                    {name} 
                                    {status != "done" ? 
                                        <><span style={{ marginRight: 10, marginLeft: 3 }}>- Aguarde estamos processando os dados</span> <Spinner small={true} /></> : 
                                        <CheckIcon style={{ fill: "green", fontSize: 19, marginLeft: 5 }} />
                                    }
                                </span>
                                {
                                    _sales && status === "done" &&
                                    <> 
                                        <S.TitleTextArea>Total de Vendas Atualizadas com Sucesso: {_count}</S.TitleTextArea>
                                        <S.TextArea 
                                            value={_sales}
                                        >

                                        </S.TextArea>
                                    </>
                                }
                            </>
                            )
                        }}   
                        LayoutComponent={({ dropzoneProps, files, input, extra: { maxFiles }, submitButton, previews }) =>
                            <S.DropzoneContainer {...dropzoneProps} >
                            {previews}
                            <S.DropzoneContent>
                                {files.length < maxFiles && input}
                            </S.DropzoneContent>
                            {files.length > 0 && submitButton}
                            </S.DropzoneContainer>
                        } 
                        InputComponent={({ accept, onFiles }) => {
                            return (
                                <div style={{ alignItems: 'center', padding: 10, justifyContent: 'center' }}>
                                    <label 
                                        style={{ 
                                            fontSize: 20, 
                                            marginBottom: 20, 
                                            textAlign: 'center' 
                                        }}>
                                        Arraste, cole ou clique aqui para selecionar o arquivo.                                        
                                    </label>
                                    <label style={{ textAlign: 'center', lineHeight: '30px' }}>
                                        Importar dados de um arquivo XLSX. A importação está limitada a 400 linhas.
                                        Para listas maiores, divida seu arquivo em arquivos menores e envie separadamente. <br/>
                                        Notas: Use o ID da venda e verifique se a TAG informada já está criada no projeto em questão!
                                    </label>
                                    <input
                                        style={{ top: 0, left: 0, width: '100%', height: '100%', position: 'absolute', opacity: 0, cursor: 'pointer' }}
                                        type="file"
                                        accept={accept}
                                        multiple
                                        onChange={e => {
                                            getFilesFromEvent(e)
                                                .then(chosenFiles => {
                                                    onFiles(chosenFiles)
                                                })
                                        }}
                                    />
                                </div>
                            )
                        }}
                        onSubmit={handleSubmit}
                        submitButtonContent={'Adicionar Nova Base'}
                    />
                </S.InsideContainer>               
            </S.Tabs>
        </>
    )
}

