
import React,{useState} from 'react';
import Context, { ScrollContext } from './Context';
import useStorage from '../utils/useStorage';

const StoreProvider = ({ children }) => {
  const [userInfoStore, setUserInfoStore] = useStorage('UserInfo');
  const [userProjectStore, setUserProjectStore] = useStorage('ProjectInfo');
  const [savedFilters, setSavedFilters] = useStorage('savedFilters');
  const [onScroll,setOnScroll] = useState();
  const [socket,setSocket] = useState();

  // console.log('useStorage ---------------->', useStorage('userInfoStore'))
  // console.log('useStorage2222 ---------------->', useStorage('userProjectStore'))
  // console.log('fnccccc ---------------->', useStorage()[1]('ok'))


  return (
    <ScrollContext.Provider
      value={{ onScroll, setOnScroll }}
    >
      <Context.Provider
        value={{
          userInfoStore,
          setUserInfoStore,
          userProjectStore,
          setUserProjectStore,   
          savedFilters,
          setSavedFilters,      
          setSocket,
          socket
        }}
      >
        {children}
      </Context.Provider>
    </ScrollContext.Provider>
  )
}


export default StoreProvider;