import { format } from "date-fns";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import swal from "sweetalert";

import { Spinner } from "../../components";
import Modal from "../../components/ModalForm";
import StoreContext from "../../context/Context";
import api from "../../services/api";

import "./styles.css";

export default function CustomerReports({ userProjectStore }) {
  const [visitDataInit, setVisitDataInit] = useState("");
  const [visitDataFinal, setVisitDataFinal] = useState("");

  const [opportunitiesDataInit, setOpportunitiesDataInit] = useState("");
  const [opportunitiesDataFinal, setOpportunitiesDataFinal] = useState("");
  const [opportunitiesNotVisited, setOpportunitiesNotVisited] = useState(false);

  const [scheduleDataInit, setScheduleDataInit] = useState("");
  const [scheduleDataFinal, setScheduleDataFinal] = useState("");

  const [formsDataInit, setFormsDataInit] = useState("");
  const [formsDataFinal, setFormsDataFinal] = useState("");

  const [postSaleDataInit, setPostSaleDataInit] = useState("");
  const [postSaleDataFinal, setPostSaleDataFinal] = useState("");
  const [lastPosteSale, setLastPostSale] = useState(false);

  const [screens, setScreens] = useState([]);
  const [selectedScreen, setSelectedScreen] = useState(null);

  const [prodDataInit, setProdDataInit] = useState("");
  const [prodDataFinal, setProdDataFinal] = useState("");
  const [prodCanceledSales, setProdCanceledSales] = useState(false);

  const [inactiveUsers, setInativeUsers] = useState(false);
  const [withVisitHistories, setWithVisitHistories] = useState(false);

  const [loadingVisit, setLoadingVisit] = useState(false);
  const [loadingSale, setLoadingSale] = useState(false);
  const [loadingPostSale, setLoadingPostSale] = useState(false);
  const [loadingProduct, setLoadingProduct] = useState(false);
  const [loadingForms, setLoadingForms] = useState(false);
  const [loadingSched, setLoadingSched] = useState(false);
  const [loadingUser, setLoadingUser] = useState(false);
  const [loadingOpportunities, setLoadingOpportunities] = useState(false);
  const [loadingAction, setLoadingAction] = useState(false);

  const [reportQueue, setReportQueue] = useState(-1);

  const [showAlert, setShowAlert] = useState(false);
  const [uploadLink, setUploadLink] = useState("");

  const optionsToast = {
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    closeButton: false,
    draggableDirection: "y",
  };

  const date = format(new Date(), "dd-MM-yyyy-HH-mm-ss", {
    timeZone: "America/Sao_Paulo",
  });

  const regex = new RegExp(/\s/g);

  const { userInfoStore, socket } = useContext(StoreContext);

  const getErrorMessage = (error) => {
    let message_error = null;

    const file = new FileReader();

    file.onload = (e) => {
      message_error = e.target.result
        ? JSON.parse(e.target.result).error
        : null;

      if (message_error) {
        swal(message_error, {
          icon: error.response.status === 401 ? "info" : "error",
          button: "Fechar",
        });
      }
    };

    if (error.response.status === 401) {
      swal(error.response?.data.error, {
        icon: "error",
        button: "Fechar",
      });
    } else if (error.response?.data) {
      file.readAsText(error.response.data);
    } else {
      swal(`Servidor indisponível!`, {
        icon: "error",
        button: "Fechar",
      });
    }
  };

  const attQueuePosition = (data) => {
    const queue_position = data.findIndex((e) => e === uploadLink);

    setReportQueue(queue_position > -1 ? queue_position + 1 : 0);
  };

  const setQueuePosition = (queue_position) => {
    setReportQueue(queue_position);
    socket.emit("reports");
  };

  const setLoadings = () => {
    setLoadingVisit(false);
    setLoadingSale(false);
    setLoadingPostSale(false);
    setLoadingProduct(false);
    setLoadingForms(false);
    setLoadingSched(false);
    setLoadingUser(false);
    setLoadingOpportunities(false);
    setLoadingAction(false);
  };

  const removeQueue = async () => {
    socket.emit("reports_disconnect");

    await api.delete("/reports/" + uploadLink);
  };

  const handleUploadLink = async () => {
    try {
      socket.emit("reports_disconnect");

      const response = await api.get("/reports/" + uploadLink, {
        responseType: "blob",
        headers: {
          Accept: "application/octet-stream",
        },
      });

      if (response.status === 200) {
        setReportQueue(-1);

        setShowAlert(false);

        const downloadUrl = window.URL.createObjectURL(
          new Blob([response.data])
        );

        const link = document.createElement("a");

        link.href = downloadUrl;

        link.setAttribute("download", `${uploadLink}.xlsx`);

        setLoadings();

        document.body.appendChild(link);

        link.click();

        link.remove();

        return;
      }
    } catch (error) {
      setReportQueue(-1);
      setShowAlert(false);
      setLoadings();
      getErrorMessage(error);
    }
  };

  const getCustomScreens = async () => {
    const screens = await api.get("/customScreens/screens", {
      params: { id_project: userProjectStore.id },
    });

    setScreens(screens.data);

    if (screens.data[0]) setSelectedScreen(screens.data[0].id);
  };

  // Verificar se as datas estão no intervalo permitido de 365 dias
  const verifyDates = (startDate, endDate) => {
    if (userInfoStore?.id === "f94a2793-a90e-4f49-b8ae-94add971e8a1") {
      return false;
    }

    const maxDiffDays = 92;

    // Se a data final não foi informada, considerar data atual
    if (!endDate) {
      endDate = new Date().toISOString().slice(0, 10);
    }

    const diffTime = Math.abs(new Date(endDate) - new Date(startDate));

    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); // Arredonda para cima em caso de hora de verão

    return diffDays > maxDiffDays;
  };

  useEffect(() => {
    getCustomScreens();
  }, []);

  useEffect(() => {
    if (reportQueue === 0) {
      handleUploadLink();
    }
  }, [reportQueue]);

  useEffect(() => {
    socket.on("queue_next", attQueuePosition);

    return () => socket.off("queue_next");
  }, [attQueuePosition, socket]);

  const handleQueueReport = async (
    order,
    upload_key,
    report_type,
    dateInit,
    dateFinal,
    parameters = {}
  ) => {
    try {
      setUploadLink(upload_key);

      const queue = await api.post(
        "/reports/" + userProjectStore.id,
        {
          order,
          upload_key,
          report_type,
          date: {
            init: dateInit,
            final: dateFinal,
          },
        },
        {
          params: parameters,
        }
      );

      if (queue.status === 200) {
        setQueuePosition(queue.data.queue_position, upload_key);
      }
    } catch (error) {
      setReportQueue(-1);
      setLoadings();
      setShowAlert(false);
      getErrorMessage(error);
    }
  };

  const getVisitReport = async () => {
    if (visitDataInit != "") {
      if (isNaN(new Date(visitDataInit))) {
        toast.warn("Data Inválida", optionsToast);
        return;
      }

      const valid_date = verifyDates(visitDataInit, visitDataFinal);

      if (valid_date) {
        swal(`O intervalo de datas deve ser de no máximo 90 dias`, {
          icon: "info",
          button: "Fechar",
        });

        return;
      }
    } else {
      swal(`Obrigatório informar a data inicial!`, {
        icon: "info",
        button: "Fechar",
      });

      return;
    }

    if (visitDataFinal != "")
      if (isNaN(new Date(visitDataFinal))) {
        toast.warn("Data Inválida", optionsToast);
        return;
      }

    setLoadingVisit(true);

    let order = [];

    if (withVisitHistories) {
      order = [
        { name: "ID Cliente", key: "increment_id" },
        { name: "Nome Cliente", key: "fantasy_name" },
        { name: "Ramo", key: "customer_segment_name", captalize: true },
        { name: "Endereço", key: "address" },
        { name: "Numero", key: "address_number" },
        { name: "Complemento", key: "address_complement" },
        { name: "CEP", key: "address_zip" },
        { name: "Bairro", key: "address_district" },
        { name: "Cidade", key: "address_city" },
        { name: "Estado", key: "address_state" },
        { name: "Nome representante", key: "contact_name", captalize: true },
        { name: "Telefone representante", key: "contact_mobile_phone" },
        { name: "Cargo", key: "contact_position" },
        { name: "Fase", key: "customer_phase", captalize: true },
        { name: "Observacoes", key: "SCHEDULE_REASON" },
        { name: "Status do Agendamento", key: "SCHEDULE_STATUS" },
        { name: "Motivo Desistencia", key: "question" },
        { name: "Promotor", key: "fullname", captalize: true },
        { name: "Grupo", key: "grupo" },
        { name: "Time", key: "time" },
        { name: "CPF", key: "cpf" },
        { name: "CPF(Somente numeros)", key: "cpf_ON" },
        { name: "Supervisor", key: "supervisor_fullname", captalize: true },
        { name: "Coordenador", key: "coordinator_fullname", captalize: true },
        { name: "Gerente", key: "manager_fullname", captalize: true },
        { name: "Data", key: "CREATED_AT_DATE" },
        { name: "Hora", key: "CREATED_AT_HOUR" },
        { name: "Tags", key: "GIVE_UP_TAGS" },
        { name: "Status do Agendamento", key: "schedule_status" },
        { name: "Agendamento", key: "HAS_SCHEDULE" },
        { name: "Agendado para", key: "SCHEDULE_DATE" },
        { name: "Horario do agendamento", key: "SCHEDULE_HOUR" },
        { name: "Motivo do agendamento", key: "schedule_reason" },
        { name: "Classificação", key: "type_status" },
        { name: "Criado em", key: "CREATED_AT_SCHEDULE_DATE" },
        { name: "Horario de criação", key: "CREATED_AT_SCHEDULE_HOUR" },
        { name: "Criado por", key: "created_by", captalize: true },
        { name: "Motivo do encerramento", key: "conclusion_reason" },
        { name: "Encerrado em", key: "SCHEDULE_END_DATE" },
        { name: "Horario do encerramento", key: "SCHEDULE_END_HOUR" },
        { name: "Encerrado por", key: "concluded_by", captalize: true },
        { name: "Retorno Feito Por", key: "responsavel_nova_visita" },
        { name: "Custom Fields", key: "custom_fields", screens: [1, 2] },
      ];
    } else {
      order = [
        {
          name: "ID Cliente",
          key: "increment_id",
        },
        {
          name: "Nome Cliente",
          key: "fantasy_name",
        },
        {
          name: "Ramo",
          key: "customer_segment_name",
          captalize: true,
        },
        {
          name: "Endereço",
          key: "address",
        },
        {
          name: "Numero",
          key: "address_number",
        },
        {
          name: "Complemento",
          key: "address_complement",
        },
        {
          name: "CEP",
          key: "address_zip",
        },
        {
          name: "Bairro",
          key: "address_district",
        },
        {
          name: "Cidade",
          key: "address_city",
        },
        {
          name: "Estado",
          key: "address_state",
        },
        {
          name: "Nome representante",
          key: "contact_name",
          captalize: true,
        },
        {
          name: "Telefone representante",
          key: "contact_mobile_phone",
        },
        {
          name: "Cargo",
          key: "contact_position",
        },
        {
          name: "Fase",
          key: "customer_phase",
          captalize: true,
        },
        {
          name: "Promotor",
          key: "fullname",
          captalize: true,
        },
        {
          name: "Grupo",
          key: "grupo",
        },
        {
          name: "Time",
          key: "time",
        },
        {
          name: "CPF",
          key: "cpf",
        },
        {
          name: "CPF(Somente numeros)",
          key: "ONLY_NUMBERS",
          field: "cpf",
        },
        {
          name: "Supervisor",
          key: "supervisor_fullname",
          captalize: true,
        },
        {
          name: "Coordenador",
          key: "coordinator_fullname",
          captalize: true,
        },
        {
          name: "Gerente",
          key: "manager_fullname",
          captalize: true,
        },
        {
          name: "Data",
          key: "FORMAT_AS_DATE",
          field: "created_at",
        },
        {
          name: "Hora",
          key: "FORMAT_AS_HOUR",
          field: "created_at",
        },
        {
          name: "Tags",
          key: "giveup_tags",
        },
        {
          name: "Status do Agendamento",
          key: "schedule_status",
        },
        {
          name: "Agendamento",
          key: "EXISTS",
          field: "schedule_status",
        },
        {
          name: "Agendado para",
          key: "FORMAT_AS_DATE",
          field: "schedule_date",
        },
        {
          name: "Horario do agendamento",
          key: "FORMAT_AS_HOUR",
          field: "schedule_date",
        },
        {
          name: "Motivo do agendamento",
          key: "schedule_reason",
        },
        {
          name: "Classificação",
          key: "type_status",
        },
        {
          name: "Criado em",
          key: "FORMAT_AS_DATE",
          field: "schedule_created_at",
        },
        {
          name: "Horario de criação",
          key: "FORMAT_AS_HOUR",
          field: "schedule_created_at",
        },
        {
          name: "Criado por",
          key: "created_by",
          captalize: true,
        },
        {
          name: "Motivo do encerramento",
          key: "conclusion_reason",
        },
        {
          name: "Encerrado em",
          key: "FORMAT_AS_DATE",
          field: "concluded_at",
        },
        {
          name: "Horario do encerramento",
          key: "FORMAT_AS_HOUR",
          field: "concluded_at",
        },
        {
          name: "Encerrado por",
          key: "concluded_by",
          captalize: true,
        },
        {
          name: "Custom Fields",
          key: "custom_fields",
          screens: [1, 2],
        },
      ];
    }

    const name = `${userProjectStore.name}-VISITAS-${date}_N${
      Math.floor(Math.random() * 1000) + Math.floor(Math.random() * 2000)
    }`;
    const upload_key = name.replace(regex, "");
    const report_type = "visits";

    setShowAlert(true);

    const params = {
      withVisitHistories,
    };

    handleQueueReport(
      order,
      upload_key,
      report_type,
      visitDataInit,
      visitDataFinal,
      params
    );

    return;
  };

  const getOpportunitiesReport = async () => {
    setLoadingOpportunities(true);

    if (opportunitiesDataInit != "")
      if (isNaN(new Date(opportunitiesDataInit))) {
        toast.warn("Data Inválida", optionsToast);
        return;
      }

    if (opportunitiesDataFinal != "")
      if (isNaN(new Date(opportunitiesDataFinal))) {
        toast.warn("Data Inválida", optionsToast);
        return;
      }

    let order = [];

    if (opportunitiesNotVisited) {
      order = [
        { name: "ID Lead", key: "id_lead" },
        { name: "Tipo", key: "type" },
        { name: "Codigo", key: "code" },
        { name: "Cliente", key: "fantasy_name" },
        { name: "Endereço", key: "address" },
        { name: "Numero", key: "address_number" },
        { name: "Bairro", key: "address_district" },
        { name: "Complemento", key: "address_complement" },
        { name: "Cidade", key: "address_city" },
        { name: "Estado", key: "address_state" },
        { name: "CEP", key: "address_zip" },
        { name: "Endereço Completo", key: "address_complete" },
        { name: "Documento", key: "document_ON" },
        { name: "Contato", key: "contact_phone" },
        { name: "Lead Criada Em", key: "CREATED_AT_DATE" },
        { name: "Hora da Criação da Lead", key: "CREATED_AT_HOUR" },
        { name: "Promotor", key: "promotor", captalize: true },
      ];
    } else {
      order = [
        { name: "ID Lead", key: "id_lead" },
        { name: "Lead Criada Em", key: "CREATED_AT_DATE" },
        { name: "Hora da Criação da Lead", key: "CREATED_AT_HOUR" },
        { name: "Tipo", key: "type" },
        { name: "Codigo", key: "code" },
        { name: "Cliente", key: "fantasy_name" },
        { name: "Endereço", key: "address" },
        { name: "Numero", key: "address_number" },
        { name: "Bairro", key: "address_district" },
        { name: "Complemento", key: "address_complement" },
        { name: "Cidade", key: "address_city" },
        { name: "Estado", key: "address_state" },
        { name: "CEP", key: "address_zip" },
        { name: "Endereço Completo", key: "address_complete" },
        { name: "Documento", key: "document_ON" },
        { name: "Contato", key: "contact_phone" },
        { name: "Id Cliente", key: "increment_id" },
        { name: "Tags", key: "tags" },
        { name: "Lead Concluída Em", key: "CREATED_AT_DATE_AND_HOUR" },
        { name: "Promotor", key: "promotor", captalize: true },
        { name: "Fase Atual", key: "phase_name" },
      ];
    }

    const name = `${userProjectStore.name}-OPORTUNIDADES-${date}_N${
      Math.floor(Math.random() * 1000) + Math.floor(Math.random() * 2000)
    }`;
    const upload_key = name.replace(regex, "");
    const report_type = "opportunities";

    setShowAlert(true);

    const params = {
      opportunitiesNotVisited,
    };

    handleQueueReport(
      order,
      upload_key,
      report_type,
      opportunitiesDataInit,
      opportunitiesDataFinal,
      params
    );

    return;
  };

  const getProductionReport = () => {
    if (prodDataInit != "") {
      if (isNaN(new Date(prodDataInit))) {
        toast.warn("Data Inválida", optionsToast);
        return;
      }

      const valid_date = verifyDates(prodDataInit, prodDataFinal);

      if (valid_date) {
        swal(`O intervalo de datas deve ser de no máximo 90 dias`, {
          icon: "info",
          button: "Fechar",
        });

        return;
      }
    } else {
      swal(`Obrigatório informar a data inicial!`, {
        icon: "info",
        button: "Fechar",
      });

      return;
    }

    if (prodDataFinal != "")
      if (isNaN(new Date(prodDataFinal))) {
        toast.warn("Data Inválida", optionsToast);
        return;
      }

    setLoadingSale(true);

    const order = [
      { name: "ID Cliente", key: "id" },
      { name: "Nome do Projeto", key: "projectName", captalize: true },
      { name: "Data", key: "CREATED_AT_DATE" },
      { name: "Hora", key: "CREATED_AT_HOUR" },
      { name: "Ultima atualização data", key: "UPDATED_AT_DATE" },
      { name: "Ultima atualização hora", key: "UPDATED_AT_HOUR" },
      { name: "Tipo Documento", key: "id_customer_type" },
      { name: "Documento", key: "document" },
      { name: "Documento (Somente numeros)", key: "document_ON" },
      { name: "Cliente", key: "fantasy_name" },
      { name: "Ramo de Atividade", key: "segment" },
      { name: "Razão Social", key: "social_name", captalize: true },
      { name: "Natureza Jurídica", key: "legal_nature", captalize: true },
      { name: "Atividade primária", key: "primary_cnae", captalize: true },
      { name: "Atividade secundária", key: "secondary_cnae", captalize: true },
      { name: "Situação Receita", key: "situation", captalize: true },
      { name: "Status Receita", key: "status_receita", captalize: true },
      { name: "Faturamento Total", key: "monthly_revenue" },
      { name: "Número de Funcionários", key: "number_employees" },
      { name: "Endereço", key: "address" },
      { name: "Numero", key: "address_number" },
      { name: "Complemento", key: "address_complement" },
      { name: "CEP", key: "address_zip" },
      { name: "Bairro", key: "address_district" },
      { name: "Cidade", key: "address_city" },
      { name: "UF", key: "address_state" },
      { name: "Nome representante", key: "contact_name", captalize: true },
      { name: "Cargo", key: "contact_position" },
      { name: "E-mail", key: "email" },
      { name: "Validação Email", key: "verified_email" },
      { name: "Telefone representante", key: "contact_mobile_phone" },
      { name: "Validação Celular", key: "verified_contact_mobile_phone" },
      { name: "Id Venda", key: "id_sale" },
      { name: "Categoria do Produto", key: "sku_category_name" },
      { name: "Nome do Produto", key: "sku_name" },
      { name: "Código do Produto", key: "sku_ean_code" },
      { name: "Status da Venda", key: "SALE_STATUS", captalize: true },
      { name: "Pendencia", key: "SALE_PENDENCY" },
      { name: "Tag de Pendência", key: "sale_tag" },
      { name: "Observação", key: "observation" },
      { name: "Vendedor", key: "seller_fullname", captalize: true },
      { name: "Grupo", key: "grupo" },
      { name: "Time", key: "time" },
      { name: "CPF do vendedor", key: "seller_cpf" },
      { name: "CPF do vendedor(Somente numeros)", key: "seller_cpf_ON" },
      { name: "Supervisor", key: "supervisor_fullname", captalize: true },
      { name: "Coordenador", key: "coordenator_fullname", captalize: true },
      { name: "Gerente", key: "manager_fullname", captalize: true },
      { name: "Status Zapsign", key: "status_zapsign" },
      { name: "Documento Zapsign", key: "original_file" },
      { name: "Pós Venda", key: "" },
      { name: "Tipo de contato", key: "POST_SALE_TYPE" },
      { name: "Razão do contato", key: "contact_reason" },
      { name: "Razão da troca", key: "exchange_reason" },
      { name: "Código produto substituído", key: "installed_code" },
      { name: "Produto substituído", key: "installed_name" },
      { name: "Código produto instalado", key: "exchanged_code" },
      { name: "Produto instalado", key: "exchanged_name" },
      { name: "Substituído por", key: "exchanged_response" },
      { name: "Custom Fields", key: "custom_fields" },
    ];

    if (prodCanceledSales) {
      order.push({ name: "Observações", key: "comments" });
    }

    const name = `${userProjectStore.name}-BaseDeProducao-${date}_N${
      Math.floor(Math.random() * 1000) + Math.floor(Math.random() * 2000)
    }`;
    const upload_key = name.replace(regex, "");
    const report_type = "sales";

    const params = {
      canceled_sales: prodCanceledSales,
    };

    setShowAlert(true);

    handleQueueReport(
      order,
      upload_key,
      report_type,
      prodDataInit,
      prodDataFinal,
      params
    );
  };

  const getScheduleReport = () => {
    setLoadingSched(true);

    if (scheduleDataInit != "")
      if (isNaN(new Date(scheduleDataInit))) {
        toast.warn("Data Inválida", optionsToast);
        return;
      }

    if (scheduleDataFinal != "")
      if (isNaN(new Date(scheduleDataFinal))) {
        toast.warn("Data Inválida", optionsToast);
        return;
      }

    const order = [
      { name: "Projeto", key: "projectName" },
      { name: "Fase Atual", key: "phase_name" },
      { name: "ID Agendamento", key: "id" },
      { name: "ID do Cliente", key: "increment_id" },
      { name: "Nome Fantasia", key: "fantasy_name" },
      { name: "Documento", key: "documento" },
      { name: "Telefone Comercial", key: "commercial_phone" },
      { name: "Celular Comercial", key: "commercial_mobile" },
      { name: "Telefone Representante Comercial", key: "contact_mobile_phone" },
      { name: "Classificação", key: "type_status" },
      { name: "Agendado para", key: "SCHEDULE_DATE" },
      { name: "Horario do agendamento", key: "SCHEDULE_HOUR" },
      { name: "Grupo", key: "grupo" },
      { name: "Time", key: "time" },
      { name: "Vendedor", key: "fullname" },
      { name: "Supervisor", key: "supervisor", captalize: true },
      { name: "Coordenador", key: "coordenator_fullname" },
      { name: "Gerente", key: "manager_fullname" },
      { name: "Criado Em", key: "created_at" },
      { name: "Horario de criação", key: "CREATED_AT_HOUR" },
      { name: "Status do Agendamento", key: "schedule_status" },
      { name: "Motivo do agendamento", key: "schedule_reason" },
      { name: "Tags", key: "TAGS" },
      { name: "Motivo do encerramento", key: "conclusion_reason" },
      { name: "Encerrado em", key: "concluded_at" },
      { name: "Horario do encerramento", key: "SCHEDULE_END_HOUR" },
      { name: "Criado por", key: "created_by", captalize: true },
      { name: "Encerrado por", key: "concluded_by", captalize: true },
      { name: "Tags de Encerramento", key: "HISTORY_TAGS" },
      { name: "Custom Fields", key: "custom_fields", screens: [1, 2] },
    ];

    const name = `${userProjectStore.name}-AGENDAMENTOS-${date}_N${
      Math.floor(Math.random() * 1000) + Math.floor(Math.random() * 2000)
    }`;
    const upload_key = name.replace(regex, "");
    const report_type = "schedules";

    setShowAlert(true);

    handleQueueReport(
      order,
      upload_key,
      report_type,
      scheduleDataInit,
      scheduleDataFinal
    );
  };

  const getPostSaleReport = () => {
    setLoadingPostSale(true);

    if (postSaleDataInit != "")
      if (isNaN(new Date(postSaleDataInit))) {
        toast.warn("Data Inválida", optionsToast);
        return;
      }

    if (postSaleDataFinal != "")
      if (isNaN(new Date(postSaleDataFinal))) {
        toast.warn("Data Inválida", optionsToast);
        return;
      }

    const order = [
      { name: "Data da Pós-Venda", key: "created_at" },
      { name: "Data de Criação da Venda", key: "CREATED_AT_DATE_AND_HOUR" },
      { name: "Id da Venda", key: "sale_increment_id" },
      { name: "Id do Cliente", key: "customer_increment_id" },
      { name: "Nome Fantasia", key: "fantasy_name" },
      { name: "CPF/CNPJ", key: "document" },
      { name: "Categoria do Produto", key: "category_product" },
      { name: "Código do Produto", key: "product_eancode" },
      { name: "Razão do Contato", key: "contact_reason" },
      { name: "Tipo de Contato", key: "POST_SALE_TYPE" },
      { name: "Razão da Troca", key: "exchange_reason" },
      { name: "Código do Produto Substituído", key: "exchanged_code" },
      { name: "Produto Substituído", key: "exchanged_name" },
      { name: "Código do Produto Instalado", key: "installed_code" },
      { name: "Produto Instalado", key: "installed_name" },
      { name: "Substituído Por", key: "exchanged_response" },
      { name: "Projeto", key: "projectName" },
      { name: "Grupo", key: "grupo" },
      { name: "Time", key: "time" },
      { name: "Promotor", key: "fullname" },
      { name: "Supervisor", key: "supervisor", captalize: true },
      { name: "Coordenador", key: "coordenator_fullname" },
      { name: "Gerente", key: "manager_fullname" },
      { name: "Custom Fields", key: "custom_fields", screens: [4, 5] },
    ];

    const name = `${userProjectStore.name}-PosVendas-${date}_N${
      Math.floor(Math.random() * 1000) + Math.floor(Math.random() * 2000)
    }`;

    const upload_key = name.replace(regex, "");

    const report_type = "postsales";

    setShowAlert(true);

    const params = {
      last_postsale: lastPosteSale,
    };

    handleQueueReport(
      order,
      upload_key,
      report_type,
      postSaleDataInit,
      postSaleDataFinal,
      params
    );
  };

  function remove_accents(strAccents) {
    var strAccents = strAccents.split("");
    var strAccentsOut = new Array();
    var strAccentsLen = strAccents.length;
    var accents =
      "ÀÁÂÃÄÅàáâãäåÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž";
    var accentsOut =
      "AAAAAAaaaaaaOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz";
    for (var y = 0; y < strAccentsLen; y++) {
      if (accents.indexOf(strAccents[y]) != -1) {
        strAccentsOut[y] = accentsOut.substr(accents.indexOf(strAccents[y]), 1);
      } else strAccentsOut[y] = strAccents[y];
    }
    strAccentsOut = strAccentsOut.join("");

    return strAccentsOut;
  }

  const getFormsReport = async () => {
    setLoadingForms(true);

    const form_name = screens.find((e) => e.id == selectedScreen).name;

    const name = `${userProjectStore.name}-FORMS-${remove_accents(
      form_name
    )}-${date}__N${
      Math.floor(Math.random() * 1000) + Math.floor(Math.random() * 2000)
    }`;

    const upload_key = name.replace(regex, "");

    const report_type = "forms";

    setShowAlert(true);

    handleQueueReport(
      {},
      upload_key,
      report_type,
      formsDataInit,
      formsDataFinal,
      { id_screen: selectedScreen }
    );
  };

  const getProductsReport = async () => {
    setLoadingProduct(true);

    const name = `${userProjectStore.name}-PRODUTOS-${date}_N${
      Math.floor(Math.random() * 1000) + Math.floor(Math.random() * 2000)
    }`;

    const upload_key = name.replace(regex, "");

    const report_type = "products";

    setShowAlert(true);

    handleQueueReport({}, upload_key, report_type, null, null);
  };

  const getUsersReport = async () => {
    setLoadingUser(true);

    const order = [
      { name: "Matrícula", key: "registration" },
      { name: "Situação do Empregado", key: "employee_status" },
      { name: "Nome Completo", key: "fullname" },
      { name: "Email", key: "email" },
      { name: "RG", key: "rg" },
      { name: "CPF", key: "cpf" },
      { name: "Celular Corporativo", key: "corporate_mobile_phone" },
      { name: "Endereco", key: "address" },
      { name: "Numero", key: "address_number" },
      { name: "Complemento", key: "address_complement" },
      { name: "Estado", key: "address_state" },
      { name: "Cidade", key: "address_city" },
      { name: "Distrito", key: "address_district" },
      { name: "CEP", key: "address_zip" },
      { name: "Cargo", key: "cargo" },
      { name: "CEP", key: "address_zip" },
      { name: "Código do Banco", key: "bank_code" },
      { name: "Banco", key: "bank" },
      { name: "Agência", key: "agency" },
      { name: "Dígito Agência", key: "agency_digit" },
      { name: "Conta Bancária", key: "bank_account" },
      { name: "Dígito Verificação", key: "check_digit" },
      { name: "Ativo", key: "active" },
      { name: "Grupo", key: "section" },
      { name: "Time", key: "name" },
      { name: "Estado do Time", key: "state" },
      { name: "Cidade do Time", key: "city" },
      { name: "Distrito do Time", key: "district" },
      { name: "Projeto", key: "projeto" },
      { name: "Versão em Uso", key: "app_version" },
      { name: "Última Versão Usada ", key: "old_app_version" },
      { name: "Data da Atualização da Versão", key: "app_version_updated_at" },
    ];

    const name = `${userProjectStore.name}-USUARIOS-${date}_N${
      Math.floor(Math.random() * 1000) + Math.floor(Math.random() * 2000)
    }`;
    const upload_key = name.replace(regex, "");
    const report_type = "users";

    const params = {
      inactive_user: inactiveUsers,
    };

    setShowAlert(true);

    handleQueueReport(order, upload_key, report_type, null, null, params);
  };

  const getActionsReport = async () => {
    setLoadingAction(true);

    const name = `${userProjectStore.name}-ACOES-${date}_N${
      Math.floor(Math.random() * 1000) + Math.floor(Math.random() * 2000)
    }`;

    const upload_key = name.replace(regex, "");

    const report_type = "actions";

    setShowAlert(true);

    handleQueueReport({}, upload_key, report_type, null, null);
  };

  const renderModal = useCallback(() => {
    return (
      <Modal
        showAlert={showAlert}
        close={() => {
          setReportQueue(-1);
          setShowAlert(false);
          setLoadings();
          removeQueue();
        }}
        customButton={() => <> </>}
      >
        <div className="queue">
          <h1 className="queueTitle">Fila de Relatório</h1>
          <div className="queueContent">
            <p>Aguarde um momento!</p>
            {reportQueue > 1 || reportQueue < 0 ? (
              <>
                <p>Estamos processando o seu relatório.....</p>
                {reportQueue > 0 ? (
                  <h3>Você está em {reportQueue}º na fila</h3>
                ) : (
                  <div className="spinner">
                    <Spinner />
                  </div>
                )}
              </>
            ) : (
              <>
                <p>Seu download começará em breve</p>
                <div className="process">
                  <h3>Processando...</h3>
                  <div className="spinner">
                    {" "}
                    <Spinner />{" "}
                  </div>
                </div>
              </>
            )}

            <div className="tableFormControlModalDownloadContainer">
              <button
                className="tableFormControlModalDownload"
                onClick={() => {
                  setReportQueue(-1);
                  setShowAlert(false);
                  setLoadings();
                  removeQueue();
                }}
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }, [showAlert, reportQueue]);

  return (
    <>
      {renderModal()}
      <div className="customerReportsMain">
        <div className="visits_opportunities">
          <div className="visits">
            <h2 className="reportsTitle">Visitas</h2>
            <div className="datePickerReportsC">
              <div className="datePickerReportsContainer">
                <label className="datePickerLabel">Data de Início</label>
                <input
                  value={visitDataInit}
                  onChange={(e) => setVisitDataInit(e.target.value)}
                  className="datePickerReports"
                  type="date"
                  id="initTimeVisit"
                  name="initTimeVisit"
                />
              </div>
              <div className="datePickerReportsContainer">
                <label className="datePickerLabel">Data de Termino</label>
                <input
                  value={visitDataFinal}
                  onChange={(e) => setVisitDataFinal(e.target.value)}
                  className="datePickerReports"
                  type="date"
                  id="finalTimeVisit"
                  name="finalTimeVisit"
                />
              </div>
            </div>
            <p className="titleCheckbox">Incluir</p>
            <div className="checkboxReport">
              <input
                type="checkbox"
                onClick={(_) => setWithVisitHistories(!withVisitHistories)}
              />
              <label>Trazer retornos de visitas</label>
            </div>
            {loadingVisit ? (
              <button
                onClick={() => {
                  getVisitReport();
                }}
                className="tableFormControlDownload"
                disabled
              >
                <Spinner small />
              </button>
            ) : (
              <button
                onClick={() => {
                  getVisitReport();
                }}
                className="tableFormControlDownload"
              >
                Exportar{" "}
              </button>
            )}
          </div>
          <div className="opportunities">
            <h2 className="reportsTitle">Oportunidades</h2>
            <div className="datePickerReportsC">
              <div className="datePickerReportsContainer">
                <label className="datePickerLabel">Data de Início</label>
                <input
                  value={opportunitiesDataInit}
                  onChange={(e) => setOpportunitiesDataInit(e.target.value)}
                  className="datePickerReports"
                  type="date"
                  id="initTimeVisit"
                  name="initTimeVisit"
                />
              </div>
              <div className="datePickerReportsContainer">
                <label className="datePickerLabel">Data de Termino</label>
                <input
                  value={opportunitiesDataFinal}
                  onChange={(e) => setOpportunitiesDataFinal(e.target.value)}
                  className="datePickerReports"
                  type="date"
                  id="finalTimeVisit"
                  name="finalTimeVisit"
                />
              </div>
            </div>
            <p className="titleCheckbox">Incluir</p>
            <div className="checkboxReport">
              <input
                type="checkbox"
                onClick={(_) =>
                  setOpportunitiesNotVisited(!opportunitiesNotVisited)
                }
              />
              <label>Somente oportunidades não concluídas</label>
            </div>
            {loadingOpportunities ? (
              <button
                onClick={() => {
                  getOpportunitiesReport();
                }}
                className="tableFormControlDownload"
                disabled
              >
                <Spinner small />
              </button>
            ) : (
              <button
                onClick={() => {
                  getOpportunitiesReport();
                }}
                className="tableFormControlDownload"
              >
                Exportar{" "}
              </button>
            )}
          </div>
        </div>

        <div className="sale_postsale">
          <div className="sale">
            <h2 className="reportsTitle">Base de produção</h2>
            <div className="datePickerReportsC">
              <div className="datePickerReportsContainer">
                <label className="datePickerLabel">Data de Início</label>
                <input
                  value={prodDataInit}
                  onChange={(e) => setProdDataInit(e.target.value)}
                  className="datePickerReports"
                  type="date"
                  id="initTimeVisit"
                  name="initTimeVisit"
                />
              </div>
              <div className="datePickerReportsContainer">
                <label className="datePickerLabel">Data de Termino</label>
                <input
                  value={prodDataFinal}
                  onChange={(e) => setProdDataFinal(e.target.value)}
                  className="datePickerReports"
                  type="date"
                  id="finalTimeVisit"
                  name="finalTimeVisit"
                />
              </div>
            </div>
            <p className="titleCheckbox">Incluir</p>
            <div className="checkboxReport">
              <input
                type="checkbox"
                onClick={(_) => setProdCanceledSales(!prodCanceledSales)}
              />
              <label>Vendas Canceladas</label>
            </div>
            {loadingSale ? (
              <button
                onClick={() => {
                  getProductionReport();
                }}
                className="tableFormControlDownload"
                disabled
              >
                <Spinner small />
              </button>
            ) : (
              <button
                onClick={() => {
                  getProductionReport();
                }}
                className="tableFormControlDownload"
              >
                Exportar
              </button>
            )}
          </div>
          <div className="postsale">
            <h2 className="reportsTitle">Pós Vendas</h2>
            <div className="datePickerReportsC">
              <div className="datePickerReportsContainer">
                <label className="datePickerLabel">Data de Início</label>
                <input
                  value={postSaleDataInit}
                  onChange={(e) => setPostSaleDataInit(e.target.value)}
                  className="datePickerReports"
                  type="date"
                  id="initTimeVisit"
                  name="initTimeVisit"
                />
              </div>
              <div className="datePickerReportsContainer">
                <label className="datePickerLabel">Data de Termino</label>
                <input
                  value={postSaleDataFinal}
                  onChange={(e) => setPostSaleDataFinal(e.target.value)}
                  className="datePickerReports"
                  type="date"
                  id="finalTimeVisit"
                  name="finalTimeVisit"
                />
              </div>
            </div>
            <p className="titleCheckbox">Incluir</p>
            <div className="checkboxReport">
              <input
                type="checkbox"
                onClick={(_) => setLastPostSale(!lastPosteSale)}
              />
              <label>Trazer somente a última pós-venda de cada cliente</label>
            </div>
            {loadingPostSale ? (
              <button
                onClick={() => {
                  getPostSaleReport();
                }}
                className="tableFormControlDownload"
                disabled
              >
                <Spinner small />
              </button>
            ) : (
              <button
                onClick={() => {
                  getPostSaleReport();
                }}
                className="tableFormControlDownload"
              >
                Exportar
              </button>
            )}
          </div>
        </div>

        <div className="forms_schedules">
          <div className="forms">
            <h2 className="reportsTitle">Formulários</h2>
            <div className="datePickerReportsC">
              <div className="datePickerReportsContainer">
                <label className="datePickerLabel">Data de Início</label>
                <input
                  value={formsDataInit}
                  onChange={(e) => setFormsDataInit(e.target.value)}
                  className="datePickerReports"
                  type="date"
                  id="initTimeVisit"
                  name="initTimeVisit"
                />
              </div>
              <div className="datePickerReportsContainer">
                <label className="datePickerLabel">Data de Termino</label>
                <input
                  value={formsDataFinal}
                  onChange={(e) => setFormsDataFinal(e.target.value)}
                  className="datePickerReports"
                  type="date"
                  id="finalTimeVisit"
                  name="finalTimeVisit"
                />
              </div>
            </div>
            <label
              className="datePickerLabel"
              style={{ marginTop: 5, marginBottom: 1 }}
            >
              Telas
            </label>
            <select
              className="inputRounded"
              onChange={(value) => {
                setSelectedScreen(value.target.value);
              }}
              style={{ width: 250 }}
            >
              {screens.map((e) => (
                <option value={e.id}>{e.name}</option>
              ))}
            </select>
            {userInfoStore.permissions &&
            userInfoStore.permissions.includes("forms_read") ? (
              loadingForms ? (
                <button
                  onClick={() => {}}
                  className="tableFormControlDownload"
                  disabled
                >
                  <Spinner small />
                </button>
              ) : (
                <button
                  onClick={getFormsReport}
                  disabled={!selectedScreen}
                  style={!selectedScreen ? { backgroundColor: "#888" } : null}
                  className="tableFormControlDownload"
                >
                  Exportar
                </button>
              )
            ) : (
              <button
                onClick={() => {}}
                className="tableFormControlDownloadDisabled"
                disabled
              >
                Exportar
              </button>
            )}
          </div>
          <div className="schedules">
            <h2 className="reportsTitle">Agendamentos</h2>
            <div className="datePickerReportsC">
              <div className="datePickerReportsContainer">
                <label className="datePickerLabel">Data de Início</label>
                <input
                  value={scheduleDataInit}
                  onChange={(e) => setScheduleDataInit(e.target.value)}
                  className="datePickerReports"
                  type="date"
                  id="initTimeVisit"
                  name="initTimeVisit"
                />
              </div>
              <div className="datePickerReportsContainer">
                <label className="datePickerLabel">Data de Termino</label>
                <input
                  value={scheduleDataFinal}
                  onChange={(e) => setScheduleDataFinal(e.target.value)}
                  className="datePickerReports"
                  type="date"
                  id="finalTimeVisit"
                  name="finalTimeVisit"
                />
              </div>
            </div>
            {loadingSched ? (
              <button
                onClick={() => {
                  getScheduleReport();
                }}
                className="tableFormControlDownload"
                disabled
              >
                <Spinner small />
              </button>
            ) : (
              <button
                onClick={() => {
                  getScheduleReport();
                }}
                className="tableFormControlDownload"
              >
                Exportar
              </button>
            )}
          </div>
        </div>

        <div className="products_users">
          <div className="users">
            {userInfoStore.id_users_type !== 1 &&
              userInfoStore.id_users_type !== 7 && (
                <>
                  <h2 className="reportsTitle">Usuários</h2>
                  <p className="titleCheckbox">Incluir</p>
                  <div className="checkboxReport">
                    <input
                      type="checkbox"
                      onClick={(_) => setInativeUsers(!inactiveUsers)}
                    />
                    <label>Usuários Inativos</label>
                  </div>
                  {loadingUser ? (
                    <button
                      onClick={() => {
                        getUsersReport();
                      }}
                      className="tableFormControlDownload"
                      disabled
                    >
                      <Spinner small />
                    </button>
                  ) : (
                    <button
                      onClick={() => {
                        getUsersReport();
                      }}
                      className="tableFormControlDownload"
                    >
                      Exportar
                    </button>
                  )}
                </>
              )}
          </div>

          <div className="products">
            <h2 className="reportsTitle">Produtos</h2>
            {loadingProduct ? (
              <button
                onClick={() => {
                  getProductsReport();
                }}
                className="tableFormControlDownload"
                disabled
              >
                <Spinner small />
              </button>
            ) : (
              <button
                onClick={() => {
                  getProductsReport();
                }}
                className="tableFormControlDownload"
              >
                Exportar
              </button>
            )}
          </div>

          <div className="products">
            <h2 className="reportsTitle">Ações</h2>
            {loadingAction ? (
              <button
                onClick={() => {
                  getActionsReport();
                }}
                className="tableFormControlDownload"
                disabled
              >
                <Spinner small />
              </button>
            ) : (
              <button
                onClick={() => {
                  getActionsReport();
                }}
                className="tableFormControlDownload"
              >
                Exportar
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
