import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import { CheckCircleOutline } from '@material-ui/icons';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import BlockIcon from '@material-ui/icons/Block';
import CheckIcon from '@material-ui/icons/Check';
import FaceIcon from '@material-ui/icons/Face';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import RestoreIcon from '@material-ui/icons/Restore';
import * as datefns from 'date-fns';
import pt from 'date-fns/locale/pt-BR';
import { isEmpty } from 'lodash'
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useForm } from "react-hook-form";
import { Link, useHistory } from 'react-router-dom'
import { useLocation, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip'
import { mask } from 'remask';
import swal from 'sweetalert';

import DefaultImage from '../../assets/iconAddPhoto.png'
import iconUser from '../../assets/icons/iconUser.png'
import { MapIcon } from '../../assets/icons/topBarIcons'
import WarningIcon from '../../assets/icons/warning_icon.svg'
import fallback from '../../assets/imagens/fallback.png'
import { MaskImage, MaskPhoto } from '../../components'
import LoaderSpin from '../../components/LoaderSpin';
import ModalMap from '../../components/ModalMap'
import SaleModal from '../../components/SaleModal'
import StatusHistory from '../../components/StatusHistory'
import { Tag } from '../../components/StatusHistory/styled';
import StoreContext from '../../context/Context';
import api from "../../services/api";
import apiZipcode from '../../services/apiZipcode';
import * as S from './customer-info.styles'
import CustomInfo from './CustomInfo/CustomInfo'
import ImageModal from './ImageModal/ImageModal'

import './style.css'

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const toNormalizedData = (date) => {
  if (!date) return '---'

  const dateN = new Date(date)

  return dateN.toLocaleString().replace(/(.+ \d\d:\d\d)(:\d\d)/, '$1')
}

const formatDate = (date) => {
  return datefns.format(datefns.subHours(datefns.parseISO(date), 3), "dd/MM/yyyy 'às' HH:mm")
}

const utcToNormalizedData = (date) => {
  return datefns.format(datefns.parseISO(date.replace('T', ' ').replace('Z', '')), "dd/MM/yyyy 'às' HH:mm", { locale: pt })
}

export const CustomerInfo = (props) => {
  const {
    userProject
  } = props


  const history = useHistory()
  
  let query = useQuery();

  const inputFileRef = useRef(null)
  const inputFilePostSaleRef = useRef(null)

  const { id: idProject } = userProject
  const { idCustomer } = useParams();

  const { userInfoStore, userProjectStore } = useContext(StoreContext);
  const [buttons, setButtons] = useState('sales')
  const [registerEditing, setRegisterEditing] = useState(false)
  const [contactEditing, setContactEditing] = useState(false)
  const [dataEditing, setDataEditing] = useState(false)
  const [sales, setSales] = useState([])
  const [afterSales, setAfterSales] = useState([])
  const [histories, setHistory] = useState([])
  const [customFieldVisitValues, setCustomFieldVisitValues] = useState([])
  const [customFieldContact, setCustomFieldContact] = useState([])
  const [customFieldSignUp, setCustomFieldSignUp] = useState([])
  const [customFieldSale, setCustomFieldSale] = useState([])
  const [categoryList, setCategoryList] = useState([]);
  const [categoryActiveList, setCategoryActiveList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [productListAttributed, setProductListAttributed] = useState([]);

  const [visitBackGround, setVisitBackGround] = useState(fallback)
  const [loading, setLoading] = useState(false)
  const [schedule, setSchedule] = useState([])
  const [showImageModal, setShowImageModal] = useState(false)
  const [carouselImages, setCarouselImages] = useState([])
  const [imageOpened, setImageOpened] = useState(2)
  const [initialCep, setInitialCep] = useState('')

  const [visitHistories, setVisitHistories] = useState([])
  const [salesTags, setSalesTags] = useState([])
  const [oldSaleTags, setOldSaleTags] = useState([])
  const [newSaleTags, setNewSaleTags] = useState([])
  const [modalFormOpen, setModalFormOpen] = useState(false)
  const [modalCancelOpen, setModalCancelOpen] = useState(false)
  const [modalPendOpen, setModalPendOpen] = useState(false)
  const [modalRevertOpen, setModalRevertOpen] = useState(false)
  const [modalApproveOpen, setModalApproveOpen] = useState(false)
  const [modalObservationHistory, setModalObservationHistory] = useState(false)
  const [observationHistory, setObservationHistory] = useState('')
  const [saleObservationHistory, setSaleObservationHistory] = useState('')
  const [saleToBeChanged, setSaleToBeChanged] = useState('')
  const [saleObservation, setSaleObservation] = useState('')
  const [editingSale, setEditingSale] = useState('')
  const [imageToBeChanged, setImageToBeChanged] = useState('')
  const [imageToBePostSaleChanged, setImageToBePostSaleChanged] = useState('')
  const [newImageId, setNewImageId] = useState('')
  const [indexPhotoRoll, setIndexPhotoRoll] = useState('')
  const [editSaleCategoryName, setEditSaleCategoryName] = useState('')
  const [editSaleProductName, setEditSaleProductName] = useState('')
  const [verifiedMobile, setVerifiedMobile] = useState(false)
  const [verifiedEmail, setVerifiedEmail] = useState(false)
  const [customer, setCustomer] = useState({})
  const [promoterData, setPromoterData] = useState({})
  const [customFieldPostSale, setCustomFieldPostSale] = useState([])
  const [createPostSale, setCreatePostSale] = useState({})
  const [activeStock, setActiveStock] = useState(false)
  const [notAllowEditSale, setNotAllowEditSale] = useState(false)
  const [tpv, setTpv] = useState([])
  const [createPostSaleValid, setCreatePostSaleValid] = useState({
    id_user: false,
    id_sku_category: false,
    id_installed_sku: false,
    contact_reason: false,
  })
  const [customFieldProjectVisit, setCustomFieldProjectVisit] = useState([])
  const [customFieldProjectContact, setCustomFieldProjectContact] = useState([])
  const [customFieldProjectSignUp, setCustomFieldProjectSignUp] = useState([])
  const [customFieldProjectSale, setCustomFieldProjectSale] = useState([])
  const [customerProject, setCustomerProject] = useState([])
  const [hidden, setHidden] = useState(true)
  const [scheduleHidden, setScheduleHidden] = useState(true)

  const custom_field_screen = {
    POST_SALE: 5,
    SALE: 4,
    SIGNUP: 3,
    CONTACT: 2,
    VISIT: 1
  }
  
  const [modalMap, setModalMap] = useState(false)

  const BKO = '(Preenchido pelo Bko)'

  const [searchProduct, setSearchProduct] = useState('')

  const checkCategoryStockActive = (category, products) => {
    if (!category) {
      return
    }

    const active = categoryList.filter(c => category === c.id)[0]?.stock ?? false

    setActiveStock(active)

    if (active && products.length === 0) {
      setNotAllowEditSale(true)
    } else if (!active) {
      setNotAllowEditSale(false)
      setEditSaleProductName('')
    } else if (active && products.length > 0) {
      if (editSaleProductName) {
        setNotAllowEditSale(false)
      } else {
        setNotAllowEditSale(true)
      }
    }
  }


  const checkURL = (value) => {
    const testRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/

    return testRegex.test(value) ? `${process.env.REACT_APP_API}/images/` + value : value
  }

  const [promoters, setPromoters] = useState([])

  const { register, handleSubmit, formState: { errors }, reset, getValues, watch, setValue } = useForm();

  const watchZipCode = watch('address.zip')

  const getCategoriesStockActive = async () => {
    const response = await api.get('/categories', { params: { id_project: idProject, stock: 'ativo' } })
    setCategoryActiveList(response.data)
  }

  const getPromoters = async () => {
    const { data } = await api.get('promotors', { params: { id_project: idProject } });

    setPromoters(
      data.sort((a, b) => 
        a.fullname.toLowerCase().localeCompare(b.fullname.toLowerCase()) - 
        b.fullname.toLowerCase().localeCompare(a.fullname.toLowerCase())
      )  
    )
  }

  const getCustomFieldPostSale = async () => {
    const id_screen = 5

    const { data } = await api.get(`customfield/${idProject}/${id_screen}`);

    setCustomFieldPostSale(data)
  }  

  const getProductsForPostSale = useCallback(async () => {
    const { data } = await api.get('attributedProducts',
      { params: { id_user: createPostSale.id_user, id_sku_category: createPostSale.id_sku_category } }
    )

    setProductListAttributed(data)

  }, [createPostSale.id_user, createPostSale.id_sku_category])


  const testRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/

  const checkValue = (e) => testRegex.test(e) ? `${process.env.REACT_APP_API}/images/` + e : e

  const openLink = value => {
    const url = checkValue(value)

    window.open(url, '_blank')
  }

  const formatCurrency = (value) => {
    value = value.replace(/\D/g, '');

    value = (Number(value) / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });

    return value
  }

  const HandleCustomFieldsPostSale = (value, index) => {
    if (value.type === '1' || value.type === '3' || value.type === '4') {
      return (
        <>
          <S.MyLabel htmlFor={value.label} key={index}>
            {value.required === 'true' ?
              <>
                {value.label}
                <span style={{ color: 'red', fontSize: 16, fontWeight: 'bold' }}>
                  *
                </span>
              </>
              : value.label
            }
          </S.MyLabel>
          <S.MyInput2
            placeholder={value.desc}
            key={index + 10000}
            onChange={(event) => handleChangeCustomPostSaleValues(event.target.value, value.label)}
            value={value.value ?? ''}
          />
        </>
      )

    }

    else if (value.type === '2') {
      return (
        <>
          <S.MyLabel htmlFor={value.label} key={index}>
            {value.required === 'true' ?
              <>
                {value.label}
                <span style={{ color: 'red', fontSize: 16, fontWeight: 'bold' }}>
                  *
                </span>
              </>
              : value.label
            }
          </S.MyLabel>
          <S.MyInput2
            placeholder={value.desc}
            key={index + 10000}
            onChange={(event) => handleChangeCustomPostSaleValues(formatCurrency(event.target.value), value.label)}
            value={value?.value ? `${value.value}` : ''}
          />
        </>
      )

    }

    else if (value.type === '5') {
      return (
        <>
          <S.MyLabel htmlFor={value.label} key={index}>
            {value.required === 'true' ?
              <>
                {value.label}
                <span style={{ color: 'red', fontSize: 16, fontWeight: 'bold' }}>
                  *
                </span>
              </>
              : value.label
            }
          </S.MyLabel>
          <S.MySelect
            onChange={(event) => handleChangeCustomPostSaleValues(event.target.value, value.label)}
            id={value.label}
          >
            <option style={{ textTransform: 'none' }}>Selecione a opção </option>
            {
              value.options.map((op, index) => {
                return (
                  <option value={op} key={index}>{op}</option>
                )
              })
            }
          </S.MySelect>
        </>
      )
    }

    else if (value.type === '6') {
      return (
        <>
          <S.MyLabel htmlFor={value.label} key={index}>
            {value.required === 'true' ?
              <>
                {value.label}
                <span style={{ color: 'red', fontSize: 16, fontWeight: 'bold' }}>
                  *
                </span>
              </>
              : value.label
            }
          </S.MyLabel>
          <S.MyInput2
            placeholder={value.desc}
            key={index + 10000}
            onChange={(event) => handleChangeCustomPostSaleValues(mask(event.target.value, ['99/99/9999']), value.label)}
            value={value.value ?? ''}
          />
        </>
      )

    }

    else if (value.type === '9') {
      return (
        <>
          <S.MyLabel htmlFor={value.label} key={index}>
            {value.required === 'true' ?
              <>
                {value.label}
                <span style={{ color: 'red', fontSize: 16, fontWeight: 'bold' }}>
                  *
                </span>
              </>
              : value.label
            }
          </S.MyLabel>
          <S.MyInput2
            placeholder={value.desc}
            key={index + 10000}
            maxLength="14"
            minLength="14"
            onChange={(event) => handleChangeCustomPostSaleValues(mask(event.target.value, ['999.999.999-99']), value.label)}
            value={value.value ?? ''}
          />
        </>
      )

    }

    else if (value.type === '7') {
      return (
        <>
          <S.MyLabel
            htmlFor={value.label}
            key={value.label}
          >
            {value.required === 'true' ?
              <>
                {value.label}
                <span style={{ color: 'red', fontSize: 16, fontWeight: 'bold' }}>
                  *
                </span>
              </>
              : value.label
            }
          </S.MyLabel>
          <S.PostSaleImage>
            <S.MaskImagePostSale
              key={index}
              onClick={
                () => uploadNewPostSaleImage(value.label)
              }
              title={value.label}
            >
              <S.UploadIcon editing={true} />
            </S.MaskImagePostSale>
            {
              value.value &&
              <img src={value.value} key={value.id} />
            }
          </S.PostSaleImage>
        </>
      )
    }

    else if (value.type === '8') {
      return (
        <>
          <S.MyLabel
            htmlFor={value.label}
            key={value.label}
          >
            {value.required === 'true' ?
              <>
                {value.label}
                <span style={{ color: 'red', fontSize: 16, fontWeight: 'bold' }}>
                  *
                </span>
              </>
              : value.label
            }
          </S.MyLabel>
          <S.PostSaleImage>
            <S.MaskImagePostSale
              key={index}
              onClick={
                () => uploadNewPostSaleImage(value.label)
              }
              title={value.label}
            >
              <S.UploadIcon editing={true} />
            </S.MaskImagePostSale>
            {
              value.value &&
              <S.ButtonActionFile
                type='button'
                title={value.label}
                onClick={() => openLink(value.value)}
              >
                <AttachFileIcon />
              </S.ButtonActionFile>
            }
          </S.PostSaleImage>
        </>
      )
    }

    else if (value.type === '11') {
      return (
        <>
          <S.MyLabel
            htmlFor={value.label}
            key={value.label}
          >
            {value.required === 'true' ?
              <>
                {value.label}
                <span style={{ color: 'red', fontSize: 16, fontWeight: 'bold' }}>
                  *
                </span>
              </>
              : value.label
            }
          </S.MyLabel>
          <S.MaskImagePostSale
            key={index}
            onClick={
              () => uploadNewPostSaleImage(value.label)
            }
            title={value.label}
          >
            <S.UploadIcon editing={true} />
          </S.MaskImagePostSale>
          {
            Array.isArray(value.value) && value.value.length > 0 &&
            <S.PostSaleImages>
              {
                value.value.map(elm => (
                  <img src={elm} key={elm} />
                ))
              }
            </S.PostSaleImages>
          }
        </>
      )
    }
  }
  
  const getInfoPostSale = async () => {
    await getPromoters();
    await getCustomFieldPostSale();
    await getCategoriesStockActive();
  }

  useEffect(() => {
    if (createPostSale?.id_user && createPostSale?.id_sku_category) {
      getProductsForPostSale()
    }

  }, [createPostSale.id_user, createPostSale.id_sku_category, getProductsForPostSale])

  const onSubmitPostSale = async (sale) => {
    const uuid = crypto.randomUUID()

    try {
      let data_postSale = {
        ...createPostSale,
        id_post_sale_contact_type: 2,
        id_exchanged_sku: sale.product.id,
        exchange_reason: createPostSale.contact_reason,
        id_sku_exchange_request: uuid
      }

      if (customFieldPostSale.length > 0) {
        if (customFieldPostSale.some(s => s.type === '11')) {
          customFieldPostSale
            .filter(elm => elm.type === '11')
            .forEach(_elm => {
              _elm.value = JSON.stringify(_elm.value)
            })
        }

        data_postSale['custom_fields'] = customFieldPostSale
      }

      const requiredFields = ['id_user', 'id_sku_category', 'id_installed_sku', 'contact_reason']

      let valid = true

      const fields = { ...createPostSaleValid }

      for (const field of requiredFields) {
        if (!data_postSale[field]) {
          fields[field] = true
          setCreatePostSaleValid(fields)
          valid = false
        }
      }

      if (valid) {
        await api.post('postsales', data_postSale)

        await getCustomerInfo()

        swal(`Pós-Venda cadastrada com sucesso!`, {
          icon: "success",
          button: "Fechar"
        });

        setCreatePostSale({})
        setSearchProduct('')
        setProductListAttributed([])
      } else {
        toast.error('Preencha os campos marcados')
      }


    } catch (e) {
      swal(`Erro ao cadastrar pós-venda!`, {
        icon: "success",
        button: "Fechar"
      });

      await getCustomerInfo()

      setCreatePostSale({})
    }
  }

  const CheckArray = (_array, setArray = null) => {
    if (Array.isArray(_array) && _array.length > 0) {

      if (!setArray) return _array

      setArray(_array.reverse())

      return
    }

    if (!setArray) return []

    setArray([])
  }

  const PromoterData = (id, history) => {
    if (id && history.length > 0) {
      const _history = history.find(elm => elm?.promoter?.id === id)

      if (_history?.promoter) return setPromoterData(_history.promoter)
    }

    return setPromoterData({})
  }

  const SalePromotorName = (id_sale, value) => {
    if (id_sale && histories.length > 0) {
      const _history = histories.find(elm => elm.id_sale === id_sale);

      if (_history?.promoter) {
        return _history?.promoter[value] ?? '---'
      }

      return '---'
    }

    return '---'
  }


  const EditingSale = (sale) => {
    const id_user_sales = SalePromotorName(sale.id, 'id')

    sale.id_user_sales = id_user_sales

    setEditingSale(sale)
  }

  const CheckStringLength = (value, _length) => {
    return value.length < _length ? value : value.substring(0, _length - 3) + '...'
  }

  const PrepareSaleSqlData = (_sale) => {
    const sale = {
      id: _sale.id,
      id_customer: idCustomer,
      id_project: userProject.id,
      comments: _sale?.comments ?? '',
      id_sku: _sale?.product?.id ?? '',
      id_sku_category: _sale?.product?.category?.id ?? '',
      custom_fields: _sale?.custom_fields ?? [],
      id_phase: _sale.salePhase.id,
      salePhase: _sale.salePhase,
      saleTags: {
        observation: '',
        tags: []
      }
    }

    return sale
  }

  const PrepareCustomerSqlData = (_customer) => {
    const customer = {
      id_project: userProject.id,
      document: _customer.document,
      social_name: _customer?.revenueData?.social_name ?? '',
      fantasy_name: _customer.fantasy_name,
      foundation_date: _customer?.revenueData?.foundation_date ?? '',
      primary_cnae: _customer?.revenueData?.primary_cnae ?? '',
      commercial_phone: _customer.commercial_phone ?? '',
      commercial_mobile: _customer.commercial_mobile ?? '',
      email: _customer.email,
      monthly_revenue: _customer.monthly_revenue,
      number_employees: _customer.number_employees,
      id_customer_type: _customer.isPj ? 2 : 1,
      latitude: _customer.latitude,
      longitude: _customer.longitude,
      address: _customer.address.street,
      address_number: _customer.address.number,
      address_complement: _customer.address.complement,
      address_district: _customer.address.district,
      address_city: _customer.address.city,
      address_state: _customer.address.state,
      address_zip: _customer.address.zip,
      id_customer_segment: _customer.customerSegment.id,
      contact_cpf: _customer?.contact?.cpf ?? '',
      contact_mobile_phone: _customer?.contact?.mobile_phone ?? '',
      contact_position: _customer?.contact?.position ?? '',
      contact_name: _customer?.contact?.name ?? '',
      created_at: _customer.updated_at,
      updated_at: _customer.created_at,
      tags: _customer.tags
    }

    return customer
  }

  const getAddressByCep = useCallback(async (value) => {
    if (value && value.length >= 8) {
      const cep = value.replace(/\D/g, '');
      const response = await apiZipcode.get(`${cep}/json`)

      setValue('address.street', response?.data?.logradouro)
      setValue('address.district', response?.data?.bairro)
      setValue('address.city', response?.data?.localidade)
      setValue('address.state', response?.data?.uf)
    }
  }, [setValue])

  useEffect(() => {
    if (registerEditing && initialCep && initialCep !== watchZipCode) {
      getAddressByCep(watchZipCode)
    }
  }, [registerEditing, getAddressByCep, initialCep, watchZipCode])

  const handleClickSales = () => {
    setButtons('sales')
  }

  const handleClickAfterSales = () => {
    setButtons('afterSales')
  }

  const handleClickPhases = () => {
    setButtons('phases')
  }

  const handleClickAccess = () => {
    setButtons('access')
  }

  const onSubmit = async (_data) => {
    const data = PrepareCustomerSqlData(_data)

    setRegisterEditing(false)
    setContactEditing(false)
    setDataEditing(false)

    data['custom_fields_sign_up'] = customFieldSignUp
    data['custom_fields_contact'] = customFieldContact
    data['custom_fields_visit'] = customFieldVisitValues

    try {
      await api.put(`/customers/${idCustomer}`, { ...data })

      getCustomerInfo()

      swal(`Informações atualizadas com sucesso!`, {
        icon: "success",
        button: "Fechar"
      });

    } catch (e) {
      swal(`Ocorreu uma falha ao alterar as informações!`, {
        icon: "error",
        button: "Fechar"
      });
    }
  }

  const sortAndSetSales = useCallback((unsortedSales) => {
    if (unsortedSales) {
      const copySales = unsortedSales
     
      setSales(copySales.reverse())
    }
  }, [])

  const getSchedules = async () => {
    const schedules = (await api.get(`scheduleClient/${idCustomer}`, { params: { id_project: idProject } })).data;

    if (schedules.length > 0) {
      setSchedule(schedules);
    }
  }

  const getCustomFieldProject = async () => {
    
    const promise4 = api.get(`customfield/${idProject}/${custom_field_screen.SALE}`);
    const promise3 = api.get(`customfield/${idProject}/${custom_field_screen.SIGNUP}`);
    const promise2 = api.get(`customfield/${idProject}/${custom_field_screen.CONTACT}`);
    const promise1 = api.get(`customfield/${idProject}/${custom_field_screen.VISIT}`);

    const [sale, signup, contact, visit] = await Promise.all([promise4, promise3, promise2, promise1])
  
    setCustomFieldProjectVisit(visit.data)
    setCustomFieldProjectContact(contact.data)
    setCustomFieldProjectSignUp(signup.data)
    setCustomFieldProjectSale(sale.data)    

    return {
      visit: visit.data,
      contact: contact.data,
      signup: signup.data,
    }
  }

  const getPromoterNameVisitHistories = async (_customer) => {
    const visits = _customer.visitHistories
    try {     
      for (const visit of visits)  {
        const response = await api.get('/users/' + visit.id_user)

        if (response.status === 200) {
          const [ data ] = response.data

          visit.name = data.fullname.toLowerCase()
        }
      }

      setVisitHistories(visits)      

      return _customer
    } catch {
     
    }    
  }

  const getCustomerInfo = useCallback(async () => {
    setLoading(true)
    
    const customerData = await api.get(`customer/${idCustomer}`)
    
    const { data } = customerData;

    const id_project = data.id_project

    setCustomerProject(id_project)

    if (id_project !== idProject) {
      const projectName = data?.project?.name ?? ''

      if (projectName) {
        toast.error(`Cliente do projeto ${projectName.toLowerCase()}, Você foi redirecionado para a tela de perfil para mudar de projeto caso tenha acesso!`)
        
        setTimeout(() => {
          history.push('/Profile')
        }, 500)
      } else {
        toast.error("Cliente de outro projeto!, Você foi redirecionado para a tela de clientes e vendas.")
        history.push('/Customer')
      }
    }

    if (data?.address?.zip) {
      setInitialCep(data.address.zip);
    }
   
    const { visit, contact, signup } = await getCustomFieldProject()

    addProjectCustomFields(data.custom_fields_visit ?? [], visit, setCustomFieldVisitValues);
    addProjectCustomFields(data.custom_fields_contact ?? [], contact, setCustomFieldContact);
    addProjectCustomFields(data.custom_fields_sign_up ?? [], signup, setCustomFieldSignUp);  

    const { sales } = data;

    if (!isEmpty(data) && Array.isArray(data?.custom_fields_visit) && data?.custom_fields_visit.some(d => d.label === 'Fachada')) {
      data?.custom_fields_visit.filter(d => d.label === 'Fachada')[0].value &&
        setVisitBackGround(data?.custom_fields_visit.filter(d => d.label === 'Fachada')[0].value)
    }

    await getSchedules();
    await getInfoPostSale();

    if (data?.promoters?.length > 0) {
      PromoterData(data?.promoters[0]?.id ?? null, data?.history ?? [])
    }

    if (data?.visitHistories?.length > 0) {
      const customer = await getPromoterNameVisitHistories(data)

      setCustomer(customer)
    } else {
      setCustomer(data)
    }
    
    setVerifiedMobile(data?.verified_contact_mobile_phone ?? false)
    
    setVerifiedEmail(data?.verified_email ?? false)

    setHistory(data?.history ?? []);

    if (data?.TPV && Object.values(data.TPV).length > 0 ) {
      setTpv(Object.entries(data.TPV))
    }    

    if (data.TPV) delete data.TPV;
    if (data.tpv) delete data.tpv;

    if (data?.phase?.id < 4) {
      setButtons('phases')
    }

    reset(data);
    sortAndSetSales(sales);

    CheckArray(data.postSales, setAfterSales)

    ReactTooltip.rebuild();

    setLoading(false)
  }, [idCustomer, idProject, reset, sortAndSetSales])

  const getCategories = useCallback(async () => {
    const response = await api.get(`/categories?id_project=${idProject}`)
    setCategoryList(response.data)
  }, [idProject])

  const getProductsForSales = useCallback(async () => {
    if (editingSale.id_user_sales) {
      const response = await api.get(`/products/list?id_project=${idProject}&id_category=${editSaleCategoryName}&page=1&id_user=${editingSale.id_user_sales}&id_sku_status=${2}`)
      const products = response.data.data
      setProductList(products)

      if (editSaleCategoryName) {
        checkCategoryStockActive(editSaleCategoryName, products)
      }
    }
  }, [editSaleCategoryName, editingSale.id_user_sales, idProject])

  useEffect(() => {
    if (editSaleCategoryName) {
      getProductsForSales()
    }

  }, [editSaleCategoryName, getProductsForSales])

  useEffect(() => {
    getCustomerInfo();
    getCategories();

  }, [getCustomerInfo, getCategories])

  const handleChangeCustomVisitValues = (value, label) => {
    const copiedCustomFieldVisit = [...customFieldVisitValues]
    const toChangeIndex = copiedCustomFieldVisit.findIndex(data => data.label === label)
    copiedCustomFieldVisit[toChangeIndex].value = value
    setCustomFieldVisitValues(copiedCustomFieldVisit)
  }

  const handleChangeCustomContactValues = (value, label) => {
    const copieCustomContact = [...customFieldContact]
    const toChangeIndex = copieCustomContact.findIndex(data => data.label === label)
    copieCustomContact[toChangeIndex].value = value
    setCustomFieldContact(copieCustomContact)
  }

  const handleChangeCustomSignUpValues = (value, label) => {
    const copieCustomSignUp = [...customFieldSignUp]
    const toChangeIndex = copieCustomSignUp.findIndex(data => data.label === label)
    copieCustomSignUp[toChangeIndex].value = value
    setCustomFieldSignUp(copieCustomSignUp)
  }

  const handleChangeCustomSaleValues = (value, label) => {
    const copieCustomSale = [...editingSale.custom_fields]
    const toChangeIndex = copieCustomSale.findIndex(data => data.label === label)
    copieCustomSale[toChangeIndex].value = value
    setCustomFieldSale(copieCustomSale)
  }

  const handleChangeCustomPostSaleValues = (value, label) => {
    const copieCustomPostSale = [...customFieldPostSale]

    const toChangeIndex = copieCustomPostSale.findIndex(data => data.label === label)

    copieCustomPostSale[toChangeIndex].value = value

    setCustomFieldPostSale(copieCustomPostSale)
  }


  const handleClickNewSchedule = () => {
    // history.push('/scheduleCreate')
    window.open(`/scheduleCreate?increment_id=${customer.increment_id}`, '_blank')
  }

  const getApprove = (value) => {
    if (value) {
      return value.slice(0, value.indexOf('/'))
    }
  }

  const getStatus = (value) => {
    if (value) {
      return value.slice(value.indexOf('/') + 1)
    }
  }

  const cancelSale = async () => {
    saleToBeChanged['id_phase'] = 6

    if (saleToBeChanged['saleTags'] === null) {
      saleToBeChanged['saleTags'] = {}
    }

    saleToBeChanged['saleTags']['observation'] = saleObservation
    saleToBeChanged['saleTags']['tags'] = newSaleTags

    try {
      setModalCancelOpen(false)

      await api.put(`/sales/${saleToBeChanged.id}`, saleToBeChanged)

      setSaleObservation('')

      setNewSaleTags([])

      await getCustomerInfo()

      swal(`Venda cancelada com sucesso!`, {
        icon: "success",
        button: "Fechar"
      });


    } catch (e) {
      setModalCancelOpen(false)

      swal(`Erro ao cancelar venda!`, {
        icon: "error",
        button: "Fechar"
      });
    }
  }

  const pendSale = async () => {
    if (saleToBeChanged.salePhase.name.includes('Aprovação')) {
      saleToBeChanged['id_phase'] = 2

      if (saleToBeChanged['saleTags'] === null) {
        saleToBeChanged['saleTags'] = {}
      }

      saleToBeChanged['saleTags']['observation'] = saleObservation
      saleToBeChanged['saleTags']['tags'] = newSaleTags

    } else {
      saleToBeChanged['id_phase'] = 5
      if (saleToBeChanged['saleTags'] === null) {
        saleToBeChanged['saleTags'] = {}
      }
      saleToBeChanged['saleTags']['observation'] = saleObservation
      saleToBeChanged['saleTags']['tags'] = newSaleTags
    }
    try {
      setModalPendOpen(false)

      await api.put(`/sales/${saleToBeChanged.id}`, saleToBeChanged)

      await getCustomerInfo()

      setSaleObservation('')

      setNewSaleTags([])

      swal(`Venda pendenciada com sucesso!`, {
        icon: "success",
        button: "Fechar"
      });
    } catch (e) {
      setModalPendOpen(false)

      swal(`Erro ao pendenciar venda!`, {
        icon: "error",
        button: "Fechar"
      });

      console.log(e)
    }
  }

  const revertSale = async () => {
    saleToBeChanged['id_phase'] = 1

    if (saleToBeChanged['saleTags'] === null) {
      saleToBeChanged['saleTags'] = {}
    }

    try {
      setModalRevertOpen(false)

      await api.put(`/sales/${saleToBeChanged.id}`, saleToBeChanged)
      
      await getCustomerInfo()

      setSaleObservation('')

      setNewSaleTags([])

      swal(`Venda revertida com sucesso!`, {
        icon: "success",
        button: "Fechar"
      });
    } catch (e) {
      console.log(e)
    }
  }

  const confirmSale = async () => {
    saleToBeChanged['id_phase'] = 4

    if (saleToBeChanged['saleTags'] === null) {
      saleToBeChanged['saleTags'] = {}
    }

    saleToBeChanged['saleTags']['observation'] = saleObservation
    saleToBeChanged['saleTags']['tags'] = newSaleTags

    try {
      setModalApproveOpen(false)

      await api.put(`/sales/${saleToBeChanged.id}`, saleToBeChanged)

      await getCustomerInfo()

      setSaleObservation('')

      setNewSaleTags([])

      swal(`Venda aprovada com sucesso!`, {
        icon: "success",
        button: "Fechar"
      });
    } catch (e) {
      setModalApproveOpen(false)

      swal(`Erro ao confirmar venda!`, {
        icon: "error",
        button: "Fechar"
      });
      console.log(e)
    }
  }

  const onSubmitSale = async (_sale, custom_field_label = null) => {
    const sale = PrepareSaleSqlData(_sale)

    if (sale['saleTags'] === null || !sale['saleTags']) {
      sale['saleTags'] = {}
    }

    if (saleObservation) {
      sale['saleTags']['observation'] = saleObservation
    }

    if (!isEmpty(customFieldSale)) {
      sale.custom_fields = customFieldSale
    }
    
    if (newImageId && imageToBeChanged && (indexPhotoRoll || indexPhotoRoll === 0 )) {
      const index = sale.custom_fields.findIndex(element => element.label === imageToBeChanged)

      const parse = JSON.parse(sale.custom_fields[index].value)

      parse[indexPhotoRoll] = newImageId

      sale.custom_fields[index].value = JSON.stringify(parse)      

      setIndexPhotoRoll('')
    } else if (newImageId && imageToBeChanged) {
      const index = sale.custom_fields.findIndex(element => element.label === imageToBeChanged)

      sale.custom_fields[index].value = newImageId
    } 
    
    if (custom_field_label) {
      const index = sale.custom_fields.findIndex(element => element.label === custom_field_label)
      sale.custom_fields[index].value = ""
    }

    // Edita o produto na venda
    if (editSaleProductName && activeStock) {
      sale.id_sku = editSaleProductName
    }
    
    // Edita a categoria do produto na venda
    if (editSaleCategoryName) {
      sale.id_sku_category = editSaleCategoryName
      
      if (!activeStock) {
        sale.id_sku = null
      }
    }

    if (observationHistory) {
      sale.observationHistory = `Inclusão de informação no status atual: ${observationHistory}`

      sale['saleTags']['tags'] = newSaleTags

      setObservationHistory('')
      setModalObservationHistory(false)
    }

    if (!observationHistory) {
      if (newSaleTags?.length > 0) {
        sale['saleTags']['tags'] = newSaleTags
      }
      
      setModalObservationHistory(false)
    }

    try {
      setModalFormOpen(false)

      await api.put(`/sales/${sale.id}`, sale)

      await getCustomerInfo()

      setEditingSale({})
      setSaleObservation('')
      setActiveStock(false)
      setNewSaleTags([])
      setEditSaleCategoryName('')

      swal(`Venda editada com sucesso!`, {
        icon: "success",
        button: "Fechar"
      });
    } catch (e) {
      console.log(e)
      swal(`Não foi possível editar venda no momento!`, {
        icon: "info",
        button: "Fechar"
      });
    }
  }

  const getTags = async (id_project, tagType, oldTags = []) => {
    const response = await api.get('/tag', { params: { id_project, id_tag_type: [tagType] } })
    setSalesTags(response.data)
    setOldSaleTags(oldTags)
  }

  useEffect(() => {
    setNewSaleTags(oldSaleTags)
  }, [oldSaleTags])

  const getTagTypeCancel = currentStatus => {
    return !currentStatus.includes('Cancelado') ? 8 : 9
  }

  const getTagTypePend = currentStatus => {
    return currentStatus.includes('Aprovação') ? 7 : 5
  }

  const getTagTypeApprove = () => {
    return 4
  }

  const getSpecificTagsCancel = async sale => {
    const tagType = getTagTypeCancel(sale.salePhase.name)
    getTags(sale.id_project, tagType, sale.saleTags?.tags)
  }

  const getSpecificTagsPend = async sale => {
    const tagType = getTagTypePend(sale.salePhase.name)
    getTags(sale.id_project, tagType, sale.saleTags?.tags)
  }

  const getSpecificTagsApprove = async sale => {
    const tagType = getTagTypeApprove(sale.salePhase.name)
    getTags(sale.id_project, tagType, sale.saleTags?.tags)
  }

  const openCancel = _sale => {
    const sale = PrepareSaleSqlData(_sale)

    setSaleToBeChanged(sale)
    getSpecificTagsCancel(sale)
    setSaleObservation(sale?.saleTags?.observation || '')
    setModalCancelOpen(true)
  }

  const openPend = _sale => {
    const sale = PrepareSaleSqlData(_sale)

    setSaleToBeChanged(sale)
    getSpecificTagsPend(sale)
    setSaleObservation(sale?.saleTags?.observation || '')
    setModalPendOpen(true)
  }

  const openRevert = _sale => {
    const sale = PrepareSaleSqlData(_sale)

    setSaleToBeChanged(sale)
    getSpecificTagsPend(sale)
    setSaleObservation(sale?.saleTags?.observation || '')
    setModalRevertOpen(true)
  }

  const openConfirm = _sale => {
    const sale = PrepareSaleSqlData(_sale)

    setSaleToBeChanged(sale)
    getSpecificTagsApprove(sale)
    setSaleObservation(sale?.saleTags?.observation || '')
    setModalApproveOpen(true)
  }

  const openCarouselModal = (imageArray, index) => {
    //const filterByNotEmptyImages = imageArray?.filter(img => img.value) ?? []
    setShowImageModal(true)
    setCarouselImages(imageArray)
    setImageOpened(index)
  }

  const addSaleTags = (tag) => {
    setNewSaleTags((prevState) => [...prevState, tag])
  }

  const removeSaleTags = (tag) => {
    let copyTags = newSaleTags
    copyTags = copyTags.filter(item => item.id !== tag.id)
    setNewSaleTags(copyTags)
  }

  const handleCloseImageModal = () => setShowImageModal(false)

  const checkSaleCancelButton = status => {
    return !(status.includes('Cancelado') || status.includes('Aprovação/Pendente'))
  }

  const checkSalePendButton = status => {
    return !(status.includes('Cancelado') || status.includes('Pendente'))
  }

  const checkSaleRevertButton = (status, sale) => {
    return status.includes('Ativo') || (status.includes('Cancelado') || status.includes('Pendente') && userInfoStore.id_users_type === 6 && !sale?.comments)
  }

  const checkSaleApproveButton = status => {
    return !status.includes('Cancelado') && !status.includes('Ativo')
  }

  const getSalesButtonName = status => {
    return status.includes('Aprovação') ? 'Aprovar' : 'Ativar'
  }

  const checkEditSale = (status, sale = null) => {    
    return checkSaleCancelButton(status) || (userInfoStore.id_users_type === 6 && status.includes('Cancelado') && sale && sale?.comments)
  }

  const addOrRemoveSalesTags = (tag) => {
    if (newSaleTags.some(t => t.id === tag.id)) {
      removeSaleTags(tag)
    } else {
      addSaleTags(tag)
    }
  }

  const SalesTagsComponent = () => {
    return (
      <>
        <p style={{ alignSelf: 'flex-start', color: '#EE5928', fontSize: '15px', margin: '0 0 10px' }}>Selecione as Tags</p>
        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%' }}>
          {isEmpty(salesTags) && <span style={{ fontSize: '18px', fontWeight: 'bold', color: '#999', marginBottom: '10px' }}>Nenhuma tag cadastrada</span>}
          {salesTags?.map((tag, index) => {
            return (<S.Tag onClick={() => addOrRemoveSalesTags(tag)} presentTag={newSaleTags.some(t => t.id === tag.id)} key={`${index + tag.name}`}>#{tag.name}</S.Tag>)
          })}
        </div>
      </>
    )
  }

  const uploadImage = async (event) => {
    const formData = new FormData();
    formData.append('file', event.target.files[0])
    const response = await api.post('/uploads', formData)
    setNewImageId(process.env.REACT_APP_API + '/images/' + response.data.upload.id)

    swal(`Seu arquivo foi carregado com sucesso. Agora clique em salvar para que seja enviado!`, {
      icon: "success",
      button: "Fechar"
    });
  }

  const uploadImagePostSale = async (event) => {
    const formData = new FormData();

    formData.append('file', event.target.files[0])

    const response = await api.post('/uploads', formData)

    if (imageToBePostSaleChanged) {
      const index = customFieldPostSale.findIndex(element => element.label === imageToBePostSaleChanged)

      if (customFieldPostSale[index].type === '11') {

        if (Array.isArray(customFieldPostSale[index].value)) {
          const values = [...customFieldPostSale[index].value, process.env.REACT_APP_API + '/images/' + response.data.upload.id]
          //const values = [...customFieldPostSale[index].value, 'https://api.prismapro.com.br' + '/images/' + response.data.upload.id]

          customFieldPostSale[index].value = [...values]
        } else {
          const firstImage = [process.env.REACT_APP_API + '/images/' + response.data.upload.id]
          //const firstImage = ['https://api.prismapro.com.br' + '/images/' + response.data.upload.id]

          customFieldPostSale[index].value = firstImage
        }


      } else {
        //customFieldPostSale[index].value = process.env.REACT_APP_API + '/images/' + response.data.upload.id
        customFieldPostSale[index].value = 'https://api.prismapro.com.br' + '/images/' + response.data.upload.id
      }

    }

    setImageToBePostSaleChanged('')

    event.target.value = ''

    swal(`Arquivo carregado com sucesso`, {
      icon: "success",
      button: "Fechar"
    });
  }

  const uploadNewSaleImage = (index) => {
    setImageToBeChanged(index)
    inputFileRef.current.click()
  }

  const uploadNewPostSaleImage = (label) => {
    setImageToBePostSaleChanged(label)
    inputFilePostSaleRef.current.click()
  }

  const goToEditUserPage = (userId) => {
    window.open(`/UsersRegister/${userId}`, '_blank')
  }

  const getUserTypeName = (type) => {
    if (type === 1) {
      return 'Promotor'
    } else if (type === 2) {
      return 'Supervisor'
    } else if (type === 3) {
      return 'Coordenador'
    } else if (type === 4) {
      return 'Gerente'
    }
    else if (type === 5) {
      return 'Backoffice'
    }
    else if (type === 6) {
      return 'Administração'
    }

    return '---'
  }


  const GetIdSale = (postSale) => {
    if (postSale?.id) {
      const sale = sales.find((elm) => elm?.id === postSale?.id_sale)      

      return (
        sale?.increment_id && <>
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => { 
              setButtons("sales")
              
              setTimeout(() => {
                saleScrollView(sale.increment_id)        
              }, 400);
            }}
          >
            <S.MyLabel 
              style={{ cursor: 'pointer' }}
              htmlFor="sale.increment_id"          
            >
              ID da Venda
            </S.MyLabel>
            <S.MyInput
              placeholder="---" 
              readOnly 
              id="sale.increment_id" 
              value={sale.increment_id} 
              orange
              style={{ cursor: 'pointer' }}
            />
          </div>
          <S.MyLabel htmlFor="sale.created_at">Data da Venda</S.MyLabel>
          <S.MyInput placeholder="---" readOnly id="sale.created_at" value={toNormalizedData(sale.created_at)}/>
        </>
      )
    }   
  } 

  const HandleCustomFieldsSale = (value, index, sale) => {
    if (value.type === '1' || value.type === '3' || value.type === '4') {
      return (
        <>
          <S.MyLabel htmlFor={value.label} key={index}>
            {value.required === 'true' ?
              <>
                {value.label}
                <span style={{ color: 'red', fontWeight: 'bold' }}>
                  *
                </span>
              </>
              : value.label
            }
          </S.MyLabel>
          <S.MyInput2
            disabled={sale.id !== editingSale.id}
            placeholder={'Informe ' + value.label}
            key={index + 10000}
            onChange={(event) => handleChangeCustomSaleValues(event.target.value, value.label)}
            value={value.value ?? ''}
          />
        </>
      )

    }

    else if (value.type === '2') {
      return (
        <>
          <S.MyLabel htmlFor={value.label} key={index}>
            {value.required === 'true' ?
              <>
                {value.label}
                <span style={{ color: 'red', fontWeight: 'bold' }}>
                  *
                </span>
              </>
              : value.label
            }
          </S.MyLabel>
          <S.MyInput2
            disabled={sale.id !== editingSale.id}
            placeholder={'Informe ' + value.label}            
            key={index + 10000}
            onChange={(event) => handleChangeCustomSaleValues(formatCurrency(event.target.value), value.label)}
            value={value?.value ? `${value.value}` : ''}
          />
        </>
      )

    }

    else if (value.type === '5') {
      const options = customFieldProjectSale.find(field => field.label === value.label)?.options ?? []

      return (
        <>  
          <S.MyLabel htmlFor={value.label} key={index}>
            {value.required === 'true' ?
              <>
                {value.label}
                <span style={{ color: 'red', fontWeight: 'bold' }}>
                  *
                </span>
              </>
              : value.label
            }
          </S.MyLabel>
          <S.MySelect
            style={{ width: '100%' }}
            onChange={(event) => handleChangeCustomSaleValues(event.target.value, value.label)}
            id={value.label}
            value={value.value}  
            disabled={sale.id !== editingSale.id}
          >
            <option style={{ textTransform: 'none' }}>Selecione a opção</option>
            {
              options.map((op, index) => {
                return (
                  <option value={op} key={index}>{op}</option>
                )
              })
            }
          </S.MySelect>
        </>
      )
    }

    else if (value.type === '6') {
      return (
        <>
          <S.MyLabel htmlFor={value.label} key={index}>
            {value.required === 'true' ?
              <>
                {value.label}
                <span style={{ color: 'red', fontWeight: 'bold' }}>
                  *
                </span>
              </>
              : value.label
            }
          </S.MyLabel>
          <S.MyInput2
            disabled={sale.id !== editingSale.id}
            placeholder={'Informe ' + value.label}      
            key={index + 10000}
            onChange={(event) => handleChangeCustomSaleValues(mask(event.target.value, ['99/99/9999']), value.label)}
            value={value.value ?? ''}
          />
        </>
      )

    }

    else if (value.type === '9') {
      return (
        <>
          <S.MyLabel htmlFor={value.label} key={index}>
            {value.required === 'true' ?
              <>
                {value.label}
                <span style={{ color: 'red', fontWeight: 'bold' }}>
                  *
                </span>
              </>
              : value.label
            }
          </S.MyLabel>
          <S.MyInput2
            disabled={sale.id !== editingSale.id}
            placeholder={'Informe ' + value.label}                  
            key={index + 10000}
            maxLength="14"
            minLength="14"
            onChange={(event) => handleChangeCustomSaleValues(mask(event.target.value, ['999.999.999-99']), value.label)}
            value={value.value ?? ''}
          />
        </>
      )
    }

    else if (value.type === '10') {
      const field = customFieldProjectSale.find(field => field.label === value.label)

      const mask_field = field?.mask ?? ''
      const regex = field?.regex ?? ''
      const desc = field?.desc ?? 'Informe ' + value.label

      return (
        <>
          <S.MyLabel htmlFor={value.label} key={index}>
            {value.required === 'true' ?
              <>
                {value.label}
                <span style={{ color: 'red', fontWeight: 'bold' }}>
                  *
                </span>
              </>
              : value.label
            }
          </S.MyLabel>
          <S.MyInput2
            disabled={sale.id !== editingSale.id}
            placeholder={desc}                  
            key={index + 10000}
            maxLength="14"
            minLength="14"
            pattern={regex}
            onChange={(event) => handleChangeCustomSaleValues(mask(event.target.value, [mask_field]), value.label)}
            value={value.value ?? ''}
          />
        </>
      )
    }
  }

  const checkCustomField = (s) => {
    if (s.type !== '8' && s.type !== '7' && s.type !== '11') {
      if (s.label.includes(BKO) && userInfoStore.id_users_type < 5) {
        return false
      }
      
      return true
    }

    return false
  }

  const saleCustomFields = (sale) => {
    const sale_custom_fields = customFieldProjectSale
    
    const new_fields = []

    if (sale_custom_fields?.length > 0 && sale?.custom_fields?.length > 0) {      
      for (const project_field of sale_custom_fields) {
        if (!sale?.custom_fields.find(field => field.label === project_field.label)) {
          if (project_field.type !== '7' && project_field.type !== '11' &&
          (sale?.product?.category?.id === project_field.id_category || project_field.id_category === "Todos")
          ) {
            project_field.value = ""
            new_fields.push(project_field)
          }
        }
      }

      sale.custom_fields = [...sale.custom_fields, ...new_fields]
    }
    
    return (
      sale?.custom_fields?.some(s => s.type !== '8' && s.type !== '7' && s.type !== '11') &&
      <S.Informations>
        <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', width: '100%' }}>
          <p style={{ fontSize: '13px', fontWeight: 'bold', color: '#EE5928', margin: '0' }}>Informações adicionais</p>
        </div>
        <div style={{ width: '100%', height: 'auto', display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gridGap: '1px 10px' }}>
          {
            sale?.custom_fields?.filter(checkCustomField)
            .map((field, index) => {
              return (
                <div style={{ display: 'flex', alignItems: 'baseline' }} key={index}>
                  {HandleCustomFieldsSale(field, index, sale)}
                  {/* <S.MyLabel htmlFor={field.label}>{field.label}</S.MyLabel>
                  <S.MyInput placeholder="---" isEditing={sale.id === editingSale.id} 
                    onChange={(event) => handleChangeCustomSaleValues(event.target.value, field.label)} 
                    id="example" 
                    readOnly={!sale.id === editingSale.id} 
                    value={field.value}
                  /> */}
                </div>
              )
          })}
        </div>
      </S.Informations>

    )
  }

  const addProjectCustomFields = (_custom_fields, project_fields, setFields) => {
    const new_fields = []

    if (project_fields?.length > 0 && _custom_fields?.length > 0) {      
      for (const project_field of project_fields) {
          if (!_custom_fields.find(field => field.label === project_field.label)) {
            if (project_field.type !== '8' && project_field.type !== '7' && project_field.type !== '11') {
              project_field.value = "---"
              new_fields.push(project_field)
            }
          }
      }
    } else if (_custom_fields.length === 0 && project_fields?.length > 0) {
      project_fields.forEach((project_field) => {
        if (project_field.type !== '8' && project_field.type !== '7' && project_field.type !== '11') {
          project_field.value = "---"
          new_fields.push(project_field)
        }      
      })
    }

    setFields([..._custom_fields, ...new_fields])
  }

  const getTagsNewObservation = (phaseName) => {
    let tagType = null

    switch(phaseName) {
      case "Aprovação/Cancelado": tagType = 8;
        break; 
      case "Aprovação/Pendente": tagType = 7;
        break; 
      case "Acompanhamento/Pendente": tagType = 5;
        break; 
      case "Acompanhamento/Ativo": tagType = 4;
        break; 
      case "Aprovação/Aguardando": tagType = 3;
        break; 
    }

    if (tagType)
      getTags(idProject, tagType, [])
  }


  function handleGenerateDocument(e) {
    e.preventDefault()

    swal({
        title: "Deseja realmente gerar um novo documento para assinatura?",
        text: "A geração de novos documentos implica em custos adicionais, deste modo precisa ser utilizado com cuidado. Ao clicar em Gerar, um novo documento será criado e enviado para os signatários.",
        icon: "warning",
        buttons: ['Voltar', 'Gerar'],
        dangerMode: true,
    })
    .then(async (willGenerate) => {
        if (willGenerate) {
          await api.post('/sales/trigger', { ...customer, trigger: { name: "ZAPSIGN", increment_id: null } })
          
          swal(`Documento gerado com sucesso!`, {
            icon: "success",
            button: "Fechar"
          });

          setTimeout(() => {
            window.location.reload()          
          }, 3500);
        } 
    })
    .catch((error) => {
      const message = error?.response?.data?.message || 'Não foi possível gerar documento!'

      swal(message, { icon: 'error', button: 'Fechar'})
    });
  }

  function handleUpdateStatusDocument(e) {
    e.preventDefault()

    swal({
        title: "Deseja realmente atualizar o documento?",
        text: "Ao clicar em Atualizar o sistema irá verificar se o documento já foi assinado e modificar o status!",
        icon: "warning",
        buttons: ['Voltar', 'Atualizar'],
        dangerMode: true,
    })
    .then(async (willGenerate) => {
        if (willGenerate) {
          await api.put('/docfiles', customer)

          swal(`Documento atualizado com sucesso!`, {
            icon: "success",
            button: "Fechar"
          });

          setTimeout(() => {
            window.location.reload()          
          }, 1500);
        } 
    })
    .catch((error) => {
      const message = error?.response?.data?.message || 'Não foi possível atualizar documento!'

      swal(message, { icon: 'info', button: 'Fechar'})
    });
  }

  function handleUpdateDocument(e) {
    e.preventDefault()

    swal({
        title: "Deseja realmente atualizar o documento?",
        icon: "warning",
        buttons: ['Voltar', 'Atualizar'],
        dangerMode: true,
    })
    .then(async (willGenerate) => {
        if (willGenerate) {
          await api.put('/docfiles', customer)

          swal(`Documento atualizado com sucesso!`, {
            icon: "success",
            button: "Fechar"
          });

          setTimeout(() => {
            window.location.reload()          
          }, 1500);
        } 
    })
    .catch((error) => {
      const message = error?.response?.data?.message || 'Não foi possível atualizar documento!'

      swal(message, { icon: 'info', button: 'Fechar'})
    });
  }  

  const saleScrollView = (increment_id) => {
    const element = document.getElementById(String(increment_id));

    if (element) {
      // element.scrollIntoView({ behavior: 'smooth' });
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    let increment_id = query.get('increment_id') ?? null

    if (increment_id && sales.length > 0) {
      setTimeout(() => {
        saleScrollView(increment_id)        
      }, 800);
    }
  }, [sales])  

  // useEffect( () => { 
  //   if (customer?.visitHistories?.length > 0) {
  //     getPromoterName(customer)
  //   }
  // }, [customer]);
  
  const checkValuesTpv = (value) => {
    if ( /^\d\d-\d\d-\d\d$/.test(value)) {
      const format = new Intl.DateTimeFormat('pt-BR', 
        { 
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
          
        }
      ).format(new Date(value));

      return format
    }

    return value
  }

  const VisitHistory = () => {
    let _visitHistories

    if (Array.isArray(visitHistories)) {
      _visitHistories = [...visitHistories].reverse()
    }
    else {
      _visitHistories = [];
    }

    return (
      _visitHistories.length > 0
      &&
      <S.contact>
        <div style={{ width: '100%', height: 'auto', display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between', margin: '10px 0 10px' }}>
          <h3>Retornos de Visitas</h3>
          {/* {
            userInfoStore.permissions &&
              userInfoStore.permissions.includes('customerdetail_edit')
              ? contactEditing
                ? <>
                  <S.ButtonNew type='submit'>Salvar</S.ButtonNew>
                  <S.ButtonNew onClick={() => setContactEditing(false)}>Cancelar</S.ButtonNew>
                </>
                : <><S.ButtonEdit onClick={() => setContactEditing(true)} type='button'>Editar</S.ButtonEdit></>
              : null
          } */}
        </div>
        <S.MyCard>                
            {
              ( hidden ? [..._visitHistories].slice(0, 1) : _visitHistories)?.map((visit, index) => (
                <S.CardVisitHistories key={index}>
                  <div>
                    Realizado Por: <span>{visit?.name ?? '---'}</span>                                      
                  </div>   
                  <S.ContentVisitHistories>
                    <div>
                      <S.MyLabel htmlFor="created_at">Data</S.MyLabel>
                      <S.MyInput 
                        placeholder="---" 
                        readOnly={true}  
                        value={toNormalizedData(visit.created_at)}
                        id="created_at"
                      />                
                    </div>
                    <div>
                      <S.MyLabel htmlFor="updated_at">Última Atualização</S.MyLabel>
                      <S.MyInput 
                        placeholder="---" 
                        id="updated_at" 
                        readOnly={true} 
                        value={toNormalizedData(visit.updated_at)}
                      />                
                    </div>
                    {
                      visit?.tag?.name &&
                      <div>
                        <S.MyLabel htmlFor="tag">Tag</S.MyLabel>
                        <Tag style={{ 
                          background: "#ebebeb",   
                          width: 'auto'                        
                        }}>
                          #{visit?.tag?.name}
                        </Tag>
                      </div>                           

                    }
                  </S.ContentVisitHistories>
                  <CustomInfo        
                    readOnly={true}            
                    style={{ margin: 0 }}
                    customFieldValues={visit.custom_fields}
                    openCarouselModal={openCarouselModal}
                  />
                </S.CardVisitHistories>
              ))
            }
            { 
              _visitHistories.length > 1 &&
              <S.HiddenContainer>
                <S.HiddenButton onClick={(e) => { e.preventDefault(); setHidden(!hidden) }}>
                    {hidden ? 'Ver mais' : 'Ver menos'}
                    {hidden ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
                </S.HiddenButton>
              </S.HiddenContainer>
            }
        </S.MyCard>
      </S.contact>
    )
  }

  const Schedules = () => {
    const schedule_length = schedule?.length ?? 0

    const schedule_operation = {
      1: "Cancelado",
      2: "Concluído",
      3: "Aberto"
    }

    const schedule_operation_color = {
      1: "#555555",
      2: "#5CD17D",
      3: "#EE5928"
    }

    const CheckStringLength = (value, _length) => {
      return value.length < _length ? value : value.substring(0, _length - 3) + '...'
    }  

    return (
      <S.ScheduleCardContainer>
        {
          schedule_length > 0 ? 
          (
            ( scheduleHidden ? [...schedule].slice(0, 2) : schedule)?.map((schedules, index) => {
                return (  
                  <S.ScheduleCard key={index} 
                    style={{ marginBottom: '15px', display: 'flex', flexDirection: 'column' }}
                  >               
                    <S.ScheduleHeader>
                        <div className='left'>                         
                          <S.ScheduleLabel
                            htmlFor="registerFor"
                          >	
                            Agendado para:
                          </S.ScheduleLabel>
                          <S.ScheduleInput 
                            placeholder="---" 
                            id="date" 
                            readOnly={true}
                            value={utcToNormalizedData(schedules.date)} 
                            orange
                            style={{ marginBottom: 0 }}
                          /> 
                          <Link 
                                to={`/Schedule?id_schedule=${schedules.id}&id_customer=${customer.id}`}
                                style={{ 
                                  display: 'flex',
                                  flexDirection: 'row',
                                  color: '#ee5928',
                                  alignItems: 'center',
                                  fontSize: '13px',
                                  fontWeight: 'bold',
                                  margin: 0
                                }}
                              >
                              Acessar Agendamento
                              {<OpenInNewIcon style={{ fontSize: '15px' }}/>} 
                          </Link>
                        </div>
                        <div className='right'>
                          <span
                            style={{ 
                              background: schedule_operation_color[schedules.id_operation] 
                            }}
                          >
                            {schedule_operation[schedules.id_operation]}
                          </span>    
                        </div>
                    </S.ScheduleHeader>     
                    <S.ScheduleContent>
                      <div>
                        <S.ScheduleLabel
                          htmlFor="registerFor"
                        >	
                          Aberto por:
                        </S.ScheduleLabel>

                        <div className='schedule_avatar'>
                          <S.ScheduleAvatar 
                            background={`${schedules?.user?.file?.uri ?? iconUser}`} 
                          />
                          <div>  
                            <S.ScheduleText>
                              {CheckStringLength(schedules?.history[0]?.user?.fullname ?? '--', 20)}
                            </S.ScheduleText>                       
                            <S.ScheduleLabel>
                              { getUserTypeName(schedules?.history[0]?.user?.id_users_type) ?? '---'}
                            </S.ScheduleLabel>
                          </div>
                        </div>

                        <S.ScheduleLabel 
                          htmlFor="observations-date"
                          style={{ marginTop: '3px' }}
                        >
                          Criado em:
                        </S.ScheduleLabel>   
                        <S.ScheduleText>
                          {formatDate(schedules?.history[0]?.created_at ?? new Date())}
                        </S.ScheduleText>

                        {
                          schedules?.user?.fullname && schedules?.history[0]?.user?.fullname && 
                          schedules.user.fullname !== schedules.history[0].user.fullname && (  
                            <>
                              <S.ScheduleLabel 
                                htmlFor="registerFor"
                                style={{ marginTop: '12px' }}
                              >
                                Atribuído para:
                              </S.ScheduleLabel>                               
                              <S.ScheduleText>
                                {CheckStringLength(schedules?.user?.fullname, 20)}
                              </S.ScheduleText>
                            </>                           
                          )
                        }

                        <S.ScheduleLabel
                          style={{ marginTop: '12px' }}
                        >
                          Observações:
                        </S.ScheduleLabel>
                        {
                          schedules.tags.length > 0 &&
                          <div style={{ height: 'auto', marginBottom: '5px' }}>
                            <div style={{ width: 100, flexFlow: 'row wrap'}}>
                              {
                                schedules.tags.map(tag => (
                                  <Tag style={{ 
                                    background: "#ebebeb",
                                    fontSize: '12px',
                                    fontWeight: 'bold'
                                  }}>
                                    #{tag}
                                  </Tag>
                                ))
                              }
                            </div>
                          </div> 
                        }
                        <S.ScheduleObservation>
                          {schedules.return_reason} 
                        </S.ScheduleObservation>
                      </div>
                      
                      {
                        schedules?.history.length > 1 &&
                        <div>
                          <S.ScheduleLabel
                            htmlFor="registerFor"
                          >
                            {schedules.id_operation === 1 ? "Cancelado por:": "Concluído por:"}	
                          </S.ScheduleLabel>

                          <div className='schedule_avatar'>
                            <S.ScheduleAvatar 
                              background={`${schedules.history[1]?.user?.file?.uri ?? iconUser}`} 
                            />
                            <div>                           
                              <S.ScheduleText>
                                {schedules?.history[1]?.user?.fullname}
                              </S.ScheduleText> 
                              <S.ScheduleLabel>
                                { getUserTypeName(schedules?.history[1]?.user?.id_users_type) ?? '---'}
                              </S.ScheduleLabel>
                            </div>
                          </div>

                          <S.ScheduleLabel 
                            htmlFor="observations-date"
                            style={{ marginTop: '3px' }}
                          >
                            {schedules.id_operation === 1 ? "Cancelado em:": "Concluído em:"}
                          </S.ScheduleLabel> 
                          <S.ScheduleText>
                            {formatDate(schedules?.history[1]?.created_at)}
                          </S.ScheduleText>   

                          <S.ScheduleLabel 
                            style={{ marginTop: '12px' }}                          
                          >
                            Observações:
                          </S.ScheduleLabel>
                          {
                            schedules?.history[1]?.tags.length > 0 &&
                            <div style={{ height: 'auto', marginBottom: '5px' }}>
                              <div style={{ width: 100, flexFlow: 'row wrap'}}>
                                {
                                  schedules?.history[1]?.tags.map(tag => (
                                    <Tag style={{ 
                                      background: "#ebebeb",
                                      fontSize: '12px',
                                      fontWeight: 'bold'
                                    }}>
                                      #{tag}
                                    </Tag>
                                  ))
                                }
                              </div>
                            </div> 
                          }
                          <S.ScheduleObservation>
                            {schedules?.history[1]?.description} 
                          </S.ScheduleObservation>
                        </div>
                      }                   
                    </S.ScheduleContent>
                  </S.ScheduleCard>
                )
            })
          )
          : <p>Nenhum Agendamento.</p>
        }     
        
        { 
          schedule_length > 2 &&
          <S.HiddenContainer>
            <S.HiddenButton onClick={(e) => { e.preventDefault(); setScheduleHidden(!scheduleHidden) }}>
                {scheduleHidden ? 'Ver mais' : 'Ver menos'}
                {scheduleHidden ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
            </S.HiddenButton>
          </S.HiddenContainer>
        }
      </S.ScheduleCardContainer>
    )
  }

  const closeSaleModal = () => {
    setModalCancelOpen(false)
    setModalPendOpen(false)
    setModalRevertOpen(false)
    setModalApproveOpen(false)
    setNewSaleTags([])
  }

  const ZapSign = () => {
    let last_document = []


    if (customer?.docFiles?.length > 0) {
      last_document = [customer.docFiles[customer?.docFiles.length - 1]]
    }

    return (      
      <S.MyCardButton>
        <div style={{ display: 'flex', alignItems: 'flex-start', margin: '10px', flexDirection: 'column' }}>
          <S.ZapSignTitle>
            <span>ZapSign</span>
            {
              last_document.length > 0 &&
              <span
                style={{
                  color: last_document[0].status === "signed" ? "#FFF" : "#555555",   
                  background: last_document[0].status === "signed" ? "#5CD17D" : "#FFCC4A"                   
                }}
              >
                {last_document[0].status === "signed" ? "Assinado" : "Pendente"}
              </span>
            }
          </S.ZapSignTitle>
          {
            last_document.length > 0 &&
            <S.ZapSignContent>         
              <span
                onClick={
                  () => window.open(`https://app.zapsign.com.br/conta/documentos/${last_document[0].token}`, '_blank')
                } 
              >
                Acessar documento na ZapSign 
                {<OpenInNewIcon style={{ fontSize: '14px', marginLeft: '1px' }}/>} 
              </span>

              {
                last_document[0].status === "signed" &&
                <span
                  onClick={() => window.open(last_document[0].signed_file, '_blank')} 
                >
                  Documento Assinado
                  {<OpenInNewIcon style={{ fontSize: '14px', marginLeft: '1px' }}/>} 
                </span>
              }

              <div>
                <span>Criado em: {toNormalizedData(last_document[0].created_at)}</span>
                
                <span>Atualizado em: {toNormalizedData(last_document[0].updated_at)}</span>
              </div>                                   
            </S.ZapSignContent>
          }
          <hr/>
          {
            last_document.length > 0 && last_document[0].status === "pending" && <>
              <S.ZapSignDocumentGenerateButton                                                                              
                onClick={handleUpdateStatusDocument}  
              >
                Atualizar status do documento <RestoreIcon style={{ fontSize: '18px', marginLeft: '5px' }} />
              </S.ZapSignDocumentGenerateButton>
              <S.ZapSignDescription>
                Caso o documento esteje pendente verifique se na ZapSign está como assinado e clique nesta opção para atualiza-lo.
              </S.ZapSignDescription> 
            </>
          }
          {
            last_document.length > 0 && last_document[0].status === "signed" && <>
              <S.ZapSignDocumentGenerateButton                                                                              
                onClick={handleUpdateDocument}  
              >
                Atualizar documento <RestoreIcon style={{ fontSize: '18px', marginLeft: '5px' }} />
              </S.ZapSignDocumentGenerateButton>
              <S.ZapSignDescription>
                O documento está assinado, porém algumas informações podem estar desatualizadas. O link do documento assinado tem uma duração de 60 minutos por padrão da ZapSign. Clique em "Atualizar documento".
              </S.ZapSignDescription> 
            </>
          }
          <S.ZapSignDocumentGenerateButton                                                                              
            onClick={handleGenerateDocument}  
          >
            Gerar novo documento <img src={WarningIcon}/>
          </S.ZapSignDocumentGenerateButton>
          <S.ZapSignDescription>
            Cuidado, ao clicar nesta opção, um novo documento será criado e enviado para os signatários automáticamente.
          </S.ZapSignDescription>
        </div>
      </S.MyCardButton>    
    )
  }


  function handleTrigger(e, triggerName, increment_id) {    
    e.preventDefault()

    swal({
        title: `Deseja realmente disparar s integração da ${triggerName}?`,
        text: `A venda de ID (${increment_id}) receberá os dados da integração.`,
        icon: "warning",
        buttons: ['Voltar', 'Disparar'],
        dangerMode: true,
    })
    .then(async (willGenerate) => {
        if (willGenerate) {
          await api.post('/sales/trigger', { ...customer, trigger: { name: triggerName, increment_id } })
          
          swal(`Integração acionada com sucesso!`, {
            icon: "success",
            button: "Fechar"
          });

          setTimeout(() => {
            window.location.reload()          
          }, 1500);
        } 
    })
    .catch((error) => {
      const message = error?.response?.data?.message || 'Não acionar integração no momento!'

      swal(message, { icon: 'error', button: 'Fechar'})
    });
  }

  const SaleExternalData = () => {
    const salesFilter = sales
      .filter(sale => sale?.externalData?.length === 0 || !sale?.externalData)

    const salesFilterExternalData = sales
    .filter(sale => sale?.externalData?.length > 0)

    const externalDatas = []

    for (const sale of salesFilterExternalData) {
      const integrations = Object.groupBy(sale.externalData, ({ name }) => name)

      const obj = { increment_id: sale.increment_id, data: [] }

      for (const [key, value] of Object.entries(integrations)) {        
        const values = value.map((item) => {
          return { data: toNormalizedData(item.created_at), ...item.data }
        })

        
        obj.data.push({
          service_name: key, 
          data: values.reverse()
        })
      }

      externalDatas.push(obj)
    }
   
    
    return (      
      <>
        {
          salesFilter.length > 0 &&
          <S.MyCardButton 
            style={{ padding: '10px', scrollBehavior: 'smooth' }}
          >
            <S.SalesFilterTitle>
              Venda(s) sem integrações
            </S.SalesFilterTitle>
            {              
              salesFilter.map(sale => (
                <S.ExternalDataHeader key={sale.id}>
                  <S.ExternalDataTitle>
                    ID da Venda: {sale.increment_id}
                  </S.ExternalDataTitle>
                  <S.ExternalDataButton
                    onClick={(e) => handleTrigger(e, 'BOW-E', sale.increment_id)}
                  >
                    Disparar
                  </S.ExternalDataButton>
                </S.ExternalDataHeader>     
              ))            
            }        
          </S.MyCardButton>
        }

        {
          externalDatas.map(externalData => (
            <S.MyCardButton 
              key={externalData.increment_id}
              style={{ padding: '10px' }}
            >
              <S.ExternalDataHeader>
                <S.ExternalDataTitle>
                  ID da Venda: {externalData.increment_id}
                </S.ExternalDataTitle>
              </S.ExternalDataHeader>
              {
                externalData.data.map(item => (
                    <S.MyCardButton 
                      key={item.service_name}
                      style={{ padding: '10px' }}
                    >
                      <S.ExternalDataHeader>
                        <S.ExternalDataTitle>
                          {item.service_name}
                        </S.ExternalDataTitle>
                        <S.ExternalDataButton
                          onClick={(e) => handleTrigger(e, item.service_name, externalData.increment_id)}
                        >
                          Disparar
                        </S.ExternalDataButton>
                      </S.ExternalDataHeader>      
                        <S.ExternalDataContent>
                            {
                              item.data.map((subitem, index) => (
                                <S.ExternalDataCardButton key={index}>
                                  {
                                    Object.entries(subitem).map(([key, value]) => (
                                      <S.ExternalDataFields key={`${key}-${value}`}>
                                        <S.ExternalDataLabel>{key.toLowerCase()}</S.ExternalDataLabel>
                                        <S.MyInput 
                                          style={{ color: "#999" }}
                                          readOnly={true} 
                                          value={value}
                                        />
                                      </S.ExternalDataFields>
                                    ))
                                  }
                                </S.ExternalDataCardButton>
                              ))
                            }
                        </S.ExternalDataContent>  
                    </S.MyCardButton>
                ))
              }
            </S.MyCardButton>
          ))  
        }
      </>
    )
  }

  const SearchProduct = () => {
    let products = productListAttributed
    
    if (searchProduct) {
      products = productListAttributed
      .filter(product => product.ean_code
        .toUpperCase()
        .includes(searchProduct.toUpperCase())
      )
    } 

    const productsLength = searchProduct && products.length === 1 && !createPostSale?.id_installed_sku ? products.length + 1 : products.length

    const sizeSelect = productsLength === 1 ? productsLength : Math.min(productsLength, 8)

    return (
      <>
        <S.MyLabel 
          htmlFor="ean_code"
        >
          Código do Produto
          <span style={{ color: 'red', fontSize: 16, fontWeight: 'bold' }}>*</span>
        </S.MyLabel>
        
        <S.SearchInput 
          disabled={!(createPostSale.id_user && createPostSale.id_sku_category)}
          type="text" 
          name="search" 
          placeholder='Pequisar produto'
          value={searchProduct}
          onChange={(e) => {
            const value = e.target.value
      
            setSearchProduct(value?.toUpperCase())

            if (!value) {
              setCreatePostSale({
                ...createPostSale, id_installed_sku: null
              })
            }
          }}
        />

        <S.MySelect
          size={sizeSelect}
          id="ean_code"
          style={{ borderColor: createPostSaleValid.id_installed_sku ? 'red' : '#888888' }}
          onChange={(e) => {
              const ean_code = products.find(p => p.id === e.target.value)?.ean_code ?? ''
              
              setSearchProduct(ean_code?.toUpperCase())

              setCreatePostSale({
                ...createPostSale, id_installed_sku: e.target.value
              })

              setCreatePostSaleValid({ ...createPostSaleValid, id_installed_sku: false })
            }
          }
          value={createPostSale?.id_installed_sku}
          disabled={!(createPostSale.id_user && createPostSale.id_sku_category)}
        > 
          <option style={{ textTransform: 'none' }} value={''}>Selecione o produto</option>
          {           
            products
            .map((product) => {
              return (
                <option value={product.id} key={product.id}>{product.ean_code}</option>
              )
            })
          }
        </S.MySelect>
      </>

    )
  }

  return (
    <>
      <div>
        <ReactTooltip />
        {!isEmpty(carouselImages) && (
          <ImageModal
            active={showImageModal}
            imageArray={carouselImages}
            handleCloseModal={handleCloseImageModal}
            imageIndex={imageOpened}
          />
        )}
        <SaleModal
          active={modalCancelOpen}
          contentMain={<SalesTagsComponent />}
          saleObservation={saleObservation}
          setSaleObservation={setSaleObservation}
          titleContent={`cancelada`}
          subtitle={`cancelamento`}
          pendSale={(saleToBeChanged?.salePhase?.name?.includes('Pendente') || saleToBeChanged?.salePhase?.name?.includes('Cancelado')) || ''}
          handleClickSave={cancelSale}
          handleClickClose={closeSaleModal}
        />
        <SaleModal
          active={modalPendOpen}
          contentMain={<SalesTagsComponent />}
          saleObservation={saleObservation}
          setSaleObservation={setSaleObservation}
          titleContent={`pendente`}
          subtitle={`pendência`}
          pendSale={saleToBeChanged?.salePhase?.name?.includes('Pendente') || ''}
          handleClickSave={pendSale}
          handleClickClose={closeSaleModal}
        />
        <SaleModal
          active={modalRevertOpen}
          contentMain={<SalesTagsComponent />}
          saleObservation={saleObservation}
          setSaleObservation={setSaleObservation}
          titleContent={`reverter`}
          subtitle={`reverter`}
          pendSale={saleToBeChanged?.salePhase?.name?.includes('Pendente') || ''}
          handleClickSave={revertSale}
          handleClickClose={closeSaleModal}
        />

        <SaleModal
          active={modalApproveOpen}
          contentMain={<SalesTagsComponent />}
          saleObservation={saleObservation}
          setSaleObservation={setSaleObservation}
          titleContent={`aprovada`}
          subtitle={`aprovada`}
          pendSale={saleToBeChanged?.salePhase?.name?.includes('Pendente') || ''}
          handleClickSave={confirmSale}
          handleClickClose={closeSaleModal}
        />
        <SaleModal
          active={modalObservationHistory}
          contentMain={<SalesTagsComponent />}
          saleObservation={observationHistory}
          setSaleObservation={setObservationHistory}
          titleContent={`novo Histórico`}
          subtitle={`novo Histórico`}
          handleClickSave={() => {
            if (newSaleTags.length > 0 || observationHistory) {
              onSubmitSale(saleObservationHistory)
            } else {
              setModalObservationHistory(false)
            }
          }}
          handleClickClose={() => {
            setModalObservationHistory(false)
            setNewSaleTags([])
          }}
        />
      </div>
      <ModalMap
        showAlert={modalMap}
        close={() => {
          setModalMap(false);
        }}
        options={{ 
          latitude: customer.latitude, 
          longitude: customer.longitude, 
          address: customer.address 
        }}
      >
      </ModalMap>
      {loading ? (
        <div style={{ width: '100%', height: '100vh', display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'center' }}>
          <LoaderSpin enable={loading}/>
        </div>
      ) : (
        <S.Form onSubmit={handleSubmit(onSubmit)}>
          <S.Container>
            <S.visitRecord>
              <div style={{ width: '100%', height: 'auto', display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between', margin: '0 0 10px' }}>
                <h3>Registro de Visita</h3>
                {
                  userInfoStore?.permissions &&
                    userInfoStore?.permissions.includes('customerdetail_edit')
                    ? registerEditing
                      ? <>
                        <S.ButtonNew type='submit'>Salvar</S.ButtonNew>
                        <S.ButtonNew onClick={() => setRegisterEditing(false)}>Cancelar</S.ButtonNew>
                      </>
                      : <><S.ButtonEdit onClick={() => setRegisterEditing(true)} type='button'>Editar</S.ButtonEdit></>
                    : null
                }
              </div>
              <S.MyCardVisit background={`${visitBackGround}`}>
                <S.MyCardGradient>
                </S.MyCardGradient>
                <h2> {customer?.fantasy_name}</h2>
                <p> {customer?.customerSegment?.name} </p>
                <div
                  onClick={() => setModalMap(true)}
                >
                  {
                    customer?.latitude && (customer.latitude !== 0 && customer.latitude !== 1) &&
                    customer?.longitude && (customer.longitude !== 0 && customer.longitude !== 1) 
                    && <MapIcon />
                  }
                </div>
              </S.MyCardVisit>
            </S.visitRecord>
            <S.visitRecord2>
              <S.MyCard>
                <div style={{ width: '100%', height: 'auto', display: 'flex', alignItems: 'flex-start', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <div style={{ width: '50%' }} >
                    <S.MyLabel htmlFor="name">Nome do Estabelecimento</S.MyLabel>
                    <S.MyInput placeholder="---" data-tip={getValues('fantasy_name')} {...register('fantasy_name', {
                      required: true
                    })} id="name" isEditing={registerEditing} readOnly={!registerEditing} orange style={{ fontSize: '15px' }} />
                  </div>
                  <div style={{ width: '50%' }} >
                    <S.MyLabel htmlFor="name">ID do Cliente</S.MyLabel>
                    <S.MyInput
                      placeholder="---"
                      data-tip={getValues('increment_id')}
                      {...register('increment_id')}
                      readOnly={true}
                      orange
                      style={{ fontSize: '15px' }}
                    />
                  </div>
                </div>
                <div style={{ width: '100%', height: 'auto', display: 'flex', alignItems: 'flex-start', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <div style={{ width: '50%' }} >
                    <S.MyLabel htmlFor="publicPlace">Logradouro</S.MyLabel>
                    <S.MyInput placeholder="---" data-tip={getValues('address.street')} id="publicPlace" {...register('address.street')} isEditing={registerEditing} readOnly={!registerEditing} />
                  </div>
                  <div style={{ width: '50%' }} >
                    <S.MyLabel htmlFor="number">Número</S.MyLabel>
                    <S.MyInput placeholder="---" data-tip={getValues('address.number')} id="numer" {...register('address.number')} isEditing={registerEditing} readOnly={!registerEditing} />
                  </div>
                </div>
                <div style={{ width: '100%', height: 'auto', display: 'flex', alignItems: 'flex-start', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <div style={{ width: '50%' }}>
                    <S.MyLabel htmlFor="cep">CEP</S.MyLabel>
                    <S.MyInput placeholder="---" data-tip={getValues('address.zip')} id="cep" {...register('address.zip')} isEditing={registerEditing} readOnly={!registerEditing} />
                  </div>
                  <div style={{ width: '50%' }}>
                    <S.MyLabel htmlFor="complemento">Complemento</S.MyLabel>
                    <S.MyInput placeholder="---" data-tip={getValues('address.complement')} id="complemento" {...register('address.complement')} isEditing={registerEditing} readOnly={!registerEditing} />
                  </div>
                </div>
                <div style={{ width: '100%', height: 'auto', display: 'flex', alignItems: 'flex-start', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <div style={{ width: '33.33%' }}>
                    <S.MyLabel htmlFor="district">Bairro</S.MyLabel>
                    <S.MyInput placeholder="---" data-tip={getValues('address.district')} id="district" {...register('address.district')} isEditing={registerEditing} readOnly={!registerEditing} />
                  </div>
                  <div style={{ width: '33.33%', marginLeft: 5 }}>
                    <S.MyLabel htmlFor="city">Cidade</S.MyLabel>
                    <S.MyInput placeholder="---" data-tip={getValues('address.city')} id="city" {...register('address.city')} isEditing={registerEditing} readOnly={!registerEditing} />
                  </div>
                  <div style={{ width: '33.33%' }}>
                    <S.MyLabel htmlFor="state" >UF</S.MyLabel>
                    <S.MyInput placeholder="---" data-tip={getValues('address.state')} id="state" {...register('address.state')} isEditing={registerEditing} readOnly={!registerEditing} />
                  </div>
                </div>         
                { 
                  customer?.tag?.name && 
                  <div style={{ height: 'auto' }}>
                    <S.MyLabel htmlFor="tag">Tag</S.MyLabel>
                    <Tag style={{ 
                      background: "#ebebeb",
                    }}>
                      #{customer.tag.name}
                    </Tag>
                  </div>                  
                }       
               
                <CustomInfo
                  readOnly={!registerEditing}
                  customFieldProject={customFieldProjectVisit}
                  customFieldValues={customFieldVisitValues}
                  handleChangeCustom={handleChangeCustomVisitValues}
                  isEditingCustom={registerEditing}
                  openCarouselModal={openCarouselModal}
                />
              </S.MyCard>
            </S.visitRecord2>
            
            { VisitHistory() }

            <S.contact>
              <div style={{ width: '100%', height: 'auto', display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between', margin: '10px 0 10px' }}>
                <h3>Contato</h3>
                {
                  userInfoStore.permissions &&
                    userInfoStore.permissions.includes('customerdetail_edit')
                    ? contactEditing
                      ? <>
                        <S.ButtonNew type='submit'>Salvar</S.ButtonNew>
                        <S.ButtonNew onClick={() => setContactEditing(false)}>Cancelar</S.ButtonNew>
                      </>
                      : <><S.ButtonEdit onClick={() => setContactEditing(true)} type='button'>Editar</S.ButtonEdit></>
                    : null
                }
              </div>
              <S.MyCard>               
                <div style={{ width: '100%' }}>
                  <S.MyLabel htmlFor="representative">Representante</S.MyLabel>
                  <S.MyInput placeholder="---" data-tip={getValues('contact.name')} id="representative" {...register('contact.name')} isEditing={contactEditing} readOnly={!contactEditing} />
                  <S.MyLabel htmlFor="position">Cargo do Representante</S.MyLabel>
                  <S.MyInput placeholder="---" data-tip={getValues('contact.position')} {...register('contact.position')} id="position" isEditing={contactEditing} readOnly={!contactEditing} />         
                  <S.MyLabel htmlFor="owner">CPF</S.MyLabel>
                  <S.MyInput placeholder="---" data-tip={getValues('contact.cpf')} {...register('contact.cpf')} id="owner" isEditing={contactEditing} readOnly={!contactEditing} />         
                  <S.MyLabel htmlFor="email">Email</S.MyLabel>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <S.MyInput placeholder="---" style={{ width: '50%' }} data-tip={getValues('email')} id="email" {...register('email')} isEditing={contactEditing} readOnly={!contactEditing} />
                    {!contactEditing && verifiedEmail && <CheckIcon fontSize={'small'} style={{ color: '#44DF6F', alignSelf: 'center' }} />}
                    {!contactEditing && !verifiedEmail && <HighlightOffIcon fontSize={'small'} style={{ color: '#FE5454', alignSelf: 'center' }} />}
                  </div>       
                  <S.MyLabel htmlFor="tel">Telefone do Representante</S.MyLabel>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <S.MyInput placeholder="---" style={{ width: '50%' }} data-tip={getValues('contact.mobile_phone')} {...register('contact.mobile_phone')} id="tel" isEditing={contactEditing} readOnly={!contactEditing} />
                    {!contactEditing && verifiedMobile && <CheckIcon fontSize={'small'} style={{ color: '#44DF6F', alignSelf: 'center' }} />}
                    {!contactEditing && !verifiedMobile && <HighlightOffIcon fontSize={'small'} style={{ color: '#FE5454', alignSelf: 'center' }} />}
                  </div>                 
                  <S.MyLabel htmlFor="commercial_phone">Telefone Comercial</S.MyLabel>
                  <S.MyInput placeholder="---" data-tip={getValues('commercial_phone')} {...register('commercial_phone')} id="commercial_phone" isEditing={contactEditing} readOnly={!contactEditing} />
                  <S.MyLabel htmlFor="commercial_mobile">Celular Comercial</S.MyLabel>
                  <S.MyInput placeholder="---" data-tip={getValues('commercial_mobile')} {...register('commercial_mobile')} id="commercial_mobile" isEditing={contactEditing} readOnly={!contactEditing} />
                  <CustomInfo
                    readOnly={!contactEditing}
                    customFieldProject={customFieldProjectContact}
                    customFieldValues={customFieldContact}
                    handleChangeCustom={handleChangeCustomContactValues}
                    isEditingCustom={contactEditing}
                    openCarouselModal={openCarouselModal}
                  />
                </div>
              </S.MyCard>
            </S.contact>
            <S.scheduling>
              {/* <h3>Agendamentos</h3>               */}
            </S.scheduling>
            <S.registrationData>
              <div style={{ width: '100%', height: 'auto', display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between', margin: '0 0 10px' }}>
                <h3>Dados Cadastrais</h3>
                {
                  userInfoStore.permissions &&
                    userInfoStore.permissions.includes('customerdetail_edit')
                    ? dataEditing
                      ? <>
                        <S.ButtonNew type='submit'>Salvar</S.ButtonNew>
                        <S.ButtonNew onClick={() => setDataEditing(false)}>Cancelar</S.ButtonNew>
                      </>
                      : <><S.ButtonEdit onClick={() => setDataEditing(true)} type='button'>Editar</S.ButtonEdit></>
                    : null
                }
              </div>
              <S.MyCard style={{ height: '100%' }}>
                <div style={{ width: '100%', height: 'auto' }}>
                  <S.MyLabel htmlFor="corporateName">Razão social</S.MyLabel>
                  <S.MyInput placeholder="---" id="corporateName" {...register('revenueData.social_name')} isEditing={dataEditing} readOnly={!dataEditing} />
                </div>
                <div style={{ width: '100%', height: 'auto' }}>
                  <S.MyLabel htmlFor="cnpj">{customer?.document?.length <= 11 ? 'CPF' : 'CNPJ'}</S.MyLabel>
                  <S.MyInput placeholder="---" id="cnpj" {...register('document')} isEditing={dataEditing} readOnly={!dataEditing} />
                </div>
                <div style={{ width: '100%', height: 'auto' }}>
                  <S.MyLabel htmlFor="status">Status na Receita Federal</S.MyLabel>
                  <S.MyInput placeholder="---" id="status" {...register('revenueData.status')} isEditing={dataEditing} readOnly={!dataEditing} />
                </div>
                <div style={{ width: '100%', height: 'auto' }}>
                  <S.MyLabel htmlFor="legalNature">Natureza Jurídica</S.MyLabel>
                  <S.MyInput placeholder="---" id="legalNature" {...register('revenueData.legal_nature')} isEditing={dataEditing} readOnly={!dataEditing} />
                </div>
                <div style={{ width: '100%', height: 'auto' }}>
                  <S.MyLabel htmlFor="primaryActivity">Atividade primária</S.MyLabel>
                  <S.MyInput placeholder="---" id="primaryActivity" {...register('revenueData.primary_cnae')} isEditing={dataEditing} readOnly={!dataEditing} />
                </div>               
                <div style={{ width: '100%', height: 'auto', display: 'flex', alignItems: 'flex-start', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <div style={{ width: '50%', marginBottom: '10px' }}>
                    <S.MyLabel htmlFor="foundationDate">Data da fundação </S.MyLabel>
                    <S.MyInput placeholder="---" id="foundationDate" value={toNormalizedData(getValues('revenueData.foundation_date'))} isEditing={dataEditing} readOnly={!dataEditing} />
                  </div>
                  <div style={{ width: '50%', borderLeft: '1px solid #E8E8E8', paddingLeft: '10px' }}>
                    <S.MyLabel htmlFor="situation">Situação</S.MyLabel>
                    <S.MyInput placeholder="---" id="situation" {...register('revenueData.situation')} isEditing={dataEditing} readOnly={!dataEditing} />
                  </div>
                </div>
                <div style={{ width: '100%', height: 'auto', display: 'flex', alignItems: 'flex-start', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <div style={{ width: '50%' }}>
                    <S.MyLabel htmlFor="totalBilling">Faturamento Total</S.MyLabel>
                    <S.MyInput placeholder="---" id="totalBilling" {...register('monthly_revenue')} isEditing={dataEditing} readOnly={!dataEditing} />
                  </div>
                  <div style={{ width: '50%', borderLeft: '1px solid #E8E8E8', paddingLeft: '10px' }}>
                    <S.MyLabel htmlFor="workers">Funcionários</S.MyLabel>
                    <S.MyInput placeholder="---" id="workers" {...register('number_employees')} isEditing={dataEditing} readOnly={!dataEditing} />
                  </div>
                </div>

                {
                  tpv.length > 0 && <>
                    <S.MyLabel style={{ color: '#EE5928', opacity: 1, marginTop: '15px', fontWeight: '500'}}>TPV</S.MyLabel>
                    <S.Tpv>            
                      { 
                        tpv.map(([key, value]) => (    
                          <>
                            <S.TpvContent key={key+value}>
                              <S.MyLabel style={{ marginTop: 0 }}>{key.toLowerCase()}</S.MyLabel>
                              <div>{checkValuesTpv(value)}</div>
                            </S.TpvContent>     
                          </>                 
                        ))
                      }                     
                    </S.Tpv>
                  </>
                }

                <CustomInfo
                  readOnly={!dataEditing}
                  customFieldProject={customFieldProjectSignUp}
                  customFieldValues={customFieldSignUp}
                  handleChangeCustom={handleChangeCustomSignUpValues}
                  isEditingCustom={dataEditing}
                  openCarouselModal={openCarouselModal}
                />
              </S.MyCard>
            </S.registrationData>
            <S.record>
              <div style={{ width: '100%', height: 'auto', display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between', margin: '3px 0 13px' }}>
                <h3>Informações do Registro</h3>
                <p></p>
              </div>
            </S.record>
            <S.sales>
              <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>

                <S.Button
                  type="button"
                  onClick={handleClickSales}
                  style={{ width: '33%', zIndex: '1000' }}
                  small
                  active={buttons === 'sales'}
                >
                  Vendas
                  <S.SalesIndicator active={buttons === 'sales'}>
                    {sales?.length ? sales?.length : '0'}
                  </S.SalesIndicator>
                </S.Button>


                <S.Button
                  type="button"
                  onClick={handleClickAfterSales}
                  style={{ width: '33%', zIndex: '1000', paddingLeft: 3, paddingRight: 3 }}
                  active={buttons === 'afterSales'}
                >
                  Pós-vendas
                  <S.SalesIndicator active={buttons === 'afterSales'}>
                    {afterSales.length ? afterSales.length : '0'}
                  </S.SalesIndicator>
                </S.Button>

                <S.Button
                  type="button"
                  onClick={handleClickPhases}
                  style={{ width: '33%', zIndex: '1000' }}
                  small
                  active={buttons === 'phases'}
                >
                  Registros
                  <S.SalesIndicator active={buttons === 'phases'}>
                    {histories.length ? histories.length : '0'}
                  </S.SalesIndicator>
                </S.Button>

                <S.Button
                  type="button"
                  disabled={customer?.phase?.id !== 4 || userInfoStore.id_users_type < 5}
                  onClick={handleClickAccess}
                  style={{ width: '33%', zIndex: '1000' }}
                  small
                  active={buttons === 'access'}
                >
                  Integrações
                </S.Button>

              </div>
              <S.CardBody>
                <S.AccordionWrapper>
                  <S.record>
                    {buttons === 'sales' &&
                      <>
                        {sales.map((sale, index) => {
                          return (
                            <S.MyCardButton key={index + 1} id={sale?.increment_id ?? ''}>
                              <S.BtnsEditSale>
                                {
                                  sale?.id === createPostSale?.id_sale &&
                                    checkEditSale(sale?.salePhase?.name) ?
                                    (
                                      <S.ButtonPostSalesContainer>
                                        <S.ButtonNew
                                          onClick={() => onSubmitPostSale(sale)}
                                          type='button'
                                          style={{ color: '#fff' }}
                                        >
                                          Cadastrar
                                        </S.ButtonNew>
                                        <S.ButtonNew
                                          onClick={() => {
                                            setCreatePostSale({})
                                            setSearchProduct('')
                                            setProductListAttributed([])
                                          }}
                                          type='button'
                                          style={{ color: '#fff' }}
                                        >
                                          Cancelar
                                        </S.ButtonNew>
                                      </S.ButtonPostSalesContainer>
                                    ) :
                                    (
                                      userInfoStore.permissions &&
                                      userInfoStore.permissions.includes('postsale_create') &&
                                      !(sale?.id === editingSale?.id) &&
                                      sale?.product?.id &&
                                      (checkEditSale(sale?.salePhase?.name) && sale?.salePhase?.name !== 'Aprovação/Aguardando') &&
                                      <div
                                      >
                                        <S.ButtonEdit
                                          onClick={() => {
                                            setCreatePostSale({
                                              id_customer: idCustomer,
                                              id_sale: sale.id,
                                              id_project: idProject
                                            })
                                            setProductListAttributed([])
                                            setSearchProduct('')
                                          }}
                                          type='button'
                                          style={{ color: '#ff7d52', textDecoration: 'underline' }}>
                                          Nova Pós Venda
                                        </S.ButtonEdit>
                                      </div>
                                    )
                                }                          
                                {
                                  !(sale?.id === createPostSale?.id_sale) &&
                                  userInfoStore.permissions &&
                                  userInfoStore.permissions.includes('customerdetail_edit') &&
                                  checkEditSale(sale?.salePhase?.name, sale) &&
                                  (
                                    sale.id === editingSale.id ?
                                      (
                                        <S.ButtonSalesContainer>
                                          <S.ButtonNew
                                            disabled={notAllowEditSale && !(sale?.product?.category?.id === editSaleCategoryName)}
                                            onClick={() => onSubmitSale(sale)}
                                            type='button'
                                            style={{
                                              color: '#fff',
                                              opacity: notAllowEditSale && !(sale?.product?.category?.id === editSaleCategoryName)
                                                ? '0.6' : '1'
                                            }}
                                          >
                                            Salvar
                                          </S.ButtonNew>
                                          <S.ButtonNew
                                            onClick={() => {
                                              setEditingSale('')
                                              setActiveStock(false)
                                              setNotAllowEditSale(false)
                                              setEditSaleCategoryName('')
                                              setEditSaleProductName('')
                                              checkCategoryStockActive(sale?.product?.category?.id, productList)
                                            }}
                                            type='button'
                                            style={{ color: '#fff' }}
                                          >
                                            Cancelar
                                          </S.ButtonNew>
                                        </S.ButtonSalesContainer>
                                      ) :
                                      (
                                        <div
                                        >
                                          <S.ButtonEdit
                                            onClick={
                                              () => {
                                                setActiveStock(false)
                                                EditingSale(sale)
                                                setEditSaleCategoryName(sale?.product?.category?.id ?? '')
                                              }
                                            }
                                            type='button'
                                            style={{ color: '#ff7d52', textDecoration: 'underline' }}>
                                            Editar
                                          </S.ButtonEdit>
                                        </div>
                                      )
                                  )
                                }
                              </S.BtnsEditSale>
                              {
                                createPostSale.id_sale === sale.id ?
                                  <S.PostSaleContainer>
                                    <S.TitlePostSale>Troca de Produto</S.TitlePostSale>
                                    <div>
                                      <S.MyLabel htmlFor="promoter">
                                        Promotor
                                        <span style={{ color: 'red', fontSize: 16, fontWeight: 'bold' }}>*</span>
                                      </S.MyLabel>
                                      <S.MySelect
                                        onChange={(event) => {
                                          setCreatePostSale({
                                            ...createPostSale, id_user: event.target.value
                                          })
                                          setCreatePostSaleValid({ ...createPostSaleValid, id_user: false })
                                        }
                                        }
                                        id="promoter"
                                        style={{ borderColor: createPostSaleValid.id_user ? 'red' : '#888888' }}
                                      >
                                        <option style={{ textTransform: 'none' }}>Selecione o promotor</option>
                                        {promoters.map((promoter) => {
                                          return (
                                            <option value={promoter.id} key={promoter.id}>{promoter.fullname}</option>
                                          )
                                        })}
                                      </S.MySelect>
                                      <S.MyLabel htmlFor="category_pos">
                                        Categoria
                                        <span style={{ color: 'red', fontSize: 16, fontWeight: 'bold' }}>*</span>
                                      </S.MyLabel>
                                      <S.MySelect
                                        disabled={!createPostSale.id_user}
                                        onChange={(event) => {
                                          setCreatePostSale({
                                            ...createPostSale, id_sku_category: event.target.value
                                          })
                                          setCreatePostSaleValid({ ...createPostSaleValid, id_sku_category: false })
                                         }
                                        }
                                        id="category_pos"
                                        style={{ borderColor: createPostSaleValid.id_sku_category ? 'red' : '#888888' }}
                                      >
                                        <option style={{ textTransform: 'none' }}>Selecione a categoria</option>
                                        {categoryActiveList.map((category, index) => {
                                          return (
                                            <option value={category.id} key={index + category.name}>{category.name}</option>
                                          )
                                        })}
                                      </S.MySelect>                
                                      {SearchProduct()}                    
                                      <S.MyLabel htmlFor="observations">
                                        Observações
                                        <span style={{ color: 'red', fontSize: 16, fontWeight: 'bold' }}>*</span>
                                      </S.MyLabel>
                                      <S.MyTextarea
                                        style={{
                                          width: '100%',
                                          border: '1px solid #888888',
                                          borderColor: createPostSaleValid.contact_reason ? 'red' : '#888888'
                                        }}
                                        id="observations"
                                        onChange={(event) => {
                                          setCreatePostSale({
                                            ...createPostSale, contact_reason: event.target.value
                                          })
                                          setCreatePostSaleValid({ ...createPostSaleValid, contact_reason: false })
                                        }
                                        }
                                        value={createPostSale?.contact_reason ?? ''}
                                      />
                                    </div>
                                    {
                                      customFieldPostSale.length > 0 &&
                                      <>
                                        <S.Hr />
                                        <S.TitlePostSale>Informações Adicionais</S.TitlePostSale>
                                        {
                                          customFieldPostSale.map((value, index) => (
                                            <React.Fragment key={index + 99999}>
                                              {HandleCustomFieldsPostSale(value, index)}
                                            </React.Fragment>
                                          ))
                                        }
                                        <input style={{ visibility: 'hidden' }} ref={inputFilePostSaleRef} type="file" onChange={(event) => uploadImagePostSale(event)} />
                                      </>
                                    }
                                  </S.PostSaleContainer>
                                  :
                                  <>
                                    <div key={index + 1} style={{
                                      width: '100%',
                                      height: 'auto',
                                      display: 'flex',
                                      alignItems: 'flex-start',
                                      flexDirection: 'row',
                                      justifyContent: 'space-between',
                                      padding: '10px 15px',
                                      margin: '0 0 20px'
                                    }}>
                                      <div style={{ width: '35%' }} >
                                        <S.MyLabel htmlFor='id' style={{ fontSize: '11px', fontWeight: 'bold', color: '#777', margin: '0' }}>
                                          ID da Venda
                                        </S.MyLabel>
                                        <S.MyInput placeholder="---" id='id' value={sale?.increment_id} style={{ margin: '0 0 5px' }} />
                                        <S.MyLabel htmlFor="date" style={{ fontSize: '11px', fontWeight: 'bold', color: '#777', margin: '0' }}>
                                          Data da Venda
                                        </S.MyLabel>
                                        <S.MyInput placeholder="---" id="date" value={toNormalizedData(sale?.created_at)} orange style={{ margin: '0 0 5px' }} />
                                        <S.MyLabel htmlFor="lastUpdate" style={{ fontSize: '11px', fontWeight: 'bold', color: '#777', margin: '0' }}>
                                          Última Atualização
                                        </S.MyLabel>
                                        <S.MyInput placeholder="---" id="lastUpdate" value={toNormalizedData(sale?.updated_at)} style={{ margin: '0 0 5px' }} />
                                      </div>
                                      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', width: '65%', borderLeft: '1px solid #E8E8E8', margin: '0', padding: '0 15px' }}>
                                        <div style={{ width: '100%', height: 'auto', display: 'flex', alignItems: 'flex-start', flexDirection: 'column', justifyContent: 'space-between', margin: '0' }}>
                                          {/* <S.MyLabel htmlFor="project" style={{ fontSize: '11px', fontWeight: 'bold', color: '#777', margin: '0' }} >Projeto</S.MyLabel>
                                            <S.MyInput placeholder="---" id="project" value={sale?.project?.name} style={{ margin: '0 0 5px' }} /> */}
                                          <S.MyLabel style={{ fontSize: '11px', fontWeight: 'bold', color: '#777', margin: '0' }}>Categoria</S.MyLabel>
                                          {sale.id !== editingSale.id ? (
                                            <S.MyInput readOnly={true} placeholder="---" id="category" value={sale?.product?.category?.name} style={{ margin: '0 0 5px' }} />
                                          ) :
                                            (
                                              <S.MySelect
                                                onChange={
                                                  (event) => {
                                                    setEditSaleCategoryName(event.target.value)
                                                  }
                                                }
                                                id="code"
                                              >
                                                <option
                                                  style={{ textTransform: 'none' }}
                                                  value={sale?.product?.category?.id}
                                                >
                                                  {sale?.product?.category?.name ?? 'Selecione a categoria'}
                                                </option>
                                                {categoryList.map((category, index) => {
                                                  return (
                                                    sale?.product?.category?.id !== category.id && <option value={category.id} key={index + category.name}>{category.name}</option>
                                                  )
                                                })}
                                              </S.MySelect>
                                            )}

                                          {sale.id !== editingSale.id ? (
                                            <>
                                              <S.MyLabel style={{ fontSize: '11px', fontWeight: 'bold', color: '#777', margin: '0' }}>Nome do Produto</S.MyLabel>
                                              <S.MyInput readOnly={true} placeholder="---" id="category" value={sale?.product?.name ?? '---'} style={{ margin: '0 0 5px' }} />
                                            </>
                                          ) :
                                            (

                                              <>
                                                {
                                                  activeStock && productList?.length > 0 || (sale?.product?.category?.id === editSaleCategoryName && sale?.product?.id) ?
                                                    <>
                                                      <S.MyLabel 
                                                        htmlFor="nameProduct" 
                                                        style={{ 
                                                          fontSize: '11px', 
                                                          fontWeight: 'bold', 
                                                          color: '#777', 
                                                          margin: '0' 
                                                        }}
                                                      >
                                                        Código do Produto
                                                      </S.MyLabel>
                                                      <S.MySelect
                                                        onChange={(event) => {
                                                          const value = event.target.value === 'Selecione o produto' ? '' : event.target.value

                                                          setEditSaleProductName(value)

                                                          if (editSaleCategoryName && value) {
                                                            setNotAllowEditSale(false)
                                                          } else if (!value) {
                                                            setNotAllowEditSale(true)
                                                          }
                                                        }}
                                                        id="code"
                                                      >
                                                        <option
                                                          style={{ textTransform: 'none' }}
                                                          value={sale?.product?.category?.id === editSaleCategoryName ? sale?.product?.id : 'Selecione o produto'}
                                                        >
                                                          {
                                                            (sale?.product?.category?.id === editSaleCategoryName) && sale?.product?.eanCode
                                                              ? sale?.product?.eanCode : 'Selecione o produto'
                                                          }
                                                        </option>
                                                        {productList?.map((product, index) => {
                                                          return (
                                                            <option value={product.id} key={index + product.ean_code}>{product.ean_code ?? 'Selecione o produto'}</option>
                                                          )
                                                        })}
                                                      </S.MySelect>
                                                    </>
                                                    : activeStock ?
                                                      <>
                                                        <S.MyLabel htmlFor="nameProduct" style={{ fontSize: '11px', fontWeight: 'bold', color: '#777', margin: '0' }}>Código do Produto</S.MyLabel>
                                                        <S.MyInput placeholder="---" id="code" value={'Sem produtos atribuídos'} style={{ margin: '0 0 5px' }} />
                                                      </>
                                                      : <></>
                                                }
                                              </>
                                            )}
                                          {
                                            sale.id !== editingSale.id &&
                                            <>
                                              <S.MyLabel style={{ fontSize: '11px', fontWeight: 'bold', color: '#777', margin: '0' }}>Código</S.MyLabel>
                                              <S.MyInput readOnly={true} placeholder="---" id="code" value={sale?.product?.eanCode} style={{ margin: '0 0 5px' }} />
                                            </>
                                          }

                                          <div style={{ marginTop: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', width: '100%' }}>
                                            <S.SalesmanLabel>Vendido por: {CheckStringLength(SalePromotorName(sale.id, 'name'), 20)} </S.SalesmanLabel>
                                            <S.SalesmanType>Cargo: {SalePromotorName(sale.id, 'position')}</S.SalesmanType>
                                            <S.SalesmanType>Supervisor: {
                                                CheckStringLength(SalePromotorName(sale.id, 'supervisor_name'), 25) === '---'
                                                  ? CheckStringLength(SalePromotorName(sale.id, 'supervisor'), 25)
                                                  : CheckStringLength(SalePromotorName(sale.id, 'supervisor_name'), 25)
                                              }
                                            </S.SalesmanType>
                                            <S.SalesmanType>
                                              Grupo: {CheckStringLength((SalePromotorName(sale.id, 'group'))?.name ?? '---', 25)}
                                            </S.SalesmanType>
                                            <S.SalesmanType>
                                              Time: {CheckStringLength((SalePromotorName(sale.id, 'group'))?.team?.name ?? '---', 25)}
                                            </S.SalesmanType>
                                          </div>
                                          {
                                            sale?.salePhase?.id === 6 && sale?.comments &&
                                            <>
                                              <S.MyLabel htmlFor="cod" style={{ fontSize: '11px', fontWeight: 'bold', color: '#777', margin: '0', marginTop: 10 }}>
                                                Código do Produto Devolvido
                                              </S.MyLabel>
                                              <S.SalesmanType style={{ color: 'black', fontSize: '11px'}}>
                                                {sale?.comments.split(':')[1]}
                                              </S.SalesmanType>                                             
                                            </>
                                          }
                                          <S.MyLabel htmlFor="status" style={{ fontSize: '11px', fontWeight: 'bold', color: '#777', margin: '0', marginTop: 5 }}>Status</S.MyLabel>
                                          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                                            <S.StatusTag approved>{getApprove(sale?.salePhase?.name)}</S.StatusTag>
                                            <S.StatusTag>{getStatus(sale?.salePhase?.name)}</S.StatusTag>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <S.InternalWrapper open={true}>
                                      <div 
                                        style={{ 
                                          display: 'flex', 
                                          justifyContent: 'space-between', 
                                          alignItems: 'flex-start', 
                                          width: '100%', 
                                          flexDirection: 'row' 
                                        }}
                                      >
                                        <p style={{ fontSize: '15px', fontWeight: 'bold', color: '#EE5928', margin: '0', marginLeft: 20 }}>
                                          Histórico de Status
                                        </p>
                                        {
                                          sale?.salePhase?.id !== 6 &&
                                          <p 
                                            style={{ fontSize: '15px', fontWeight: 'bold', color: '#EE5928', margin: '0', marginRight: 20, cursor: 'pointer' }}
                                            onClick={() => {
                                              getTagsNewObservation(sale.salePhase.name)
                                              setSaleObservationHistory(sale)
                                              setModalObservationHistory(true)
                                            }}
                                          >
                                            <span style={{ fontSize: '18px', textAlign: 'center' }}>+</span>
                                            Nova Observação
                                          </p>
                                        }
                                      </div>
                                      <StatusHistory sale={sale} />
                                      <input style={{ visibility: 'hidden' }} ref={inputFileRef} type="file" onChange={(event) => uploadImage(event)} />

                                      {sale?.custom_fields?.some(s => s.type === '7') &&
                                        <S.Image>
                                          <div style={{ display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap', alignItems: 'center', width: '96%', flexDirection: 'row', padding: '0px 10px', marginTop: '10px' }}>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', width: '100%', marginBottom: 10 }}>
                                              <h3>Imagens</h3>
                                            </div>
                                            {sale?.custom_fields?.filter(s => s.type === '7').map((value, index, array) => {
                                              return (
                                                value.value && (value.value !== "" && value.value !== null && value.value !== undefined) ?
                                                  <div
                                                    style={{ margin: '0 0 8px 0', borderRadius: '10px', display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}
                                                    key={index}
                                                  >
                                                    <MaskImage
                                                      style={{ marginRight: 5, marginBottom: 10 }}
                                                      onClick={() =>
                                                        sale.id !== editingSale.id ?
                                                          openCarouselModal(array, index)
                                                          :
                                                          uploadNewSaleImage(value.label)
                                                      }
                                                      title={value.label}
                                                      background={checkURL(value.value)}
                                                    >
                                                      <S.UploadIcon editing={sale?.id === editingSale?.id} />
                                                    </MaskImage>
                                                  </div>
                                                : sale?.id === editingSale?.id ?
                                                    <div
                                                      style={{ margin: '0 0 8px 0', borderRadius: '10px', display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}
                                                      key={index}
                                                    > 
                                                      <MaskImage
                                                        style={{ marginRight: 5, marginBottom: 10 }}
                                                        onClick={() =>                                                       
                                                          uploadNewSaleImage(value.label)
                                                        }
                                                        title={value.label}
                                                        background={DefaultImage}
                                                      >
                                                        {/* <S.UploadIcon editing={sale?.id === editingSale?.id} style={{ color: '#ccc' }} /> */}
                                                      </MaskImage>
                                                    </div>       

                                                  : <></>    
                                                  
                                              )
                                            })}
                                          </div>
                                        </S.Image>
                                      }

                                      {
                                        sale?.custom_fields?.some(v => v.type === '8') &&
                                          <S.Image>
                                            <div style={{ display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap', alignItems: 'center', width: '96%', flexDirection: 'row', padding: '0px 10px' }}>
                                              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', width: '100%', marginBottom: 10 }}>
                                                <h3>Arquivos</h3>
                                              </div>
                                              {
                                                sale?.custom_fields?.filter(v => v.type === '8').map((field, index) => {
                                                return (
                                                  field.value &&
                                                    sale.id !== editingSale.id ?
                                                      <S.ButtonActionFile
                                                        key={index}
                                                        type='button'
                                                        title={field.label}
                                                        onClick={() => openLink(field.value)}
                                                      >
                                                        <AttachFileIcon />
                                                      </S.ButtonActionFile>    

                                                    : sale?.id === editingSale?.id && <S.ButtonActionFileUpdate
                                                        key={index}
                                                        type='button'
                                                        title={field.label}
                                                        onClick={() => {
                                                          swal({
                                                            title: field?.value ? "Atenção ao clicar em deletar não será possível recuperar o arquivo!" : `Incluir ${field.label}!`,
                                                            text: field?.value ? 
                                                            `Você pode deletar ou editar o arquivo ${field.label}, escolha com atenção a opção desejada!`
                                                            : `Clique em adicionar para escolher o arquivo.`,
                                                            icon: "info",
                                                            buttons: {
                                                              cancel: {
                                                                text: "Fechar",
                                                                value: "close",
                                                                visible: true,
                                                                closeModal: true,
                                                              },
                                                              roll: {
                                                                text: "Deletar",
                                                                visible: field?.value ? true : false,
                                                                value: "delete",
                                                                closeModal: true,
                                                              },
                                                              confirm: {
                                                                text: field?.value ? "Editar" : "Adicionar",
                                                                value: "update",
                                                                closeModal: true,
                                                              },

                                                            },                                                        
                                                            dangerMode: true,
                                                          })
                                                          .then((value) => {                                                            
                                                            switch (value) {
                                                              case 'close':
                                                                break;
                                                              case 'delete': 
                                                                onSubmitSale(sale, field.label)
                                                                break;
                                                              case 'update': 
                                                                uploadNewSaleImage(field.label)
                                                                break;
                                                            }                                                            
                                                          });                                                        
                                                        }}
                                                        notempty={field?.value ? true : false}                                                      
                                                      >
                                                         <S.UploadIcon 
                                                          title={field.label}
                                                          key={field.label}
                                                          style={{ marginTop: 0 }}
                                                          editing={sale?.id === editingSale?.id} 
                                                        />       
                                                      </S.ButtonActionFileUpdate>    
                                                      
                                                                                         
                                                )
                                              })}
                                            </div>
                                          </S.Image>
                                      }                                      
                                      {sale?.custom_fields?.some(s => s.type === '11') &&
                                        <S.Image>
                                          <div style={{ 
                                              display: 'flex', 
                                              justifyContent: 'flex-start', 
                                              flexWrap: 'wrap', 
                                              alignItems: 'center', 
                                              width: '96%', 
                                              flexDirection: 'row', 
                                              padding: '0px 10px', 
                                              marginTop: '10px' 
                                            }}
                                          > 
                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', width: '100%', marginBottom: 10 }}>
                                                <h3>Rolo de Fotos</h3>
                                            </div>      
                                            {sale?.custom_fields?.filter(s => s.type === '11').map((field, index) => {
                                              return (
                                                field.value &&
                                                <div
                                                  style={{ 
                                                    margin: '0 0 8px 0', 
                                                    borderRadius: '10px', 
                                                    display: 'flex', 
                                                    flexDirection: 'row', 
                                                    flexWrap: 'wrap' 
                                                  }}
                                                  key={index}
                                                >                                                                                         
                                                  { 
                                                    field?.value && JSON.parse(field?.value).map((value, index, array) =>
                                                          <MaskImage
                                                            key={index+100}
                                                            style={{ marginRight: 5, marginBottom: 10 }}                                                        
                                                            onClick={
                                                              () => {
                                                                if (sale.id !== editingSale.id) {
                                                                  openCarouselModal((array.map((val, index) => {
                                                                    return { value: checkValue(val), label: index }
                                                                  })), index)
                                                                } else {
                                                                  setIndexPhotoRoll(index)
                                                                  uploadNewSaleImage(field.label)
                                                                }
                                                              }
                                                            }
                                                            title={field.label}
                                                            background={checkURL(value)}
                                                          >
                                                            <S.UploadIcon editing={sale?.id === editingSale?.id} />
                                                          </MaskImage>                                                         
                                                      )
                                                  }
                                                </div>
                                              )
                                            })}
                                          </div>
                                        </S.Image>
                                      }
                                      {saleCustomFields(sale)}
                                      {/* {
                                        sale?.custom_fields?.some(s => s.type !== '8' && s.type !== '7' && s.type !== '11') &&
                                        <S.Informations>
                                          <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', width: '100%' }}>
                                            <p style={{ fontSize: '13px', fontWeight: 'bold', color: '#EE5928', margin: '0' }}>Informações adicionais</p>
                                          </div>
                                          <div style={{ width: '100%', height: 'auto', display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '10px' }}>
                                            {sale?.custom_fields?.filter(s => s.type !== '8' && s.type !== '7' && s.type !== '11').map((field, index) => {
                                              return (
                                                <div style={{ display: 'flex', alignItems: 'baseline' }} key={index}>
                                                  <S.MyLabel htmlFor={field.label}>{field.label}</S.MyLabel>
                                                  <S.MyInput placeholder="---" isEditing={sale.id === editingSale.id} onChange={(event) => handleChangeCustomSaleValues(event.target.value, field.label)} id="example" readOnly={!sale.id === editingSale.id} value={field.value} />
                                                </div>
                                              )
                                            })}
                                          </div>
                                        </S.Informations>
                                      } */}

                                      {
                                        !(sale.id === editingSale.id) &&
                                          userInfoStore.permissions &&
                                          userInfoStore.permissions.includes('customerdetail_edit')
                                          ? ((checkSaleCancelButton(sale?.salePhase?.name) || checkSalePendButton(sale?.salePhase?.name) || checkSaleApproveButton(sale?.salePhase?.name) || checkSaleRevertButton(sale?.salePhase?.name, sale)) &&
                                            <>
                                              <S.Buttons>
                                                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', width: '100%', margin: '0 0 15px' }}>
                                                  <p style={{ fontSize: '13px', fontWeight: 'bold', color: '#EE5928', margin: '0' }}>Ações de {getApprove(sale?.salePhase?.name)}</p>
                                                </div>
                                                <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', width: '100%', flexDirection: 'row' }}>
                                                  {checkSaleCancelButton(sale?.salePhase?.name) &&
                                                    <S.ButtonAction type='button' onClick={() => openCancel(sale)}>
                                                      <BlockIcon style={{ fontSize: '16px', margin: '0 5px 0 0' }} />
                                                      Cancelar
                                                    </S.ButtonAction>}
                                                  {checkSaleRevertButton(sale?.salePhase?.name, sale) &&
                                                    <S.ButtonAction type='button' onClick={() => openRevert(sale)}>
                                                      <RestoreIcon style={{ fontSize: '16px', margin: '0 5px 0 0' }} />
                                                      Reverter
                                                    </S.ButtonAction>}
                                                  {checkSalePendButton(sale?.salePhase?.name) &&
                                                    <S.ButtonAction type='button' onClick={() => openPend(sale)}>
                                                      <FaceIcon style={{ fontSize: '16px', margin: '0 5px 0 0' }} />
                                                      Pendenciar
                                                    </S.ButtonAction>}
                                                  {checkSaleApproveButton(sale?.salePhase?.name) &&
                                                    <S.ButtonAction type='button' onClick={() => openConfirm(sale)}>
                                                      <CheckCircleOutline style={{ fontSize: '16px', margin: '0 5px 0 0' }} />
                                                      {getSalesButtonName(sale?.salePhase?.name)}
                                                    </S.ButtonAction>}
                                                </div>
                                              </S.Buttons>
                                            </>)
                                          : null
                                      }

                                    </S.InternalWrapper>
                                  </>
                              }
                            </S.MyCardButton>
                          )
                        })}
                      </>}

                    {buttons === 'afterSales' &&
                      afterSales.map((e, index) =>
                        <S.MyCardButton key={index}>
                            <S.ContentPostSale>
                                <S.ContentPostSaleDate>     
                                  <div>
                                    <div>
                                        {
                                          GetIdSale(e)
                                        }
                                    </div>                                   
                                  </div>                            
                                  <div 
                                    style={{ cursor: 'pointer', borderLeft: '1px solid #ddd', paddingLeft: '15px' }}
                                    onClick={() => goToEditUserPage(e?.promotor?.id)} 
                                  >
                                    <S.MyLabel
                                      style={{ cursor: 'pointer'}}
                                      htmlFor="user"
                                    >
                                      Pós Venda Realizada por
                                    </S.MyLabel>
                                    <S.MyInput 
                                      disabled={true} 
                                      placeholder="---" 
                                      id="user" 
                                      value={e?.promotor?.name?.toLowerCase() ?? '---'} 
                                      orange 
                                      style={{ cursor: 'pointer', textTransform: 'capitalize' }}
                                    />
                                    <div>
                                      <S.MyLabel htmlFor="date">Data da Pós Venda</S.MyLabel>
                                      <S.MyInput placeholder="---" id="date" value={e?.created_at ? toNormalizedData(e.created_at) : '---'} orange />
                                      {/* <S.MyLabel htmlFor="lastUpdate">Última Atualização</S.MyLabel>
                                      <S.MyInput placeholder="---" id="lastUpdate" value={e?.updated_at ? toNormalizedData(e.updated_at) : '---'} /> */}
                                    </div>
                                  </div>
                                </S.ContentPostSaleDate>
                                <div style={{ borderBottom: '1px solid #E8E8E8', marginTop: '25px' }}></div>                                

                                <S.MyLabel htmlFor="contact" style={{ marginTop: '1px'}}>Tipo do contato</S.MyLabel>
                                <S.MyInput placeholder="---" id="contact" value={e?.contactType?.name} />

                                {e?.productExchange?.id &&
                                  <>
                                    <S.MyLabel style={{ marginTop: '20px' }} htmlFor="code1">Categoria do Produto Substituido</S.MyLabel>
                                    <S.MyInput placeholder="---" id="code1" value={e?.productExchange?.exchanged_product?.category?.name} />
                                    <S.MyLabel htmlFor="code2">Código do Produto Substituido</S.MyLabel>
                                    <S.MyInput placeholder="---" id="code2" value={e?.productExchange?.exchanged_product?.eanCode || e?.productExchange?.exchange_product?.eanCode} />

                                    <S.MyLabel style={{ marginTop: '20px' }} htmlFor="code3">Categoria do Produto Instalado</S.MyLabel>
                                    <S.MyInput placeholder="---" id="code3" value={e?.productExchange?.installed_product?.category?.name} />
                                    <S.MyLabel htmlFor="code4">Código do Produto Instalado</S.MyLabel>
                                    <S.MyInput placeholder="---" id="code4" value={e?.productExchange?.installed_product?.eanCode} orange/>
                                  </>
                                }

                                <S.MyLabel htmlFor="observations" style={{ marginTop: '15px'}}>Observações</S.MyLabel>
                                <S.MyTextarea disabled={true} style={{ width: '100%', border: '1px solid #ddd' }} id="observations" value={e.contact_reason} />                    
                                
                                <S.MyCardPostSale>
                                  <CustomInfo
                                    readOnly={true}
                                    customFieldValues={e.custom_fields}
                                    handleChangeCustom={() => { }}
                                    isEditingCustom={false}
                                    openCarouselModal={openCarouselModal}
                                  />
                                </S.MyCardPostSale>
                            </S.ContentPostSale>
                        </S.MyCardButton>)
                    }
                    {buttons === 'phases' &&
                      (<>
                        {histories.map((hist, index) => {
                          return (
                            <S.MyCardButton 
                              key={index + 100000}
                            >
                              <S.ContentRegisters>  
                                <S.ContentRegistersPromotor
                                  onClick={() => goToEditUserPage(hist.promoter.id)}
                                >
                                  <MaskPhoto 
                                    background={`${hist?.user?.file?.uri ?? iconUser}`}
                                  />
                                  <S.ContentRegistersPromotorContent>
                                    <S.MyInput 
                                      disabled={true}
                                      placeholder="---" 
                                      style={{ fontSize: '13px', textTransform: 'capitalize' }} 
                                      id="promoter_name" value={hist?.promoter?.name?.toLowerCase()}
                                    />
                                    <S.MyInput disabled={true} placeholder="---" style={{ color: '#A9A9A9', textTransform: 'capitalize' }} id="position" value={`Cargo: ${hist?.promoter?.position?.toLowerCase() ?? '--'}`} />
                                    <S.MyInput disabled={true} placeholder="---" style={{ color: '#A9A9A9', textTransform: 'capitalize' }} id="supervisor" 
                                      value={`Supervisor: ${hist?.promoter?.supervisor_name?.toLowerCase()|| hist?.promoter?.supervisor?.toLowerCase() || '---'}`}                                       
                                    />
                                    <S.MyInput disabled={true} placeholder="---" style={{ color: '#A9A9A9', textTransform: 'capitalize' }} id="group" value={`Grupo: ${hist?.promoter?.group?.name?.toLowerCase() ?? '--'}`} />
                                    <S.MyInput disabled={true} placeholder="---" style={{ color: '#A9A9A9', textTransform: 'capitalize' }} id="team" value={`Time: ${hist?.promoter?.group?.team?.name?.toLowerCase() ?? '--'}`} />
                                  </S.ContentRegistersPromotorContent>
                                  <S.TypePhase tagValue={hist?.type}>{hist?.type}</S.TypePhase>
                                </S.ContentRegistersPromotor>
                                <S.RegisterDate>
                                  <S.MyInput 
                                    placeholder="---" 
                                    style={{ color: '#A9A9A9', fontSize: '11px'}} 
                                    id="hist_date" 
                                    value={`Data do Servidor: ${toNormalizedData(hist?.created_at)}`} 
                                    disabled={true}
                                  />
                                  <S.RegisterDateLine />
                                  <S.MyInput 
                                    placeholder="---" 
                                    style={{ color: '#A9A9A9', fontSize: '11px' }} 
                                    id="hist_date_mobile" 
                                    value={`Data do Celular: ${toNormalizedData(hist?.mobile_phone_created_at)}`} 
                                    disabled={true}
                                  />
                                </S.RegisterDate>
                              </S.ContentRegisters> 
                            </S.MyCardButton>
                          )
                        })}
                      </>
                      )
                    }
                    {
                      buttons === 'access' && <div id="access" style={{ width: '100%' }}>
                        {sales?.length > 0 && SaleExternalData()}
                        {userProjectStore?.digital_document_token && ZapSign()}
                      </div>
                    }
                  </S.record>
                </S.AccordionWrapper>
              </S.CardBody>
              <S.ScheduleContainer>              
                  <div style={{ width: '100%', height: 'auto', display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between', margin: '10px 0 10px' }}>
                    <h3 style={{ marginLeft: '5px'}}>Agendamentos</h3>
                    {
                      userInfoStore.permissions &&
                        userInfoStore.permissions.includes('schedule_create')
                        ? <S.ButtonNew 
                            type="button" 
                            onClick={handleClickNewSchedule}
                            style={{ width: '125px' }}
                          >
                            + Novo Agendamento
                          </S.ButtonNew>
                        : null
                    }
                  </div>
                  {Schedules()}                      
              </S.ScheduleContainer>
            </S.sales>       
          </S.Container>
        </S.Form>
      )}
    </>
  )
}
