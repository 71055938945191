import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Tags from "@yaireo/tagify/dist/react.tagify"; // React-wrapper file
import "./styles.css";
import swal from 'sweetalert';
import api from "../../services/api";

import iconProjects from "../../assets/icons/icon_projetos.svg";

function ProjectsList() {
  const [projectsList, setProjectsList] = useState([]);
  const [project, setProject] = useState();
  const [editProject, setEditProject] = useState(false);
  const [tagTypes, setTagTypes] = useState([]);
  const [selectedTag, setSelectedTag] = useState("1");
  const [tags, setTags] = useState([]);
  const [canSaveTags, setCanSaveTags] = useState(false);
  const [docActionType, setDocActionType] = useState([]);

  const projectRef = useRef();
  const selectedTagRef = useRef();
  const CanSaveTagsRef = useRef();
  
  projectRef.current = project;
  selectedTagRef.current = selectedTag;
  CanSaveTagsRef.current = canSaveTags;

  const docRepeatedActionTypes = async () => {
    try {
      const response = await api.get("/repeatedActionTypes")

      if (response.status === 200) {
        setDocActionType(response.data)
      }

    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getTagTypes();

    api
      .get("/projects")
      .then((response) => {
        if (response?.data) {
          setProjectsList(response?.data);
          if (response?.data.length > 0) {
            setProject(response?.data[0]);
            getTags(1);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });

    docRepeatedActionTypes();

  }, []);

  useEffect(() => {
    if (project) {
      getTags(1);
    }
  }, [project]);

  const addTag = (name) => {
    if (CanSaveTagsRef.current) {
      api.post("/tag/add", {
        name,
        id_tag_type: selectedTagRef.current,
        id_project: projectRef.current.id,
      });
    }
  };
  const removeTag = (name) => {
    if (CanSaveTagsRef.current) {
      api.post("/tag/remove", {
        name,
        id_tag_type: selectedTagRef.current,
        id_project: projectRef.current.id,
      });     
    }
  };

  function getTags(selectedTagRef, projectRef) {
    setCanSaveTags(false);
    
    api
      .post("/tag", {
        id_project: projectRef ? projectRef : project.id,
        id_tag_type: selectedTagRef,
      })
      .then((response) => {
        setTags(response?.data.map((elm) => ({ value: elm.name, id: elm.id })));
        setTimeout(() => setCanSaveTags(true), 500);
      });
  }

  function getTagTypes() {
    api.get("/tagTypes").then((response) => {
      setTagTypes(response?.data);
    });
  }

  function updateList() {
    api
      .get("/projects")
      .then((response) => {
        if (response?.data) {
          setProjectsList(response?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function getStatusItem(status) {
    if (status === "green") {
      return true;
    } else {
      return false;
    }
  }

  function isURLValid(url) {
    const regex = /^(https?|http):\/\/[^\s/$.?#].[^\s]*$/i;

    return regex.test(url);
  }

  async function handleSubmit() {
    let params = {
      name: project?.name,
      color: project?.color,
      description: project?.description,
      id_template_email: project?.id_template_email,
      audit: project?.audit,
      default_pendency_sms: project?.default_pendency_sms,
      default_pendency_email: project?.default_pendency_email,
      max_sales: Number(project?.max_sales),
      postsale_approval: project?.postsale_approval,
      doc_repeated_action: project?.doc_repeated_action,
      offer01: project?.offer01 ?? false,
      management_approval: project?.management_approval ?? false,
      external_dashboard: project?.external_dashboard,
    };

    if (params?.external_dashboard && !isURLValid(params.external_dashboard)) {
      swal(`Informe uma url válida para a dashboard externa!`, {
        icon: "warning",
        button: "Fechar"
      });

      return
    }

    api
      .put("/projects/" + project?.id, params)
      .then((response) => {
        if (response?.data) {
          swal(`Informações atualizadas com sucesso!`, {
            icon: "success",
            button: "Fechar"
          });
          setEditProject(false);         
          
          updateList();

          getTags(selectedTag)
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const DescriptionActionType = () => {
    const op = docActionType.find(elm => elm.id === project?.doc_repeated_action) 

    return <>
      <label className="projectInfoLabel" style={{ marginTop: 20 }}>Descrição Venda duplicada:</label>
      <p style={{ fontSize: 14}}>{op?.description ?? ''}</p>
    </>
  }

  const CheckStringLength = (value, _length) => {
    return value.length < _length ? value : value.substring(0, _length - 3) + '...'
  }  

  return (
    <div className="projectList">
      <div className="newProjectContainer">
        <Link className="newProjectButton" to="/ProjectsRegister">
          <p>Novo projeto</p>
          <img src={iconProjects} alt="projectIcon" />
        </Link>
      </div>

      <div id="projectTitleRow">
        <div id="projectListTitle" className="projectDivTitle">
          <label>Projetos</label>
        </div>
        <div id="projectInfoTitle" className="projectDivTitle">
          <label>Projeto atual</label>
        </div>
      </div>

      <div className="projectDivList">
        <div className="projectListContainer">
          {
            projectsList
              .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
              .map((item, index) => {
              return (
                <button
                  key={index}
                  className={`projectContainer ${
                    item?.id === project?.id ? "projectSelected" : ""
                  }`}
                  onClick={() => {
                    setCanSaveTags(false);
                    setProject(item);
                    setSelectedTag(1);
                    getTags(1, item.id);
                  }}
                >
                  <div className="projectName">
                    <label>{item.name}</label>
                  </div>
                  <div
                    className={`projectStatus ${
                      getStatusItem(item?.color) ? "projectAtive" : ""
                    }`}
                  />
                </button>
              );
          })}
        </div>
        <div className="projectInfoContainer">
          <div id="projectInfo">
            <div id="projectInfoCard">
              {editProject ? (
                <>
                  <div>
                    <label className="projectInfoLabel">Nome do projeto</label>
                  </div>
                  <div id="projectInfoTitleRow" className="projectInfoRow">
                    <input
                      id="projectInfoName"
                      className="inputRounded"
                      onChange={(event) => {
                        setProject({ ...project, name: event.target.value });
                      }}
                      value={project?.name}
                    />
                    <div className="projectInfoRow" style={{ marginLeft: 5 }}>
                      <label id="projectInfoStatusLabel">Status</label>
                      <select
                        id="projectInfoStatus"
                        className="inputRounded"
                        value={project?.color}
                        onChange={(event) => {
                          setProject({ ...project, color: event.target.value });
                        }}
                      >
                        <option value="green">Ativo</option>
                        <option value="gray">Inativo</option>
                      </select>
                    </div>
                  </div>
                  <div className="projectInfoInputContainer">
                    <label className="projectInfoLabel">
                      Descrição do projeto
                    </label>
                    <textarea
                      className="inputRounded"
                      value={project?.description}
                      onChange={(event) => {
                        setProject({
                          ...project,
                          description: event.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="projectInfoInputContainer">
                    <label className="projectInfoLabel">
                      Código template de e-mail
                    </label>
                    <input
                      style={{ color: '#1C1C1C' }}
                      className="inputRounded"
                      onChange={(event) => {
                        setProject({
                          ...project,
                          id_template_email: event.target.value,
                        });
                      }}
                      value={project?.id_template_email}
                    />
                  </div>
                  <div className="projectInfoInputContainer">
                    <label className="projectInfoLabel">
                      Dashboard Externa
                    </label>
                    <input
                      style={{ color: '#1C1C1C' }}
                      className="inputRounded"
                      onChange={(event) => {
                        setProject({
                          ...project,
                          external_dashboard: event.target.value,
                        });                        
                      }}
                      value={project?.external_dashboard}
                    />
                  </div>
                  <div className="projectInfoInputContainer">
                    <label className="projectInfoLabel">
                      Necessário auditoria
                    </label>
                    <input
                      type="checkbox"
                      className="inputRoundedCheckBox"
                      onClick={() =>                     
                          setProject({ ...project, audit: !project.audit })                       
                      }
                      checked={project.audit}
                    />
                    <label className="projectInfoLabel" style={{ marginTop: 13 }}>
                      Necessário Aprovação Gerencial
                    </label>
                    <input
                      type="checkbox"
                      className="inputRoundedCheckBox"
                      onClick={() => {
                        setProject({ ...project,  management_approval: !project.management_approval })
                      }}
                      checked={project.management_approval}
                    />                  
                    <label className="projectInfoLabel">
                         Pendências
                    </label>
                    <div className="container_pendency">                       
                        <div className="pendency_item">                          
                          <input
                            type="checkbox"
                            className="inputRoundedCheckBox"
                            onClick={() =>                            
                                setProject({ ...project, default_pendency_email: !project.default_pendency_email })                              
                            }
                            checked={project.default_pendency_email}
                          />
                          <label>E-mail</label>
                        </div>
                        <div className="pendency_item">                        
                          <input
                            type="checkbox"
                            className="inputRoundedCheckBox"
                            onClick={() =>                             
                                setProject({ ...project, default_pendency_sms: !project.default_pendency_sms })                              
                            }
                            checked={project.default_pendency_sms}
                          />
                          <label>Sms</label>
                        </div>                                
                    </div>
                    <label className="projectInfoLabel">
                         Permitir
                    </label>
                    <div className="container_pendency">                       
                        <div className="pendency_item">                          
                          <input
                            type="checkbox"
                            className="inputRoundedCheckBox"
                            onClick={() =>                            
                                setProject({ ...project, default_pendency_cpf: !project.default_pendency_cpf })                              
                            }
                            checked={project.default_pendency_cpf}
                          />
                          <label>CPF</label>
                        </div>
                        <div className="pendency_item">                        
                          <input
                            type="checkbox"
                            className="inputRoundedCheckBox"
                            onClick={() =>                             
                                setProject({ ...project, default_pendency_cnpj: !project.default_pendency_cnpj })                              
                            }
                            checked={project.default_pendency_cnpj}
                          />
                          <label>CNPJ</label>
                        </div>                                
                    </div>                  
                    <label className="projectInfoLabel" style={{ marginTop: 13 }}>
                        Aprovação Automática de Acompanhamento
                    </label>
                    <input
                        type="checkbox"
                        className="inputRoundedCheckBox"
                        onClick={() =>                             
                            setProject({ ...project,  postsale_approval: !project.postsale_approval })                              
                        }
                        checked={project.postsale_approval}
                    />
                    <label className="projectInfoLabel" style={{ marginTop: 13 }}>
                         Máximo de Vendas
                    </label>
                    <input
                      className="inputRounded widthInputRounded"
                      onChange={(event) => {
                        setProject({
                          ...project,
                          max_sales: event.target.value,
                        });
                      }}
                      value={project?.max_sales}
                      type="number"
                      min={1}
                      max={10}
                    />
                    <label className="projectInfoLabel">
                      Ofertar Futuro Previdência
                    </label>
                    <input
                      type="checkbox"
                      className="inputRoundedCheckBox"
                      onClick={() =>                     
                          setProject({ ...project, offer01: !project.offer01 })                       
                      }
                      checked={project.offer01}
                    />
                  </div>
                  <div className="projectInfoInputContainer" style={{ marginTop: 10 }}>
                    <label className="projectInfoLabel">Tags:</label>
                    <select
                      className="inputRounded"
                      value={selectedTag}
                      onChange={(elm) => {
                        setCanSaveTags(false);
                        setSelectedTag(elm.target.value);
                        getTags(elm.target.value);
                      }}
                    >
                      {tagTypes && tagTypes.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())).map((elm, index) => (
                        <option value={elm.id} label={elm.name} key={elm.id} />
                      ))}
                    </select>
                    <Tags
                      onRemove={(e) => {
                        removeTag(e.detail.data.value);
                      }}
                      onAdd={(e) => {
                        addTag(e.detail.data.value);
                      }}
                      value={tags}
                      className="Tags"
                    />
                  </div>
                  <div className="projectInfoInputContainer">
                    <label className="projectInfoLabel">Vendas Duplicadas:</label>
                    <select
                      className="inputRounded"
                      onChange={(event) => {
                        setProject({
                          ...project,
                          doc_repeated_action: Number(event.target.value) === 0 ? null : Number(event.target.value)
                        });
                      }}
                      value={project?.doc_repeated_action ?? 0}
                    >
                      <option value={0}>Sem ação de venda</option>
                      {docActionType.map((elm) => (
                        <option 
                          value={elm.id} 
                          label={elm.name} 
                          key={elm.id}
                        />
                      ))}
                    </select>                    
                    <DescriptionActionType />    
                  </div>
                </>
              ) : (
                <>
                  <div id="projectInfoTitleRow" className="projectInfoRow">
                    <label id="projectInfoNameDisabled">{project?.name}</label>
                    <div className="projectInfoRow">
                      <label id="projectInfoStatusLabel">Status</label>
                      <label
                        id="projectInfoStatus"
                        className={`${
                          getStatusItem(project?.color)
                            ? "projectStatusAtive"
                            : ""
                        }`}
                      >
                        {getStatusItem(project?.color) ? "Ativo" : "Inativo"}
                      </label>
                    </div>
                  </div>
                  <div className="projectInfoInputContainer">
                    <label className="projectInfoLabel">
                      Descrição do projeto:
                    </label>
                    <p>
                      {project?.description ? project?.description : ""}
                    </p>
                  </div>
                  <div className="projectInfoInputContainer">
                    <label className="projectInfoLabel">
                      Código template de e-mail:
                    </label>
                    <p>
                      {project?.id_template_email}
                    </p>
                  </div>
                  <div className="projectInfoInputContainer">
                    <label className="projectInfoLabel">
                      Dashboard Externa:
                    </label>
                    <p>
                      {project?.external_dashboard ? CheckStringLength(project?.external_dashboard, 65) :'Link não informado'}
                    </p>
                  </div>
                  <div className="projectInfoInputContainer">
                    <label className="projectInfoLabel">
                      Necessário auditoria:
                    </label>
                    <p style={{ marginTop: 5, fontSize: 15 }}>
                      {project?.audit ? "Sim" : "Não"}
                    </p>
                  </div>
                  <div className="projectInfoInputContainer">
                    <label className="projectInfoLabel">
                      Necessário Aprovação Gerencial
                    </label>
                    <p style={{ fontSize: 15}}>{project?.management_approval ? "Sim" : "Não"}</p>
                  </div>
                  <div className="projectInfoInputContainer">
                    <label className="projectInfoLabel">
                      Pendências:
                    </label>
                    <div className="projectInfoInputContainerPendency">
                      <p>
                        <span>E-mail:</span> {project?.default_pendency_email ? "Sim" : "Não"}
                      </p>
                      <p>
                        <span>Sms:</span> {project?.default_pendency_sms ? "Sim" : "Não"}
                      </p>
                    </div>
                    <label className="projectInfoLabel">
                      Permitir:
                    </label>
                    <div className="projectInfoInputContainerPendency">
                      <p>
                        <span>CPF:</span> {project?.default_pendency_cpf ? "Sim" : "Não"}
                      </p>
                      <p>
                        <span>CNPJ:</span> {project?.default_pendency_cnpj ? "Sim" : "Não"}
                      </p>
                    </div>
                  </div>
                  <div className="projectInfoInputContainer">
                    <label className="projectInfoLabel">
                      Aprovação Automática de Acompanhamento:
                    </label>
                    <p style={{ fontSize: 15}}>{project?.postsale_approval ? "Sim" : "Não"}</p>
                  </div>                 
                  <div className="projectInfoInputContainer">
                    <label className="projectInfoLabel">
                      Máximo de Vendas:
                    </label>
                    <p style={{ fontSize: 15}}>{project?.max_sales}</p>
                  </div>
                  <div className="projectInfoInputContainer">
                    <label className="projectInfoLabel">
                      Ofertar Futuro Previdência:
                    </label>
                    <p style={{ marginTop: 5, fontSize: 15 }}>
                      {project?.offer01 ? "Sim" : "Não"}
                    </p>
                  </div>
                  <div className="projectInfoInputContainer">
                    <label className="projectInfoLabel">Tags:</label>
                    <select
                      disabled={!editProject}
                      className="inputRounded"
                      value={selectedTag}
                      onChange={(elm) => {
                        setCanSaveTags(false);
                        setSelectedTag(elm.target.value);
                        getTags(elm.target.value);
                      }}
                    >
                      {tagTypes && tagTypes.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())).map((elm, index) => (
                        <option value={elm.id} label={elm.name} key={elm.id} />
                      ))}
                    </select>
                    <Tags
                      readOnly={!editProject}
                      onRemove={(e) => {
                        removeTag(e.detail.data.value);
                      }}
                      onAdd={(e) => {
                        addTag(e.detail.data.value);
                      }}
                      value={tags}
                      className="Tags"
                    />
                  </div>
                  <div className="projectInfoInputContainer">
                    <label className="projectInfoLabel">Vendas Duplicadas:</label>
                    <select
                      disabled={!editProject}
                      className="inputRounded"
                      onChange={(event) => {
                        setProject({
                          ...project,
                          doc_repeated_action: Number(event.target.value) === 0 ? null : Number(event.target.value)
                        });
                      }}
                      value={project?.doc_repeated_action ?? 0}
                    > 
                      <option value={0}>Sem ação de venda</option>
                      {docActionType.map((elm) => (
                        <option 
                          value={elm.id} 
                          label={elm.name} 
                          key={elm.id} 
                        />
                      ))}
                    </select>                    
                    <DescriptionActionType />                    
                  </div>
                </>
              )}
            </div>
          </div>
          <div id="projectInfoButton">
            {editProject ? (              
                <div className="contentBtnAlteracoes">
                    <button
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                      Salvar alterações
                    </button>
                    <button
                      onClick={() => {
                        setEditProject(false)
                      }}
                    >
                      Cancelar alterações
                    </button>  
                </div>
            ) : (
              <button
                onClick={() => {
                  setEditProject(true);
                }}
              >
                Editar informações
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectsList;
