/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext, useCallback } from "react";
import StoreContext, { ScrollContext } from "../../context/Context";
import { Link } from "react-router-dom";
import api from "../../services/api";
import swal from "sweetalert";

import CustomerList from "../../components/CustomerList";
import DeleteIcon from "@material-ui/icons/Delete";
import SearchClients from "../../components/SearchClients";
import LoaderSpin from "../../components/LoaderSpin";

import "./styles.css";

function CustomerSalesList(props) {
  const { userProject } = props;
  const { userInfoStore } = useContext(StoreContext);
  const { onScroll } = useContext(ScrollContext);

  const [customerList, setCustomerList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");
  const [loadingQuery, setLoadingQuery] = useState(false);
  const [tags, setTags] = useState([]);
  const [availableGroups, setAvailableGroups] = useState([]);
  const [availableTeams, setAvailableTeams] = useState([]);
  const [availablePromotors, setAvailablePromotors] = useState([]);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);
  const [filterName, setFilterName] = useState("");

  const customerSaleFilterStorage =
    localStorage.getItem("customerSaleFilter") ||
    sessionStorage.getItem("customerSaleFilter");
  const savedCustomerSaleFiltersInStorage = JSON.parse(
    customerSaleFilterStorage
  ) ?? { id_project: props.userProject.id };
  savedCustomerSaleFiltersInStorage.id_project = props.userProject.id;

  const [queryParameters, setQueryParameters] = useState(
    savedCustomerSaleFiltersInStorage
  );

  const savedSearch = queryParameters?.search_fantasy_name ?? "";
  const [search, setSearch] = useState(savedSearch);

  const savedFiltersInStorage = localStorage.getItem("savedFilters");
  const [filterState, setStateFilter] = useState(savedFiltersInStorage);

  const mapPhaseToName = {
    1: "visit",
    2: "contact",
    3: "register",
    4: "sell",
  };

  const mapPhaseToValue = {
    visit: 1,
    contact: 2,
    register: 3,
    sell: 4,
  };

  const mapStatusToName = {
    1: "Aprovação | Aguardando",
    2: "Aprovação | Pendente",
    4: "Acompanhamento | Ativo",
    5: "Acompanhamento | Pendente",
    6: "Aprovação | Cancelado",
  };
  const mapStatusToValue = {
    "Aprovação | Aguardando": 1,
    "Aprovação | Pendente": 2,
    "Acompanhamento | Ativo": 4,
    "Acompanhamento | Pendente": 5,
    "Aprovação | Cancelado": 6,
  };

  const [statusFilter, setStatusFilter] = useState(
    mapStatusToName[queryParameters?.status] ?? ""
  );
  const [phaseFilter, setPhaseFilter] = useState(
    mapPhaseToName[queryParameters?.phase] ?? ""
  );
  const [initialDateFilter, setInitialDateFilter] = useState(
    queryParameters?.initDate ?? ""
  );
  const [finalDateFilter, setFinalDateFilter] = useState(
    queryParameters?.finalDate ?? ""
  );
  const [tagName, setTagName] = useState(queryParameters?.tag ?? "");
  const [groups, setGroups] = useState(queryParameters?.group_id ?? "");
  const [teams, setTeams] = useState(queryParameters?.team_id ?? "");
  const [promotors, setPromotors] = useState(
    queryParameters?.promotor_id ?? ""
  );

  const getData = useCallback(async () => {
    const types = [1, 3, 4, 5, 7, 8];

    const groups = (
      await api.get("/groupSections?id_project=" + userProject.id)
    ).data;
    const promotores = (
      await api.get(`/promotors?id_project=` + userProject.id)
    ).data;

    const project_tags = (
      await api.post(`/tag?id_project=` + userProject.id, {
        id_tag_type: types,
      })
    ).data;

    if (project_tags.length > 0) {
      setTags(
        project_tags.sort((a, b) =>
          a.name.toLowerCase().localeCompare(b.name.toLowerCase())
        )
      );
    }

    setAvailableGroups(
      groups.sort((a, b) => a.section.localeCompare(b.section))
    );
    setAvailablePromotors(
      promotores.sort((a, b) =>
        a.fullname.toLowerCase().localeCompare(b.fullname.toLowerCase())
      )
    );
  }, [userProject.id]);

  useEffect(() => {
    getData();
  }, [getData]);

  const getTeams = useCallback(async () => {
    if (queryParameters.group_id) {
      const firstTeams = (
        await api.get("/groups", {
          params: {
            id_project: userProject.id,
            section_id: queryParameters.group_id,
          },
        })
      ).data;
      setAvailableTeams(
        firstTeams.sort((a, b) =>
          a.name.toLowerCase().localeCompare(b.name.toLowerCase())
        )
      );
    }
  }, [queryParameters?.group_id, userProject.id]);

  useEffect(() => {
    getTeams();
  }, [getTeams]);

  useEffect(() => {
    if (
      onScroll?.target.scrollTop /
        (onScroll?.target.scrollHeight - onScroll?.target.clientHeight) >
      0.8
    ) {
      if (!loadingQuery) {
        autoSrollQuery();
      }
    }
  }, [onScroll]);

  // const filter = async (filter) => {

  //   if (queryParameters.search_fantasy_name)
  //     filter.search_fantasy_name = queryParameters.search_fantasy_name;

  //   setQueryParameters({ ...filter, id_project: props.userProject.id })

  //   setPage(1)
  // }

  const queryParametersChangeStatus = (statusFilter) => {
    const status = mapStatusToValue[statusFilter];

    if (status === queryParameters?.status) {
      const params = { ...queryParameters };

      delete params.status;

      setStatusFilter("");

      setQueryParameters(params);
    } else {
      setStatusFilter(statusFilter);
      setQueryParameters({ ...queryParameters, status });
    }
  };

  const queryParametersChangePhase = (phaseFilter) => {
    const phase = mapPhaseToValue[phaseFilter];

    const params = { ...queryParameters };

    if (phase === queryParameters?.phase) {
      delete params.phase;

      setPhaseFilter("");

      if (phase === 4) delete params.status;

      setQueryParameters(params);
    } else {
      if (phase !== 4 && queryParameters?.status) {
        delete params.status;
      }

      setPhaseFilter(phaseFilter);

      setQueryParameters({ ...params, phase });
    }
  };

  const queryParametersChangePendencie = (pendFilter) => {
    const pendencie = { ...queryParameters };

    if (pendencie[pendFilter]) {
      delete pendencie[pendFilter];

      setQueryParameters(pendencie);
    } else {
      pendencie[pendFilter] = true;
      setQueryParameters(pendencie);
    }
  };

  const checkEmptyObjectValues = (objValues) => {
    const newValues = { ...objValues };

    if (newValues.group_id === "") delete newValues.group_id;

    if (newValues.team_id === "") delete newValues.team_id;

    if (newValues.promotor_id === "") delete newValues.promotor_id;

    if (newValues.search_fantasy_name === "")
      delete newValues.search_fantasy_name;

    return newValues;
  };

  const refetchCustomerList = useCallback(async () => {
    setLoading(true);

    const queryParams = checkEmptyObjectValues(queryParameters);

    setLoadingQuery(true);

    try {
      const response = await api.get(`customers/showAll`, {
        params: { page: 1, ...queryParams },
      });

      setLastPage(response?.data?.lastPage);

      setPage(1);

      const customers = response?.data.data;

      // customers.sort((a, b) => new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime())

      setCustomerList(customers);

      if (customers.length > 0) {
        setMessage("");
      } else {
        setMessage("Nenhum cliente foi encontrado!");
      }

      setLoadingQuery(false);

      document.querySelector("#mainScroll").scrollTo(0, 0);

      setLoading(false);

      localStorage.setItem(
        "customerSaleFilter",
        JSON.stringify(queryParameters)
      );

      sessionStorage.setItem(
        "customerSaleFilter",
        JSON.stringify(queryParameters)
      );
    } catch (e) {
      setCustomerList([]);

      setMessage("Ocorreu um erro ao trazer as informações!");

      setLoading(false);
    }
  }, [queryParameters]);

  useEffect(() => {
    if (queryParameters) {
      refetchCustomerList();
    }
  }, [queryParameters]);

  const autoSearch = async (_search) => {
    setLoading(true);

    const queryParams = checkEmptyObjectValues(queryParameters);

    queryParams.search_fantasy_name = _search.trim();

    setQueryParameters(queryParams);

    setSearch(search);
  };

  const autoSrollQuery = async () => {
    if (page <= lastPage) {
      const queryParams = checkEmptyObjectValues(queryParameters);

      setLoadingQuery(true);
      const { data } = await api.get("customers/showAll", {
        params: {
          ...queryParams,
          id_project: props.userProject.id,
          page: page + 1,
        },
      });

      setPage(page + 1);
      const newArray = customerList.concat(data.data);
      setCustomerList(newArray);
      setLoadingQuery(false);
    }
  };

  const applyFilters = (filterToBeApplied) => {
    const applypParameters = { id_project: props.userProject.id, page: 1 };

    filterToBeApplied.values.forEach((filter) => {
      switch (filter.filter) {
        case "phaseFilter":
          const phase = mapPhaseToValue[filter.value];
          applypParameters.phase = phase;
          break;

        case "statusFilter":
          if (applypParameters.phase && applypParameters.phase !== 4) {
            break;
          }

          const phaseIsSell = filterToBeApplied.values.some(
            (elm) => elm.value === "sell"
          );

          if (phaseIsSell) {
            const status = mapStatusToValue[filter.value];
            applypParameters.status = status;
          }

          break;

        case "sms":
          applypParameters.sms = true;
          break;

        case "email":
          applypParameters.email = true;
          break;

        case "backoffice":
          applypParameters.backoffice = true;
          break;

        case "initialDateFilter":
          applypParameters.initDate = filter.value;
          break;

        case "finalDateFilter":
          applypParameters.finalDate = filter.value;
          break;

        case "groups":
          applypParameters.group_id = filter.value;
          break;

        case "teams":
          applypParameters.team_id = filter.value;
          break;

        case "promotors":
          applypParameters.promotor_id = filter.value;
          break;
        case "search":
          applypParameters.search_fantasy_name = filter.value;
          setSearch(filter.value);
          break;
        case "tag":
          applypParameters.tag = filter.value;
          break;
        default:
          break;
      }

      setQueryParameters(applypParameters);
    });
  };

  const deleteFilter = (name) => {
    const filteredFilters = JSON.parse(savedFiltersInStorage).filter(
      (f) => f.name !== name
    );

    localStorage.setItem("savedFilters", JSON.stringify(filteredFilters));
    sessionStorage.setItem("savedFilters", JSON.stringify(filteredFilters));

    setStateFilter(JSON.stringify(filteredFilters));

    swal(`Filtro deletado com sucesso!`, {
      icon: "success",
      button: "Fechar",
    });
  };

  const checkPendencie = (filter) => {
    if (queryParameters.sms) {
      filter.values.push({ filter: "sms", value: queryParameters["sms"] });
    }
    if (queryParameters.email) {
      filter.values.push({ filter: "email", value: queryParameters["email"] });
    }
    if (queryParameters.backoffice) {
      filter.values.push({
        filter: "backoffice",
        value: queryParameters["backoffice"],
      });
    }
  };

  const filterNames = {
    search: "search_fantasy_name",
    phase: "phaseFilter",
    status: "statusFilter",
    initDate: "initialDateFilter",
    finalDate: "finalDateFilter",
    tag: "tag",
    group_id: "groups",
    team_id: "teams",
    promotor_id: "promotors",
  };

  const finishSaveFilters = (previousFilters) => {
    let filter = { name: filterName, values: [] };

    // if (filter.values.length === 0 && Object.values(queryParameters).length > 1) {
    //   for (const [key, value] of Object.entries(queryParameters)) {
    //     const key_name = filterNames[key]

    //     console.log(key, key_name, value)
    //     if (key_name) {
    //       filter.values.push({ filter: key_name, value })
    //     }
    //   }
    // }

    if (statusFilter) {
      filter.values.push({ filter: "statusFilter", value: statusFilter });
    }
    if (phaseFilter) {
      filter.values.push({ filter: "phaseFilter", value: phaseFilter });
    }
    if (initialDateFilter) {
      filter.values.push({
        filter: "initialDateFilter",
        value: initialDateFilter,
      });
    }
    if (finalDateFilter) {
      filter.values.push({ filter: "finalDateFilter", value: finalDateFilter });
    }
    if (groups) {
      filter.values.push({ filter: "groups", value: groups });
    }
    if (teams) {
      filter.values.push({ filter: "teams", value: teams });
    }
    if (promotors) {
      filter.values.push({ filter: "promotors", value: promotors });
    }
    if (search) {
      filter.values.push({ filter: "search", value: search });
    }
    if (tagName) {
      filter.values.push({ filter: "tag", value: tagName });
    }

    checkPendencie(filter);

    if (filter.values.length > 0) {
      previousFilters.push(filter);

      localStorage.setItem("savedFilters", JSON.stringify(previousFilters));
      sessionStorage.setItem("savedFilters", JSON.stringify(previousFilters));

      const savedFiltersInStorage = localStorage.getItem("savedFilters");

      setStateFilter(savedFiltersInStorage);

      setFilterName("");

      swal(`Filtro salvo com sucesso!`, {
        icon: "success",
        button: "Fechar",
      });
    } else {
      swal(`Nenhum filtro foi selecionado!`, {
        icon: "info",
        button: "Fechar",
      });

      return;
    }
  };

  const saveFilters = () => {
    if (savedFiltersInStorage) {
      finishSaveFilters(JSON.parse(savedFiltersInStorage));
    } else {
      finishSaveFilters([]);
    }
  };

  const clearFilter = () => {
    const default_filter = { id_project: props.userProject.id };
    setSearch("");
    setQueryParameters(default_filter);
    localStorage.setItem("customerSaleFilter", JSON.stringify(default_filter));
    sessionStorage.setItem(
      "customerSaleFilter",
      JSON.stringify(default_filter)
    );
  };

  return (
    <div className="customerSalesList">
      <SearchClients
        autoSearch={autoSearch}
        setSearch={setSearch}
        search={search}
      />
      {
        <div id="customerDivList">
          <div className="registerContainer" id="headerRegisterContainer">
            <div className="l1" style={{ marginLeft: "10px" }}>
              <label>Fase | Estabelecimento</label>
            </div>
            <div className="l2" style={{ marginLeft: "60px" }}>
              <label>Tipo | Decisor</label>
            </div>
            {/* <div className='l3'>
              <label>Contato</label>
            </div> */}

            <div className="l4">
              <label>Promotor | Status</label>
            </div>
            <div className="l4">
              <label>Data</label>
            </div>
          </div>
          <div className="registerAndFilterContainer">
            <div className="registerListContainer">
              {loading ? (
                <LoaderSpin type={"Grid"} enable={loading} />
              ) : (
                customerList?.map((item, index) => {
                  const increment_id = item?.sales?.increment_id
                    ? "?increment_id=" + item?.sales?.increment_id
                    : "";

                  return userInfoStore.permissions &&
                    userInfoStore.permissions.includes(
                      "customerdetail_read"
                    ) ? (
                    <Link
                      key={`${item.id}${index}`}
                      to={`/CustomerInfo/${item.id}${increment_id}`}
                    >
                      <CustomerList item={item} />
                    </Link>
                  ) : (
                    <CustomerList noClick={true} item={item} />
                  );
                })
              )}

              {!loading && customerList.length <= 0 && (
                <div className="alertTextMessage">
                  <textarea readOnly defaultValue={message} />
                </div>
              )}
            </div>
            <div className="filterContainer">
              <div className="filterBoxes">
                <span className="filterTitle" style={{ marginBottom: 10 }}>
                  Filtros salvos
                </span>
                {filterState &&
                  filterState?.length &&
                  JSON.parse(filterState).map((filters, index) => {
                    return (
                      <div key={index + 1} className="filterSaved">
                        <span
                          onClick={() => applyFilters(filters)}
                          className="filterSavedTitle"
                        >
                          {filters?.name}
                        </span>
                        <div style={{ marginRight: "15px" }}>
                          <DeleteIcon
                            className="btnDeleteGroup"
                            onClick={() => deleteFilter(filters.name)}
                            style={{
                              fontSize: 20,
                              cursor: "pointer",
                              color: "#EE5928",
                            }}
                          />
                        </div>
                      </div>
                    );
                  })}
              </div>
              <div className="filterBoxes">
                <div className="filterHeader">
                  <span className="filterTitle">Fase funil</span>
                  <button
                    className="clearFilterButton"
                    onClick={() => clearFilter()}
                  >
                    Limpar
                  </button>
                </div>
                <div className="filtersCheckboxesDivs">
                  <div className="filterCheckboxAndSpanDiv">
                    <input
                      checked={queryParameters?.phase === 1}
                      onChange={() => queryParametersChangePhase("visit")}
                      className="filterCheckbox"
                      type="checkbox"
                    />
                    <span className="filterCheckboxLabel">Visita </span>
                  </div>
                  <div className="filterCheckboxAndSpanDiv">
                    <input
                      checked={queryParameters?.phase === 2}
                      onChange={() => queryParametersChangePhase("contact")}
                      className="filterCheckbox"
                      type="checkbox"
                    />
                    <span className="filterCheckboxLabel">Contato </span>
                  </div>
                  <div className="filterCheckboxAndSpanDiv">
                    <input
                      checked={queryParameters?.phase === 3}
                      onChange={() => queryParametersChangePhase("register")}
                      className="filterCheckbox"
                      type="checkbox"
                    />
                    <span className="filterCheckboxLabel">Cadastro </span>
                  </div>
                  <div className="filterCheckboxAndSpanDiv">
                    <input
                      checked={queryParameters?.phase === 4}
                      onChange={() => queryParametersChangePhase("sell")}
                      className="filterCheckbox"
                      type="checkbox"
                    />
                    <span className="filterCheckboxLabel">Venda</span>
                  </div>
                </div>
                <span className="filterTitle">Status da venda</span>
                <div className="filtersCheckboxesDivs">
                  <div className="filterCheckboxAndSpanDiv">
                    <input
                      checked={
                        queryParameters?.status === 1 &&
                        queryParameters?.phase === 4
                      }
                      onChange={() =>
                        queryParametersChangeStatus("Aprovação | Aguardando")
                      }
                      className="filterCheckbox"
                      type="checkbox"
                      disabled={queryParameters?.phase !== 4}
                    />
                    <span className="filterCheckboxLabel">
                      Aprovação | Aguardando{" "}
                    </span>
                  </div>
                  <div className="filterCheckboxAndSpanDiv">
                    <input
                      checked={
                        queryParameters?.status === 6 &&
                        queryParameters?.phase === 4
                      }
                      onChange={() =>
                        queryParametersChangeStatus("Aprovação | Cancelado")
                      }
                      className="filterCheckbox"
                      type="checkbox"
                      disabled={queryParameters?.phase !== 4}
                    />
                    <span className="filterCheckboxLabel">
                      Aprovação | Cancelado{" "}
                    </span>
                  </div>
                  <div className="filterCheckboxAndSpanDiv">
                    <input
                      checked={
                        queryParameters?.status === 2 &&
                        queryParameters?.phase === 4
                      }
                      onChange={() =>
                        queryParametersChangeStatus("Aprovação | Pendente")
                      }
                      className="filterCheckbox"
                      type="checkbox"
                      disabled={queryParameters?.phase !== 4}
                    />
                    <span className="filterCheckboxLabel">
                      Aprovação | Pendente{" "}
                    </span>
                  </div>
                  <div className="filterCheckboxAndSpanDiv">
                    <input
                      checked={
                        queryParameters?.status === 4 &&
                        queryParameters?.phase === 4
                      }
                      onChange={() =>
                        queryParametersChangeStatus("Acompanhamento | Ativo")
                      }
                      className="filterCheckbox"
                      type="checkbox"
                      disabled={queryParameters?.phase !== 4}
                    />
                    <span className="filterCheckboxLabel">
                      Acompanhamento | Ativo
                    </span>
                  </div>
                  <div className="filterCheckboxAndSpanDiv">
                    <input
                      checked={
                        queryParameters?.status === 5 &&
                        queryParameters?.phase === 4
                      }
                      onChange={() =>
                        queryParametersChangeStatus("Acompanhamento | Pendente")
                      }
                      className="filterCheckbox"
                      type="checkbox"
                      disabled={queryParameters?.phase !== 4}
                    />
                    <span className="filterCheckboxLabel">
                      Acompanhamento | Pendente
                    </span>
                  </div>
                </div>
                <span className="filterTitle">Pendência</span>
                <div className="filtersCheckboxesDivs">
                  <div className="filterCheckboxAndSpanDiv">
                    <input
                      checked={queryParameters?.sms === true}
                      onChange={() => queryParametersChangePendencie("sms")}
                      className="filterCheckbox"
                      type="checkbox"
                    />
                    <span className="filterCheckboxLabel">
                      Validação celular{" "}
                    </span>
                  </div>
                  <div className="filterCheckboxAndSpanDiv">
                    <input
                      checked={queryParameters?.email === true}
                      onChange={() => queryParametersChangePendencie("email")}
                      className="filterCheckbox"
                      type="checkbox"
                    />
                    <span className="filterCheckboxLabel">
                      Validação email{" "}
                    </span>
                  </div>
                  <div
                    className="filterCheckboxAndSpanDiv"
                    style={{ display: "none" }}
                  >
                    <input
                      checked={queryParameters?.backoffice === true}
                      onChange={() =>
                        queryParametersChangePendencie("backoffice")
                      }
                      className="filterCheckbox"
                      type="checkbox"
                    />
                    <span className="filterCheckboxLabel">Backoffice </span>
                  </div>
                </div>
                <span className="filterTitle">Data Inicial</span>
                <input
                  onChange={(e) => {
                    const value = e.target.value;
                    setInitialDateFilter(value);
                    setQueryParameters({ ...queryParameters, initDate: value });
                  }}
                  type="date"
                  className="filterInputs"
                  value={queryParameters?.initDate || ""}
                />
                <span className="filterTitle">Data Final</span>
                <input
                  onChange={(e) => {
                    const value = e.target.value;
                    setFinalDateFilter(value);
                    setQueryParameters({
                      ...queryParameters,
                      finalDate: value,
                    });
                  }}
                  type="date"
                  className="filterInputs"
                  style={{ marginBottom: 25 }}
                  value={queryParameters?.finalDate || ""}
                />
                <span className="filterTitle">Tags</span>
                <select
                  value={queryParameters?.tag || ""}
                  onChange={(event) => {
                    const value = event.target.value;

                    setTagName(value);

                    setQueryParameters({ ...queryParameters, tag: value });
                  }}
                  className="inputRoundedFilter"
                >
                  <option value="">Selecione a Tag</option>
                  {tags
                    ?.filter((tag) => {
                      if (
                        tag.id_tag_type !== 1 &&
                        (phaseFilter === "sell" || queryParameters?.phase === 4)
                      ) {
                        return true;
                      } else if (
                        tag.id_tag_type === 1 &&
                        (phaseFilter !== "sell" || queryParameters?.phase !== 4)
                      ) {
                        return true;
                      }

                      return false;
                    })
                    .map((options, index) => {
                      return (
                        <option value={options.name} key={`${index + options}`}>
                          {options.name.toLowerCase()} (
                          {options.id_tag_type !== 1
                            ? "Venda"
                            : "Visita | Contato | Cadastro | Venda"}
                          )
                        </option>
                      );
                    })}
                </select>
                <span className="filterTitle">Grupo</span>
                <select
                  value={queryParameters?.group_id || ""}
                  onChange={(event) => {
                    const value = event.target.value;

                    setGroups(value);
                    setTeams("");

                    if (queryParameters?.team_id) {
                      delete queryParameters.team_id;
                    }

                    setQueryParameters({ ...queryParameters, group_id: value });
                  }}
                  className="inputRoundedFilter"
                >
                  <option value="">Todos os grupos</option>
                  {availableGroups?.map((options, index) => {
                    return (
                      <option value={options.id} key={`${index + options}`}>
                        {options.section.toLowerCase()}
                      </option>
                    );
                  })}
                </select>
                <span className="filterTitle">Time</span>
                <select
                  value={queryParameters?.team_id || ""}
                  disabled={!queryParameters?.group_id}
                  onChange={(event) => {
                    const value = event.target.value;

                    setTeams(value);

                    setQueryParameters({ ...queryParameters, team_id: value });
                  }}
                  className="inputRoundedFilter"
                >
                  <option value="">Todos os times</option>
                  {availableTeams?.map((options, index) => {
                    return (
                      <option value={options.id} key={`${index + options}`}>
                        {options.name.toLowerCase()}
                      </option>
                    );
                  })}
                </select>
                <span className="filterTitle">Promotor</span>
                <select
                  value={queryParameters?.promotor_id || ""}
                  onChange={(event) => {
                    const value = event.target.value;

                    setPromotors(value);

                    setQueryParameters({
                      ...queryParameters,
                      promotor_id: value,
                    });
                  }}
                  className="inputRoundedFilter"
                  style={{ marginBottom: 5 }}
                >
                  <option value="">Todos os promotores</option>
                  {availablePromotors?.map((options, index) => {
                    return (
                      <option value={options.id} key={`${index + options}`}>
                        {options.fullname.toLowerCase()}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="filterBoxes">
                <div className="saveFilterDiv">
                  <input
                    value={filterName}
                    onChange={(event) => setFilterName(event.target.value)}
                    type="text"
                    className="filterInputs"
                    placeholder="Nome do filtro"
                  />
                  <button
                    onClick={() => saveFilters()}
                    className="saveFilterButton"
                    disabled={filterName.length === 0}
                  >
                    <span className="saveFilterButtonText">Salvar</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  );
}

export default CustomerSalesList;
