import React from 'react';

import { useHistory } from 'react-router-dom';

import * as datefns from 'date-fns';
import pt from 'date-fns/locale/pt-BR';

import api from '../../services/api';

import './styles.css';

const BtnCheck = () => (
    <svg width="25" height="25" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8 0C10.2108 0 12.0968 0.78064 13.6581 2.34192C15.2194 3.9032 16 5.78298 16 7.98126C16 10.1795 15.2194 12.0593 13.6581 13.6206C12.0968 15.1819 10.2108 15.9625 8 15.9625C5.78923 15.9625 3.9032 15.1819 2.34192 13.6206C0.78064 12.0593 0 10.1795 0 7.98126C0 5.78298 0.78064 3.9032 2.34192 2.34192C3.9032 0.78064 5.78923 0 8 0ZM6.40749 11.9906L13.6019 4.79625L12.4778 3.63466L6.40749 9.70492L3.52225 6.85714L2.39813 7.98126L6.40749 11.9906Z" fill="#999999"/>
    </svg>
)

const BtnChecked = () => (
    <svg width="25" height="25" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8 0C10.2108 0 12.0968 0.78064 13.6581 2.34192C15.2194 3.9032 16 5.78298 16 7.98126C16 10.1795 15.2194 12.0593 13.6581 13.6206C12.0968 15.1819 10.2108 15.9625 8 15.9625C5.78923 15.9625 3.9032 15.1819 2.34192 13.6206C0.78064 12.0593 0 10.1795 0 7.98126C0 5.78298 0.78064 3.9032 2.34192 2.34192C3.9032 0.78064 5.78923 0 8 0ZM6.40749 11.9906L13.6019 4.79625L12.4778 3.63466L6.40749 9.70492L3.52225 6.85714L2.39813 7.98126L6.40749 11.9906Z" fill="#48DB60"/>
    </svg>
)


const toNormalizedData = (date) => {
    return `Criado em: ${datefns.format(datefns.parseISO(date), 'dd/MM/yyyy', { locale: pt })} ${datefns.format(datefns.parseISO(date), "'às 'HH:mm'h'")}`
}

export default function VirtualizedList({ notifications, setNotifications, setShowNotication }) {
    // const history = useHistory();

    const markAsReadNotification = async (_id) => {
        try {
            if (!_id) return

            const _notifications = { ...notifications }

            const notification = _notifications?.data?.find(elm => elm._id === _id)

            notification.read = true

            setNotifications(_notifications)

            await api.put(`/notification/${_id}`)
        } catch (error) {
          console.log(error)
        }
    }

    return (

        <div className='card_box'>
            {   

                notifications?.data?.length !== 0 
                    ? notifications?.data?.map((notification) => (
                        <div 
                            className='card_box_container'
                            key={notification._id}                                            
                        >
                                <div 
                                    className='card_box_content_text'
                                    onClick={() =>{ 
                                        if (notification?.id_customer && notification?.increment_id) {
                                            setShowNotication(false)
                                            window.open(`/CustomerInfo/${notification.id_customer}?increment_id=${notification.increment_id}`, "_blank");
                                            //history.push(`/CustomerInfo/${notification?.id_customer}`)
                                        } else if (notification?.id_customer) {
                                            setShowNotication(false)
                                            window.open(`/CustomerInfo/${notification.id_customer}`, "_blank");
                                        }

                                        if (!notification.read ) {
                                            markAsReadNotification(notification._id)
                                        }
                                    }} 
                                >
                                    <span>{notification.title}</span>
                                    <span>{notification.contents}</span>
                                    <span>{toNormalizedData(notification.created_at)}</span>
                                </div>                
                                <div
                                    onClick={() => {
                                        if (!notification.read ) {
                                            markAsReadNotification(notification._id)
                                        }
                                    }}
                                >
                                    {
                                        notification.read ? <BtnChecked/> : 
                                        <BtnCheck />    
                                    }                                  
                                </div>
                        </div>
                    ))

                    : <p>Sem notificações no momento!</p>
            }
        </div>
   );
}