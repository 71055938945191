import React from "react";
import styled from "styled-components";
import AnimatedNumbers from "react-animated-numbers";
const Info = ({name,value,className,size='small', animated,width}) => {

     if(animated){
        return ( 
            <InfoContainer width={width} size={size}>
                <span>
                   {name}
                </span>
                <span>
                    <AnimatedNumbers
                        animateToNumber={Number(value)}
                        configs={[{
                            progress:1
                        }]}
                    />
                
                </span>
             </InfoContainer>
         );
     }
     return ( 
        <InfoContainer width={width} size={size}>
            <span>
               {name}
            </span>
            <span>
                {value}
            </span>
         </InfoContainer>
     );
}
const InfoContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: ${({width})=>width??'100%'} ;
    margin-right: ${({width})=>width?'30px':0};
    justify-content: space-between;
    margin-top: 2px;
    > span{
        :first-child{
            font-size: ${({size})=>size==='small'?'12px':'16px'};
            color: #999;
            font-weight: bold;
            margin-right:10px;
        }
        :last-child{
            font-size: ${({size})=>size==='small'?'12px':'16px'};
            color: #444;
            font-weight: bold;
        }
        font-size: 12px;
    }

`
 
export default Info;