import styled from 'styled-components'

export const AccordionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: white;
    border-radius: 10px;
    height: auto;
    padding: 2%;
    text-align: center;
`;

export const InternalWrapper = styled.div`
    width: 100%;
    max-height: ${(props) => (props.open ? '100px' : '0')};
    transition: all 0.4s ease-in-out;
    overflow: hidden;
`;
