import "./styles.css";
import React, { useEffect, useState, useRef, useCallback } from "react";
import { TextInfo } from "../../components/CardInfo";
import { useHistory } from "react-router-dom";
import api from "../../services/api";
import Tags from "@yaireo/tagify/dist/react.tagify"; // React-wrapper file
import ModalAlert from "../../components/ModalAlert";
import { useLocation } from "react-router-dom";
import swal from "sweetalert";

const CategoryDetails = ({ userInfo }) => {
  const history = useHistory();

  const [tags, setTags] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [canSaveTags, setCanSaveTags] = useState(false);

  const [showModal, setShowModal] = useState(false);

  const location = useLocation();
  const CanSaveTagsRef = useRef();
  CanSaveTagsRef.current = canSaveTags;

  const getData = async () => {
    const response = (await api.get("/categories/" + location.state.id)).data;

    setCategoryData(response);

    setStatus(response.active ? 1 : 2);

    getTags(response.id);

    setStock(response.stock ? 1 : 2);

    switch (response.document_type?.join()) {
      case "CPF":
        setCadType(1);
        break;
      case "CNPJ":
        setCadType(2);
        break;
      case "CPF,CNPJ":
        setCadType(3);
        break;
    }
  };

  const [editMode, setEditMode] = useState(false);
  const [stock, setStock] = useState(1);
  const [cadType, setCadType] = useState(3);
  const [status, setStatus] = useState(1);

  const stockTypes = [
    { id: 1, name: "Ativo" },
    { id: 2, name: "Desativado" },
  ];
  const cadTypes = [
    { id: 1, name: "CPF" },
    { id: 2, name: "CNPJ" },
    { id: 3, name: "CPF|CNPJ" },
  ];

  useEffect(() => {
    getData();
  }, []);

  const getTags = useCallback(async (categoryId) => {
    if (!categoryId) return;

    const response = await api.get(`skuCategoryTag/${categoryId}`);
    setTags(response?.data?.map((elm) => ({ value: elm.name, id: elm.id })));
    setTimeout(() => setCanSaveTags(true), 500);
  }, []);

  const saveData = async () => {
    try {
      const newData = {
        name: categoryData.name,
        description: categoryData.description,
        max_sales: categoryData.max_sales,
      };

      newData.active = status == 1 ? true : false;
      newData.stock = stock == 1 ? true : false;
      newData.document_type = JSON.stringify(
        cadType == 1 ? ["CPF"] : cadType == 2 ? ["CNPJ"] : ["CPF", "CNPJ"]
      );

      const response = await api.put(
        "/categories/" + location.state.id,
        newData
      );

      if (response?.status === 200)
        swal(`Categoria editada com sucesso!`, {
          icon: "success",
          button: "Fechar",
        });
    } catch (error) {
      swal(`Não foi possível editar categoria no momento!`, {
        icon: "errror",
        button: "Fechar",
      });
    }
  };

  useEffect(() => {
    getTags(categoryData.id);
  }, [getTags, categoryData]);

  const addTag = (event) => {
    api.post("/skuCategoryTag", {
      id_category: location.state.id,
      name: event.detail.data.value,
    });
  };

  const removeTag = (id) => {
    api.delete(`/skuCategoryTag/${id}`);
  };

  const handleShowModal = () => {
    setShowModal(!showModal);
  };

  const [alertError, setAlertError] = useState({
    buttons: [
      {
        name: "OK",
        onClick: (event) => {
          event.preventDefault();
          setMessage(false);
        },
      },
    ],
  });

  const [message, setMessage] = useState(false);
  //
  const [alert, setAlert] = useState({
    showAlert: true,
    title: "Exclusão de categoria",
    desc: "Você deseja excluir a categoria ?",
    onClose: (event) => {
      event.preventDefault();
      setShowModal(false);
    },
    buttons: [
      {
        name: "Sim",
        onClick: async (event) => {
          event.preventDefault();
          try {
            await api.delete(`/categories/${location.state.id}`);
            history.push("/Products");
          } catch (e) {
            setShowModal(false);
            setMessage(true);
          }
          return;
        },
      },
      {
        name: "Nao",
        onClick: (event) => {
          event.preventDefault();
          setShowModal(false);
        },
      },
    ],
  });

  const handleCloseMessage = () => {
    setMessage(false);
  };

  return (
    <div className="backgroundCategoryDetails">
      <div className="HeaderCategoryDetails">
        <p style={{ margin: 0, marginLeft: 20 }}>Categoria</p>
        <div style={{ display: "flex", flexDirection: "row" }}>
          {userInfo.permissions.includes("productCategory_edit") && (
            <button
              onClick={() => {
                editMode
                  ? (() => {
                      setEditMode(false);
                      saveData();
                    })()
                  : setEditMode(true);
              }}
              class="btnProducts"
              style={{ margin: 0, marginRight: 20 }}
            >
              {editMode ? "Salvar" : "Editar Categoria"}
            </button>
          )}
          {editMode && (
            <button
              class="btnProducts"
              style={{ margin: 0, marginRight: 15 }}
              onClick={() => {
                setEditMode(false);
              }}
            >
              Cancelar
            </button>
          )}
          {userInfo.permissions.includes("productCategory_delete") && (
            <>
              <button
                class="btnProducts"
                onClick={() => {
                  handleShowModal();
                }}
              >
                Excluir
              </button>
              <button
                style={{ marginLeft: 15 }}
                class="btnProducts"
                onClick={() => history.push("/Products")}
              >
                Voltar
              </button>
            </>
          )}
          {message == true ? (
            <div style={{ zIndex: 10000 }}>
              <ModalAlert
                showAlert={alert.showAlert}
                title="Error"
                desc="Não foi possivel excluir essa categoria pois ela possue produtos associados!"
                color="gray"
                buttons={alertError.buttons}
                onClose={handleCloseMessage}
              />
            </div>
          ) : null}
          {showModal == true ? (
            <div style={{ zIndex: 10000 }}>
              <ModalAlert
                showAlert={alert.showAlert}
                title={alert.title}
                desc={alert.desc}
                buttons={alert.buttons}
                onClose={alert.onClose}
                color="gray"
              />
            </div>
          ) : null}
        </div>
      </div>
      <div className="CategoryDetailsMainContainer">
        <div className="CategoryDetailsMainContainer">
          <div className="CardInfoCategoryDetails">
            <div className="rowCategory">
              <TextInfo
                className="rowFull"
                title={"Nome"}
                value={categoryData?.name}
                onChange={(event) => {
                  setCategoryData({
                    ...categoryData,
                    name: event.target.value,
                  });
                }}
                editing={editMode}
              />

              <TextInfo
                className="rowFull"
                title="Descrição"
                value={categoryData?.description}
                onChange={(event) => {
                  setCategoryData({
                    ...categoryData,
                    description: event.target.value,
                  });
                }}
                editing={editMode}
              />

              <div className="max-sales rowFull">
                <span className="textInfoTitle">Máximo de Vendas</span>

                {editMode ? (
                  <input
                    value={categoryData?.max_sales ?? 0}
                    onChange={(e) => {
                      setCategoryData({
                        ...categoryData,
                        max_sales: Number(e.target.value),
                      });
                    }}
                    type="number"
                    min={0}
                    className="inputRounded"
                  />
                ) : (
                  <label
                    style={{ marginTop: 10, fontWeight: "bold", fontSize: 13 }}
                  >
                    {categoryData?.max_sales ?? "Padrão"}
                  </label>
                )}
              </div>

              {!editMode &&
                canSaveTags &&
                userInfo.permissions.includes("productCategory_edit") &&
                userInfo.permissions.includes("productCategory_delete") && (
                  <>
                    <div className="projectInfoInputContainerTags">
                      <label className="projectInfoLabel">Tags:</label>
                      <Tags
                        onRemove={(e) => {
                          removeTag(e.detail.data.id);
                        }}
                        onAdd={(e) => {
                          addTag(e);
                        }}
                        value={tags}
                        className="Tags"
                      />
                    </div>
                  </>
                )}
            </div>

            <div className="rowCategory">
              <TextInfo
                type="dropDown"
                values={stockTypes}
                className="rowFull"
                title={"Status"}
                value={status}
                onChange={(event) => {
                  setStatus(event.target.value);
                }}
                editing={editMode}
              />
              <TextInfo
                type="dropDown"
                values={cadTypes}
                className="rowFull"
                title={"Cadastro Permitido"}
                value={cadType}
                valuesId="id"
                valuesName="name"
                onChange={(event) => {
                  setCadType(event.target.value);
                }}
                editing={editMode}
              />

              <TextInfo
                type="dropDown"
                className="rowFull"
                title="Estoque"
                values={stockTypes}
                value={stock}
                onChange={(event) => {
                  setStock(event.target.value);
                }}
                editing={editMode}
              />
            </div>

            <div className="rowCategory">
              <TextInfo
                className="rowFull"
                title={"Criado em"}
                value={new Date(categoryData?.created_at).toLocaleString()}
                // value={new Date(categoryData?.created_at)}
                onChange={(event) => {}}
                editing={false}
              />

              <TextInfo
                className="rowFull"
                title="Atualizado em"
                value={new Date(categoryData?.updated_at).toLocaleString([], {
                  dateStyle: "short",
                  timeStyle: "short",
                })}
                editing={false}
              />

              <TextInfo
                className="rowFull"
                title=""
                value={""}
                editing={false}
              />
            </div>
          </div>

          <div className="CardInfoCategoryDetailsMiniContainer">
            <div className="row">
              <div className="CardInfoCategoryDetailsMini">
                <div className="iconTextContainer">
                  <div className="iconContainer">
                    <svg
                      width="33"
                      height="33"
                      viewBox="0 0 33 33"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M27.5 4.125H5.5C3.98338 4.125 2.75 5.35837 2.75 6.875V9.625C2.75 10.637 3.30687 11.5156 4.125 11.9928V26.125C4.125 27.6416 5.35838 28.875 6.875 28.875H26.125C27.6416 28.875 28.875 27.6416 28.875 26.125V11.9928C29.6931 11.5156 30.25 10.637 30.25 9.625V6.875C30.25 5.35837 29.0166 4.125 27.5 4.125ZM5.5 6.875H27.5L27.5028 9.625H5.5V6.875ZM6.875 26.125V12.375H26.125L26.1278 26.125H6.875Z"
                        fill="black"
                      />
                      <path d="M11 15.125H22V17.875H11V15.125Z" fill="black" />
                    </svg>
                  </div>
                  <div className="categoryDashText colorfull">
                    Produtos
                    <br />
                    Cadatrados
                  </div>
                </div>
                <div
                  style={{ fontSize: 35, fontWeight: "bold", color: "#333" }}
                >
                  {categoryData?.cadproduct}
                </div>
              </div>

              <div className="CardInfoCategoryDetailsMini">
                <div style={{ width: "100%" }}>
                  <div className="tagsContainer">
                    <div className="iconTextContainer">
                      <div className="iconContainer">
                        <svg
                          width="25"
                          height="25"
                          viewBox="0 0 25 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M18.292 10.4795C18.5767 10.1848 18.7341 9.79015 18.7306 9.38047C18.727 8.97078 18.5627 8.57889 18.273 8.28919C17.9833 7.99949 17.5914 7.83516 17.1817 7.8316C16.772 7.82804 16.3773 7.98553 16.0827 8.27016L10.9373 13.4155L8.91703 11.3952C8.62234 11.1105 8.22765 10.953 7.81797 10.9566C7.40828 10.9602 7.01639 11.1245 6.72669 11.4142C6.43699 11.7039 6.27266 12.0958 6.2691 12.5055C6.26554 12.9152 6.42303 13.3098 6.70766 13.6045L9.83266 16.7295C10.1257 17.0225 10.523 17.187 10.9373 17.187C11.3517 17.187 11.749 17.0225 12.042 16.7295L18.292 10.4795Z"
                            fill="black"
                          />
                          <path
                            d="M0 12.5C0 9.18479 1.31696 6.00537 3.66117 3.66117C6.00537 1.31696 9.18479 0 12.5 0C15.8152 0 18.9946 1.31696 21.3388 3.66117C23.683 6.00537 25 9.18479 25 12.5C25 15.8152 23.683 18.9946 21.3388 21.3388C18.9946 23.683 15.8152 25 12.5 25C9.18479 25 6.00537 23.683 3.66117 21.3388C1.31696 18.9946 0 15.8152 0 12.5ZM12.5 3.125C11.2689 3.125 10.0498 3.36749 8.91234 3.83863C7.77492 4.30977 6.74142 5.00032 5.87087 5.87087C5.00032 6.74142 4.30977 7.77492 3.83863 8.91234C3.36749 10.0498 3.125 11.2689 3.125 12.5C3.125 13.7311 3.36749 14.9502 3.83863 16.0877C4.30977 17.2251 5.00032 18.2586 5.87087 19.1291C6.74142 19.9997 7.77492 20.6902 8.91234 21.1614C10.0498 21.6325 11.2689 21.875 12.5 21.875C14.9864 21.875 17.371 20.8873 19.1291 19.1291C20.8873 17.371 21.875 14.9864 21.875 12.5C21.875 10.0136 20.8873 7.62903 19.1291 5.87087C17.371 4.11272 14.9864 3.125 12.5 3.125Z"
                            fill="black"
                          />
                        </svg>
                      </div>
                      <div className="categoryDashText colorfull">
                        Produtos
                        <br />
                        Disponíveis
                      </div>
                    </div>
                    <div
                      style={{
                        fontSize: 35,
                        fontWeight: "bold",
                        color: "#333",
                      }}
                    >
                      {categoryData?.availableproduct}
                    </div>
                  </div>
                  {categoryData.tags
                    ?.filter((elm) => elm.id_sku_status === 1)
                    .map((elm) => (
                      <div className="tagsCategory">
                        <div className="tagName">{elm.name}</div>
                        <div className="tagValue">{elm.count}</div>
                      </div>
                    ))}
                </div>
              </div>

              <div className="CardInfoCategoryDetailsMini">
                <div style={{ width: "100%" }}>
                  <div className="tagsContainer">
                    <div className="iconTextContainer">
                      <div className="iconContainer">
                        <svg
                          width="25"
                          height="25"
                          viewBox="0 0 25 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M18.292 10.4795C18.5767 10.1848 18.7341 9.79015 18.7306 9.38047C18.727 8.97078 18.5627 8.57889 18.273 8.28919C17.9833 7.99949 17.5914 7.83516 17.1817 7.8316C16.772 7.82804 16.3773 7.98553 16.0827 8.27016L10.9373 13.4155L8.91703 11.3952C8.62234 11.1105 8.22765 10.953 7.81797 10.9566C7.40828 10.9602 7.01639 11.1245 6.72669 11.4142C6.43699 11.7039 6.27266 12.0958 6.2691 12.5055C6.26554 12.9152 6.42303 13.3098 6.70766 13.6045L9.83266 16.7295C10.1257 17.0225 10.523 17.187 10.9373 17.187C11.3517 17.187 11.749 17.0225 12.042 16.7295L18.292 10.4795Z"
                            fill="black"
                          />
                          <path
                            d="M0 12.5C0 9.18479 1.31696 6.00537 3.66117 3.66117C6.00537 1.31696 9.18479 0 12.5 0C15.8152 0 18.9946 1.31696 21.3388 3.66117C23.683 6.00537 25 9.18479 25 12.5C25 15.8152 23.683 18.9946 21.3388 21.3388C18.9946 23.683 15.8152 25 12.5 25C9.18479 25 6.00537 23.683 3.66117 21.3388C1.31696 18.9946 0 15.8152 0 12.5ZM12.5 3.125C11.2689 3.125 10.0498 3.36749 8.91234 3.83863C7.77492 4.30977 6.74142 5.00032 5.87087 5.87087C5.00032 6.74142 4.30977 7.77492 3.83863 8.91234C3.36749 10.0498 3.125 11.2689 3.125 12.5C3.125 13.7311 3.36749 14.9502 3.83863 16.0877C4.30977 17.2251 5.00032 18.2586 5.87087 19.1291C6.74142 19.9997 7.77492 20.6902 8.91234 21.1614C10.0498 21.6325 11.2689 21.875 12.5 21.875C14.9864 21.875 17.371 20.8873 19.1291 19.1291C20.8873 17.371 21.875 14.9864 21.875 12.5C21.875 10.0136 20.8873 7.62903 19.1291 5.87087C17.371 4.11272 14.9864 3.125 12.5 3.125Z"
                            fill="black"
                          />
                        </svg>
                      </div>
                      <div className="categoryDashText colorfull">
                        Produtos
                        <br />
                        Atribuídos
                      </div>
                    </div>
                    <div
                      style={{
                        fontSize: 35,
                        fontWeight: "bold",
                        color: "#333",
                      }}
                    >
                      {categoryData?.assignedproduct}
                    </div>
                  </div>
                  {categoryData.tags
                    ?.filter((elm) => elm.id_sku_status === 2)
                    .map((elm) => (
                      <div className="tagsCategory">
                        <div className="tagName">{elm.name}</div>
                        <div className="tagValue">{elm.count}</div>
                      </div>
                    ))}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="CardInfoCategoryDetailsMini">
                <div style={{ width: "100%" }}>
                  <div className="tagsContainer">
                    <div className="iconTextContainer">
                      <div className="iconContainer">
                        <svg
                          width="27"
                          height="27"
                          viewBox="0 0 27 27"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M18.7084 13.4998L13.5001 18.7082L8.29175 13.4998M13.5001 7.24984V17.6665V7.24984ZM13.5001 2.0415C19.8282 2.0415 24.9584 7.17171 24.9584 13.4998C24.9584 19.828 19.8282 24.9582 13.5001 24.9582C7.17196 24.9582 2.04175 19.828 2.04175 13.4998C2.04175 7.17171 7.17196 2.0415 13.5001 2.0415Z"
                            stroke="black"
                            stroke-width="2.6"
                          />
                        </svg>
                      </div>
                      <div className="categoryDashText colorfull">
                        Produtos
                        <br />
                        Instalados
                      </div>
                    </div>
                    <div
                      style={{
                        fontSize: 35,
                        fontWeight: "bold",
                        color: "#333",
                      }}
                    >
                      {categoryData?.instaledproduct}
                    </div>
                  </div>
                  {categoryData.tags
                    ?.filter((elm) => elm.id_sku_status === 3)
                    .map((elm) => (
                      <div className="tagsCategory">
                        <div className="tagName">{elm.name}</div>
                        <div className="tagValue">{elm.count}</div>
                      </div>
                    ))}
                </div>
              </div>

              <div className="CardInfoCategoryDetailsMini">
                <div style={{ width: "100%" }}>
                  <div className="tagsContainer">
                    <div className="iconTextContainer">
                      <div className="iconContainer">
                        <svg
                          width="33"
                          height="33"
                          viewBox="0 0 33 33"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M25.2506 7.74958C26.3997 8.89871 27.3112 10.2629 27.9332 11.7643C28.5551 13.2658 28.8752 14.875 28.8752 16.5001C28.8752 18.1252 28.5551 19.7344 27.9332 21.2358C27.3112 22.7372 26.3997 24.1014 25.2506 25.2506C24.1014 26.3997 22.7372 27.3112 21.2358 27.9332C19.7344 28.5551 18.1252 28.8752 16.5001 28.8752C14.875 28.8752 13.2658 28.5551 11.7643 27.9332C10.2629 27.3112 8.89871 26.3997 7.74958 25.2506C5.4288 22.9298 4.125 19.7821 4.125 16.5001C4.125 13.218 5.4288 10.0704 7.74958 7.74958C10.0704 5.4288 13.218 4.125 16.5001 4.125C19.7821 4.125 22.9298 5.4288 25.2506 7.74958ZM22.2641 24.2083L8.79045 10.7361C7.40296 12.5899 6.73019 14.8818 6.89549 17.1914C7.06078 19.5011 8.05308 21.6737 9.69043 23.3111C11.3278 24.9484 13.5005 25.9407 15.8101 26.106C18.1198 26.2713 20.4116 25.5986 22.2655 24.2111L22.2641 24.2083ZM23.3063 9.69383C24.9427 11.3306 25.9346 13.5021 26.1005 15.8106C26.2664 18.1191 25.5952 20.4101 24.2097 22.2641L10.7347 8.79045C12.5887 7.40432 14.88 6.73268 17.1889 6.89859C19.4978 7.06451 21.6696 8.05686 23.3063 9.69383Z"
                            fill="black"
                          />
                        </svg>
                      </div>
                      <div className="categoryDashText colorfull">
                        Produtos
                        <br />
                        Indisponíveis
                      </div>
                    </div>
                    <div
                      style={{
                        fontSize: 35,
                        fontWeight: "bold",
                        color: "#333",
                      }}
                    >
                      {categoryData?.unavailableproduct}
                    </div>
                  </div>
                  {categoryData.tags
                    ?.filter((elm) => elm.id_sku_status === 4)
                    .map((elm) => (
                      <div className="tagsCategory">
                        <div className="tagName">{elm.name}</div>
                        <div className="tagValue">{elm.count}</div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryDetails;
