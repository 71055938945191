export function FormatPhone(value) {
  let val = value;
  if (val) {
    val = val.replace(/[^\d]/g, '');
    if (val.length > 10) {
      if (val.length > 7) val = val.replace(/^(.{7})/, '$1-');
      if (val.length > 3) val = val.replace(/^(.{3})/, '$1 ');
      if (val.length > 2) val = val.replace(/^(.{2})/, '$1) ');
      if (val.length > 1) val = val.replace(/^(.{1})/, '($1');
    } else {
      if (val.length > 6) val = val.replace(/^(.{6})/, '$1-');
      if (val.length > 2) val = val.replace(/^(.{2})/, '$1) ');
      if (val.length > 1) val = val.replace(/^(.{1})/, '($1');
    }
  }
  return val;
}

export function FormatCelphone(value) {
  let val = value;
  if (val) {
    val = val.replace(/[^\d]/g, '');
    if (val.length > 7) val = val.replace(/^(.{7})/, '$1-');
    if (val.length > 3) val = val.replace(/^(.{3})/, '$1 ');
    if (val.length > 2) val = val.replace(/^(.{2})/, '$1) ');
    if (val.length > 1) val = val.replace(/^(.{1})/, '($1');
    if (val.length > 16) val = val.slice(0, val.length - 1);
  }
  return val;
}


export function FormatImei(value){
  var val = value;
  if(val){
    val = val.replace(/[^\d]/g, '');
    // 000000-00-000000-0.
    if (val.length > 14) val = val.replace(/^(.{14})/, '$1-');
    if (val.length > 8) val = val.replace(/^(.{8})/, '$1-');
    if (val.length > 6) val = val.replace(/^(.{6})/, '$1-');

  }
  return val;
}

export function FormatDocument(value) {
  var val = value;
  if (val) {
    val = val.replace(/[^\d]/g, '');
    if (val.length > 11) {
      if (val.length > 12) val = val.replace(/^(.{12})/, '$1-');
      if (val.length > 8) val = val.replace(/^(.{8})/, '$1/');
      if (val.length > 5) val = val.replace(/^(.{5})/, '$1.');
      if (val.length > 2) val = val.replace(/^(.{2})/, '$1.');
    } else {
      if (val.length > 9) val = val.replace(/^(.{9})/, '$1-');
      if (val.length > 6) val = val.replace(/^(.{6})/, '$1.');
      if (val.length > 3) val = val.replace(/^(.{3})/, '$1.');
    }
  }
  return val;
}

export function FormatDocumentCnpj(value) {
  var val = value;
  if (val) {
    val = val.replace(/[^\d]/g, '');
    if (val.length > 12) val = val.replace(/^(.{12})/, '$1-');
    if (val.length > 8) val = val.replace(/^(.{8})/, '$1/');
    if (val.length > 5) val = val.replace(/^(.{5})/, '$1.');
    if (val.length > 2) val = val.replace(/^(.{2})/, '$1.');
  }
  return val;
}

export function FormatDocumentCpf(value) {
  var val = value;
  if (val) {
    val = val.replace(/[^\d]/g, '');
    if (val.length > 9) val = val.replace(/^(.{9})/, '$1-');
    if (val.length > 6) val = val.replace(/^(.{6})/, '$1.');
    if (val.length > 3) val = val.replace(/^(.{3})/, '$1.');
    
    if (val.length > 14) val = val.slice(0, val.length - 1)
  }
  return val;
}
//commit aaaa
export function FormatDocumentRg(value) {
  var val = value;
  if (val) {
    val = val.replace(/[^\d]/g, '');
    if (val.length > 8) val = val.replace(/^(.{8})/, '$1-');
    if (val.length > 5) val = val.replace(/^(.{5})/, '$1.');
    if (val.length > 2) val = val.replace(/^(.{2})/, '$1.');
  }
  return val;
}

export function FormatZipcode(value) {
  var val = value;
  if (val) {
    val = val.replace(/[^\d]/g, '');
    if (val.length > 5) val = val.replace(/^(.{5})/, '$1-');
  }
  return val;
}

export function FormatMoney(value) {
  if (value) {
    var val = value;
    if (typeof val === 'string') {
      val = val.replace(',', '');
      val = parseFloat(val);
      if(val > 0){
        val = val/100
      }else{
        val = 0
      }
    }
    if (typeof val === 'number') {
      val = val.toFixed(2);
    }
    val = val.toString();
    val = val.replace('.', ',');
    return val;
  } else {
    return '00,00';
  }
}

export function FormatNumber(value) {
  let val = 0;
  if(value){
    val = value;
    val = val.replace(/[^\d]/g, '');
    val = parseInt(val, 10);
  }
  return val;
}

export function validadeHour(value) {
  let val = value;
  if (val) {
    val = val.replace(/[^\d]/g, '');
    if (val.length > 0) {
      if (parseInt(val[0], 10) > 2) {
        val = '0' + val;
      }
    }
  }
  return val;
}

export function validadeMinutes(value) {
  let val = value;
  if (val) {
    val = val.replace(/[^\d]/g, '');
    if (val.length > 0) {
      if (parseInt(val[0], 10) > 5) {
        val = '0' + val;
      }
    }
  }
  return val;
}
