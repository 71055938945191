import styled from "styled-components";



export const Container = styled.div`
    display: flex;  
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    *{
        cursor:pointer;
    }
`;
export const PromoterHighlight = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    > div{
       
        margin-top:5px;
        align-items: flex-start;
        justify-content: flex-start;
        width: 350px;
        @media (min-width:1600px) {
            width:180px;
        }
        text-overflow: ellipsis;
        height: 40px;
        z-index: 50;
        .Infos{
           font-size: 0.7rem;
           color:#666;
           font-weight: bold;
        }
        .name{
            color:#333;
            font-size: 1.1rem;
            font-weight: bold;
        }
    }
    span{
        display:flex;
        white-space: nowrap;                  
        overflow: hidden; 
        text-overflow:ellipsis;
        width:100%;
    }
`
export const HighsAndLows = styled.div`
    .value{
        font-weight: bold;
        text-align: center;
        margin-top:24px;
    }

    .colorColumn{
            label{
                position: absolute;
                font-weight: bold;
                font-size: .6rem;
                margin-top:5px;
                color:#888;
            }
            :nth-child(1){
                right: 50px;
            }
            :nth-child(2){
                right: 110px;
            }
            :nth-child(3){
                right: 170px;
            }
            position: absolute;
            top: 0;
            bottom: 0;

            width:50px;
            color:#444444 ;
            font-size: 1.3rem;
            background-color: ${props => props.color === 'lows' ? '#FFDADA' : '#DAFFE0'};
            padding-top:30px;
            align-items: center;
            
        }
`
export const ProjectCardContainer = styled.div`
    :not(:last-child){
        margin-bottom: 25px;
    }   

    display: grid;
    width:100%;
    grid-template-columns: 1fr 1.5fr 2fr 2fr;
    grid-template-rows: repeat(2, 1fr);
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 15px -6px rgba(0,0,0,0.5);
    box-shadow: 0px 0px 15px -6px rgba(0,0,0,0.5);
    padding:10px;

    grid-template-areas: 
    "p v h l"
    "s s h l"; 
    .project { 
        grid-area: p;
        h3{
            color:#EE5928;
        }
    }  
    .visit { grid-area: v; }
    .sales { grid-area: s; }
    .highs { grid-area: h; }
    .lows { grid-area:  l; }

    @media (max-width: 1600px) {
        grid-template-columns: repeat(6, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-template-areas: 
        "p v v s s s"
        "h h h l l l";
    }
    @media (max-width: 1040px) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: repeat(3, 1fr);
        grid-template-areas: 
         "p v v v"
         "s s s s" 
         "h h l l"; 
    }
    @media (max-width: 1000px) {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(5, 1fr);
        grid-template-areas: 
         "p"
         "v"
         "s" 
         "h"
         "l"; 
    }
  
    .title{
        background-color: #BBBBBB;
        padding: 4px 18px;
        display:flex ;
        justify-content: center;
        align-items: flex-start;
        border-radius: 20px;
        color:#fff;
        font-size: 14px;
    }
    > div{
         .infoContainer{
             margin-top: 10px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            display:flex ;
            width:100%;
        }
        border-radius: 10px;
        -webkit-box-shadow: 0px 0px 15px -6px rgba(0,0,0,0.5);
        box-shadow: 0px 0px 15px -6px rgba(0,0,0,0.5);
        margin: 5px;
        padding: 10px;
    }
    .productsContainer{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(auto-fill, 1fr);
        margin-top: 10px;
        border-radius: 10px;
        padding: 5px 10px ;
        background-color: #eee;

        .infoContainer{
             margin-top: 10px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            display:flex ;
            width:auto !important;
        }
    }
`
