import React, { useEffect, useState, useContext, useCallback } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import Header from "../../components/Header";
import api from "../../services/api";
import * as storeImage from "../../assets/iconStore.png";
import { useForm } from "react-hook-form";
import swal from "sweetalert";

import EditIcon from "@material-ui/icons/Edit";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import ClearIcon from "@material-ui/icons/Clear";

import * as S from "./produt-info.styles";
import "./style.css";

import StoreContext from "../../context/Context";
import { NormalizedData } from "../../utils/NormalizedData";

const HistoryTypes = {
  1: {
    name: "Produto Criado",
  },
  2: {
    name: "Supervisor Alterado",
  },
  3: {
    name: "Promotor Alterado",
  },
  4: {
    name: "Grupo",
  },
  5: {
    name: "Status Alterado",
  },
  6: {
    name: "Coordenador Alterado",
  },
  7: {
    name: "Gerente Alterado",
  },
  8: {
    name: "Atualização",
  },
  9: {
    name: "Removido",
  },
  10: {
    name: "Time",
  },
  11: {
    name: "Devolução Solicitada",
  },
  12: {
    name: "Produto Devolvido",
  },
  13: {
    name: "Devolução Recusada pelo Sistema",
  },
};

const ProductStatus = [1, 2, 3, 4];

function ProductInfo(prop) {
  let { id } = useParams();
  const history = useHistory();
  const location = useLocation();

  const iconEdit = <EditIcon />;
  const iconOpen = <OpenInNewIcon />;
  const iconClear = <ClearIcon />;

  const [overFlow, setOverFlow] = useState("none");
  const [categories, setCagories] = useState([]);
  const [showButton, setShowButton] = useState(false);
  const [editing, setEditing] = useState(false);
  const [tags, setTags] = useState([]);
  const [product, setProduct] = useState("");
  const [productHistory, setProductHistory] = useState([]);
  const [teams, setTeams] = useState([]);
  const [customer, setCustomer] = useState({});

  const { register, handleSubmit, watch, reset } = useForm({
    defaultValues: {
      id_sku_category: "",
      id_sku_status: "",
      name: "",
      description: "",
      supervisor_fullname: "",
      promotor_fullname: "",
      ean_code: "",
      id_tag: "",
      status: "",
      id_group: "",
    },
  });

  const { userInfoStore, userProjectStore } = useContext(StoreContext);

  const products_edit = userInfoStore.permissions.includes("products_edit");
  const changeproductstatus_edit = userInfoStore.permissions.includes(
    "changeproductstatus_edit"
  );
  const requestproduct_create = userInfoStore.permissions.includes(
    "requestproduct_create"
  );

  const statusOptions = [
    { id: 1, name: "Disponível" },
    { id: 2, name: "Atribuído" },
    { id: 3, name: "Instalado" },
    { id: 4, name: "Indisponível" },
  ];

  const requestProduct = async (productId) => {
    try {
      const response = await api.post("/productRequest", { id_sku: productId });

      //const newProduct = { ...product }
      //newProduct.requested = true;
      //setProduct(newProduct)

      if (response.status === 200) {
        req();

        swal({
          title: "Atenção",
          text: response.data.message,
          icon: "success",
          button: "Fechar",
        });
      }
    } catch (error) {
      const { data } = error?.response;

      if (data?.message) {
        swal({
          title: "Atenção",
          text: data.message,
          icon: "warning",
          button: "Fechar",
        });
      } else {
        swal({
          title: "Atenção",
          text: "Ocorreu um erro ao solicitar produto!",
          icon: "error",
          button: "Fechar",
        });
      }
    }
  };

  const req = useCallback(() => {
    try {
      api.get(`/productstatus`).then((res) => {
        api.get(`/products/${id}`).then((response) => {
          if (response?.data?.product?.customer?.id) {
            setCustomer(response.data.product.customer);
          }

          setProduct(response.data.product);

          setProductHistory(response.data.productHistory);

          if (response.data.product.id_sku_status === 3) {
            setOverFlow("auto");
          }
        });
      });
    } catch (e) {
      history.push("/ProductsDetails");
    }
  }, [id, history]);

  const saveProducts = async (data) => {
    try {
      const response = await api.put("/products/" + id, data);

      if (response.status === 200) {
        req();

        swal(`Produto atualizado com sucesso!`, {
          icon: "success",
          button: "Fechar",
        });
      }
    } catch (error) {
      const { data } = error?.response;

      swal({
        title: "Atenção",
        text: data?.error,
        icon: "warning",
        button: "Fechar",
      });
    }
  };

  const handleClickAbout = () => {
    window.open(`/CustomerInfo/${product.customer.id}`, "_blank");
  };

  const getTags = async (id) => {
    const response = await api.get(`/skuCategoryTag/${id}`);

    setTags(response.data);
  };

  const getCategories = useCallback(async () => {
    const { data: categories } = await api.get("/categories", {
      params: { id_project: userProjectStore.id },
    });

    setCagories(categories);
  }, [userProjectStore.id]);

  const watchStatus = watch("id_sku_status") || product.id_sku_status;

  useEffect(() => {}, [watchStatus]);

  useEffect(() => {
    req();

    getCategories();
  }, [getCategories, history, id, req]);

  const onSubmit = (data) => {
    saveProducts(data);
    setShowButton(false);
  };

  const resetForm = () => {
    reset({
      id_sku_category: product.id_sku_category,
      name: product.name,
      description: product.description,
      ean_code: product.ean_code,
      supervisor_fullname:
        product.supervisor_fullname ||
        product.supervisor_username ||
        "Não Atríbuido",
      promotor_fullname:
        product.promotor_fullname ||
        product.promotor_username ||
        "Não Atríbuido",
      id_tag: product.id_tag,
      id_sku_status: product.id_sku_status,
      id_group: product.id_group,
    });
  };

  useEffect(() => {
    if (product) {
      reset({
        id_sku_category: product.id_sku_category,
        name: product.name,
        description: product.description,
        ean_code: product.ean_code,
        supervisor_fullname:
          product.supervisor_fullname ||
          product.supervisor_username ||
          "Não Atríbuido",
        promotor_fullname:
          product.promotor_fullname ||
          product.promotor_username ||
          "Não Atríbuido",
        id_tag: product.id_tag,
        id_sku_status: product.id_sku_status,
        id_group: product.id_group,
      });
    }
  }, [product, tags, reset]);

  useEffect(() => {
    if (product) {
      getTags(product.id_sku_category);
    }
  }, [product]);

  const getTeams = async () => {
    const { data = [] } = await api.get(
      `/group/groupSection?id_project=${userProjectStore.id}`
    );

    setTeams(data);
  };

  useEffect(() => {
    getTeams();
  }, []);

  return (
    // <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', backgroundColor: "#444", width: '100%'}}>
    <>
      <Header
        title="Detalhes do produto"
        buttons={() => {
          return (
            <S.ContentBtns>
              {!showButton &&
                ProductStatus.includes(watchStatus) &&
                (products_edit || changeproductstatus_edit) && (
                  <button
                    onClick={() => {
                      if (product.id_sku_status == 2) {
                      } else {
                        setEditing(!editing);
                        //saveProducts();
                      }

                      setShowButton(true);
                    }}
                    className="btnProducts"
                  >
                    Editar
                  </button>
                )}

              {product.id_sku_status === 2 && requestproduct_create && (
                <>
                  {product.requested ? (
                    <button
                      disabled
                      className="btnProducts"
                      style={{ marginLeft: "20px" }}
                    >
                      Solicitar Produto
                    </button>
                  ) : (
                    <button
                      onClick={() => requestProduct(product.id)}
                      className="btnProducts"
                      style={{ marginLeft: "20px" }}
                    >
                      Solicitar Produto
                    </button>
                  )}
                </>
              )}

              <button
                style={{ marginLeft: "20px" }}
                onClick={() => {
                  history.push("/ProductsDetails", location.state);
                }}
                className="btnProducts"
              >
                Voltar
              </button>
            </S.ContentBtns>
          );
        }}
      />

      {/* <div style={{ marginTop: "-44px", display: "flex", flex: 1, justifyContent: "space-around" }} > */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div className="box" style={{ overflow: overFlow }}>
          <div className="boxHeader">
            <h3>Registro</h3>
          </div>
          {product && (
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="containerSmallBox">
                <S.Card>
                  <div style={{ marginLeft: 15, marginTop: 15, width: "80%" }}>
                    <S.Label>Categoria do produto</S.Label>
                    <S.Select
                      disabled={
                        !showButton ||
                        !products_edit ||
                        watchStatus == "3" ||
                        watchStatus == "2"
                      }
                      {...register("id_sku_category")}
                      style={{ cursor: "pointer" }}
                    >
                      {categories.map((options) => (
                        <option value={options.id} key={options.id}>
                          {options.name}
                        </option>
                      ))}
                    </S.Select>

                    <S.Label>Nome do produto</S.Label>
                    <S.Input
                      {...register("name")}
                      disabled={
                        !showButton ||
                        !products_edit ||
                        watchStatus == "3" ||
                        watchStatus == "2"
                      }
                      primary
                    />

                    <S.Label>Descrição</S.Label>
                    <S.TextArea
                      {...register("description")}
                      disabled={
                        !showButton ||
                        !products_edit ||
                        watchStatus == "3" ||
                        watchStatus == "2"
                      }
                      primary
                    />
                  </div>
                </S.Card>
                <S.Card>
                  <div style={{ marginLeft: 15, width: "85%" }}>
                    <S.Label>Código</S.Label>
                    <S.Input
                      disabled={
                        !showButton ||
                        !products_edit ||
                        watchStatus == "3" ||
                        watchStatus == "2"
                      }
                      {...register("ean_code")}
                      primary
                    />

                    <S.Label for="status">Status</S.Label>
                    <S.Select
                      disabled={
                        !showButton ||
                        (!products_edit && !changeproductstatus_edit) ||
                        watchStatus == "3" ||
                        watchStatus == "2"
                      }
                      {...register("id_sku_status")}
                      style={{ marginRight: 15, cursor: "pointer" }}
                      primary
                    >
                      {statusOptions.map((options, index) => (
                        <option
                          disabled={
                            options.name === "Instalado" ||
                            options.name === "Atribuído"
                          }
                          value={options.id}
                          key={`${index + options}`}
                        >
                          {options.name}
                        </option>
                      ))}
                    </S.Select>

                    <S.Label for="region">Time</S.Label>
                    <S.Select
                      disabled={
                        !showButton ||
                        !products_edit ||
                        watchStatus == "3" ||
                        watchStatus == "2"
                      }
                      {...register("id_group")}
                      style={{
                        marginRight: 15,
                        cursor: "pointer",
                        textTransform: "none",
                      }}
                      primary
                    >
                      <option
                        value={""}
                        disabled={true}
                        style={{ textTransform: "none" }}
                      >
                        Não Atribuído
                      </option>
                      {teams
                        .sort((a, b) =>
                          a.groupSection.section
                            .toLowerCase()
                            .localeCompare(b.groupSection.section.toLowerCase())
                        )
                        .map((team) => (
                          <option value={team.id} key={team.id}>
                            {`Grupo (${team.groupSection.section}) - Time (${team.name})`}
                          </option>
                        ))}
                    </S.Select>

                    <S.Label>Supervisor</S.Label>
                    <S.Input
                      disabled={true}
                      {...register("supervisor_fullname")}
                      primary
                    ></S.Input>
                    <S.Label>Promotor</S.Label>
                    <S.Input
                      disabled={true}
                      {...register("promotor_fullname")}
                      primary
                    ></S.Input>
                    <S.ShowSelect
                      view={ProductStatus.includes(Number(watchStatus))}
                    >
                      <S.Label>Tag Status</S.Label>
                      <S.Select
                        disabled={!showButton || tags.length === 0}
                        {...register("id_tag")}
                        primary
                      >
                        {tags.length > 0 ? (
                          <>
                            <option value="" disabled>
                              Selecione a tag de status
                            </option>
                            {tags.map((options, index) => (
                              <option
                                value={options.id}
                                key={`${index + options}`}
                              >
                                {options.name}
                              </option>
                            ))}
                          </>
                        ) : (
                          <option value="" disabled>
                            Sem tags cadastradas
                          </option>
                        )}
                      </S.Select>
                    </S.ShowSelect>
                    <div
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <S.InfoText>
                        <div
                          style={{
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <h2>Criado em:</h2>
                          <h2>Atualizado em:</h2>
                          <span></span>
                        </div>
                        <div style={{ flexDirection: "row" }}>
                          <p>
                            {product.created_at !== ""
                              ? NormalizedData(product?.created_at)
                              : "--/--/--T--:--"}
                          </p>
                          <p>
                            {product.updated_at !== ""
                              ? NormalizedData(product?.updated_at)
                              : "--/--/--T--:--"}
                          </p>
                        </div>
                      </S.InfoText>
                    </div>
                  </div>

                  {/* status[product.id_sku_status].name */}
                  <div
                    style={{
                      width: "90%",
                      fontWeight: "bold",
                      marginTop: 10,
                      alignSelf: "center",
                      color: "#c71e12",
                    }}
                  >
                    {product.requested
                      ? "Disponibilização solicitada. Aguardando a liberação do promotor."
                      : ""}
                  </div>
                </S.Card>
              </div>
              {showButton && (
                <div
                  style={{
                    marginTop: 20,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                  }}
                >
                  <div>
                    <S.ButtonSend type="submit">
                      {iconEdit} Salvar Alteração
                    </S.ButtonSend>
                  </div>
                  <div>
                    <S.ButtonSend
                      onClick={(_) => {
                        setShowButton(false);
                        resetForm();
                      }}
                    >
                      {iconClear} Cancelar Alteração
                    </S.ButtonSend>
                  </div>
                </div>
              )}
            </form>
          )}

          {overFlow == "auto" && customer ? (
            <S.ContainerCustomer>
              <h3>Dados da Instalação</h3>
              <S.ContentCustomer>
                <S.CustomerPhoto>
                  <S.ClientInfo onClick={handleClickAbout}>
                    Abrir detalhes do cliente {iconOpen}
                  </S.ClientInfo>
                  <img alt="StoreImage" src={storeImage} />
                </S.CustomerPhoto>
                <S.CustomerInfo>
                  <div>
                    <S.CustomerInfoData>
                      <label>ID do Cliente</label>
                      <span className="prisma-color">
                        {customer.increment_id}
                      </span>
                    </S.CustomerInfoData>
                    <S.CustomerInfoData>
                      <label>Nome do Estabelecimento</label>
                      <span className="prisma-color">
                        {customer.fantasy_name}
                      </span>
                    </S.CustomerInfoData>
                    <S.CustomerInfoData>
                      <label>Razão Social</label>
                      <span>{customer.social_name ?? "---"}</span>
                    </S.CustomerInfoData>
                    <S.CustomerInfoData>
                      <label>Natureza Jurídica</label>
                      <span>{customer.legal_nature ?? "---"}</span>
                    </S.CustomerInfoData>
                    <S.CustomerInfoData>
                      <label>
                        {customer.document.length > 11 ? "CNPJ" : "CPF"}
                      </label>
                      <span>{customer.document}</span>
                    </S.CustomerInfoData>
                  </div>
                  <div>
                    <S.CustomerManyInfoData>
                      <div>
                        <label>UF</label>
                        <span>{customer?.address_state}</span>
                      </div>
                      <div>
                        <label>Cidade</label>
                        <span>{customer?.address_city}</span>
                      </div>
                    </S.CustomerManyInfoData>
                    <S.CustomerManyInfoData>
                      <div>
                        <label>Bairro</label>
                        <span>{customer?.address_district}</span>
                      </div>
                      <div>
                        <label>CEP</label>
                        <span>{customer?.address_zip}</span>
                      </div>
                    </S.CustomerManyInfoData>
                    <S.CustomerManyInfoData>
                      <div>
                        <label>Logradouro</label>
                        <span>
                          {customer?.address} - {customer?.address_number}
                        </span>
                      </div>
                      <div>
                        <label>Complemento</label>
                        <span>{customer?.address_complement}</span>
                      </div>
                    </S.CustomerManyInfoData>
                  </div>
                </S.CustomerInfo>
              </S.ContentCustomer>
            </S.ContainerCustomer>
          ) : null}
        </div>

        <div className="box2">
          <div className="boxHeader">
            <h3>Histórico de Alterações</h3>
          </div>
          {productHistory.map((e, index) => (
            <div className="smallbox2" key={index + 1}>
              <div className="lineContainer">
                <p className="historyLabel">Por: </p>

                {e.id_type == 13 ? (
                  <p className="historyText">Sistema PrismaPro</p>
                ) : (
                  <p className="historyText">
                    {e.modified_by_name ? e.modified_by_name : e.modified_by}
                  </p>
                )}
              </div>
              <div className="lineContainer">
                {e.id_type != 11 && e.id_type != 12 && e.id_type != 13 ? (
                  <p className="historyLabel">Em:</p>
                ) : (
                  <p className="historyLabel">Tipo:</p>
                )}
                <p className="historyText">
                  {HistoryTypes[e.id_type].name ?? "---"}
                </p>
              </div>
              {e.id_type != 1 && e.id_type != 12 && e.id_type != 13 && (
                <>
                  <div className="lineContainer">
                    <p className="historyLabel">De:</p>
                    <p className="historyText">
                      {e.modified_from ? e.modified_from : "---"}
                    </p>
                  </div>
                  <div className="lineContainer">
                    <p className="historyLabel">Para:</p>
                    <p className="historyText">
                      {e.modified_to ? e.modified_to : "---"}
                    </p>
                  </div>
                </>
              )}
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  color: "gray",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                }}
              >
                <p
                  style={{
                    fontWeight: "bold",
                    fontSize: "10px",
                    margin: "3px 10px",
                  }}
                >
                  {NormalizedData(e?.created_at)}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* </div> */}
    </>
    // </div >
  );
}

export default ProductInfo;
