import { createContext } from 'react';

const StoreContext = createContext({
    userInfoStore: null,
    userProjectStore: null,
    setUserInfoStore: () => { },
    setUserProjectStore: () => { },
    onScroll: () => { },
    setOnScroll: () => { },
    setSocket: () => { },
    socket: null,

});

export const ScrollContext = createContext({   
    onScroll: () => { },
    setOnScroll: () => { },
   
});

export default StoreContext;