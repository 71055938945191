import React, { useEffect, useRef } from 'react';

import './styles.css';

import Streetview from 'react-google-streetview';
import styled from 'styled-components';
import GoogleMapReact, { fitBounds } from 'google-map-react';
import StoreMallDirectoryIcon from '@material-ui/icons/StoreMallDirectory';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
const googleMapsApiKey = process.env.REACT_APP_GOOGLE_API_KEY;

export default function ModalForm({ close, options, showAlert }) {
  const [selectedOption, setSelectedOption] = React.useState('street');
  const [mapCenter, setMapCenter] = React.useState({ lat: options.latitude, lng: options.longitude });
  const [zoom, setZoom] = React.useState(20);

  const streetViewPanoramaOptions = {
    position: { lat: options.latitude, lng: options.longitude },
    zoom: 1,
    addressControl: true,
    showRoadLabels: true,
    zoomControl: true,
  };

  const mapRef = useRef(null);
  const defaultProps = {
    center: {
      lat: options.latitude, lng: options.longitude
    },
    zoom: 20
  };
  useEffect(() => {
    //map focus on all markers
    if (selectedOption === 'map') {
      if (mapRef.current && options?.address?.lat) {
        const bounds = new window.google.maps.LatLngBounds();
        console.log("teste", parseFloat(options?.address?.lat), parseFloat(options?.address?.lng));

        bounds.extend(new window.google.maps.LatLng(options.latitude, options.longitude));

        bounds.extend(new window.google.maps.LatLng(parseFloat(options?.address?.lat), parseFloat(options?.address?.lng)));


        if (bounds.getNorthEast()) {
          const newBounds = fitBounds({ ne: { lat: bounds.getNorthEast().lat(), lng: bounds.getNorthEast().lng() }, sw: { lat: bounds.getSouthWest().lat(), lng: bounds.getSouthWest().lng() } }, { width: 900, height: 600 });
          console.log(newBounds);

          setMapCenter(newBounds.center)
          setZoom(newBounds.zoom)
        }
      }
    }
  }
    , [options, selectedOption]);

  return (
    <>
      {
        showAlert ? (
          <div
            onMouseDown={_ => close()}
            id="modalMapContainer"
          >
            <div
              id="modalMapContent"
              onMouseDown={(e) => e.stopPropagation()}
            >
              <TabBar>
                <button onClick={() => setSelectedOption('street')} className={selectedOption === 'street' ? 'active' : ''}>
                  Ver rua
                </button>
                <button onClick={() => setSelectedOption('map')} className={selectedOption === 'map' ? 'active' : ''}>
                  Ver no mapa
                </button>
              </TabBar>

              <div style={{ width: '900px', height: '600px', overflow: 'hidden' }}>
                {selectedOption === 'street' ?
                  <Streetview
                    apiKey={googleMapsApiKey}
                    streetViewPanoramaOptions={streetViewPanoramaOptions}
                  />
                  :
                  <GoogleMapReact
                    defaultCenter={defaultProps.center}
                    defaultZoom={defaultProps.zoom}
                    ref={mapRef}
                    center={mapCenter}
                    zoom={zoom - 0.5}
                  >
                    <EmojiPeopleIcon
                      style={{
                        color: '#EE5928',
                        fontSize: '40px',
                        marginTop: '-40px',
                        marginLeft: '-20px'
                      }}
                      lat={options.latitude}
                      lng={options.longitude}
                      text="Localização promotor"
                    />
                    {
                      options?.address?.lat && (
                        <StoreMallDirectoryIcon
                          style={{
                            color: '#EE5928',
                            fontSize: '40px',
                            marginTop: '-40px',
                            marginLeft: '-20px'
                          }}
                          lat={options?.address?.lat}
                          lng={options?.address?.lng}
                          text="Localização do cliente"
                        />
                      )}


                  </GoogleMapReact>
                }
              </div>


            </div>
          </div>
        ) : (
          <>

          </>
        )}
    </>
  )
}

const TabBar = styled.div`
  height:50px;
  display:flex;
  flex-direction:row;
  width:100% ;
  justify-content:center;
  position:absolute;
  z-index:5;
  border-radius: 20px !important;
  button{
    margin:5px;
    &:not(:last-child){
    margin-right:50px;
    }
    padding:10px;
    border-radius:10px;
    background-color:#fff ;
    color: #EE5928;
    font-weight:bold;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    &.active{
      background-color: #EE5928;
      color: #FFF;
    }
  }
`