import { GoogleMap, InfoWindow, Marker, MarkerClusterer, Polyline, useJsApiLoader } from '@react-google-maps/api';
import React, { useContext, useEffect, useRef, useState } from 'react';

import ModalForm from '../../components/ModalForm'
import StoreContext from '../../context/Context';
import api from '../../services/api';
import * as S from './styles'

import './styles.css'

const googleMapsApiKey = process.env.REACT_APP_GOOGLE_API_KEY;

const Maps = () => {
  const BRASIL_LAT_LNG = { lat: -14.235004, lng: -51.92528 }
  const { userProjectStore } = useContext(StoreContext);  
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: googleMapsApiKey,
  });
  const [teams, setTeams] = useState([])
  const [groups, setGroups] = useState([])
  const [filterPromoter, setFilterPromoter] = useState('')  
  const [promoters, setPromoters] = useState([])  
  const [stores, setStores] = useState([])  
  const [userCoords, setUserCoords] = useState(BRASIL_LAT_LNG);
  const [zoom, setZoom] = useState(5);
  const [selectedOption, setSelectedOption] = useState('promoters');
  const [filterGroup, setFilterGroup] = useState('')
  const [filterTeam, setFilterTeam] = useState('')
  const [map, setMap] = useState(null);  
  const [showModal, setShowModal] = useState(false);  
  const [selectedMarker, setSelectedMarker] = useState(null);

  const maps = useRef(null);
  const markersRef = useRef([]);

  useEffect(() => {
    let isMounted = true;
    
    const getData = async () => {
      try {
        const group_sections = (await api.get(`/groupSections?id_project=${userProjectStore.id}`))?.data ?? []
 
        if (isMounted) {       
          setGroups(group_sections)
        }
      } catch (error) {
        console.log(error)
      } 
    }

    getData()

    return () => {
      isMounted = false;
    };
  }, []);

    
  const getTeams = async (group) => {
    const response = await api.get(`/groupSections/${group}`)
    setTeams(response?.data?.teams || [])
  }

  useEffect(() => {
    if (filterGroup) {
      getTeams(filterGroup)
    }
  }, [filterGroup])

  const getPositionsUsers = async () => {
    try {
      if (!filterTeam) return 

      const data = (await api.get("/positions/users", { params: { id_project: userProjectStore.id, id_group: filterTeam} }))?.data ?? []

      setStores(data)
      setPromoters(data)
      
    } catch (error) {
      console.log(error)
    } 
  }

  useEffect(() => {
    if (filterTeam) {
      getPositionsUsers()
    }
  }, [filterTeam])

  useEffect(() => {
    const intervalId = setInterval(async () => {
      if (filterTeam && selectedOption === 'promoters') {
        const data = (await api.get("/positions/users", { params: { id_project: userProjectStore.id, id_group: filterTeam} }))?.data ?? []        
        setPromoters(data)
      }
    }, 60000); // 60000 ms = 1 minuto

    return () => clearInterval(intervalId);
  }, [filterTeam, selectedOption]);
  
  const onLoad = (mapInstance) => {
    setMap(mapInstance);
  };

  // const customerPositions = (customers) => {
  //   const positions = []
    
  //   customers.forEach(customer => {
  //     positions.push({ lat: Number(customer.lat), lng: Number(customer.lng) })
  //     positions.push({ lat: customer.promoter_lat, lng: customer.promoter_lng })
  //   }) 

  //   return positions
  // }

  const popupRef = useRef(null);

  const abrirPopup = () => {
    const larguraPopup = 500;
    const alturaPopup = 800;   

    popupRef.current = window.open('', 'meuPopup', `width=${larguraPopup},height=${alturaPopup}`);
  };

  const abrirLinkNoPopup = (url) => {
    // Verifica se o pop-up já está aberto e carrega o link nele
    if (popupRef.current && !popupRef.current.closed) {
      popupRef.current.location.href = url;
    } else {
      // Se o pop-up estiver fechado, abre o pop-up e carrega o link nele
      abrirPopup();
      popupRef.current.location.href = url;
    }
  };
  
  const StoreMarker = (promoter, clusterer, key) => {
    const length_positions =  promoter?.positions?.length ?? 0
  
    if (length_positions === 0) return   

    const first_position = promoter.positions[length_positions - 1]
    const last_position = promoter.positions[0]
    const name = promoter.email.split('@')[0]
    
    return (
      <div key={key}>
        {
          promoter?.customers?.length > 0 && promoter.customers.map((customer, index) => (
            <>
              <Marker 
                key={customer.fantasy_name}
                icon={
                  { 
                    url: `${process.env.PUBLIC_URL}/mdi_human-hiking.png`,
                    scaledSize: new window.google.maps.Size(50, 50)          
                  } 
                } 
                draggable={true}
                position={{ lat: customer.promoter_lat, lng: customer.promoter_lng }} 
                options={{ 
                  label: { 
                    text: ` Hora: ${customer.hour} - ${customer.fantasy_name} - (${customer.phase})`,    
                    className: 'customer-store'               
                  }, 
                }}
                onClick={() => {                
                  setUserCoords({ lat: Number(customer.lat), lng: Number(customer.lng) })
                  setZoom(17)
                }}
              />
              <Marker 
                key={customer.id}
                icon={
                  { 
                    url: `${process.env.PUBLIC_URL}/store.png`,
                    scaledSize: new window.google.maps.Size(50, 50)          
                  } 
                } 
                draggable={true}
                position={{ lat: Number(customer.lat), lng: Number(customer.lng) }} 
                options={{ 
                  label: { 
                    text: ` Hora: ${customer.hour} - ${customer.fantasy_name} - (${customer.phase})`,    
                    className: 'customer-store'               
                  }, 
                }}
                onClick={() => {  
                  abrirLinkNoPopup(`/CustomerInfo/${customer.id}`)  
                  //window.open(`/CustomerInfo/${customer.id}`, '_blank')
                }}
              />

            </>
          ))
        }        

        {
          promoter.positions.reverse().map((position, index) => (
            <Marker 
              key={index}
              icon={
                index === 0 || index + 1 === length_positions ? { 
                  url: `${process.env.PUBLIC_URL}/mdi_human-hiking.png`,
                  scaledSize: new window.google.maps.Size(50, 50)          
                } : {
                  path: "M-1.547 12l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM0 0q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z",
                  fillColor: "red",
                  fillOpacity: 0.8,
                  strokeWeight: 0,
                  rotation: 0,
                  scale: 2,
                  anchor: new window.google.maps.Point(0, 20),
                  scaledSize: new window.google.maps.Size(50, 50)  
                }
              } 
              clusterer={index === 0 || index + 1 === length_positions ? undefined : clusterer}
              draggable={true}
              position={{ lat: position.lat, lng: position.lng }} 
              options={{ 
                label: { 
                  text: index === 0 || index + 1 === length_positions ? `${index === 0 ? 'Inicio' : 'Fim'}: ${position.hour} - ${name}` : `Hora: ${position.hour} - ${name}`, 
                  className: 'marker-final',                  
                }, 
                animation: !(index === 0 || index + 1 === length_positions) ? window.google.maps.Animation.BOUNCE : window.google.maps.Animation.DROP,
              }}
              onClick={() => {                
                if (index === 0 || index + 1 === length_positions ) {
                  const lat = index === 0 ? last_position.lat : first_position.lat
                  const lng = index === 0 ? last_position.lng : first_position.lng
                  setUserCoords({ lat, lng })
                  setZoom(17)
                } else {
                  setUserCoords({ lat: position.lat, lng: position.lng })
                  setZoom(20)
                }
              }}
            />
          ))
        }
        <Polyline
          path={promoter?.positions?.reverse()}
          options={{
            strokeColor: '#4B0082',
            strokeOpacity: 1,
            strokeWeight: 3,
          }}
        />
      </div>
    )  
  };
 
  const PromoterMarker = (promoter, index) => {
    const length_positions =  promoter?.positions?.length ?? 0
  
    if (length_positions === 0) return 
  
    const first_position = promoter.positions[length_positions - 1]
    const last_position = promoter.positions[0]
    const name = promoter.email.split('@')[0]
  
    return (
      <>
        <Marker 
          draggable={true}
          icon={{ 
            url: `${process.env.PUBLIC_URL}/mdi_human-hiking.png`, 
            scaledSize: new window.google.maps.Size(50, 50)          
          }} 
          position={{ lat: first_position.lat, lng: first_position.lng }} 
          options={{ 
            label: { 
              text: `Inicio: ${first_position.hour} - ${name}`, 
              className: 'marker-initial',
            }, 
          }}
          onClick={() => {
            setUserCoords({ lat: last_position.lat, lng: last_position.lng })
            setZoom(15)
          }}
        />      
        <Marker 
          draggable={true}
          icon={{ 
            url: `${process.env.PUBLIC_URL}/mdi_human-hiking.png`, 
            scaledSize: new window.google.maps.Size(50, 50)          
          }} 
          position={{ lat: last_position.lat, lng: last_position.lng }} 
          options={{ 
            label: { 
              text: `Fim: ${last_position .hour} - ${name}`, 
              className: 'marker-final'
            }, 
          }}
          ref={(marker) => markersRef.current[index] = marker}
          animation={window.google.maps.Animation.DROP}
          onClick={() => {
            setUserCoords({ lat: first_position.lat, lng: first_position.lng })
            setZoom(15)
          }}
        />     
        <Polyline
          path={[first_position, last_position]}
          options={{
            strokeColor: '#4B0082',
            strokeOpacity: 1,
            strokeWeight: 3,
          }}
        />
      </>
    )  
  };

  const updateMapMarkers = (marker, lat, lng) => {
    marker.marker.position.lat(lat)
    marker.marker.position.lng(lng)
  };

  useEffect(() => {
    if (maps.current && markersRef.current.length === promoters.length && selectedOption === 'promoters') {
      promoters.forEach((promoter, index) => {
        promoter.positions.forEach(position => {
          updateMapMarkers(markersRef.current[index], position.lat, position.lng);
        })
      });
    }
  }, [promoters]);

  const routePromoter = (filter) => {
    if (!filter) return
       
    setStores(promoters.filter(p => p.id === filter))    
    setSelectedOption('store')
    setShowModal(false)    
  }

  const handleMarkerClick = marker => {
    setSelectedMarker(marker);
  };

  const handleClusterClick = cluster => {
    const markers = cluster.getMarkers()
    const text = markers.map(marker => marker.label.text).join(', ')
    
    handleMarkerClick({ label: `Total de ${markers.length} sincronismos próximos: ${text}`, lat: markers[0].getPosition().lat(), lng: markers[0].getPosition().lng() })
  };

  return isLoaded ? (
    <>
      <S.Container>
        <S.Card>
          <S.Select
            onChange={(elm) => {
              setFilterGroup(elm.target.value)
              setFilterTeam('')
            }} 
          >
              <option value='' >Grupos</option>     
              {
                groups.map(group => (
                  <option
                    key={group.id}
                    value={group.id}
                  >
                    {group?.section?.toLowerCase()}
                  </option>
                ))
              }           
          </S.Select>
          <S.Select
            disabled={teams.length === 0}
            value={filterTeam}
            onChange={(elm) => {
              setSelectedOption('promoters')
              setZoom(5)
              setUserCoords(BRASIL_LAT_LNG)
              setFilterTeam(elm.target.value)
            }} 
          >
              <option value='' selected>Times</option>      
              {
                teams.map(team => (
                  <option
                    key={team.id}
                    value={team.id}
                  >
                    {team.name}
                  </option>
                ))
              }                
          </S.Select>
        </S.Card>
        <S.TabBar>
            <button 
              onClick={() => setSelectedOption('promoters')} 
              className={selectedOption === 'promoters' ? 'active' : ''}
              disabled={promoters.length === 0}
            >
              Ver promotores em tempo real 
            </button>
            <button 
              onClick={() => {
                setShowModal(true)
              }} 
              className={selectedOption === 'store' ? 'active' : ''}
              disabled={promoters.length === 0}
            >
              Ver rotas dos promotores
            </button>
        </S.TabBar>
        {filterTeam && <p>Total de promotores na rua: {promoters.length}</p>}
      </S.Container>
      {
        selectedOption === 'promoters' &&
          <GoogleMap
            ref={maps}
            mapContainerStyle={{ width: '100%', height: '71vh' }}
            center={userCoords}
            zoom={zoom}
            onLoad={onLoad}
            options={{
              clickableIcons: true, 
              controlSize: false, 
            }}  
          > 
            {
              promoters.length > 0 && promoters.map((promoter, index) => (
                <div key={promoter.id}>
                  {PromoterMarker(promoter, index)}
                </div>
              ))
            }          
          </GoogleMap>        
      }
      {
        selectedOption === 'store' &&
        <GoogleMap
          mapContainerStyle={{ width: '100%', height: '71vh' }}
          center={userCoords}
          zoom={zoom}
          onLoad={onLoad}
          options={{
            clickableIcons: true, 
            controlSize: false,
          }}  
        > 
          <MarkerClusterer
            gridSize={60}
            onClick={handleClusterClick}
          >
              {(clusterer) =>
                stores.length > 0 && stores.map((store) => (
                  <>
                    {StoreMarker(store, clusterer, store.id)}
                  </>
                ))
              }
          </MarkerClusterer>

          {selectedMarker && (
            <InfoWindow
              position={{ lat: selectedMarker.lat, lng: selectedMarker.lng}}
              onCloseClick={() => setSelectedMarker(null)}
            >
              <div className='infoWindow'>{selectedMarker.label}</div>
            </InfoWindow>
          )}  
        </GoogleMap>
      }
    <ModalForm 
      title="Promotores que acessaram o aplicativo hoje" 
      customButton={() => <S.Button onClick={() => {
        setZoom(5)
        setSelectedMarker(null)
        routePromoter(filterPromoter)
      }}>Verificar Rotas</S.Button>} 
      close={() => {
        setShowModal(false)
      }} 
      showAlert={showModal}          
    >
      <S.ModalFormContainer>            
        <S.MySelect      
          id='promoter'      
          onChange={(e) => {
            setFilterPromoter(e.target.value)
            setUserCoords(BRASIL_LAT_LNG)
            setZoom(2)
          }}
          value={filterPromoter}
        >
          <option
            value={'Selecione o promotor'}
          >
            Selecione o promotor
          </option>
          {
            promoters.length > 0 && promoters.map(promoter => {
              return (
                <option value={promoter.id} key={promoter.id}>{promoter.email}</option>
              )
            })
          }
        </S.MySelect>
      </S.ModalFormContainer>
    </ModalForm>
      
    </>
  ) : null;
}

export default Maps