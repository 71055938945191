import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from "react-router-dom";
import './styles.css';

import api from '../../services/api';

import iconUser from '../../assets/icons/iconUser.png';
import iconUserColor from '../../assets/icons/iconUserColor.png';
import iconProducts from '../../assets/icons/iconProdutos.png';
import iconTransfer from '../../assets/icons/iconTransferencia.png';
// import { getField } from '../../components/Search';

// import { RadioButtonChecked, RadioButtonUnchecked, Add } from '@material-ui/icons';

function ProductsTransfer(props) {
  let { idProduct } = useParams();
  const history = useHistory();
  const [products, setProducts] = useState([]);
  const [users, setUsers] = useState([]);

  const selectProduct = {
    file: { uri: iconProducts },
    name: 'Selecionar produto'
  }
  const selectUser = {
    file: { uri: iconUserColor },
    username: 'Selecionar promotor'
  }

  const [productTransfer, setProductTransfer] = useState(selectProduct);
  const [userTransfer, setUserTransfer] = useState(selectUser);

  useEffect(() => {
    api.get('/products').then((response) => {
      if (response?.data) {
        const data = response?.data.filter(
          product =>
            product.category?.id_project === props.userProject.id &&
            product.id_sku_status !== 3
        )
        setProducts(data)
      }
      api.get('/users').then(response => {
        if (response?.data) {
          const data = response?.data.filter(
            user => user.projects.some(
              project => project.id === props.userProject.id
            )
          )
          setUsers(data);
        }
      }).catch(err => {
        console.log('users ', err);
      })

    }).catch(err => {
      console.log('products ', err);
    })
  }, [history, idProduct, props.userProject.id])

  function handleSubmit(event) {
    event.preventDefault();
  }

  function transferProduct() {
    const assignment = {
      id_sku: productTransfer.id,
      id_user: userTransfer.id
    }
    api.post('/assignment', assignment).then(response => {
      console.log('assignment ', response.data);
      if (response?.data) {
        history.push('/Products')
      }
    }).catch(err => {
      console.log('assignment ', err);
    })
  }

  function selectedItem(id, itemId) {
    if (id) {
      if (id === itemId) {
        return 'transferSelected'
      } else {
        return 'transferNotSelected'
      }
    } else {
      return ''
    }
  }

  return (
    <div className='productList'>
      <div className='newProductTitleContainer'>
        <div className='newProductTitle'>
          <label>Transferência de posse</label>
          <img src={iconTransfer} alt="userIcon" />
        </div>
      </div>
      <form id='productTransferContainer' onSubmit={(event) => handleSubmit(event)}>
        <div id='productTransferLeft'>
          {
            products.map(item => (
              <button key={item.id}
                className={`productTransferContainer ${productTransfer.id && productTransfer.id !== item.id ? 'transferNotSelected' : ''}`}
                onClick={() => {
                  setProductTransfer(item)
                }}>
                <div className='productTransferInfoContainer'>

                  <label className='productInfoLabel'>Nome</label>
                  <p className='productInfo, title'>{item.name}</p>
                  <label className='productInfoLabel'>Categoria</label>
                  <p className='productInfo'>{item.category?.name}</p>
                  <label className='productInfoLabel'>Código</label>
                  <p className='productInfo'>{item.ean_code}</p>

                </div>
                <div className={`productTransferImgContainer ${item.id_sku_status === 1 ? 'productAtive' : ''}`}>
                  <div className='productImg productTransferImg'>
                    <img src={item.file?.uri ? item.file.uri : iconTransfer} alt="product" />
                  </div>
                  <label>{item.status?.name}</label>
                </div>
              </button>
            ))
          }
        </div>
        <div id='productTransferMain'>
          <div id='productTransferRow'>
            <div className='cardTransferProduct'>
              <div id='cardTransferProductImg'>
                <img src={productTransfer.file?.uri} alt='product' />
              </div>
              <div>
                <label className={productTransfer.id ? 'textColorful' : ''}>{productTransfer.name}</label>
                <label className={`textDemiBold ${productTransfer.id ? 'textColorful' : ''}`}>
                  {productTransfer.ean_code}
                </label>
              </div>
            </div>
            <div className='cardTransferUser'>
              <div id='cardTransferUserImg'>
                <img
                  src={userTransfer.file?.uri ? userTransfer.file?.uri : iconUser}
                  alt='user' />
              </div>
              <div>
                <label className={userTransfer.id ? 'textColorful' : ''}>{
                  userTransfer.fullname
                    ? userTransfer.fullname
                    : userTransfer.username
                }</label>
              </div>
            </div>
          </div>
          {productTransfer.id && userTransfer.id && (
            <div>
              <button id='productTransferButton' onClick={() => { transferProduct() }}>
                Fazer transferência
              </button>
            </div>
          )}
        </div>
        <div id='productTransferRight'>
          {
            users.map(item => (
              <button
                className={`${selectedItem(userTransfer.id, item.id)}`}
                key={item.id}
                onClick={() => {
                  setUserTransfer(item)
                }}>
                <div className='userProductsTransferImg'>
                  <img src={item.file?.uri ? item.file?.uri : iconUser} alt='user' />
                </div>
                <label>{item.fullname
                  ? item.fullname
                  : item.username
                }</label>
              </button>
            ))
          }
        </div>
      </form>
    </div>
  )
}

export default ProductsTransfer;
