import styled from'styled-components'
import {Input, Label} from '../../components'

export const Root = styled.div`
  height:100%;
  font-family: 'Roboto';
  &::before{
    display: block;
    content: ""
  }
`

export const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  align-items: center;
  bottom: 0;
  justify-content: center;
  overflow: auto;
  right: 0;
  display: flex;
  opacity: 1;
  z-index: 100000;
  padding-top: 25px;
  padding-bottom: 25px;
  margin: 0 auto; 
  display: flex; 
`

export const Container = styled.div`
  display: flex;
  background: #FFF;
  width: 50%;
  height: auto;
  border-radius: 10px;
  font-size: 1.4rem;
  padding: 10px;
  p {
    color:#EE5928;
  }
`

export const IconDiv = styled.div `
  display:flex;
  align-items: center;
  justify-content: center;
  margin-bottom:15px;
`

export const TitleDiv = styled.div `
  display:flex;
  align-items:center;
  justify-content: center;
  margin-bottom:15px;
`

export const Title = styled.span `
  color: #EE5928;
  font-weight:500;
  font-size:18px;
`

export const SubTitleDiv = styled(TitleDiv) `
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-left:10px;
  padding-right:10px;
`

export const SubTitle = styled.span `
  color: #999;
  font-weight:400;
  font-size:14px;
  text-align: center;
`

export const Header = styled.div`
  display: flex;
  align-items: flex-end;
  background-color: white;
  // margin-bottom:20px;
  button{
    background-color: transparent;
    color: #EE5928;
  }
`

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
`

export const DivMain = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  padding: 0 50px;
`

export const MyInputModal = styled.textarea `
  height:60px;
  align-self: left;
  border:${props => (props.isEditing ? '2px solid #AAA' : '0 none')};
  margin: 10px 0px 0px;
  padding: 5px;
  width: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  border-radius: 15px;
  color: ${props => (props.orange ? '#EE5928' : '#444444')};
  :disabled{
    background-color: white;
  }
`

export const MyLabel = styled(Label)`
  margin: 10px 0px 0px;
  font-size: 15px;
  align-self: flex-start;
  color:#EE5928;
  font-weight: 500;
  opacity:1;
`

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 15px 0;
`

export const ButtonModal = styled.button`
  background-color: #EE5928;
  text-transform: uppercase;
  color: white;
  height: 30px;
  width: 100px;
  margin-right:10px;
  font-size: 12px;
  border-radius: 15px;
  display: flex;
  justify-content:center;
  align-items: center;
  flex-direction: row;
`
