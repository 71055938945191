import React, {  useState } from 'react'

import CloseIcon from '@material-ui/icons/Close'

import * as S from './styles'
import CheckIcon from '@material-ui/icons/Check';
import HelpIcon from '@material-ui/icons/Help';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import { FaExpeditedssl, FaEye } from 'react-icons/fa'

const GroupRemoveModal = (props) => {
  const [newPasswordCheck, setNewPasswordCheck] = useState('')
  const [newPasswordCheckEye, setNewPasswordCheckEye] = useState(false)

  const [newPasswordConfirmationCheck, setNewPasswordConfirmationCheck] = useState('')
  const [newPasswordConfirmationCheckEye, setNewPasswordConfirmationCheckEye] = useState(false)


  const resetStates = () => {
    setNewPasswordCheck('')
    setNewPasswordCheckEye(false)
    setNewPasswordConfirmationCheck('')
    setNewPasswordConfirmationCheckEye(false)
  }

  const { 
    active, 
    handleClickClose = () => {}, 
    handleClickSave,
    setNewPassword,
    setNewPasswordConfirmation
   
  } = props

  return(
    <>
      { active  && (
        <>
          <S.Root >
            <S.Modal>
              <S.Container>
                <S.Header>
                  <button onClick={() => {
                    resetStates()
                    handleClickClose()
                  }}>
                    <CloseIcon style={{width:'30px', height:'30px'}} />
                  </button>
                </S.Header>
                <S.IconDiv>
                  <HelpIcon style={{color:'#EE5928', width:'80px', height:'80px'}} />
                </S.IconDiv>
                <S.TitleDiv>
                    <S.Title>Deseja realmente trocar a senha deste usuário?</S.Title>
                </S.TitleDiv>
                <S.SubTitleDiv>
                  <S.SubTitle>
                    Esteja ciente de que alterar a senha, o usuário não poderá acessar o sistema a menos que a nova senha seja enviada para ele ou ele faça nova alteração.
                  </S.SubTitle>
                </S.SubTitleDiv>
                <S.InputContainer>
                    <S.InputDiv>
                      <S.InputPassword 
                        autoFocus
                        placeholder='Nova Senha' 
                        type={newPasswordCheckEye ? 'text' : 'password'} 
                        onChange={(e) => { 
                          const value = e.target.value
                          setNewPassword(value)
                          setNewPasswordCheck(e.target.value)
                        }}
                      />
                      <span id="newPasswordCheck"><FaExpeditedssl/></span>
                      <span id="newPasswordCheckEye" onClick={() => setNewPasswordCheckEye(!newPasswordCheckEye)}><FaEye /></span>
                    </S.InputDiv>
                    <S.InputDiv>
                      <S.InputPassword 
                        placeholder='Confirmar Senha' 
                        type={newPasswordConfirmationCheckEye ? 'text' : 'password'} 
                        onChange={(e) => {
                          const value = e.target.value
                          setNewPasswordConfirmation(value)
                          setNewPasswordConfirmationCheck(value)
                        }}
                      />
                      <span id="newPasswordConfirmationCheck"><FaExpeditedssl/></span>
                      <span id="newPasswordConfirmationCheckEye" onClick={() => setNewPasswordConfirmationCheckEye(!newPasswordConfirmationCheckEye)}><FaEye /></span>
                    </S.InputDiv>
                   {
                      newPasswordCheck !== newPasswordConfirmationCheck && 
                        <p style={{ color: 'red', fontSize: 14, fontWeight: 'bold' }}>Nova senha e Confirmar senha não coincidem!</p>                   
                   }
                   {  
                      newPasswordCheck === newPasswordConfirmationCheck && 
                      newPasswordCheck.length < 8 && newPasswordCheck.length > 0 && 
                      newPasswordConfirmationCheck.length < 8 && newPasswordConfirmationCheck.length > 0 &&
                        <p style={{ color: 'red', fontSize: 14, fontWeight: 'bold' }}>A senha tem que conter no minímo 8 caracteres!</p> 
                   }
                </S.InputContainer>
                <S.Footer>
                  <S.ButtonModal return
                    onClick={() => {
                      resetStates()
                      handleClickClose()
                    }}
                  >
                    <KeyboardReturnIcon /> Voltar
                  </S.ButtonModal> 
                  <S.ButtonModal 
                    onClick={() => {
                      handleClickSave()
                      resetStates()
                    }} 
                    disabled={
                      newPasswordCheck !== newPasswordConfirmationCheck || newPasswordCheck.length === 0 || newPasswordCheck.length < 8
                    }
                  >
                    <CheckIcon /> Salvar
                  </S.ButtonModal> 
                </S.Footer>
              </S.Container>
            </S.Modal>
          </S.Root>
        </>
        )
      }
    </>
  )
} 

export default GroupRemoveModal