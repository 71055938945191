import React, { useMemo, useCallback, useEffect } from 'react';
import { useTable, useExpanded } from 'react-table';
import { nameCase } from '../../../../../utils/formatter';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/KeyboardArrowRight'
import { TableStyles, ColumnContainer, Accessor, Avatar, Info, Abbreviation } from './styles';

function getNameInitials(abbreviation) {
  switch (abbreviation) {
    case 'M':
      return 'G';
    case 'G':
      return 'C';
    case 'T':
      return 'S';
    default:
      return '';
  }
}

function Table({ columns: userColumns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    toggleAllRowsExpanded,
  } = useTable({ columns: userColumns, data }, useExpanded)

  useEffect(() => {
    toggleAllRowsExpanded(true);
  }, [toggleAllRowsExpanded]);

  return (
    <TableStyles>
      <div className="tableWrap">
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps()}>
                    <ColumnContainer>
                      {column.render('Header')}
                    </ColumnContainer>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          
          <tbody {...getTableBodyProps()}>
            {rows.map((row, rowIndex) => {
              prepareRow(row)
           
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell, cellIndex) => {
                    const colspan = rowIndex === 0 && cellIndex === 1 ? 3 : 0;

                    const isProdColumn = ['prod_1', 'prod_2', 'prod_3', 'prod_4'].includes(cell.column.id);

                    const isTotalColumn = cell.column.id.substring(0, 6) === 'total_' && cell.column.id.substring(0, 10) !== 'total_days';
                    
                    const isDayColumn = cell.column.id.substring(0, 4) === 'day_';

                    return (
                      <td {...cell.getCellProps()} colSpan={colspan}>
                        <ColumnContainer
                          depth={row.depth}
                          isProdColumn={isProdColumn}
                          isTotalColumn={isTotalColumn}
                          isDayColumn={isDayColumn}
                          valueColumn={cell.value}
                        >
                          {cell.render('Cell')}
                        </ColumnContainer>
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </TableStyles>
  )
}

const VisitsAndSalesTable = ({ visitsAndSales, projectName }) => {
  const columns = useMemo(
    () => {
      const dayColumns = { 1: [], 2: [], 3:[], 4:[] };

      const totalColumns =  { 1: [], 2: [], 3:[], 4:[] };

      visitsAndSales.days.forEach(day => {
        if (day.day) {
          dayColumns[day.week].push({
            Header: new Intl.DateTimeFormat('pt-BR',     { 
              day: '2-digit',
              month: '2-digit',
            }).format(new Date(`${day.day}T03:00:00`)),
            accessor: (d) => <>
                               <sup>
                                 {d[`day_${day.day}_visits`]}
                               </sup>
                               {d[`day_${day.day}_sales`]}
                               {d['hc_is_visible'] && (
                                  <sub>
                                    {d[`day_${day.day}_hc`] - d[`day_${day.day}_actives`]}
                                  </sub>
                               )}                            
                             </>,
            id: `day_${day.day}`,
          });
        } else if (day.week_of_total) {
          totalColumns[day.week_of_total].push({
            Header: `Total`,
            accessor: (d) => <>
                               <sup>
                                 {d[`total_${day.week_of_total}_visits`]}
                               </sup>
                               {d[`total_${day.week_of_total}_sales`]}
                               {d['hc_is_visible'] && (
                                  <sub>
                                    {d[`total_${day.week_of_total}_hc`] - d[`total_${day.week_of_total}_actives`]}
                                  </sub>
                               )}  
                             </>,
            id: `total_${day.week_of_total}`,
          });

          totalColumns[day.week_of_total].push({
            Header: 'Prod',
            accessor: `prod_${day.week_of_total}`,
          });
        }
      })

      const tableColumns = [
        {
          Header: '',
          accessor: (d) => <Accessor>
              {['M', 'G', 'T'].includes(d['level']) && (
                <Avatar level={d['level']}>
                  <Abbreviation>{getNameInitials(d['level'])}</Abbreviation>
                </Avatar>
              )}
                          
              <Info>
                {d['title']}
                  <h2>
                    {d['subtitle']}
                  </h2>
              </Info>
            </Accessor>,
          id: 'managers_groups_and_teams',
        },
        {
          Header: 'Data Admissão',
          accessor: 'hire_date',
        },
        {
          Header: 'Total Dias',
          accessor: 'total_days',
        },
        ...dayColumns[4].reverse(),
        ...totalColumns[4],
        ...dayColumns[3].reverse(),
        ...totalColumns[3],
        ...dayColumns[2].reverse(),
        ...totalColumns[2],
        ...dayColumns[1].reverse(),
        ...totalColumns[1],
      ];

      return [
        {
          id: 'expander',
          Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
            <span {...getToggleAllRowsExpandedProps()}>
              {isAllRowsExpanded ? <ExpandMoreIcon /> : <ExpandLessIcon />}
            </span>
          ),
          Cell: ({ row }) => {
            return row.canExpand ? (
              <span
                {...row.getToggleRowExpandedProps({
                  style: {
                    paddingLeft: `${row.depth * 2}rem`,
                    minWidth: '88px',
                  },
                })}
              >
                {row.isExpanded ? <ExpandMoreIcon /> : <ExpandLessIcon />}
              </span>
            ) : null},
        },
        {
          Header: 'Visitas e Vendas',
          columns: tableColumns,
        }
      ]
    },
    [visitsAndSales]
  )

  function getDays(days, data) {
    days.forEach(day => {
      if (day.day) {
        data[`day_${day.day}_visits`] = day.visits
        data[`day_${day.day}_sales`] = day.sales
        data[`day_${day.day}_actives`] = day.actives
        data[`day_${day.day}_hc`] = data.hc
      } else if (day.week_of_total) {
        data[`total_${day.week_of_total}_visits`] = day.total_visits
        data[`total_${day.week_of_total}_sales`] = day.total_sales
        data[`total_${day.week_of_total}_actives`] = day.total_actives
        data[`total_${day.week_of_total}_hc`] = day.worked_days * data.hc
        data[`prod_${day.week_of_total}`] = new Intl.NumberFormat('pt-BR', { style: 'decimal', maximumFractionDigits: 2 }).format(day.prod)
      }
    })
  }

  const createDataTable = useCallback(() =>  {
    const tableData = [];

    const projectData = { title: nameCase(projectName), hc: visitsAndSales.hc, hc_is_visible: true };

    getDays(visitsAndSales.days, projectData);

    const managerRows = [];

    for (const manager of visitsAndSales.managers) {
      const managerData = {
        title: nameCase(manager.manager_name ?? '---'),
        level: 'M',
        hire_date: manager?.manager_hire_date ? new Intl.DateTimeFormat('pt-BR',{
          day: '2-digit',
          month: '2-digit',
          year: 'numeric'
        }).format(new Date(`${manager.manager_hire_date}T03:00:00`))
        : '---',
        total_days: manager.manager_total_days,
        hc: manager.hc,
        hc_is_visible: true,
      };

      getDays(manager.days, managerData);

      const groupRows = [];

      for (const group of manager.groups) {
        const groupData = {
          title: nameCase(group.coordinator_name),
          subtitle: `Grupo: ${nameCase(group.group_name ?? '---')}`,
          level: 'G',
          hire_date: group?.coordinator_hire_date ? new Intl.DateTimeFormat('pt-BR',{ 
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
          }).format(new Date(`${group.coordinator_hire_date}T03:00:00`))
          : '---',
          total_days: group.coordinator_total_days,
          hc: group.hc,
          hc_is_visible: true,
        };

        getDays(group.days, groupData);

        const teamRows = [];

        for (const team of group.teams) {
          const teamData = {
            title: nameCase(team.supervisor_name),
            subtitle: `Time: ${nameCase(team.team_name)}`,
            level: 'T',
            hire_date: team?.supervisor_hire_date ? new Intl.DateTimeFormat('pt-BR',{ 
              day: '2-digit',
              month: '2-digit',
              year: 'numeric'
            }).format(new Date(`${team.supervisor_hire_date}T03:00:00`))
            : '---',
            total_days: team.supervisor_total_days,
            hc: team.hc,
            hc_is_visible: true,
          };

          getDays(team.days, teamData);

          const promoterRows = [];

          for (const promoter of team.promoters) {
            const promoterData = {
              title: nameCase(promoter.promoter_name),
              level: 'P',
              hire_date: promoter?.hire_date ? new Intl.DateTimeFormat('pt-BR', { 
                day: '2-digit',
                month: '2-digit',
                year: 'numeric'
              }).format(new Date(`${promoter.hire_date}T03:00:00`)) 
              : '---',
              total_days: promoter.total_days,
              hc: 1,
              hc_is_visible: false,
            };

            getDays(promoter.days, promoterData);

            promoterRows.push(promoterData);
          }

          teamData.subRows = promoterRows;
          teamRows.push(teamData);
        }

        groupData.subRows = teamRows;
        groupRows.push(groupData);
      }

      managerData.subRows = groupRows;
      managerRows.push(managerData)
    }

    projectData.subRows = managerRows;
    tableData.push(projectData)

    return tableData;
  }, [projectName, visitsAndSales]);

  const data = useMemo(() => createDataTable(), [createDataTable]);
  
  return (
    <Table columns={columns} data={data} />
  )
}

export default VisitsAndSalesTable;