import React from 'react'

import CloseIcon from '@material-ui/icons/Close'

import * as S from './styles'


export const Modal = (props) => {
  const { 
    active, 
    titleContent = "", 
    handleClickCloseModal, 
    children
  } = props

  return(
    <>
      { active  && (
        <>
          <S.Root >
            <S.Modal>
              <S.Container>
                  <S.Header>
                    <S.DivHeader>
                      <p>{titleContent}</p>
                      <span></span>
                      <button onClick={() => handleClickCloseModal()}>
                        <CloseIcon />
                      </button>
                    </S.DivHeader>
                  </S.Header>
                  <S.Main>
                      {children}
                  </S.Main>
              </S.Container>
            </S.Modal>
          </S.Root>
        </>
        )
      }
    </>
  )
} 