import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  width: 100%;
  border-radius: 15px;
  box-shadow: 5px 5px 15px 5px rgb(0 0 0 / 15%);
  padding: 10px;
  margin-bottom: 20px;
  font-family: 'Roboto';
`;

export const WithoutDataText = styled.h1`
  font-family: 'Roboto';
  font-size: 14px;
`;