import React from 'react';
import * as S from '../forgot-password.styles'
import { Spinner } from '../../../components'

const ChangePasswordSection = (props) => {

  const { register, errors, handleSubmitPassword, handleSubmit, notEqualError, loading} = props;

  return (
    <>
    <S.newPasswordDiv>
      <S.newPasswordDivTitle>Criar nova senha</S.newPasswordDivTitle>
      <S.newPasswordSpan>Digite e confirme sua nova senha para <br /> realizar a alteração.</S.newPasswordSpan>
    </S.newPasswordDiv>
    <form onSubmit={handleSubmit(handleSubmitPassword)}>
      <div className="divInputandButton">
        <S.Label for="password" opacity>Nova senha</S.Label>
        <S.Input
          id="password"
          type="password"
          placeholder="Digite sua nova senha"
          {...register('password',
          {required: true}
        )} 
        />
        {errors.password && errors.password.type === "required" && <S.PValidator>Insira uma senha</S.PValidator>}
        <S.Label for="confirmPassword" opacity>Confirmação da Senha</S.Label>
        <S.Input 
        type="password" 
        id="confirmPassword" 
        placeholder="Confirm sua nova senha"
        {...register('confirmPassword',
          {required: true}
        )} 
        />
      </div>
      {errors.confirmPassword && errors.confirmPassword.type === "required" && <S.PValidator>Confirme a senha</S.PValidator>}
      {notEqualError && <S.PValidator>Senha e confirmação de senha devem ser iguais.</S.PValidator>}
      <div className="divInputandButton"> 
        <S.MyButton type='submit' disabled={errors.password || errors.confirmPassword || notEqualError} centered={true}>
          {loading && <Spinner small/>}
          {!loading && <>Salvar</>} 
        </S.MyButton>
      </div>
    </form>
  </>
  )
}

export default ChangePasswordSection;