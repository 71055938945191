import React, {useState, useEffect} from 'react';
import {useHistory} from "react-router-dom";
// import {useHistory, useParams} from "react-router-dom";
import './styles.css';

import api from '../../services/api';

import iconUsers from  '../../assets/icons/icon_usuarios.svg';
import iconClients from '../../assets/icons/icon_clientes_vendas.svg';
import iconSchedule from '../../assets/icons/icon_agenda.svg';
import iconProducts from '../../assets/icons/icon_produtos.svg';
import iconCompany from  '../../assets/icons/icon_empresas.svg';
import iconProjects from  '../../assets/icons/icon_projetos.svg';
import iconRegions from  '../../assets/icons/icon_regioes.svg';
import iconPermission from  '../../assets/icons/icon_permissoes.svg';
import iconDashboard from  '../../assets/icons/icon_metricas.svg';

const itemList = [
  {id:1, file:{uri:iconClients}, name:'Clientes & vendas', status:false},
  {id:2, file:{uri:iconSchedule}, name:'Adenda', status:false},
  {id:3, file:{uri:iconProducts}, name:'Produtos', status:false},
  {id:4, file:{uri:iconUsers}, name:'Usuários', status:false},
  {id:5, file:{uri:iconCompany}, name:'Empresas', status:false},
  {id:6, file:{uri:iconProjects}, name:'Projetos', status:false},
  {id:8, file:{uri:iconRegions}, name:'Regiões', status:false},
  {id:9, file:{uri:iconPermission}, name:'Permissões', status:false},
  {id:10, file:{uri:iconDashboard}, name:'Permissões', status:false},
]

function PermissionsRegister() {
  // let {idPermission} = useParams();
  const history = useHistory();
  const [permissionEnable, setPermissionEnable] = useState([])
  const [permission, setPermission] = useState({   
    name: '',
    color: "green",
    description: '',
    daily_goal_visits: 0,
    daily_goal_headcount: 0,
    daily_goal_sales: 0,
    daily_goal_productivity: 0
  })
  
  const [invalidInput, setInvalidInput] = useState([]);
  // const [invalidMessage, setInvalidMessage] = useState('');

  useEffect(() => {
    setPermissionEnable(itemList);
  }, []);
  
  function handleSubmit(event){
    event.preventDefault();    
    let listInvalidInputs = [];

    if(!permission.name || permission.name.length < 3){
      listInvalidInputs.push('name')
    }
    if(!permission.description){
      listInvalidInputs.push('description')
    }

    if(listInvalidInputs.length < 1 ){
      api.post('/permissions', permission)
      .then(response => {
        if(response?.data){
          history.push('/Permissions')
        }
      }).catch(err => {
        console.log(err);
      })
    }
  }

  function removeInvalidInput(input){
    if(invalidInput.includes(input)){
      setInvalidInput(invalidInput.filter(item => item !== input));
    }    
  }

  function selectPage(id){
    let list = [];
    permissionEnable.forEach(item => {
      if(item.id === id){
        let newItem = item;
        newItem.status = !item.status;
        list.push(newItem); 
      }else{
        list.push(item);
      }
    })
    setPermissionEnable(list);
  }

  return(
    <div className='permissionList'>
      <div className='newPermissionTitleContainer'>
        <div className='newPermissionTitle'>
          <label>Novo perfil</label>
          <img src={iconPermission} alt="permissionIcon" />
        </div>
      </div>
      <form id='permissionRegistreFormContainer' onSubmit={(event) => handleSubmit(event)}>
        <div id='permissionFormCenter'>
          <div>
            <label className='permissionFormTitle'>Tipo de perfil de acesso</label>
            <input 
              placeholder='Nome do perfil' 
              className={`inputRoundedPermission  ${invalidInput.includes('name')?'invalidInput':''}`}
              onChange={(event) => {
                setPermission({...permission, name: event.target.value})
                removeInvalidInput('name');
              }} 
              value={permission.name} />
          </div>
          <div>
            <label className='permissionFormTitle'>Telas com acesso</label>
            <div className='permissionRegistrePage'>
              {permissionEnable.map(item => (
                <div 
                  className={`permissionRegistrePageAccess ${item.status?'permissionRegistrePageEnable':''}`}
                  onClick={(event)=>{
                    event.preventDefault();
                    selectPage(item.id);
                  }}
                >
                  <img src={item.file?.uri?item.file?.uri:null} alt='icon' />
                  <label>{item.name}</label>
                </div>
              ))}
            </div>
          </div>
          {/* <textarea 
            placeholder='Descrição do projeto' 
            className={`inputRoundedPermission  ${invalidInput.includes('description')?'invalidInput':''}`}
            onChange={(event) => {
              setPermission({...permission, description: event.target.value})
              removeInvalidInput('description');
            }} 
          value={permission.description} /> */}
          <div className='fullColumn' type="submit" value="Submit">
            <button id='permissionFormSubmit'>
              Salvar
            </button>
          </div>
        </div>     
      </form>
    </div>
  )
}

export default PermissionsRegister;
