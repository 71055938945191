import styled from 'styled-components'
import {Input, Label} from '../../../components'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosNewIcon from '@material-ui/icons/ArrowBackIos';


export const MyInput = styled(Input)`
  border:${props => (props.isEditing ? '1.5px solid #888888' : '0 none')};
  margin: 10px 0px 0px 15px;
  width: 100%;
  max-width: 165px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  height: auto;
  text-overflow: ellipsis; 
  padding-left:${props => (props.isEditing ? '12px' : '0px')};
  color: ${props => (props.orange ? '#EE5928' : '#888888')};
  :disabled{
    background-color: white;
  }
`
export const MyLabel = styled(Label)`
  text-align:left;
  margin: 10px 0px 0px 15px;
  font-size: 12px;
`

export const ButtonAction = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FFFFFF;
  min-width: 5px;
  height: 30px;
  border: 2px solid #EE5928;
  color: #EE5928;
  border-radius: 25px;
  margin:  2.5px;
`

export const CarouselImages = styled.img `
  height:100%;
  width:100%;
  border-radius: 20%;
  margin-right:5px;
  cursor: pointer;
`

export const ArrowButton = styled.button `
  border-radius:45%;
  background: #EE5928;
  height: 30px;
  width: 30px;
`

export const ForwardIcon = styled(ArrowForwardIosIcon) `
  color:white;
  padding-left: 5px;
`

export const BackIcon = styled(ArrowBackIosNewIcon) `
  color:white;
  padding-left: 5px;
`

export const MySelect = styled.select`
  margin: 10px 0px 0px 15px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color:#888888;
  max-width: 165px;
  padding-left: 12px;
  background-color: white;
  border: 1.5px solid #888888;
  border-radius: 20px;
  box-sizing: border-box;
  &:link {
    text-decoration: none;
  }
  &:active {
    text-decoration: none;
  }
  &:focus {
    text-decoration: none;
    box-shadow: 0 0 0 0;
    outline: 0;
  }
  margin-bottom: 10px;
  text-transform: none;
`