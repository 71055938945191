import api from '../api'

const authApi = {
  login: ({ email, password }) => {
    return api.post('/sessions', {
      email,
      password
    })
  },
  validateToken: () => {
    return api.get('/auth/validate-token', {
    })
  }
}

export default authApi