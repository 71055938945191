import PublishIcon from '@material-ui/icons/Publish';
import styled from 'styled-components'

import { Card, Input, Label } from '../../components'

const phasesTagValuesColors = {
  'Venda': '#0098EF',
  'Cadastro': '#42AB58',
  'Contato': '#D72D2E',
  'Visita': '#F58D27'
}

export const Container = styled.div`
  width: 100%;
  display: grid;
  grid-template-areas:
  "visitRecord    registrationData  record  record"
  "visitRecord    registrationData  sales   sales"
  "visitRecord2   registrationData  sales   sales"
  "visitRecord2   registrationData  sales   sales"
  "visitRecord2   registrationData  sales   sales"
  "contact        registrationData  sales   sales"
  "contact        registrationData  sales   sales"
  "scheduling     scheduling        sales   sales"
  "void     void        sales   sales";
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(9, auto);
  grid-gap: 10px;
  h3{
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    color: #444;
  }
  
  margin: 10px;
  @media (max-width:1150px){
    grid-template-areas:
    "visitRecord    registrationData "
    "visitRecord    registrationData "
    "visitRecord2   registrationData "
    "visitRecord2   registrationData "
    "visitRecord2   registrationData "
    "contact        registrationData "
    "contact        registrationData "
    "scheduling     scheduling       "
    "scheduling     scheduling       "
    "record         record           "
    "sales          sales            "
    "sales          sales            "
    "sales          sales            "
    "sales          sales            "
    "sales          sales            "
    "sales          sales            "
    "sales          sales            "
    "sales          sales            "
    ;
    grid-template-columns: 45% 45% ;
  } 
  @media (max-width:600px){
    display: flex;
    flex-direction: column;
    justify-content: center;
} 
`

export const Form = styled.form`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
@media (max-width:1280px){
  display: flex;
  align-items: center;
  justify-content: center;
}
`
export const visitRecord = styled.div`
  grid-area: visitRecord;
`
export const visitRecord2 = styled.div`
  /* grid-area: visitRecord2; */
`

export const contact = styled.div`
  /* grid-area: contact; */
`

export const scheduling = styled.div`
  grid-area: scheduling;
`

export const registrationData = styled.div`
  grid-area: registrationData;
`
export const record = styled.div`
  grid-area: record;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`

export const sales = styled.div`
  grid-area: sales;
`
export const ChatBallon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 5px;
  padding: 10px;
`
export const MyCard = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  padding: 10px 20px 20px;
  p {
    color: #444444;
    font-size: 14px;
  }
  h6{
    color: #EE5928;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    margin: 10px 0px 0px 15px;
  }
`

export const MyCardPostSale = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 0 !important;

 h6 {
  font-size: 13px;
 }

 > div {
  margin-left: 0 !important;
  margin-bottom: 5px !important;
 }

 > div > div {
  margin-left: 0 !important;
  margin-bottom: 5px !important;
 }

 > div > div > div {
  margin-left: 0 !important;
  margin-bottom: 5px !important;
 }
`

export const CardVisitHistories = styled.div`
  display: flex;
  box-shadow: 1px 2px 13px #00000032;
  max-height: auto;
  border-radius: 10px;
  flex-direction: column;
  width: 100%;
  margin-bottom: 15px;

  > div:first-child {
    margin: 15px 0px 0px 15px;
    color: #000000;
    display: flex;
    flex-direction: row;
    font-size: 14px;
    font-weight: bold;
    color: #EE5928;

    span {
      margin-left: 4px;
      text-transform: capitalize;
    }
  }
`
export const ContentVisitHistories = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  width: 99%;  
  margin: 10px 0px 0px 15px;

  > div {
    margin-bottom: 10px;
  }

`

export const MyCardGradient = styled.div`
  width: 100%;
  height:100%;
  position:absolute;
  border-radius:10px;
  background: rgb(255,255,255);
  background: linear-gradient(180deg, rgba(255,255,255,0) -20%, rgba(0,0,0,0.8995973389355743) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#000000",GradientType=1);
`

export const MyCardVisit = styled(Card)`
  display: flex;
  position: relative;
  justify-content: flex-end;
  align-items: flex-start;
  flex-direction: column;
  min-height: 30vh;
  width: 100%;
  background: ${props => `url(${props.background}) no-repeat`};
  background-size: 100%, contain;
  line-height: 27px;
  h2 {
    color: white;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    margin: 0px 0px 2px 10px;
    z-index:200;
    
  }
  p {
    color: #EE5928;
    margin: 0px 0px 0px 10px;
    font-style: normal;
    font-weight: 500;
    z-index:200;
    margin-bottom:10px;
  }

  > div > svg {
    color: white;
    width: 60px;
    height: 30px;
    position: absolute;
    top: 10px;
    right: 0px;
    cursor: pointer;
  }
`

export const MyCardButton = styled(Card)`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  min-height: 10vh;
  width: 100%;
  margin-bottom: 20px;
  margin-top: 5px;
`

export const ContentPostSale = styled.div`
  width: 100%;
  display: flex;
  height: auto;
  padding: 10px 20px 20px 20px;
  justify-content: 'center';
  > h6 {
    display: flex;
    justify-content: flex-start;
    font-size: 13px;
  }
`
export const ContentRegisters = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 15px;
`

export const ContentRegistersPromotor = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  > div:nth-child(1) {
    width: 10%;
  }


  > div:nth-child(2) {
    width: 60%;
  }
  
  > div:nth-child(3) {
    width: 20%;
  }
`
export const ContentRegistersPromotorContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  margin-left: 15px; 

  input {
    cursor: pointer;
  }
`

export const RegisterDate = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 0 10px 0 10px;
  position: relative;

  @media(max-width: 1440px) {
    margin-top: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`
export const RegisterDateLine = styled.div`
  position: absolute;
  width: 25px;
  height: 0px;
  left: 185px;
  top: 10px;


  border: 1px solid #CCCCCC;
  transform: rotate(90deg);

  @media(max-width: 1440px) {
    display: none;
  }
`

export const ContentPostSaleDate = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  border-right: '1px solid #E8E8E8';
  > div {
    flex: 1;
  }
`

export const MyInput = styled(Input)`
  border:${props => (props.isEditing ? '0 none' : '0 none')};
  border-bottom:${props => (props.isEditing ? '2px solid #ff7d52' : '0 none')};
  margin: 5px 0px 0px;
  padding-left: 4px;
  padding: 0px;
  background-color: transparent;
  width: 100%;
  ::-webkit-input-placeholder {
    color: black;
  }
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  height: auto;
  border-radius:0px;
  text-overflow: ellipsis; 
  color: ${props => (props.orange ? '#EE5928' : '#444444')};
  :disabled{
    background-color: white;
  }
`

export const MyInputModal = styled(Input)`
  height:150px;
  align-self: center;
  border:${props => (props.isEditing ? '1.5px solid #888888' : '0 none')};
  margin: 10px 0px 0px;
  padding-left: 4px;
  padding: 0px;
  background-color: transparent;
  width: 70%;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  text-overflow: ellipsis; 
  color: ${props => (props.orange ? '#EE5928' : '#444444')};
  :disabled{
    background-color: white;
  }
`


export const MyLabel = styled(Label)`
  margin: 10px 0px 3px;
  font-size: 12px;
  text-align: initial;
`

export const Button = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: ${props => props.active ? "#FFF" : "#CCCCCC"};
  border-radius: 15px 15px 0px 0px;
  color:  ${props => props.active ? "#EE5928" : "white"} ;
  height: 40px;
  width: ${props => props.small ? "33%" : "40%"};
  box-shadow: 0px -2px 10px  #00000032;
  :focus {
    background: white;
    color: #EE5928;
  }
`

export const CardButton = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 0 0 15px 15px;
  width: 100%;
  height: 100%;
  box-shadow: 0px -5px 5px #00000032;
`

export const CardBody = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 0 0 15px 15px;
  width: 100%;
  height: 85vh;
  overflow-y: auto;
  box-shadow: 0px 1px 8px #00000032;
`

export const AccordionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: white;
    border-radius: 0 0 10px 10px;
    height: auto;
    text-align: center;
    width: 100%;
    height: 100%;
    padding: 10px;
`;

export const InternalWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    max-height: ${(props) => (props.open ? 'auto' : '0')};
    transition: all 0.4s ease-in-out;
    overflow: hidden;
    hr{
      align-self:center;
      color: #707070;
      width: 90%;
    }
`;

export const Image = styled.div`
  margin: 10px;
  width: 100%;
  margin-bottom: 10px;
`
export const Informations = styled.div`
  padding: 0 20px;
  width: 100%;
  margin: 10px 0;
`

export const Tags = styled.div`
  padding: 0 10px 0 20px;
  width: 100%;
  margin: 10px 0 10px;
`

export const MyTextarea = styled.textarea`
  min-height:none !important;
  border:${props => (props.isEditing ? '1.5px solid #888888' : '0 none')};
  margin: 5px 0 0 0;
  width: auto;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  height: 60px;
  padding: 8px;
  text-overflow: ellipsis; 
  color: ${props => (props.orange ? '#EE5928' : '#444444')};
  :disabled{
    background-color: #eee;
  }
  border-radius: 10px;
  &:link {
    text-decoration: none;
  }
  &:active {
    text-decoration: none;
  }
  &:focus {
    text-decoration: none;
    box-shadow: 0 0 0 0;
    outline: 0;
  }
`

export const Buttons = styled.div`
  padding: 0 20px;
  width: 100%;
  margin: 20px 0;
`

export const ButtonAction = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FFFFFF;
  max-width:auto;
  width:100px;
  height: 25px;
  border: 2px solid #555;
  color: #555;
  font-size: 13px;
  border-radius: 25px;
  :hover{
    background: #EE5928;
    border: 2px solid #EE5928;
    color: white;
    opacity: 1;
  }
`

export const ButtonWrapper = styled.div`
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-bottom: 0px;
  border-radius: 80px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  margin: 10px;
  p{
    color: #EE5928;
    font-size: 30px;
    margin-left: 30px;
  }
`

export const SchedulingInfo = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  flex-direction: row;
  div {
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #EE5928;
      font-size: 12px;
    }
  }
`

export const StatusTag = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => (props.approved ? '#3E007C' : '#CCCCCC')};
  color: ${props => (props.approved ? 'white' : '#444444')};
  border-radius: 15px;
  height: 16px;
  padding: 3px 15px;
  font-size: 10px;
  margin: 5px 15px 0 0;
`

export const SalesmanLabel = styled.span`
  font-weight:500;
  font-size:12px;
  align-self:baseline;
  margin-bottom: 5px;
`

export const SalesmanName = styled.span`
  font-weight:700;
  font-size:12px;
  text-align:initial;
`

export const SalesmanType = styled.span`
  font-weight: 500;
  font-size:11px;
  color: rgb(119, 119, 119);
  align-self:baseline;
  margin-bottom: 5px;
`

export const Tag = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => !props.presentTag ? '#444444' : '#EE5928'};
  color: white;
  border-radius: 7px;
  font-size: 10px;
  padding: 5px 20px;
  margin: 0 6px 6px 0;
  cursor: pointer;
`
export const TypePhase = styled.div`
  background-color: ${props => phasesTagValuesColors[props.tagValue]};
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  min-width: 100px;
  height: 16px;
  font-size: 10px;
  margin: 15px;
`

export const ButtonNew = styled.button`
    background: #EE5928;
    color: white;
    width: 100px;
    height: 20px;
    border-radius: 15px;
    font-size: 12px;
    margin: 0 5px 0 0;
`

export const ButtonEdit = styled.button`
  background: transparent;
  color: #bbb;
  width: auto;
  height: 30px;
  border-radius: 30px;
  font-size: 14px;
  font-weight: 600px;
  text-decoration: none;
  margin-right: 10px;
`

export const ButtonSave = styled.button`
  background: transparent;
  color: #EE5928;
  width: auto;
  height: 30px;
  border-radius: 30px;
  font-size: 12px;
  text-decoration: underline;
`

export const SaleForm = styled.form`
  display:flex;
  flex-direction: column;
  align-items: flex-start;
  width:100%;
  margin-left:15px;
  input {
    margin-left: 15px;
    width: auto;
  }
  select {
    margin-left: 15px;
    width: auto;
  }
  option {
    font-size: 11px;
  }
`

export const MySelect = styled.select`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color:#888888;
  min-width: 165px;
  padding-left: 12px;
  background-color: white;
  border: 1.5px solid #888888;
  border-radius: 20px;
  box-sizing: border-box;
  &:link {
    text-decoration: none;
  }
  &:active {
    text-decoration: none;
  }
  &:focus {
    text-decoration: none;
    box-shadow: 0 0 0 0;
    outline: 0;
  }
  margin-bottom: 10px;
  text-transform: none;
`
export const SalesIndicator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${props => props.active ? "#EE5928" : "#FFF"};
  width: 18px;
  height:18px;
  border-radius: 50%;
  color: ${props => props.active ? "#FFF" : "#CCC"};
  font-size: 11px;
  font-weight: bold;
  margin: 0 0 0 6px;
`

export const UploadIcon = styled(PublishIcon)`
  font-size: 2.0rem !important;
  align-self: center;
  margin-top: 27px;
  display: ${props => !props.editing ? 'none !important' : 'inline-block'};
  color: #EE5928;
`

export const ButtonSalesContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px 10px 15px 10px;
`
export const ButtonPostSalesContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 5px;
`

export const BtnsEditSale = styled.div` 
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px 0 0;
  padding: 0 10px;                              
`

export const PostSaleContainer = styled.div` 
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;  
`
export const TitlePostSale = styled.p`
  align-self: flex-start;
  color: #ff7d52;
  margin: 0;
  font-weight: bold;
  margin-bottom: 10px;
`
export const TitleCustomFieldPostSale = styled.p`
  align-self: flex-start;
  color: black;
  margin: 0;
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 12px;
`

export const MyInput2 = styled.input`
font-family: Roboto;
font-style: normal;
font-weight: bold;
font-size: 12px;
line-height: 16px;
color: #888888;
padding-left: 12px;
border: 1.5px solid #888888;
border-radius: 20px;
box-sizing: border-box;
background-color: transparent;
margin-bottom: 10px;
width: 100%;
::-webkit-input-placeholder {
  color: black;
}
:disabled{
  background-color: white;
  opacity: 0.7;
}
&:link {
  text-decoration: none;
}
&:active {
  text-decoration: none;
}
&:focus {
  text-decoration: none;
  box-shadow: 0 0 0 0;
  outline: 0;
}
`

export const Hr = styled.hr`
  color: #888888;
  width: 100%;
  margin: 20px 0px;
`

export const MaskImagePostSale = styled.div`
  margin: 0;
  border-radius: 10px;
  background: ${props => `url(${props.background}) no-repeat top center`};
  background-size: cover;
  cursor: pointer;
  display: flex;
  margin-bottom: 10px;
  align-items: center;
  justify-content: flex-start;
  > svg {
    margin-top: 0;
    margin-right: 15px;
    align-self: flex-start;
    margin-right: 25px;
    font-size: 35px;
    color: gray;
    :hover {    
      opacity: 0.6;
    }
  }
`

export const PostSaleImage = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 90%;
  align-items: center;
  border-radius: 10px;
  > img {
    margin-top: 5px;
    width: 60px;
    height: 50px;
    border-radius: 10px
  }
`

export const PostSaleImages = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 90%;
  justify-content: space-evenly;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 10px;
  margin-bottom: 15px;
  > img {
    padding: 5px;
    width: 60px;
    height: 60px;
    border-radius: 10px;
    margin-bottom: 10px;
  }
`

export const ButtonActionFile = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FFFFFF;
  width: 32px;
  height: 32px;
  border: 2px solid #EE5928;
  color: #EE5928;
  border-radius: 25px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-right: 7px;
`
export const ButtonActionFileUpdate = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FFFFFF;
  height: 32px;
  width: 32px;
  border: 2px solid ${props => props.notempty ? '#EE5928' : '#ccc'};
  color: ${props => props.notempty ? '#EE5928' : '#ccc'};
  border-radius: 25px;
  padding-top: 7px;
  padding-bottom: 7px;
  margin-right: 7px;
`

export const Tpv = styled.div` 
  width: 100%;
  height: auto; 
  display: flex; 
  justify-content: space-between;
  flex-direction: row; 
  align-items: center;
  flex-wrap: wrap;
  margin-top: 5px;
`
export const TpvContent = styled.div`
  flex: 1;
  padding-bottom: 15px; 
  height: auto;
  text-transform: capitalize;

  > div {
    width: 200px;
    color: #444444;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    font-family: Roboto;
    word-break: break-word;
    margin-right: 5px;
  }
`

export const ZapSignTitle = styled.p`
  margin: 0;
  font-size: 18px;
  color: #EE5928;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 600;
  line-height: 21px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  > span:nth-child(2) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    padding: 5px;
    border-radius: 15px;
    height: 20px;
    width: 87px;
    font-weight: 500;
  }
`
export const ZapSignDescription = styled.p`
  margin: 0;
  text-align: left;
  color: #999999;
  font-weight: 500;
  font-size: 11px;
  margin-top: 8px;
  margin-bottom: 10px;
  line-height: 15px;
`

export const ZapSignDocumentGenerateButton = styled.button`
  display: flex;
  align-items: center;
  background: transparent;
  border: 1.5px solid #EE5928;
  border-radius: 5px;
  padding: 5px;
  color: #EE5928;
  font-weight: 600;

  > img {
    height: 16px;
    margin-left: 5px;
  }
`
export const ZapSignContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  span {
    display: flex;
    align-items: flex-start;
    color: #999999;
    font-weight: 500;
    font-size: 12px;
    margin-bottom: 10px;
  }

  > span {
    cursor: pointer;
  }

  > div {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    border-bottom: 1px solid #999999;
    margin-bottom: 5px;

    > span {
      font-size: 11px;
      color: #EE5928;
      font-weight: bold;
      margin-bottom: 4px;
    }
  }

`

export const HiddenContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
`

export const HiddenButton = styled.button`
  height: 8px;
  background-color: transparent;
  color: #ee5928;
  font-weight: bold;
  display:flex ;
  flex-direction:row;
  align-items:center;
  text-align:center;
`


// AGENDAMENTOS ==========================================
export const ScheduleContainer = styled.div`
 margin-top: 15px;
`
export const ScheduleCardContainer = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  padding: 10px;
`             

export const ScheduleCard = styled(Card)`
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
  padding: 15px; 
`

export const ScheduleHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  width: 100%;
  margin-top: 5px;

  .left {
    display: flex;
    flex-direction: column;
    justify-content: center;  

    > input {     
      font-weight: 500;
      font-size: 15px;
      line-height: 19px;
      margin-top: 4px;
    }
  }

  .right {
    display: flex;
    flex-direction: column;
    justify-content: flex-start; 
    
    > span {      
      font-family: 'Roboto';
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      height: 18px;
      width: 150px;   
      border-radius: 10px;
      color: #FFF;
      display: flex;
      align-items: center;
      justify-content: center;
      letter-spacing: 1px;
    }
  }
`
export const ScheduleLabel = styled.label`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 14px;
  color: #999999;  
  margin: 0;
  text-transform: capitalize;
`

export const ScheduleInput = styled(Input)`
  border:${props => (props.isEditing ? '0 none' : '0 none')};
  border-bottom:${props => (props.isEditing ? '2px solid #ff7d52' : '0 none')};
  padding-left: 4px;
  padding: 0px;
  background-color: transparent;
  width: 100%; 
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  height: auto;
  border-radius:0px;
  text-overflow: ellipsis; 
  color: ${props => (props.orange ? '#EE5928' : '#444444')}; 
  margin-bottom: 10px;
`

export const ScheduleContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  width: 100%;
  margin-top: 15px;  
  justify-content: space-between;
  
  .schedule_avatar {
    display: flex;
    flex-direction: row;
    margin-top: 3px;  
    margin-bottom: 10px;    

    > div {
      display: flex;
      flex-direction: column;   
      
      > label:nth-child(2) {
        font-size: 12px; 
      }
    }
  }    

`
export const ScheduleText = styled.label`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  margin-top: 3px;
  color: #555555;
`
export const ScheduleObservation = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  width: 150px;
  margin-top: 3px;
  color: #555555;
`

export const ScheduleAvatar = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: ${props => `url(${props.background}) no-repeat top center`};
  background-size: cover;
  margin-right: 3px;
`

export const SearchInput = styled.input`
  width: 100%;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  background-color: transparent;
  border-radius: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #ccc;
  text-align: center;

  ::-webkit-input-placeholder {
    color: #888888;
  }
 
  &:focus {
    text-decoration: none;
    box-shadow: 0 0 0 0;
    outline: 0;
  }
`

export const ExternalDataHeader = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  padding: 10px;
`

export const SalesFilterTitle = styled.span`
  font-size: 18px;
  color: #000;
  opacity: 0.71;
  font-weight: 600;
  padding: 8px;
  text-decoration: underline;
`

export const ExternalDataTitle = styled.span`
  font-size: 18px;
  color: #EE5928;
  font-weight: 600;
`

export const ExternalDataContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: start;
  justify-content: space-between;
  padding: 5px;
`

export const ExternalDataButton = styled.button`
  background: transparent;
  border: 1.5px solid #EE5928;
  border-radius: 5px;
  padding: 5px;
  color: #EE5928;
  font-weight: 600;
`

export const ExternalDataCardButton = styled.div`
  width: 48%;
  display: flex;
  flex-direction: column;
  box-shadow: 1px 2px 13px #00000032;
  border-radius: 15px;
  padding: 15px;
  margin-bottom: 15px;
`

export const ExternalDataFields= styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`

export const ExternalDataLabel = styled.label`
  text-align: initial;
  color: #000000;
  opacity: 0.71;
  font-weight: 600;
  font-size: 16px;
  text-transform: capitalize;
  margin-bottom: 1px;
`

