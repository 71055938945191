import React from "react";
import Styles from './styles'

export default function Header({ title, buttons, width,styles }) {


    return (
        <Styles style={styles}>


            <div className='headerCardComponent' style={width ? { width } : null}>
                <h3 className="ProductTitle" style={{ paddingRight: 0 }} >{title}</h3>
                <div>
                    {buttons && buttons()}

                </div>
            </div>


        </Styles>
    )
}