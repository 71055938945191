import styled from "styled-components";


export const Container = styled.div`

width: 100%;
height: 60px;
border-radius: 10px;
background-color:#fff;
-webkit-box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.15); 
box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.15);
overflow:hidden;
flex-direction:row;


`
export const TopBarView = styled.div`
flex:1;
display: flex;
`
export const TopButton = styled.button`
    height: 100%;
    display:flex;
    width: 160px;
    max-width: 160px;
    justify-content:space-between;
    background-color: transparent;
    align-items:center;
    flex-direction:column;
    border:0;

    .innerContent{
        align-items:center;
        justify-content:center;
        flex:1;
        flex-direction:row;
        label{
            font-size:0.9rem;
            font-family:'Roboto';
            color: ${({ active }) => active ? 'var(--color-primary)' : '#aaa'};
            font-weight:bold;
        }
        svg{
            width: ${({iconSize})=>iconSize?iconSize:'12px'};
            margin-right: 5px;
            margin-left: 5px;
        
            *{
            fill:${({ active }) => active ? 'var(--color-primary)' : '#aaa'};
            stroke:${({ active }) => active ? 'var(--color-primary)' : '#aaa'};
        }
    }

}
.buttonStatus{

height: 3px;
transition: width 0.1s ease;
background-color: var(--color-primary);
width: ${({ active }) => active ? '80%' : '0px'};
}
*{
:hover{

cursor:pointer;

}
}
`