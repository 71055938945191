import styled from 'styled-components'

export const Button = styled.button`
  background-color:${props => props.primary ? "transparent" : "#EE5928"};
  border: 3px solid ${props => props.primary ? "white": "#EE5928"};
  text-transform: uppercase;
  color: white;
  height: 70px;
  width: 178px;
  margin: 20px;
  font-size: 16px;
  border-radius: 15px;
  opacity: 0.9;
  display: ${props => props.centered ? "flex" : " "};
  justify-content: ${props => props.centered ? "center": " "};
  align-items: ${props => props.centered ? "center" : " "};
  flex-direction: ${props => props.centered ? "column" : " "};
  @media (max-width: 800px) {
    height: 50px;
    margin: 10px;
    font-size: 13px;
    border-radius: 10px;
  }
  &:disabled{
    background-color: gray;
    color: white;
    border: 3px solid gray;
    cursor: default;
  }
`