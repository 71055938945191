import React, { useState, useEffect, useCallback, useRef } from 'react';

import { useParams } from 'react-router-dom'
import Logo from '../../utils/logo.svg'

import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify';

import {Button, Checkbox, Spinner} from '../../components'

import { Modal } from './components/modal/modal'

import useComponentVisible from '../../hooks/useComponentVisible'

import api from '../../services/api';

import * as S from './LGPD.styles'


export const LGPD = () => {
  const history = useHistory();
  const { id_customer, token } = useParams()
  const ref = useRef()
  const { isComponentVisible, setIsComponentVisible} = useComponentVisible(ref, false)

  const [name, setName] = useState('')
  const [loadingAccept, setLoadingAccept] = useState(false)
  const [loadingRevogue, setLoadingRevogue] = useState(false)
  const [checked, setChecked] = useState(false)
  const [notDocument, setNotDocument] = useState(!!token)
  const [documentWithoutValidation, setDocumentWithoutValidation] = useState(false)
  const [documentWithValidation, setDocumentWithValidation] = useState(false)
  const [revoked, setRevoked] = useState(false)

  //const [document, setDocument] = useState('')


  const checkStatus = useCallback(async() => {
    setLoadingAccept(true)
    try {
      const response = await api.get(`/check_status_term/${id_customer}`)
      setLoadingAccept(false)
      
      const { data } = response
      
      setName(data?.fantasy_name || '')

      console.log(data)

      console.log(data?.accept_term_lgpd_name )
      if(data?.accept_term_lgpd_name && data?.accept_term_lgpd_name === 'Aceito') {
        setDocumentWithValidation(true)
        setNotDocument(false)
      } else if(data?.accept_term_lgpd_name && data?.accept_term_lgpd_name === 'Pendente') {
        setDocumentWithoutValidation(true)
        setNotDocument(false)
      } else if(data?.accept_term_lgpd_name && data?.accept_term_lgpd_name === 'Revogado') {
        setNotDocument(true)
        setRevoked(true)
      } 
      // else {
      //   setNotDocument(true)
      // }

    } catch(e) {
      setLoadingAccept(false)
      setNotDocument(true)
    }

  },[id_customer])

  useEffect(() => {
    checkStatus()
  },[checkStatus])

  const redirectToDashBoard = () => {
    setTimeout(() => {
      history.push('/dashboard')
    },3500)
  }

  const acceptTerms = async() => {
    setLoadingAccept(false)
    try{
      await api.put(`/confirm_email/${id_customer}?token=${token}`)     
      setIsComponentVisible(true)
      document.location.reload()
    } catch(e){
      setLoadingAccept(false)
      toast.error('Erro ao aceitar termo.')
    }
  }

  const revogueTerms = async() => {
    setLoadingRevogue(false)
    try{
      await api.put(`/revoke_term_lgpd/${id_customer}?token=${token}`)
      toast.success('Sucesso ao revogar termo')
      setRevoked(true)
      setLoadingRevogue(false)
    }catch(e) {
      setLoadingRevogue(false)
      toast.error('Erro ao revogar termo.')
    }
  }

  const handleCheckboxChange = () => {
    setChecked(!checked)
  }

  return (
    <>
    <div className='modal'>
      <Modal
        active={isComponentVisible}
        descriptionModal={revoked && "Termo revogado"}
      />
    </div>
      <S.Container>
        <S.Wrapper>
          <div className="square">
            <div />
          </div>
          <div  className="boxText">
            <div className="mainText">
              <div className="firstLine">
                <S.Paragraph large primary>Olá, {name}</S.Paragraph>
                <img src={Logo} alt="Logo prisma" />
              </div>
              { 
                !revoked ?
                  <S.Paragraph className="thePrisma">A PRISMA SE PREOCUPA COM VOCÊ! MANTENHA SEUS DADOS SEGUROS</S.Paragraph>
                :
                  <S.Paragraph large primary>Você solicitou a revogação.</S.Paragraph>

              }
              { 
                !revoked && documentWithoutValidation ?
                  <>
                    <S.Paragraph primary>
                      Para que seu produto possa ser corretamente ativado, confirme sua aceitação de acordo com a Lei Geral de Proteção de Dados e continue aproveitando os benefícios entre a parceria Prisma.
                    </S.Paragraph>
                    <label className="labelDiv">
                      <Checkbox
                        checked={checked}
                        onChange={handleCheckboxChange}
                      />
                      <S.Paragraph style={{fontSize: '9px' }} primary>Li e concordo com os <a href={'/terms-and-conditions'}>*Termos de Uso e Política de Privacidade</a></S.Paragraph>
                    </label>
                  </>
                :  !revoked && <>
                    <S.Paragraph primary>
                      Caso não esteja mais usando nossos produtos e deseja solicitar a revogação do aceite sobre os dados fornecidos.
                    </S.Paragraph>                   
                  </>

              }
              {
                revoked && <>
                    <S.Paragraph primary>
                      Em caso de qualquer dúvida entre em contato através do e-mail privacidade@prismapromotora.com.br.
                    </S.Paragraph>
                    <S.Paragraph className="thePrisma" style={{ marginTop: '30px', fontSize: '22px' }}>Obrigado.</S.Paragraph>
                </>
              }
              <div className="buttonDiv">
                {/* {notDocument &&
                  <>
                    <S.Input value={document} onChange={(event) => setDocument(event.target.value)} placeholder="**CPF ou CNPJ" required />
                    <Button disabled={!checked || !document} onClick={() => acceptTerms()} centered>
                    {loadingAccept && <Spinner small/>}  
                    {!loadingAccept && <>Aceitar</>}
                    </Button>
                    <Button disabled={!checked || !document} onClick={() => revogueTerms()} primary>
                    {loadingRevogue && <Spinner small/>} 
                    {!loadingRevogue && <>Solicitar Revogação</>}  
                    </Button>
                  </>   
                }  */}
                {documentWithoutValidation && 
                  <>                  
                    <Button disabled={!checked} onClick={() => acceptTerms()}>
                    {loadingAccept && <Spinner small/>} 
                    {!loadingAccept && <>Aceitar</>}
                    </Button>
                  </>
                }
                {documentWithValidation && !revoked &&
                  <>
                    <Button 
                      onClick={() => revogueTerms()} 
                      primary
                      style={{ margin: '100px' }}
                    >
                      {loadingRevogue && <Spinner small/>}
                      {!loadingRevogue && <>Solicitar Revogação</>}   
                    </Button>
                  </>
                }
              </div>
              {/* {notDocument && 
                <div className="textInput">
                  <p>**Preencha com o mesmo documento informado ao promotor de venda no momento da aquisição do produto</p>
                </div>  
              } */}
            </div>
          </div>
        </S.Wrapper>
        <p className="termsAndUse">*Em nosso Termo de Uso e Política de Privacidade, você confere as informações sobre tratamento de dados.</p>
      </S.Container>
    </>
  )
}

