import React from "react";
import TabBar, { TabBarProvider, TopBarView, useSelected } from "../../components/TabBar";
import * as S from "./styled"
import { apiIcon, permissionIcon, fieldsIcon, groupIcons,metricIcons, baseIcon, promotersIcon } from '../../assets/icons/topBarIcons'
import Apis from "../APIConfig";
import Permissions from "../Permissions";
import Regions from "../Regions"
import Fields from "../Fields"
import Metrics from "../Metrics"
import Bases from '../Bases'
import CustomersWallets from '../CustomersWallets'

const Settings = () => {

    const renderItems = [
        {
            id: "fields",
            name: "Campos",
            img: fieldsIcon,
            permissionNeeded: 'campos',
            iconSize: '20px',
            render: Fields
        },
        {
            id: "group_teams",
            name: "Grupos e Times",
            img: groupIcons,
            iconSize: '20px',
            permissionNeeded: 'grupos',
            render: Regions
        },
        {

            id: "metrics",
            name: "Metricas",
            img: metricIcons,
            iconSize: '20px',
            permissionNeeded: 'metricas',
            render: Metrics

        },
        {
            id: "permissions",
            name: "Permissões",
            img: permissionIcon,
            iconSize: '13px',
            permissionNeeded: 'permissoes',
            render: Permissions
        },
        {
            id: "bases",
            name: "Bases",
            img: baseIcon,
            iconSize: '13px',
            permissionNeeded: 'bases',
            render: Bases
        },
        {
            id: "api",
            name: "API's",
            img: apiIcon,
            iconSize: '17px',
            render: Apis
        },
        {
            id: "wallets",
            name: "Carteiras",
            img: promotersIcon,
            iconSize: '17px',
            render: CustomersWallets
        },
        
    ]

    return <S.Container>
        <TabBarProvider>
            <TabBar renderItems={renderItems} />
            <TopBarView />
        </TabBarProvider>
    </S.Container>


}
export default Settings