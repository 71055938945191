/* eslint-disable no-alert, no-console */

import React, { useState, useEffect, useContext, useCallback } from "react";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom"



import UserList from "./pages/UserListV2";
import NavigationMenu from "./components/NavigationMenu";
import ForgotPassword from "./pages/ForgotPassword";
import CreateCategories from "./pages/CreateCategories"
import ProductInfo from "./pages/ProductInfo"
import { LGPD } from "./pages/LGPD"
import { TermsAndConditions } from './pages/TermsAndConditions'
import { Login } from './pages/LoginV2'
import Maps from "./pages/Maps";
import UserRegisterV2 from "./pages/UserRegisterV2"
import UserUpdate from "./pages/UserUpdateV2";
import CustomerSalesList from "./pages/CustomerSalesList";
import { CustomerInfo } from "./pages/CostumerInfoV2"
import Customer from "./pages/Customer";
import CustomerReports from "./pages/CustomerReportsV2";
import StepContact from "./pages/Customer/StepContact";
import StepVisit from "./pages/Customer/StepVisit";
import StepRegister from "./pages/Customer/StepRegister";
import StepSales from "./pages/Customer/StepSales";
import ProductsList from "./pages/ProductsList";
import ProductsListAll from "./pages/ProductListAll";
import ProductsRegister from "./pages/ProductsRegister";
import ProductsTransfer from "./pages/ProductsTransfer";
import ProductsCategory from "./pages/ProductsCategory";
import ProjectsList from "./pages/ProjectsList";
import ProjectRegister from "./pages/ProjectRegister";
import Groups from "./pages/Regions";
import Profile from "./pages/Profile";
import Metrics from "./pages/Metrics";
import Fields from "./pages/Fields";
import AddFields from "./pages/Fields/AddFields";
import EditFields from "./pages/Fields/EditFields";
import ProductsDetails from "./pages/ProductsDetails";
import SchedulesList from "./pages/SchedulesList";
import ScheduleCreate from "./pages/ScheduleCreate"
import ErrorPage from "./pages/ErrorPage";
import CategoryDetails from "./pages/CategoryDetails";
import api from "./services/api";
import StoreContext from './context/Context';

import PermissionsEdit from './pages/PermissionsEdit';
import Permissions from './pages/Permissions';
import PermissionsRegister from './pages/PermissionsRegister';
import { io } from "socket.io-client";
import { permissionCheck } from './utils/permission_role';
import DashboardV2 from "./pages/Dashboardv2";
import Panel from "./pages/Panel";
import Settings from "./pages/Settings";

const Routes = () => {
  const [loading, setLoading] = useState(true);
  const [userInfo, setUserInfo] = useState({});
  const [userProject, setUserProject] = useState({});
  const [userAccess, setUserAccess] = useState([]);
  const { setUserProjectStore, setUserInfoStore, userInfoStore, setSocket, setSavedFilters } = useContext(StoreContext);

  const updateStates = useCallback((user, project) => {
    const savedFiltersInStorage = sessionStorage.getItem('savedFilters') || localStorage.getItem('savedFilters')

    if (savedFiltersInStorage) {
      setSavedFilters(JSON.parse(savedFiltersInStorage))
    }

    setUserInfo(user);
    setUserInfoStore(user);

    setUserProject(Array.isArray(project) ? { ...project[0], categories: undefined } : { ...project, categories: undefined });
    setUserProjectStore(Array.isArray(project) ? { ...project[0], categories: undefined } : { ...project, categories: undefined });

    return true;
  }, [setUserInfoStore, setUserProjectStore, userInfoStore])

  const setUserAccessCall = useCallback(() => {
    if (userInfoStore?.permissions) {
      setUserAccess(userInfoStore.permissions);
    }
  }, [userInfoStore])

  const getData = () => {
    const token = localStorage.getItem('token') || sessionStorage.getItem('token')

    const socket = io(process.env.REACT_APP_API_DASHBOARD)
            
    setSocket(socket)   

    socket.on("connect", () => {
      socket.emit('token', token)
    });       

    if (token && token !== "undefined") {
      api
        .get("/user")
        .then((response) => {
          if (response.data) {
            const user = response.data[0];
            const localParsed = localStorage.getItem('project') && JSON.parse(localStorage.getItem('project'))

            if (localParsed && localParsed.length === 1) {
              updateStates(user, localParsed[0]);
              
              setLoading(false);
            } else {
              localStorage.setItem("project", JSON.stringify(user.projects))
              
              updateStates(user, user.projects[0]);
              
              setLoading(false);
            }    
          }
        })
        .catch(_ => {         
          setLoading(false)
        })
    }
    else {
      setLoading(false)
    }
  }

  const RouteWithPermission = useCallback(({ path, permission_name, children }) => {
    if (userAccess.length === 0) {
      return <></>
    }

    if (userInfo.id_users_type === 6) {
      return (
        <Route exact path={path}>
          {children}
        </Route>
      )
    }

    return (<Route exact path={path}>
      {
        permissionCheck(permission_name, userAccess) ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/Profile",
              state: { accessInvalid: true },
            }}
          />
        )
      }
    </Route>)
  }, [userAccess])

  useEffect(() => {
    if (userInfoStore) {
      setUserAccessCall()
    }
  }, [userInfoStore]);

  useEffect(() => {
    getData()
  }, []);

  return !loading ?
    <Router>
      <div>
        <Switch>
          <Route path="/forgotPassword">
            <ForgotPassword />
          </Route>
          <Route path={["/confirm/:id_customer/:token?"]}>
            <LGPD />
          </Route>
          <Route path="/terms-and-conditions">
            <TermsAndConditions />
          </Route>
          <Route path="/login">
            <Login />
          </Route>
          <Route exact path="/404">
            <ErrorPage />
          </Route>
          <NavigationMenu
            userInfo={userInfo}
            userProject={userProject}
            updateStates={(user, project) => {
              updateStates(user, project);
            }}
          >
            <Route exact path="/">
              <Redirect
                to="/Profile"
                userInfo={userInfo}
                userProject={userProject}
              />
            </Route>
            <Route exact path="/Dashboard">
              <DashboardV2 userInfo={userInfo} userProject={userProject} />
            </Route>
            <Route exact path="/Panel">
              <Panel />
            </Route>
            <RouteWithPermission
              path="/Users"
              permission_name="usuarios"
            >
              <UserList />
            </RouteWithPermission>
            <Route exact path="/Profile">
              <Profile
                userInfo={userInfo}
                userProject={userProject}
                updateStates={(user, project) => {
                  updateStates(user, project);
                }}
              />
            </Route>
            <RouteWithPermission
              path="/UsersRegister"
              permission_name="acoes_usuario"
            >
              <UserRegisterV2 />
            </RouteWithPermission>
            <RouteWithPermission
              path="/UsersRegister/:idUser"
              permission_name="acoes_usuario"
            >
              <UserUpdate />
            </RouteWithPermission>
            <Route exact path="/Customer">
              <CustomerSalesList
                userInfo={userInfo}
                userProject={userProject}
              />
            </Route>
            <RouteWithPermission
              path="/Reports/"
              permission_name="reports"
            >
              <CustomerReports userProject={userProject} />
            </RouteWithPermission>
            <Route exact path="/CustomerInfo/:idCustomer">
              <CustomerInfo userProject={userProject} userInfo={userInfo} />
            </Route>
            <Route path="/CustomerRegister">
              <Customer>
                <Route
                  exact
                  path={[
                    "/CustomerRegister/:idCustomer?",
                    "/CustomerRegister/stepVisit/:idCustomer",
                  ]}
                  children={<StepVisit userProject={userProject} />}
                />
                <Route
                  exact
                  path="/CustomerRegister/stepContact/:idCustomer"
                  children={<StepContact userProject={userProject} />}
                />
                <Route
                  exact
                  path="/CustomerRegister/stepRegister/:idCustomer"
                  children={<StepRegister userProject={userProject} />}
                />
                <Route
                  exact
                  path="/CustomerRegister/stepSales/:idCustomer"
                  children={<StepSales userProject={userProject} />}
                />
              </Customer>
            </Route>
            <RouteWithPermission
              path="/Products"
              permission_name="produtos"
            >
              <ProductsList userInfo={userInfo} userProject={userProject} />
            </RouteWithPermission>
            <RouteWithPermission
              path="/CreateCategory"
              permission_name="acoes_categorias"
            >
              <CreateCategories userInfo={userInfo} userProject={userProject} />
            </RouteWithPermission>
            <Route path="/ProductInfo/:id">
              <ProductInfo userInfo={userInfo} userProject={userProject} />
            </Route>
            <RouteWithPermission
              path="/ProductsTransfer"
              permission_name="acoes_produtos"
            >
              <ProductsTransfer userProject={userProject} />
            </RouteWithPermission>
            <RouteWithPermission
              path="/ProductsRegister/:idProduct?"
              permission_name="acoes_produtos"
            >
              <ProductsRegister userProject={userProject} />
            </RouteWithPermission>
            <Route exact path="/Schedule" >
              <SchedulesList userInfo={userInfo} userProject={userProject} />
            </Route>
            <Route exact path="/scheduleCreate">
              <ScheduleCreate userInfo={userInfo} userProject={userProject} />
            </Route>
            <RouteWithPermission
              path="/Projects"
              permission_name="projetos"
            >
              <ProjectsList />
            </RouteWithPermission>
            <RouteWithPermission
              path="/ProjectsRegister"
              permission_name="acoes_projetos"
            >
              <ProjectRegister />
            </RouteWithPermission>
            <RouteWithPermission
              path="/Groups"
              permission_name="grupos"
            >
              <Groups userInfo={userInfo} userProject={userProject} />
            </RouteWithPermission>
            <RouteWithPermission
              path="/Permission"
              permission_name="permissoes"
            >
              <Permissions />
            </RouteWithPermission>
            <RouteWithPermission
              path="/Permission/:id"
              permission_name="permissoes"
            >
              <PermissionsEdit />
            </RouteWithPermission>
            <RouteWithPermission
              path="/PermissionsRegister"
              permission_name="acoes_permissoes"
            >
              <PermissionsRegister />
            </RouteWithPermission>
            <RouteWithPermission
              path="/Metrics"
              permission_name="metricas"
            >
              <Metrics userInfo={userInfo} userProject={userProject} />
            </RouteWithPermission>
            <RouteWithPermission
              path="/CategoryDetails"
              permission_name="categorias"
            >
              <CategoryDetails userInfo={userInfo} userProject={userProject} />
            </RouteWithPermission>
            <RouteWithPermission
              path="/Maps"
              permission_name="maps"
            >
              <Maps />
            </RouteWithPermission>
            <RouteWithPermission
              path="/Fields"
              permission_name="campos"
            >
              <Fields userInfo={userInfo} userProject={userProject} />
            </RouteWithPermission>
            <RouteWithPermission
              path="/Fields/Add"
              permission_name="acoes_campos"
            >
              <AddFields userInfo={userInfo} userProject={userProject} />
            </RouteWithPermission>
            <RouteWithPermission
              path="/Fields/Edit"
              permission_name="acoes_campos"
            >
              <EditFields userInfo={userInfo} userProject={userProject} />
            </RouteWithPermission>

            <RouteWithPermission
              path="/ProductsDetails"
              permission_name="produtos"
            >
              <ProductsDetails />
            </RouteWithPermission>

            <RouteWithPermission
              path="/ProductsListAll"
              permission_name="produtos"
            >
              <ProductsListAll userInfo={userInfo} userProject={userProject} />
            </RouteWithPermission>
            <RouteWithPermission
              path="/Settings"
              permission_name="produtos"
            >
              <Settings />
            </RouteWithPermission>
            <RouteWithPermission
              path="/ProductsCategory"
              permission_name="acoes_produtos"
            >
              <ProductsCategory userInfo={userInfo} userProject={userProject} />
            </RouteWithPermission>
          </NavigationMenu>
        </Switch>
      </div>
    </Router>
    :
    <>
      <p>Carregando...</p>
    </>
}

export default Routes