import React, {useState, useEffect} from 'react';
import {useHistory, useParams} from "react-router-dom";

import './styles.css';
import api from '../../../services/api';
import SubmitButtonContainer from '../../../components/SubmitButtonContainer';
import iconMap from '../../../assets/icons/iconMap.png'
import { FormatCelphone } from '../../../utils/FormatData';

function StepVisit(props){
  let {idCustomer} = useParams();
  const history = useHistory();
  const [invalidInput, setInvalidInput] = useState([]);
  const [invalidMessage, setInvalidMessage] = useState('');
  const [representative, setRepresentative] = useState({
    contact_mobile_phone: '',
    contact_position: '',
    contact_name: '',
    id_customer: ''
  });
  const [location, setLocation] = useState({
    fantasy_name: '',
    address: '',
    address_number: '',
    address_city: '',
    address_state: ''
  });
  
  
  useEffect(()=>{
    if(idCustomer && props.userProject?.id){
      api.get('/customers/'+idCustomer, {params:{id_project:props.userProject.id}})
      .then(response => {
        if(response?.data){
          let data = response?.data;
          setRepresentative({
            contact_mobile_phone: data.contact_mobile_phone?FormatCelphone(data.contact_mobile_phone):'',
            contact_position: data.contact_position?data.contact_position:'',
            contact_name: data.contact_name?data.contact_name:'',
            id_customer:idCustomer
          });
          setLocation({
            fantasy_name: data.fantasy_name,
            address: data.address,
            address_city: data.address_city,
            address_state: data.address_state,
            address_number: data.address_number,
          });
        }
      }).catch(err => {
        console.log(err);
        return({success: false})
      });
    }else{
      if(!idCustomer){
        history.push('/CustomerRegister')
      }
    }
  }, [history, idCustomer, props.userProject.id])

  function submitForm(){
    let invalidList = []
    let postRepresentative = representative;
    postRepresentative.id_project = props.userProject?.id?props.userProject?.id:'';
    if(!postRepresentative.contact_name){
      invalidList.push('name');
    }
    if(!postRepresentative.contact_position){
      invalidList.push('position');
    }
    if(!postRepresentative.contact_mobile_phone){
      invalidList.push('phone');
    }else{
      postRepresentative.contact_mobile_phone = postRepresentative.contact_mobile_phone.replace(/[^\d]/g, '');
    }
    
    

    if(invalidList.length > 0){
      setInvalidInput(invalidList);
      setInvalidMessage('Não foi possível salvar o novo contato.')
    }else{
      api.post('/representatives', postRepresentative)
      .then(response => {
        if(response?.data){
          history.push('/CustomerRegister/stepRegister/'+response?.data.id)
        }else{
          console.log('representatives: ', response);
        }
      }).catch(err => {
        console.log('representatives error: ', err);
      })
    }
  }

  function removeInvalidInput(input){
    if(invalidInput.includes(input)){
      setInvalidInput(invalidInput.filter(item => item !== input));
    }    
  }

  return(
    <>
      <div id='stepFormRow'>
        <div className='stepFormHalf'>
          <div className='leftAlignedTitle'>  
            <label>Dados comerciais</label>
          </div>
          <input
            className={`inputRounded ${invalidInput.includes('name')?'invalidInput':''}`}
            placeholder='Nome Completo'
            value={representative.contact_name}
            onChange={(event) => {
              removeInvalidInput('name');
              setRepresentative({...representative, contact_name:event.target.value})
            }}
          />
          <input
            className={`inputRounded ${invalidInput.includes('phone')?'invalidInput':''}`}
            placeholder='Celular'
            maxLength="16"
            value={representative.contact_mobile_phone}
            onChange={(event) => {
              removeInvalidInput('phone');
              setRepresentative({...representative, contact_mobile_phone:FormatCelphone(event.target.value)})
            }}
          />
          <input
            className={`inputRounded ${invalidInput.includes('position')?'invalidInput':''}`}
            placeholder='Cargo/função'
            value={representative.contact_position}
            onChange={(event) => {
              removeInvalidInput('position');
              setRepresentative({...representative, contact_position:event.target.value})
            }}
          />
          {invalidInput.length>0 &&  (
            <label id="invalidMessage">{invalidMessage}</label>
          )}
        </div>
        <div className='stepFormHalf'>          
          <div className='leftAlignedTitle'>  
            <label>Visita</label>
          </div>
          <div id='cardVisit'>
            <div id='iconContainer'>
              <img src={iconMap} alt='iconMap'/>
            </div>
            <div className='contactMiddleEdge' />
            <div>
              <label className='contactTextLabelBold'>{location?.fantasy_name}</label>
              <label className='contactTextLabel'>{location?.address}, {location?.address_number} - {location?.address_city}/{location?.address_state}</label>
            </div>
          </div>

        </div>
      </div>
      <SubmitButtonContainer
        idCustomer={idCustomer}
        idProject={props.userProject?.id}
        steps={1}
        submitForm={()=>{submitForm()}}
      />
    </>
  )
}

export default StepVisit;