import React, {useState, useEffect} from 'react';
import Dropzone from 'react-dropzone';

import api from '../../services/api';
import './styles.css';
import {CameraAltOutlined} from '@material-ui/icons';
// import iconUsers from  '../../assets/icons/icon_usuarios.svg';
import iconUsers from '../../assets/icons/iconUser.png'
import { useHistory } from 'react-router';
import LoaderSpin from '../../components/LoaderSpin';

function Profile(props) {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [invalidInput, setInvalidInput] = useState([]);
  const [invalidMessage, setInvalidMessage] = useState('');
  const [userProfile, setUserProfile] = useState({});
  const [newPassword, setNewPassword] = useState({
    password: "",
    password_confirmation: ""
  });
  const [project, setProject] = useState('');  
  const [projectList, setProjectList] = useState([]);  
  const [passwordProfile, setPasswordProfile] = useState(false);
  const [file, setFile] = useState({});

  useEffect(() => {
    let isSubscribed = true;
    if(isSubscribed){
      setLoading(true);
    }
    try {
      const userInfo = JSON.parse(localStorage.getItem('user'));
      api.get('/users/'+userInfo.id).then(response => {
        if(response.data.length > 0){
          if(isSubscribed){
            setUserProfile(response.data[0]);
            setFile(response.data[0].file);
            
            let projectsListed = response.data[0].projects.filter(
              (v,i)=>response.data[0].projects.findIndex(l => l.id === v.id)===i
            );

            setProjectList(projectsListed.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())));

            if(props.userProject && response.data[0].projects.find(project => project.id === props.userProject.id)){
              setProject(props.userProject.id);
            }else{
              setProject(response.data[0].projects[0].id);
            }
            setLoading(false);
          }
        }
      }).catch(err => {
        console.log(err);
        if(isSubscribed){
          setLoading(false);
        }
      })
    } catch(err) {
      console.log(err);
      if(isSubscribed){
        setLoading(false);
      }
    }
  }, [props.userProject])

  async function updateUser(){
    setLoading(true);
    if(file?.id){
      api.put('/users/'+userProfile.id, {
        id_file: file.id,
      }).then(response => {
        if(response.data){
          api.get('/users/'+userProfile.id)
          .then(response => {
            if(response.data.length > 0){
              const user = (response.data[0]);
              let thisProject = props.userProject;
              const findProject = user.projects.find(item => item.id === project);
              if(findProject){
                thisProject = findProject;
              }
              localStorage.setItem('user', JSON.stringify({...user, logs:undefined}));
              localStorage.setItem('project', JSON.stringify([thisProject]));
              setUserProfile(user);
              setLoading(false);
              props.updateStates(JSON.parse(localStorage.getItem("user")), JSON.parse(localStorage.getItem("project")));
            }
          }).catch(err => {
            console.log(err);
            setLoading(false);
          })
        }
      }).catch(err => {
        if(userProfile){
          const user = userProfile;
          let thisProject = props.userProject;
          const findProject = user.projects.find(item => item.id === project);
          if(findProject){
            thisProject = findProject;
          }
          localStorage.setItem('user', JSON.stringify({...user,logs:undefined}));
          localStorage.setItem('project', JSON.stringify([thisProject]));
          setUserProfile(user);
          setLoading(false);
          props.updateStates(user, thisProject);
        }
        console.log(err);
        setLoading(false);
      })
    }else{
      api.get('/user')
      .then(response => {

        if(response.data.length > 0){
          const user = (response.data[0]);
          let thisProject = props.userProject;
          thisProject.categories = undefined;
          const findProject = user.projects.find(item => item.id === project);          
          if(findProject){
            const queryParameters = { id_project: project }
            localStorage.setItem('customerSaleFilter', JSON.stringify(queryParameters))
            sessionStorage.setItem('customerSaleFilter', JSON.stringify(queryParameters))
            thisProject = findProject;
          }
          localStorage.setItem('user', JSON.stringify({...user,logs:undefined,customers:undefined,projects:undefined}));
          localStorage.setItem('project', JSON.stringify([thisProject]));
          setUserProfile({...user,logs:undefined,customers:undefined,projects:undefined});
          setLoading(false);
          props.updateStates(user, thisProject);
        }
      }).catch(err => {

        console.log(err);
        setLoading(false);
      })
    }
  }

  async function updatePassword(){
    let inputInvalid = [];
    if(!newPassword.password_confirmation){
      inputInvalid.push('password_confirmation');
      setInvalidMessage('Preencha o campo "Confirmar senha".');
    }
    if(!newPassword.password){
      inputInvalid.push('password');
      setInvalidMessage('Preencha o campo "Nova senha".');
    }
    if(newPassword.password !== newPassword.password_confirmation){
      inputInvalid.push('password');
      inputInvalid.push('password_confirmation');
      setInvalidMessage('Senhas não conferem.');
    }

    if(inputInvalid.length > 0) {
      setInvalidInput(inputInvalid);
    }else{
      api.put('/users/'+userProfile.id, newPassword)
      .then(response =>{
        if(response.data){
          history.push('/')
        }else{
          console.log(response);
          setInvalidMessage('Não foi possivel alterar a senha.');
        }
      }).catch(err => {
        console.log(err)
        setInvalidMessage('Não foi possivel alterar a senha.');
      })
    }
  }

  async function loadFiles(id, files){
    if(id !== null && id !== undefined){
      const formData = new FormData();
      formData.append('file', files[0])

      api.put('/uploads/'+id, formData)
      .then(response =>{
        console.log('upload: ', response.data)
        if(response.data.previousFile){
          setFile(response.data.previousFile);
        }
        if(response.data.upload){
          setFile(response.data.upload);
        }
      }).catch(err => {
        console.log('response: ', err);
      })
    }else{
      const formData = new FormData();
      formData.append('file', files[0])
      
      api.post('/uploads', formData)
      .then(response =>{
        if(response.data.upload){
          setFile(response.data.upload);
        }
      }).catch(err => {
        console.log('response: ', err);
      })
    }
  }

  function removeInvalidInput(input){
    if(invalidInput.includes(input)){
      setInvalidInput(invalidInput.filter(item => item !== input));
    }    
  }

  return(
    <>
      {loading ? (
        <LoaderSpin enable={loading} />
      ):(
        <>  
        <div className='profileContainerLeft'>
          <Dropzone 
            accept='image/*'
            onDrop={acceptedFiles => loadFiles(file?.id, acceptedFiles)} 
          >
            {({getRootProps, getInputProps }) => (
              <div className='profileFullRow'>
                <div className='profileImg' {...getRootProps()} style={{ cursor: 'pointer' }}>
                  <input {...getInputProps()} />
                  <div className='userFile'>
                    <img className='' src={file?.uri? file.uri : iconUsers} />
                  </div>
                  <div className='iconImageCard'>
                    <CameraAltOutlined />
                  </div>
                </div>
              </div>
            )}
          </Dropzone>
        {!passwordProfile && (
          <>
        <div className='inputRoundedUser profileFullRowInput'>
          <label className='profileLabel'>Nome:</label>
          <label className='profileData'>{userProfile.fullname
            ? userProfile.fullname
            : userProfile.username
          }</label>
        </div>
        <div className='inputRoundedUser profileFullRowInput' style={{ marginTop: '10px', marginBottom: '10px' }}>
          <label className='profileLabel'>E-mail:</label>
          <label className='profileData'>{userProfile.email}</label>
        </div>
        </>
        )}
        {passwordProfile && (
        <>
          <input
            type='password'
            placeholder='Nova senha' 
            className={`inputRoundedUser profileFullRowInput ${invalidInput.includes('password')?'invalidInput':''}`}
            onChange={(event) => {
              setNewPassword({
                ...newPassword, 
                password: event.target.value, 
              })
              removeInvalidInput('password');
            }} 
            value={newPassword.password}
          />
        <input
            type='password'
            placeholder='Confirmar senha' 
            className={`inputRoundedUser profileFullRowInput ${invalidInput.includes('password_confirmation')?'invalidInput':''}`}
            onChange={(event) => {
              setNewPassword({
                ...newPassword, 
                password_confirmation: event.target.value, 
              })
              removeInvalidInput('password_confirmation');
            }} 
            value={newPassword.password_confirmation}
          />
          <div className='profileButtonContainer'>
            <button onClick={()=>{
              setPasswordProfile(false);
              setNewPassword({...newPassword, password: "", password_confirmation: ""})
            }}>
              Cancelar
            </button>
            <button onClick={()=>{
              updatePassword();
            }} >
              Salvar
            </button>
          </div>
        </>
        )}
        {!passwordProfile && (
          <>
            <select  
                style={{ marginTop: 0, marginBottom: '50px' }}
                className='inputRounded profileFullRow selectInputField profileData' 
                onChange={(event) =>{
                  setProject(event.target.value)
                }}
                value={project}
              > 
                <option value="" disabled selected className='profileData'>Selecione o projeto</option>
                {projectList.map(item => (
                  <option key={item.id} value={item.id} className='profileData'>{item.name}</option>
                ))}
              </select>
              <div className='profileButtonContainer'>
                <button onClick={()=>{setPasswordProfile(true)}}>Alterar Senha</button>
                <button onClick={()=>{updateUser()}}>Salvar</button>
              </div>
          </>
        )}
          <p>{invalidMessage}</p>
      </div>

      
      </>)}
    </>
  )
}

export default Profile;