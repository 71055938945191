import styled from 'styled-components';
import {Button} from '../../components'

export const Input = styled.input`
  border-bottom: 1.5px solid #EE5928;
  min-width: 350px;
  max-width: auto;
  height: 30px;
  ::placeholder{
    color: #aaa;
    font-weight: 500;
    font-size: 14px;
  }

`

export const DivSearch = styled.div`
  position: relative;
  display: flex;
  flex-direction:row;
  justify-content:flex-end;
  align-items: center;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 20px;
  margin-right: 10px
`


export const MyButton = styled(Button)`
  height: 25px;
  font-weight: bold;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;

`

