import styled from 'styled-components';

const Styles = styled.div`
  

  width: 100%;
 flex-direction:row;


.searchInputContainerClients{
    width: 50%;
   
img{
   
width:25px;
margin-right:7px;

}

flex-direction:row;

}
.spaceb{
width:50%;
height:1px;
}

.filterModal{
    position:absolute;
    width:400px;
    right:4.5%;
    padding-bottom:10px;
    margin-top:60px;
    background-color:#fff;
    box-shadow: 3px 3px 10px #00000032;
    border-radius:20px;
    align-items:center;




.modalDataFilter{
width:90%;
justify-content:space-between;
flex-direction:row;



}
.inputDiv{

flex-direction:column;
justify-content:center;
select{
        width:164px;
        color:#444;
    }
label{

    font-size:13.333px;
    font-weight:bold;
    color:#555;
    margin-top:10px;


}

}

}
.searchInputCustomerListClients{
    justify-content:flex-end;
    border-bottom: 1px solid var(--color-primary);
    flex:1;
    input{

        font-size:15px;
    }
    
}










`;

export default Styles;