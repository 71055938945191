import * as datefns from 'date-fns';
import pt from 'date-fns/locale/pt-BR';
import React from 'react';

import calculateDistance from '../../services/calculateDistance';
import Styles from './styles';

export default function CustomerList({ item, noClick }) {
  const acceptableDistance = 150;

  const distance = item.address.lat
    ? calculateDistance(
        item.address.lat,
        item.address.lng,
        item.latitude,
        item.longitude
      )
    : null;

  const formatedDocument = () => {
    // eslint-disable-next-line default-case
    return item.isPj
      ? `ID do Cliente: ${item.increment_id ?? '---'} |  ${
          item.document.replace(
            /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
            '$1.$2.$3/$4-$5'
          ) ?? ''
        }`
      : `ID do Cliente: ${item.increment_id ?? '---'} |  ${
          item.document.replace(
            /(\d{1,2})(\d{3})(\d{3})(\d{1})$/,
            '$1.$2.$3-$4'
          ) ?? ''
        }`;
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const capitalize = (str, lower = true) =>
    (lower ? str?.toLowerCase() : str)?.replace(
      /(?:^|\s|["'([{])+\S/g,
      (match) => match?.toUpperCase()
    );

  const PromotorName = (item) => {
    const history = item.history;

    if (item?.sales?.id) {
      const promoter_name = history.find(
        (elm) => elm.id_sale === item.sales.id
      );
      const seller_name = promoter_name?.promoter?.name;

      return capitalize(seller_name) ?? '';
    } else {
      const promoter_name = history.find(
        (elm) => elm.type === item?.phase?.name
      );
      const seller_name = promoter_name?.promoter?.name;

      return capitalize(seller_name) ?? '';
    }
  };

  const toNormalizedData = (date) => {
    if (!date) return '---';

    const dateN = new Date(date);

    const _date = dateN
      .toLocaleString()
      .replace(/(.+ \d\d:\d\d)(:\d\d)/, '$1')
      .split(' ');

    return `${_date[0].replace(',', '')} às ${_date[1]}`;
  };

  return (
    <Styles noClick={noClick}>
      <div className="customerListContainer">
        <div className="customerPhase">
          <div className={'squarePhaseStatus ' + item?.phase?.name ?? ''}>
            {item?.phase?.name ?? ''}
          </div>
        </div>
        <div className="estabelecimento" style={{}}>
          <label className="fantasyName" style={{ marginBottom: '5px' }}>
            {item.fantasy_name ? item.fantasy_name : '------'}
          </label>
          <label className="smallLetter" style={{ marginBottom: '3px' }}>
            {item.address.district}, {item.address.city} - {item.address.state}
          </label>
          {item?.visitHistories?.length > 0 && (
            <label className="smallLetterBold" style={{ marginBottom: '3px' }}>
              Quantidade de retornos de visita: {item.visitHistories.length}
            </label>
          )}
          <label className="smallLetterBold" style={{ marginBottom: '3px' }}>
            {item.document ? formatedDocument() : ' '}
          </label>
          {item?.sales?.id && item?.sales?.created_at && (
            <>
              <label className="smallLetter" style={{ fontWeight: '500' }}>
                <span className="saleSmallLetterBold">
                  ID da Venda: {item.sales.increment_id} |{' '}
                </span>
                <span style={{ color: '#EE5928', fontWeight: '700' }}>
                  Venda realizada em: {toNormalizedData(item.sales.created_at)}
                </span>
              </label>
            </>
          )}
        </div>
        <div className="ramo">
          <label className="smallLetterBold">
            {item.customerSegment.name ? item.customerSegment.name : ' '}
          </label>
          <label className="smallLetter">
            {item.monthly_revenue ? 'R$ ' + item.monthly_revenue : ' '}{' '}
          </label>
          {item.number_employees ? (
            <label className="smallLetter">
              {item.number_employees + ' funcionários'}
            </label>
          ) : (
            ''
          )}
          <label className="smallLetterBold">
            {item?.contact?.name
              ? capitalizeFirstLetter(item.contact.name)
              : ' '}
          </label>
          <label className="smallLetter">
            {item?.contact?.position
              ? capitalizeFirstLetter(item.contact.position)
              : ' '}
          </label>
          {/* <div className={'phaseStatus ' + item.phase_name} >{item.phase_name}</div> */}
        </div>
        {/* <div className='contato'>
                    {item.contact_mobile_phone &&
                        <div className='sellStatusContainer'>
                            <div className='smallLetter phone'>{item.contact.mobile_phone ? item.contact.mobile_phone.replace(/^(\d{2})(\d)/g, "($1) $2") : ' '}</div>
                            <PhoneRoundedIcon style={{ fontSize: 13.33, fill: item.verified_contact_mobile_phone ? '#44DF6F' : '#FF6060', marginLeft: 4 }} />
                            <EmailRoundedIcon style={{ fontSize: 13.33, fill: item.verified_email ? '#44DF6F' : '#FF6060', marginLeft: 4 }} />
                        </div>
                    }
                </div> */}

        <div className="status">
          <label className="smallLetterBold" style={{}}>
            {PromotorName(item)}
          </label>

          {item?.sales?.id && item?.sales?.salePhase?.id ? (
            <>
              <label
                className="smallLetter"
                style={{ fontSize: 11, marginTop: '5px', marginBottom: '5px' }}
              >
                {item?.sales?.product?.category?.name ?? '---'}{' '}
                {item?.sales?.product?.eanCode &&
                  ` - ${item?.sales?.product?.eanCode}`}
              </label>

              {item?.sales?.comments && item?.sales?.salePhase?.id === 6 && (
                <label
                  className="smallLetter"
                  style={{ fontSize: 11, marginBottom: '5px' }}
                >
                  {item?.sales?.comments}
                </label>
              )}

              <div className="sellStatusContainer">
                <label
                  className={
                    'salePhase1 ' + item?.sales?.salePhase?.name?.split('/')[0]
                  }
                >
                  {item?.sales?.salePhase?.name.split('/')[0]}
                </label>
                <label
                  className={
                    'salePhase2 ' + item?.sales?.salePhase?.name?.split('/')[1]
                  }
                >
                  {item?.sales?.salePhase?.name?.split('/')[1]}
                </label>
              </div>
            </>
          ) : (
            <div />
          )}
        </div>

        <div className="data">
          {distance && item.phase.id < 3 ? (
            <>
              <label className="smallLetterDistance">
                {distance ? `Distância de ${distance}m` : 'Erro de Endereço'}
              </label>
              <div
                className={
                  distance && distance > acceptableDistance
                    ? 'suspect'
                    : !distance
                    ? 'displaynone'
                    : 'acceptable'
                }
              >
                <label
                  className={
                    distance && distance > acceptableDistance
                      ? 'smallLetterSuspect'
                      : !distance
                      ? 'smallLetterSuspect'
                      : 'smallLetterAcceptable'
                  }
                >
                  {distance && distance > acceptableDistance
                    ? 'Suspeito'
                    : !distance
                    ? 'Suspeito'
                    : 'Aceitável'}
                </label>
              </div>
              <label className="smallLetter datatext">Criado em:</label>
              <label
                className="smallLetter datatext"
                style={{ marginBottom: '5px' }}
              >
                {item.created_at
                  ? `${datefns.format(
                      datefns.parseISO(item.created_at),
                      'dd/MM/yyyy',
                      { locale: pt }
                    )} ${datefns.format(
                      datefns.parseISO(item.created_at),
                      "'às 'HH:mm'h'"
                    )}`
                  : ' '}
              </label>
            </>
          ) : (
            <>
              <label className="smallLetter datatext">Criado em:</label>
              <label
                className="smallLetter datatext"
                style={{ marginBottom: '5px' }}
              >
                {item.created_at
                  ? `${datefns.format(
                      datefns.parseISO(item.created_at),
                      'dd/MM/yyyy',
                      { locale: pt }
                    )} ${datefns.format(
                      datefns.parseISO(item.created_at),
                      "'às 'HH:mm'h'"
                    )}`
                  : ' '}
              </label>
              <label className="smallLetter datatext">Atualizado em:</label>
              <label className="smallLetter datatext">
                {item.updated_at
                  ? `${datefns.format(
                      datefns.parseISO(item.updated_at),
                      'dd/MM/yyyy',
                      { locale: pt }
                    )} ${datefns.format(
                      datefns.parseISO(item.updated_at),
                      "'às 'HH:mm'h'"
                    )}`
                  : ' '}
              </label>
            </>
          )}
        </div>
      </div>
    </Styles>
  );
}
