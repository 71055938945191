import React, { useState, useEffect, useCallback, useRef } from "react";
import ModalNewProduct from "../../components/ModalNewProduct";
import { useHistory, useLocation } from "react-router-dom";

import api from "../../services/api";
import Tags from "@yaireo/tagify/dist/react.tagify"; // React-wrapper file
import "./styles.css";

function ProductsCategory({ userProject }) {
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [description, setDescription] = useState("");
  const [region, setRegion] = useState("");
  const [eanCodes, setEanCodes] = useState([]);
  const [showModal, setShowModal] = useState(false)
  const [addedProducts, setAddedProducts] = useState([])
  const [sendedProducts, setSendedProducts] = useState([])
  const [regions, setRegions] = useState([])
  const [filterSupervisors, setFilterSupervisors] = useState([])
  const [filterSupervisor, setFilterSupervisor] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [groups, setGroups] = useState([])
  const [filterGroups, setFilterGroups] = useState('')

  const History = useHistory()

  const location = useLocation();

  const tagifyRef = useRef()
  
  const createProducts = async () => {
    setAddedProducts([])    
    setSendedProducts([])
    setNameError(false)
    setDescriptionError(false)
    setErrorMessage("")

    if (name.length == 0) {
      setNameError(true)
      setErrorMessage("O nome do produto não pode ficar em branco")
      return;
    }
    if (descriptionError.length == 0) {
      setDescriptionError(true)
      setErrorMessage("A descrição do produto não pode ficar em branco")
      return;
    }

    if (eanCodes.length == 0) {
      setErrorMessage("Adicione ao menos um código de produto")
      return;
    }
    if (!regions) {
      setErrorMessage("Selecione um grupo para o produto")
      return;
    }

    const unique_ean_codes = new Set([...eanCodes])

    const array_unique_ean_codes = [...unique_ean_codes]
    
    const data = {
      id_sku_category: location.state.id,
      eans_code: array_unique_ean_codes,
      id_group: region,
      name,
      description,
      id_sku_status: 1,
      id_project: userProject?.id ?? null
    }

    if (filterSupervisor != '') {
      data.id_supervisor = filterSupervisor
    }

    const addedProducts = (await api.post('/products/createmany', data)).data

    setAddedProducts(addedProducts.map(e => e.ean_code))
    
    setSendedProducts(array_unique_ean_codes.filter(function (obj) { return addedProducts.map(e => e.ean_code).indexOf(obj) == -1; }))
    
    setEanCodes([])
    
    tagifyRef.current.removeAllTags()

    setShowModal(true)
  }

  const removeTags = (e) => {   
    const newEanCodes = tagifyRef.current.value
      .filter(elm => elm.value !== e.detail.data.value)
      .map(elm => elm.value)
       
    setEanCodes(newEanCodes)  
  }

  const addTags = _ => {
    const newEanCodes = tagifyRef.current.value.map(elm => elm.value.toUpperCase())
       
    setEanCodes(newEanCodes)  
  }

  const getSupervisors = useCallback(async (regions) => {
    const response = await api.get(`/userList?id_users_type=2&id_team=${regions}`)

    setFilterSupervisors(response.data)
  },[])

  useEffect(() => {
    if (region) {
      getSupervisors(region)
    }
  },[region, getSupervisors])


  const getGroups = useCallback(async () => {
    const response = await api.get(`/groupUser?id_project=${userProject.id}`)

    setGroups(response.data)
  }, [userProject.id])

 
  useEffect(() => {
    getGroups();
  }, [getGroups]);

  const filterTeams = async (group) => {
    const response = await api.get(`/groupSections/${group}`)
    setRegions(response?.data?.teams || [])
  }

  useEffect(() => {
    if (filterGroups) {
      filterTeams(filterGroups)
    }
  }, [filterGroups])
  
  return (
    <div className="fieldsMainContainer">
      <ModalNewProduct 
        exit={() => { setShowModal(false); History.push('/ProductsDetails', location.state) }} 
        title={"Produtos adicionados"} 
        sendedProducts={sendedProducts} 
        addedProducts={addedProducts} 
        showAlert={showModal} 
        showModal={() => { setShowModal(false)}}  
        />
      <div className="topProductsCategoryContainer">
        <div className="cardProductss">
          <h3 className="ProductTitleDts" style={{ paddingRight: 0 }}>Novo Produto</h3>
          <button  
            onClick={() => {
                History.push('/ProductsDetails', location.state)
            }}  
            className='ButtonProductCategory'>
            Voltar
          </button>
        </div>
      </div>
      <div className='contentContainer'>
        <div className='ProductsCategoryCatContainer'>
          <span className='ProductsCategoryCatLabel'>Nome da categoria:</span>
          <span className='ProductsCategoryCatValue'>{location.state.name}</span>
        </div>
        <div className='InputsContainerProducts'>

          <div className='ProductsCategoryInputContainer'>
            <span className='ProductsCategoryLabel'>Nome do produto</span>
            <input value={name} onChange={(e) => { setName(e.target.value) }} className={`inputRounded ${nameError ? 'invalidInput' : ''}`} />
            
            <span className='ProductsCategoryLabel'>Descrição do Produto</span>
            <textarea value={description} onChange={(e) => { setDescription(e.target.value) }} className={`inputRounded ${descriptionError ? 'invalidInput' : ''}`} />
            
            <span className='ProductsCategoryLabel'>Grupo</span>
            <select 
               onChange={(elm) => {
                  setRegion('')
                  setFilterGroups(elm.target.value)
              }} 
              className='inputRounded'
            >
              <option value="" disabled selected>Selecione o grupo</option>
              {groups?.map((elm) => <option value={elm.id} key={elm.id+elm.section}>{elm.section}</option>)}
            </select>
            
            <span className='ProductsCategoryLabel'>Time</span>
            <select 
              value={region}
              onChange={(e) => setRegion(e.target.value)} 
              className='inputRounded'
              disabled={!filterGroups}
            >
              <option value="" disabled selected>Selecione um time</option>
              {regions?.map((elm) => <option value={elm.id} key={elm.id+elm.name}>{elm.name}</option>)}
            </select>
            
            <span className='ProductsCategoryLabel'>Selecione um supervisor</span>
            <select value={filterSupervisor} onChange={(e) => setFilterSupervisor(e.target.value)} className='inputRounded'>
              <option value={''}>{"Nenhum"}</option>
              {filterSupervisors?.map((elm) => <option value={elm.id} key={elm.id+elm.username}>{elm.username}</option>)}
            </select>
          </div>
          <div className='ProductsCategoryInputContainer'>
            <span className='ProductsCategoryLabel'>Código dos Produtos</span>
            <Tags
              tagifyRef={tagifyRef}
              onRemove={removeTags}
              onAdd={addTags}
              className={`Tags ProductCategoryTags`}
            />
          </div>
        </div>
        <p className="errorMessage">{errorMessage}</p>
        <button onClick={createProducts} className='ButtonProductCategory'>Salvar Produto</button>
      </div>
    </div>

  );
}

export default ProductsCategory;
