import React from 'react'

import * as S from './terms-and-conditions.styles'

import Logo from '../../utils/logo.svg'

export const TermsAndConditions = () => {
  return (
    <S.Body>
      <S.Wrapper>
        <S.Header>
          <img src={Logo} alt="Logo Prisma"/>
        </S.Header>
        <S.Content>
        <span style={{
          fontSize: '30px',
          fontStyle: 'normal',
          fontWeight: '700'
        }}>
          Termos de Uso e Política de Privacidade</span><br/><br/>

          Atualizado em maio de 2023<br/><br/>

          <span style={{fontWeight: '700'}}>1. Informações Gerais</span><br/><br/>

          O site www.prismapro.com.br (“Site”) é de propriedade da PRISMA PROMOTORA PRESTADORA DE SERVICOS E INTERMEDIACAO DE NEGOCIOS, pessoa jurídica de direito privado, com sede na Alameda Rio Negro, nº 877, Conj 201, Alphaville, Barueri, SP CEP 06454-000, inscrita no CNPJ/MF sob nº 07.178.659/0001-81, doravante denominada “PrismaPro”.
          Ao acessar o site, aplicativo ou através de nossos promotores de vendas, fornecer seus dados pessoais (ou seja, dados relacionados à pessoa física ou jurídica identificada ou identificável), você (“Usuário”) consente e concorda com as disposições a seguir, aplicáveis para qualquer acesso aos produtos Prisma, seja por meio de desktop, smartphones e/ou outros dispositivos móveis. Por isso, sugerimos que o Usuário leia atentamente o conteúdo deste Termo.
          Este Termo poderá ser atualizado em decorrência de eventual atualização normativa ou atualização de procedimentos internos da Prisma, razão pela qual se convida o Usuário a consultar periodicamente esse Termo. Caso as alterações dessa Termo sejam significativas, a Prisma poderá entrar em contato para avisar o Usuário.<br/><br/>

          <span style={{fontWeight: '700'}}>2. Política de Privacidade</span><br/><br/>
          A Prisma toma as medidas necessárias para que o tratamento dos dados pessoais provenientes do correio eletrônico ou através do preenchimento de formulários eletrônicos no Site ou através de promotores de vendas, esteja em conformidade com as disposições a Lei Federal nº 12.965 de 23 de abril de 2014 (Marco Civil da Internet) e com a Lei Federal nº 13.709, de 14 de agosto de 2018 (Lei Geral de Proteção de Dados Pessoais). Os dados pessoais são tratados com o auxílio de ferramentas informáticas, por pessoal autorizado, exclusivamente para efeitos de processamento de acordo com os critérios legais e finalidades compatíveis com o que é informado ao usuário.<br/>
          A Prisma adota as medidas que entende cabíveis para garantir a segurança dos dados pessoais dos Usuários. Os bancos de dados estão hospedados na nuvem em servidores com Firewalls e segurança contra invasão. Havendo quaisquer incidentes de segurança, que acarrete vazamento, quebra de sigilo ou integridade, encaminharemos e-mail ao Usuário informando sobre o ocorrido.<br/>
          O responsável pela proteção dos dados pessoais da Prisma pode ser contatado na sede da empresa através do e-mail: privacidade@prismapromotora.com.br.<br/><br/>

          <span style={{fontWeight: '700'}}>2.1 Que Dados Pessoais tratamos e para quê?</span><br/><br/>

          O PrismaPro usa as informações que coleta para operar, manter e fornecer os recursos e funcionalidades do Serviço, para analisar como o Serviço é usado, diagnosticar serviços ou problemas técnicos, manter a segurança, personalizar o conteúdo, recuperar informações para ajudá-lo a acessar sua conta com eficiência, monitorar métricas agregadas, como número total de visitantes, tráfego e padrões demográficos, e rastrear conteúdo e usuários para fornecer um serviço melhor.<br/><br/>
          O PrismaPro se compromete a processar informações legalmente classificadas como Dados Pessoais em conformidade com a legislação aplicável, incluindo, mas não se limitando a Lei Federal 13.709/2018 (Lei Geral de Proteção de Dados do Brasil) e, como Operador de Processamento de Dados Pessoais, realizará o Tratamento de Dados Pessoais Dados exclusivamente para atingir as Finalidades delimitadas pelos Termos de Uso e/ou demais documentos que regulem a relação entre o PrismaPro e seus clientes.<br/><br/>
          Quando na qualidade de Operador, o PrismaPro tem o direito de recusar, mediante notificação formal e por escrito, qualquer operação ordenada por um Controlador, que implique o Tratamento de Dados Pessoais em desconformidade com as regras de proteção de Dados Pessoais em vigor.<br/><br/>
          O PrismaPro se declara como controlador de dados apenas dos dados pessoais utilizados para criar os usuários da plataforma, por exemplo: nome e e-mail do usuário, onde a base legal definida para tal controle é a execução do contrato, para acessar a plataforma para uso do nosso produto.<br/><br/> 
          Para todos os demais dados inseridos, cadastrados, armazenados e processados na plataforma, o PrismaPro se declara como operador dos dados, disponibilizando apenas a plataforma para que nossos clientes operacionalizem esses dados.<br/><br/><br/>

          Informações fornecidas diretamente por você:<br/><br/>

          Registro: Você nos fornece informações sobre você, como seu nome e endereço de e-mail, quando é registrado em uma conta para usar nosso Serviço, incluindo conectar-se ao nosso Serviço por meio de um serviço de terceiros ou "seguir", "tornar-se um fã ," baixando o aplicativo PrismaPro etc. em um site ou rede de terceiros. Seu nome, endereço de e-mail e outras informações que você escolher fornecer no Serviço serão visíveis e descobertos por outros usuários de acordo com suas configurações em nosso Serviço. <br/><br/>
          Podemos usar seu endereço de e-mail para enviar mensagens relacionadas ao Serviço (incluindo quaisquer avisos exigidos por lei, em vez de comunicação por correio postal). Também podemos usar suas informações de contato para enviar marketing por e-mail. Se você não deseja receber mais mensagens nossas, pode optar por cancelar a assinatura delas seguindo as instruções apropriadas divulgadas em cada mensagem.  <br/><br/>
          Se você se comunicar conosco por e-mail, poderemos reter o conteúdo de suas mensagens de e-mail e seu endereço de e-mail, bem como nossas respostas. Se você optar por usar nosso serviço de convite para convidar um amigo para se inscrever no Serviço, solicitaremos o endereço de e-mail dessa pessoa e enviaremos automaticamente um convite por e-mail. O PrismaPro armazena essas informações para enviar este e-mail, registrar seu amigo caso seu convite seja aceito e acompanhar o sucesso de nosso serviço de convite. <br/><br/>
          Conteúdo: Você também nos fornece informações não pessoais no Conteúdo que você publica no Serviço. Seu Conteúdo e metadados sobre seu Conteúdo podem ser visualizados por outros usuários de acordo com suas configurações no Serviço. O PrismaPro pode, mas não tem obrigação, de monitorar o Conteúdo que você publica no Serviço. Também podemos remover qualquer informação que você postar de acordo com as disposições dos Termos e Condições. O PrismaPro ou os funcionários do PrismaPro não revisarão seu Conteúdo, exceto para o seguinte: (i) se suas configurações no Serviço permitirem; (ii) para manter, fornecer ou melhorar o Serviço; (iii) para ajudá-lo a resolver suas solicitações de suporte; ou (iv) para cumprir ou evitar qualquer violação da lei ou regulamento aplicável, cooperando com as autoridades policiais. <br/><br/><br/>

          Dados coletados automaticamente:<br/><br/>

          <span style={{fontWeight: '700'}}>Cookies:</span> quando você usa o Serviço, podemos enviar um ou mais “cookies” — um pequeno arquivo de dados — para o seu computador para identificar exclusivamente o seu navegador e permitir que o PrismaPro o ajude a fazer login mais rapidamente e aprimorar sua navegação pelo site. Um cookie pode transmitir informações anônimas sobre como você navega no Serviço para nós. Um cookie persistente permanece em seu disco rígido depois que você fecha seu navegador para que possa ser usado por seu navegador em visitas subsequentes ao Serviço. Cookies persistentes podem ser removidos seguindo as instruções do seu navegador. Um cookie de sessão é temporário e desaparece depois que você fecha o navegador. Você pode redefinir seu navegador da Web para recusar todos os cookies ou para indicar quando um cookie está sendo enviado. No entanto, alguns recursos do Serviço podem não funcionar corretamente se a capacidade de aceitar cookies estiver desativada. <br/><br/>
          <span style={{fontWeight: '700'}}>Arquivos de Log:</span> Quando você usa o Serviço, nossos servidores registram automaticamente certas informações enviadas pelo seu navegador da web. Esses logs do servidor podem incluir informações como sua solicitação da web, endereço de Protocolo de Internet (“IP”), tipo de navegador, páginas de referência/saída e URLs, número de cliques e como você interage com links no Serviço, nomes de domínio, páginas de destino , páginas visualizadas, operadora de celular e outras informações desse tipo. <br/><br/>
          <span style={{fontWeight: '700'}}>Informação Clear Gifs:</span> Quando você usa o Serviço, podemos empregar clear gifs (também conhecidos como web beacons) que são usados para rastrear os padrões de uso online. Além disso, também podemos usar clear gifs em e-mails baseados em HTML enviados aos nossos usuários para rastrear quais e-mails são abertos pelos destinatários. As informações são usadas para permitir relatórios mais precisos e tornar o PrismaPro melhor para nossos usuários. <br/><br/>
          <span style={{fontWeight: '700'}}>Informações de geolocalização:</span> quando você usa o Serviço por ou por meio de um dispositivo móvel ou computador/laptop, podemos acessar, coletar, monitorar e/ou armazenar remotamente “dados de localização”, que podem incluir coordenadas de GPS (por exemplo, latitude e/ ou longitude) ou informações semelhantes sobre a localização do seu dispositivo. Os dados de localização, ainda que não recolhamos ou partilhemos Dados Pessoais que o identifiquem de imediato, poderão ser utilizados em conjunto com outros Dados Pessoais que permitam a sua identificação. Alguns recursos do Serviço, especialmente serviços baseados em localização, podem não funcionar corretamente se o uso ou a disponibilidade dos dados de localização forem prejudicados ou desativados. <br/><br/>
          <span style={{fontWeight: '700'}}>Identificadores de dispositivo:</span> quando você acessa o Serviço por ou por meio de um dispositivo móvel, podemos acessar, coletar, monitorar e/ou armazenar remotamente um ou mais “identificadores de dispositivo” bem como dados da operadora de telefonia móvel como número de celular e id do chip da operadora. Estes dados podem ser compartilhados com terceiros para aprimoramento dos serviços como Google Firebase, Analytics, OneSignal e serviços da Amazon AWS. Sobre os identificadores de dispositivo, são pequenos arquivos de dados ou estruturas de dados semelhantes armazenados ou associados ao seu dispositivo móvel, que identificam exclusivamente o seu dispositivo móvel e são usados para aprimorar o Serviço. Um identificador de dispositivo pode permanecer persistentemente em seu dispositivo para ajudá-lo a fazer login mais rapidamente e aprimorar sua navegação pelo Serviço. Alguns recursos do Serviço podem não funcionar corretamente se o uso ou a disponibilidade de identificadores de dispositivos forem prejudicados ou desativados. O PrismaPro pode acessar, coletar e/ou armazenar identificadores de dispositivos ao habilitar os Serviços do PrismaPro. Um identificador de dispositivo pode ser armazenado pelos seguintes meios: em conexão com o hardware do dispositivo, por dados armazenados em conexão com o sistema operacional do dispositivo ou outro software ou dados enviados ao dispositivo por nós. Um identificador de dispositivo, embora não colete ou compartilhe quaisquer Dados Pessoais que o identifiquem diretamente, pode ser usado em conjunto com outros Dados Pessoais que permitam sua identificação. <br/><br/>
          <span style={{fontWeight: '700'}}>Ferramentas de Terceiros:</span> O PrismaPro usa ferramentas analíticas de terceiros, como o Google Analytics e OneSignal, para ajudar a entender o uso do Serviço e facilitar serviços como notificações. Muitas dessas ferramentas coletam as informações como parte de uma solicitação de página da web, incluindo cookies e seu endereço IP. Essas ferramentas de análise também recebem essas informações e seu uso é regido por sua política de privacidade. <br/><br/>
          <span style={{fontWeight: '700'}}>Seu uso:</span> exibiremos suas informações pessoais em sua página de perfil e em outros lugares do Serviço de acordo com as preferências definidas em sua conta. Qualquer informação que você decida fornecer deve refletir o quanto você deseja que os outros saibam sobre você. Considere cuidadosamente quais informações você divulga em sua página de perfil e o nível de anonimato desejado. Também podemos compartilhar ou divulgar suas informações com o seu consentimento, por exemplo, se você usar um aplicativo de terceiros para acessar sua conta (veja abaixo). Você pode revisar e revisar suas informações de perfil a qualquer momento. <br/><br/>
          No Brasil, caso sejam processados Dados Pessoais de menores de 12 anos, solicitaremos o consentimento específico e independente de um dos pais ou responsável legal. Na União Europeia, se os Dados Pessoais de menores de 16 anos forem processados, solicitaremos o consentimento da pessoa que detém o poder parental sobre a criança. Finalmente, no Reino Unido, se o Tratamento de Dados Pessoais de menores de 13 anos estiver incompleto, solicitaremos o consentimento da pessoa que detém o poder parental sobre a criança. <br/><br/><br/>

          <span style={{fontWeight: '700'}}>2.2 Por que e com quem compartilhamos seus dados pessoais?</span><br/><br/>

          Compartilhamos seus Dados Pessoais apenas para Finalidades específicas e legítimas, sempre de acordo com a legislação de privacidade e proteção de dados aplicável, conforme descrito abaixo: <br/><br/>
          Prestadores de serviços e outros: O PrismaPro pode compartilhar seus Dados Pessoais com outros Terceiros com a finalidade de fornecer o Serviço a você. Se o fizermos, tais parceiros de negócios e outros Terceiros serão obrigados a manter suas informações confidenciais e adotar os mesmos procedimentos e nível de proteção que o PrismaPro adota. Também podemos armazenar Dados Pessoais em locais fora das instalações do PrismaPro (por exemplo, em servidores ou bancos de dados co-localizados com provedores de hospedagem). <br/><br/>
          Transferências de negócios:   À medida que desenvolvemos nossos negócios, podemos comprar ou vender ativos ou ofertas de negócios. Informações de clientes, e-mail e visitantes geralmente são um dos ativos comerciais transferidos nesses tipos de transações. Também podemos transferir ou atribuir essas informações no curso de alienações, fusões ou dissoluções corporativas. <br/><br/>
          Serviços de terceiros: podemos compartilhar suas informações com um aplicativo de terceiros com o seu consentimento, por exemplo, quando você optar por acessar nossos serviços por meio de tal aplicativo. Portanto, você deve se certificar de que confia no aplicativo e de que ele possui uma política de privacidade aceitável para você. <br/><br/>

          O PrismaPro usa subprocessadores para fornecer suporte e permitir um ambiente e soluções online. Todos os subprocessadores são cuidadosamente avaliados e seguem os mais altos requisitos de segurança, privacidade e conformidade possíveis.<br/><br/>

          <span style={{fontWeight: '700'}}>Entidade Terceirizada | Hospedagem (localização) | Atividade</span><br/><br/>
          SendGrid | EUA | Envie e-mails pelo sistema PrismaPro<br/>
          OneSignal | EUA | Notificações via push<br/>
          Zapsign | BR | Solução de assinatura digital<br/>
          AWS | BR | Solução de nuvem PrismaPro<br/><br/>

          <span style={{fontWeight: '700'}}>Conformidade com as leis e solicitações de aplicação da lei e proteção dos direitos do PrismaPro:</span> O PrismaPro pode divulgar suas informações pessoais se exigido por lei ou intimação ou se acreditarmos que é razoavelmente necessário cumprir uma lei, regulamento ou solicitação legal; para proteger a segurança de qualquer pessoa; para lidar com questões de fraude, segurança ou técnicas; ou para proteger os direitos ou propriedade do PrismaPro .<br/><br/>
          <span style={{fontWeight: '700'}}>Dados anônimos:</span> Podemos divulgar algumas informações, normalmente agregadas, com terceiros interessados ​​para ajudá-los a entender os padrões de uso de determinados Serviços PrismaPro. As informações compartilhadas para este fim não são consideradas Dados Pessoais pela LGDP, pois não permitem a identificação do seu titular.<br/><br/>

          <span style={{fontWeight: '700'}}>2.3 Quais direitos você possui e como exercê-los?</span><br/><br/>

          No âmbito da LGPD, você, como Titular dos Dados, tem o direito de: <br/><br/>

          <ul style={{marginLeft: '20px'}}>
          <li>saber quais Dados Pessoais foram tratados pelo PrismaPro e acessá-los;</li>  
          <li>descobrir com quem seus Dados Pessoais foram compartilhados; </li>
          <li>corrigir, atualizar e completar seus Dados Pessoais </li>
          <li>exigir anonimização, bloqueio ou exclusão de dados desnecessários, excessivos ou processados ilegalmente; </li>
          <li>quando o Tratamento exigir o seu consentimento, ser informado sobre a possibilidade de não fornecê-lo e sobre as consequências de tal recusa; </li>
          <li>revogar seu consentimento a qualquer momento se você o tiver fornecido; </li>
          <li>solicitar a portabilidade dos dados para outro prestador de serviço ou produto, mediante solicitação expressa do Usuário; </li>
          <li>solicitar a reconsideração de decisões tomadas exclusivamente com base no Tratamento Automatizado de Dados Pessoais e que afetem seus interesses; e </li>
          <li>opor-se ao Tratamento de dados que, porventura, tenha sido realizado em desacordo com a lei.   </li>
          </ul>
          <br/>
          Para exercer os direitos acima, ou quaisquer outros direitos garantidos por lei, entre em contato conosco em privacidade@prismapromotora.com.br .     <br/><br/>
          Para atender a Lei Geral de Proteção de Dados do Brasil, disponibilizamos um canal de atendimento do nosso Diretor de Proteção de Dados, que responderá nos prazos legais estabelecidos por lei. O contacto do Gabinete de Proteção de Dados é: dpo@prismapromotora.com.br . Depois de recebermos o aviso de que você revogou seu consentimento, não processaremos mais suas informações para o(s) propósito(s) com o qual você originalmente consentiu. <br/><br/>
          Caso tratemos seus Dados Pessoais para fins de marketing direto, você tem o direito de se opor a essa atividade a qualquer momento, caso em que não processaremos mais seus Dados Pessoais para tais fins de marketing. <br/><br/>
          Nosso serviço oferece serviços comunitários acessíveis ao público, incluindo blogs e fóruns. Você deve estar ciente de que qualquer informação fornecida nessas áreas pode ser lida, coletada e usada por outras pessoas que as acessam. <br/><br/>

          <span style={{fontWeight: '700'}}>2.4 Por quanto tempo retemos seus Dados Pessoais? </span><br/><br/>

          Reteremos cópias de suas informações durante todo o período em que você tiver uma conta ou pelo período necessário para os fins estabelecidos neste Aviso, a menos que a lei aplicável exija um período ou retenção mais longo. Além disso, retemos suas informações pelo período necessário para estabelecer, exercer ou defender quaisquer direitos legais. <br/><br/>
          A partir do momento em que o contrato de prestação de serviço entre o PrismaPro e você terminar, manteremos seus Dados Pessoais em nosso banco de dados por 180 dias. Após esse período, seus dados serão excluídos permanentemente de nossos sistemas. <br/><br/>

          <span style={{fontWeight: '700'}}>2.5 Dados que coletamos de terceiros </span><br/><br/>

          Podemos recolher alguns Dados Pessoais de outras fontes que não o Titular dos Dados, nas seguintes situações : <br/><br/>
          
          <ul style={{marginLeft: '20px'}}>
          <li>dados cadastrais e comportamentais, quando um parceiro PrismaPro fornece Dados Pessoais de seus clientes para prospecção e promoção de nossos produtos e serviços; </li>
          <li>dados cadastrais e financeiros, quando você entra nas dependências do PrismaPro para prestar um serviço em nome de terceiros; </li>
          <li>dados cadastrais e comportamentais, quando se candidatar a uma vaga de emprego, por intermédio de Terceiro; </li>
          <li>dados de registro e interação do usuário com nossos produtos, coletados por meio de plataformas de gerenciamento de vendas de terceiros, assinaturas e feedbacks, bem como análises de marketing e negócios. </li>
          <li>dados cadastrais e comportamentais, para atendimento ao cliente quando este suporte envolver plataformas de terceiros; </li>
          <li>quando você é funcionário de uma empresa que usa o PrismaPro, essa empresa pode nos fornecer dados cadastrais sobre você para que possamos firmar o contrato com ela. Além disso, a empresa também poderá compartilhar dados comportamentais de seus funcionários conosco, com o objetivo de treinar os usuários que serão administradores da ferramenta PrismaPro em nome da empresa. </li>
          </ul>
          <br/><br/>

          <span style={{fontWeight: '700'}}>3. Alterações a este Aviso </span><br/><br/>

          Se alterarmos nosso Aviso de Privacidade e você for nosso cliente ou cadastrado em nosso mailing, enviaremos um e-mail informando sobre a atualização. Além disso, publicaremos essas alterações nesta página para mantê-lo informado sobre quais informações coletamos, como as usamos e sob quais circunstâncias podemos divulgá-las.  <br/><br/>
          As alterações a esta Política de Privacidade entram em vigor quando são publicadas nesta página.  <br/><br/>

          <span style={{fontWeight: '700'}}>4. Aviso Legal </span><br/><br/>

          Não somos responsáveis pelas práticas empregadas por sites vinculados a ou a partir de nosso Serviço, nem pelas informações ou conteúdo neles contidos. Lembre-se de que quando você usa um link para ir do Serviço para outro site, nosso Aviso de Privacidade não estará mais em vigor. A sua navegação e interação em qualquer outro site, incluindo aqueles que possuem um link em nosso site, está sujeita às regras e políticas do site que você está visitando.  <br/><br/>

          <span style={{fontWeight: '700'}}>5. Perguntas </span><br/><br/>

          Se você tiver alguma dúvida sobre este Aviso de Privacidade, entre em contato conosco em privacidade@prismapromotora.com.br.  <br/><br/>

          <span style={{fontWeight: '700'}}>6. Termos de Uso</span><br/><br/>

          A Prisma não garante que o Site estará disponível ininterruptamente e livre de erros, não podendo, por conseguinte, ser responsabilizada por qualquer interrupção no funcionamento do Site, seja por que motivo for.<br/>

          A Prisma reserva-se o direito de modificar o conteúdo do Site a qualquer momento e sem nenhum aviso prévio.<br/>

          O usuário deve atentar-se para que tudo o que for enviado à Prisma através dos endereços de correio eletrônico indicados no Site ou através do Site (por exemplo, mas sem se limitar, a ideias, solicitações, informações, sugestões, documentos), não será considerado informações ou dados de natureza confidencial, não violará os direitos de terceiros e deve conter informações corretas e verdadeiras. De qualquer modo, a Prisma não pode ser responsabilizada pelo conteúdo das mensagens enviadas pelos usuários.<br/><br/>

          <span style={{fontWeight: '700'}}>6.1 Acesso a Sites Externos</span><br/><br/>
          A Prisma não assume nenhuma responsabilidade com referência aos sites de terceiros, a seus conteúdos e funcionamento, aos quais seja possível acessar através dos links indicados no Site.<br/>

          O usuário que visitar um site através de um link indicado no Site da Prisma, faz a seu próprio risco, sendo responsável pela adoção de todas as medidas necessárias contra vírus ou outros elementos destrutivos. A ligação com outros sites não implica que entre a Prisma e os sujeitos dos links haja um vínculo.<br/><br/>

          <span style={{fontWeight: '700'}}>6.2 Propriedade Intelectual</span><br/><br/>
          Todos os conteúdos (textos, gráficas, arquivos, tabelas, imagens e informações de qualquer natureza), assim como os produtos e as marcas mencionadas neste Site, são protegidos ao abrigo das normas vigentes, em matéria de propriedade intelectual e industrial.<br/>

          A reprodução, em qualquer meio, dos materiais publicados, se expressamente permitida, poderá ser efetuada, sendo, porém, para uso exclusivamente pessoal, sem fins lucrativos e sem fins direta ou indiretamente comerciais.<br/><br/>

          <span style={{fontWeight: '700'}}>7. Disposições Gerais</span><br/><br/>
          Conforme mencionado acima, caso o usuário tenha dúvidas ou precise tratar de qualquer assunto relacionado a esse Termo, entre em contato conosco através do e-mail: privacidade@prismapromotora.com.br.<br/>

          Caso qualquer cláusula, termo ou disposição deste Termo seja declarada nula, tal nulidade não afetará quaisquer outras cláusulas, termos ou disposições aqui contidas, as quais permanecerão em pleno vigor e efeito.<br/>

          A tolerância por qualquer uma das Partes com relação a qualquer violação dos presente Termo ou sua omissão no exercício de qualquer direito outorgado pelo mesmo, não será considerado como novação ou renúncia em relação a qualquer violação futura, seja semelhante ou não, ou ao exercício por qualquer uma das partes de qualquer direito futuro conferido por este Termo.<br/><br/>

          <span style={{fontWeight: '700'}}>8. Legislação e Foro</span><br/><br/>
          Esse Termo é regido de acordo com a legislação brasileira. Quaisquer disputas ou controvérsias oriundas de quaisquer atos praticados no âmbito da utilização do Site, inclusive com relação ao descumprimento deste Termo ou pela violação dos direitos da Prisma, inclusive direitos de propriedade intelectual e de sigilo, serão processadas na Comarca da Capital do Estado de São Paulo.<br/>
        </S.Content>
      </S.Wrapper>
    </S.Body>
    
  )
}