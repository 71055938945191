import styled from 'styled-components'

export const Body = styled.div`
  overflow-x: hidden;

`

export const Wrapper = styled.div`
padding: 60px;
  display: grid;
  grid-gap: 1em;
  grid-template-areas:
   "header"
   "content";
`
export const Header = styled.header`
  grid-area: header;
  img{
    width: 60px;
    height: 90px;
  }
`

export const Content = styled.main`
  grid-area: content;

`