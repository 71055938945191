import React, { useState, useContext } from 'react';

import StoreContext from '../../../context/Context';

import { Spinner } from '../../../components';
import Dropzone from 'react-dropzone-uploader'
import CheckIcon from '@material-ui/icons/Check';
import { getDroppedOrSelectedFiles } from 'html5-file-selector'

import * as S from './styled';

import api from "../../../services/api";

import swal from 'sweetalert';

export const Actions = (props) => {    
    const [fileUploaded, setFileUploaded] = useState(false)
    const [firstPanelConcluded, setFirstPanelConcluded] = useState(false);

    const { userProjectStore } = useContext(StoreContext);

    const getUploadParams = async ({ file, remove }) => {
        const formData = new FormData();

        formData.append('xlsx', file);
    
        const token = localStorage.getItem('token') || sessionStorage.getItem('token');
        
        setFirstPanelConcluded(true);      

        const ok = await swal({  
            title: `Atenção você está no projeto ${userProjectStore.name}!`,
            text: "Você deseja realmente importar ações para esse projeto?",
            icon: "warning",
            buttons: {
                cancel: {
                  text: "Cancel",
                  value: false,
                  visible: true,
                  className: "",
                  closeModal: true,
                },
                confirm: {
                  text: "OK",
                  value: true,
                  visible: true,
                  className: "",
                  closeModal: true
                }              

            }      
          });

        if (ok) {
            return {
                url: (process.env.REACT_APP_API ?? 'https://apidev.prismapro.com.br') + `/user-actions?id_project=${userProjectStore.id}`,
                headers: { authorization: `Bearer ${token}` },
                body: formData,
                method: 'POST'
            }
        } 
        
        remove()
    }

    const getFilesFromEvent = e => {
        return new Promise(resolve => {
          getDroppedOrSelectedFiles(e).then(chosenFiles => {
            resolve(chosenFiles.map(f => f.fileObject))
          })
        })
    }

    const handleChangeStatus = ({ meta, file, remove }, status) => {
        if (status === 'done') {
          setFileUploaded(true);
        }    

        if (status === 'error_upload' || status === 'exception_upload') {
            remove()
            
            swal('Desculpe, tivemos um erro inesperado!', {
                icon: "error",
                button: "Fechar"
            });            
        }
    }
    
    const handleSubmit = (files, allFiles) => {
        allFiles.forEach(f => f.remove())
    }

    const downloadModelSheet = async () => {
        const response = await api.get('https://api.prismapro.com.br/images/42c59acd-9eb3-4c30-a673-e45db4b40b82', {
            responseType: "blob",
            headers: {
              'Accept': 'application/octet-stream'
            }
        })
    
        if (response.status === 200) {
        
            const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
    
            const link = document.createElement('a');
    
            link.href = downloadUrl;    
    
            link.setAttribute('download', `Modelo_De_Acoes.xlsx`);
    
            document.body.appendChild(link);
    
            link.click();
    
            link.remove()
    
            return
        }    
    }
    
    return (
        <>  
            <S.Tabs>
                <S.ExternalButtonDiv>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <S.StyledCheckIcon panelconcluded={firstPanelConcluded} />
                        <S.HeaderSpan>{'Adicionar Base de Ações'}</S.HeaderSpan>
                    </div>
                </S.ExternalButtonDiv>
                <div style={{ textAlign: 'center' }}>
                    <p 
                        style={{ 
                            textDecoration: 'underline', 
                            color: '#EE5928',
                            cursor: 'pointer'
                        }}
                       onClick={downloadModelSheet}
                    >
                        Baixe o modelo aqui
                    </p>                       
                    <p 
                        style={{ 
                            color: '#EE5928',
                            marginBottom: '0px',
                            fontWeight: 'bold',
                            fontSize: '18px'
                        }}
                    >
                        Importante
                    </p>                       
                    <p
                        style={{ 
                            lineHeight: '30px',
                            marginBottom: '1px',
                            fontSize: '16px'
                        }}
                    >
                    Em cada upload realizado, a lista de ações importada anteriormente será sobrescrita por completo, isto é, 
                    todos os usuários da listagem anterior terão suas ações deletadas mesmo que a nova lista não mencione um determinado usuário da lista anterior, 
                    portanto garanta que sua nova lista de ações esteja completa em todos os uploads que realizar.
                    </p>                       
                </div>
                <S.InsideContainer>                   
                    <Dropzone
                        getUploadParams={getUploadParams}
                        maxFiles={1}
                        multiple={false}
                        canCancel={false}
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        inputContent="Arraste ou clique aqui para selecionar o arquivo"
                        onChangeStatus={handleChangeStatus}      
                        PreviewComponent={({ meta }) => {
                            const { name, status } = meta
                            return (
                            <span 
                                style={{ 
                                    textAlign: 'center', 
                                    alignSelf: 'flex-start', 
                                    margin: '10px 3%', 
                                    fontFamily: 'Roboto', 
                                    display: 'flex', 
                                    flexDirection: 'row',
                                    fontSize: 20 
                                }}
                            >
                                {name} 
                                {status != "done" ? 
                                    <><span style={{ marginRight: 10, marginLeft: 3 }}>- Aguarde estamos processando os dados</span> <Spinner small={true} /></> : 
                                    <CheckIcon style={{ fill: "green", fontSize: 19, marginLeft: 5 }} />
                                }
                            </span>
                            )
                        }}   
                        LayoutComponent={({ dropzoneProps, files, input, extra: { maxFiles }, submitButton, previews }) =>
                            <S.DropzoneContainer {...dropzoneProps} >
                            {previews}
                            <S.DropzoneContent>
                                {files.length < maxFiles && input}
                            </S.DropzoneContent>
                            {files.length > 0 && submitButton}
                            </S.DropzoneContainer>
                        } 
                        InputComponent={({ accept, onFiles }) => {
                            return (
                                <div style={{ alignItems: 'center', padding: 10, justifyContent: 'center' }}>
                                    <label style={{ fontSize: 20, marginBottom: 20, textAlign: 'center' }}>Arraste, cole ou clique aqui para selecionar o arquivo.</label>
                                    <label style={{ textAlign: 'center' }}>Importar dados de um arquivo XLSX. A importação está limitada a 40 MB.
                                    Para listas maiores, divida seu arquivo em arquivos menores e envie separadamente.</label>
                                    <label style={{ textAlign: 'center' }}>
                                    
                                    </label>
                                    <input
                                        style={{ top: 0, left: 0, width: '100%', height: '100%', position: 'absolute', opacity: 0, cursor: 'pointer' }}
                                        type="file"
                                        accept={accept}
                                        multiple
                                        onChange={e => {
                                            getFilesFromEvent(e)
                                                .then(chosenFiles => {
                                                    onFiles(chosenFiles)
                                                })
                                        }}
                                    />
                                </div>
                            )
                        }}
                        onSubmit={handleSubmit}
                        submitButtonContent={'Adicionar Nova Base'}
                    />
                </S.InsideContainer>               
            </S.Tabs>
        </>
    )
}

