import React, {useState, useEffect} from 'react';

import './styles.css';

import { Link, useLocation, useHistory } from 'react-router-dom';
// useRouteMatch, useParams

function Customer(props) {
  let [idCustomer, setIdCustomer] = useState('');
  const [stepSelected, setStepSelected] = useState(0);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {

    if(location.pathname.split('/')[3]){
      setIdCustomer(location.pathname.split('/')[3])
    }else{
      setIdCustomer('');
      if(location.pathname.split('/')[2]){
        history.push('/CustomerRegister');
      }
    }

    if(location.pathname.includes('/CustomerRegister')){
      setStepSelected(0)
    }
    if(location.pathname.includes('/CustomerRegister/stepContact')){
      setStepSelected(1)
    }
    if(location.pathname.includes('/CustomerRegister/stepRegister')){
      setStepSelected(2)
    }
    if(location.pathname.includes('/CustomerRegister/stepSales')){
      setStepSelected(3)
    }
  }, [history, location]);

  return(
    <div id='customerContainer'>
      <div id='stepsContainer'>
        <Link 
          id={stepSelected===3?'stepSelected':''}
          className={`stepRegister ${stepSelected>3?'stepRegisterDone':''}`} 
          to={
            stepSelected>3?
            '/CustomerRegister/stepSales/'+(idCustomer?idCustomer:''):
            '#'
          }
        >
          <div className={stepSelected===3?'stepCount':'hideStep'}>
            <label>4</label>
          </div>
          <label>Venda</label>
        </Link>
        <Link 
          id={stepSelected===2?'stepSelected':''}
          className={`stepRegister ${stepSelected>2?'stepRegisterDone':''}`} 
          to={
            stepSelected>2?
            '/CustomerRegister/stepRegister/'+(idCustomer?idCustomer:''):
            '#'
          }
        >
          <div className={stepSelected===2?'stepCount':'hideStep'}>
            <label>3</label>
          </div>
          <label>Cadastro</label>
        </Link>
        <Link 
          id={stepSelected===1?'stepSelected':''} 
          className={`stepRegister ${stepSelected>1?'stepRegisterDone':''}`} 
          to={
            stepSelected>1?
            '/CustomerRegister/stepContact/'+(idCustomer?idCustomer:''):
            '#'
          }
        >
          <div className={stepSelected===1?'stepCount':'hideStep'}>
            <label>2</label>
          </div>
          <label>Contato</label>
        </Link>
        <Link 
          id={stepSelected===0?'stepSelected':''} 
          className={`stepRegister stepFirstRegister ${stepSelected>0?'stepRegisterDone':''}`} 
          to={idCustomer==='undefined'?('/CustomerRegister'):('/CustomerRegister/stepVisit/'+idCustomer)} >
          <div className={stepSelected===0?'stepCount':'hideStep'}>
            <label>1</label>
          </div>
          <label>Visita</label>
        </Link>
      </div>
      <div id='customerMainContainer'>
        {props.children}
      </div>
    </div>
  )
}

export default Customer;