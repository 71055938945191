import styled from 'styled-components'

export const Container = styled.div`
  font-family: 'Roboto';
  display:flex;
  flex-direction: row;
  width: 100%;
  justify-content:space-between;
  border-radius: 15px;
  box-shadow: 5px 5px 15px 5px rgb(0 0 0 / 15%);
  padding: 10px;
  margin-bottom: 20px;
`


export const InfoUser = styled.div`
    
    margin-right: 17px;
    font-size: 0.7rem;
    padding: 7px;
    max-width:22%;
    text-transform: capitalize;

    > div > span:nth-child(1) {
        font-size: 1rem;
        font-style: normal;
        font-weight: 700;
        color: #000;         
    }

    > div > span:nth-child(2) {
        font-size: 0.8rem;
        font-style: normal;
        font-weight: 700;
        margin-bottom: 5px;    
        color: #EE5928;
    }

`

export const InfoUserContent = styled.div`
   
   > div {
        display: flex;
        flex-direction: row;
        margin-bottom: 2px;
        color: #888888;
        text-transform: capitalize;

        > span:first-child {
            margin-right: 5px;            
        }

        > span:last-child {
            font-style: normal;
            font-weight: 700;        
            color: #666666;
        }
   }
   

`

export const Info = styled.div`
    width: 50%;
    border-radius: 15px;
    box-shadow: 0px 1.5px 10px rgba(0, 0, 0, 0.2);
    margin-right: 17px;
    padding: 10px;
    margin: 5px;

    @media(max-width: 1440px) {
        width: 30%;
    }
`
export const InfoContent = styled.div`
    display:flex;
    flex-direction: row;
    justify-content: space-evenly;    
    padding: 5px;

    span {
        margin-bottom: 4px;
    }

    > div:first-child {
        width: 85px;

        > span {
            font-style: normal;
            font-weight: 700;
            font-size: 16px;

        }   

        > span:last-child {
            margin-top: 4px;
            font-size: 35px;
        }
    }

    > div:nth-child(2) {
        margin-left: 7px;
        margin-right: 7px;
    }

    > div:nth-child(2), > div:nth-child(3) {  
        width: 45%;   
        font-size: 0.8rem;
        display:flex;
        flex-direction: row;
        justify-content: space-evenly;
        color: #AAAAAA;
        font-style: normal;
        font-weight: 700;
        padding: 5px;

        > div:last-child {
            color: #666666;
        }
    }

    @media(max-width: 1440px) {
        > div:nth-child(2), > div:nth-child(3) {
            font-size: 0.6rem;
            
        }

        > div:nth-child(3) {
            > div {
                width: 72px;;
            }
        }
    }
`


