/* eslint-disable no-alert, no-console */

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Routes from './routes';
import * as serviceWorker from './serviceWorker';
import StoreProvider from './context/Provider'
import 'react-toastify/dist/ReactToastify.css';
import '@brainhubeu/react-carousel/lib/style.css';
import 'react-multi-carousel/lib/styles.css';
import "@pathofdev/react-tag-input/build/index.css";

import {MyToast} from './components';

ReactDOM.render(
  <React.StrictMode>
    <StoreProvider>
      <Routes />
      <MyToast />  
    </StoreProvider>
  </React.StrictMode>,
  document.getElementById('root')

);

serviceWorker.unregister();
