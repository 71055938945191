import React, { useEffect, useState, useContext,useCallback } from 'react';
import Header from '../../components/Header';
import StoreContext from '../../context/Context';
import ModalForm from '../../components/ModalForm';
import * as datefns from 'date-fns';
import pt from 'date-fns/locale/pt-BR';
import swal from 'sweetalert';
import api from "../../services/api";
import { useHistory, useLocation } from 'react-router-dom';

import './style.css';

import * as S from './styled'

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function ScheduleCreate() {
    const [customersPage, setCustomersPage] = useState(1)

    const [users, setUsers] = useState([])
    const [usersState, setUsersState] = useState('Selecione um usuário')
    const [customers, setCustomers] = useState([])
    const [customersState, setCustomersState] = useState('Selecione o cliente')
    const [phaseID, setPhaseID] = useState()
    const [scheduleStatus, setScheduleStatus] = useState()
    const [sendNotificationApp, setSendNotificationApp] = useState(false)
    const [tags, setTags] = useState([])
    const [addTags, setAddTags] = useState([])
    // const [sendNotificationEmail, setSendNotificationEmail] = useState(false)
    
    const [events, setEvents] = useState([])
    const [date, setDate] = useState(0)
    const [hour, setHour] = useState()
    const [obs, setObs] = useState('')
    const [inputValue, setInputValue] = useState('')

    const history = useHistory()
    
    let cusTomerChoice = ''

    let query = useQuery();
     
    const [modal, setModal] = useState(false)

    const { userProjectStore } = useContext(StoreContext);

    useEffect(() => {
        setTimeout(() => {
            api.get("/schedulesUsers", { 
                    params: { project: userProjectStore.id } 
                })
                .then(res => { setUsers(res.data) })
        }, 500)

    }, [userProjectStore.id])

    const handleSearchEvents = useCallback(async () => {
        if (date == 0) {
            setDate(datefns.format(Date.now(), 'yyyy-MM-dd', { locale: pt }))
        }

        if (date != 0 && usersState !== 'Selecione um usuário') {
            const getScheduler = await api.get("/scheduleFilter", {
                params: {
                    project: userProjectStore.id,
                    usuario: usersState,
                    date: date

                }
            })

            setEvents(getScheduler.data)
        }
    }, [date, usersState, userProjectStore.id])

    useEffect(() => {

        handleSearchEvents()

        if (Date.parse(date) < Date.now() + (86400000 * 7)) {
            setScheduleStatus(3)
            //Negociação quente
        }
        if (Date.parse(date) > Date.now() + (86400000 * 8) && Date.parse(date) < Date.now() + (86400000 * 15)) {
            setScheduleStatus(2)
            //Negociação morna
        }
        if (Date.parse(date) > Date.now() + (86400000 * 16)) {
            setScheduleStatus(1)
            //Negociação fria
        }
        //  Date.parse(date)

    }, [usersState, date, handleSearchEvents])

    useEffect(() => {
        const getPhaseId = customers.find(e => e.id === customersState)
        if (getPhaseId) {
            setPhaseID(getPhaseId.phase.id)
        }
    }, [customers, customersState])

    const handleSeekPhaseId = () => {
        const getPhaseId = customers.find(e => e.id === cusTomerChoice)

        if (getPhaseId) {
            setPhaseID(getPhaseId.phase.id)

            setCustomers([])
        }

        setCustomers([])
    }  

    const handleChangeUser = (e) => {
        return setUsersState(e)
    }  

    const handleSetDate = (e) => {
        return setDate(e)
    }

    const handleSetHour = (e) => {
        return setHour(e)
    }

    const handleSetObs = (e) => {
        return setObs(e)
    }
    
    const handleCreateSchedule = async (e) => {

        if (!userProjectStore.id) {
            swal(`É necessario estar em algum projeto`, {
                icon: "info",
                button: "Fechar"
            });

            return;
        }

        if (!usersState || usersState === 'Selecione um usuário' ) {
            swal(`É preciso escolher um usuário`, {
                icon: "info",
                button: "Fechar"
            });

            return;
        }

        if (!customersState || customersState === 'Selecione o cliente') {
            swal(`É preciso escolher um cliente`, {
                icon: "info",
                button: "Fechar"
            });

            return;
        }

        if (!phaseID) {
            swal(`Faltando informações para cadastro`, {
                icon: "info",
                button: "Fechar"
            });
            
            return;
        }
        
        if (!obs) {
            swal(`Observações são obrigatórias`, {
                icon: "info",
                button: "Fechar"
            });

            return;
        }
        
        if (!date || date === 0) {
            swal(`É preciso escolher uma Data`, {
                icon: "info",
                button: "Fechar"
            });

            return;
        }    
        
        if (!hour) {
            swal(`É preciso escolher uma Hora`, {
                icon: "info",
                button: "Fechar"
            });

            return;
        }
           
        
        await api.post("/schedules", {
            id_customer: customersState,
            id_user: usersState,
            date: `${date} ${hour}`,
            id_customer_phase: phaseID,
            id_schedule_status: scheduleStatus,
            return_reason: obs,
            id_project: userProjectStore.id,
            send_notification_app: sendNotificationApp,
            tags: addTags
            // send_notification_email: sendNotificationEmail,

        }).then(_ => {
            
            swal(`Seu agendamento foi salvo com sucesso`, {
                icon: "success",
                button: "Fechar"
            });
            
            history.push('/Schedule')
        })
    }

    const handleCloseModal = () => {
        setModal(false)        
    }

    const handleSeach = async (e) => {
        setInputValue(e)

        if (e == '') {
            return setCustomers([])
        }

        const res = await api.get("/customers/search", { 
            params: {
                id_project: userProjectStore.id,
                page: customersPage,
                search: e
            }
        })

        if (res.data.lastPage < customersPage) {
            setCustomersPage(1)
        }
        
        setCustomers(res.data.data)
    }

    const handleChoiseCustomer = async (e) => {
        if (e) {
            const getCustomer = customers.find(el => el.id === e)
            setInputValue(getCustomer.fantasy_name)
            setCustomersState(e)
            cusTomerChoice = e
        }

        return handleSeekPhaseId()
    }

    useEffect(() => {
        let increment_id = query.get('increment_id') ?? null

        if (increment_id) {
            handleSeach(increment_id)
            history.replace({ search: '' })
        }
    },[])

    const getScheduleTags = async () => {
        try {
            const schedule_tags = (await api.post(`/tag?id_project=` + userProjectStore.id, { id_tag_type: 2 })).data
           
            if (schedule_tags.length > 0) {
              setTags(schedule_tags.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())))
            }

        } catch {
            setTags([])
        }
    }

    useEffect(() => {
        getScheduleTags()
    },[])


    const addOrRemoveTag = (tag_name) => {
        if (addTags.includes(tag_name)) {
            setAddTags(addTags.filter(tag => tag !== tag_name))   
        } else {
            const tags = [...addTags, tag_name]

            setAddTags(tags)
        }
    }

    return (
        <>
            <Header
                title="Novo Agendamento"
            />

            <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', width: '100%', height: '100%' }}>
                <div className="ModalBoxShadowCreate" style={{ overflow: 'auto' }}>
                <div style={{width: '100%', padding: '10px 0'}}>
                    <h3 style={{ color: '#ee5928' }}>Agendamento</h3>
                    </div>
                    <div>
                        <div style={{ color: 'gray', fontSize: '14px', marginTop: '8px' }}>
                            <p >Local: </p>
                            <div style={{ marginTop: '-12px' }}>
                                <input className="inputRounded" style={{ width: '90%' }} type="text" value={inputValue} placeHolder="Buscar..." onChange={(e) => handleSeach(e.target.value)}></input>
                                <div>
                                    {
                                        customers.length > 0 ?
                                        <div className='inputBox'>
                                            {customers.map((e) => (
                                                <button key={e.id} value={e.id} onClick={(e) => handleChoiseCustomer(e.target.value)} style={{ color: 'gray', cursor: 'pointer' }}>{e.fantasy_name}</button>
                                            )
                                            )}
                                        </div>
                                    : null
                                    }
                                </div>                               
                            </div>
                        </div>

                        <div style={{ color: 'gray', fontSize: '14px', marginTop: '8px' }}>
                            <p >Para: </p>
                            <div style={{ marginTop: '-12px' }}>
                                <select 
                                    onChange={e => handleChangeUser(e.target.value)} 
                                    value={usersState} 
                                    className="inputRounded" 
                                    style={{ color: 'gray', width: '90%' }}  >
                                    <option value="0" style={{ color: 'gray' }}>Selecione um usuário</option>
                                    {
                                        users.map((e) => (
                                            <option key={e.id_user} value={e.id_user} style={{ color: 'gray' }}>{e.name}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>

                        

                        <div style={{ color: 'gray', fontSize: '14px', marginTop: '8px' }}>
                            <p>Selecione as Tags: </p>    
                                {
                                    tags.length > 0 ? 
                                    <div className='container-tags'>
                                        <div className='content-tags'>  
                                            {
                                                tags.map(tag => (
                                                    <S.Tag 
                                                        key={`${tag.name}`}
                                                        onClick={() => addOrRemoveTag(tag.name)} 
                                                        presentTag={addTags.some(t => t === tag.name)}
                                                    >
                                                        #{tag.name}
                                                    </S.Tag>

                                                ))
                                            }
                                        </div>
                                    </div>
                                    : 
                                    <span 
                                        style={{ 
                                            fontSize: '12px', 
                                            fontWeight: 'bold', 
                                            color: '#999', 
                                            margin: '-6px 0 0 10px'
                                        }}
                                    >
                                        Nenhuma tag cadastrada
                                    </span>
                                }
                            </div>                   
                  

                        <div style={{ color: 'gray', fontSize: '14px', marginTop: '8px' }}>
                            <p >Observações: </p>
                            <div style={{ marginTop: '-12px', width: '90%' }}>
                                <textarea 
                                    className="inputRounded" 
                                    onChange={e => handleSetObs(e.target.value)}
                                    style={{ height: '120px' }}
                                >
                                </textarea>
                            </div>
                        </div>

                        <div style={{ display: 'flex', flexFlow: 'row wrap', width: '90%', marginTop: '8px' }}>
                            <div style={{ color: 'gray', fontSize: '14px', width: '49%', marginRight: '2px' }}>
                                <p style={{ margin: '8px 0 0 0' }}>Data do Agendamento: </p>
                                <div style={{ width: '18vw'}}>
                                    <input className="inputRounded" type="date" onChange={(e) => handleSetDate(e.target.value)} />
                                </div>
                            </div>

                            <div style={{ color: 'gray', fontSize: '14px', width: '50%' }}>
                                <p style={{ margin: '8px 0 0 12px' }}>Horario do Agendamento: </p>
                                <div style={{ marginLeft: '10px', width: '18vw' }}>
                                    <input className="inputRounded" 
                                        onChange={e => handleSetHour(e.target.value)} 
                                        type="time"
                                    />
                                </div>
                            </div>
                        </div> 

                        <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start',  marginTop: '15px', }}>
                            <div style={{ 
                                    display: 'flex', 
                                    justifyContent: 'center', 
                                    alignItems: 'center', 
                                    flexDirection: 'row', 
                                    fontSize: '14px', 
                                    color: 'gray',
                                    marginLeft: '3px'
                                }}>
                                <input 
                                    type="checkbox"
                                    onClick={() =>                     
                                        setSendNotificationApp(!sendNotificationApp)            
                                    }
                                    checked={sendNotificationApp}
                                >

                                </input>
                                <p>Enviar notificação no aplicativo</p>
                            </div>
                            {/* <div style={{ marginTop: '-10px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row', fontSize: '14px', color: 'gray' }}>
                                <input 
                                    type="checkbox"
                                    onClick={() =>                     
                                       setSendNotificationEmail(!sendNotificationEmail)                     
                                    }
                                    checked={sendNotificationEmail}
                                >                                    
                                </input>
                                <p> Enviar notificação por email</p>
                            </div> */}
                        </div>
                        <div style={{ marginTop: '4vh', marginBottom: '2vh' }}>
                            <button 
                                style={{ height: '2vh', width: '8vw' }} 
                                className="btnStandart" 
                                onClick={() => handleCreateSchedule()}
                            >
                                Salvar
                            </button>
                        </div>

                    </div>

                </div>
                <div className="ModalBoxShadowCreate">
                <div style={{width: '100%', padding: '10px 0 20px'}}>
                    <h3 style={{ color: '#ee5928' }}>Agenda</h3>
                </div>
                    {usersState == 'Selecione Um Usuário' ? 
                        <p style={{ marginLeft: '-2px', marginTop: '-10px', color: 'gray' }}>
                            Selecione um usuario
                        </p> : 
                            events.length <= 0 ? 
                                <p style={{ marginLeft: '-2px', marginTop: '-10px', color: 'gray', overflow: 'auto' }}>
                                    Sem Agendamentos 
                                </p> 
                                :
                                <div className="listBoxShadow">
                                    <div style={{marginBottom: '7px'}}>
                                        <h3 style={{ color: "#AAA" }}>Agendamentos</h3>
                                    </div>
                                    <div style={{marginBottom: '5px', marginTop: '3px'}}>
                                        <h4 style={{ fontSize: '14px'}}>Data dos Agendamentos: </h4>
                                    </div>
                                    <div style={{marginBottom: '5px'}}>
                                        <h5 style={{ color: 'gray' }}>
                                            {datefns.format(datefns.parseISO(date), 'dd/MM/yyyy', { locale: pt })}
                                        </h5>
                                    </div>
                                    {
                                        events
                                        .map((e, i) => {
                                            return (
                                                <div key={i}>
                                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                        <p style={{ fontSize: '12px', fontWeight: 'bold', letterSpacing: '0px' }}>{datefns.format(datefns.addHours(new Date(e.date), 3), 'HH:mm', { locale: pt })}</p>
                                                        <div style={{ marginBottom: '2vh', backgroundColor: '#ffddd2', width: '96%', borderRadius: '5px', marginRight: '10px' }}>
                                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                                {e.id_schedule_status === 1 ? (
                                                                    <div style={{ height: '10px', width: '10px', backgroundColor: '#28affb', borderRadius: '50%', marginLeft: '4px' }}></div>
                                                                ) : e.id_schedule_status === 1 ? (
                                                                    <div style={{ height: '10px', width: '10px', backgroundColor: '#ffae1a', borderRadius: '50%', marginLeft: '4px' }}></div>

                                                                ) : <div style={{ height: '10px', width: '10px', backgroundColor: '#ff2120', borderRadius: '50%', marginLeft: '4px' }}></div>}
                                                                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                                                    <p style={{ fontSize: '13.333px', fontWeight: 'bold', color: '#ee5928' }}>{e.to}</p>
                                                                    <p style={{ fontSize: '13.333px', fontWeight: 'bold', color: 'gray', marginTop: '-10px' }}>{`${e.address}, ${e.address_number} - ${e.address_district}`}</p>
                                                                </div>

                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                </div>
                        }
                </div>
                {/* <div className="ModalBoxShadowCreate">
                    <div style={{width: '100%', padding: '10px 0'}}>
                        <h3 style={{ color: '#ee5928' }}>Local</h3>
                    </div>
                </div> */}
            </div>
            <ModalForm title="Agendamento Salvo com sucesso" customButton={() =>
                <button className="btnStandartModal" onClick={(e) => { handleCloseModal() }}><h3>Ok</h3></button>
            } close={() => handleCloseModal()} showAlert={modal}>
                <h3 style={{ color: 'gray' }}>Seu agendamento foi salvo com sucesso</h3>
            </ModalForm>
        </>
    )
}

export default ScheduleCreate;