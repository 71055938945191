import React,{useContext} from "react";
import { useCallback } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router";
import swal from "sweetalert";
import StoreContext from "../../context/Context";
import api from "../../services/api";
import Info from "./Info";
import * as S from "./styled";
import LoaderSpin from '../../components/LoaderSpin';
const Project = ({projectName,hc}) => {
    return ( 
    <div className="project">
        <h3>{projectName}</h3>
        <div className="infoContainer">
           <div className="column">
               <Info name="HC" value={hc}/>                          
           </div>
        </div>
    </div>)
}
const normalizeValue = (value)=>{
    if(isNaN(value) || value == Infinity || !value){
        return 0
    }
    return value
}
const Visit = ({visits,sales,additional,schedules}) => {

    const additionalVisits = additional?.visits || 0;;   
    const additionalSales = additional?.sales || 0;
    const dailyVisits = (visits.day+additionalVisits) || 0;
    const weekVisits = (visits.week+additionalVisits) || 0;
    const weekSales = (sales.week+additionalSales) || 0;
    const visitSales = (weekVisits/weekSales || 0).toFixed(1);

    return ( 
    <div className="visit">
        <div className="title">
                Visita
        </div>
        <div className="infoContainer">
            <div className="column">
                <Info name="Hoje"  value={dailyVisits} size="big"/>                     
                <Info name="Semana"  value={visits.week+additionalVisits} size="big"/>     
                <Info name="Mês"  value={visits.month+additionalVisits} size="big"/>     
            </div>
            <div className="column">
                <Info name="Agend. Criado Hoje" value={schedules || 0} size="big"/>     
                <Info name="Visitas/Vendas" value={visitSales} size="big"/>     
                <Info name="Zerados Hoje" value={"--"} size="big"/>     
                <Info name="Zerados Ontem" value={"--"} size="big"/>     
            </div>
        </div>
    </div>
)
}
const Sales = ({sales,additional,hc}) => {
    const additionalSales = additional?.sales
    const daySales = (sales.day.total+additionalSales) || 0
    const weekSales = (sales.week.total+additionalSales) || 0
    return (
        <div className="sales">
        <div className="title">
                Vendas

        </div>
        <div className="productsContainer">
                <Info  width='auto' name="Novos Clientes" value={sales.day.total+(additionalSales?.new || 0)} size="small"/>
                    {
                        sales.day.categories.map((category,index) => {
                            return (
                                <Info width='auto' name={category.category_name} value={category.count+(additionalSales?(additionalSales[category.category_id] || 0):0)} size="small"/>   
                            )
                        })
                    }
            </div>
        <div className="infoContainer">
           
            <div className="column">
             
                <Info name="Semana" value={sales.week.total+(additionalSales?.new || 0)} size="big"/>     
                <Info name="Mês" value={sales.month.total+(additionalSales?.new || 0)} size="big"/>     
            </div>
            <div className="column">
                <Info name="Zerados Hoje" value={"--"} size="big"/>     
                <Info name="Zerados Ontem" value={"--"} size="big"/>    
                <Info name="Prod. Hoje" value={Math.round((daySales/hc)*100)/100} size="big"/>    
                <Info name="Prod. Semana" value={Math.round((weekSales/hc)*100)/100}  size="big"/>    
            </div>
            <div className="column">
                <Info name="PF" value={"--"} size="big"/>    
                <Info name="PJ" value={"--"} size="big"/>    
                <Info name="Pend. Apro." value={"--"} size="big"/>    
                <Info name="Pend. Acom." value={"--"} size="big"/>    
            </div>
        </div>
    </div>
    )
}
const Highs = ({promotersHighs,workDays}) => {
    return (
    <div className="highs" style={{
        position: 'relative',
    }} >
        <div className="title" style={{zIndex:5,marginBottom:'13px'}}>
                Altas do Mês
        </div>
        {promotersHighs?.map(e =>
                 <PromoterHighlight>
                 <div>
                   <span className="name">
                        {e?.promoterName}
                   </span>
                   <span className="Infos">
                        {e?.teamName} | {e?.supervisor}
                   </span>
                </div>
             </PromoterHighlight>
            )}

    <S.HighsAndLows>
        <div className="colorColumn" >
             <label>
                VISITAS
             </label>
             {promotersHighs?.map(e =>
             <span className="value">
                   {e.visits.month}
              </span>
             )}
        </div>
        <div className="colorColumn">
             <label>
                VENDAS
             </label>
             {promotersHighs?.map(e =>
             <span className="value">
                   {e.sales.month.total}
              </span>
             )}

        </div>
        <div className="colorColumn">
            <label>
                PROD
             </label>
             {promotersHighs?.map(e =>
             <span className="value">
                    {normalizeValue(e.sales.month.total/workDays).toFixed(1)}
              </span>
             )}
        </div>
    </S.HighsAndLows>
    </div>
    )
}
const PromoterHighlight = ({children}) => {
    return (
        <S.PromoterHighlight>
            {children}
        </S.PromoterHighlight>

    )
}
const Lows = ({promotersLows,workDays}) => {
    return (
        <div className="lows" style={{
            position: 'relative',
        }} >
            <div className="title" style={{zIndex:5,marginBottom:'13px'}}>
                    Baixas do Mês
            </div>
            {promotersLows?.map(e =>
                 <PromoterHighlight>

                 <div>
                   <span className="name">
                        {e?.promoterName}
                   </span>
                   <span className="Infos">
                   {e?.teamName} | {e?.supervisor}
                   </span>
                </div>
             </PromoterHighlight>
            )}
        <S.HighsAndLows color="lows">
            <div className="colorColumn" >
                 <label>
                    VISITAS
                 </label>
                 {promotersLows?.map(e =>
             <span className="value">
                   {e.visits.month}
              </span>
             )}
            </div>
            <div className="colorColumn">
                  <label>
                      VENDAS
                  </label>
                  {promotersLows?.map(e =>
             <span className="value">
                   {e.sales.month.total}
              </span>
             )}
            </div>
            <div className="colorColumn">
                 <label>
                    PROD
                 </label>
                 {promotersLows?.map(e =>
                    <span className="value">
                           {normalizeValue(e.sales.month.total/workDays).toFixed(1)}
                     </span>
                 )}
            </div>
        </S.HighsAndLows>
        </div>
    )
}

const ProjectCard = ({ projectName }) => {
    const [projects, setProjects] = useState([]);
    const [additional, setAdditional] = useState({});
    const [loading,setLoading] = useState(false);
    const history = useHistory();
    useEffect(() => {
        setLoading(true)
        api.get('/dashboard/project').then(response => {
            setProjects(response.data);
             setLoading(false)
        }
        ).catch(()=>{
            setLoading(false)
            swal("Não foi possível exibir o painel","","error")
        });

    },[])
    const {socket} = useContext(StoreContext);

    const addVisit = useCallback((data) => {
        setAdditional(prev => {
            const newAdditional = {...prev};
            if(!newAdditional[data.id_project]){
                newAdditional[data.id_project] = {
                    visits: 0,
                    sales: {}
                }
            } 
            newAdditional[data.id_project].visits++;
            return newAdditional;

        });

    },[])
    const addSale = useCallback((data) => {
        setAdditional(prev => {
            const newAdditional = {...prev};
            if(!newAdditional[data.id_project]){
                newAdditional[data.id_project] = {
                    visits: 0,
                    sales:{}
                }
            } 
    
            newAdditional[data.id_project].sales[data.sku_category] += 1;
            if(data.isNew){
                newAdditional[data.id_project].sales.new++;
            }
            return newAdditional;

        });

    },[])
     
    /*
        Socket system WIP
    useEffect(()=>{
 
        socket.on("dash-visit", addVisit)
        socket.on("dash-sale", addSale)
      return () => {
        socket.off("dash-sale");
        socket.off("dash-visit");
      }
    },[addSale, addVisit, projects, socket])
    */
    if (loading){
     return <LoaderSpin enable={true}/>
    }
    return projects.map(project => (
        <S.ProjectCardContainer  onClick={()=>{
            history.push('/Dashboard',project)
        }}>
            <Project projectName={project.projectName} hc={project.hc} />
            <Visit id_project={project.projectId}  schedules={project.schedules} visits={project.visits} sales={project.sales} additional={additional[project.projectId]}/>
            <Sales id_project={project.projectId} hc={project.hc} sales={project.sales} additional={additional[project.projectId]}/>
            <Highs  workDays={project?.workDays} promotersHighs={project?.promotersLows}/>
            <Lows   workDays={project?.workDays} promotersLows={project?.promotersHighs}/>
        </S.ProjectCardContainer>
    ))
}

const ProjectDash = () => {
 
    return (

        <S.Container>

            <ProjectCard />
            
        </S.Container>
    );
}

export default ProjectDash;