import axios from 'axios';

const api = axios.create({
  /*
  #f00
  NÃO ALTERAR API POR AQUI, USAR COMANDO A SEGUIR
  YARN START (npm start) - rodar na api LOCAL
  YARN DEV   (npm run dev) - rodar na api DEV
  YARN PROD  (npm run prod) - rodar na api PROD
  #f00
  */
  baseURL: process.env.REACT_APP_API ?? 'https://apidev.prismapro.com.br',
  headers: {
    "Access-Control-Allow-Origin": "*"
  }
});

api.interceptors.request.use(async (config) => {
  if (
    !config.url.endsWith('/sessions') &&
    !config.url.endsWith('/refresh') &&
    !config.url.endsWith('/forgot') &&
    !config.url.includes('/confirm_email')
  ) {
    const token = localStorage.getItem('token') || sessionStorage.getItem('token')
    const refresh = localStorage.getItem('refresh') || sessionStorage.getItem('refresh')

    if (token === "undefined") {
      localStorage.clear()
      sessionStorage.clear() 
      return
    }
    
    if (token) {
      config.headers.common['Authorization'] = `Bearer ${token}`
    } else {
      if (!refresh) {
        localStorage.clear()
        sessionStorage.clear() 

        return
      } 

      await reAuthenticateUser()
    }
  }

  return config;
}, (error) => {
 
  return Promise.reject(error);
})

let errorCount = 0;

api.interceptors.response.use(async (response) => {
  return response;
}, async (error) => {
  const requestConfig = error.config;

  const message_authenticate_error = error.response?.data?.error 
  
  if (
    error.response?.status === 401 &&
    (
      !error.config.url.endsWith('/sessions') &&
      !error.config.url.endsWith('/refresh') &&
      !error.config.url.endsWith('/forgot')
    ) && errorCount < 1 && !requestConfig._retry
  ) {
    errorCount++;
    requestConfig._retry = true;

    if (
      message_authenticate_error === "E_JWT_TOKEN_EXPIRED: The jwt token has been expired. Generate a new one to continue" || 
      message_authenticate_error === 'E_INVALID_JWT_TOKEN: jwt must be provided' ||
      message_authenticate_error === 'E_INVALID_JWT_TOKEN: jwt malformed'
    ) {
      const token = await reAuthenticateUser()      

      requestConfig.headers.Authorization = `Bearer ${token}`
    }


    return api(requestConfig);
  }

  errorCount = 0;
  
  return Promise.reject(error);
});

async function reAuthenticateUser() {
  const refresh = localStorage.getItem('refresh');

  let newtoken = null
  
  if (refresh) {
    await api.post('/refresh', { refresh_token: refresh })
      .then(response => {
        if (response.data) {
          const token = response.data.token;
          const refresh_token = response.data.refreshToken;          
          
          localStorage.setItem('token', token)
          localStorage.setItem('refresh', refresh_token)

          api.defaults.headers.common['Authorization'] = `Bearer ${token}`
          
          newtoken = token
        }
      }
      ).catch(err => {
        localStorage.clear()
        sessionStorage.clear()
      });
  } else {
    localStorage.clear()
    sessionStorage.clear()
  }

  return newtoken
}

export default api;
