import { Badge } from '@material-ui/core';
import { Notifications } from '@material-ui/icons'
import CloseIcon from '@material-ui/icons/Close';
import MenuIcon from '@material-ui/icons/Menu';
/* eslint-disable no-alert, no-console */

import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from "react-router-dom";
import { toast } from 'react-toastify'

import iconSchedule from '../../assets/icons/icon_agenda.svg';
import iconClients from '../../assets/icons/icon_clientes_vendas.svg';
import iconForm from '../../assets/icons/icon_form_correct.svg'
import iconHome from '../../assets/icons/icon_home.svg';
import iconProducts from '../../assets/icons/icon_produtos.svg';
import iconProjects from '../../assets/icons/icon_projetos.svg';
import iconReports from '../../assets/icons/icon_reports.svg';
import iconsSettings from '../../assets/icons/icon_settings.svg';
import iconUsers from '../../assets/icons/icon_usuarios.svg';
import iconUser from '../../assets/icons/iconUser.png'
import iconPainel from '../../assets/icons/painel_icon.svg';
import iconMaps from '../../assets/icons/icon_regioes.svg';
import StoreContext, { ScrollContext } from '../../context/Context';
import api from '../../services/api';
import { permissionCheck } from '../../utils/permission_role';
import VirtualizedList from '../ListItem';

import './styles.css';

const pagesList = [
  'Dashboard',
  'Users',
  'Profile',
  'UsersRegister',
  'Customer',
  'CustomerInfo',
  'CustomerRegister',
  'Companies',
  'CompaniesRegister',
  'Products',
  'ProductsTransfer',
  'ProductsRegister',
  'Schedule',
  'Projects',
  'ProjectsRegister',
  'Teams',
  'Groups',
  'Permission',
  'PermissionsRegister',
  'Metrics',
  'Fields',
  'Forms',
  'Maps',
]

let timer;

function NavigationMenu(props) {
  const { 
      setUserInfoStore,
      setUserProjectStore, 
      userInfoStore, 
      setSavedFilters, 
      userProjectStore,
      socket 
  } = useContext(StoreContext);

  const { setOnScroll } = useContext(ScrollContext);

  const [Navbar, setNavbar] = useState([]);
  const [tokenState, setTokenState] = useState();
  const [menuOpen, setMenuOpen] = useState(false);
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [showNotification, setShowNotication] = useState(false);
  const [notifications, setNotifications] = useState({});
  const [newNotifications, setNewNotifications] = useState(true);
  const [totalNotifications, setTotalNotifications] = useState('');
  const [pageNotification, setPageNotification] = useState(1);
  const [lastPageNotification, setLastPageNotification] = useState(1);

  const token = localStorage.getItem('token') || sessionStorage.getItem('token');
  const history = useHistory();
  const path = useLocation();
  
  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useEffect(() => {
    if (!token) {
      history.push('/login');
    }

    setTokenState(token)

    try {
      if (path.pathname) {
        const pagePaths = path.pathname?.split('/');
        if (pagePaths.length > 1) {
          if (!pagesList.includes(pagePaths[1]) && pagePaths[1] !== '') {
            // history.push('/404');
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
    try {
      const user = JSON.parse(localStorage.getItem('user'));

      let project = JSON.parse(localStorage.getItem('project'));;
      // setUserInfo(user);
      if (project) {
        props.updateStates(user, project)
      } else {
        if (user?.id !== props.userInfo?.id) {
          if (user?.projects?.length > 0) {
            project = user?.projects;

            localStorage.setItem('project', JSON.stringify(project));
          }
          props.updateStates(user, project)
        }
      }
    } catch (err) {
      console.log(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, token]);

  useEffect(() => {
    setNavbar([
      { name: 'Clientes & vendas', permissionNeeded: 'clientes_vendas', img: iconClients, link: '/Customer', status: true },
      { name: 'Rotas', permissionNeeded: 'maps', img: iconMaps, link: '/Maps', status: true },
      { name: 'Painel', permissionNeeded: 'clientes_vendas', img: iconPainel, link: '/Dashboard', status: props?.userInfo?.id_users_type !== 1 },
      { name: 'Relatórios', permissionNeeded: 'reports', img: iconReports, link: '/Reports', status: true },
      { name: 'Agenda', permissionNeeded: 'agenda', img: iconSchedule, link: '/Schedule', status: true },
      { name: 'Produtos', permissionNeeded: 'produtos', img: iconProducts, link: '/Products', status: true },
      { name: 'Usuários', permissionNeeded: 'usuarios', img: iconUsers, link: '/Users', status: true },
      { name: 'Projetos', permissionNeeded: 'projetos', img: iconProjects, link: '/Projects', status: props?.userInfo?.id_users_type !== 1 },
      { name: 'Ajustes', permissionNeeded: 'campos', img: iconsSettings, link: '/Settings', status: true },
    ])
  }, [props.userInfo])

  function handleLogout() {
    const savedFiltersInStorage = sessionStorage.getItem('savedFilters') || localStorage.getItem('savedFilters')

    setUserInfoStore(null)
    setUserProjectStore(null)
    setTokenState()

    localStorage.clear();
    sessionStorage.clear();

    if (savedFiltersInStorage) {
      setSavedFilters(savedFiltersInStorage)
      localStorage.setItem('savedFilters', savedFiltersInStorage)
      sessionStorage.setItem('savedFilters', savedFiltersInStorage)
    }

    history.push("/login");
  }

  function reloadUser() {
    if (props.userInfo) {
      api.get('/users/' + props.userInfo.id)
        .then(response => {
          if (response?.data) {
            const user = response.data[0];
            let project = props.userProject;
            props.updateStates(user, project);
          }
        })
        .catch()
    }
  }

  function capitalize(word) {

    if (typeof word === 'string' && (word !== null || word !== undefined)) {
      const lower = word.toLowerCase();
      return word.charAt(0).toUpperCase() + lower.slice(1);
    } else {
      return ''
    }
  }

  function splitFullName (fullname) {
    const split_fullname = fullname.split(' ')
    const length_split_fullname = split_fullname.length

    return ` ${capitalize(fullname.split(' ')[0])} ${capitalize(fullname.split(' ')[length_split_fullname - 1])}`
  }

  function scrollDebouncer(event) {
    clearTimeout(timer)

    timer = setTimeout(() => {
      if ((event?.target.scrollTop) / (event?.target.scrollHeight - event?.target.clientHeight) > 0.8) {
        setOnScroll(event)
      }

    }, 600)
  }

  const getNotifications = useCallback(async () => {
    try {
      if (userProjectStore?.id && userInfoStore?.id) {
        const response = await api.get('/notification', 
          {
            params: { 
              id_project: userProjectStore.id, 
              id_user: userInfoStore.id
            }
          },
        )
  
        const _data = response.data
        
        setPageNotification(_data.page ?? 1)
  
        setLastPageNotification(_data.lastPage ?? 1)
  
        setNotifications(_data)
  
        setTotalNotifications(_data?.total)
      }
    } catch (error) {
      console.log(error)
    }  
  }, [userProjectStore?.id, userInfoStore?.id])

  useEffect(() => {
    setNewNotifications(true)
    setShowNotication(false)                   

    if (token)
      getNotifications()

  }, [getNotifications])

  const getNotificationsOnScroll = async () => {
    try {
      if (pageNotification < lastPageNotification) {
        const page = pageNotification + 1

        setPageNotification(page)

        const response = await api.get('/notification', 
          {
            params: { 
              id_project: userProjectStore.id, 
              id_user: userInfoStore.id,
              page
            }
          },
        )

        const _data = response.data

        const _notifications = { ...notifications }

        _notifications.data = [..._notifications.data, ..._data?.data]
        
        setNotifications(_notifications)

        setTotalNotifications(_data?.total)
      }

    } catch (error) {
      console.log(error)
    }  
  }

  const markAsVisualizedNotification = async () => {
    try {
      await api.put('/notification/visualized', 
        {
         
          id_project: userProjectStore.id, 
          id_user: userInfoStore.id          
        }
      )

      setTotalNotifications(0)
    } catch (error) {
      console.log(error)
    }
  }

  function scrollNotification(event) {  
    if ((event.target.scrollTop) / (event.target.scrollHeight - event.target.clientHeight) >= 1) {
       getNotificationsOnScroll()
    }    
  }   

  useEffect(() => {
    socket.on("notification", (data) => {   
      if (data?.id_project === userProjectStore.id) {   
        toast.info("Você tem uma nova notificação!", { 
          autoClose: 1200, 
          closeButton: true, 
          position: 'top-left',
        })
       
        getNotifications()      
  
        setNewNotifications(true)
      }
    })

    return () => socket.off("notification")
  }, [socket, userProjectStore])


  return (
    <>
      {tokenState ? <div id="mainScroll" onScroll={scrollDebouncer} className="container">
        <div className="topMenu">
          <div className="mainTab">

            <div style={{ marginLeft: '80px' }}>
              {userInfoStore && userInfoStore?.permissions && userInfoStore?.permissions.includes('home_read') &&
                <Link to={'/'} className={`homeItem ${path.pathname.includes('/Profile') ? 'selectedItem' : ''}`}>
                  <img src={iconHome} alt="home" />
                  {/* <p className="homeButtonText">Inicio</p> */}
                </Link>
              }
            </div>

            <div style={{ display: 'flex', flexDirection: 'row'}}>
              <div className="notification">
                <Badge 
                  onClick={() => {
                    setShowNotication(!showNotification)                   

                    if (newNotifications) {
                      markAsVisualizedNotification()
                      setNewNotifications(false)
                    }
                  }}
                  badgeContent={totalNotifications > 0 ? totalNotifications : 0} 
                  color="secondary"
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                    <Notifications style={{ fontSize: 30, color: '#ee5928'}}/>
                </Badge>

           
                <div 
                  onScroll={scrollNotification}
                  className={`list_notification ${showNotification ? 'open_notification' : ''}`}
                >
                    <VirtualizedList 
                      notifications={notifications}
                      setNotifications={setNotifications}
                      setShowNotication={setShowNotication} 
                    />
                </div>
               
              </div>
              <div className='userInfo'>
                <Link to={`/Profile`}>
                  <div className="userInfoContainer">
                    <label id='userInfoName'>
                      Olá, 
                      {
                        props?.userInfo?.fullname
                        ? splitFullName(props.userInfo.fullname)
                        : props?.userInfo?.username
                      }
                    </label>
                    <div className="userInfoProjectContainer">
                      {props?.userProject?.name && (                        
                          <label id='userInfoProject'>Você está em {props.userProject.name}</label>
                      )}                      
                      <button onClick={() => { handleLogout() }}>Sair</button>
                    </div>
                  </div>
                  <div id='menuItemImage'>
                    <img
                      src={props?.userInfo?.file?.uri ? props?.userInfo?.file?.uri : iconUser}
                      onError={() => {
                        reloadUser()
                      }}
                      alt="user" />
                  </div>
                </Link>                
              </div>
            </div>
          </div>

        </div>
        <div className="horizontalMenu">
          {windowSize.innerWidth > 768 ?
            <div className="leftTab">

              {userInfoStore?.permissions && Navbar.filter(e => userInfoStore.id_users_type === 6 || permissionCheck(e.permissionNeeded, userInfoStore.permissions)).map((item, index) => {
                if (item.status) {
                  return (
                    <Link
                      to={item.link}
                      key={index}
                      className={`menuItem ${index > 0 ? 'menuItemMargin' : ''} ${path.pathname.includes(item.link) ? 'selectedItem' : ''}`}
                    >
                      <img src={item.img} alt={item.name} />
                      <p>{item.name}</p>
                    </Link>
                  )
                } else {
                  return (<div key={index} />)
                }
              })}
            </div>
            :
            <div>
              <div className="openMenuButton">
                <MenuIcon  onClick={()=>{setMenuOpen(true)}}  style={{fontSize:26, color: '#ee5928'}}/>
              </div>
              <div className='sideBarMenu' style={{
                transform: menuOpen ? 'translateX(0)' : 'translateX(-100%)',
              }}>
                <div onClick={()=>{setMenuOpen(false)}} className="closeMenuButton">
                  <CloseIcon style={{fontSize:26, color: '#ee5928'}}/>
                </div>
                {userInfoStore?.permissions && Navbar.filter(e => userInfoStore.id_users_type === 6 || permissionCheck(e.permissionNeeded, userInfoStore.permissions)).map((item, index) => {
                  if (item.status) {
                    return (
                      <Link
                        to={item.link}
                        key={index}
                        onClick={()=>{setMenuOpen(false)}}
                        className={`menuItem ${index > 0 ? 'menuItemMargin' : ''} ${path.pathname.includes(item.link) ? 'selectedItem' : ''}`}
                      >
                        <img src={item.img} alt={item.name} />
                        <p>{item.name}</p>
                      </Link>
                    )
                  } else {
                    return (<div key={index} />)
                  }
                })}
              </div>
            </div>
          }
          <div className="mainTab">
            <div className="mainView">
              {props.children}
            </div>
          </div>


        </div>
      </div>
        : <h3> Carregando...</h3>

      }
    </>
  );
}

export default NavigationMenu;
