import React from 'react';
import './styles.css';

function ErrorPage(){
  return (
    <div id='soonPage'>
      <label>Error 404</label>
      <span>A página requisitada não existe, por favor verifique a URL.</span>
    </div>
  )
}

export default ErrorPage;
