import styled from 'styled-components';
import { Card } from '../../../components/Styled-Components/Card';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

export const Button = styled.button`
    border-radius:20px;
    display:flex;
    flex-direction: row;
    background-color:${({disabled}) => disabled?"#555":"#ee5928"};
    :hover{
    cursor:${({disabled}) => disabled?"not-allowed":"pointer"};
    background-color:${({disabled}) => disabled?"#555f":"#ee592855"};
    opacity:1 ;
    }
    margin-right:20px;
    padding:5px;
    color:white;
    height: 36px;
    align-items: center;
`

export const Tabs = styled(Card)`
    margin-right:10px;
    margin-bottom:10px;
    margin-top:10px;
    border-radius:10px;
    width:100% ;
    height:100%;
`;

export const InsideContainer = styled.div`
  padding:50px;
  max-width:90vw;
  overflow: auto;
`

export const HeaderSpan = styled.span`
  font-size:15px;
  font-weight:bold;
`

export const ExternalButtonDiv = styled.div` 
  display:flex;
  flex-direction:row;
  justify-content:space-between;
  padding:10px;
`

export const StyledCheckIcon = styled(CheckCircleOutlineIcon)`
  color:${props => !props?.panelconcluded ? 'grey' : 'green'};
  margin-right:10px;
`
export const DropzoneContainer = styled.div`
  border: 2px dashed #555;
  height:150px ;
`
export const DropzoneContent = styled.div`

`