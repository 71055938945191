import styled, {keyframes}  from 'styled-components'

export const RingsAnimation = keyframes` 
0%
{
    transform: rotate(0deg);
}
100%
{
    transform: rotate(360deg);
}
  
`


export const LoadersSpinner = styled.div`
  border: ${props => props.small ? "3px" : "8px"} solid white;
  border-left-color:  #EE5928;
  border-radius: 50%;
  width: ${props => props.small ? "20px" : "50px"};
  height:  ${props => props.small ? "20px" : "50px"};
  animation: ${RingsAnimation} 1.2s linear infinite;
`