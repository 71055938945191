import React from 'react';
import * as S from '../forgot-password.styles'

const SuccessSection = (props) => {

  const { goToLogin} = props;

  return (
    <>
    <S.newPasswordDiv>
      <S.newPasswordDivTitle>Alteração de senha realizada com sucesso</S.newPasswordDivTitle>
      <S.newPasswordSpan>Para realizar um novo login clique no botão abaixo:</S.newPasswordSpan>
    </S.newPasswordDiv>
    <div className="divInputandButton"> 
      <S.MyButton onClick={() => goToLogin()} type='button' centered={true}>
        Retornar para o Login
      </S.MyButton>
    </div>
  </>
  )
}

export default SuccessSection;