import React from 'react';
import Loader from 'react-loader-spinner';

export default function LoaderSpin(props){
  return (
    <>
      {props.enable?(     
        <div style={{flex:1, alignItems:'center', justifyContent:'center', maxHeight: '200px'}}>
          <Loader type={props?.type || 'Oval'} color="#EE5928" height="100" width="100" />
        </div>
      ):(<></>)}
    </>
  )
}