import styled from 'styled-components';

export const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  label{
    margin-left: 10px;
    color: var(--color-text-medium);
    font-weight: 350;
    font-size:14px;
  }
`
export const FlagsContainer = styled.div`
 margin-top: 15px;
 margin-bottom: 15px;
`
export const DateContainer = styled.div`

`
export const DatesShortcutsContainer = styled.div`
  display: flex;
  flex-direction: row;
  button:not(:first-child) {
    margin-left:10px;
  }
  button {

      background-color:transparent;
      color: #fff;
      background-color: #ee5928;
      border-radius: 8px;
      padding:0 10px;
      height:24px;
      margin-bottom:10px;
      font-weight: bold;
      text-align:center;
  }
`
export const InputContainer = styled.div`
  flex-direction: row;
  > div:last-child {
    margin-left: 10px;
  }
 p {
   color: #ee5928;
   font-weight: bold;
  margin:0 ;

  
 }
 input{
    width: 150px;
    height: 30px;
    background-color: #eee;
    border-radius: 5px;
    margin-left: 0px;

  }
`
export const Container = styled.div`
  width: 100%;
  height:auto;
  font-family: "Roboto";
  display:flex;
  flex-direction: row;
`;
