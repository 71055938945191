import React, {useState, useEffect} from 'react';
import api from '../../services/api';
import {useLocation, useHistory} from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from 'react-toastify'
import './styles.css';
import * as S from './forgot-password.styles'
// import { Spinner } from '../../components'
import Logo from '../../utils/logoPro.svg'
import ChangePasswordSection from './ChangePasswordSection'
import RequestPasswordSection from './RequestPasswordSection'
import SuccessSection from './SuccessSection'

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [validEmail, setvalidEmail] = useState(false)
  const [loading, setLoading] = useState(false)
  const [token, setToken] = useState(null);
  const [success, setSuccess] = useState(false);
  const [notEqualError, setNotEqualError] = useState(false)
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i

  const history = useHistory();
  const query = useQuery();
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const { register, handleSubmit, formState: { errors }, watch} = useForm();

  const watchPassword = watch('password')
  const watchConfirmPassword = watch('confirmPassword')
  
  useEffect(() => {
    if(watchPassword && watchConfirmPassword) {
      setNotEqualError(watchPassword !== watchConfirmPassword)
    } else {
      setNotEqualError(false)
    }
  },[watchPassword, watchConfirmPassword])



  useEffect(()=>{
    const getToken = query.get("token")
    if(getToken){
      setToken(getToken);
    }
  }, [query])

  const setInputEmail = value => {
    if(value) {
      setEmail(value)
      setvalidEmail(emailRegex.test(value))
    }
  }

  async function submit() {
    if(!validEmail) {
      try {
        await api.post('/forgot', {
          email: email,
        }).then((response) => {
          setSuccess(true);
        }).catch(err => {
          console.log(err)

        })
        
      } catch (err) {
        console.log(err)

      }
    }
  }

  async function handleSubmitPassword(data) {
    try {
      if (data.password.length < 8) {
        toast.error("Sua senha deve conter no mínimo 8 caracteres!")
        return
      }

      setLoading(true)
      const response = await api.put('/forgot', {
        token: token,
        password: data.password,
        password_confirmation: data.confirmPassword
      }).then((response) => {
        setLoading(false)
        if(response.data.message === "Senha alterada com sucesso."){

          setSuccess(true);
        }
        return response;
      }).catch(err => {
        setLoading(false)

      })
      
      console.log('success: ', response)
    } catch (err) {

      setLoading(false)


    }
  }

  const goToLogin = () => {
    history.push('/login')
  }
  
  return (
    <S.Background>
    <S.SideWhite/>
    <S.SideOrange/>
    <S.Container>
      <S.BoxLogo>
        <img src={Logo}  alt="Logo Prisma"/>
      </S.BoxLogo>
      <S.BoxLogin>
        <S.Login>
        {!token && !success && (
        <>
          <RequestPasswordSection
            loading={loading}
            submit={submit}
            setInputEmail={setInputEmail}
            validEmail={validEmail}
            email={email}
          />
        </>
        )}
        {token && !success && (
          <>
          <ChangePasswordSection
            handleSubmit={handleSubmit}
            errors={errors}
            register={register}
            handleSubmitPassword={handleSubmitPassword}
            notEqualError={notEqualError}
            loading={loading}
          />
          </>
        )}
        {success && (
          <>
            <SuccessSection
              goToLogin={goToLogin}
            />
          </>
        )}
        </S.Login>
      </S.BoxLogin>
    </S.Container>
  </S.Background>
  );
}

export default ForgotPassword;

