
import React, { useContext,useMemo } from 'react';
import styled from 'styled-components';
import { DashboardContext } from '..';
import { TabBarContext } from '../../../components/TabBar';

const Navigator = () => {
    const {useNav} = useContext(DashboardContext);
    const {changeSceneById} = useContext(TabBarContext);
    const {getPaths} = useNav()
    const paths = useMemo(() => getPaths(),[getPaths])
    return (
        <NavigatorContainer>
            {paths.map((path, index) => {
                return (
                    <>
                        <div key={index}>
                            <NavigatorText onClick={path.onClick} selected={paths.length - 1 === index}> {path.name}</NavigatorText>
                        </div>
                        {paths.length - 1 !== index &&
                            <NavigatorDivisor />

                        }

                    </>

                )
            })}
        </NavigatorContainer>
    )
}
const NavigatorDivisor = styled.span`
:before{
    content: ">";

}
margin:0px 6px;
color:#AAAAAA;
`
const NavigatorText = styled.span`
    cursor:${({ onClick }) => onClick ? 'pointer' : 'auto'};
    font-size: 16px;
    font-weight: 500;
    color: ${({ selected }) => selected ? '#EE5928' : '#AAAAAA'};
`
const NavigatorContainer = styled.div`
    flex-direction: row;
    display: flex;
    margin-left: 2px;
    margin-top: 20px ;
`
export default Navigator;