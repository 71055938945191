import React, { useState, useEffect, useContext, useCallback } from 'react';

import StoreContext from '../../../context/Context';
import LoaderSpin from '../../../components/LoaderSpin';
import api from '../../../services/api';
import VisitsAndSalesTable from './components/VisitsAndSalesTable';
import { Container, WithoutDataText } from './styles';

const SimplifiedControl = ({ scene, selectedId }) => {
    const { userProjectStore } = useContext(StoreContext);

    const [isLoading, setIsLoading] = useState(true);
    const [visitsAndSales, setVisitsAndSales] = useState([]);

    const getVisitAndSalesMetrics = useCallback(async () => {
        try {
            if (userProjectStore.id) {
                setIsLoading(true)

                const response = await api.get('/new/dashboard', {
                    params:{
                        id_project: userProjectStore.id,
                    }
                })

                setVisitsAndSales(response.data);
                setIsLoading(false);
            }
        } catch {
            setIsLoading(false);
        }
    }, [userProjectStore]);

    useEffect(() => {
        if (scene.id === selectedId) {
            getVisitAndSalesMetrics();
        };
    },[scene.id, selectedId, getVisitAndSalesMetrics])

    if (isLoading) {
        return (<LoaderSpin enable={isLoading} />)
    }

    if (visitsAndSales.length === 0) {
        return (<WithoutDataText>Não foi encontrado nenhuma informação para exibir.</WithoutDataText>)
    }

    return (
        <Container>
            <VisitsAndSalesTable
                visitsAndSales={visitsAndSales}
                projectName={userProjectStore.name}
            />
        </Container>
    )
}

export default SimplifiedControl;