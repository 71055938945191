import React from 'react';
import styled from 'styled-components';
import DefaultImage from './defaultImage.png';
const VisitCard = ({main,secondary}) => {
    const normalizeValue = (value)=>{
        if(isNaN(value) || value == Infinity || !value){
            return 0
        }
        return value
    }
    return ( 
        <Container>
            <MainInfo>
                <h4>{main?.key}</h4>
                <h1>{main?.value}</h1>
            </MainInfo>
            <SecondaryInfo>
                {
                    secondary?.map(e=>{
                        return (
                        <div>
                            <label>{e.key}</label>
                            <span>{normalizeValue(e.value)}</span>
                        </div>
                        )
                    })
                }
            </SecondaryInfo>
            <ImageContainer>
                <img alt="mapimage" src={DefaultImage}/>
            </ImageContainer>
        </Container>

     );
}
 
const ImageContainer = styled.div`
    grid-area: image;
    display:flex;
    flex:1;
    background-color:#CCC;
    border-radius:15px;
    overflow:hidden;
    box-shadow: 0px 1.5px 5px rgba(0, 0, 0, 0.2);
    img{
        width:100%;
        height:100%;
        object-fit: cover;
    }
`
const SecondaryInfo = styled.div`
        div{
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            label{
                margin-right:5px;
                color:#AAAAAA;
                font-weight:bold;
                font-size:14px;
            }
            span{
                color:#666;
                font-weight:bold;
                font-size:14px;
            }
        }
        grid-area: secondary;

`
const MainInfo = styled.div`
        h4,h1{
            color: #222222;
        }
        grid-area:main ;

`
const Container = styled.div`
    height:200px ;
    border-radius: 15px;
    box-shadow: 0px 1.5px 10px rgba(0, 0, 0, 0.2);
    margin-right: 17px;
    padding:10px 20px;
    margin: 5px;
    width: 250px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 4fr;
    row-gap:10px;
    grid-template-areas:
    "main secondary"
    "image image"
`


export default VisitCard;