import React from 'react';

import * as S from './styled';

export default function ReportOptions({ flags, setInit, setFinal, init, final, setOldAndNewCustomFields, oldAndNewCustomFields }) {

    return (<S.Container>    
        <S.DateContainer>            
            <S.DatesShortcutsContainer>
                <button onClick={() => {
                    setInit(new Date().toISOString().substring(0, 10));
                }}>
                    Hoje
                </button>
                <button onClick={() => {
                    let date = new Date();
                    date.setDate(date.getDate() - date.getDay());
                    setInit(date.toISOString().substring(0, 10))
                }}>
                    Esta Semana
                </button>
                <button onClick={() => {
                    let date = new Date();
                    date.setDate(1);
                    setInit(date.toISOString().substring(0, 10));
                }}>
                    Este Mês
                </button>
                <button onClick={() => {
                    let date = new Date();
                    date.setDate(1);
                    date.setMonth(0);
                    setInit(date.toISOString().substring(0, 10));
                }}>
                    Este Ano
                </button>
            </S.DatesShortcutsContainer>
            <S.InputContainer>
                <div>
                    <p>Data Inicial</p>
                    <input value={init} onChange={(e) => setInit(e.target.value)} type='date' />
                </div>
                <div>
                    <p>Data Final</p>
                    <input value={final} onChange={(e) => setFinal(e.target.value)} type='date' />
                </div>
            </S.InputContainer>
            <S.FlagsContainer>            
                <S.CheckboxContainer>
                    <input 
                        type='checkbox' 
                        checked={oldAndNewCustomFields}
                        onChange={() => setOldAndNewCustomFields(!oldAndNewCustomFields)}
                    />
                    <label>Com antigos e novos campos customizados</label>
                </S.CheckboxContainer>
            </S.FlagsContainer>
        </S.DateContainer>       
       
        {/* <S.FlagsContainer>
            {flags && flags.map((flag) => {
                return <S.CheckboxContainer>
                    <input type='checkbox' />
                    <label>{flag.label}</label>
                </S.CheckboxContainer>
            })}

        </S.FlagsContainer>
        */}
    </S.Container>)
}

