import styled from 'styled-components'

export const MaskPhoto = styled.div`
  margin: 58px auto 8;
  width: 39px;
  height:39px;
  border-radius: 50%;
  background: ${props => `url(${props.background}) no-repeat top center`};
  background-size: cover;
  cursor: pointer;
`