import React, {useState, useEffect} from 'react';
import {useHistory, useParams} from "react-router-dom";
import Dropzone from 'react-dropzone';
import './styles.css';

import api from '../../services/api';

import iconProducts from  '../../assets/icons/iconProdutos.png';
import { RadioButtonChecked, RadioButtonUnchecked, Add } from '@material-ui/icons';
// import GetSubordinate from '../../utils/GetSubordinate';

function ProductsRegister(props) {
  let {idProduct} = useParams();
  const history = useHistory();
  const [product, setProduct] = useState({
    id_sku_category: '',
    id_sku_status: 1,
    name: '',
    description: '',
    ean_code: '',
    id_file:'',
  });
  const [productStatus, setProductStatus] = useState(1);
  const [productImg, setProductImg] = useState();
  const [editCategory, setEditCategory] = useState(false);
  const [category, setCategory] = useState({
    id_project: '',
 	  description:'',
  });

  const [categoryName, setCategoryName] = useState('')

  
  const [invalidInput, setInvalidInput] = useState([]);
  const [invalidCategory, setInvalidCategory] = useState([]);
  const [invalidMessage, setInvalidMessage] = useState('');

  // const [hireDate, setHireDate] = useState('');
  const [categoriesList, setCategoriesList] = useState([]);
  const [productStatusList, setProductStatusList] = useState([]);
  // const [projectsList, setProjectsList] = useState([]);
  // const [userList, setUserList] = useState([]);

  // const getSubordinate = useCallback(
  //   (array) => {
  //     return GetSubordinate(array)
  //   },
  //   []
  // )

  useEffect(() => {
    api.get('/projects/'+props.userProject.id).then((response) => {
      if(idProduct){
        let params = {
          filter_type:'id',
          conditional:idProduct
        }
        api.get('/products/filter', {params}).then(response => {
          if(response?.data){
            if(response?.data?.length > 0){
              let data = response?.data[0];
              let products = {
                id_sku_category: data.id_sku_category,
                id_sku_status: data.id_sku_status,
                id_user: data.id_user,
                name: data.name,
                description: data.description,
                ean_code: data.ean_code,
                id_file: data.file?.id,

              };
              setProduct(products);
              setProductStatus(data.id_sku_status)
              setProductImg(data.file);
            }
            // setProductStatusList(response?.data);
          }
        }).catch(err => {
          console.log('productstatus ',err);
        })
      }
      if(response?.data.length > 0){
        let categoriesData = response?.data[0].categories
        setCategoriesList(categoriesData);
        setCategory(c => {
          let category = {...c};
          category.id_project = props.userProject.id;
          return category
        })
      }
      api.get('/productstatus').then(response => {
        if(response?.data){
          setProductStatusList(response?.data);
        }
      }).catch(err => {
        console.log('productstatus ',err);
      })
      // api.get('/teams').then(response => {
      //   if(response?.data?.length > 0){
      //     const users = getSubordinate(response?.data);
      //     setUserList(users);
      //   }else{
      //     setUserList([]);
      //   }
      // }).catch(err => {
      //   console.log('teams',err);
      // })
    }).catch(err => {
      console.log('categories ',err);
    })
  }, [history, idProduct, props.userProject.id])

  async function loadFiles(files){
    try{
      if(files){
        const formData = new FormData();
        formData.append('file', files[0])
        
        if(product.id_file){
          api.put('/uploads/'+product.id_file, formData)
          .then(response =>{
            // console.log('response: ', response.data.upload)
            if(response.data.upload){
              setProductImg(response.data.upload);
              setProduct({...product, id_file:response?.data?.upload?.id})
            }
          }).catch(err => {
            console.log('response: ', err);
          })
        }else{
          api.post('/uploads', formData)
          .then(response =>{
            if(response.data.upload){
              console.log('response: ', response.data.upload);
              setProductImg(response.data.upload);
              setProduct({...product, id_file:response?.data?.upload?.id})
            }
          }).catch(err => {
            console.log('response: ', err);
          })
        }

      }
    }catch(err){
      console.log(err);
    }
  }
  
  function handleSubmit(event){
    event.preventDefault();    
    let listInvalidInputs = [];
    const categoryPayload = {...category, name:categoryName }


    if(!product.name || product.name.length < 3){
      listInvalidInputs.push('name')
    }
    if(!product.ean_code){
      listInvalidInputs.push('code')
    }
    if(!product.id_sku_category){
      listInvalidInputs.push('category')
    }
    if(!product.description){
      listInvalidInputs.push('description')
    }
    if(!product.id_file){
      listInvalidInputs.push('image')
    }

    if(product.id_sku_category === 'New'){
      let listInvalidCategory = [];
      if(!category.id_project){
        listInvalidCategory.push('project')
      }
      if(!category.name){
        listInvalidCategory.push('name')
      }
      if(!category.description){
        listInvalidCategory.push('description')
      }

      
      if(listInvalidCategory.length < 1){
        api.post('/categories', categoryPayload)
        .then(async (response) => {
          if(response?.data){
            if(response?.data){
              let productCategory = product;
              productCategory.id_sku_category = response?.data?.id;
              setProduct({...product, id_sku_category: response?.data?.id})
              setCategory({id_project: '', name: '', description:'',})

              try{
                if(listInvalidInputs.length < 1){
                  handleSubmitProduct(productCategory)
                }else{
                  setInvalidInput(listInvalidInputs);
                  setInvalidMessage('Nova categoria criada, porem o produto não foi salvo, \nverifique os campos marcados e tente novamente.')
                }
              }catch(err){
                console.log(err);
              }
            }
            api.get('/projects/'+props.userProject.id)
            .then(response => {
              if(response?.data.length > 0){
                let categoriesData = response?.data[0].categories
                setCategoriesList(categoriesData);
              }
            })
            .catch( err => {
              console.log('categories list ', err)
            })
          }
        })
        .catch( err => {
          console.log('categories ', err)
        });
      } else {
        setInvalidCategory(listInvalidCategory);
        setInvalidMessage('Verifique os campos marcados e tente novamente.')
      }
    }
    else if(editCategory && product.id_sku_category){
      let listInvalidCategory = [];
      if(!category.id_project){
        listInvalidCategory.push('project')
      }
      if(!categoryName){
        listInvalidCategory.push('name')
      }
      if(!category.description){
        listInvalidCategory.push('description')
      }

      
      if(listInvalidCategory.length < 1){
        api.put('/categories/'+product.id_sku_category, categoryPayload)
        .then(async (response) => {
          if(response?.data){
            console.log('categories: ', response?.data)
            if(response?.data){
              let productCategory = product;
              productCategory.id_sku_category = response?.data?.id;
              setProduct({...product, id_sku_category: response?.data?.id})
              setCategory({id_project: '', name: '', description:'',})
              setEditCategory(false);

              try{
                if(listInvalidInputs.length < 1){
                  handleSubmitProduct(productCategory)
                }else{
                  setInvalidInput(listInvalidInputs);
                  setInvalidMessage('Categoria alterada, porem o produto não foi salvo, \nverifique os campos marcados e tente novamente.')
                }
              }catch(err){
                console.log(err);
              }
            }
            api.get('/projects/'+props.userProject.id)
            .then(response => {
              if(response?.data.length > 0){
                let categoriesData = response?.data[0].categories
                setCategoriesList(categoriesData);
              }
            })
            .catch( err => {
              console.log('categories list ', err)
            })
          }
        })
        .catch( err => {
          console.log('categories ', err)
        });
      } else {
        setInvalidCategory(listInvalidCategory);
        setInvalidMessage('Verifique os campos marcados e tente novamente.')
      }
    }
    else{
      if(listInvalidInputs.length < 1){
        handleSubmitProduct(product)
      }else{
        setInvalidInput(listInvalidInputs);
        setInvalidMessage('Verifique os campos marcados e tente novamente.')
      }    
    }
    
  }

  function handleSubmitProduct(productInfo){
    console.log('handleSubmitProduct: ', productInfo);
    if(idProduct){
      let updateProduct = {
        id_sku_category: productInfo.id_sku_category,
        id_sku_status: productInfo.id_sku_status,
        id_user: productInfo.id_user,
        name: productInfo.name,
        description: productInfo.description,
        ean_code: productInfo.ean_code,
      }
      console.log('updateProduct: ', updateProduct);
      api.put('/products/'+idProduct, updateProduct)
      .then((response) =>{
        if(response?.data){
          console.log('Product: ', response?.data)
          history.push('/Products')
        }else{
          setInvalidMessage('Erro ao salvar o novo produto.')
        }
      })
      .catch((err) => {
        setInvalidMessage(`Erro ao salvar o novo produto. O código deve ser único.`)
        console.log(err);
      });
    }else{
      api.post('/products', productInfo)
      .then((response) =>{
        if(response?.data){
          console.log('Product: ', response?.data)
          history.push('/Products')
        }else{
          setInvalidMessage('Erro ao salvar o novo produto.')
        }
      })
      .catch((err) => {
        setInvalidMessage(`Erro ao salvar o novo produto. O código deve ser único.`)
        console.log(err);
      });
    }
  }

  function removeInvalidInput(input){
    if(invalidInput.includes(input)){
      setInvalidInput(invalidInput.filter(item => item !== input));
    }    
  }

  function removeInvalidCategoryInput(input){
    if(invalidCategory.includes(input)){
      setInvalidCategory(invalidCategory.filter(item => item !== input));
    }    
  }

  return(
    <div className='productList'>
      <div className='newProductTitleContainer'>
        <div className='newProductTitle'>
          <label>{idProduct?'Atualizar produto':'Novo produto'}</label>
          <img src={iconProducts} alt="userIcon" />
        </div>
      </div>
      <form id='productFormContainer' onSubmit={(event) => handleSubmit(event)}>
        <div id='productFormLeft'>
          <div className='productRegisterContainer'>
            <select
              className={`selectionRounded ${invalidInput.includes('category')?'invalidInput':''}`}
              value={product.id_sku_category} 
              onChange={(event) => {
                setProduct({...product, id_sku_category: event.target.value});
                removeInvalidInput('category');
                setEditCategory(false);
              }}>
              <option value="" disabled>Selecionar categoria</option>
              <option id="NewCategories" value="New">Nova categoria</option>
              {categoriesList.map((item) => (
                <option key={item.id} value={item.id}>{item.name}</option>
              ))}
            </select>
            <div className='productRegisterEdit'>
              {product.id_sku_category&&product.id_sku_category!=='New'?(
                <button 
                  className='productRegisterEditButton'
                  onClick={(event)=>{
                    event.preventDefault();
                    setCategory(
                      categoriesList.find(
                        category => category.id === product.id_sku_category
                      )
                    )
                    setEditCategory(true);
                  }}
                >
                  Editar
                </button>
              ):(
                <span/>
              )}
            </div>
          </div>
          {(product.id_sku_category === 'New' || editCategory) && (
            <>
              {/* <select
                className={`selectionRounded ${invalidCategory.includes('project')?'invalidInput':''}`}
                value={category.id_project} 
                onChange={(event) => {
                  setCategory({...category, id_project: event.target.value})
                  removeInvalidCategoryInput('project');
                }}>
                <option value="">Selecionar projeto</option>
                {projectsList.map((item) => (
                  <option key={item.id} value={item.id}>{item.name}</option>
                ))}
              </select> */}
              <input placeholder='Nome da categoria' 
                className={`inputRounded fullColumn ${invalidCategory.includes('name')?'invalidInput':''}`}
                onChange={(event) => {
                  setCategoryName(event.target.value)
                  
                }} 
                value={categoryName} />
              <textarea placeholder='Descrição da categoria' 
                className={`inputRounded fullColumn ${invalidCategory.includes('description')?'invalidInput':''}`}
                onChange={(event) => {
                  setCategory({...category, description: event.target.value})
                  removeInvalidCategoryInput('description');
                }} 
                value={category.description} />
            </>
          )}
          <input placeholder='Código do produto' 
            className={`inputRoundedProduct  ${invalidInput.includes('code')?'invalidInput':''}`}
            onChange={(event) => {
              setProduct({...product, ean_code: event.target.value})
              removeInvalidInput('code');
            }} 
            value={product.ean_code} />
          <input placeholder='Nome do produto' 
            className={`inputRoundedProduct  ${invalidInput.includes('name')?'invalidInput':''}`}
            onChange={(event) => {
              setProduct({...product, name: event.target.value})
              removeInvalidInput('name');
            }} 
            value={product.name} />
          <textarea placeholder='Descrição do produto' 
            className={`inputRoundedProduct  ${invalidInput.includes('description')?'invalidInput':''}`}
            onChange={(event) => {
              setProduct({...product, description: event.target.value})
              removeInvalidInput('description');
            }} 
            value={product.description} />
        </div>     
        <div id='productFormRight'>          
          {idProduct && productStatus === 4 && (
            <div className='statusButtonContainer'>
              <label className='productFormTitle'>Status</label>
              <div id='statusList'>
                {productStatusList.map(item => (
                  (item.id === 1 || item.id === 4 )?(
                    <button className='statusButton' key={item.id} onClick={(event)=>{
                      event.preventDefault();
                      setProduct({...product, id_sku_status:item.id, id_user:null})
                    }}>
                      {product.id_sku_status===item.id?(
                        <RadioButtonChecked className='iconRadio' style={{ fontSize: 20 }}/>
                      ):(
                        <RadioButtonUnchecked className='iconRadio' style={{ fontSize: 20 }}/>
                      )}
                      <label>{item.name}</label>
                    </button>
                  ):( false )
                ))}
              </div>
            </div>
          )}
          {/* {(product.id_sku_status!==3) && (
            <select
              className={`selectionRounded ${invalidInput.includes('id_user')?'invalidInput':''}`}
              value={category.id_user} 
              onChange={(event) => {
                console.log(event.target.value)
                setProduct({...product, id_user: event.target.value})
                removeInvalidInput('id_user');
              }}>
              <option value="">Atribuir posse</option>
              {userList.map((item) => (
                <option key={item.id} value={item.id}>{item.username}</option>
              ))}
            </select>
          )} */}
          <div id='photoContainer'>
            <div id='photoTitle'>
              <label>Foto</label>
            </div>
            <div id='photoIcon'>
              <Dropzone 
                accept='image/*' 
                onDrop={acceptedFiles => loadFiles(acceptedFiles)} 
              >
                {({getRootProps, getInputProps, isDragActive, isDragReject}) => (
                  <div className='imageCard imagemDrop' {...getRootProps()} >
                    <input {...getInputProps()} />
                    {productImg?.uri? 
                      <img className='' src={productImg.uri} alt='photoClient'/> :
                      <Add className='iconAdd' style={{ fontSize: 40 }}/>
                    }
                  </div>
                )}
              </Dropzone>
            </div>
          </div>
          <div className='productSubFormRow'>
            {invalidMessage && <label id='invalidMessage'>{invalidMessage}</label>}
          </div>
          <div className='fullColumn'>
            <button id='productFormSubmit' type="submit" value="Submit">
              Salvar
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default ProductsRegister;
