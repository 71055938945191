import styled from 'styled-components'
import { Card } from '../../components/Styled-Components/Card'
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import GroupIcon from '@material-ui/icons/Group';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import AllInboxIcon from '@material-ui/icons/AllInbox';

export const Container = styled.div`
  display:flex;
  width: 100%;
`
export const Tab = styled.div`
  width: 100%;
  padding: 20px;
`
export const FilterTitle = styled.h4`
  font-size: 16px;
  color:#555;
  margin-bottom:20px ;
`
export const TabContent = styled.div`
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: row;  
`

// Mudar para 80% quando criar o filtro
export const Left = styled.div`
  width: '72%';
  margin-right: 10px;

`
export const FilterContainer = styled.div`
  width: 100%;
  height: 100%;
  box-shadow: 1px 2px 13px #00000032;
  border-radius:15px;
  z-index:5;
`
export const Right = styled.div`
  width: 28%;
  background-color:#fff;
  border-radius:15px;
  box-shadow: 1px 2px 13px #00000032;
  padding:25px 15px;
`

// export const SpecificProductLinks = styled.div `
//   ${'' /* box-shadow: 1px 2px 13px #00000032; */}

// `
export const Icon1 = styled(GroupWorkIcon)`
  margin-right:5px;
  height:1.1em !important;
  width:1.1em !important;
  color: ${props => props.active ? '#f4582e' : '#bfbfbf'}
`

export const Icon2 = styled(GroupIcon)`
  margin-right:5px;
  height:1.1em !important;
  width:1.1em !important;
  color: ${props => props.active ? '#f4582e' : '#bfbfbf'}
`
export const Icon3 = styled(PersonOutlineIcon)`
  margin-right:5px;
  height:1.1em !important;
  width:1.1em !important;
  color: ${props => props.active ? '#f4582e' : '#bfbfbf'}
`

export const Icon4 = styled(AllInboxIcon)`
  margin-right:5px;
  height:1.1em !important;
  width:1.1em !important;
  color: ${props => props.active ? '#f4582e' : '#bfbfbf'}
`
