/* eslint-disable no-alert, no-console */

import React, {useContext} from 'react';
import { format, parseISO } from 'date-fns';

import './styles.css';

import { CloseOutlined } from '@material-ui/icons';
import { dateFnsConfig } from '../../utils/dateFnsConfig';
import StoreContext from '../../context/Context'

export default function CardInfo(props) {
  const { userInfoStore } = useContext(StoreContext);

  return (
    <>
      <div className='cardInfoTitleContainer'>
       
        {
          userInfoStore.permissions &&
          userInfoStore.permissions.includes('customerdetail_edit')
          ? props.editing 
            ? (<button className='cardInfoSalveButton' onClick={props.onSave}>Salvar</button>) 
            : (<button className='cardInfoEditButton' onClick={props.onEdit}>Editar</button>)
          : null
        }
      </div>
      <div className='cardInfo'>
        <div className='cardInfoContainer'>
          {props.children}
        </div>
      </div>
    </>
  )
}

export function TextInfo(props) {
  function editableField() {
    if (props.editing) {
      if (props.type === 'dropDownList') {
        const itemName = props.valuesName ? props.valuesName : 'name'
        const itemId = props.valuesId ? props.valuesId : 'id'
        return (
          <div>
            <div className='buttonItemListContainer'>
              {props.listValues && props.listValues.map(item => {
                // console.log(item);
                return (
                  <button key={item.id} className='buttonItemList' onClick={(event) => { props.onRemove(event, item) }}>
                    {item[itemName]} <CloseOutlined className='iconItemDelete' fontSize="small" />
                  </button>
                );
              })}
            </div>
            <div className='dropDownListInput'>
              <select
                className={`inputRounded ${props.isError ? 'invalidInput' : ''}`}
                onChange={props.onChange}
                value={props.value ? props.value : ''}
              >
                <option disabled value="">{props.title}</option>
                {props.values.map(item => (
                  <option key={item[itemId]} value={item[itemId]}>{item[itemName]}</option>
                ))}
              </select>
              {props.onAdd && (
                <button className="buttonAddList" onClick={(event) => { props.onAdd(event) }}>
                  + Add
                </button>
              )}
            </div>
          </div>
        )

      } else if (props.type === 'dropDown') {
        const itemName = props.valuesName ? props.valuesName : 'name'
        const itemId = props.valuesId ? props.valuesId : 'id'
        return (
          <select
            className={`selectionRoundedRegister ${props.isError ? 'invalidInput' : ''}`}
            onChange={props.onChange}
            value={props.value ? props.value : ''}
            readOnly={props.readOnly}
          >
            <option disabled value="">{props.title}</option>
            {props.values.map(item => (
              <option key={item[itemId]} value={item[itemId]}>{item[itemName]}</option>
            ))}
          </select>
        )
      }
      if (props.type === 'date') {
        return (
          <>
            <input
              className={`inputRounded ${props.isError ? 'invalidInput' : ''}`}
              type={props.type}
              value={props.value ? format(parseISO(props.value), "yyyy-MM-dd", dateFnsConfig()) : ''}
              onChange={props.onChange}
              maxLength={props.maxLength}
              readOnly={props.readOnly}
              style={props.style}
            />
            {props.isError && <label className='inputMessage'>{props.errorMessage}</label>}
          </>
        )
      }
      else {
        return (
          <>
            <input
              className={`inputRounded ${props.isError ? 'invalidInput' : ''}`}
              type={props.type}
              value={props.value ? props.value : ''}
              onChange={props.onChange}
              maxLength={props.maxLength}
              readOnly={props.readOnly}
            />
            {props.isError && <label className='inputMessage'>{props.errorMessage}</label>}
          </>
        )
      }
    } else {
      if (props.children) {
        return props.children
      }
      if (props.type === 'dropDown') {
        const itemName = props.valuesName ? props.valuesName : 'name'
        const itemId = props.valuesId ? props.valuesId : 'id'
        let label = '';
        if (props.values) {
          let item = props.values.find(item => item[itemId] == props.value);
          // console.log(props.valuesName)
          if (item) {
            label = item[itemName] ? item[itemName] : '';
          }
        }
        return (
          <label> {label} </label>
        )
      }
      if (props.type === 'dropDownList') {
        const itemName = props.valuesName ? props.valuesName : 'name'
        const itemId = props.valuesId ? props.valuesId : 'id'
        let label = '';
        if (props?.values && props?.listValues?.length > 0) {
          props.listValues.forEach((value, index) => {
            let item = props.values.find(item => item[itemId] === value[itemId]);
            if (item) {
              if (index > 0) {
                label += ', ' + (item[itemName] ? item[itemName] : '');
              } else {
                label += (item[itemName] ? item[itemName] : '');
              }
            }
          });
        }
        return (
          <label> {label} </label>
        )
      }
    }
    return (
      <>
        <label
          className={`${props.color === 'colorful' ? 'textInfoColorful' : ''} ${props.isError ? 'textInfoError' : ''}`}>
          {props.type === 'date' && props.value ? format(parseISO(props.value), props.dateMask, dateFnsConfig()) : props.value}
        </label>
        {props.isError && <label className='inputMessage'>{props.errorMessage}</label>}
      </>
    )
  }

  return (
    <div className={`textInfoText ${props.disable ? 'textInfoDisable' : ''} ${props.className}`} style={props.style} id={props.id}>
      <label className='textInfoTitle' >{props.title}</label>
      {editableField()}
    </div>
  )
}