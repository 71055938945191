import React from 'react'

import * as S from './modal.styles'

export const Modal = (props) => {
  const {active, descriptionModal, headerContent = ""} = props
  const acceptModal = <><p>Sua solicitação foi enviada</p>
  <p>com sucesso. Obrigado.</p></>  

  return(
    <>
      { active  && (
        <>
          <S.Root >
            <S.Modal>
              <S.Container>
                <S.Header>
                  {headerContent}
                </S.Header>
                <S.Main>
                  {descriptionModal ? 
                  (
                    <>
                      <p>{descriptionModal}</p>
                    </>
                  ): 
                  (
                    <>
                      {acceptModal}
                    </>
                  )}
                </S.Main>
              </S.Container>
            </S.Modal>
          </S.Root>
        </>
        )
      }
    </>
  )
} 