import styled from 'styled-components'

export const Input = styled.input`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: #000000;
  width: ${props => props.primary ? "100%" : "50%"};
  padding-left: 12px;
  height: 30px;
  border: 1.5px solid #888888;
  border-radius: 20px;
  box-sizing: border-box;
  &:link {
    text-decoration: none;
  }
  &:active {
    text-decoration: none;
  }
  &:focus {
    text-decoration: none;
    box-shadow: 0 0 0 0;
    outline: 0;
  }
`