import React, { useContext, useEffect, useState,useCallback } from "react";
import { useHistory, useLocation } from 'react-router-dom'
import StoreContext from "../../context/Context";
import { permissionCheck } from "../../utils/permission_role";
import * as S from "./styles"


export const TabBarContext = React.createContext();

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}
export const TabBarProvider = ({ children }) => {
    const history = useHistory()
    const [selected, setSelected] = useState(null)
    const [renderItems, setRenderItems] = useState([])
    const [selectedId, setSelectedId] = useState(null)

    const changeSceneById = (id) => {
       

        setSelectedId(id)
        setSelected(renderItems.findIndex(e => e.id == id))
        history.replace({ search: '?s=' + encodeURI(id) })
        document.querySelector('#mainScroll').scrollTo(0, 0, { behavior: 'smooth' })

    }
    const changeScene = (elm, index) => {
        history.replace({ search: '?s=' + encodeURI(elm.id) })
        setSelectedId(elm.id)
        setSelected(index)
    }
    return (
        <TabBarContext.Provider value={{ selected, setSelected, renderItems, setRenderItems, selectedId, setSelectedId, changeScene, changeSceneById }}>
            {children}
        </TabBarContext.Provider>
    );
};

const TabBar = ({ renderItems: _renderItems, onSwitchTab }) => {

    const query = useQuery()
    const { userInfoStore } = useContext(StoreContext)
    const { renderItems, setRenderItems, setSelectedId, setSelected, selected, changeScene } = useContext(TabBarContext)


    useEffect(() => {
        const firstScene = _renderItems[0]
        setRenderItems(_renderItems)

        if (firstScene) {
            if (!selected) {
                const q = query.get("s");
                if (q) {
                    setSelectedId(q)
                    setSelected(_renderItems.findIndex(e => e.id == q))
                } else {


                    setSelected(0)
                    setSelectedId(firstScene.id)
                }

            }
        }
    }, [query, setRenderItems, renderItems, _renderItems, setSelectedId, setSelected, selected])
    return <S.Container>
        {
            renderItems.map((elm, index) => {
                if (!!elm.permissionNeeded && !permissionCheck(elm?.permissionNeeded, userInfoStore.permissions)) {

                    return null
                }
                return <S.TopButton iconSize={elm.iconSize} onClick={() => {
                    changeScene(elm, index);
                    if (onSwitchTab)
                        onSwitchTab(elm);
                }} key={index} active={selected === index}>
                    <div className="innerContent">
                        {elm.img && elm.img()}
                        <label>
                            {elm.name}
                        </label>

                    </div>
                    <div className="buttonStatus">
                    </div>
                </S.TopButton>
            }
            )
        }
    </S.Container>

}

const RenderCheck = ({ item, selectedId, children }) => {


    if (item.id === selectedId)
        return children

    return null

}
export const TopBarView = ({ styles }) => {
    const { renderItems, selectedId, setSelectedId, setSelected } = useContext(TabBarContext)
    
    const history = useHistory()

    const changeScene = (elm, index, filter) => {
        history.replace({ search: '?s=' + encodeURI(elm) + `&group=${filter}` })
        setSelectedId(elm)
        setSelected(index)
    }

    return <S.TopBarView style={styles}>
        {renderItems.map((e, index) =>
            <RenderCheck item={e} selectedId={selectedId} key={index}>
                {e.render({ index, changeScene,scene:e,selectedId })}
            </RenderCheck>
        )}
    </S.TopBarView>
}

export default TabBar