import React, { useState, useMemo, useContext, useEffect, useCallback } from 'react';
import * as S from './styled';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend'
import DraggableField from './DraggableField';
import { useDrop } from 'react-dnd';
import api from '../../../services/api';
import StoreContext from '../../../context/Context';
import { Spinner } from '../../../components';
import swal from 'sweetalert';
 

export default function DNDContext({ fields, report_id, init, final, oldAndNewCustomFields }) {
  return <DndProvider backend={HTML5Backend}>
    <SheetPrepare 
      init={init} 
      final={final} 
      fields={fields} 
      report_id={report_id} 
      oldAndNewCustomFields={oldAndNewCustomFields}
    />
  </DndProvider>
}


function DroppableZone({ column, onDrop, fields, setFields, remove }) {
  const [{ canDrop }, drop] = useDrop(() => ({
    accept: 'string',
    name: column,
    drop: (item, monitor) => {
      onDrop(item, column)
    },
    hover: (item, monitor) => {
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  }))

  return <td 
    id={canDrop ? "canDrop" : ""} 
    className={fields[column] ? "selected" : ""} 
    ref={drop} 
    key={fields[column]}
  >
    {fields[column] ? 
    <DraggableField outsideDropped={remove} setFields={setFields} fields={fields} data={fields[column]} dispatchAdd={false} type={fields[column]?.type === "custom_field"} /> : "---"}
  </td>
}

const SheetPrepare = ({ fields: _fields, report_id, init, final, oldAndNewCustomFields }) => {
  const { userProjectStore, userInfoStore } = useContext(StoreContext);

  const [fields, setFields] = useState({})
  const [possibleColumns, setPossibleColumns] = useState(_fields)
  const [waitingReport, setWaitingReport] = useState(false)
  const [requestId, setRequestId] = useState(null)
  const [reportStatus, setReportStatus] = useState(null)

  const CONCAT_FIELD = '(CP)'
  let intervalId = null;

  function generateColumns(number) {
    let columns = [];

    let column = '';

    for (let i = 0; i < number; i++) {
      if (i < 26) {
        column = String.fromCharCode(65 + i);
      } else {
        column = String.fromCharCode((65 + Math.floor(i / 26)) - 1) + String.fromCharCode(65 + i % 26);
      }

      columns.push(column);
    }

    return columns;
  }

  const columns = useMemo(() => generateColumns(Math.ceil(possibleColumns.length / 10) * 10), [possibleColumns]);  

  const cleanFields = () => {
    setFields([])
  }

  const add = (item) => {
    let column

    for (const col of columns) {
      let isEmptyColumn = fields[col]
  
      if (!isEmptyColumn) {      
        column = col
        break
      } 
    }   

    setFields((e) => {
      const _fields = { ...e };
      
      const asArray = Object.entries(_fields);

      const filtered = asArray.filter(e => e[1].name !== item.name); 

      const asObject =  { [column]: item, ...Object.fromEntries(filtered) }

      return asObject
    })
  }

   // Verificar se as datas estão no intervalo permitido de 365 dias
   const verifyDates = (startDate, endDate) => {
    if (userInfoStore?.id === 'f94a2793-a90e-4f49-b8ae-94add971e8a1'){
      return false
    }

    const maxDiffDays = 92;

    // Se a data final não foi informada, considerar data atual
    if (!endDate) {
      endDate = new Date().toISOString().slice(0, 10);
    }
    
    const diffTime = Math.abs(new Date(endDate) - new Date(startDate));
    
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); // Arredonda para cima em caso de hora de verão
    
    return diffDays > maxDiffDays;
  }

  const startGenerateReport = async () => {
    if (waitingReport) {
      return
    }

    if (!init) {
      swal(`Data Inicial é obrigatório!`, {
        icon: "info",
        button: "Fechar"
      });

      document.querySelector('#mainScroll').scrollTo(0, 0)
      
      return
    }

    const valid_date = verifyDates(init, final)

    if (valid_date) {
      swal(`O intervalo de datas deve ser de no máximo 90 dias!`, {
        icon: "info",
        button: "Fechar"
      });

      document.querySelector('#mainScroll').scrollTo(0, 0)

      return
    }

    const copy_fields = JSON.parse(JSON.stringify(fields))

    const letter_size_one = Object.keys(copy_fields).filter(letter => letter.length === 1).sort()
    const letter_size_two = Object.keys(copy_fields).filter(letter => letter.length > 1).sort()

    const save_order_fields = [...letter_size_one, ...letter_size_two].reduce((obj, key) => {
      if (copy_fields[key].name.endsWith(CONCAT_FIELD)) {
        copy_fields[key].name = copy_fields[key].name.replace(CONCAT_FIELD, '')
      }

      obj[key] = copy_fields[key];

      return obj;
    }, {});

    setWaitingReport(true)

    const response = await api.post("/v2/report", {
      fields: Object.values(save_order_fields),
      id_project: userProjectStore.id,
      report_id: report_id,
      date: { init, final },
      old_and_new_customfields: oldAndNewCustomFields
    })

    setRequestId(response.data.request_id)    
      intervalId = setInterval(async () => {
        const res = await api.get(`/v2/report/status`, { params: { request_id: response.data.request_id } })

        if (res.data.status === "done" || res.data.status === "error") {
          clearInterval(intervalId)

          intervalId = false

          setWaitingReport(false)

          if (res.data.status === "done") {
            window.open(res.data.uri.replace('https://s3.sa-east-1.amazonaws.com/prismapro.files.cloud/reports/', "https://api.prismapro.com.br" + "/v2/report/"), "_top")
            //window.open(res.data.uri.replace('https://s3.sa-east-1.amazonaws.com/prismapro.files.cloud/reports/', process.env.REACT_APP_API + "/v2/report/"), "_top")

            setFields(fields)
          }
        }

        setReportStatus(res.data)
    }, 1000)
  }

  const getConfigs = useCallback(async () => {
    const res = await api.get('v2/report/config', { params: { id_project: userProjectStore.id, id_report: report_id } })

    if (res.data) {      
      const columns = generateColumns(res.data.fields.length)

      const fields = {}
      
      res.data.fields.forEach((field, index) => {
        if (!field.name.endsWith(CONCAT_FIELD)) {
          const fieldType = field.type === "custom_field" ? CONCAT_FIELD : ''
          
          field.name = field.name + fieldType
        }
                
        fields[columns[index]] = field
      })

      setFields(fields)
    } else {
      const colu = generateColumns(possibleColumns.length);
      
      const obj = {}

      colu.forEach((col, index) => {
        if (!possibleColumns[index].name.endsWith(CONCAT_FIELD)) {
          const fieldType = possibleColumns[index].type === "custom_field" ? CONCAT_FIELD : ''
          
          possibleColumns[index].name = possibleColumns[index].name + fieldType
        }
        
        obj[col] = possibleColumns[index]
        
        setFields(obj)
      })
    }

  }, [possibleColumns, report_id, userProjectStore.id])

  useEffect(() => {
    getConfigs()
  }, [getConfigs])


  const dropped = (item, column) => {
    setFields((e) => {
      const _fields = { ...e };
      
      const asArray = Object.entries(_fields);

      const filtered = asArray.filter(e => e[1].name !== item.name); 

      const asObject =  { [column]: item, ...Object.fromEntries(filtered) }

      return asObject
    })
  }

  const remove = (item) => {
    setFields((e) => {
      const _fields = { ...e };
      
      const asArray = Object.entries(_fields);

      const filtered = asArray.filter(elm => {
        return elm[1].name !== item.name
      });

      const asObject = { ...Object.fromEntries(filtered) }

      return asObject
    })
  }

  return (
    <S.MainContainer>

      <S.FieldsToAddContainer>
        {
          possibleColumns?.filter(e => !Object.values(fields)
            .map(e => e.name)
            .includes(e.name))
            .map((e) => {

              if (!e?.name.endsWith(CONCAT_FIELD)) {
                const fieldType = e.type === "custom_field" ? CONCAT_FIELD : ''
                
                e.name = e.name + fieldType
              }
              
              return <DraggableField 
                outsideDropped={remove} 
                key={e.name} 
                data={e} 
                type={e?.type === "custom_field"}
                dispatchAdd={true} 
                add={add} 
              />}
            )
        }
      </S.FieldsToAddContainer>
      {
        Array.apply(null, Array(Math.ceil(possibleColumns.length / 10))).map((e, index) => {
          const copy = [...columns];
          const columnLine = copy.splice(index * 10, 10);

          return <table>
            <thead>
              <tr>
                {
                  columnLine.map((e) => <td key={e}>{e}</td>)
                }
              </tr>
            </thead>
            <tbody>
              <tr>
                {
                  columnLine.map((e) => <DroppableZone fields={fields} setFields={setFields} remove={remove} onDrop={dropped} column={e} key={e}/>)
                }
              </tr>
            </tbody>
          </table>
        })
      }
      <S.ExportButtonContainer>
        <S.ExportButton active={!waitingReport} onClick={startGenerateReport}>
          Exportar
        </S.ExportButton>
        <S.ExportButton 
          onClick={cleanFields} 
          active={true}
          style={{ marginLeft: '15px' }}
        >
          Limpar Campos
        </S.ExportButton>
      </S.ExportButtonContainer>
      {
        waitingReport &&
        <S.ReportStatusContainer>
          <S.ReportStatusText>{reportStatus?.message}</S.ReportStatusText>
          <Spinner style={{ borderLeftColor: "#999", marginTop: 2 }} small />
        </S.ReportStatusContainer>
      }
    </S.MainContainer>

  )
}

