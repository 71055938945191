import styled from 'styled-components';
import { Card } from '../../components/Styled-Components/Card'
import Search from '../../components/Search';

export const Container = styled.div `
  flex: 1;
  width: 100%;
  height: auto;
  font-family: Roboto;
`;

export const HeaderCard = styled(Card) `
  width:100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
`;

export const ButtonDiv = styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const HeaderTitle = styled.h1 `
  font-size: 20px;
  color: #EE5928;
`;

export const UserSearch = styled(Search) `
  flex: 0 0;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-right: 30px;
  height: 32px;
  
  & input {
    width: 270px;
  }
`;

export const NewUserButton = styled.button `
  height: 32px;
  padding: 5px 20px;
  border-radius: 16px;
  color: var(--color-text-light);
  background-color: var(--color-background-primary);
  align-self: center;
  align-items: center;
  justify-content: center;
`;

export const MainCard = styled(Card) `
  margin-top: 10px;
  padding: 15px;
  width: 100%;
`

export const GridTitleContainer = styled.div `
  cursor : ${(props) => props.clickable ? 'pointer': 'default'};
  display: grid; 
  grid-template-columns: 3fr 3fr 3fr 1fr 1fr 1fr; 
  grid-template-rows: 1fr; 
  gap: 0px 1px; 
  grid-template-areas: ". . . . . ."; 
  padding: 12px;
  margin-bottom: 15px;

  & span { 
    align-self: center;
  }

`;

export const UserListContainer = styled.div `
  cursor : ${(props) => props.clickable ? 'pointer': 'default'};
  display: grid; 
  grid-template-columns: 3fr 3fr 3fr 1fr 1fr 1fr; 
  grid-template-rows: 1fr; 
  gap: 0px 1px; 
  grid-template-areas: 
    ". . . . . ."; 
    padding:12px;

    & span { 
      align-self: center;
    }
`;

export const GridTitle = styled.span `
  font-size:14px; 
  color:#333;
  font-weight: 700;
`;

export const GridCard = styled(Card) `
  width:100%;
  min-height:0;
  margin-bottom:10px;
`;

export const UserImg = styled.img `
  height: 40px;
  width:40px;
  margin-right:10px;
  border-radius:50%;
  cursor: pointer;
`;

export const PromotorDiv = styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const UserListText = styled.span `
  font-size:14px; 
  color:#444;
  text-transform: capitalize;
`;
