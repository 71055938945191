import React, { useState, useEffect, useContext } from 'react';

import api from '../../services/api';

import './styles.css';

import metricCollaborators from '../../assets/icons/icon_metricas_contratacoes.svg'
import metricVisit from '../../assets/icons/icon_metricas_visitas.svg'
import metricSales from '../../assets/icons/icon_metricas_vendas.svg'
// import metricProductivity from '../../assets/icons/icon_metricas_produtividade.svg'
// import metricActivations from '../../assets/icons/icon_metricas_ativacoes.svg'
import { FormatNumber } from '../../utils/FormatData';
import ModalAlert from '../../components/ModalAlert';
import StoreContext from '../../context/Context';

function Metrics(){
  const [metric, setMetric] = useState([]);
  const [message, setMessage] = useState('')
  const { userProjectStore: userProject, userInfoStore: userInfo } = useContext(StoreContext);

  const [alert, setAlert] = useState({
    showAlert: false,
    title: 'Métricas',
    desc: 'Atualização enviada com sucesso!',
    onClose: (event) => {
      event.preventDefault();
      setAlert({ ...alert, showAlert: false })
    },
    buttons: [
      {
        name: 'OK', onClick: (event) => {
          event.preventDefault();
          setAlert({ ...alert, showAlert: false })
        }
      }
    ]
  })

  useEffect(() => {
  if (userInfo?.id && userProject?.id) {
    api.get('/projects/' + userProject.id)
      .then(response => {
        if (response.data.length > 0) {
          const data = response.data[0]
          setMetric([
            { id: 1, img: metricCollaborators, title: 'Contratações', name: 'daily_goal_headcount', goal: data.daily_goal_headcount },
            { id: 2, img: metricVisit, title: 'Visitas', name: 'daily_goal_visits', goal: data.daily_goal_visits },
            { id: 3, img: metricSales, title: 'Vendas', name: 'daily_goal_sales', goal: data.daily_goal_sales },
            // {id: 4, img: metricActivations, title: 'Ativações', name:'daily_goal_productivity', goal: data.daily_goal_productivity},
          ])
        }
      });
  } else {
    if (userInfo?.id && !userProject?.id) {
      setMessage('Usuário sem projeto selecionado! \nPor favor verifique a aba perfil.');
    }
  }
}, [userProject, userInfo]);

function submitMetric(event) {
  event.preventDefault();
  console.log(metric);
  let postProject = {};
  metric.forEach(item => {
    postProject[item.name] = parseInt(item.goal, 10);
  })
  console.log('postProject: ', postProject);
  api.put('/projects/' + userProject.id, postProject)
    .then(response => {
      if (response.data) {
        setAlert({
          ...alert,
          showAlert: true,
          title: 'Métricas',
          desc: 'Atualização enviada com sucesso!'
        });
      } else {
        setAlert({
          ...alert,
          showAlert: true,
          title: 'Métricas',
          desc: 'Não foi possivel atualizar as metas!'
        });
      }
    }).catch(err => {
      console.log(err);
      setAlert({
        ...alert,
        showAlert: true,
        title: 'Métricas',
        desc: 'Não foi possivel atualizar as metas!'
      });
    })
}

return (
  <div id='metricMain'>
    <ModalAlert
      showAlert={alert.showAlert}
      title={alert.title}
      desc={alert.desc}
      buttons={alert.buttons}
      onClose={alert.onClose}
    />
    {!message && (
      <>
        <div id='metricContainer'>
          <div className='metricCardTitle'>
            <label>Métricas</label>
            <label>Metas definidas</label>
          </div>
          {metric.map(item => (
            <div key={item.id} className='metricCard'>
              <div className='metricCardRow'>
                <div className='metricCardLabel'>
                  <img className='metricCardIcon' src={item.img} alt='icon' />
                  <label>{item.title}</label>
                </div>
                <input
                  className='metricCardInput'
                  onChange={(event) => {
                    let metrics = [];
                    metric.forEach(itemInfo => {
                      if (itemInfo.id !== item.id) {
                        metrics.push(itemInfo);
                      } else {
                        let tempMetric = itemInfo;
                        tempMetric.goal = FormatNumber(event.target.value);
                        metrics.push(tempMetric);
                      }
                    })
                    setMetric(metrics)
                  }}
                  value={item.goal}
                />
              </div>
            </div>
          ))}
        </div>
        <div id='metricButtonContainer'>
          <button
            className='metricButton'
            onClick={(event) => { submitMetric(event) }}
          >
            atualizar metas
          </button>
        </div>
      </>
    )}
    {message && (
      <div className='alertTextMessage'>
        <textarea readOnly defaultValue={message} />
      </div>
    )}
  </div>
)
}

export default Metrics;