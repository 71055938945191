import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as datefns from 'date-fns';

import './styles.css';
import iconSearch from '../../assets/icons/icon_search.svg';
import iconClose from '../../assets/icons/icon_close.svg';
import iconConfig from '../../assets/icons/iconFiltro.png';

const operationList = [
  {
    func:
      function (val, compare) {
        if (!val) {
          return false;
        } else {
          return val.includes(compare);
        }
      },
    id: '0',
    name: 'Contém'
  },
  {
    func:
      function (val, compare) {
        if (!val) {
          return true;
        } else {
          return !val.includes(compare.toLowerCase());
        }
      },
    id: '1',
    name: 'Não contém'
  },
  {
    func:
      function (val, compare) {
        if (!val) {
          return false;
        } else {
          return val.toLowerCase() === compare.toLowerCase();
        }
      },
    id: '2',
    name: 'Igual'
  },
  {
    func:
      function (val, compare) {
        if (!val) {
          return true;
        } else {
          return val.toLowerCase() !== compare.toLowerCase();
        }
      },
    id: '3',
    name: 'Diferente'
  },
]

const statesButtons = [
  { text: 'Filtrar', img: iconConfig },
  { text: 'Fechar', img: iconClose }
]

export default function Search(props) {

  const [searchInput, setSearchInput] = useState('');

  const [showFilters, setShowFilters] = useState(false);
  const [fieldFilters, setFieldFilters] = useState({
    field: "", operator: "0", value: ""
  });
  const [filtersList, setFiltersList] = useState({})
  const [filterButton, setFilterButton] = useState(statesButtons[0]);

  useEffect(() => {
    let isSubscribed = true;
    if (props.dropDownFields?.length > 0 && isSubscribed) {
      const field = props.dropDownFields[0].id;
      setFieldFilters(f => {
        f.field = f.field === "" ? field : f.field;
        return f;
      });
    }
    return () => { isSubscribed = false }
  }, [props.dropDownFields])

  function filter(event) {
    event.preventDefault();
    setShowFilters(false);
    setFilterButton(statesButtons[0]);

    let filterList = [];
    let filtered = [];

    if (searchInput !== '' && props.searchField) {
      props.searchField.forEach(field => {
        filtered = props.list.filter(item => {
          if (!filterList.includes(item)) {
            return getField(field, item).toLowerCase().includes(searchInput.toLowerCase());
          }
          return false;
        })
        filterList = [...filterList, ...filtered];
      });
    } else {
      filterList = props.list;
    }




    if (fieldFilters.field && fieldFilters.operator && fieldFilters.value) {
      const operator = operationList.find(item => item.id === fieldFilters.operator);
      const fields = props.dropDownFields.find(item => item.id === fieldFilters.field);

      if (operator?.func && fields?.field) {
        let newFiltered = [];
        fields.field.forEach(field => {
          filtered = filterList.filter(item => {
            let val = getField(field, item);
            if (typeof val !== 'string') {
              val = val.toString().toLowerCase();
            } else {
              val = val.toLowerCase();
            }

            let comp = fieldFilters.value;
            if (typeof comp !== 'string') {
              comp = comp.toString().toLowerCase();
            } else {
              comp = comp.toLowerCase();
            }
            return operator.func(getField(field, item), fieldFilters.value) ? true : false;
          })
          newFiltered = [...newFiltered, ...filtered];
        })

        filterList = newFiltered;
      }
    }

    if (filtersList && props.dropDownChildren) {
      props.dropDownChildren.forEach(filter => {
        if (filtersList[filter.id]) {
          let newFiltered = [];
          filter.field.forEach(field => {
            filtered = filterList.filter(item => {
              let val = getField(field, item);
              if (typeof val !== 'string') {
                val = val.toString().toLowerCase();
              } else {
                val = val.toLowerCase();
              }

              let comp = filtersList[filter.id];
              if (typeof comp !== 'string') {
                comp = comp.toString().toLowerCase();
              } else {
                comp = comp.toLowerCase();
              }


              if (filter.type === "between") {
                if (filter.input.type === 'date' && (filtersList[filter.id]['ini'] || filtersList[filter.id]['end'])) {
                  const ini = filtersList[filter.id]['ini'] ? datefns.parseISO(filtersList[filter.id]['ini']) : new Date();
                  const end = filtersList[filter.id]['end'] ? datefns.parseISO(filtersList[filter.id]['end']) : new Date();
                  const compDate = datefns.parseISO(val);

                  return (ini < compDate && compDate < end);
                }
                return val.includes('');
              } else {
                return val.includes(comp);
              }
            })


            newFiltered = [...newFiltered, ...filtered];
          })

          filterList = newFiltered;
        }
      });
    }



    return filterList;
  }


  function changeFilter(event) {
    event.preventDefault()
    if (!showFilters) {
      setFilterButton(statesButtons[1]);
      setShowFilters(true);
    } else {
      setFilterButton(statesButtons[0]);
      setShowFilters(false);
    }
  }

  return (
    <form onSubmit={(e) => e.preventDefault() } className={`searchInput ${props.className}`} id={props.id}>
      { 
        props.showButton && 
        <button onClick={(event) => { props.submit && props.submit(event, filter(event)) }}>
          <img src={iconSearch} alt="searchIcon" />
        </button>
      }
      <input
        placeholder={props.placeholder}
        onChange={(event) => {
          if(props.onChange) props.onChange(event.target.value);
         
          setSearchInput(event.target.value);
        }}
        value={searchInput}
      />
      {props.dropDown && (

        <div id='dropdown'>
          <button id='filterButton' onClick={event => changeFilter(event)}>
            {filterButton.text}
            <img src={filterButton.img} alt="searchIcon" />
          </button>
          {showFilters &&
            (<div id='dropdownRegisterContent'>
              <div className='fullColumnFilter'>
                <label className='labelFilter'>
                  Pesquisar em
            </label>
                <select
                  className='inputRoundedFilter'
                  value={fieldFilters.field}
                  onChange={(event) => {
                    setFieldFilters({ ...fieldFilters, field: event.target.value });
                  }}>
                  {
                    props.dropDownFields.map((item, index) => (
                      <option key={index} value={item.id}>{item.name}</option>
                    ))
                  }
                </select>
              </div>
              <div className='fullColumnFilter'>
                <label className='labelFilter'>Critério de pesquisa</label>
                <select
                  className='inputRoundedFilter'
                  value={fieldFilters.operator}
                  onChange={(event) => {
                    setFieldFilters({ ...fieldFilters, operator: event.target.value });
                  }}>
                  {operationList.map((item, index) => (
                    <option key={index} value={item.id}>{item.name}</option>
                  ))}
                </select>
              </div>
              <div className='fullColumnFilter'>
                <label className='labelFilter'>Pesquisar por</label>
                <input
                  className='inputRoundedFilter'
                  value={fieldFilters.value}
                  onChange={(event) => {
                    setFieldFilters({ ...fieldFilters, value: event.target.value });
                  }} />
                {props.dropDownChildren && (
                  <div className='searchFieldsList'>
                    {props.dropDownChildren.map((item) => (
                      <div key={item.id} className={item.className}>
                        {item.type !== 'between' ? (
                          <>
                            <label {...item.label} className={`labelFilter ${item.label?.className}`}>
                              {item.name}
                            </label>
                            {item.input.type === 'select' ? (
                              <select
                                {...item.input}
                                className={`inputRoundedFilter ${item.input.className}`}
                                defaultValue={filtersList[item.id] ? filtersList[item.id] : ''}
                                onChange={(event) => {
                                  const val = event.target.value
                                  setFiltersList(f => {
                                    f[item.id] = val;
                                    return f
                                  });
                                }}
                              >
                                {item.list.length > 0 && item.list.map(option => (
                                  <option key={option.id} value={option.id}>{option.name}</option>
                                ))}
                              </select>
                            ) : (
                              <input
                                {...item.input}
                                className={`inputRoundedFilter ${item.input.className}`}
                                value={filtersList[item.id]}
                                onChange={(event) => {
                                  setFiltersList(f => {
                                    f[item.id] = event.target.value;
                                    return f
                                  });
                                }}
                              />
                            )}
                          </>
                        ) : (
                          <>
                            <div className={item.inputClassName}>
                              <label {...item.label} className={`labelFilter ${item.label?.className}`}>
                                {item.label_ini}
                              </label>
                              <input
                                {...item.input}
                                className={`inputRoundedFilter ${item.input.className}`}
                                defaultValue={filtersList[item.id]?.ini}
                                onChange={(event) => {
                                  const val = event.target.value;
                                  setFiltersList(f => {
                                    f[item.id] = { ini: val, end: f[item.id]?.end }
                                    return f
                                  });
                                }}
                              />
                            </div>
                            <div className={item.inputClassName}>
                              <label {...item.label} className={`labelFilter ${item.label?.className}`}>
                                {item.label_end}
                              </label>
                              <input
                                {...item.input}
                                className={`inputRoundedFilter ${item.input.className}`}
                                defaultValue={filtersList[item.id]?.end}
                                onChange={(event) => {
                                  const val = event.target.value;
                                  setFiltersList(f => {
                                    f[item.id] ?
                                      f[item.id].end = val :
                                      f[item.id] = { end: val }
                                    return f
                                  });
                                }}
                              />
                            </div>
                          </>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div id='filterButtonContainer' onClick={(event) => {
                props.submit && props.submit(event, filter(event))
              }}>
                <button>Aplicar</button>
              </div>
            </div>)}
        </div>
      )}
    </form>
  )
}

export function getField(path, item) {
  let routes = path?.split('.');
  let value = '';
  if (routes.length > 1) {
    routes.forEach((route, index) => {
      if (index === 0) {
        value = item[route]
      } else {
        if (value && typeof value === 'object') {
          if (Array.isArray(value)) {
            if (value.length > 0) {
              value = value[0][route];
            }
          } else {
            value = value[route];
          }
        }
      }
    });
  } else {
    value = item[path];
  }

  if (!path.includes("verified"))
    return value ? value : '';
  else
    return value;
}

Search.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  list: PropTypes.array.isRequired,
  submit: PropTypes.func.isRequired,
  searchField: PropTypes.array.isRequired,
  dropDown: PropTypes.bool,
  dropDownFields: PropTypes.array,
  dropDownChildren: PropTypes.array,
};